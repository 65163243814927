import { Auth } from "domain/model/Auth"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface SendPlacementsMailUsecase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class SendPlacementsMail implements SendPlacementsMailUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, data: any): Promise<any> {
    return await this.repository.sendPlacementsMail(auth, data)
  }
}
