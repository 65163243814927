import { Button } from "core/components/v2/Button"
import { STR_FAILURE } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import React from "react"
import { isEmpty } from "core/utils/misc"

const CancelReasonModal = ({
  changeToastDetails,
  changeToastVisibility,
  cancelReason,
  setCancelReason,
  setCancelPopUpModal,
  activeSessionIndex,
  activeSession,
  isOpen,
  onClose,
}: any) => {
  if (!isOpen) return null
  function formatDate(inputDate: any) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    if (!dateRegex.test(inputDate)) return inputDate

    const dateObject = new Date(inputDate)

    const dayOfMonth = dateObject.getDate()
    const monthName = dateObject.toLocaleString("default", { month: "short" })

    const getOrdinal = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"]
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }

    const dayWithOrdinal = getOrdinal(dayOfMonth)

    const formattedDate = `${dayWithOrdinal} ${monthName}`

    return formattedDate
  }
  function formatTime(hour24: any) {
    hour24 = parseFloat(hour24)

    const hours = Math.floor(hour24)
    const minutes = (hour24 % 1) * 60

    const period = hours < 12 ? "AM" : "PM"

    let hour12 = hours % 12
    hour12 = hour12 === 0 ? 12 : hour12

    return `${hour12}:${minutes.toString().padStart(2, "0")} ${period}`
  }

  return (
    <div className="relative z-30">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0"></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative bg-white w-[449px] h-[574px] p-[24px] rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="flex justify-between">
            <div>
              <p className="text-[#DC2626] text-[16px] font-[600]">Mentor session Cancellation</p>
              <p>Mentor Session {activeSessionIndex + 1}</p>
            </div>
            <button className="hover:bg-slate-200 rounded-[4px] size-[24px]" onClick={onClose}>
              <CrossIcon className="size-[24px]" />
            </button>
          </div>
          <div className=" my-[16px]">
            <p className="text-[#414141] font-inter text-[14px] font-semibold">
              {" "}
              {!isEmpty(activeSession?.temp_mentor) ? activeSession?.temp_mentor?.name : activeSession?.mentor_name}
            </p>
            <p className="text-[#414141] font-inter text-[14px] font-semibold">
              {formatDate(activeSession?.session_date)} | {formatTime(activeSession?.session_start_time)}
            </p>
          </div>
          <p className="text-[14px] font-[600] text-[#333] my-[16px]">
            Cancellation Reason <span className="text-[#DC2626]">*</span>
          </p>

          <textarea
            placeholder="Write your reason here..."
            className="h-[195px] w-full border-[#D3D3D3] focus:border-[#D3D3D3] rounded-[4px]"
            value={cancelReason}
            onChange={(e: any) => setCancelReason(e.target.value)}
          />

          <div className="absolute bottom-0 left-0 rounded-b-[4px] flex justify-end w-full h-[59px] bg-[#F9F9F9] px-[24px] py-[12px]">
            <Button
              onClick={() => {
                if (!cancelReason) {
                  changeToastDetails(STR_FAILURE, "Reason is required")
                  changeToastVisibility(true)
                  return
                }
                setCancelPopUpModal(true)
              }}
              size={"sm"}
              variant={"cancel"}
              className="px-[16px] py-[9px] border-[1px] border-[#DC2626] "
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancelReasonModal
