import React, { useState, useEffect } from "react"
import { GetAllCompensation } from "domain/useCase/Common/InfluencerCommon/GetAllCompensation"
import { CommonInfluencerRepositoryImpl } from "data/repository/Common/CommonInfluencerRepositoryImpl"
import { useAuth } from "core/context/auth"
import useHomeViewModel from "../Home/HomeViewModel"
import { InfluencerAPIDataSourceImpl } from "data/API/Common/InfluencerCommonAPIDataSourceImpl"

export function useInfluencerCompensationViewModel() {
  const { toast, influencer, changeToastVisibility } = useHomeViewModel()
  const GetAllRatesUseCase = new GetAllCompensation(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )
  const { auth } = useAuth()
  const [Rates, setAllRates] = React.useState<any>([])

  async function getAllRates(id: any) {
    const Rates = await GetAllRatesUseCase.invoke(auth, {
      email: influencer.email,
    })
    if (Rates) {
      setAllRates(Rates)
    }
  }
  const processAndSortCampaignData = (Rates: any) => {
    const sortedCampaignData: { campaign: string; dates: { date: string; value: string }[] }[] = []

    if (!Rates || !Rates.data) {
      return sortedCampaignData
    }

    const campaigns = Rates.data

    Object.keys(campaigns).forEach((campaign) => {
      const campaignDetails = campaigns[campaign]

      if (campaignDetails) {
        const sortedDates = Object.keys(campaignDetails)
          .sort((a, b) => {
            const dateA = new globalThis.Date(a.slice(3) + "-" + a.slice(0, 2))
            const dateB = new globalThis.Date(b.slice(3) + "-" + b.slice(0, 2))
            return dateA.getTime() - dateB.getTime()
          })
          .map((date) => ({
            date,
            value: campaignDetails[date],
          }))
        sortedCampaignData.push({
          campaign,
          dates: sortedDates,
        })
      }
    })

    return sortedCampaignData
  }

  return { Rates, toast, changeToastVisibility, processAndSortCampaignData, getAllRates, influencer }
}
