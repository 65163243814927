import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react"
import RoadmapCard from "./components/RoadmapCard"
import useNewViewModel, { SectionType } from "./NewViewModel"
import Tags from "core/components/v2/Tags"
import RemainderCard from "./components/RemainderCard"
import CourseCompletionCard from "./components/CourseCompletionCard"
import OnboardingManagerModal from "./components/OnboardingManagerModal"
import BasicModal from "./components/BasicModal"
import BasicCard from "./components/BasicCard"
import { useApp } from "core/context/app"
import DashboardLayoutV2 from "core/layouts/DashboardLayoutv2"
import OfferBanner from "presentation/Student/Payment/component/OfferBanner"
import CourseCompletionCelebration from "presentation/Student/Payment/component/CourseCompletionCelebration"
import { Head } from "core/components/seo"
import { isEmpty } from "core/utils/misc"
import AttendanceFailureModal from "../Course/components/AttendanceFailureModal"

const NewView = () => {
  const {
    loading,
    notificationDetails,
    selectedTag,
    Onboarding,
    remainder,
    onboardingTeam,
    viewOnboardingManager,
    viewPrerequisite,
    viewMeetAndGreet,
    showCelebration,
    setShowCelebration,
    MeetAndGreetDetails,
    prerequisiteDetails,
    roadMap,
    setWaitingTime,
    colorCode,
    roadmapDetails,
    roadmapRef,
    scratchCardDetails,
    fetchScratchCardDetails,
    setShortcutTabNotification,
    handleScroll,
    handleRemainderCardClick,
    fetchRoadmapDetails,
    fetchMeetAndGreetDetails,
    handleShowCelebration,
    isEL,
    fetchPrerequisiteDetails,
    fetchRemainder: fetchReminder,
    handleViewDetailsClick,
    handleMeetAndGreetModalClose,
    handleOnboardingModalClose,
    handlePreRequisiteModalClose,
    handleSelectedTagBtnClick,
    getOnboardingTeamDetails,
    setSelectedTag,
    setLoading,
    waitingTime,
    attendanceFailure,
    setAttendanceFailure,
    attendanceFailureCount,
  } = useNewViewModel()

  const location = useLocation()
  const { student } = useApp()
  const isAccelerator = student?.batch?.includes("Accelerator") && !student?.batch?.includes("KI-Accelerator")
  const isKCE = student?.batch?.includes("KCE") || student?.batch?.includes("KI-Accelerator")
  const searchParams = new URLSearchParams(location.search)
  const stepParam = searchParams.get("step")
  const cardRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const [remainingTime, setRemainingTime] = useState({ hours: 0, minutes: 0, seconds: 0 })
  const [expired, setExpired] = useState(true)
  // const [showCelebration, setShowCelebration] = useState(true)
  // isEL === true ? Onboarding.filter((item: any) => item[0] !== "Course Payment") :
  const onboarding = Onboarding
  useEffect(() => {
    !isAccelerator && fetchReminder()

    fetchRoadmapDetails()

    getOnboardingTeamDetails()

    !isAccelerator && fetchMeetAndGreetDetails()

    fetchPrerequisiteDetails()
    fetchScratchCardDetails()
  }, [])

  useEffect(() => {
    if (!isEmpty(remainder)) {
      if (!selectedTag) {
        const typeList = Object.keys(remainder) as SectionType[]
        if (typeList[0] === "Onboarding Meet") {
          // handle special case for Onboarding Meeting
          if ("expired" in remainder[typeList[0]] && !remainder[typeList[0]].expired) {
            // if meeting is still not expired
            setSelectedTag(typeList[0])
          } else if (typeList.length > 1) {
            // take another type from list
            setSelectedTag(typeList[1])
          }
        } else {
          setSelectedTag(typeList[0])
        }
      }

      setLoading(false)
    }
  }, [remainder])

  useEffect(() => {
    if (stepParam && cardRefs.current[`Step ${stepParam}`]) {
      const element = cardRefs.current[`Step ${stepParam}`]

      if (element) {
        element.classList.remove("border-[0.5px]", "border-[#D3D3D3]")
        element.classList.add("border-red-600", "border-2")
        element.scrollIntoView({ behavior: "smooth" })
        element.focus()

        setTimeout(() => {
          const buttonRef = element.querySelector("button")
          if (buttonRef) buttonRef.click()
        }, 500)

        if (stepParam === "4") {
          setTimeout(() => {
            element.classList.remove("border-red-600", "border-2")
            element.classList.add("border-[0.5px]", "border-[#D3D3D3]")
          }, 3000)
        }
      }
    }
  }, [stepParam])
  useEffect(() => {
    localStorage.setItem("attendanceFailureCount", attendanceFailureCount.toString())

    if (attendanceFailureCount >= 3) {
      window.open(remainder?.Class?.link)
    }
  }, [attendanceFailureCount])
  useEffect(() => {
    if (waitingTime > 0) {
      const timer = setInterval(() => {
        setWaitingTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => clearInterval(timer) // Cleanup function
    }
  }, [waitingTime])
  useEffect(() => {
    if (notificationDetails.length > 0) {
      notificationDetails.forEach((data: any) => {
        setShortcutTabNotification((prev: any) => ({
          ...prev,
          [data.type]: data.isRead,
        }))
      })
    }
  }, [notificationDetails])

  useEffect(() => {
    if (!viewMeetAndGreet && !viewOnboardingManager && !viewPrerequisite) {
      if (stepParam && cardRefs.current[`Step ${stepParam}`]) {
        const element = cardRefs.current[`Step ${stepParam}`]
        if (element) {
          element.classList.remove("border-red-600", "border-2")
          element.classList.add("border-[0.5px]", "border-[#D3D3D3]")
        }
      }
    }
  }, [viewMeetAndGreet, viewOnboardingManager, viewPrerequisite])

  useEffect(() => {
    const updateRemainingTime = () => {
      if (!student?.is_payment_done && student?.walk_through_date) {
        const walk_through_date = student?.walk_through_date
        const walk_through_date_in_millis = walk_through_date * 1000
        const current_date = new Date()
        const future_date_in_millis = walk_through_date_in_millis + 66.5 * 60 * 60 * 1000

        if (current_date.getTime() > future_date_in_millis) {
          setExpired(true)
          setRemainingTime({ hours: 0, minutes: 0, seconds: 0 })
          return true
        }

        if (expired) setExpired(false)
        const remaining_time_in_millis = future_date_in_millis - current_date.getTime()
        const hours = Math.floor(remaining_time_in_millis / (1000 * 60 * 60))
        const minutes = Math.floor((remaining_time_in_millis % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((remaining_time_in_millis % (1000 * 60)) / 1000)
        setRemainingTime({ hours, minutes, seconds })
        return false
      }
    }

    updateRemainingTime()

    const intervalId = setInterval(() => {
      if (updateRemainingTime()) {
        clearInterval(intervalId)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [student?.email])

  useEffect(() => {
    const celebrationShown = localStorage.getItem("courseCelebrationShown")
    if (student?.course_completed && !celebrationShown) {
      setShowCelebration(true)
    }
  }, [student?.course_completed])
  return (
    <DashboardLayoutV2>
      <AttendanceFailureModal
        waitingTime={waitingTime}
        isOpen={attendanceFailure}
        onClose={() => setAttendanceFailure(false)}
        sessionName={remainder?.Class?.name}
        handleNewSubmitFeedback={() => handleRemainderCardClick("Class", remainder?.Class?.id)}
        // onMenteeAbsent={() => {
        //   setIsOpen("absent")
        //   setIndexToBeMarkedAbsent(mentorWaiting?.id)
        // }}
        // onTakenElseWhere={() => {
        //   markTakenElsewhere(mentorWaiting?.id)
        //   // setIsOpen("takenElseWhere")
        //   // setIndexToBeMarkedTakenElsewhere(mentorWaiting?.id)
        // }}
      />
      <Head title="Home" />

      <div className="w-full rounded">
        <div className="flex w-full flex-col gap-6 p-6">
          {!student?.is_payment_done && student?.walk_through_date && !expired && (
            <OfferBanner remaining_time={remainingTime} scratchCardDetails={scratchCardDetails} />
          )}

          {showCelebration && <CourseCompletionCelebration student={student} onClose={handleShowCelebration} />}
          {!isKCE && (
            <div className="relative mb-16 w-full">
              <div className="custom-gradient flex min-h-[125px] w-full items-start justify-between rounded-[8px] border-[0.2px] border-[#2C689F] p-4 shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
                <div>
                  <p className="text-base font-semibold text-white">Bosscoder Roadmap</p>
                  <p className="text-sm font-[300] text-white">
                    This Roadmap outlines the Timeline of your Journey with Bosscoder, leading you to your dream Job
                    offer.
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <button className="h-6 w-6 rounded bg-white p-1" onClick={() => handleScroll(-150)}>
                    <ChevronLeftIcon size={16} />
                  </button>
                  <button className="h-6 w-6 rounded bg-white p-1" onClick={() => handleScroll(150)}>
                    <ChevronRightIcon size={16} />
                  </button>
                </div>
              </div>
              <div
                className="hide-scrollbar absolute top-[78px] left-4 z-0 flex min-h-[100px] w-full max-w-[calc(100%-32px)] overflow-x-auto rounded-[8px] bg-white shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]"
                ref={roadmapRef}
              >
                {Object.keys(roadmapDetails).length > 0 &&
                  roadMap?.map((item: any) => (
                    <RoadmapCard
                      key={item}
                      type={item}
                      data={roadmapDetails[item]}
                      roadMap={roadmapDetails}
                      batch={student?.batch}
                    />
                  ))}
              </div>
            </div>
          )}
          <div className="space-y-3 mt-2">
            <div className="flex justify-start space-x-1">
              {Object.keys(remainder)?.map((type: any) => {
                if (type in remainder && Object.keys(remainder[type])?.length > 0) {
                  if (type === "Onboarding Meet" && remainder[type].hasOwnProperty("expired")) {
                    return null
                  }
                  return (
                    <Tags key={type} selected={type === selectedTag} onClick={() => handleSelectedTagBtnClick(type)}>
                      {type}
                    </Tags>
                  )
                }
              })}
            </div>
            {selectedTag && (
              <RemainderCard
                selectedTag={selectedTag}
                data={remainder}
                loading={loading}
                color={colorCode[selectedTag]}
                handleRemainderCardClick={handleRemainderCardClick}
              />
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <div className="space-y-1">
              <p className="text-base font-semibold text-[#333]">Steps of Onboarding</p>
              <p className="text-sm font-normal text-[#646464]">
                Ensure all Onboarding Steps are completed before the Start of your Batch.
              </p>
            </div>
            <div className="gray-scrollbar flex w-full gap-4 overflow-x-auto bg-white pb-2">
              {onboarding.map(
                (key: any, index: any) =>
                  key && (
                    <BasicCard
                      step={`Step ${index + 1}`}
                      key={index}
                      checked={true}
                      ref={(el) => (cardRefs.current[`Step ${index + 1}`] = el)}
                      heading={key[0]}
                      description={key[1]}
                      btnType={key[2]}
                      handleViewDetailsClick={handleViewDetailsClick}
                      disabled={true}
                    />
                  )
              )}
            </div>
          </div>
          {viewOnboardingManager && (
            <OnboardingManagerModal onboardingTeam={onboardingTeam} handleCrossBtnClick={handleOnboardingModalClose} />
          )}
          {viewPrerequisite && (
            <BasicModal
              ModalTitle="Prerequisite"
              data={prerequisiteDetails}
              handleModalClose={handlePreRequisiteModalClose}
              type={"prerequisite"}
            />
          )}
          {viewMeetAndGreet && (
            <BasicModal
              ModalTitle="Meet and Greet"
              data={MeetAndGreetDetails}
              handleModalClose={handleMeetAndGreetModalClose}
              type={"meet_and_greet"}
            />
          )}
          <CourseCompletionCard
            courseCompleted={student?.course_completed}
            course_name={student?.course_name}
            certificate_url={student?.certificate_url}
          />
        </div>
      </div>
    </DashboardLayoutV2>
  )
}

export default NewView
