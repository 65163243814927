import React from "react"
import { useParams } from "react-router-dom"
import ConditionalLoader from "core/components/ConditionalLoader"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import ConfirmationPopUp from "../Components/ConfirmationPopup"
import useViewModel from "./ViewModel"
import QuestionView from "../Components/QuestionView"
import SolutionView from "../Components/SolutionView"
import ResponseView from "../Components/ResponseView"
import SubmitButton from "../Components/SubmitButton"
import { cn } from "core/lib/utils"
import SubmitConfirmationPopup from "../Components/SubmitConfirmationPopup"

export default function CaseStudyView() {
  const {
    toast,
    changeToastVisibility,
    pageLoading,
    loading,
    question,
    isQuestionSubmitted,
    fetchQuestion,
    fetchSubmission,
    TABS,
    activeTab,
    viewSolution,
    isSolutionViewed,
    confirmViewSoluton,
    denyViewSolution,
    handelTabChange,
    optionSelected,
    handleOptionChange,
    handleSolutionSubmit,
    solution,
    handleSolutionChange,
    handleSubmitBtnClick,
    submitConfirmationPopup,
    handleCancelBtnClick,
    handleAddToQuestionBank,
  } = useViewModel()

  const { id } = useParams()
  React.useEffect(() => {
    fetchQuestion(id!)
  }, [])

  React.useEffect(() => {
    fetchSubmission(id!)
  }, [question])

  React.useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })

  const [activeResponseTab, setActiveResponseTab] = React.useState<number>(0)

  return (
    <div className="flex w-full h-screen bg-new-editor-dark-700">
      {!isSolutionViewed[activeResponseTab] && viewSolution[activeResponseTab] && (
        <ConfirmationPopUp
          confirmViewSoluton={confirmViewSoluton}
          denyViewSolution={denyViewSolution}
          activeResponseTab={activeResponseTab}
        />
      )}
      <ConditionalLoader isLoading={pageLoading} loader={<Loader />}>
        <div className="flex flex-1 flex-col border-r-2 border-new-neutral-light overflow-y-auto text-white gap-4 p-8">
          <div className="m-[-32px] mb-0 flex divide-x bg-new-editor-dark-500">
            {TABS.map((btn: any, index: number) => (
              <button
                key={index}
                className={cn(
                  "w-full flex-1 select-none rounded-sm py-2 text-lg leading-[22px]",
                  activeTab === index ? "bg-new-editor-dark-700 text-white" : "text-new-neutral-light"
                )}
                onClick={() => handelTabChange(index, activeResponseTab)}
              >
                {btn}
              </button>
            ))}
          </div>
          <button
            className="ml-auto h-[31.844px] min-w-[80px] px-4 py-[9px] gap-[13px] bg-[#242424] text-white text-[14px] font-[Inter] font-medium rounded-[4px] cursor-pointer"
            onClick={handleAddToQuestionBank}
          >
            + Add to Question Bank
          </button>
          {activeTab === 0 ? (
            <QuestionView
              question={question}
              activeResposeTab={activeResponseTab}
              setActiveResposeTab={setActiveResponseTab}
            />
          ) : (
            <SolutionView question={question} activeResposeTab={activeResponseTab} />
          )}
        </div>

        <div className="relative flex-1 flex flex-col text-white overflow-y-auto">
          {question?.response && (
            <ResponseView
              activeResposeTab={activeResponseTab}
              response={question.response}
              optionSelected={optionSelected}
              handleOptionChange={handleOptionChange}
              solution={solution}
              handleSolutionChange={handleSolutionChange}
            />
          )}
          <div className="w-full mt-auto sticky bottom-0">
            <SubmitButton handleSolutionSubmit={handleSubmitBtnClick} isQuestionSubmitted={isQuestionSubmitted} />
          </div>
          {submitConfirmationPopup && (
            <SubmitConfirmationPopup
              message={
                "Please ensure that all questions are answered before submitting. Changes are not allowed after submission."
              }
              confirmAction={() => handleSolutionSubmit(id!)}
              cancelAction={handleCancelBtnClick}
              isQuestionSubmitted={isQuestionSubmitted}
            />
          )}
        </div>
      </ConditionalLoader>
      <Toast data={toast} onClick={changeToastVisibility} />
    </div>
  )
}
