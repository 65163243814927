import AssessmentDataSource from "data/dataSource/Student/AssessmentDataSource"
import ContestRepository from "domain/repository/Student/ContestRepository"
import { Auth } from "domain/model/Auth"

export default class ContestRepositoryImpl implements ContestRepository {
  private dataSource: AssessmentDataSource

  constructor(dataSource: AssessmentDataSource) {
    this.dataSource = dataSource
  }

  async getAllContest(auth: Auth): Promise<any> {
    return await this.dataSource.getAllContest(auth)
  }

  async isStudentAttended(auth: Auth, contestId: any): Promise<any> {
    return await this.dataSource.isStudentAttended(auth, contestId)
  }

  async contestResult(auth: Auth, contestId: any): Promise<any> {
    return await this.dataSource.contestResult(auth, contestId)
  }
  async getAllExams(auth: Auth): Promise<any> {
    return await this.dataSource.getAllExams(auth)
  }
  async getExamResult(auth: Auth, contest_id: string): Promise<any> {
    return await this.dataSource.getExamResult(auth, contest_id)
  }
  async getBatchwiseExams(auth: Auth, email: string, batch_id: string) {
    return this.dataSource.getBatchwiseExams(auth, email, batch_id)
  }
  async checkBatchHasExamTemplate(auth: Auth, batch_id: string) {
    return this.dataSource.checkBatchHasExamTemplate(auth, batch_id)
  }
  async getExamwiseLeaderboard(auth: Auth, examId: string) {
    return this.dataSource.getExamwiseLeaderboard(auth, examId)
  }
  async getUpcomingExams(auth: Auth, page: number, limit: number): Promise<any> {
    return await this.dataSource.getUpcomingExams(auth, page, limit)
  }
}
