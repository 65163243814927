import { Auth } from "domain/model/Auth"
import { Server } from "core/utils/axios"
import { API_ADMIN, API_MENTOR } from "core/constants/strings"
import pn from "core/utils/pn"
import { MentorNotificationsDataSource } from "data/dataSource/Mentor/MentorNotificationsDataSource"

const server = new Server()
export default class MentorNotificationsAPIDataSourceImpl implements MentorNotificationsDataSource {
  async getMentorNotifications(auth: Auth): Promise<any> {
    const response = await server.get(pn(API_MENTOR, "get_mentor_notifications"), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async removeMentorNotifications(auth: Auth, id: string): Promise<any> {
    const response = await server.post(
      pn(API_MENTOR, "remove_mentor_notifications"),
      {
        id,
      },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async openSlotNotification(auth: Auth): Promise<any> {
    const response = await server.get(pn(API_MENTOR, "open_slot_notification"), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }
}
