import { Auth } from "domain/model/Auth"
import GetLiveSessionDetails from "domain/model/GetLiveSessionDuration"
import SessionStatusRepository from "domain/repository/Student/UpdateSessionStatusRepository"

export interface UpdateSessionStatusUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UpdateSessionStatus implements UpdateSessionStatusUseCase {
  private repository: SessionStatusRepository

  constructor(repository: SessionStatusRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: GetLiveSessionDetails): Promise<any> {
    return this.repository.updateSessionStatus(auth, details)
  }
}
