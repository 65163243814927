import React, { useEffect } from "react"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { Head } from "core/components/seo"
import { useSearchParams, Link } from "react-router-dom"
import useQuestionBankViewModel from "./QuestionBankViewModel"
import ButtonGroup from "core/components/v2/ButtonGroup"
import { STR_ALL_QUESTION_TYPES, STR_MCQ, STR_CODING, STR_SUBJECTIVE, STR_CASE_STUDY } from "core/constants/strings"

export default function QuestionBankView() {
  const { loading, questionBank, fetchQuestionBankData, activeButtonType, changeActiveButtonType, topicList } =
    useQuestionBankViewModel()
  const [searchParams] = useSearchParams()
  const [selectedTopic, setSelectedTopic] = React.useState("")
  const [topic, setTopic] = React.useState("")
  const [questions, setQuestions] = React.useState<any[]>([])

  //const topics = ["Select Topic", "Data Science", "Machine Learning", "Software Engineering", "Web Development", "Cyber Security"];
  //const topics=["Select Topic", "Data Science", "SQL", "Graph", "Recursion","Array","Greedy","Math","Tree","Sorting","Searching","Matrix","Hash Table","Stack","Linear Regression","Coordinate Geometry"]

  useEffect(() => {
    fetchQuestionBankData()
  }, [])

  useEffect(() => {
    if (questionBank) {
      setQuestions(questionBank)
    }
  }, [questionBank])

  useEffect(() => {
    let filteredQuestions = questionBank

    // Filter by topic (if topic is defined)
    if (topic) {
      filteredQuestions = filteredQuestions.filter((q: any) =>
        q.problem_name.toLowerCase().includes(topic.toLowerCase())
      )
    }

    // Filter by selectedTopic (only if it's not "Select Topic")
    if (selectedTopic && selectedTopic != "Select Topic") {
      filteredQuestions = filteredQuestions.filter((q: any) => q.topics.some((t: string) => t.includes(selectedTopic)))
    }

    // Filter based on activeButtonType
    if (activeButtonType === 1) {
      filteredQuestions = filteredQuestions.filter((q: any) => q.problem_type === "MCQ")
    } else if (activeButtonType === 2) {
      filteredQuestions = filteredQuestions.filter((q: any) => q.problem_type === "Code")
    } else if (activeButtonType === 3) {
      filteredQuestions = filteredQuestions.filter((q: any) => q.problem_type === "Subjective")
    } else if (activeButtonType === 4) {
      filteredQuestions = filteredQuestions.filter((q: any) => q.problem_type === "Case Study")
    }

    setQuestions(filteredQuestions)
  }, [topic, selectedTopic, activeButtonType, questionBank])

  console.log("This is topic list", topicList)

  return (
    <DashboardLayoutv2>
      <Head title="Question Bank" />
      <div className="p-[24px]">
        <div className="text-[16px] font-[Inter] font-semibold leading-normal text-[#333]">Question Bank</div>
        <div className="text-[14px] font-[Inter] font-normal leading-normal text-[#646464]">
          All the exams have been kept in pace with the completion of the course.
        </div>
      </div>
      <div className="">
        <div className="flex items-center space-x-2 p-[20px]">
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            placeholder="Enter Question Name"
            className="p-2 border border-gray-300 rounded-md w-64"
          />
          <select
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
            className="p-2 border border-gray-300 rounded-md w-64"
          >
            <option value="Select Topic" disabled>
              Select Topic
            </option>
            {topicList.map((topic, index) => (
              <option key={index} value={topic}>
                {topic}
              </option>
            ))}
          </select>
          <button
            onClick={() => {
              setTopic("")
              setSelectedTopic("Select Topic")
            }}
            className="p-2 border border-gray-300 rounded-md bg-white hover:bg-gray-200"
          >
            Clear All Filters
          </button>
        </div>

        <div className="ml-[18px]">
          <ButtonGroup
            className="inline-flex"
            buttons={[STR_ALL_QUESTION_TYPES, STR_MCQ, STR_CODING, STR_SUBJECTIVE, STR_CASE_STUDY]}
            active={activeButtonType}
            onChange={changeActiveButtonType}
          />
        </div>
      </div>
      <div className="overflow-x-auto p-4">
        <table className="w-full border border-gray-300 rounded-xl md overflow-hidden">
          <thead className="bg-gray-800 text-white rounded-t-xl">
            <tr>
              <th className="p-3 text-left">Question Name</th>
              <th className="p-3 text-left">Topics</th>
              <th className="p-3 text-left">Problem Type</th>
              <th className="p-3 text-left">Problem Status</th>
              <th className="p-3 text-left">Link to Start</th>
            </tr>
          </thead>
          <tbody className="bg-white rounded-b-xl">
            {questions.map((question: any, index: any) => {
              const lastRoute = question?.problem_link?.split("/")?.at(-1)
              const secondLastRoute = question?.problem_link?.split("/")?.at(-2)
              const problemType = secondLastRoute === "codeEditor" ? "editor" : secondLastRoute
              const problemLink = `/${problemType}/${lastRoute}`
              const topicsText =
                question?.topics.length > 3
                  ? question.topics.slice(0, 3).join(", ") + "..."
                  : question.topics.join(", ")

              return (
                <tr
                  key={index}
                  className={`border-b hover:bg-gray-100 ${index === questions.length - 1 ? "rounded-b-xl" : ""}`}
                >
                  <td className="p-3">{question.problem_name}</td>
                  <td className="p-3 relative group">
                    <span>{topicsText}</span>
                    {question?.topics.length > 3 && (
                      <div className="absolute left-0 mt-2 hidden w-max p-2 bg-gray-800 text-white text-sm rounded-md shadow-md group-hover:block">
                        {question?.topics.join(", ")}
                      </div>
                    )}
                  </td>
                  <td className="p-3">{question.problem_type}</td>
                  <td className="p-3">{question.status ? "Completed" : "Pending"}</td>
                  <td className="p-3">
                    <Link
                      to={problemLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex w-[88px] px-4 py-[5px] justify-center items-center gap-[13px] border border-gray-300 bg-white rounded-md text-gray-700 hover:bg-gray-100"
                    >
                      Start
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </DashboardLayoutv2>
  )
}
