import { Auth } from "domain/model/Auth"
import { InfluencerProfile } from "domain/model/InfluencerDashboard"
import { CommonInfluencerRepository } from "domain/repository/Common/CommonInfluencerRepository"

export interface GetInfluencerProfileUseCase {
  invoke(profile: InfluencerProfile, auth: Auth): Promise<any>
}

export class GetInfluencerProfile implements GetInfluencerProfileUseCase {
  private repository: CommonInfluencerRepository

  constructor(repository: CommonInfluencerRepository) {
    this.repository = repository
  }

  async invoke(profile: InfluencerProfile, auth: Auth): Promise<any> {
    return await this.repository.GetInfluencerProfile(profile, auth)
  }
}
