import { cn } from "core/lib/utils"
import React from "react"

interface Props {
  currentPage: number
  totalPages: number
  onPageChange: (pageNumber: number) => void
}

const Pagination: React.FC<Props> = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <>
      <div className="flex gap-2 w-[inherit] overflow-x-auto">
        {[...Array(totalPages)].map((_, i) => (
          <button
            key={i}
            className={cn(
              "border border-new-accent shrink-0 inline-flex items-center justify-center min-w-[16px] h-6 p-2 hover:bg-new-accent hover:text-new-solid-white",
              currentPage === i + 1 ? "bg-new-accent text-new-solid-white" : "text-new-accent"
            )}
            onClick={() => onPageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </>
  )
}

export default Pagination
