import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_STUDENT, STR_SAVE_QUESTION, STR_GET_QUESTIONS } from "core/constants/strings"
import QuestionBankDataSource from "data/dataSource/Student/QuestionBankDataSource"
import { Auth } from "domain/model/Auth"

const server = new Server()

export class QuestionBankAPIDataSourceImpl implements QuestionBankDataSource {
  async saveQuestion(
    auth: Auth,
    problem_name: string,
    problem_id: string,
    problem_type: string,
    problem_link: string,
    status: boolean,
    topics: string[]
  ): Promise<any> {
    try {
      console.log("This is auth token", auth.id_token)
      const response = await server.post(
        pn(API_STUDENT, STR_SAVE_QUESTION),
        {
          problem_name,
          problem_id,
          problem_type,
          problem_link,
          status,
          topics,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      const data = response.data

      console.log("This is response", response)

      console.log("This is response data", response.data)

      if (data.message === "Question updated successfully" || data.message === "New question added successfully") {
        console.log("Save question response data:", data.message)
        return true
      } else {
        console.log("API response was not successful:", data)
      }
    } catch (error) {
      console.error("Error in saving question in API file:", error)
      throw error
    }
  }

  async getQuestionBank(auth: Auth): Promise<any> {
    try {
      console.log("Executing getQuestionBank with auth token:", auth.id_token)
      const response = await server.get(pn(API_STUDENT, STR_GET_QUESTIONS), {
        Authorization: `Bearer ${auth.id_token}`,
      })

      const data = response.data

      console.log("getQuestionBank response:", response)
      console.log("getQuestionBank response data:", data)

      if (data.message === "Questions fetched successfully") {
        console.log("Fetched questions:", data.data)
        return data.data // Return the list of questions
      } else {
        console.log("Failed to fetch questions:", data)
        return []
      }
    } catch (error) {
      console.error("Error in getQuestionBank:", error)
      throw error
    }
  }
}
