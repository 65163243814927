import { ShortRepository } from "domain/repository/Student/ShortRepository"
import { Auth } from "domain/model/Auth"

export interface ReadShortUseCase {
  invoke(auth: Auth, sessionID: string, chapterName: string, shortTitle: string): Promise<any>
}

export class ReadShort implements ReadShortUseCase {
  private repository: ShortRepository

  constructor(repository: ShortRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, sessionID: string, chapterName: string, shortTitle: string): Promise<any> {
    return this.repository.readShort(auth, sessionID, chapterName, shortTitle)
  }
}
