import React from "react"
import GenerateInvoiceViewModel from "../GenerateInvoiceViewModel"
import Spinner from "core/components/Spinner"
import { format } from "date-fns"
import { isEmpty } from "core/utils/misc"
import Button from "core/components/Button"
import Model from "core/components/Model"
import { useAuth } from "core/context/auth"
import UpdateSession from "./UpdateSession"

export default function SessionsCard({
  updateClassBool,
  setUpdateClassBool,
  data,
  index,
  onChange,
  toast,
  changeToastDetails,
  changeToastVisibility,
  sessions,
}: any) {
  const { auth } = useAuth()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const statusColor = data?.status.includes("Cancelled") ? "red" : "green"
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  return (
    <div>
      <div className="flex justify-between px-6 py-4 text-new-neutral-dark shadow">
        <div className="flex items-end gap-x-6">
          <div className="space-y-1">
            <h4 className="mb-1">{data.chapter_name}</h4>
            <div className="flex gap-x-4">
              {!isEmpty(data?.instructor) ? (
                <p className="">{data.instructor_name != "" ? data.instructor_name : "No Instructor Found"}</p>
              ) : (
                <p className="italic">No Instructor Found</p>
              )}
              {!isEmpty(data?.batches) ? (
                data.batches.map((item: "string", ky: number) => <p className="">{item}</p>)
              ) : (
                <p className="italic">No Instructor Found</p>
              )}
            </div>

            <p className="">{format(parseInt(data.session_timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}</p>
          </div>
          <div className="inline-flex items-baseline gap-x-1"></div>
        </div>
        <div className="flex gap-x-12">
          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p
              className={
                statusColor === "green" ? "text-green-600" : statusColor !== "red" ? "text-yellow-500" : "text-red-500"
              }
            >
              {data.status ? data.status : "Scheduled Class"}
            </p>
            <div className="flex items-center">
              <h3
                className={`
                  ${
                    data.class_rating == null
                      ? "text-gray-500"
                      : data.class_rating < 2
                        ? "text-red-500"
                        : data.class_rating < 4
                          ? "text-yellow-500"
                          : "text-green-500"
                  } text-lg font-semibold
                `}
              >
                {data.class_rating == null
                  ? "N/A"
                  : `${Number.isInteger(data.class_rating) ? data.class_rating : Number(data.class_rating).toFixed(2)}/5`}
              </h3>
              <div className="flex gap-x-2 ml-2">
                <p className="text-sm">Rating</p>
              </div>
            </div>
          </div>
          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p className="">
              <strong>AD:</strong>
              {data.actual_duration ? data.actual_duration : "0 min"}
            </p>
          </div>
          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p className="">
              <strong>CD:</strong>
              {data.considered_duration ? data.considered_duration : "0 min"}
            </p>
            <Button outlined className="w-fit" onClick={toggleModal}>
              Update
            </Button>
          </div>
        </div>
      </div>

      <Model isOpen={isModalOpen} onClose={toggleModal} width={"max-w-3xl"}>
        <UpdateSession
          toast={toast}
          data={data}
          changeToastDetails={changeToastDetails}
          changeToastVisibility={changeToastVisibility}
          updateClassBool={updateClassBool}
          setUpdateClassBool={setUpdateClassBool}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          index={index}
          onChange={onChange}
          session_id={data.session_id}
          instructor_name={data.instructor_name}
          rating={data.class_rating}
          class_name={data.chapter_name}
          session_date={format(parseInt(data.session_timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}
        />
      </Model>
    </div>
  )
}
