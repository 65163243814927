import { Auth } from "domain/model/Auth"
import { EditorRepository } from "domain/repository/Student/EditorRepository"

export interface GetExamQuestionSubmissionsUseCase {
  invoke(auth: Auth, topic: string, exam_id?: string): Promise<any>
}

export class GetExamQuestionSubmissions implements GetExamQuestionSubmissionsUseCase {
  private repository: EditorRepository

  constructor(repository: EditorRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, topic: string, exam_id: string, batch_id?: string) {
    return this.repository.getExamQuestionSubmissions(auth, topic, exam_id, batch_id)
  }
}
