import { useAuth } from "core/context/auth"
import InstructorAvailabilityAPIDataSourceImpl from "data/API/Mentor/InstructorAvailabilityAPIDataSourceImpl"
import { InstructorAvailabilityRepositoryImpl } from "data/repository/Mentor/InstructorAvailabilityRepositoryImpl"
import { GetInstructorAvailability } from "domain/useCase/Mentor/InstructorAvailability/GetInstructorAvailability"
import useToast from "core/hooks/useToast"
import { UpdateInstructorAvailability } from "domain/useCase/Mentor/InstructorAvailability/UpdateInstructorAvailability"
import { useState } from "react"
import InstructorAvailabilityAPIDataSourceImplComman from "data/API/Admin/InstructorAvailabilityAPIDataSourceImpl"
import { InstructorAvailabilityRepositoryImpl as InstructorAvailabilityRepositoryImplComman } from "data/repository/Admin/InstructorAvailabilityRepositoryImpl"
import { GetToggleInstructorAvailability } from "domain/useCase/Admin/InstructorAvailability/GetToggleInstructorAvailability"

import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

interface DateInfo {
  day: string
  date: string
}

const InstructorAvailabilityViewModal = () => {
  const [availabilityDates, setAvailabilityDates] = useState<string[]>([])
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [toggleStatus, setToggleStatus] = useState<boolean>(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()

  const updateInstructorAvailabilityUseCase = new UpdateInstructorAvailability(
    new InstructorAvailabilityRepositoryImpl(new InstructorAvailabilityAPIDataSourceImpl())
  )

  const getInstructorAvailabilityUseCase = new GetInstructorAvailability(
    new InstructorAvailabilityRepositoryImpl(new InstructorAvailabilityAPIDataSourceImpl())
  )

  const getToggleInstructorAvailabilityUseCase = new GetToggleInstructorAvailability(
    new InstructorAvailabilityRepositoryImplComman(new InstructorAvailabilityAPIDataSourceImplComman())
  )

  const generateDates = (): DateInfo[] => {
    const dates: DateInfo[] = []
    const today = new Date()
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    for (let i = 0; i < 15; i++) {
      const nextDate = new Date(today)
      nextDate.setDate(today.getDate() + i)

      const day = daysOfWeek[nextDate.getDay()]
      const formattedDate = `${String(nextDate.getDate()).padStart(2, "0")}-${String(nextDate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(nextDate.getFullYear()).slice(2)}`

      dates.push({ day, date: formattedDate })
    }
    return dates
  }

  const dates = generateDates()

  const isDateAvailable = (date: string): boolean => {
    return availabilityDates.includes(date)
  }

  const handleCheckboxChange = async (date: string): Promise<void> => {
    setAvailabilityDates((prev) => (prev.includes(date) ? prev.filter((d) => d !== date) : [...prev, date]))

    setSelectedDates((prev) => (prev.includes(date) ? prev.filter((d) => d !== date) : [...prev, date]))
  }

  const updateInstructorAvailabilityHandler = async (): Promise<void> => {
    setLoading(true)

    try {
      const res = await updateInstructorAvailabilityUseCase.invoke(auth, {
        date: JSON.stringify(selectedDates),
      })
      setSelectedDates([])

      if (!res.success) {
        changeToastDetails(STR_FAILURE, "An error occurred while updating availability.")
        changeToastVisibility(true)
      } else {
        changeToastDetails(STR_SUCCESS, "Instructor Availability Updated Successfully.")
        changeToastVisibility(true)
      }
    } catch {
      changeToastDetails(STR_FAILURE, "An error occurred while updating availability.")
      changeToastVisibility(true)
      return
    }
    setLoading(false)
  }

  const getAllInstructorAvailability = async (): Promise<void> => {
    const res = await getInstructorAvailabilityUseCase.invoke(auth)
    setAvailabilityDates(res.data.lastDates)
  }

  const getToggleInstructorAvailability = async () => {
    const res = await getToggleInstructorAvailabilityUseCase.invoke(auth)
    setToggleStatus(Boolean(res?.data?.availability))
  }

  return {
    dates,
    loading,
    toast,
    toggleStatus,
    handleCheckboxChange,
    getAllInstructorAvailability,
    isDateAvailable,
    updateInstructorAvailabilityHandler,
    getToggleInstructorAvailability,
    changeToastVisibility,
  }
}

export default InstructorAvailabilityViewModal
