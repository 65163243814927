import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import DashboardLayout from "core/layouts/DashboardLayout"
import useCreateClassViewModel from "./CreateClassViewModel"
import { AddIcon, CrossIcon } from "core/constants/svgs"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import { useEffect } from "react"

export default function CreateClassView() {
  const {
    toast,
    level,
    selectBatch,
    enableOthersClass,
    othersClassName,
    selectHomeworkQuestions,
    selectPracticalQuestions,
    selectWarmupQuestions,
    selectAssigmentsQuestions,
    currentSelectAssignmentsQuestions,
    selectInstructor,
    batch,
    classLink,
    moduleClassesList,
    selectClassName,
    selectModuleId,
    selectSessionTimestamp,
    track,
    videoId,
    selectTrack,
    videoSecret,
    notesLink,
    notesSummaryLink,
    preRequisite,
    instructor,
    currentSelectHomeworkQuestions,
    currentSelectPracticalQuestions,
    currentSelectWarmupQuestions,
    currentSelectBatch,
    loading,
    moduleId,
    compensationStatus,
    otherModules,
    assignmentSolutionActivationTime,
    assignmentSolutionLink,
    assignmentCoins,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    firstReminderMessage,
    secondReminderMessage,
    setSessionDuration,
    setOtherSessionDuration,
    handleChangePreRequisite,
    handleSessionDuration,
    handleMasterClassType,
    handleOtherSessionDuration,
    handleChangeAssignmentCoins,
    handleChangeAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    handleOtherModules,
    setSelectBatch,
    setCurrentSelectBatch,
    handleSelectHomeworkChange,
    handleAssignmentChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    handleChangeSessionTimestamp,
    handleChangeTrack,
    handleChangeVideoId,
    handleChangeVideoSecreat,
    handleWarmupChange,
    handlePracticalChange,
    handleAddAssignmentQuestions,
    handleAddHomeworkQuestions,
    handleAddPracticalQuestions,
    handleAddWarmupQuestions,
    createClasses,
    changeToastVisibility,
    setSelectAssignmentsQuestions,
    setSelectHomeworkQuestions,
    setSelectPracticalQuestions,
    setSelectWarmupQuestions,
    handleOthersClass,
    handleChapterLevel,
    getBatches,
    getAllTracks,
    getInstructorsList,
    handleCompensationStatus,
    setFirstReminderMessage,
    setSecondReminderMessage,
  } = useCreateClassViewModel()

  const chapterLevel = ["Easy", "Medium", "Hard"]

  const compensationStatusArray = ["Hourly Class", "Module Based Class"]
  const DurationArray = [1, 1.5, 2, 2.5, 3, "other"]
  const masterClassTypeArray = ["Software Engineering", "Data Scientist", "Both"]

  useEffect(() => {
    getBatches()
    getAllTracks()
    getInstructorsList()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="w-full space-y-1">
          <h2 className="text-new-accent">Create Class</h2>
          <p>Creating class for students</p>
        </div>
        <div className="round-2 mx-auto flex w-full max-w-[600px] flex-col gap-4 p-6 shadow">
          <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
            <ConditionalLoader
              isLoading={isEmpty(selectBatch)}
              loader={<em className="text-xs text-new-neutral-light">No batches selected</em>}
            >
              {selectBatch.map((batch: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {batch}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => {
                      setSelectBatch(removeAtIndex(selectBatch, i))
                      setCurrentSelectBatch("")
                    }}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <StandaredSelect
            required
            options={batch}
            placeholder="Select Batch"
            onChange={handleBatchChange}
            value={currentSelectBatch}
          />

          <StandaredSelect
            required
            options={track}
            placeholder="Track"
            onChange={handleChangeTrack}
            value={selectTrack}
          />
          {selectTrack === "master_class" && (
            <StandaredSelect
              required
              options={masterClassTypeArray}
              placeholder="Master Class Type"
              onChange={handleMasterClassType}
              value={masterClassType}
            />
          )}
          <StandaredSelect
            required
            options={moduleId}
            placeholder="Module ID"
            onChange={handleChangeModuleId}
            value={selectModuleId}
          />
          {selectModuleId === "other" && (
            <Input
              type={STR_TEXT_FIELD}
              value={otherModules}
              onChange={handleOtherModules}
              placeholder="Other Module"
            />
          )}
          <select
            className="w-full cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleChangeChapterName}
            value={selectClassName}
          >
            {!selectClassName && (
              <option value="" className="bg-neutral-50 text-neutral-500">
                Class Name
              </option>
            )}
            {moduleClassesList?.map((header: any, i: number) => (
              <option key={i} className="bg-neutral-50 text-neutral-500" value={header.topic}>
                {header.topic}
              </option>
            ))}
            <option value="others">others</option>
          </select>
          {enableOthersClass && (
            <Input
              type={STR_TEXT_FIELD}
              value={othersClassName}
              onChange={handleOthersClass}
              placeholder="Class Name"
            />
          )}
          <StandaredSelect
            required
            options={chapterLevel}
            placeholder="Level"
            onChange={handleChapterLevel}
            value={level}
          />
          <Input type={STR_TEXT_FIELD} value={classLink} onChange={handleChangeClassLink} placeholder="Class Link" />
          <StandaredSelect
            required
            options={compensationStatusArray}
            value={compensationStatus}
            onChange={handleCompensationStatus}
            placeholder="Compensation Status"
          />
          <select
            required
            className="w-full cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleChangeInstructor}
            value={selectInstructor}
          >
            <option value="">Instructor</option>
            {instructor?.map((instructor: any, i: number) => (
              <option key={i} value={instructor?.email}>
                {instructor?.name}
              </option>
            ))}
          </select>
          <ul className="flex w-full flex-wrap gap-3 border p-2">
            <ConditionalLoader
              isLoading={isEmpty(selectAssigmentsQuestions)}
              loader={<em className="text-xs text-new-neutral-light">No assignment problem added</em>}
            >
              {selectAssigmentsQuestions?.map((assignment: any, i: number) => (
                <li key={assignment + i} className="inline-flex items-center gap-2">
                  {assignment}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => setSelectAssignmentsQuestions((prev: any) => removeAtIndex(prev, i))}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <form className="flex w-full justify-between gap-4" onSubmit={handleAddAssignmentQuestions}>
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Assignments"
              value={currentSelectAssignmentsQuestions}
              onChange={handleAssignmentChange}
              containerClass="w-full"
            />
            <Button small className="ml-auto w-fit">
              <AddIcon className="h-5 w-5 text-new-solid-white" />
            </Button>
          </form>
          <ul className="flex w-full flex-wrap gap-3 border p-2">
            <ConditionalLoader
              isLoading={isEmpty(selectHomeworkQuestions)}
              loader={<em className="text-xs text-new-neutral-light">No homework question added</em>}
            >
              {selectHomeworkQuestions?.map((homework: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {homework}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => setSelectHomeworkQuestions(removeAtIndex(selectHomeworkQuestions, i))}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <form className="flex w-full justify-between gap-4" onSubmit={handleAddHomeworkQuestions}>
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Homework"
              onChange={handleSelectHomeworkChange}
              value={currentSelectHomeworkQuestions}
              containerClass="w-full"
            />
            <Button small className="ml-auto w-fit">
              <AddIcon className="h-5 w-5 text-new-solid-white" />
            </Button>
          </form>
          <ul className="flex w-full flex-wrap gap-3 border p-2">
            <ConditionalLoader
              isLoading={isEmpty(selectPracticalQuestions)}
              loader={<em className="text-xs text-new-neutral-light">No practice question added</em>}
            >
              {selectPracticalQuestions?.map((practical: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {practical}
                  <button
                    className="rounded bg-new-solid-black p-1 text-new-solid-white"
                    onClick={() => setSelectPracticalQuestions(removeAtIndex(selectPracticalQuestions, i))}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <form className="flex w-full justify-between gap-4" onSubmit={handleAddPracticalQuestions}>
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Practice Questions"
              onChange={handlePracticalChange}
              value={currentSelectPracticalQuestions}
              containerClass="w-full"
            />
            <Button small className="ml-auto w-fit">
              <AddIcon className="h-5 w-5 text-new-solid-white" />
            </Button>
          </form>
          <ul className="flex w-full flex-wrap gap-3 border p-2">
            <ConditionalLoader
              isLoading={isEmpty(selectWarmupQuestions)}
              loader={<em className="text-xs text-new-neutral-light">No warmup question added</em>}
            >
              {selectWarmupQuestions?.map((questions: any, i: number) => (
                <li key={i} className="inline-flex items-center gap-2">
                  {questions}
                  <button
                    className="rounded bg-new-solid-black px-1 py-1 text-new-solid-white"
                    onClick={() => setSelectWarmupQuestions(removeAtIndex(selectWarmupQuestions, i))}
                  >
                    <CrossIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ConditionalLoader>
          </ul>
          <form className="flex w-full justify-between gap-4" onSubmit={handleAddWarmupQuestions}>
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Warmup Questions"
              onChange={handleWarmupChange}
              value={currentSelectWarmupQuestions}
              containerClass="w-full"
            />
            <Button small className="ml-auto w-fit">
              <AddIcon className="h-5 w-5 text-new-solid-white" />
            </Button>
          </form>
          <Input
            type={STR_TEXT_FIELD}
            value={selectSessionTimestamp}
            onChange={handleChangeSessionTimestamp}
            placeholder="Session Time"
            onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
            onFocus={(e) => (e.target.type = STR_DATE_AND_TIME_FIELD)}
          />
          <StandaredSelect
            required
            options={DurationArray}
            value={sessionDuration}
            onChange={handleSessionDuration}
            placeholder="Session Duration (in hrs)"
          />
          {sessionDuration == "other" && (
            <Input
              required
              type="number"
              value={otherSessionDuration}
              onChange={handleOtherSessionDuration}
              placeholder="Session Duration (in hrs)"
            />
          )}
          <Input type={STR_TEXT_FIELD} value={videoId} onChange={handleChangeVideoId} placeholder="Video ID" />
          <Input
            type={STR_TEXT_FIELD}
            value={videoSecret}
            onChange={handleChangeVideoSecreat}
            placeholder="Video Secret"
          />
          <Input type={STR_TEXT_FIELD} value={notesLink} onChange={handleChangeNotesLink} placeholder="Notes Link" />
          <Input
            type={STR_TEXT_FIELD}
            value={notesSummaryLink}
            onChange={handleChangeNotesSummaryLink}
            placeholder="Notes Summary Link"
          />
          <Input
            type={STR_TEXT_FIELD}
            value={preRequisite}
            onChange={handleChangePreRequisite}
            placeholder="Pre Requisite"
          />

          <Input
            type={STR_TEXT_FIELD}
            value={assignmentSolutionLink}
            onChange={handleChangeAssignmentSolutionLink}
            placeholder="Assignment Solution Link"
          />
          <Input
            type="number"
            max="100"
            min="0"
            placeholder="Assignment Solution Activation Time (in Days)"
            value={assignmentSolutionActivationTime}
            onChange={handleChangeAssignmentSolutionActivationTime}
          />
          <Input
            type="number"
            max="100"
            min="0"
            placeholder="Assignment Coins"
            value={assignmentCoins}
            onChange={handleChangeAssignmentCoins}
          />
          <Input
            type="text"
            value={firstReminderMessage}
            placeholder="No first reminder message"
            onChange={(e) => setFirstReminderMessage(e.target.value)}
          />
          <Input
            type="text"
            value={secondReminderMessage}
            placeholder="No second reminder message"
            onChange={(e) => setSecondReminderMessage(e.target.value)}
          />
          <Button disabled={loading} loading={loading} onClick={createClasses}>
            Create Class
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
