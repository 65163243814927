import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { InstructorAvailabilityDataSource } from "data/dataSource/Admin/InstructorAvailabilityDataSource"
import { Server } from "core/utils/axios"
import { InstructorAvailability } from "domain/model/Mentor"
import { API_MENTOR, API_MENTORS, API_ADMIN } from "core/constants/strings"
const server = new Server()
export default class InstructorAvailabilityAPIDataSourceImpl implements InstructorAvailabilityDataSource {
  async getInstructorAvailability(auth: Auth) {
    try {
      const response = await server.get(pn(API_ADMIN, "instructor", "get-instructor-availability"), {
        Authorization: `Bearer ${auth.id_token}`,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async toggleInstructorAvailability(auth: Auth, availability: boolean) {
    try {
      const response = await server.post(
        pn(API_ADMIN, "instructor", "toggle-instructor-availability"),
        {
          availability: availability,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      return response
    } catch (error) {
      return error
    }
  }

  async getToggleInstructorAvailability(auth: Auth) {
    try {
      const response = await server.get(pn("get_toggle_instructor"), {
        Authorization: `Bearer ${auth.id_token}`,
      })

      return response
    } catch (error) {
      return error
    }
  }
}
