import pn from "core/utils/pn"
import AssessmentDataSource from "data/dataSource/Student/AssessmentDataSource"
import {
  API_GET_ALL_ASSESSMENT,
  API_GET_ALL_EXAMS,
  API_GET_ATTENDENCE,
  API_GET_CONTEST_RESULT,
  API_V4_GET_EXAM_RESULT,
  API_V4_STUDENT,
  API_STUDENT,
  API_CONTEST,
  API_V4_STUDENT_GET_BATCHWISE_EXAMS,
  API_V4_STUDENT_CHECK_BATCH_HAS_EXAM_TEMPLATE,
  API_V4_STUDENT_GET_EXAMWISE_LEADERBOARD,
  API_V4_GET_MCQ_ID_FROM_NAME,
  STR_GET_UPCOMING_EXAMS,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { Server } from "core/utils/axios"
const server = new Server()

export default class ContestAPIDataSourceImpl implements AssessmentDataSource {
  async getAllContest(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_ALL_ASSESSMENT), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async isStudentAttended(auth: Auth, contestId: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_ATTENDENCE), {
        id_token: auth.id_token,
        contest_id: contestId,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async contestResult(auth: Auth, contestId: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_CONTEST_RESULT), {
        id_token: auth.id_token,
        contest_id: contestId,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getAllExams(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_ALL_EXAMS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamResult(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_EXAM_RESULT), {
        id_token: auth.id_token,
        contest_id: contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getBatchwiseExams(auth: Auth, email: string, batch_id: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_CONTEST, API_V4_STUDENT_GET_BATCHWISE_EXAMS),
        { email, batch_id },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
  async checkBatchHasExamTemplate(auth: Auth, batch_id: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_CONTEST, API_V4_STUDENT_CHECK_BATCH_HAS_EXAM_TEMPLATE),
        { batch_id },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
  async getExamwiseLeaderboard(auth: Auth, examId: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_CONTEST, API_V4_STUDENT_GET_EXAMWISE_LEADERBOARD),
        { examId },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
  async getUpcomingExams(auth: Auth, page: number, limit: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_UPCOMING_EXAMS), {
        id_token: auth.id_token,
        page: page,
        limit: limit,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
