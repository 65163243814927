import PaymentInvoiceViewModel from "../PaymentInvoiceViewModel"

export default function PaymentPopUpBreakdowntotal(props: any) {
  const { generateCID } = PaymentInvoiceViewModel()
  if (!props.selectedInvoice) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] relative">
        <button onClick={props.handlPopUp2} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          X
        </button>
        <h2 className="text-xl font-semibold mb-4">Invoice Breakdown</h2>

        <div className="space-y-2">
          {Object.keys(props.selectedInvoice?.campaign || {}).map((campaignType, index) => {
            const sanitizedCampaignType = campaignType.trim().toLowerCase()
            const campaignData = props.selectedInvoice.campaign[campaignType]

            if (!campaignData || typeof campaignData !== "object") return null

            let campaignTotal = 0

            return (
              <div key={index} className="border-b pb-2">
                <p className="font-bold">{campaignType.trim()}</p>
                {Object.keys(campaignData).map((pricePerPost, idx) => {
                  const numPosts = campaignData[pricePerPost]
                  const cost = parseFloat(pricePerPost)
                  const total = numPosts * cost
                  campaignTotal += total

                  return (
                    <p key={idx}>
                      {numPosts} posts @ ₹{cost} per post → <strong>₹{total}</strong>
                    </p>
                  )
                })}
                <p className="font-medium">
                  Total for {campaignType.trim()}: ₹{campaignTotal}
                </p>
              </div>
            )
          })}
        </div>

        <h3 className="text-lg font-semibold mt-4">Total Amount: ₹{props.selectedInvoice.amount}</h3>
      </div>
    </div>
  )
}
