import Input from "core/components/new/Input"
import Select from "core/components/new/Select"
import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useEffect, useState } from "react"
import { Button } from "core/components/v2/Button"
import useSessionRescheduleRequestViewModal from "./SessionRescheduleRequestViewModal"
import Spinner from "core/components/Spinner"

const SessionRescheduleRequestView = () => {
  const {
    email,
    batch,
    batches,
    statusBtns,
    requestStatus,
    rescheduleRequestCpy,
    refresh,
    statusChange,
    fetchBatches,
    batchChange,
    setEmail,
    fetchSessionRescheduleRequest,
    updateRequestStatusHandler,
    cancelMentorSession,
    loading,
  } = useSessionRescheduleRequestViewModal()

  useEffect(() => {
    fetchBatches()
  }, [])

  useEffect(() => {
    fetchSessionRescheduleRequest()
  }, [refresh])
  return (
    <DashboardLayout>
      <div className="p-6">
        <div className=" mb-[24px]">
          <p className="text-gray-800 font-inter text-[16px] font-semibold leading-normal">Re-session Request</p>
          <p className="text-[#646464] font-inter text-[14px] font-normal leading-normal">
            Track all recent mentor session cancelled request list.
          </p>
        </div>
        <div className=" flex gap-[16px]">
          <Input
            id="search-email"
            type="email"
            placeholder="Email Id"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            containerClass="w-[300px]  "
            className="border-[#D3D3D3] h-[33px]"
            search
          />
          <Select
            required
            className="w-[300px] h-[33px] border-[#D3D3D3]"
            options={batches}
            value={batch}
            onChange={batchChange}
            defaultOption="Select Batch"
          />
          <Button
            size={"sm"}
            onClick={fetchSessionRescheduleRequest}
            variant={"secondary"}
            className="px-[16px] py-[9px] border-[1px] border-[#D3D3D3]"
            disabled={false}
          >
            Search
          </Button>
        </div>

        <div className="my-[24px] flex gap-[8px]">
          {statusBtns.map((btn: any, index) => {
            return btn.value === requestStatus ? (
              <Button
                size={"sm"}
                onClick={() => {
                  statusChange(btn.value)
                }}
                variant={"primary"}
                className="px-[16px] py-[9px] border-[1px] border-[#D3D3D3]  rounded-[4px]"
                disabled={false}
              >
                {btn.label}
              </Button>
            ) : (
              <Button
                size={"sm"}
                onClick={() => {
                  statusChange(btn.value)
                }}
                className="px-[16px] py-[9px]  font-normal text-[14px]  text-[#646464] bg-[#F9F9F9] rounded-[4px] hover:text-white"
                disabled={false}
              >
                {btn.label}
              </Button>
            )
          })}
        </div>

        {rescheduleRequestCpy.length === 0 && <div className=" w-full flex justify-center ">No Records Found !</div>}
        {loading && (
          <div className=" w-full h-full flex justify-center items-center">
            <Spinner />
          </div>
        )}
        {!loading &&
          rescheduleRequestCpy.map((req: any, index: any) => {
            return (
              <div className="mb-[27px] w-full h-[138px] border-[0.5px] rounded-[8px] border-[#D3D3D3] p-[12px]">
                <div className=" flex justify-between">
                  <div className="w-[275px] h-[52px]">
                    <div className=" flex items-center  ">
                      <p className="text-[#333] mr-[8px] font-inter text-[14px] font-semibold leading-normal">
                        {req.student_name}
                      </p>

                      <div className=" cursor-pointer">
                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="Group 40900">
                            <g id="Group 40899">
                              <path
                                id="Rectangle 469 (Stroke)"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 2.70542C0 2.33417 0.283279 2.0332 0.632727 2.0332H7.44C7.78945 2.0332 8.07273 2.33416 8.07273 2.70542V11.3283C8.07273 11.6995 7.78945 12.0005 7.44 12.0005H0.632727C0.283281 12.0005 0 11.6996 0 11.3283V2.70542ZM1.09091 3.19219V10.8415H6.98182V3.19219H1.09091Z"
                                fill="#646464"
                              />
                              <path
                                id="Subtract"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.56047 0C2.21102 0 1.92773 0.300964 1.92773 0.672214V2.47374H3.01865V1.15899H8.90962V8.80831H7.437V9.9673H9.36781C9.71726 9.9673 10.0005 9.66634 10.0005 9.29509V0.672213C10.0005 0.300961 9.71726 0 9.36781 0H2.56047Z"
                                fill="#646464"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <p className="text-[#646464] font-inter text-[12px] font-medium leading-normal">{req.batch}</p>
                    <p className="text-[#646464] font-inter text-[10px] font-medium leading-normal">
                      {req.session_name} - 18/05/2025
                    </p>
                  </div>
                  {req.status === "pending" ? (
                    <div className="gap-[16px] flex">
                      <Button
                        variant="cancel"
                        size={"sm"}
                        className="px-[16px] text-[14px]  py-[9px] border-[1px] border-[#D41B1B]"
                        onClick={() => {
                          updateRequestStatusHandler(req.id, "denied")
                        }}
                        disabled={false}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        size={"sm"}
                        className="px-[16px] text-[14px] py-[9px]"
                        disabled={false}
                        onClick={() => {
                          cancelMentorSession(req.id, "approved", req.session_id, "reschedule")
                          // updateRequestStatusHandler(req.id, "approved")
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  ) : req.status === "approved" ? (
                    <div className="gap-[16px] flex">
                      <p className=" text-green-500 font-bold text-[16px]">Approved</p>
                    </div>
                  ) : (
                    <div className="gap-[16px] flex">
                      <p className=" text-red-500 font-bold text-[16px]">Denied</p>
                    </div>
                  )}
                </div>
                <div className=" mt-[12px] rounded-[4px] flex  items-center w-full px-[16px] py-[8px]   min-h-[50px] bg-[#F9F9F9]">
                  <p className="font-inter text-[14px] font-normal">{req.reason}</p>
                </div>
              </div>
            )
          })}
      </div>
    </DashboardLayout>
  )
}

export default SessionRescheduleRequestView
