import { Auth } from "domain/model/Auth"
import { CreateMentorNotificationsDetails } from "domain/model/MentorNotifications"
import CreateMentorNotificationsRepository from "domain/repository/Student/MentorNotificationsRepository"

export interface CreateMentorNotificationsUseCase {
  invoke(auth: Auth, details: CreateMentorNotificationsDetails): Promise<any>
}

export class CreateMentorNotifications implements CreateMentorNotificationsUseCase {
  private repository: CreateMentorNotificationsRepository

  constructor(repository: CreateMentorNotificationsRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: CreateMentorNotificationsDetails): Promise<any> {
    return this.repository.createMentorNotifications(auth, details)
  }
}
