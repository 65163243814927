import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface getModulewiseExamsUseCase {
  invoke(auth: Auth, template_name: string, module_name: string): Promise<any>
}

export class GetModulewiseExams implements getModulewiseExamsUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, template_name: string, module_name: string) {
    return await this.repository.getModulewiseExams(auth, template_name, module_name)
  }
}
