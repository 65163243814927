import React, { useState } from "react"
import McqAPIDataSourceImpl from "data/API/Admin/McqAPIDataSourceImpl"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import GetQuestionList from "domain/useCase/Admin/Contest/GetQuestionList"
import GetCaseStudiesQuestionList from "domain/useCase/Admin/Contest/GetCaseStudiesQuestionList"
import GetSubjectiveQuestionList from "domain/useCase/Admin/Contest/GetSubjectiveQuestionsList"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import GetContestDetails from "domain/useCase/User/Contest/GetContestDetails"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import { McqRepositoryImpl } from "data/repository/Admin/McqRepositoryImpl"
import { CreateContest } from "domain/useCase/Admin/Contest/CreateContest"
import { GetMcqQuestions } from "domain/useCase/Admin/Mcq/GetMcqQuestions"
import { useNavigate, useParams } from "react-router-dom"
import { UpdateContest } from "domain/useCase/Admin/Contest/UpdateContest"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import ContestAPIAdminDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"

import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import ContestRepositoryAdminImpl from "data/repository/Admin/ContestRepositoryImpl"

import { format, isValid } from "date-fns"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import GetMcqNameFromId from "domain/useCase/Admin/Contest/GetMcqNameFromId"
import { ValidateCodingQuestion } from "domain/useCase/Admin/Contest/ValidateCodingQuestion"
import { ValidateMcqQuestion } from "domain/useCase/Admin/Contest/ValidateMcqQuestion"
import { ValidateSubjectiveQuestion } from "domain/useCase/Admin/Contest/ValidateSubjectiveQuestion"
import { GetAllModules } from "domain/useCase/Admin/CreateClass/GetAllModules"
import { GetBatchwiseModules } from "domain/useCase/Admin/Contest/GetBatchwiseModules"
import { GetModulewiseExams } from "domain/useCase/Admin/Contest/GetModulewiseExams"
import { GetTemplateIntersection } from "domain/useCase/Admin/Contest/GetTemplateIntersection"
import { getBatchDetails } from "domain/useCase/Admin/Batch/getBatchDetails"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { CreateExam } from "domain/useCase/Admin/Contest/CreateExam"
import { UpdateExam } from "domain/useCase/Admin/Contest/UpdateExam"
import GetExamDetails from "domain/useCase/Admin/Contest/GetExamDetails"

export default function ContestEditViewModel() {
  const [name, setName] = React.useState<any>("")
  const [nickname, setNickname] = React.useState<any>("")
  const [batches, setBatches] = React.useState<any>([])
  const [duration, setDuration] = React.useState<any>("")
  const [startTime, setStartTime] = React.useState<any>("")
  const [isICPContest, setIsICPContest] = React.useState<string>("")
  const [questions, setQuestions] = React.useState<any[]>([])
  const [mcqs, setMcqs] = React.useState<any[]>([])
  const [question, setQuestion] = React.useState<any>("")
  const [mcq, setMcq] = React.useState<any>("")
  const [questionScore, setQuestionScore] = React.useState<any>("")
  const [problemName, setProblemName] = React.useState<any>("")
  const [mcqData, setMcqData] = React.useState([])
  const [mcqScore, setMcqScore] = React.useState<any>("")
  const [subjectiveQuestions, setSubjectiveQuestions] = React.useState<any[]>([])
  const [subjectiveQuestion, setSubjectiveQuestion] = React.useState<any>("")
  const [subjectiveQuestionScore, setSubjectiveQuestionScore] = React.useState<any>("")
  const [casestudyQuestions, setCasestudyQuestions] = React.useState<any[]>([])
  const [casestudyQuestion, setCasestudyQuestion] = React.useState<any>("")
  const [casestudyQuestionScore, setCasestudyQuestionScore] = React.useState<any>("")
  const [casestudyQuestinonList, setCasestudyQuestionList] = React.useState<any[]>([])
  const [subjectiveQuestionList, setSubjectiveQuestionList] = React.useState<any[]>([])

  const [questionList, setQuestionList] = React.useState<any[]>([])
  const [mcqList, setMcqList] = React.useState<any[]>([])
  const [type, setType] = React.useState<string>("live")
  const [generalInfo, setGeneralInfo] = React.useState<any>("")
  const [loading, setLoading] = React.useState<boolean>(false)
  const [allFieldsValid, setAllFieldsValid] = React.useState<boolean>(false)
  const [batchList, setBatchList] = React.useState<any>([])
  const [batch, setBatch] = React.useState<string>("")
  const [mcqName, setMcqName] = React.useState<string>("")
  const [milestone_type, setMilestoneType] = React.useState<string>("")
  const [passing_criteria, setPassingCriteria] = React.useState<any>("") //check for 0
  const [moduleList, setModuleList] = React.useState<any>([])
  const [module, setModule] = React.useState<any>("")
  const [examList, setExamList] = React.useState<any>([])
  const [exam, setExam] = React.useState<any>("")
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [isProctored, setIsProctored] = React.useState("Yes")
  const proctoringOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]
  const [flowType, setFlowType] = React.useState<string>("newFlow")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { id } = useParams()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const getMcqQuestionsUseCase = new GetMcqQuestions(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const getQuestionListUseCase = new GetQuestionList(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const getCaseStudiesQuestionListUseCase = new GetCaseStudiesQuestionList(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const getSubjectiveQuestionListUseCase = new GetSubjectiveQuestionList(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const createContestUseCase = new CreateContest(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const createExamUseCase = new CreateExam(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))

  const updateContestUseCase = new UpdateContest(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const updateExamUseCase = new UpdateExam(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const getContestDetailsUseCase = new GetContestDetails(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getExamDetailsUseCase = new GetExamDetails(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getBatchwiseModulesUseCase = new GetBatchwiseModules(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const getModulewiseExamsUseCase = new GetModulewiseExams(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const getTemplateIntersectionUseCase = new GetTemplateIntersection(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )
  const getMcqNameFromIdUseCase = new GetMcqNameFromId(
    new ContestRepositoryAdminImpl(new ContestAPIAdminDataSourceImpl())
  )

  const validateCodingQuestionUseCase = new ValidateCodingQuestion(
    new ContestRepositoryAdminImpl(new ContestAPIAdminDataSourceImpl())
  )

  const validateMcqQuestionUseCase = new ValidateMcqQuestion(
    new ContestRepositoryAdminImpl(new ContestAPIAdminDataSourceImpl())
  )

  const validateSubjectiveQuestionUseCase = new ValidateSubjectiveQuestion(
    new ContestRepositoryAdminImpl(new ContestAPIAdminDataSourceImpl())
  )
  const getBatchDetailsUseCase = new getBatchDetails(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))

  async function getBatchDetail(selectedBatch: any) {
    const response = await getBatchDetailsUseCase.invoke(auth, selectedBatch)

    if (response.success) {
      console.log("Success")
    } else {
      console.log("Error")
    }
  }
  async function fetchBatchList() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error while fetching all batches"))
      changeToastVisibility(true)
      return
    }

    const batches = response?.data
    setBatchList(batches?.map((b: any) => ({ value: b, label: b })))
  }

  async function fetchBatchwiseModuleList(selectedBatchIds: string[]) {
    const response = await getBatchwiseModulesUseCase.invoke(auth, selectedBatchIds)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error while fetching batchwise modules"))
      changeToastVisibility(true)
      return
    }

    const modules = response?.data
    setModuleList(modules?.map((m: any) => ({ value: m, label: m })))
  }
  async function getTemplateIntersection(selectedBatchIds: string[]) {
    const response = await getTemplateIntersectionUseCase.invoke(auth, selectedBatchIds)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Batches selected do not have a template intersection"))
      changeToastVisibility(true)
      return
    }

    return response.data
  }

  const handleProctoringChange = (selectedOption: any) => {
    setIsProctored(selectedOption.target.value)
  }
  const fetchMcqName = async (mcq_id: any) => {
    const response = await getMcqNameFromIdUseCase.invoke(auth, mcq_id)
    if (!response?.success) {
      setMcqName(response.data)
    }
    return response?.data
  }

  async function validateCodingQuestion(id: any) {
    const response = await validateCodingQuestionUseCase.invoke(auth, id)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occured while adding question"))
      changeToastVisibility(true)
      return
    }
    if (response?.data?.exists) {
      return true
    } else {
      return false
    }
  }

  async function validateMcqQuestion(id: any) {
    const response = await validateMcqQuestionUseCase.invoke(auth, id)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occured while adding question"))
      changeToastVisibility(true)
      return
    }
    if (response?.data?.exists) {
      return true
    } else {
      return false
    }
  }

  async function validateSubjectiveQuestion(id: any) {
    const response = await validateSubjectiveQuestionUseCase.invoke(auth, id)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occured while adding question"))
      changeToastVisibility(true)
      return
    }
    if (response?.data?.exists) {
      return true
    } else {
      return false
    }
  }

  const updateMcqObj = async (mcq: any, mcqScore: any) => {
    const mcq_id = mcq.split("/").pop()
    const response = await fetchMcqName(mcq_id)

    const mcq_name = response || mcq // fallback to the original name if response.data is undefined

    const mcqObj = {
      key: mcq_id,
      score: mcqScore,
      mcq_name: mcq_name,
    }

    return mcqObj
  }

  const handleIsICPContestChange = (e: any) => {
    setIsICPContest(e.target.value)
  }

  const fetchContestDetails = async () => {
    const response = await getExamDetailsUseCase.invoke(auth, id as string)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch exam details"))
      changeToastVisibility(true)
      return
    }

    const contest = response?.data
    const timestamp = parseInt(contest?.start_time) * 1000

    setName(contest?.name)
    setNickname(contest?.nickname)
    if (!isEmpty(contest?.batches)) setBatches(contest?.batches)
    setDuration(contest?.duration)
    setType(contest?.type)
    setGeneralInfo(contest?.general_information)
    setQuestions(contest?.question_lis)
    setMcqs(contest?.mcq_lis)
    setSubjectiveQuestions(contest?.subjective_lis ?? [])
    setCasestudyQuestions(contest?.casestudy_lis ?? [])
    setIsProctored(contest?.isProctored)

    const isICPContestValue = contest.isICPContest ? "true" : "false"
    setIsICPContest(isICPContestValue)

    setPassingCriteria(contest?.passing_criteria)
    setFlowType(contest?.flowType)
  }

  const fetchMcqQuestionList = async () => {
    setLoading(true)

    const response = await getMcqQuestionsUseCase.invoke(auth)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch mcq questions"))
      changeToastVisibility(true)
      return
    }
    setMcqList(response?.data)
  }

  const fetchQuestionList = async () => {
    setLoading(true)

    const response = await getQuestionListUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch mcq questions"))
      changeToastVisibility(true)
      return
    }

    setQuestionList(response?.data)
  }

  const fetchCaseStudiesQuestion = async () => {
    setLoading(true)

    const response = await getCaseStudiesQuestionListUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch case studies questions"))
      changeToastVisibility(true)
      return
    }

    setCasestudyQuestionList(response?.data)
  }

  const fetchSubjectiveQuestion = async () => {
    setLoading(true)

    const response = await getSubjectiveQuestionListUseCase.invoke(auth)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch subjective questions"))
      changeToastVisibility(true)
      return
    }

    setSubjectiveQuestionList(response?.data)
  }

  const handleNameChange = (event: any) => {
    setName(event.target.value)
  }

  function handleNickNameChange(e: any) {
    setNickname(e.target.value)
  }

  function handleBatchChange(e: any) {
    setBatch(e.target.value)
    setBatches([...batches, e.target.value])
  }

  const handleDurationChange = (event: any) => {
    setDuration(event.target.value)
  }

  const handleStartTimeChange = (event: any) => {
    setStartTime(event.target.value)
  }

  const handleTypeChange = (event: any) => {
    setType(event.target.value)
  }

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value)
  }

  const handleSubjectiveQuestionChange = (e: any) => {
    setSubjectiveQuestion(e.target.value)
    const question_value = e.target.value
    const mainQuestionId = e.target.value.split("/").pop()
    setSubjectiveQuestion(e.target.value)
    const selectedSubjectiveQuestion = subjectiveQuestionList.find((item) => item.id === mainQuestionId)

    if (selectedSubjectiveQuestion && selectedSubjectiveQuestion.coins) {
      const subjectiveScore = parseInt(selectedSubjectiveQuestion.coins) / 5
      setSubjectiveQuestionScore(subjectiveScore)
    } else {
      setSubjectiveQuestionScore(0)
    }
  }

  const handleCaseStudyQuestionChange = (e: any) => {
    const question_value = e.target.value
    const mainQuestionId = e.target.value.split("/").pop()
    setCasestudyQuestion(e.target.value)
    const selectedCaseStudy = casestudyQuestinonList.find((item) => item.id === mainQuestionId)

    if (selectedCaseStudy && selectedCaseStudy.coins) {
      const casestudyScore = parseInt(selectedCaseStudy.coins) / 5
      setCasestudyQuestionScore(casestudyScore)
    } else {
      setCasestudyQuestionScore(0)
    }
  }

  const handleMcqChange = (e: any) => {
    setMcq(e.target.value)
  }

  const handleQuestionScoreChange = (e: any) => {
    setQuestionScore(e.target.value)
  }

  const handleCaseStudyQuestionScoreChange = (e: any) => {
    setCasestudyQuestionScore(e.target.value)
  }

  const handleSubjectiveQuestionScoreChange = (e: any) => {
    setSubjectiveQuestionScore(e.target.value)
  }

  const handleMcqScoreChange = (e: any) => {
    setMcqScore(e.target.value)
  }

  const handleGeneralInfoChange = (e: any) => {
    setGeneralInfo(e.target.value)
  }
  //new
  const handleMilestoneTypeChange = (e: any) => {
    setMilestoneType(e.target.value)
  }
  const handlePassingCriteriaChange = (e: any) => {
    setPassingCriteria(e.target.value)
  }
  function handleModuleChange(e: any) {
    setModule(e.target.value)
  }
  function handleExamChange(e: any) {
    setExam(e.target.value)
  }
  const redirectToContests = () => {
    navigate("/admin/contests")
  }

  const handleAddQuestion = (e: any, type: string) => {
    e.preventDefault()

    const validateFields = (question: any, score: any) => {
      if (question === "" || score === "") {
        changeToastDetails(STR_FAILURE, "Please fill all the fields")
        changeToastVisibility(true)
        return false
      }

      if (score < 0) {
        changeToastDetails(STR_FAILURE, "Score cannot be negative")
        changeToastVisibility(true)
        return false
      }

      if (score > 100) {
        changeToastDetails(STR_FAILURE, "Score cannot be greater than 100")
        changeToastVisibility(true)
        return false
      }

      return true
    }

    if (type === "question") {
      if (!validateFields(question, questionScore)) return

      let isPresent = questions.some((questionObj) => questionObj.key === question.split("/").pop())

      if (isPresent) {
        changeToastDetails(STR_FAILURE, "Question already added")
        changeToastVisibility(true)
        return
      }

      const questionObj = {
        key: question.split("/").pop(),
        score: questionScore,
        type: "coding",
        problem_name: question,
      }
      validateCodingQuestion(questionObj?.key)
        .then((isValid) => {
          if (isValid) {
            setQuestions((prevQuestions) => [...prevQuestions, questionObj])
            setQuestion("") // Assuming setQuestion is a function that clears the input
            setQuestionScore("") // Assuming setQuestionScore is a function that clears the score input
          } else {
            changeToastDetails(STR_FAILURE, "Problem does not exist")
            changeToastVisibility(true)
          }
        })
        .catch((error) => {
          console.error("An error occurred while validating the coding question:", error)
        })
    } else if (type === "caseStudy") {
      if (!validateFields(casestudyQuestion, casestudyQuestionScore)) return

      let isPresent = casestudyQuestions.some((questionObj) => questionObj.key === casestudyQuestion.split("/").pop())

      if (isPresent) {
        changeToastDetails(STR_FAILURE, "Question already added")
        changeToastVisibility(true)
        return
      }

      const questionObj = {
        key: casestudyQuestion.split("/").pop(),
        score: casestudyQuestionScore,
        type: "caseStudy",
        problem_name: casestudyQuestion, // Use problem_name for consistency
      }
      validateSubjectiveQuestion(questionObj?.key)
        .then((isValid) => {
          if (isValid) {
            setCasestudyQuestions((prevCasestudyQuestions) => [...prevCasestudyQuestions, questionObj])
            setCasestudyQuestion("")
            setCasestudyQuestionScore("")
          } else {
            changeToastDetails(STR_FAILURE, "Problem does not exist")
            changeToastVisibility(true)
          }
        })
        .catch((error) => {
          console.error("An error occurred while validating the coding question:", error)
        })
    } else {
      if (!validateFields(subjectiveQuestion, subjectiveQuestionScore)) return

      let isPresent = subjectiveQuestions.some((questionObj) => questionObj.key === subjectiveQuestion.split("/").pop())

      if (isPresent) {
        changeToastDetails(STR_FAILURE, "Question already added")
        changeToastVisibility(true)
        return
      }

      const questionObj = {
        key: subjectiveQuestion.split("/").pop(),
        score: subjectiveQuestionScore,
        type: "subjective",
        problem_name: subjectiveQuestion, // Use problem_name for consistency
      }
      validateSubjectiveQuestion(questionObj?.key)
        .then((isValid) => {
          if (isValid) {
            setSubjectiveQuestions((prevSubjectiveQuestions) => [...prevSubjectiveQuestions, questionObj])
            setSubjectiveQuestion("")
            setSubjectiveQuestionScore("")
          } else {
            changeToastDetails(STR_FAILURE, "Problem does not exist")
            changeToastVisibility(true)
          }
        })
        .catch((error) => {
          console.error("An error occurred while validating the coding question:", error)
        })
    }
  }

  const handleAddMcq = async (e: any) => {
    e.preventDefault()

    if (mcq === "" || mcqScore === "") {
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      changeToastVisibility(true)
      return
    }

    if (mcqScore < 0) {
      changeToastDetails(STR_FAILURE, "Score cannot be negative")
      changeToastVisibility(true)
      return
    }

    if (mcqScore > 100) {
      changeToastDetails(STR_FAILURE, "Score cannot be greater than 100")
      changeToastVisibility(true)
      return
    }

    let isPresent = false

    mcqs.forEach((mcqObj) => {
      if (mcqObj?.key === mcq.split("/").pop()) {
        isPresent = true
      }
    })

    if (isPresent) {
      changeToastDetails(STR_FAILURE, "Question already added")
      changeToastVisibility(true)
      return
    }

    const mcq_id = mcq.split("/").pop()
    const response = await fetchMcqName(mcq_id)
    const mcq_name = response // Fallback to the original name if response.data is undefined

    const mcqObj = {
      key: mcq_id,
      score: mcqScore,
      mcq_name: mcq_name,
    }
    if (mcqObj?.mcq_name) {
      setMcqs([...mcqs, mcqObj])
      setMcq("")
      setMcqScore("")
    } else {
      changeToastDetails(STR_FAILURE, "Problem does not exist")
      changeToastVisibility(true)
    }
  }

  const handleRemoveMcq = (id: any) => {
    const newMcqs = mcqs.filter((mcq) => mcq?.key !== id)
    setMcqs(newMcqs)
  }

  const handleRemoveQuestion = (id: any, type: string) => {
    if (type === "question") {
      const newQuestions = questions.filter((question) => question?.key !== id)
      setQuestions(newQuestions)
    } else if (type === "casestudy") {
      const newQuestions = casestudyQuestions.filter((question) => question?.key !== id)
      setCasestudyQuestions(newQuestions)
    } else if (type === "subjective") {
      const newQuestions = subjectiveQuestions.filter((question) => question?.key !== id)
      setSubjectiveQuestions(newQuestions)
    }
    return
  }

  const checkIfAllFieldsAreFilled = () => {
    if (name === "" || duration === "" || type === "" || generalInfo === "" || passing_criteria === "") {
      setAllFieldsValid(false)
      return
    }

    setAllFieldsValid(true)
  }

  const segregateContestData = () => {
    const contestData = {
      name,
      nickname,
      duration: parseInt(duration),
      type,
      isICPContest: isICPContest === "true",
      general_information: generalInfo,
      question_lis: questions?.map((question) => ({
        ...question,
        score: parseInt(question?.score),
      })),
      mcq_lis: mcqs?.map((mcq) => ({
        ...mcq,
        score: parseInt(mcq?.score),
      })),
      subjective_lis: subjectiveQuestions?.map((question) => ({
        ...question,
        score: parseInt(question?.score),
      })),
      casestudy_lis: casestudyQuestions?.map((question) => ({
        ...question,
        score: parseInt(question?.score),
      })),

      passing_criteria: parseInt(passing_criteria),
      flowType,
      isProctored: isProctored,
    }
    return contestData
  }

  const resetAllValues = () => {
    setName("")
    setNickname("")
    setDuration("")
    setType("")
    setGeneralInfo("")
    setQuestions([])
    setMcqs([])
    setQuestion("")
    setMcq("")
    setQuestionScore("")
    setMcqScore("")
    setSubjectiveQuestions([])
    setCasestudyQuestions([])
    setCasestudyQuestionScore("")
    setSubjectiveQuestionScore("")
    setCasestudyQuestion("")
    setSubjectiveQuestion("")
    setPassingCriteria("")
  }

  const handleCreateContest = async () => {
    let contestData = segregateContestData()
    contestData = { ...contestData, isICPContest: isICPContest === "true" }
    setSubmitLoading(true)

    const response = await createExamUseCase.invoke(auth, contestData)

    setSubmitLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to create exam"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Exam created successfully")
    changeToastVisibility(true)

    resetAllValues()
  }

  const handleUpdateContest = async () => {
    let contestData: any = segregateContestData()
    contestData = {
      ...contestData,
      contest_id: id,
      isICPContest: isICPContest === "true",
    }

    setSubmitLoading(true)

    const response = await updateExamUseCase.invoke(auth, contestData)

    setSubmitLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to update contest"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Updated Contest successfully")
    changeToastVisibility(true)
  }

  const handleRemoveBatch = (index: number) => {
    setBatches(removeAtIndex(batches, index))
  }

  return {
    id,
    toast,
    loading,
    name,
    nickname,
    duration,
    startTime,
    type,
    generalInfo,
    questionList,
    subjectiveQuestionList,
    casestudyQuestinonList,
    mcqList,
    questions,
    mcqs,
    question,
    mcq,
    allFieldsValid,
    questionScore,
    casestudyQuestions,
    subjectiveQuestions,
    casestudyQuestion,
    subjectiveQuestion,
    casestudyQuestionScore,
    subjectiveQuestionScore,
    mcqScore,

    batchList,
    batches,
    batch,
    mcqName,
    submitLoading,
    fetchContestDetails,
    checkIfAllFieldsAreFilled,
    handleRemoveQuestion,
    handleRemoveMcq,
    handleGeneralInfoChange,
    handleAddQuestion,
    handleAddMcq,
    handleQuestionScoreChange,
    handleCaseStudyQuestionScoreChange,
    handleSubjectiveQuestionScoreChange,
    handleMcqScoreChange,
    handleQuestionChange,
    handleCaseStudyQuestionChange,
    handleSubjectiveQuestionChange,
    handleMcqChange,
    handleNameChange,
    handleNickNameChange,
    handleDurationChange,
    handleStartTimeChange,
    handleTypeChange,
    handleUpdateContest,
    handleCreateContest,
    fetchMcqQuestionList,
    fetchQuestionList,
    fetchCaseStudiesQuestion,
    fetchSubjectiveQuestion,
    setSubjectiveQuestion,
    setSubjectiveQuestionScore,
    redirectToContests,
    changeToastVisibility,
    fetchBatchList,
    handleBatchChange,
    handleRemoveBatch,
    fetchMcqName,
    setMcqData,
    setIsICPContest,
    isICPContest,
    handleIsICPContestChange,
    validateCodingQuestion,
    milestone_type,
    handleMilestoneTypeChange,
    passing_criteria,
    handlePassingCriteriaChange,
    moduleList,
    module,
    handleModuleChange,
    fetchBatchwiseModuleList,
    examList,
    exam,
    handleExamChange,
    getTemplateIntersection,
    proctoringOptions,
    handleProctoringChange,
    isProctored,
  }
}
