import { Button } from "core/components/v2/Button"
import Line from "core/components/payment-method/Line"
import LineBottom from "core/components/payment-method/LineBottom"
import card1 from "assets/images/payment-method/card1.png"
import card2 from "assets/images/payment-method/card2.png"
import card3 from "assets/images/payment-method/card3.png"
import { Link } from "react-router-dom"
import { useApp } from "core/context/app"

export default function BannerCardModel({ isOpen, onClose, children, width, height }: any) {
  if (!isOpen) return null
  const { student } = useApp()
  const oneTimeDiscount = student?.batch?.startsWith("EL") === true ? "5,000" : "10,000"
  return (
    <div className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-[2px] z-0"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative p-6">
          <div className="flex relative justify-between h-[345px] w-[692px] overflow-hidden rounded-[8px] bg-[#333]">
            <div className="absolute top-0 animate-moveWhite">
              <Line />
            </div>
            <div className="absolute top-0 animate-moveWhiteDelayed">
              <Line />
            </div>

            <div className="absolute bottom-0 animate-moveBottomWhite">
              <LineBottom />
            </div>
            <div className="absolute bottom-0 animate-moveBottomWhiteDelayed">
              <LineBottom />
            </div>
            <div className="mt-[50px] mx-[24px]">
              <span className="flex justify-center items-center gap-[10px] rounded-[4px] bg-[#ffffff4f] w-[121px] h-[29px] px-[8px] py-[4px] text-[17px] text-[#FFF] font-[500] font-[Inter] shadow-[4px_4px_0px_0px_rgba(0,0,0,0.25)]">
                Off Upto
              </span>

              <div className="text-white">
                <span style={{ textShadow: "-3px 5px 0px #2AA469" }} className="text-[70px] font-[800] font-[Inter]">
                  {oneTimeDiscount}
                </span>
                <span className="text-[17px] text-[#FFF] font-[500] font-[Inter] size[21px]">Rs</span>
              </div>

              <div className="w-[302px] h-[103px] text-[#F9F9F9]">
                <p className="text-[12px] font-[400] font-[Inter] mb-[6px]">Hi {student?.name},</p>
                <p className="text-[12px] font-[400] font-[Inter]">
                  We are pleased to offer you an Exclusive Discount of upto{" "}
                  <span className="font-[600]">Rs {oneTimeDiscount}/-</span> on Early Fee Payment.{" "}
                </p>
                <Link to="/payment" onClick={onClose}>
                  <Button variant="icon" size={"sm"} className="relative z-20 mt-[12px] focus:bg-v2-gray-200">
                    <span className="w-[122px] text-[#646464] text-[12px] font-[400] font-[Inter]">View Detail</span>
                  </Button>
                </Link>
              </div>
            </div>

            {/* Cards */}
            <div className="relative w-[280px] my-[28px] mx-[24px]">
              <Button
                variant={"cancel"}
                size={"sm"}
                onClick={onClose}
                className="absolute top-0 right-0 rounded-[1.5px] font-[300] font-[Inter] text-white bg-[#ffffff42] hover:bg-[#ffffff1c] size-[24px]"
              >
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect width="24" height="24" rx="2" fill="white" fillOpacity="0.26" />
                    <path
                      d="M18 6L6 18"
                      stroke="white"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="white"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Button>

              <div className="absolute z-20 bottom-0 right-0">
                <img src={card1} alt="" className="h-[176px] w-[154px]" />
              </div>
              <div className="absolute z-10 bottom-[60px] left-0">
                <img src={card2} alt="" className="h-[176px] w-[154px]" />
              </div>
              <div className="absolute top-0 right-[46px]">
                <img src={card3} alt="" className="h-[176px] w-[154px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
