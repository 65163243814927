import { cn } from "core/lib/utils"

export default function ButtonGroup({ buttons, active, onChange }: any) {
  return (
    <div className="inline-flex gap-2 rounded bg-[#ebebeb] p-1.5">
      {buttons.map((button: any, i: number) => (
        <div
          key={i}
          id={button}
          className={cn(
            "flex items-center justify-center cursor-pointer w-fit min-w-[150px] select-none rounded-sm py-2 px-4 text-sm font-medium leading-[22px] transition-colors",
            active === i ? "bg-white text-[#333]" : "bg-[#ebebeb] text-[#646464] hover:bg-[#d6d6d6]"
          )}
          onClick={() => onChange(i)}
        >
          {button}
        </div>
      ))}
    </div>
  )
}
