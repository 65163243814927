import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface GetAllContestsListUseCase {
  invoke(auth: Auth): Promise<any>
}

export default class GetAllContestsList implements GetAllContestsListUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return this.repository.getAllContestsList(auth)
  }
}
