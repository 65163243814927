import { ChatbotRepository } from "domain/repository/Student/ChatbotRepository"
import { Auth } from "domain/model/Auth"

export interface ChatbotUseCase {
  invoke(auth: Auth, prompt: string): Promise<any>
}

export class Chatbot implements ChatbotUseCase {
  private repository: ChatbotRepository

  constructor(repository: ChatbotRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, prompt: string): Promise<any> {
    return this.repository.getChatbotResponse(auth, prompt)
  }
}
