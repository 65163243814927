import DashboardLayout from "core/layouts/DashboardLayout"
import { useEffect } from "react"
import InstructorAvailabilityViewModal from "./InstructorAvailabilityViewModal"
import Toast from "core/components/Toast"

const InstructorAvailabilityView = () => {
  const {
    dates,
    toggleStatus,
    loading,
    toast,
    handleCheckboxChange,
    getAllInstructorAvailability,
    isDateAvailable,
    updateInstructorAvailabilityHandler,
    changeToastVisibility,
    getToggleInstructorAvailability,
  } = InstructorAvailabilityViewModal()

  useEffect(() => {
    getToggleInstructorAvailability()
    getAllInstructorAvailability()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <h2 className="text-new-accent">Instructor Availability</h2>

        {toggleStatus ? (
          <div>
            <div className="flex flex-wrap gap-4">
              {dates.map((item, index) => (
                <div
                  key={index}
                  className="h-20 w-48 border-[#312e81] border-2 flex flex-col items-center justify-center"
                >
                  <span className="text-sm">{item.date}</span>
                  <span className="text-sm font-semibold">{item.day}</span>

                  <input
                    type="checkbox"
                    checked={isDateAvailable(item.date)}
                    onChange={() => handleCheckboxChange(item.date)}
                  />
                </div>
              ))}
            </div>
            <div className="w-full flex items-center justify-center my-3">
              {loading ? (
                <button className="cursor-pointer h-10 px-2 py-2 bg-gray-400 text-white font-bold">
                  Update Your Availability
                </button>
              ) : (
                <button
                  onClick={updateInstructorAvailabilityHandler}
                  disabled={loading}
                  className="cursor-pointer h-10 px-2 py-2 bg-new-accent text-white font-bold"
                >
                  Update Instructor Availability
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className=" flex justify-center  items-center w-full h-full ">
            <p className="text-new-accent text-xl">
              Updating instructor availability is currently disabled. Please contact the admin to update your
              availability on the portal.
            </p>
          </div>
        )}

        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </div>
    </DashboardLayout>
  )
}

export default InstructorAvailabilityView
