import React from "react"
import useToast from "core/hooks/useToast"
import useLocalStorage from "core/hooks/useLocalStorage"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useParams, useNavigate } from "react-router-dom"
import EditorAPIDataSourceImpl from "data/API/Student/EditorAPIDataSourceImpl"
import { EditorRepositoryImpl } from "data/repository/Student/EditorRepositoryImpl"
import { ImportTemplateCode } from "domain/useCase/Student/Editor/ImportTemplateCode"
import { GetQuestion } from "domain/useCase/Student/Editor/GetQuestion"
import { GetQuestionWithAnswer } from "domain/useCase/Student/Editor/GetQuestionWithAnswer"
import { GetSubCaseStudyQuestion } from "domain/useCase/Student/Editor/GetCaseStudySubjectiveQuestion"
import { GetSubmissions } from "domain/useCase/Student/Editor/GetSubmissions"
import { GetExamQuestionSubmissions } from "domain/useCase/Student/Editor/GetExamQuestionSubmissions"
import { RunCode } from "domain/useCase/Student/Editor/RunCode"
import { CreateSubmission } from "domain/useCase/Student/Editor/CreateSubmission"
import { GetSubmission } from "domain/useCase/Student/Editor/GetSubmission"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import GetContestDetails from "domain/useCase/User/Contest/GetContestDetails"
import GetMcqQuestionDetails from "domain/useCase/User/Contest/GetMcqQuestionDetails"
import GetCodingQuestionDetails from "domain/useCase/User/Contest/GetCodingQuestionDetails"
import SubmitCodingQuestion from "domain/useCase/User/Contest/SubmitCodingQuestion"
import SubmitMcqQuestion from "domain/useCase/User/Contest/SubmitMcqQuestion"
import SubmitSubjectiveQuestions from "domain/useCase/User/Contest/SubmitSubjectiveQuestions"
import SubmitCasestudyQuestions from "domain/useCase/User/Contest/SubmitCasestudyQuetions"
import SubmitBatchMcqQuestion from "domain/useCase/User/Contest/SubmitBatchMcqQuestion"
import GetStudentContestDetails from "domain/useCase/User/Contest/GetStudentContestDetails"
import GetStudentExamDetails from "domain/useCase/User/Contest/GetStudentExamDetails"
import SubmitContest from "domain/useCase/User/Contest/SubmitContest"
import delay from "core/utils/delay"
import { useAuth } from "core/context/auth"
import { isEmpty } from "core/utils/misc"
import { genError } from "core/utils/string"
import MarkSubmitProctored from "domain/useCase/User/Contest/MarkSubmitProctored"
import getAllQuestions from "domain/useCase/User/Contest/GetAllQuestions"
import getAllMcqQuestions from "domain/useCase/User/Contest/GetAllMcqQuestions"
import MarkExamSubmitProctored from "domain/useCase/User/Contest/MarkExamSubmitProctored"
import GetExamDetails from "domain/useCase/Admin/Contest/GetExamDetails"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import { GetExamCodingQuestion } from "domain/useCase/Student/Editor/GetExamCodingQuestion"
import { GetExamMcqQuestion } from "domain/useCase/Student/Editor/GetExamMcqQuestion"
import { GetExamSubCaseStudyQuestion } from "domain/useCase/Student/Editor/GetExamSubjectiveCasestudyQuestion"
import { CreateExamCodeSubmission } from "domain/useCase/Student/Editor/CreateExamCodeSubmission"
import { GetExamQuestions } from "domain/useCase/Student/Editor/GetExamQuestions"
import { Auth } from "firebase/auth"
import GetQuestionwiseSubmission from "domain/useCase/User/Contest/GetQuestionwiseSubmission"
import GetMcqIdFromName from "domain/useCase/User/Contest/GetMcqIdFromName"
export default function ContestQuestionwiseSubmissionViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { contest_id } = useParams()
  const navigate = useNavigate()

  const { auth } = useAuth()
  const [problemTemplates, setProblemTemplates] = useLocalStorage<any>("problems", {})
  const [language, setLanguage] = useLocalStorage<string>("language", "cpp")

  const [code, setCode] = React.useState<any>("")
  const [templates, setTemplates] = React.useState<any>({})
  const [question, setQuestion] = React.useState<any>({})
  const [questionWithAnswer, setQuestionWithAnswer] = React.useState<any>({})
  const [sizes, setSizes] = React.useState(["40%", "60%"])
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)
  const [isTemplateLoaded, setIsTemplateLoaded] = React.useState(false)
  const [isQuestionLoaded, setIsQuestionLoaded] = React.useState(false)
  const [submissions, setSubmissions] = React.useState<any>([])
  const [isCodeRunning, setIsCodeRunning] = React.useState(false)
  const [isCodeSubmitting, setIsCodeSubmitting] = React.useState(false)
  const [runCodeDetails, setRunCodeDetails] = React.useState<any>({})
  const [submitCodeDetails, setSubmitCodeDetails] = React.useState<any>({})
  const [isAllSubmissionsLoading, setIsAllSubmissionsLoading] = React.useState<boolean>(false)
  const [recEndTimestamp, setRecTimestamp] = React.useState<number>(0)
  const [contestData, setContestData] = React.useState<any>({})
  const [endTimestamp, setEndTimestamp] = React.useState<number>(0)
  const [codingProblems, setCodingProblems] = React.useState<any[]>([])
  const [mcqQuestions, setMcqQuestions] = React.useState<any[]>([])
  const [allQuestions, setAllQuestions] = useLocalStorage<any>("allQuestions", [])
  const [activeMcqQuestionIndex, setActiveMcqQuestionIndex] = React.useState<any>(0)
  const [activeMcqQuestionDetails, setActiveMcqQuestionDetails] = React.useState<any>({})
  const [activeCodingTabIndex, setActiveCodingTabIndex] = React.useState<any>(0)
  const [activeCodingProblemIndex, setActiveCodingProblemIndex] = React.useState<any>(0)
  const [, setActiveCodingProblemDetails] = React.useState<any>({})
  const [isFetchingActiveMcqQuestion, setIsFetchingActiveMcqQuestion] = React.useState<boolean>(false)
  const [showRunCodeDetails, setShowRunCodeDetails] = React.useState<boolean>(false)
  const [resetingTemplate, setResetingTemplate] = React.useState<boolean>(false)
  const [isMcqSubmittingWithId, setIsMcqSubmittingWithId] = React.useState<any>(-1)
  const [batchMcqSolution, setBatchMcqSolution] = React.useState<any>({})
  const [isAllMcqSubmitting, setIsAllMcqSubmitting] = React.useState<boolean>(false)
  const [exitPopupOpen, setExitPopupOpen] = React.useState<boolean>(false)
  const [recordedContest, setRecordedContest] = React.useState<boolean>(false)
  const [studentContestDetails, setStudentContestDetails] = React.useState<any>({})
  const [codingProblemSolution, setCodingProblemSolution] = React.useState<any>([])
  const [subjectiveQuestions, setSubjectiveQuestions] = React.useState<any[]>([])
  const [submitCodingQuestion, setSubmitCodingQuestion] = React.useState<boolean>(false)
  const [casestudyQuestions, setCasestudyQuestions] = React.useState<any[]>([])
  const [subjectiveQuestion, setSubjectiveQuestion] = React.useState<any>("")
  const [casestudyQuestion, setCasestudyQuestion] = React.useState<any>("")
  const [activeSubjectiveQuestionIndex, setActiveSubjectiveQuestionIndex] = React.useState<number>(0)
  const [activeCasestudyQuestionIndex, setActiveCasestudyQuestionIndex] = React.useState<number>(0)

  const [subjectiveQuestionSolutions, setSubjectiveQuestionSolutions] = React.useState<any[]>([])
  const [casestudyQuestionSolutions, setCasestudyQuestionSolutions] = React.useState<any[]>([])

  const [isPast, setIsPast] = React.useState<boolean>(false)
  const [isMock, setIsMock] = React.useState<boolean>(false)
  const [reattemptedContest, setReattemptedContest] = React.useState<boolean>(false)
  const [isApproved, setIsApproved] = React.useState<boolean>(true)
  const [isContestSubmitting, setIsContestSubmitting] = React.useState<boolean>(false)
  const [id, setId] = React.useState<any>(null)
  const [blurScreen, setBlurScreen] = React.useState(true)
  const [flag, setFlag] = React.useState<number>(-1)
  const [questionsLocally, setQuestionsLocally] = useLocalStorage<any>("coding_questions", [])
  const [mcqQuestionsLocally, setMcqQuestionsLocally] = useLocalStorage<any>("mcq_questions", [])
  const [startTime, setStartTime] = React.useState<number>(0)
  const [selectedOption, setSelectedOption] = React.useState({}) as any
  const [contestDetails, setContestDetails] = useLocalStorage<any>(`contest-${contest_id}`, {})
  const [examType, setExamType] = React.useState("regular")

  const current_timestamp = Math.floor(Date.now() / 1000)

  const importTemplateCodeUseCase = new ImportTemplateCode(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getQuestionUseCase = new GetQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getQuestionWithAnswerUseCase = new GetQuestionWithAnswer(
    new EditorRepositoryImpl(new EditorAPIDataSourceImpl())
  )

  const getSubmissionsUseCase = new GetSubmissions(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))
  const getExamQuestionSubmissionsUseCase = new GetExamQuestionSubmissions(
    new EditorRepositoryImpl(new EditorAPIDataSourceImpl())
  )

  const getSubmissionUseCase = new GetSubmission(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))

  const getMcqQuestionDetailsUseCase = new GetMcqQuestionDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const getMcqIdFromNameUseCase = new GetMcqIdFromName(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getExamMcqQuestionUseCase = new GetExamMcqQuestion(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))
  const getExamQuestionsUseCase = new GetExamQuestions(new EditorRepositoryImpl(new EditorAPIDataSourceImpl()))
  const getCodingQuestionDetailsUseCase = new GetCodingQuestionDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  const getAllQuestionsUseCase = new getAllQuestions(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const getAllMcqQuestionsUseCase = new getAllMcqQuestions(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  const getStudentExamDetailsUseCase = new GetStudentExamDetails(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const getExamDetailsUseCase = new GetExamDetails(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))

  const GetQuestionwiseSubmissionUseCase = new GetQuestionwiseSubmission(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const [questionwiseSubmissions, setQuestionwiseSubmissions] = React.useState<any>({})
  const [selectedQuestionId, setSelectedQuestionId] = React.useState<string | null>(null)
  const [live_submissions, setLiveSubmissions] = React.useState<any>([])
  const [recorded_submissions, setRecordedSubmissions] = React.useState<any>([])
  const [live_response, setLiveResponse] = React.useState<any>(null)
  const [recorded_response, setRecordedResponse] = React.useState<any>({})
  async function getQuestionwiseSubmissionData(email: string, examId: string, questionId: string) {
    try {
      const response = await GetQuestionwiseSubmissionUseCase.invoke(auth, email, examId, questionId)
      if (response?.data?.type === "coding") {
        setQuestionwiseSubmissions((prev: any) => ({
          ...prev,
          [questionId]: response?.data,
        }))
        setSelectedQuestionId(questionId)
        setLiveSubmissions(response?.data?.live_submission || [])
        setRecordedSubmissions(response?.data?.recorded_submission || [])
      } else {
        setLiveResponse(response?.data?.live_submission || {})
        setRecordedResponse(response?.data?.recorded_submission || {})
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchDefaultTemplateCode = async (type = "") => {
    if (type === "reset") setResetingTemplate(true)

    const response = await importTemplateCodeUseCase.invoke(id.trim(), auth)

    if (type === "reset") setResetingTemplate(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching template code"))
      return null
    }

    setCode(response?.data[language])
    setTemplates(response?.data)
    setProblemTemplates({
      ...problemTemplates,
      ...(!!id ? { [id]: response?.data } : {}),
    })

    return response
  }

  const handleActiveTabChange = () => {
    setActiveCasestudyQuestionIndex((prev: any) => (typeof prev === "number" ? prev + 1 : 0))
  }

  const fetchTemplateCode = async () => {
    if (problemTemplates.hasOwnProperty(id) && Object.keys(problemTemplates[id]).length > 0) {
      setCode(problemTemplates[id][language])
      setTemplates(problemTemplates[id])
      setIsTemplateLoaded(true)
      return
    }

    setIsTemplateLoaded(false)
    const response = await fetchDefaultTemplateCode()
    setIsTemplateLoaded(true)

    setCode(response?.data[language])
    setTemplates(response?.data)
    setProblemTemplates({
      ...problemTemplates,
      ...(!!id ? { [id]: response?.data } : {}),
    })
  }

  const fetchQuestion = async (questionId: string) => {
    setIsQuestionLoaded(false)
    const response = await getQuestionUseCase.invoke(auth, questionId, true)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching question"))
      return
    }

    setQuestion(response?.data)
    setIsQuestionLoaded(true)
  }

  const fetchExamQuestions = async () => {
    setIsQuestionLoaded(false)
    if (allQuestions.length > 0) {
      setAllQuestions(allQuestions)
      setIsQuestionLoaded(true)
      return // Exit if data is already cached
    }
    const response = await getExamQuestionsUseCase.invoke(auth, contest_id as string)
    setIsQuestionLoaded(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching question"))
      return
    }

    setAllQuestions(response?.data)
  }

  const fetchQuestionWithAnswer = async (questionID: string) => {
    setIsQuestionLoaded(false)
    const response = await getQuestionWithAnswerUseCase.invoke(auth, questionID, true)
    setIsQuestionLoaded(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching question"))
      return
    }

    setQuestionWithAnswer(response?.data)
  }
  const handleCodingTabChange = (index: number) => {
    setActiveCodingTabIndex(index)
  }

  const fetchSubmissions = async (topic: any) => {
    setIsAllSubmissionsLoading(true)
    const response = await getExamQuestionSubmissionsUseCase.invoke(auth, topic, contest_id as string)
    setIsAllSubmissionsLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching submissions"))
      return
    }
    const contestStartTime = new Date(contestData?.start_time * 1000)
    const contestEndTime = new Date(contestStartTime.getTime() + contestData?.duration * 60 * 1000)
    if (reattemptedContest) {
      setSubmissions(response?.data?.filter((submission: any) => submission?.total_testcase !== -1)?.reverse())
      setFlag((f) => f + 1)
    } else {
      if (contestData?.flowType == "newFlow") {
        setSubmissions(response?.data?.reverse())
      } else {
        setSubmissions(
          response?.data
            ?.filter((submission: any) => {
              const submissionTime = new Date(submission?.time)
              return (
                submission?.total_testcase !== -1 &&
                submissionTime > contestStartTime &&
                submissionTime <= contestEndTime
              )
            })
            ?.reverse()
        )
        setFlag((f) => f + 1)
      }
    }
    setFlag((f) => f + 1)
  }
  React.useEffect(() => {
    const contestUrl = window.location.pathname.split("/")?.[2]
    if (contestUrl === "mock") {
      setIsMock(true)
    }
  }, [isMock])

  const fetchStudentContestDetails = async () => {
    if (contestDetails?.flowType == "newFlow") {
      let response
      if (isMock) {
        response = await getStudentExamDetailsUseCase.invoke(auth, (contest_id as string) + "_mock")
      } else {
        response = await getStudentExamDetailsUseCase.invoke(auth, contest_id as string)
      }
      if (response?.data?.flowType && response?.data?.flowType === "newFlow") {
        if (response?.data?.exam_end_timestamp) {
          setEndTimestamp(parseInt(response?.data?.exam_end_timestamp) * 1000)
          setRecTimestamp(parseInt(response?.data?.exam_end_timestamp) * 1000)
        }
        if (
          response?.data?.submitted === true &&
          contestDetails?.start_time + contestDetails?.duration * 60 >= current_timestamp
        ) {
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, "This contest has already been submitted")
          await delay(2000)
          navigate("/weekly-test-series")
        }

        let approvalField = response?.data?.is_approved ? response?.data?.is_approved : true
        setIsApproved(approvalField)
        const approval_check = response?.data?.is_approved === true
        const batchMcqStatus: any = {}
        const selectedOptionData: any = {}
        const codingProblemSolutionData: any = {}
        const subjectiveProblemSolutionData: any = {}
        const _subjectiveProblemSolutionData: any = []
        for (const mcqKey of Object.keys(response?.data?.mcq_question_status)) {
          // if (approval_check) {
          batchMcqStatus[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.user_solution
          selectedOptionData[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.user_solution
          // }
        }

        for (const problemKey of Object.keys(response?.data?.coding_question_status)) {
          // if (approval_check) {
          codingProblemSolutionData[problemKey] = response?.data?.coding_question_status[problemKey]?.status
          // }
        }

        for (const problemKey of Object.keys(response?.data?.subjective_question_status)) {
          // if (approval_check) {
          subjectiveProblemSolutionData[problemKey] =
            response?.data?.subjective_question_status?.[problemKey]?.user_solution
          // }
        }

        for (let index = 0; index < subjectiveQuestions.length; index++) {
          const key = subjectiveQuestions[index]?.key
          _subjectiveProblemSolutionData[index] = subjectiveProblemSolutionData[key]
        }

        const casestudyProblemSolutionData: any = {}
        const _casestudyProblemSolutionData: any = []

        for (const problemKey of Object.keys(response?.data?.casestudy_question_status)) {
          // if (approval_check) {
          casestudyProblemSolutionData[problemKey] =
            response?.data?.casestudy_question_status[problemKey]?.user_solution
          // }
        }

        for (let index = 0; index < casestudyQuestions.length; index++) {
          const key = casestudyQuestions[index]?.key
          _casestudyProblemSolutionData[index] = casestudyProblemSolutionData[key]
        }
        // if (approval_check) {
        //   const endTimestamp =
        //     (parseInt(response?.data?.current_timestamp) + parseInt(response?.data?.duration) * 60) * 1000
        // setEndTimestamp(endTimestamp)
        // setRecTimestamp(endTimestamp)
        // }
        setSubmissions(
          response?.data?.coding_question_status?.[codingProblems[activeCodingProblemIndex]?.key]?.submissions
            ?.filter((submission: any) => submission?.total_testcase !== -1)
            ?.reverse()
        )
        setStudentContestDetails(response?.data)
        setBatchMcqSolution(batchMcqStatus)
        setSelectedOption(selectedOptionData)
        setCodingProblemSolution(codingProblemSolutionData)
        setSubjectiveQuestionSolutions(_subjectiveProblemSolutionData)
        setCasestudyQuestionSolutions(_casestudyProblemSolutionData)
        // setStartTime(currentTimestamp)
        // setRecTimestamp(currentTimestamp + response?.data?.duration * 60)
      }
    } else {
      let retryCount = 0 // Counter for retry attempts
      const MAX_RETRY = 1 // Maximum retry attempts
      const makeApiCall = async (): Promise<void> => {
        // Perform the API call
        const response = await getStudentExamDetailsUseCase.invoke(auth, (contest_id as string) + "recorded")
        // If the call is unsuccessful, retry once and show toast only on the second failure
        if (!response?.success) {
          if (retryCount < MAX_RETRY) {
            retryCount++ // Increment retry counter
            return await makeApiCall() // Retry the API call
          } else {
            // If the retry also fails, show the toast and return
            changeToastVisibility(true)
            changeToastDetails(STR_FAILURE, genError(response, "Error in fetching student contest details"))
            return
          }
        }
        let approvalField = response?.data?.is_approved ? response?.data?.is_approved : true
        setIsApproved(approvalField)
        if (response?.data?.["rec_contest_start_timestamp"]) {
          setReattemptedContest(true)
        }
        if (response?.data?.submitted === true && !response?.data?.["rec_contest_start_timestamp"]) {
          changeToastVisibility(true)
          changeToastDetails(STR_FAILURE, "This contest has already been submitted")
          await delay(60000)
          navigate("/weekly-test-series")
        }
        const recorded_contest_check = "rec_contest_start_timestamp" in response?.data
        const batchMcqStatus: any = {}
        const selectedOptionData: any = {}

        for (const mcqKey of Object.keys(response?.data?.mcq_question_status)) {
          if (recorded_contest_check) {
            batchMcqStatus[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.rec_user_solution
            selectedOptionData[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.rec_user_solution
          } else {
            batchMcqStatus[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.user_solution
            selectedOptionData[mcqKey] = response?.data?.mcq_question_status[mcqKey]?.user_solution
          }
        }

        const codingProblemSolutionData: any = {}
        const subjectiveProblemSolutionData: any = {}
        const _subjectiveProblemSolutionData: any = []

        for (const problemKey of Object.keys(response?.data?.coding_question_status)) {
          if (recorded_contest_check) {
            codingProblemSolutionData[problemKey] = response?.data?.coding_question_status[problemKey]?.rec_status
          } else {
            codingProblemSolutionData[problemKey] = response?.data?.coding_question_status[problemKey]?.status
          }
        }

        for (const problemKey of Object.keys(response?.data?.subjective_question_status)) {
          if (recorded_contest_check) {
            subjectiveProblemSolutionData[problemKey] =
              response?.data?.subjective_question_status?.[problemKey]?.rec_user_solution
          } else {
            subjectiveProblemSolutionData[problemKey] =
              response?.data?.subjective_question_status[problemKey]?.user_solution
          }
        }

        for (let index = 0; index < subjectiveQuestions.length; index++) {
          const key = subjectiveQuestions[index]?.key
          _subjectiveProblemSolutionData[index] = subjectiveProblemSolutionData[key]
        }

        const casestudyProblemSolutionData: any = {}
        const _casestudyProblemSolutionData: any = []

        for (const problemKey of Object.keys(response?.data?.casestudy_question_status)) {
          if (recorded_contest_check) {
            casestudyProblemSolutionData[problemKey] =
              response?.data?.casestudy_question_status[problemKey]?.rec_user_solution
          } else {
            casestudyProblemSolutionData[problemKey] =
              response?.data?.casestudy_question_status[problemKey]?.user_solution
          }
        }

        for (let index = 0; index < casestudyQuestions.length; index++) {
          const key = casestudyQuestions[index]?.key
          _casestudyProblemSolutionData[index] = casestudyProblemSolutionData[key]
        }

        if (recorded_contest_check) {
          const endTimestamp =
            (parseInt(response?.data?.rec_contest_start_timestamp) + parseInt(response?.data?.duration) * 60) * 1000
          setEndTimestamp(endTimestamp)
          setRecTimestamp(endTimestamp)
        }

        if (recorded_contest_check) {
          const endTimestamp =
            (parseInt(response?.data?.rec_contest_start_timestamp) + parseInt(response?.data?.duration) * 60) * 1000
          setEndTimestamp(endTimestamp)
          setRecTimestamp(endTimestamp)
        }

        setBatchMcqSolution(batchMcqStatus)
        setSelectedOption(selectedOptionData)
        setCodingProblemSolution(codingProblemSolutionData)
        setSubjectiveQuestionSolutions(_subjectiveProblemSolutionData)
        setCasestudyQuestionSolutions(_casestudyProblemSolutionData)
        setStudentContestDetails(response?.data)
      }
      await makeApiCall()
    }
    // }
    //
  }
  const fetchContestDetails = async () => {
    const response = await getExamDetailsUseCase.invoke(auth, contest_id as string)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching contest details"))
      return
    }

    const contest = response?.data
    setContestData(contest)
    let codingQuestions = contest?.question_lis || []
    let subjectiveQuestions = contest?.subjective_lis || []
    let casestudyQuestions = contest?.casestudy_lis || []
    let mcqQuestions = contest?.mcq_lis || []
    setCodingProblems(codingQuestions)
    setSubjectiveQuestions(subjectiveQuestions)
    setCasestudyQuestions(casestudyQuestions)
    setMcqQuestions(mcqQuestions)
    // setAllQuestions([...codingQuestions, ...mcqQuestions, ...subjectiveQuestions, ...casestudyQuestions])
    setId(contest?.question_lis?.[activeCodingProblemIndex]?.key)

    const currentTimestamp = Date.now()
    const endTimestamp = (parseInt(contest?.start_time) + parseInt(contest?.duration) * 60) * 1000
    if (endTimestamp >= currentTimestamp) {
      setEndTimestamp(endTimestamp)
    }
    if (currentTimestamp > endTimestamp) {
      setRecordedContest(true)
    }
    const startTimestamp = parseInt(contest?.start_time) * 1000
    const duration = parseInt(contest?.duration) * 60
    const endTimestamps = startTimestamp + duration * 1000
    setIsPast(currentTimestamp > endTimestamps)
    // setExamType("regular")
  }
  const fetchMockContestDetails = async () => {
    const response = await getExamDetailsUseCase.invoke(auth, contest_id as string)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching contest details"))
      return
    }

    const contest = response?.data
    setContestData(contest)
    let codingQuestions = contest?.question_lis || []
    let subjectiveQuestions = contest?.subjective_lis || []
    let casestudyQuestions = contest?.casestudy_lis || []
    let mcqQuestions = contest?.mcq_lis || []
    setCodingProblems(codingQuestions)
    setSubjectiveQuestions(subjectiveQuestions)
    setCasestudyQuestions(casestudyQuestions)
    setMcqQuestions(mcqQuestions)
    // setAllQuestions([...codingQuestions, ...mcqQuestions, ...subjectiveQuestions, ...casestudyQuestions])
    setId(contest?.question_lis?.[activeCodingProblemIndex]?.key)

    const currentTimestamp = Date.now()
    const endTimestamp = (parseInt(contest?.start_time) + parseInt(contest?.duration) * 60) * 1000
    if (endTimestamp >= currentTimestamp) {
      setEndTimestamp(endTimestamp)
    }
    if (currentTimestamp > endTimestamp) {
      setRecordedContest(true)
    }
    const startTimestamp = parseInt(contest?.start_time) * 1000
    const duration = parseInt(contest?.duration) * 60
    const endTimestamps = startTimestamp + duration * 1000
  }
  const fetchAllMcqQuestions = async () => {
    const response = await getAllMcqQuestionsUseCase.invoke(auth, contest_id)
    setMcqQuestionsLocally(response?.data)
    setActiveMcqQuestionDetails(response?.data?.[0])
  }
  const [mcqId, setMcqId] = React.useState<any>(null)
  const fetchMcqId = async (name: string) => {
    const decodeURIName = decodeURIComponent(name)
    const response = await getMcqIdFromNameUseCase.invoke(auth, decodeURIName)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
      return
    }
    setMcqId(response?.data)
  }
  const fetchMcqQuestionDetails = async (id: string) => {
    setIsFetchingActiveMcqQuestion(true)
    const response = await getMcqQuestionDetailsUseCase.invoke(auth, id)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
      return
    }
    setActiveMcqQuestionDetails(response?.data)

    setIsFetchingActiveMcqQuestion(false)
  }

  const fetchAllQuestions = async () => {
    const response = await getAllQuestionsUseCase.invoke(auth, contest_id)
    setQuestionsLocally(response?.data)
    setQuestion(response?.data?.[0])
  }

  const fetchCodingProblemDetails = async () => {
    const response = await getCodingQuestionDetailsUseCase.invoke(auth, codingProblems[activeCodingProblemIndex]?.key)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response, "Error in fetching MCQ question details"))
      return
    }

    setActiveCodingProblemDetails(response?.data)
  }

  const fetchProblemData = () => {
    // fetchQuestion()
    fetchTemplateCode()
  }

  const handleGetSubmission = async (token: string, callback?: any) => {
    const response = await getSubmissionUseCase.invoke(auth, token)
    if (
      response?.data?.description !== "In Queue" &&
      response?.data?.description !== "Processing" &&
      typeof callback === "function"
    ) {
      callback()

      const allTCPassed =
        !isEmpty(response?.data?.stdout) &&
        response?.data?.stdout?.length > 0 &&
        response?.data?.stdout?.every((item: any) => item?.result === 1)

      const status = allTCPassed ? "AC" : "WA"

      setCodingProblemSolution((prev: any) => ({
        ...prev,
        [id as string]: status,
      }))

      setIsCodeRunning(false)
      setIsCodeSubmitting(false)
    }

    return response
  }

  const handleSizeChange = (newSizes: string[]) => {
    setSizes(newSizes)
  }

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value)
  }

  const handleCodeChange = (code: string | undefined) => {
    setCode(code)
    setTemplates((templates: any) => ({
      ...templates,
      [language]: code,
    }))
    setProblemTemplates((problemTemplates: any) => ({
      ...problemTemplates,
      ...(!!id
        ? {
            [id]: {
              ...problemTemplates[id],
              [language]: code,
            },
          }
        : {}),
    }))
  }

  const handleActiveMcqQuestion = (index: number) => {
    setActiveMcqQuestionIndex(index)
  }

  return {
    id,
    code,
    toast,
    sizes,
    templates,
    question,
    questionWithAnswer,
    language,
    isPast,
    isApproved,
    submissions,
    isCodeRunning,
    activeTabIndex,
    runCodeDetails,
    submitCodeDetails,
    isTemplateLoaded,
    isQuestionLoaded,
    isCodeSubmitting,
    isAllSubmissionsLoading,
    contestData,
    codingProblems,
    mcqQuestions,
    activeMcqQuestionIndex,
    activeMcqQuestionDetails,
    activeCodingTabIndex,
    isFetchingActiveMcqQuestion,
    showRunCodeDetails,
    resetingTemplate,
    isMcqSubmittingWithId,
    batchMcqSolution,
    isAllMcqSubmitting,
    exitPopupOpen,
    studentContestDetails,
    codingProblemSolution,
    isContestSubmitting,
    blurScreen,
    endTimestamp,
    flag,
    activeCodingProblemIndex,
    activeSubjectiveQuestionIndex,
    activeCasestudyQuestionIndex,
    subjectiveQuestions,
    casestudyQuestions,
    subjectiveQuestion,
    casestudyQuestion,
    subjectiveQuestionSolutions,
    casestudyQuestionSolutions,
    recordedContest,
    recEndTimestamp,
    submitCodingQuestion,
    fetchAllQuestions,
    changeToastDetails,
    fetchStudentContestDetails,
    fetchDefaultTemplateCode,
    fetchCodingProblemDetails,
    fetchMcqQuestionDetails,
    fetchAllMcqQuestions,
    handleActiveMcqQuestion,
    fetchContestDetails,
    handleLanguageChange,
    handleSizeChange,
    handleActiveTabChange,
    handleCodeChange,
    fetchProblemData,
    fetchQuestionWithAnswer,
    fetchSubmissions,
    changeToastVisibility,
    setActiveTabIndex,
    setActiveSubjectiveQuestionIndex,
    setActiveCasestudyQuestionIndex,
    setQuestion,
    questionsLocally,
    setIsCodeRunning,
    setIsCodeSubmitting,
    reattemptedContest,
    STR_FAILURE,
    setMcqQuestions,
    mcqQuestionsLocally,
    setActiveMcqQuestionDetails,
    startTime,
    selectedOption,
    setSelectedOption,
    fetchExamQuestions,
    allQuestions,
    isMock,
    setIsMock,
    // activeQuestionIndex,
    fetchMockContestDetails,
    examType,
    getQuestionwiseSubmissionData,
    questionwiseSubmissions,
    selectedQuestionId,
    live_submissions,
    recorded_submissions,
    fetchQuestion,
    fetchMcqId,
    mcqId,
    live_response,
    recorded_response,
    handleCodingTabChange,
  }
}
