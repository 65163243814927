import StandaredSelect from "core/components/StandaredSelect"
import Button from "core/components/new/Button"
import useLocalStorage from "core/hooks/useLocalStorage"
import DashboardLayout from "core/layouts/DashboardLayout"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import React from "react"
import { useNavigate } from "react-router-dom"
import ScheduledClassesViewModel from "../ViewScheduledClasses/ScheduledClassesViewModel"
import Input from "core/components/Input"
import useMentorshipViewModel from "./ContestFlowViewModel"

export default function ContestFlowView() {
  const {
    // genericBatchTypes,
    // genericStudentTypes,
    // genericBatchType,
    // genericStudentType,
    genericTemplateType,
    genericTemplateTypeList,
    fetchGenericTemplateTypeList,
    studentType,
    studentEmail,
    // isStudentTypeDisabled,
    batch,
    handleGenericBatchChangeType,
    // handleGenericStudentChangeType,
    handleBatchChange,
    handleStudentEmailChange,
    handleStudentChangeType,
    navigateToEditGeneric,
    navigateToEdit,
    navigateToEditByStudent,
    navigateToCreateGeneric,
    getBatchList,
    getBatches,
    templateId,
  } = useMentorshipViewModel()

  React.useEffect(() => {
    fetchGenericTemplateTypeList()
    getBatches()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">Create and update Exam templates here</h2>
          <p className="">You have access to Exam Flow Templates here.</p>
        </div>
        <Button onClick={navigateToCreateGeneric}>Create a new template</Button>
        <StandaredSelect
          required
          id="template"
          options={genericTemplateTypeList || ""}
          placeholder="Select exam template to edit"
          value={genericTemplateType}
          onChange={handleGenericBatchChangeType}
        />

        <Button onClick={navigateToEditGeneric}>Edit Exam Template</Button>
        <div className="space-y-1">
          <h2 className="text-new-accent">Update exam templates for a particular batch</h2>
          <p className="">You have access to Exam Flow Templates here.</p>
        </div>
        <StandaredSelect
          required
          id="Batch"
          options={getBatchList}
          placeholder="Select Batch"
          value={batch}
          onChange={handleBatchChange}
        />

        <Button onClick={navigateToEdit}>Edit Batch Template</Button>
      </div>
    </DashboardLayout>
  )
}
