import React, { useEffect, useRef } from "react"
import useCourseSessionViewModel from "./CourseSessionViewModel"
import useCourseViewModel from "./CourseViewModel"
import RecordedSessionFeedback from "./components/RecordedSessionFeedback"
import ClassFeedback from "./components/ClassFeedback"
import UserFeedbackCard from "./components/UserFeedbackCard"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import CourseDisclosure from "./components/CourseDisclosure"
import CourseSidebar from "./components/CourseSidebar"
import { cn } from "core/lib/utils"
import Loader from "core/components/Loader"
import { STR_VIEW_NOTES, STR_VIEW_SUMMARY, STR_VIEW_PRE_REQUISITES, STR_VIEW_SHORTS } from "core/constants/strings"
import { CheckSuccessIcon } from "core/constants/svgs"
import Modal from "core/components/Modal"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import SubmissionResponse from "./components/SubmissionResponse"
import { ReactComponent as SaveIcon } from "assets/svgs/save.svg"
import { ReactComponent as UnsaveIcon } from "assets/svgs/unsaved_icon.svg"
import { ReactComponent as StarIcon } from "assets/svgs/star.svg"
import { ReactComponent as FrameIcon } from "assets/svgs/Frame.svg"
import { ReactComponent as GreenCheck } from "assets/svgs/green_check.svg"
import { ReactComponent as GreyCheck } from "assets/svgs/grey_check.svg"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import Tags from "core/components/v2/Tags"
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeader } from "core/components/v2/Table"
import { Link, useSearchParams } from "react-router-dom"
import TableButton from "core/components/v2/TableButton"
import ArrowBack from "core/components/v2/ArrowBack"
import { Head } from "core/components/seo"
import AISubmissionResponse from "./components/AISubmissionResponse"
import StudentFeedback from "./components/StudentFeedback"
import TabGroup from "core/components/payment-method/TabGroup"
import { CustomScroll } from "react-custom-scroll"
import { ChatModal } from "./components/ChatModal"

export default function CourseSession() {
  const {
    toast,
    assignmentsTableContent,
    warmupTableContent,
    homeworkTableContent,
    practiceTableContent,
    sessionDetails,
    setSessionDetails,
    courseLectures,
    loading,
    activeIndex,
    isClassFeedbackGiven,
    sessionId,
    loadingTable,
    sessionList,
    courseName,

    feedbackData,
    isRecordedFeedbackVisible,
    isViewSubmissionVisible,
    order,
    questionName,
    isLoading,
    questionType,
    feedbackResponseData,
    selectedQuestion,
    tab,
    assignmenTab,
    TABS,
    AssignmentTabs,
    isChatbotOpen,
    closeChatbot,
    chatbotResponse,
    fetchChatbotResponse,
    chatShort,
    handleChatClick,
    onLectureClick,
    fetchSessionDetails,
    fetchCourseLectures,
    getFeedbackDetails,
    setIsRecordedFeedbackVisible,
    submitRecordingFeedback,
    changeToastVisibility,
    handleSidebarData,
    changeTableData,
    navigate,
    handleOnCloseBtnModalClick,
    fetchViewSubmission,
    submitClassFeedbackNew,
    setTab,
    handleTabChange,
    handleAssignmentTabChange,
    fetchCourse,
    fetchDsmlBeginnerModuleSessions,
    fetchAttendanceAndAssignments,
    toggleRecordedComplete,
    fetchMasterClass,
    handleSelectedQuestionBtnClick,
    isExternalProblem,
    handleFeedbackBtnClick,
    handleSubjectiveFeedbackBtnClick,
    isViewSubjectiveSubmissionVisible,
    handleOnCloseBtnSubmitModalClick,
    rateAIModal,
    fetchViewSubjectiveSubmission,
    setIsStudentFeedbackVisible,
    isStudentFeedbackVisible,
    subjectivefeedbackResponseData,
    handleOnCloseFeedbackBtnClick,
    submitStudentFeedback,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    classFeedback,
    getClassFeedbackNew,
    handleSaveClick,
    handleReadShort,
  } = useCourseSessionViewModel()

  const [isFrameLoading, setIsFrameLoading] = React.useState(true)
  const [notesIndex, setNotesIndex] = React.useState<any>(3)
  const [shortsIndex, setShortsIndex] = React.useState<any>(4)
  const [timerId, setTimerId] = React.useState<number | null>(null)
  const [chapterShorts, setChapterShorts] = React.useState<{ title: string; details: string }[]>([])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFrameLoading(false)
    }, 6000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (sessionDetails?.shorts) {
      setChapterShorts(sessionDetails?.shorts)
    }
  }, [sessionDetails])

  useEffect(() => {
    fetchSessionDetails()
    setTab(0)
    getClassFeedbackNew(sessionId)
  }, [sessionId])

  const startTimer = (): number => {
    let timeLeft = 60
    let readindex = 0
    const shorts = sessionDetails?.shorts || []

    const currentShort = shorts[readindex]

    const timer = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft -= 1
      } else {
        if (readindex < shorts.length) {
          shorts[readindex].is_read = true

          const currentShort = shorts[readindex]
          handleReadShort(sessionId, sessionDetails.session_name, currentShort.title)
          setSessionDetails((prev: any) =>
            prev
              ? {
                  ...prev,
                  shorts,
                }
              : null
          )

          readindex += 1
          timeLeft = 60
        } else {
          clearTimeout(timer)
        }
      }
    }, 1000)

    return timer as unknown as number
  }

  useEffect(() => {
    if (tab === shortsIndex && sessionDetails?.shorts && sessionDetails?.shorts.length > 0) {
      if (timerId) {
        clearInterval(timerId)
        setTimerId(null)
        const newTimerId = startTimer()
        setTimerId(newTimerId)
      } else if (!timerId) {
        const newTimerId = startTimer()
        setTimerId(newTimerId)
      }
    } else {
      if (timerId) {
        clearInterval(timerId)
        setTimerId(null)
      }
    }

    return () => {
      if (timerId) {
        clearInterval(timerId)
        setTimerId(null)
      }
    }
  }, [tab, sessionId])

  useEffect(() => {
    if (sessionList[sessionId]) {
      fetchCourseLectures()
      handleSidebarData()
      // getFeedbackDetails(sessionId)
    }
  }, [sessionList])

  useEffect(() => {
    changeTableData(sessionDetails)
  }, [loadingTable, sessionDetails])

  useEffect(() => {
    if (isViewSubmissionVisible) {
      fetchViewSubmission(questionName, questionType)
    }
  }, [isViewSubmissionVisible, questionName, questionType])

  useEffect(() => {
    if (isViewSubjectiveSubmissionVisible && questionType === "subjective") {
      fetchViewSubjectiveSubmission(questionName)
    } else if (isViewSubmissionVisible) {
      fetchViewSubmission(questionName, questionType)
    }
  }, [isViewSubmissionVisible, isViewSubjectiveSubmissionVisible, questionName, questionType])

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const currentTabVisible = searchParams.get("tab")
    if (currentTabVisible) {
      setTab(parseInt(currentTabVisible, 0))
    }
  }, [searchParams])
  const duration = sessionDetails?.duration

  const isFeedbackGiven = classFeedback && typeof classFeedback === "object" && Object.keys(classFeedback).length > 0

  const handleSendChat = (input: string) => {
    fetchChatbotResponse(input + "Replace ** ** with <b> </b> in the response")
  }

  useEffect(() => {
    if (isFeedbackGiven === false) setIsRecordedFeedbackVisible(true)
    else {
      setIsRecordedFeedbackVisible(false)
    }
  }, [isFeedbackGiven])

  return (
    <DashboardLayoutv2>
      <Head title="Session" />
      {isChatbotOpen && (
        <ChatModal
          isOpen={isChatbotOpen}
          onClose={closeChatbot}
          response={chatbotResponse}
          onSendMessage={handleSendChat}
          shorts={chatShort}
        />
      )}
      <ConditionalLoader isLoading={loading} loader={<Loader />}>
        <div className="space-y-4 p-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <h3 className="text-new-accent">{sessionDetails?.session_name}</h3>
              <p>
                Duration:{" "}
                {typeof duration === "number"
                  ? `${Math.floor(duration / 3600)}h:${Math.floor((duration % 3600) / 60)}m:
                ${duration % 60}s`
                  : duration}
              </p>
            </div>
            <TableButton
              className="flex h-fit w-fit items-center justify-center gap-1"
              outlined
              onClick={() => navigate("/course")}
            >
              <ArrowBack />
              <p className="text-sm font-medium">Go Back</p>
            </TableButton>
          </div>
          <div className="flex gap-6">
            <div className="w-[72%] space-y-6">
              <div className="flex gap-2">
                {TABS.map((item: string, idx: number) => (
                  <Tags selected={tab === idx} onClick={() => handleTabChange(idx)}>
                    {item}
                  </Tags>
                ))}
              </div>
              {tab === 0 && (
                <div className="flex flex-col gap-[24px]">
                  <div className="h-calc((w-full)*0.5625) w-full">
                    {sessionDetails?.video_id ? (
                      <iframe
                        title="Video"
                        className="aspect-video h-full w-full rounded"
                        src={sessionDetails?.video_link}
                        allow="autoplay; fullscreen; picture-in-picture"
                      />
                    ) : (
                      <div className="flex aspect-video h-full w-full items-center justify-center font-medium">
                        <span>Video not available!</span>
                      </div>
                    )}
                  </div>
                  <div>
                    <UserFeedbackCard
                      course_name="d"
                      courseCompleted={true}
                      certificate_url=""
                      onFeedbackClick={() => {
                        setIsRecordedFeedbackVisible(!isRecordedFeedbackVisible)
                        getClassFeedbackNew(sessionId)
                      }}
                    ></UserFeedbackCard>
                  </div>

                  <div className="flex flex-wrap mt-[20px]">
                    {sessionDetails?.shorts.map((short: any, index: number) => (
                      <div
                        key={index}
                        className="w-full h-[173px] flex rounded-[8px] border border-0.5 border-shades-bw-gray-400 bg-white mb-[30px] rounded-tr-[8.3px] rounded-br-[8.3px]"
                      >
                        <div className="pb-[16px] pl-[16px] pr-[16px] pt-[12px] w-full">
                          <div className="flex justify-between items-center">
                            <h3 className="text-[14px] font-semibold text-[#333] font-inter mb-[3px]">
                              {short.title || `Module ${index + 1}`}
                            </h3>

                            {isFrameLoading ? (
                              <div className="w-[16px] h-[16px] flex items-center justify-center">
                                <Loader />
                              </div>
                            ) : (
                              <div className="flex items-center space-x-[4px]">
                                {short.is_saved ? (
                                  <FrameIcon className="w-[16px] h-[16px] text-[#333]" />
                                ) : (
                                  <div className="flex items-center space-x-[4px]">
                                    {short.is_saved ? (
                                      <FrameIcon className="w-[16px] h-[16px] text-[#333]" />
                                    ) : (
                                      <UnsaveIcon className="w-[16px] h-[16px] text-[#333]" />
                                    )}
                                    {short.is_read ? (
                                      <GreenCheck className="w-[16px] h-[16px]" title="Read" />
                                    ) : (
                                      <div className="flex items-center space-x-[4px]">
                                        {short.is_saved && <FrameIcon className="w-[16px] h-[16px] text-[#333]" />}
                                        {short.is_read ? (
                                          <GreenCheck className="w-[16px] h-[16px]" title="Read" />
                                        ) : (
                                          <GreyCheck className="w-[16px] h-[16px]" title="Unread" />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="w-full mt-[9px] pt-[7px] pb-[7px] pl-[10px] bg-[#F9F9F9] rounded-[4px] h-[110px]">
                            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                              <p className="text-[11px] mr-[11px] text-[#646464] font-[400] font-inter leading-normal">
                                {short.details || "No additional details available."}
                              </p>
                            </CustomScroll>
                          </div>

                          <div className="w-full mt-[9px] pt-[7px] pb-[7px] pl-[10px] bg-[#F9F9F9] rounded-[4px] h-[110px]">
                            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                              <p className="text-[11px] mr-[11px] text-[#646464] font-[400] font-inter leading-normal">
                                {short.details || "No additional details available."}
                              </p>
                            </CustomScroll>
                          </div>

                          <div className="flex justify-end">
                            <div className="h-[171px] w-[4px] bg-[#646464]"></div>
                            <div className="w-[31px] h-[171px] bg-black rounded-tr-[7.6px] rounded-br-[7.6px] pt-[6px] pl-[6px] pr-[6px]">
                              <div className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]">
                                <span className="text-white text-[10px] font-bold">{index + 1}</span>
                              </div>
                              <div className="mt-[98px] ">
                                <button
                                  className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]"
                                  onClick={() =>
                                    handleSaveClick(
                                      sessionDetails?.session_id,
                                      sessionDetails?.session_name,
                                      short.title,
                                      !short.is_saved
                                    )
                                  }
                                >
                                  <SaveIcon className="w-4 h-4 text-white" />
                                </button>
                                <button
                                  className="w-auto h-auto bg-[#FFFFFF4D] mt-[4px] flex items-center justify-center p-[2px] rounded-[4px]"
                                  onClick={() => handleChatClick(short.details)}
                                >
                                  <StarIcon className="w-4 h-4 text-white" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {tab === 1 && (
                <div>
                  <div className="flex gap-2 mb-[16px]">
                    {AssignmentTabs.map((item: any, idx: number) => (
                      <Tags selected={assignmenTab === item.key} onClick={() => handleAssignmentTabChange(item.key)}>
                        {item.value}
                      </Tags>
                    ))}
                  </div>
                  {assignmenTab === 0 && <div>{warmupTableContent?.content}</div>}

                  {assignmenTab === 1 && <div className="">{assignmentsTableContent?.content}</div>}

                  {assignmenTab === 2 && <div>{homeworkTableContent?.content}</div>}

                  {assignmenTab === 3 && <div>{practiceTableContent?.content}</div>}
                </div>
              )}
              {tab === 2 && (
                <div className="flex items-start gap-4">
                  {sessionDetails?.pre_requisite ? (
                    <>
                      <button className="mt-4 text-new-success">
                        <CheckSuccessIcon className="h-4 w-4" />
                      </button>
                      <CourseDisclosure name="Pre-requisites">
                        <Button
                          outlined
                          onClick={() => window.open(sessionDetails.pre_requisite, "_blank", "noopener,noreferrer")}
                        >
                          Pre-requisite Link
                        </Button>
                      </CourseDisclosure>
                    </>
                  ) : (
                    <div className="text-[#646464] text-sm font-medium">
                      No Pre-Requisite available for this session.
                    </div>
                  )}
                </div>
              )}

              {tab === 3 && (
                <div className="flex items-start gap-4">
                  {sessionDetails?.notes_link || sessionDetails?.notes_summary_link ? (
                    <>
                      <button className="mt-4 text-new-success">
                        <CheckSuccessIcon className="h-4 w-4" />
                      </button>
                      <CourseDisclosure name="Notes">
                        <div className="flex gap-2">
                          {sessionDetails?.notes_link && sessionDetails.notes_link.length > 0 && (
                            <a href={sessionDetails.notes_link} target="_blank" rel="noreferrer">
                              <Button outlined>{STR_VIEW_NOTES}</Button>
                            </a>
                          )}
                          {sessionDetails?.notes_summary_link && sessionDetails.notes_summary_link.length > 0 && (
                            <a href={sessionDetails.notes_summary_link} target="_blank" rel="noreferrer">
                              <Button outlined>{STR_VIEW_SUMMARY}</Button>
                            </a>
                          )}
                        </div>
                      </CourseDisclosure>
                    </>
                  ) : (
                    <div className="text-[#646464] text-sm font-medium">No Notes available for this session.</div>
                  )}
                </div>
              )}
              {tab === 4 && (
                <div className="flex flex-wrap">
                  {sessionDetails?.shorts && sessionDetails.shorts.length > 0 ? (
                    sessionDetails.shorts.map((short: any, index: number) => (
                      <div
                        key={index}
                        className="w-full h-[173px] flex rounded-[8px] border border-0.5 border-shades-bw-gray-400 bg-white mb-[30px] rounded-tr-[8.3px] rounded-br-[8.3px]"
                      >
                        <div className="pb-[16px] pl-[16px] pr-[16px] pt-[12px] w-full">
                          <div className="flex justify-between items-center">
                            <h3 className="text-[14px] font-semibold text-[#333] font-inter mb-[3px]">
                              {short.title || `Module ${index + 1}`}
                            </h3>

                            {isFrameLoading ? (
                              <div className="w-[16px] h-[16px] flex items-center justify-center">
                                <Loader />
                              </div>
                            ) : (
                              <div className="flex items-center space-x-[4px]">
                                {short.is_saved ? (
                                  <FrameIcon className="w-[16px] h-[16px] text-[#333]" />
                                ) : (
                                  <UnsaveIcon className="w-[16px] h-[16px] text-[#333]" />
                                )}
                                {short.is_read ? (
                                  <GreenCheck className="w-[16px] h-[16px]" title="Read" />
                                ) : (
                                  <GreyCheck className="w-[16px] h-[16px]" title="Unread" />
                                )}
                              </div>
                            )}
                          </div>

                          <div className="w-full mt-[9px] pt-[10px] pb-[0px] pl-[10px] bg-[#F9F9F9] rounded-[4px] h-[110px]">
                            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                              <p className="text-[11px] mr-[11px] text-[#646464] font-[400] font-inter leading-normal">
                                {short.details || "No additional details available."}
                              </p>
                            </CustomScroll>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <div className="h-[171px] w-[4px] bg-[#646464]"></div>
                          <div className="w-[31px] h-[171px] bg-black rounded-tr-[7.6px] rounded-br-[7.6px] pt-[6px] pl-[6px] pr-[6px]">
                            <div className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]">
                              <span className="text-white text-[10px] font-bold">{index + 1}</span>
                            </div>
                            <div className="mt-[98px] ">
                              <button
                                className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]"
                                onClick={() =>
                                  handleSaveClick(
                                    sessionDetails?.session_id,
                                    sessionDetails?.session_name,
                                    short.title,
                                    !short.is_saved
                                  )
                                }
                              >
                                <SaveIcon className="w-4 h-4 text-white" />
                              </button>
                              <button
                                className="w-auto h-auto bg-[#FFFFFF4D] mt-[4px] flex items-center justify-center p-[2px] rounded-[4px]"
                                onClick={() => handleChatClick(short.details)}
                              >
                                <StarIcon className="w-4 h-4 text-white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-[#646464] text-sm font-medium w-full text-center">
                      No Shorts available for this session.
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="w-[28%]">
              <CourseSidebar {...{ lectures: courseLectures, courseName, activeIndex }} onClick={onLectureClick} />
            </div>
          </div>
        </div>
      </ConditionalLoader>
      <Modal
        open={isViewSubjectiveSubmissionVisible}
        onClose={handleOnCloseBtnSubmitModalClick}
        width="w-[452px]"
        height={true}
      >
        <AISubmissionResponse
          feedbackData={subjectivefeedbackResponseData}
          isLoading={isLoading}
          onClose={handleOnCloseBtnSubmitModalClick}
          rateAIModal={rateAIModal}
        />
      </Modal>

      <Modal open={isStudentFeedbackVisible} onClose={handleOnCloseFeedbackBtnClick} width="w-[450px]" height={true}>
        <StudentFeedback
          questionName={questionName}
          onClose={handleOnCloseFeedbackBtnClick}
          isLoading={isLoading}
          onSubmit={submitStudentFeedback}
        />
      </Modal>

      <Modal padding={0} open={isRecordedFeedbackVisible}>
        <ClassFeedback
          isExternal={false}
          onSubmit={submitClassFeedbackNew}
          isRecordedFeedbackVisible={isFeedbackModalOpen}
          setIsRecordedFeedbackVisible={setIsRecordedFeedbackVisible}
          feedbackData={classFeedback}
          feedbackGiven={isFeedbackGiven}
        />
      </Modal>

      <Modal
        open={isViewSubmissionVisible}
        onClose={handleOnCloseBtnModalClick}
        width={questionType === "casestudy" ? true : false}
        padding={questionType === "casestudy" ? true : false}
      >
        <SubmissionResponse
          feedbackData={feedbackResponseData}
          order={order}
          isLoading={isLoading}
          questionName={questionName}
          questionType={questionType}
          selectedQuestion={selectedQuestion}
          handleSelectedQuestionBtnClick={handleSelectedQuestionBtnClick}
        />
      </Modal>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
