import { User } from "domain/model/User"
import { AuthRepository } from "domain/repository/Admin/AuthRepository"
import AuthDataSource from "data/dataSource/Common/AuthDataSource"
import { ResetPassword } from "domain/model/ResetPassword"
import { Auth } from "domain/model/Auth"

export class AuthRepositoryImpl implements AuthRepository {
  private dataSource: AuthDataSource

  constructor(dataSource: AuthDataSource) {
    this.dataSource = dataSource
  }

  async login(user: User): Promise<any> {
    return this.dataSource.login(user)
  }

  async checkUserAuth(user: User): Promise<any> {
    return this.dataSource.checkUserAuth(user)
  }

  async resetPassword(email: string): Promise<any> {
    return this.dataSource.resetPassword(email)
  }

  async signUp(user: User): Promise<any> {
    return this.dataSource.signUp(user)
  }

  async forgotPassword(email: string): Promise<any> {
    return this.dataSource.forgotPassword(email)
  }

  async resetPasswordByStudent(auth: Auth, user: ResetPassword): Promise<any> {
    return this.dataSource.resetPasswordByStudent(auth, user)
  }
}
