import { CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"

export default function RescheduleSessionModal({ open, children, onClose }: any) {
  const ableToClose = onClose && typeof onClose === "function"

  if (!open) return null

  return (
    <div className="fixed inset-0 z-30">
      <div className="flex min-h-screen items-center justify-center">
        <div
          {...(ableToClose ? { onClick: onClose } : {})}
          className="fixed inset-0 backdrop-blur-sm"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-new-solid-black opacity-75" />
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className={cn("relative my-auto w-[449px] h-[574px]  h rounded-[8px] bg-new-solid-white")}>
            <button
              className={cn("absolute top-4 right-4", !ableToClose && "hidden")}
              {...(ableToClose ? { onClick: onClose } : {})}
            >
              <CrossIcon className="h-6 w-6" />
            </button>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
