import pn from "core/utils/pn"
import { Auth } from "domain/model/Auth"
import { InstructorAvailabilityDataSource } from "data/dataSource/Mentor/InstructorAvailabilityDataSource"
import { Server } from "core/utils/axios"
import { InstructorAvailability } from "domain/model/Mentor"
import { API_MENTOR, API_MENTORS } from "core/constants/strings"
const server = new Server()
export default class InstructorAvailabilityAPIDataSourceImpl implements InstructorAvailabilityDataSource {
  async updateInstructorAvailability(auth: Auth, details: InstructorAvailability) {
    try {
      const response = await server.post(
        pn(API_MENTOR, "instructor", "update-instructor-availability"),
        {
          details: details,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      return response
    } catch (error) {
      return error
    }
  }

  async getInstructorAvailability(auth: Auth) {
    try {
      const response = await server.get(pn(API_MENTOR, "instructor", "get-instructor-availability"), {
        Authorization: `Bearer ${auth.id_token}`,
      })

      return response
    } catch (error) {
      return error
    }
  }
}
