import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { SheetsAPIDataSourceImpl } from "data/API/Admin/SheetsAPIDataSourceImpl"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { SheetsRepositoryImpl } from "data/repository/Admin/SheetsRepositoryImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { GetBatchCertificates } from "domain/useCase/Admin/Sheets/GetBatchCertificates"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import { useState } from "react"

export default function Certificate() {
  const { auth } = useAuth()
  const [email, setEmail] = useState<string>("")
  const [course, setCourse] = useState<string>("")
  const [months, setMonths] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const getUserDataUseCase = new GetAllStudentData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const getBatchCertificatesUseCase = new GetBatchCertificates(new SheetsRepositoryImpl(new SheetsAPIDataSourceImpl()))

  const generateCertificate = async () => {
    if (email.length && course.length && months) {
      setLoading(true)
      try {
        await getBatchCertificatesUseCase.invoke(auth, "N/A", course, months as number, email)
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Certificate generated successfully!")
        const userData = await getUserDataUseCase.invoke({
          admin_uid: auth.local_id,
          email: email,
        })
        window.open(userData.response.certificate_url, "_blank")
      } catch (err) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Error generating certificates!")
        console.log(err)
      }
      setLoading(false)
    }
  }

  return {
    email,
    setEmail,
    course,
    setCourse,
    months,
    setMonths,
    loading,
    generateCertificate,
    toast,
    changeToastVisibility,
  }
}
