import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Student/ContestRepository"

export interface getBatchwiseExamsUseCase {
  invoke(auth: Auth, email: string, batch_id: string): Promise<any>
}

export default class getBatchwiseExams implements getBatchwiseExamsUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string, batch_id: string): Promise<any> {
    return this.repository.getBatchwiseExams(auth, email, batch_id)
  }
}
