import { Auth } from "domain/model/Auth"
import { Server } from "core/utils/axios"
import { API_ADMIN } from "core/constants/strings"
import pn from "core/utils/pn"
import { SessionRescheduleRequestDataSource } from "data/dataSource/Admin/SessionRescheduleRequestDataSource"
import GetSessionRescheduleRequestDetails from "domain/model/GetSessionRescheduleRequest"
import UpdateSessionRescheduleRequestDetails from "domain/model/UpdateSessionRescheduleRequest"
const server = new Server()
export default class SessionRescheduleRequestAPIDataSourceImpl implements SessionRescheduleRequestDataSource {
  async getSessionRescheduleRequest(auth: Auth, details: GetSessionRescheduleRequestDetails): Promise<any> {
    const response = await server.get(
      pn(API_ADMIN, `get_session_reschedule_request?email=${details.email}&batch=${details.batch}`),
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async updateSessionRescheduleRequest(auth: Auth, details: UpdateSessionRescheduleRequestDetails): Promise<any> {
    const response = await server.post(
      pn(API_ADMIN, `update_session_reschedule_request`),
      {
        id: details.id,
        status: details.status,
      },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }
}
