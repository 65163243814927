import { Auth } from "domain/model/Auth"
import {
  API_V3_GET_PROFILE,
  API_V3_UPDATE_PROFILE,
  API_V3_ADMIN,
  API_V3_INFLUENCER,
  API_GET_CAMPAIGN_COMPENSATION,
  API_GET_ALL_INVOICES,
} from "core/constants/strings"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { InfluencerProfile, RatesDetails, UpdateInfluencerProfile } from "domain/model/InfluencerDashboard"
import InfluencerCommonDataSource from "data/dataSource/Common/InfluencerCommonDataSource"

const server = new Server()
export class InfluencerAPIDataSourceImpl implements InfluencerCommonDataSource {
  async GetInfluencerProfile(id: InfluencerProfile, auth: Auth): Promise<any> {
    const response = await server.post(pn(API_V3_INFLUENCER, API_V3_GET_PROFILE), id, {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async updateInfluencerProfiles(id: UpdateInfluencerProfile, auth: Auth): Promise<any> {
    const response = await server.post(
      pn(API_V3_INFLUENCER, API_V3_UPDATE_PROFILE),
      {
        influencerData: id.influencer,
      },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }
  async getAllCompensationDetails(auth: Auth, details: RatesDetails): Promise<any> {
    try {
      const response = await server.post(
        pn(API_V3_INFLUENCER, API_GET_CAMPAIGN_COMPENSATION),
        {
          email: details.email,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getAllInfluencersGeneratedInvoiceDetails(auth: Auth, email?: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_V3_INFLUENCER, API_GET_ALL_INVOICES),
        {
          email,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
