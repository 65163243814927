import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClasses } from "domain/useCase/Admin/CreateClass/CreateClass"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { GetAllInstructors } from "domain/useCase/Admin/CreateClass/GetAllInstructors"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { ClassTemplate } from "domain/useCase/Admin/CreateClass/GetClassTemplate"
import { GetModuleClasses } from "domain/useCase/Admin/CreateClass/GetModuleClasses"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { useState } from "react"

export default function CreateClassViewModel() {
  const [selectAssigmentsQuestions, setSelectAssignmentsQuestions] = useState<any>([])
  const [currentSelectAssignmentsQuestions, setCurrentSelectAssignmentsQuestions] = useState("")
  const [selectHomeworkQuestions, setSelectHomeworkQuestions] = useState<any>([])
  const [currentSelectHomeworkQuestions, setCurrentSelectHomeworkQuestions] = useState("")
  const [selectPracticalQuestions, setSelectPracticalQuestions] = useState<any>([])
  const [currentSelectPracticalQuestions, setCurrentSelectPracticalQuestions] = useState("")
  const [selectWarmupQuestions, setSelectWarmupQuestions] = useState<any>([])
  const [currentSelectWarmupQuestions, setCurrentSelectWarmupQuestions] = useState("")
  const [batch, setBatch] = useState([])
  const [selectBatch, setSelectBatch] = useState<any>([])
  const [currentSelectBatch, setCurrentSelectBatch] = useState("")
  const [classLink, setClassLink] = useState("")
  const [instructor, setInstructor] = useState([])
  const [selectInstructor, setSelectInstructor] = useState("")
  const [moduleId, setModuleId] = useState<string[]>([])
  const [selectModuleId, setSelectModuleId] = useState("")
  const [compensationStatus, setCompensationStatus] = useState("")
  const [masterClassType, setMasterClassType] = useState("Software Engineering")
  const [moduleClassesList, setModuleClassesList] = useState<any>([])
  const [selectClassName, setSelectClassName] = useState("")
  const [selectSessionTimestamp, setSelectSessionTimestamp] = useState<any>("")
  const [track, setTrack] = useState<string[]>([])
  const [selectTrack, setSelectTrack] = useState("")
  const [otherModules, setOtherModules] = useState("")
  const [videoId, setVideoId] = useState("")
  const [videoSecret, setVideoSecret] = useState("")
  const [notesLink, setNotesLink] = useState("")
  const [notesSummaryLink, setNotesSummaryLink] = useState("")
  const [loading, setLoading] = useState(false)
  const [enableOthersClass, setEnableOthersClass] = useState(false)
  const [othersClassName, setOthersClassName] = useState("")
  const [level, setLevel] = useState("")
  const [sessionDuration, setSessionDuration] = useState<any>(1.5)
  const [otherSessionDuration, setOtherSessionDuration] = useState<any>(null)
  const [assignmentSolutionLink, setAssignmentSolutionLink] = useState("")
  const [assignmentSolutionActivationTime, setAssignmentSolutionActivationTime] = useState<any>("")
  const [assignmentCoins, setAssignmentCoins] = useState<string>("")
  const [firstReminderMessage, setFirstReminderMessage] = useState<string>("")
  const [secondReminderMessage, setSecondReminderMessage] = useState<string>("")
  const [preRequisite, setPreRequisite] = useState("") //new pre-requisite usestate

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const getAllQuestonsUseCase = new GetAllInstructors(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const getAllTracksUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const createClassUseCase = new CreateClasses(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const getModuleClassesUseCase = new GetModuleClasses(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )
  const getModuleBasedClassUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )
  const getClassTemplateUseCase = new ClassTemplate(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  function handleAssignmentChange(e: any) {
    setCurrentSelectAssignmentsQuestions(e.target.value)
  }

  function handleAddAssignmentQuestions(e: any) {
    e.preventDefault()

    if (currentSelectAssignmentsQuestions.length > 0) {
      setSelectAssignmentsQuestions((prev: any) => [...prev, currentSelectAssignmentsQuestions])
    }

    setCurrentSelectAssignmentsQuestions("")
  }

  function handleSelectHomeworkChange(e: any) {
    setCurrentSelectHomeworkQuestions(e.target.value)
  }

  function handleAddHomeworkQuestions(e: any) {
    e.preventDefault()
    if (currentSelectHomeworkQuestions.length > 0) {
      setSelectHomeworkQuestions([...selectHomeworkQuestions, currentSelectHomeworkQuestions])
    }
    setCurrentSelectHomeworkQuestions("")
  }

  function handlePracticalChange(e: any) {
    setCurrentSelectPracticalQuestions(e.target.value)
  }

  function handleChapterLevel(e: any) {
    setLevel(e.target.value)
  }

  function handleAddPracticalQuestions(e: any) {
    e.preventDefault()
    if (currentSelectPracticalQuestions.length > 0) {
      setSelectPracticalQuestions([...selectPracticalQuestions, currentSelectPracticalQuestions])
    }
    setCurrentSelectPracticalQuestions("")
  }

  function handleWarmupChange(e: any) {
    setCurrentSelectWarmupQuestions(e.target.value)
  }

  function handleAddWarmupQuestions(e: any) {
    e.preventDefault()
    if (currentSelectWarmupQuestions.length > 0) {
      setSelectWarmupQuestions([...selectWarmupQuestions, currentSelectWarmupQuestions])
    }
    setCurrentSelectWarmupQuestions("")
  }

  function handleBatchChange(e: any) {
    const selectedBatch = e.target.value
    if (!selectBatch.includes(selectedBatch)) {
      setSelectBatch((prevSelectBatch: any) => [...prevSelectBatch, selectedBatch])
    }
    setCurrentSelectBatch(selectedBatch)
  }

  function handleChangeChapterName(e: any) {
    const id = e.target.value
    const index = moduleClassesList.findIndex((i: { topic: string }) => i.topic === id)

    if (id === "others" || index === -1) {
      setEnableOthersClass(true)
      setSelectAssignmentsQuestions([])
      setSelectHomeworkQuestions([])
      setSelectPracticalQuestions([])
      setSelectWarmupQuestions([])
      setNotesLink("")
      setNotesSummaryLink("")
      setFirstReminderMessage("")
      setSecondReminderMessage("")
    } else {
      setEnableOthersClass(false)
      getClassTemplate(selectModuleId, index + 1)
    }

    setSelectClassName(id)
  }

  function handleOthersClass(e: any) {
    setOthersClassName(e.target.value)
  }

  function handleChangeClassLink(e: any) {
    setClassLink(e.target.value)
  }

  function handleChangeInstructor(e: any) {
    setSelectInstructor(e.target.value)
  }

  function handleCompensationStatus(e: any) {
    setCompensationStatus(e.target.value)
  }

  function handleOtherModules(e: any) {
    setOtherModules(e.target.value)
  }

  function handleSessionDuration(e: any) {
    setSessionDuration(e.target.value)
  }

  function handleOtherSessionDuration(e: any) {
    setOtherSessionDuration(e.target.value)
  }

  function handleChangeModuleId(e: any) {
    setSelectModuleId(e.target.value)
    getModuleClasses(e.target.value)
  }

  function handleChangeSessionTimestamp(e: any) {
    setSelectSessionTimestamp(e.target.value)
  }

  async function handleChangeTrack(e: any) {
    setSelectTrack(e.target.value)
    if (e.target.value != "others") {
      await getModuleBasedTrack(e.target.value)
    } else if (e.target.value === "others") {
      setModuleId(["other"])
    }
  }

  function handleChangeVideoId(e: any) {
    setVideoId(e.target.value)
  }

  function handleChangeVideoSecreat(e: any) {
    setVideoSecret(e.target.value)
  }

  function handleChangeNotesLink(e: any) {
    setNotesLink(e.target.value)
  }

  function handleChangeNotesSummaryLink(e: any) {
    setNotesSummaryLink(e.target.value)
  }
  function handleChangePreRequisite(e: any) {
    setPreRequisite(e.target.value)
  }

  function handleMasterClassType(e: any) {
    setMasterClassType(e.target.value)
  }

  function handleChangeAssignmentSolutionLink(e: any) {
    setAssignmentSolutionLink(e.target.value)
  }

  function handleChangeAssignmentSolutionActivationTime(e: any) {
    setAssignmentSolutionActivationTime(e.target.value)
  }

  function handleChangeAssignmentCoins(e: any) {
    setAssignmentCoins(e.target.value)
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (response.success) {
      setBatch(response.data)
    }
  }

  async function getInstructorsList() {
    const response = await getAllQuestonsUseCase.invoke(auth)
    setInstructor(response.data)
    if (response.success) {
    }
  }

  async function getAllTracks() {
    const response = await getAllTracksUseCase.invoke(auth)

    if (response.success) {
      setTrack((newTrack) => [...newTrack, ...response.data, "others"])
    }
  }

  async function getModuleBasedTrack(track: string) {
    const response = await getModuleBasedClassUseCase.invoke(auth, track)
    if (response.success) {
      setModuleId((newModules) => [...[], ...response.data.modules, "other"])
    } else {
      setModuleId([])
    }
  }

  async function getModuleClasses(module: string) {
    const response = await getModuleClassesUseCase.invoke({
      id_token: auth.id_token,
      module: module,
    })

    try {
      if (response.success) {
        setModuleClassesList(response.data)
      }
    } catch (error) {}
  }

  async function getClassTemplate(chapter_name: string, id: number) {
    const response = await getClassTemplateUseCase.invoke({
      id_token: auth.id_token,
      module: chapter_name,
      class_id: id,
    })
    try {
      if (response.success) {
        setSelectAssignmentsQuestions(response.data[0].assignments)
        setSelectHomeworkQuestions(response.data[0].homework)
        setSelectPracticalQuestions(response.data[0].practice)
        setSelectWarmupQuestions(response.data[0].warmup)
        setNotesLink(response?.data[0]?.notes_v4)
        setNotesSummaryLink(response?.data[0]?.notes_summary)
        setFirstReminderMessage(response?.data[0].first_reminder)
        setSecondReminderMessage(response?.data[0].second_reminder)
      }
    } catch (error) {
      console.error("Error in fetching class template", error)
    }
  }

  const problems = {
    assignments: selectAssigmentsQuestions,
    homework: selectHomeworkQuestions,
    practice: selectPracticalQuestions,
    warmup: selectWarmupQuestions,
  }

  async function createClasses() {
    setLoading(true)
    if (selectModuleId === "other") {
      setSelectModuleId(otherModules)
    }
    const response = await createClassUseCase.invoke({
      id_token: auth.id_token.trim(),
      batches: selectBatch,
      chapter_name: enableOthersClass ? othersClassName.trim() : selectClassName.trim(),
      class_link: classLink.trim(),
      instructor: selectInstructor.trim(),
      module_id: selectModuleId.trim(),
      level: level.trim(),
      problems: problems,
      session_timestamp: Date.parse(selectSessionTimestamp) / 1000,
      session_duration: Number(sessionDuration == "other" ? otherSessionDuration : sessionDuration),
      track: selectTrack.trim(),
      master_class_type: selectTrack.trim() != "master_class" ? "" : masterClassType,
      video_id: videoId.trim(),
      video_secret: videoSecret.trim(),
      notes_link: notesLink.trim(),
      notes_summary_link: notesSummaryLink.trim(),
      pre_requisite: preRequisite.trim(),
      compensation_status: compensationStatus,
      assignment_solution_link: assignmentSolutionLink.trim(),
      assignment_solution_activation_time: assignmentSolutionActivationTime.trim(),
      assignment_points: assignmentCoins === "" ? 0 : parseInt(assignmentCoins.trim()),
      first_reminder: firstReminderMessage,
      second_reminder: secondReminderMessage,
    })

    setLoading(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Class created successfully!")
  }

  return {
    toast,
    level,
    enableOthersClass,
    batch,
    selectClassName,
    instructor,
    classLink,
    moduleId,
    selectSessionTimestamp,
    track,
    videoId,
    videoSecret,
    notesLink,
    othersClassName,
    notesSummaryLink,
    preRequisite,
    selectAssigmentsQuestions,
    selectHomeworkQuestions,
    selectPracticalQuestions,
    selectWarmupQuestions,
    selectBatch,
    selectTrack,
    selectInstructor,
    selectModuleId,
    moduleClassesList,
    currentSelectAssignmentsQuestions,
    currentSelectHomeworkQuestions,
    currentSelectPracticalQuestions,
    currentSelectWarmupQuestions,
    currentSelectBatch,
    loading,

    compensationStatus,
    otherModules,
    assignmentSolutionActivationTime,
    assignmentSolutionLink,
    assignmentCoins,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    firstReminderMessage,
    secondReminderMessage,
    setSessionDuration,
    setOtherSessionDuration,
    handleSessionDuration,
    handleOtherSessionDuration,
    handleChangeAssignmentCoins,
    handleChangeAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    handleOtherModules,
    setPreRequisite,
    setSelectBatch,
    setSelectAssignmentsQuestions,
    setSelectHomeworkQuestions,
    setSelectPracticalQuestions,
    setSelectWarmupQuestions,
    setCurrentSelectBatch,
    handleCompensationStatus,
    handlePracticalChange,
    handleSelectHomeworkChange,
    handleWarmupChange,
    handleAssignmentChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    handleChangePreRequisite,
    handleChangeSessionTimestamp,
    handleChangeTrack,
    handleChangeVideoId,
    handleMasterClassType,
    handleChangeVideoSecreat,
    handleAddAssignmentQuestions,
    handleAddHomeworkQuestions,
    handleAddPracticalQuestions,
    handleAddWarmupQuestions,
    createClasses,
    changeToastVisibility,
    handleOthersClass,
    handleChapterLevel,
    getBatches,
    getAllTracks,
    getInstructorsList,
    setFirstReminderMessage,
    setSecondReminderMessage,
  }
}
