import Button from "core/components/new/Button"
import Toggle from "core/components/Toggle"
import { EyeIcon } from "core/constants/svgs"
import { useState } from "react"
import CustomModal from "./CustomeModal"
import { cn } from "core/lib/utils"
import Spinner from "core/components/Spinner"
// import CustomModel from "./CustomModel"
export default function ReferEarnTable(props: any) {
  const [model, setModel] = useState<any>(false)
  // const [reason, setReason] = useState("")

  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <CustomModal isOpen={model} heading={"Bank Details"} onClose={() => setModel(!model)}>
        <p className="text-[16px] font-[500] my-[6px]">Account Holder Name: {model?.accountHolderName}</p>
        <p className="text-[16px] font-[500] my-[6px]">Bank Name: {model?.bankName}</p>
        <p className="text-[16px] font-[500] my-[6px]">Account Number: {model?.accountNumber}</p>
        <p className="text-[16px] font-[500] my-[6px]">IFSC Code: {model?.iffciCode}</p>
        {model?.panCardNumber && <p className="text-[16px] font-[500] my-[6px]">Pan Number: {model?.panCardNumber}</p>}
      </CustomModal>
      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: any) => (
              <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                {title}
              </th>
            ))}
          </tr>
        </thead>
        {props?.loading && (
          <tr>
            <td colSpan={props?.tableHeaders?.length} className="px-[4px] py-[10px]">
              <div className="relative left-[50%] max-[1920px]:left-[35%]">
                <Spinner />
              </div>
            </td>
          </tr>
        )}
        {!props.referralData?.length && !props.loading && <div className="m-2">No data found</div>}
        <tbody>
          {props.referralData?.map((data: any, index: any) => (
            <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
              <td className="px-6 py-4 text-[16px] font-medium">{index + 1}</td>
              <td className="px-6 py-4 ">
                <p>{data?.referName}</p>
                <p className="font-medium">{data?.referEmail}</p>
                <p> {data?.referNumber}</p>
                <p className="text-new-accent font-[600]">{data?.courseType}</p>
              </td>
              <td className="px-6 py-4 ">
                <p>{data?.yourName}</p>
                <p className="font-medium">{data?.yourEmail}</p>
                <p> {data?.yourNumber}</p>
              </td>
              <td>
                <p className="text-sm w-28 text-gray-500 pl-6">
                  {data?.createdAt
                    ? new Date(data.createdAt * 1000).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : "-"}
                </p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <p>{data?.referee_amount ? <>Amount: ₹{data?.referee_amount}</> : "-"}</p>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <p>
                  {data?.referrer_amount === -1 ? (
                    "Remaining Fees Refund"
                  ) : data?.referrer_amount ? (
                    <>Amount: ₹{data?.referrer_amount}</>
                  ) : (
                    "-"
                  )}
                </p>
                <p>{data?.bonus ? <>Bonus: ₹{data?.bonus}</> : ""}</p>
              </td>
              <td>
                <p className="text-sm w-28 text-gray-500 pl-5">
                  {data?.expected_date
                    ? new Date(data.expected_date * 1000).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : "-"}
                </p>
              </td>
              {/* <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                {data?.courseType ? data?.courseType : "-"}
              </td> */}
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setModel(data?.refereeBankDetails)
                  }}
                  disabled={!data?.refereeBankDetails}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
              <td className="px-6 py-4 text-[16px] font-medium text-new-accent ">
                <Button
                  outlined
                  className="mr-2 h-fit w-fit"
                  onClick={() => {
                    setModel(data?.referrerBankDetails)
                  }}
                  disabled={!data?.referrerBankDetails}
                >
                  <EyeIcon className="w-5" />
                </Button>
              </td>
              <td className="px-6 py-4">
                <Toggle
                  checked={data?.referee_reedem}
                  onChange={() => {
                    props?.updateReferandEarn({
                      index,
                      email: data?.referEmail,
                      referee_reedem: data?.referee_reedem ? false : true,
                    })
                  }}
                />
              </td>
              <td className="px-6 py-4">
                <Toggle
                  checked={data?.referrer_reedem}
                  onChange={() => {
                    props?.updateReferandEarn({
                      index,
                      email: data?.referEmail,
                      referrer_reedem: data?.referrer_reedem ? false : true,
                    })
                  }}
                />
              </td>
              <td className="px-6 py-4">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    className="peer sr-only"
                    checked={data?.opted_out}
                    onChange={() => {
                      props?.updateReferandEarn({
                        index,
                        email: data?.referEmail,
                        opted_out: data?.opted_out ? false : true,
                      })
                    }}
                  />
                  <div
                    className={cn(
                      "peer h-6 w-11 rounded-full bg-gray-200 dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-red-300 after:content-[''] after: after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-new-solid-white after:transition-all dark:border-gray-600",
                      data?.opted_out && "peer-checked:after:translate-x-full peer-checked:bg-red-600",
                      data?.opted_out && "peer-checked:border-white"
                    )}
                  />
                </label>
                {/* <Toggle
                  checked={data?.opted_out}
                  onChange={() => {
                    props?.updateReferandEarn({
                      index,
                      email: data?.referEmail,
                      opted_out: data?.opted_out ? false : true,
                    })
                  }}
                /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
