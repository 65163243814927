import React from "react"
import { useAuth } from "core/context/auth"
import { GetCampaignsDetails } from "domain/useCase/Admin/InfluencerDashboard/GetallCampaignType"
import { GetInfluencersDetails } from "domain/useCase/Admin/InfluencerDashboard/GetAllInfuencerDetails"
import { InfluencerDashboardRepositoryImpl } from "data/repository/Admin/InfluencerDashboardRepositoryImpl"
import { InfluencerDashboardAPIDataSourceImpl } from "data/API/Admin/InfluncerAPIDataSourceImpl"

export default function InfluencerDashboardViewModel() {
  const [influencerData, setInfluencerData] = React.useState<any>([])
  const [CampaignData, setCampaignData] = React.useState<any>([])
  const [searchByEmail, setSearchByEmail] = React.useState<string>("")
  const [searchByCampaign, setSearchByCampaign] = React.useState<string>("")
  const [searchByProgram, setSearchByprogram] = React.useState<string>("")

  const { auth } = useAuth()
  const GetAllInfluencersDetailsUseCase = new GetInfluencersDetails(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const GetAllCampaignTypeDetailsUseCase = new GetCampaignsDetails(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )

  async function getInfluencersData() {
    const response = await GetAllInfluencersDetailsUseCase.invoke(auth, {
      admin_uid: auth.local_id,
    })
    if (response) {
      setInfluencerData(response.data)
    }
  }
  async function getCampaignType() {
    const campaign = await GetAllCampaignTypeDetailsUseCase.invoke(auth, {
      Campaign: auth.local_id,
    })
    if (campaign) {
      setCampaignData(campaign.data)
    }
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) setSearchByEmail(e.target.value)
    else setSearchByEmail("")
  }

  function handleSearchByProgram(e: any) {
    if (e.target.value) setSearchByprogram(e.target.value)
    else setSearchByprogram("")
  }
  function handleSearchByCampaign(e: any) {
    if (e.target.value) setSearchByCampaign(e.target.value)
    else setSearchByCampaign("")
  }

  function searchingforEmail(email: string | null | undefined) {
    return function (obj: any) {
      return !email || obj.email.toLowerCase().includes(email.toLowerCase())
    }
  }

  function searchingByCampaign(selected_campaign: string | null | undefined) {
    return function (obj: any) {
      if (!selected_campaign) {
        return true
      }
      if (Array.isArray(obj.selected_campaign)) {
        return obj.selected_campaign.some((campaign: string) =>
          campaign.toLowerCase().includes(selected_campaign.toLowerCase())
        )
      }
      return obj.selected_campaign.toLowerCase().includes(selected_campaign.toLowerCase())
    }
  }

  function searchingByProgram(selected_programs: string | null | undefined) {
    return function (obj: any) {
      if (!selected_programs) {
        return true // If no program is selected, return true to allow all items
      }
      if (Array.isArray(obj.selected_programs)) {
        return obj.selected_programs.some((program: string) =>
          program.toLowerCase().includes(selected_programs.toLowerCase())
        )
      }
      return obj.selected_programs.toLowerCase().includes(selected_programs.toLowerCase())
    }
  }

  return {
    influencerData,
    searchByEmail,
    searchByCampaign,
    searchByProgram,
    CampaignData,
    handleSearchByProgram,
    getInfluencersData,
    handleSearchByEmail,
    searchingforEmail,
    getCampaignType,
    searchingByCampaign,
    searchingByProgram,
    handleSearchByCampaign,
  }
}
