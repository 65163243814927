import CloseIcon from "assets/svgs/placement/Close"
import { useAuth } from "core/context/auth"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { GetEmailsTemplete } from "domain/useCase/Admin/Placement/GetEmailsTemplete"
import { SendPlacementsMail } from "domain/useCase/Admin/Placement/SendPlacementsMail"
import PreviewEmailCard from "presentation/Admin/PlacementDashboard/components/popUpCards/PreviewEmailCard"
import React, { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

interface EmailCardProps {
  filters: any
  onCancel: () => void
  totalCount: number
}

const ComposeEmailCard: React.FC<EmailCardProps> = ({ filters, onCancel, totalCount }) => {
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null)
  const [preview, setPreview] = useState<boolean>(false)
  const mailType = [
    "Assessment Selection Mail",
    "Assessment Not Selecton Mail",
    "Placement Cohort Entry Mail",
    "Bridge Course Entry Mail",
  ]

  const { auth } = useAuth()
  const [emailsTemplete, setEmailsTemplete] = useState<any>([])

  const GetEmailsTempleteUsecase = new GetEmailsTemplete(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  const SendPlacementsMailUseCase = new SendPlacementsMail(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  useEffect(() => {
    fetchEmailsTemplete()
  }, [])

  async function fetchEmailsTemplete() {
    const response = await GetEmailsTempleteUsecase.invoke(auth)
    if (response?.success) {
      const data = response?.data
      setEmailsTemplete(data || [])
    }
  }

  const [emailSubject, setEmailSubject] = useState("")
  const [emailBody, setEmailBody] = useState("")
  const [isSending, setIsSending] = useState(false)

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  }
  const handleSubjectChange = (e: any) => {
    setEmailSubject(e.target.value)
  }
  const handleBodyChange = (value: any) => {
    setEmailBody(value)
  }

  const handleTemplateSelection = (type: string) => {
    setSelectedEmail(type)
    setEmailSubject(emailsTemplete?.[type]?.subject || "")
    setEmailBody(emailsTemplete?.[type]?.body || "")
  }

  const handleSendEmail = async (filters: any, emailSubject: string, emailBody: string) => {
    setIsSending(true)
    const response = await SendPlacementsMailUseCase.invoke(auth, { filters, emailSubject, emailBody })
    if (response?.success) {
      setIsSending(false)
      alert("Email sent successfully")
    } else {
      setIsSending(false)
      alert("Email sending failed")
    }
  }

  return (
    <div className="bg-[#F2F2F2] min-w-[440px] max-w-[1024px] shadow-md rounded-lg p-[24px] relative">
      {!selectedEmail && (
        <>
          <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onCancel}>
            <CloseIcon className="h-6 w-6" />
          </button>
          <p className="text-center text-[16px] font-semibold text-[#000000]">Select an email template</p>
          <p className="text-center pt-1 text-[14px] text-[#2867C7]">({totalCount} Selected students)</p>
          <div className="p-6 flex flex-col gap-4 items-center justify-between">
            {mailType.map((type, index) => (
              <button
                key={index}
                onClick={() => handleTemplateSelection(type)}
                className="w-full bg-white rounded-lg py-2 px-4 text-[#525252]"
              >
                {type}
              </button>
            ))}
          </div>
        </>
      )}
      {selectedEmail && !preview && (
        <div className="flex flex-col gap-4">
          <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={onCancel}>
            <CloseIcon className="h-6 w-6" />
          </button>
          <div>
            <h3 className="text-xl font-semibold mb-4">Compose Your Email - {selectedEmail}</h3>
            <label className="block mb-2 font-medium">Subject</label>
            <input
              type="text"
              name="subject"
              value={emailSubject}
              onChange={handleSubjectChange}
              className="w-full p-2 mb-4 border border-gray-300 rounded"
              placeholder="Enter the subject"
            />

            <label className="block mb-2 font-medium">Email Body</label>
            <ReactQuill value={emailBody} onChange={handleBodyChange} modules={modules} theme="snow" />
          </div>
          <div className="flex gap-8 justify-end">
            <button
              className=" bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
              onClick={() => setSelectedEmail(null)}
            >
              Cancel
            </button>
            <button
              className=" bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
              onClick={() => setPreview(true)}
            >
              Preview
            </button>
            <button
              className=" bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
              onClick={() => handleSendEmail(filters, emailSubject, emailBody)}
            >
              {isSending ? "Sending..." : "Send"}
            </button>
          </div>
        </div>
      )}
      {selectedEmail && preview && (
        <PreviewEmailCard emailBody={emailBody} emailSubject={emailSubject} onCancel={() => setPreview(false)} />
      )}
    </div>
  )
}

export default ComposeEmailCard
