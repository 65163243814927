import pn from "core/utils/pn"
import { STUDENT, LEADERBOARD } from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"
import { LeaderboardDataSource } from "data/dataSource/Student/LeaderboardDataSource"
import { UpdateSessionStatusDataSource } from "data/dataSource/Student/UpdateSessionStatusDataSource"
import GetLiveSessionDetails from "domain/model/GetLiveSessionDuration"

const server = new Server()
export class UpdateSessionStatusAPIDataSource implements UpdateSessionStatusDataSource {
  async updateSessionStatus(auth: Auth, details: GetLiveSessionDetails): Promise<any> {
    try {
      const response = await server.post(
        pn(STUDENT, "update_session_status"),
        {
          session_id: details.session_id,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
