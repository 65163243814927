import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface getBatchExamTemplateUseCase {
  invoke(auth: Auth, batch_id: string): Promise<any>
}

export class GetBatchExamTemplate implements getBatchExamTemplateUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch_id: string) {
    return await this.repository.getBatchExamTemplate(auth, batch_id)
  }
}
