import InfluencerCommonDataSource from "data/dataSource/Common/InfluencerCommonDataSource"
import { Auth } from "domain/model/Auth"
import { InfluencerProfile, UpdateInfluencerProfile, RatesDetails } from "domain/model/InfluencerDashboard"
import { CommonInfluencerRepository } from "domain/repository/Common/CommonInfluencerRepository"

export class CommonInfluencerRepositoryImpl implements CommonInfluencerRepository {
  private dataSource: InfluencerCommonDataSource

  constructor(dataSource: InfluencerCommonDataSource) {
    this.dataSource = dataSource
  }
  updateInfluencerProfiles(id: UpdateInfluencerProfile, auth: Auth): Promise<any> {
    return this.dataSource.updateInfluencerProfiles(id, auth)
  }
  getAllInfluencerGeneratedInvoiceDetails(auth: Auth, email?: string): Promise<any> {
    return this.dataSource.getAllInfluencersGeneratedInvoiceDetails(auth, email)
  }
  GetInfluencerProfile(id: InfluencerProfile, auth: Auth): Promise<any> {
    return this.dataSource.GetInfluencerProfile(id, auth)
  }
  getAllCompensationDetails(auth: Auth, details: RatesDetails): Promise<any> {
    return this.dataSource.getAllCompensationDetails(auth, details)
  }
}
