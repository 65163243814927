import React from "react"
import { CrossIcon } from "core/constants/svgs"
import { Button } from "core/components/v2/Button"
import { Link } from "react-router-dom"
const Notifications = ({ notification, removeNotification, formatDate, formatTime }: any) => {
  const { type } = notification
  return (
    <div>
      {type === "slot open" ? (
        <div className=" w-full min-h-[90px] flex border-[0.5px] rounded-[8px] border-[#D3D3D3]">
          <div className="  min-h-[90px] w-[8px]   rounded-l-[8px] bg-[#07B42D] "></div>
          <div className=" my-[16px] ml-[16px] mr-[24px] w-full">
            <div className=" flex justify-between w-full">
              <div className="flex ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M13.3327 9.16762C13.3327 12.501 10.9993 14.1676 8.22602 15.1343C8.08079 15.1835 7.92304 15.1811 7.77935 15.1276C4.99935 14.1676 2.66602 12.501 2.66602 9.16762V4.50095C2.66602 4.32414 2.73625 4.15457 2.86128 4.02955C2.9863 3.90452 3.15587 3.83428 3.33268 3.83428C4.66602 3.83428 6.33268 3.03428 7.49268 2.02095C7.63392 1.90028 7.81358 1.83398 7.99935 1.83398C8.18511 1.83398 8.36478 1.90028 8.50602 2.02095C9.67268 3.04095 11.3327 3.83428 12.666 3.83428C12.8428 3.83428 13.0124 3.90452 13.1374 4.02955C13.2624 4.15457 13.3327 4.32414 13.3327 4.50095V9.16762Z"
                    stroke="#07B42D"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 5.83398V8.50065"
                    stroke="#07B42D"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11.166H8.00667"
                    stroke="#07B42D"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#07B42D] mx-[8px] font-inter text-[16px] font-medium">Slot Open Reminder</p>
              </div>
            </div>
            <p className="text-[#646464] font-inter text-[14px] font-normal">
              Booking will start soon {formatDate(notification?.date)}
            </p>
            <p className="text-[#646464] font-inter text-[14px] font-normal">
              Please ensure you’ve opened your slots for mentees to book their sessions.
            </p>
          </div>
        </div>
      ) : type === "missed session" ? (
        <div className=" w-full min-h-[90px] flex border-[0.5px] rounded-[8px] border-[#D3D3D3]">
          <div className=" min-h-[90px] w-[8px]   rounded-l-[8px]  bg-[#DC2626] "></div>
          <div className=" my-[16px] ml-[16px] mr-[24px] w-full">
            <div className=" flex justify-between w-full">
              <div className="flex ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M13.3327 9.16762C13.3327 12.501 10.9993 14.1676 8.22602 15.1343C8.08079 15.1835 7.92304 15.1811 7.77935 15.1276C4.99935 14.1676 2.66602 12.501 2.66602 9.16762V4.50095C2.66602 4.32414 2.73625 4.15457 2.86128 4.02955C2.9863 3.90452 3.15587 3.83428 3.33268 3.83428C4.66602 3.83428 6.33268 3.03428 7.49268 2.02095C7.63392 1.90028 7.81358 1.83398 7.99935 1.83398C8.18511 1.83398 8.36478 1.90028 8.50602 2.02095C9.67268 3.04095 11.3327 3.83428 12.666 3.83428C12.8428 3.83428 13.0124 3.90452 13.1374 4.02955C13.2624 4.15457 13.3327 4.32414 13.3327 4.50095V9.16762Z"
                    stroke="#DC2626"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 5.83398V8.50065"
                    stroke="#DC2626"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11.166H8.00667"
                    stroke="#DC2626"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#DC2626] mx-[8px] font-inter text-[16px] font-medium">Missed Session</p>
              </div>
              <button
                onClick={() => {
                  removeNotification(notification.id)
                }}
                className="cursor-pointer"
              >
                <CrossIcon />
              </button>
            </div>
            <div className=" flex justify-between ">
              <div>
                <p className="text-[#646464] font-inter text-[14px] font-normal">
                  Open new slot to compensate your missed session! {formatDate(notification?.date)}!
                </p>
                <p className="text-[#646464] font-inter text-[14px] font-normal">
                  Please ensure you’ve opened your slots for mentees to book their sessions.
                </p>
              </div>
              <Link to={"/mentor/availability"}>
                <Button variant={"primary"} className="  px-[16px] py-[9px] h-[35px] w-[142px]">
                  Open Slots
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : type === "upcoming session" ? (
        <div className=" w-full min-h-[69px] flex border-[0.5px] rounded-[8px] border-[#D3D3D3]">
          <div className="  min-h-[69px] w-[8px]   rounded-l-[8px] bg-[#7947E2] "></div>
          <div className=" my-[16px] ml-[16px] mr-[24px] w-full">
            <div className=" flex justify-between w-full">
              <div className="flex ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M13.3327 9.16762C13.3327 12.501 10.9993 14.1676 8.22602 15.1343C8.08079 15.1835 7.92304 15.1811 7.77935 15.1276C4.99935 14.1676 2.66602 12.501 2.66602 9.16762V4.50095C2.66602 4.32414 2.73625 4.15457 2.86128 4.02955C2.9863 3.90452 3.15587 3.83428 3.33268 3.83428C4.66602 3.83428 6.33268 3.03428 7.49268 2.02095C7.63392 1.90028 7.81358 1.83398 7.99935 1.83398C8.18511 1.83398 8.36478 1.90028 8.50602 2.02095C9.67268 3.04095 11.3327 3.83428 12.666 3.83428C12.8428 3.83428 13.0124 3.90452 13.1374 4.02955C13.2624 4.15457 13.3327 4.32414 13.3327 4.50095V9.16762Z"
                    stroke="#7947E2"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 5.83398V8.50065"
                    stroke="#7947E2"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11.166H8.00667"
                    stroke="#7947E2"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#7947E2] mx-[8px] font-inter text-[16px] font-medium">Upcoming Session Reminder</p>
              </div>
            </div>
            <p className="text-[#646464] font-inter text-[14px] font-normal">
              You have an upcoming session scheduled on {formatDate(notification?.date)} at{" "}
              {formatTime(notification.time)}. Please prepare accordingly.
            </p>
          </div>
        </div>
      ) : type === "reschedule session" ? (
        <div className=" w-full min-h-[69px] flex border-[0.5px] rounded-[8px] border-[#D3D3D3]">
          <div className="  min-h-[69px] w-[8px]   rounded-l-[8px] bg-[#2D9ED6] "></div>
          <div className=" my-[16px] ml-[16px] mr-[24px] w-full">
            <div className=" flex justify-between w-full">
              <div className="flex ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M13.3327 9.16762C13.3327 12.501 10.9993 14.1676 8.22602 15.1343C8.08079 15.1835 7.92304 15.1811 7.77935 15.1276C4.99935 14.1676 2.66602 12.501 2.66602 9.16762V4.50095C2.66602 4.32414 2.73625 4.15457 2.86128 4.02955C2.9863 3.90452 3.15587 3.83428 3.33268 3.83428C4.66602 3.83428 6.33268 3.03428 7.49268 2.02095C7.63392 1.90028 7.81358 1.83398 7.99935 1.83398C8.18511 1.83398 8.36478 1.90028 8.50602 2.02095C9.67268 3.04095 11.3327 3.83428 12.666 3.83428C12.8428 3.83428 13.0124 3.90452 13.1374 4.02955C13.2624 4.15457 13.3327 4.32414 13.3327 4.50095V9.16762Z"
                    stroke="#2D9ED6"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 5.83398V8.50065"
                    stroke="#2D9ED6"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11.166H8.00667"
                    stroke="#2D9ED6"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#2D9ED6] mx-[8px] font-inter text-[16px] font-medium">
                  Rescheduled Session Reminder
                </p>
              </div>

              <button
                onClick={() => {
                  removeNotification(notification.id)
                }}
                className="cursor-pointer"
              >
                <CrossIcon />
              </button>
            </div>
            <p className="text-[#646464] font-inter text-[14px] font-normal">
              Your session on {formatDate(notification.old_date)} at {formatTime(notification.old_time)} has been
              rescheduled to {formatDate(notification?.date)} at {formatTime(notification.time)}.
            </p>
          </div>
        </div>
      ) : (
        <div className=" w-full min-h-[90px] flex border-[0.5px] rounded-[8px] border-[#D3D3D3]">
          <div className=" min-h-[90px] w-[8px]   rounded-l-[8px]  bg-[#162456] "></div>
          <div className=" my-[16px] ml-[16px] mr-[24px] w-full">
            <div className=" flex justify-between w-full">
              <div className="flex ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M13.3327 9.16762C13.3327 12.501 10.9993 14.1676 8.22602 15.1343C8.08079 15.1835 7.92304 15.1811 7.77935 15.1276C4.99935 14.1676 2.66602 12.501 2.66602 9.16762V4.50095C2.66602 4.32414 2.73625 4.15457 2.86128 4.02955C2.9863 3.90452 3.15587 3.83428 3.33268 3.83428C4.66602 3.83428 6.33268 3.03428 7.49268 2.02095C7.63392 1.90028 7.81358 1.83398 7.99935 1.83398C8.18511 1.83398 8.36478 1.90028 8.50602 2.02095C9.67268 3.04095 11.3327 3.83428 12.666 3.83428C12.8428 3.83428 13.0124 3.90452 13.1374 4.02955C13.2624 4.15457 13.3327 4.32414 13.3327 4.50095V9.16762Z"
                    stroke="#162456"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 5.83398V8.50065"
                    stroke="#162456"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11.166H8.00667"
                    stroke="#162456"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#162456] mx-[8px] font-inter text-[16px] font-medium">
                  Cancellation Reminder + Slot Open Request
                </p>
              </div>
              <button
                onClick={() => {
                  removeNotification(notification.id)
                }}
                className="cursor-pointer"
              >
                <CrossIcon />
              </button>
            </div>
            <div className=" flex justify-between">
              <div>
                <p className="text-[#646464] font-inter text-[14px] font-normal">
                  Your session on {formatDate(notification?.date)} at {formatTime(notification.time)} has been canceled.
                </p>
                <p className="text-[#646464] font-inter text-[14px] font-normal">The mentee was unable to attend.</p>
                <p className="text-[#646464] font-inter mt-[4px] text-[14px] font-normal">
                  Please consider opening additional slots for mentees to book new sessions.
                </p>
              </div>
              <Link to={"/mentor/availability"}>
                <Button variant={"primary"} className="  px-[16px] py-[9px] h-[35px] w-[142px]">
                  Open Slots
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Notifications
