import pn from "core/utils/pn"
import { postAPI, Server } from "core/utils/axios"
import {
  API_DISLIKE_QUESTION,
  API_GET_QUESTION,
  API_GET_QUESTION_WITH_ANSWER,
  API_GET_SUBMISSION,
  API_GET_QUESTION_SUBMISSIONS,
  API_GET_SUBJECTIVE_CASE_STUDY_QUESTION,
  API_IMPORT_TEMPLATE_CODE,
  API_LIKE_QUESTION,
  API_V4_STUDENT,
  STUDENT,
  CODE,
  SUBMIT,
  RUN,
  TEMPLATE_CODE,
  API_GET_EXAM_CODING_QUESTION,
  API_GET_EXAM_MCQ_QUESTION,
  API_GET_EXAM_SUBJECTIVE_CASESTUDY_QUESTION,
  API_SUBMIT_EXAM_CODE,
  API_V4_GET_EXAM_QUESTION_SUBMISSIONS,
  API_V4_GET_EXAM_QUESTIONS,
} from "core/constants/strings"
import { EditorDataSource } from "data/dataSource/Student/EditorDataSource"
import { Auth } from "domain/model/Auth"
import { SubmitExam, SubmitExamCode } from "domain/model/Exams"
import { auth } from "core/configs/firebase"

const server = new Server()

export default class EditorAPIDataSourceImpl implements EditorDataSource {
  async importTemplateCode(topic: string, auth: Auth): Promise<any> {
    try {
      const response = await server.post(
        pn(STUDENT, TEMPLATE_CODE),
        { folder_name: topic },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async getQuestion(auth: Auth, topic: string, is_contest?: boolean): Promise<any> {
    try {
      const response = (await postAPI(pn(API_V4_STUDENT, API_GET_QUESTION), {
        id_token: auth.id_token,
        topic,
        is_contest,
      })) as unknown as { success: boolean; question: any }
      return { success: response?.success, data: response?.question }
    } catch (error) {
      return error
    }
  }

  async getQuestionWithAnswer(auth: Auth, topic: string, is_contest?: boolean): Promise<any> {
    try {
      const response = (await postAPI(pn(API_V4_STUDENT, API_GET_QUESTION_WITH_ANSWER), {
        id_token: auth.id_token,
        topic,
        is_contest,
      })) as unknown as { success: boolean; question: any }
      return {
        success: response?.success,
        data: response?.question,
      }
    } catch (error) {
      return error
    }
  }

  async getSubjectiveCaseStudyQuestion(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_SUBJECTIVE_CASE_STUDY_QUESTION), {
        id_token: auth.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSubmissions(auth: Auth, topic: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_QUESTION_SUBMISSIONS), {
        id_token: auth.id_token,
        topic,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSubmission(auth: Auth, token: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_SUBMISSION), {
        id_token: auth.id_token,
        token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createSubmission(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn(STUDENT, CODE, SUBMIT), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async runCode(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn(STUDENT, CODE, RUN), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async likeQuestion(auth: Auth, topic: string, like: boolean): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_LIKE_QUESTION), {
        id_token: auth.id_token,
        topic,
        like,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async dislikeQuestion(auth: Auth, topic: string, dislike: boolean): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_DISLIKE_QUESTION), {
        id_token: auth.id_token,
        topic,
        dislike,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamCodingQuestion(auth: Auth, topic: string, is_contest?: boolean, exam_id?: string): Promise<any> {
    try {
      const response = (await postAPI(pn(API_V4_STUDENT, API_GET_EXAM_CODING_QUESTION), {
        id_token: auth.id_token,
        topic,
        is_contest,
        exam_id,
      })) as unknown as { success: boolean; question: any }
      return { success: response?.success, data: response?.question }
    } catch (error) {
      return error
    }
  }

  async getExamMcqQuestion(auth: Auth, question_id: string, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_EXAM_MCQ_QUESTION), {
        id_token: auth.id_token,
        question_id,
        exam_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamSubjectiveCaseStudyQuestion(auth: Auth, id: string, exam_id?: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_EXAM_SUBJECTIVE_CASESTUDY_QUESTION), {
        id_token: auth.id_token,
        id,
        exam_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createExamCodeSubmission(submitCode: SubmitExam): Promise<any> {
    try {
      const response = await server.post(pn(STUDENT, CODE, API_SUBMIT_EXAM_CODE), submitCode as SubmitExamCode, {
        Authorization: `Bearer ${submitCode.auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamQuestionSubmissions(auth: Auth, topic: string, exam_id: string, batch_id?: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_EXAM_QUESTION_SUBMISSIONS), {
        id_token: auth.id_token,
        topic,
        exam_id,
        batch_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamQuestions(auth: Auth, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_EXAM_QUESTIONS), {
        id_token: auth.id_token,
        exam_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
