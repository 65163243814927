import { Auth } from "domain/model/Auth"

import SessionRescheduleRequestRepository from "domain/repository/Student/SessionRescheduleRequestRepository"
import { SessionRescheduleRequestDataSource } from "data/dataSource/Student/SessionRescheduleRequestDataSource"
import { CreateSessionRescheduleRequestDetails } from "domain/model/SessionRescheduleRequest"

export class SessionRescheduleRequestRepositoryImpl implements SessionRescheduleRequestRepository {
  private dataSource: SessionRescheduleRequestDataSource

  constructor(dataSource: SessionRescheduleRequestDataSource) {
    this.dataSource = dataSource
  }

  async createSessionRescheduleRequest(auth: Auth, details: CreateSessionRescheduleRequestDetails): Promise<Auth> {
    return this.dataSource.createSessionRescheduleRequest(auth, details)
  }
  async getSessionRescheduleRequest(auth: Auth): Promise<any> {
    return this.dataSource.getSessionRescheduleRequest(auth)
  }
}
