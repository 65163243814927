import { format } from "date-fns"
import TableButton from "core/components/v2/TableButton"
import { CustomScroll } from "react-custom-scroll"

export default function CourseSidebar({ lectures, courseName, activeIndex, onClick }: any) {
  return (
    <div className="space-y-2 rounded-lg bg-[#e3e3e3] pb-2 pr-1 shadow-[-1px_1px_3px_0pxr_rgba(132,136,139,0.05)]">
      <div className="p-[16px] bg-[#e3e3e3] rounded">
        <p className="text-start text-[14px] font-[600] capitalize text-[#333] font-inter leading-normal">
          {courseName}
        </p>
        <p className="text-start text-[14px] font-[600] capitalize text-[#333] font-inter leading-normal">
          {lectures?.length} Video Lectures
        </p>
      </div>

      <div className="relative flex h-[calc(100vh-300px)] w-full flex-col items-center gap-y-2 overflow-y-auto px-2 pr-1">
        <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
          {lectures?.map((item: any, idx: number) => (
            <div
              className={`flex h-[134px] min-h-[134px] w-[calc(100%-32px)] min-w-[205px] flex-col gap-2 pt-[12px] pl-[12px] pr-[12px] pb-[16px] mb-4 rounded-lg border-[0.5px] bg-white p-3 
            ${activeIndex === idx ? "shadow-[0px_1px_3px_0px_rgba(34,41,48,0.15)]" : "shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]"}
          `}
            >
              <div className="flex justify-between">
                <h3
                  className="w-4/5 text-sm font-semibold text-[#333] font-[Inter] text-[14px] leading-normal"
                  title={item?.chapter_name}
                >
                  {item?.chapter_name.slice(0, 20)}...
                </h3>
                <input
                  type="checkbox"
                  className="border-2 border-[#E3E3E3] h-[16px] w-[16px] checked:bg-v2-success"
                  checked={item?.is_completed}
                />
              </div>
              <div className="text-[#646464] font-[Inter] text-[11px] font-normal leading-[130%]">
                Date: {format(item?.timestamp * 1000, "do MMM yyyy")}
              </div>

              <div className="mt-auto flex">
                <TableButton
                  outlined
                  onClick={() => onClick(item, idx)}
                  className={`${activeIndex === idx && "border-[#07B42D] text-[#07B42D]"}`}
                >
                  {activeIndex === idx ? "Selected" : "Play Video"}
                </TableButton>
              </div>
            </div>
          ))}
        </CustomScroll>
      </div>
    </div>

    // <div className="shrink-1 flex h-full w-[222px] flex-col rounded shadow-card">
    //   <div className="space-y-1 border-b border-new-neutral-light p-6">
    //     <h3 className="text-new-accent">{courseName}</h3>
    //     <p className="text-xs leading-[19px] text-new-neutral">
    //       {lectures?.length} Video Lectures
    //     </p>
    //   </div>
    //   <div className="gray-scrollbar flex h-[60vh] flex-col gap-4 overflow-y-auto px-6 py-4">
    //     {lectures?.map((lecture: any, i: number) => (
    //       <button
    //         key={i}
    //         className={cn(
    //           "relative block cursor-pointer rounded-sm border-2 px-4 py-2 hover:border-new-accent",
    //           activeIndex === i &&
    //             "before:absolute before:top-2 before:right-4 before:h-1.5 before:w-1.5 before:rounded-full before:bg-new-accent",
    //           activeIndex === i
    //             ? "border-new-accent"
    //             : "border-new-neutral-light"
    //         )}
    //         onClick={() => onClick(lecture, i)}
    //       >
    //         <div className="flex gap-1">
    //           <span
    //             className={cn(
    //               lecture?.is_completed
    //                 ? "text-new-success"
    //                 : "text-new-neutral-light"
    //             )}
    //           >
    //             {lecture?.is_completed ? (
    //               <CheckSuccessIcon className="h-4 w-4" />
    //             ) : (
    //               <CheckBlankIcon className="h-4 w-4" />
    //             )}
    //           </span>
    //           <h4 className="text-new-neutral-light">
    //             {format(lecture?.timestamp * 1000, "dd/MM/yyyy")}
    //           </h4>
    //         </div>
    //         <h4 className="text-left text-new-accent">
    //           {lecture?.chapter_name}
    //         </h4>
    //       </button>
    //     ))}
    //   </div>
    // </div>
  )
}
