import React from "react"
import { useNavigate } from "react-router-dom"
import ScheduledClassesViewModel from "../ViewScheduledClasses/ScheduledClassesViewModel"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import { CreateExamTemplate } from "domain/useCase/Admin/Contest/CreateExamTemplate"
import { GetAllExamTemplates } from "domain/useCase/Admin/Batch/GetAllExamTemplates"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { Auth } from "domain/model/Auth"
import { STR_FAILURE } from "core/constants/strings"
import { genError } from "core/utils/string"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"

export default function ContestFlowViewModel() {
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const navigate = useNavigate()
  //   const [genericBatchType, setGenericBatchType] = React.useState("")
  //   const [genericStudentType, setGenericStudentType] = React.useState("")
  const [genericTemplateType, setGenericTemplateType] = React.useState("")
  const [genericTemplateTypeList, setGenericTemplateTypeList] = React.useState<string[]>([])
  const [studentType, setStudentType] = React.useState("")
  const [studentEmail, setStudentEmail] = React.useState("")
  const [batch, setBatch] = React.useState("")

  const GetAllExamTemplateUseCase = new GetAllExamTemplates(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))

  const [templateId, setTemplateId] = React.useState("")
  const [templateMap, setTemplateMap] = React.useState<any>({})

  async function fetchGenericTemplateTypeList() {
    const response = await GetAllExamTemplateUseCase.invoke(auth)
    if (response?.success) {
      const templateList = response?.data

      const templateMap = templateList.reduce((acc: any, template: any) => {
        acc[template.template_name] = template.template_id

        return acc
      }, {})

      setTemplateMap(templateMap)

      setGenericTemplateTypeList(templateList.map((template: any) => template.template_name))
    }
  }
  const navigateToEditGeneric = (e: any) => {
    navigate(`${templateId}/edit`)
  }
  const handleGenericBatchChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplateType = e.target.value
    if (selectedTemplateType !== "") {
      const templateId = templateMap[selectedTemplateType]
      setTemplateId(templateId)
      setGenericTemplateType(selectedTemplateType)
    } else {
      setTemplateId("")
      setGenericTemplateType("")
    }
  }

  const navigateToCreateGeneric = (e: any) => {
    navigate(`create`)
  }

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const [getBatchList, setBatchList] = React.useState<any>([])
  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)
    if (response.success) {
      setBatchList(response.data)
    }
  }

  function handleBatchChange(e: any) {
    if (e.target.value) setBatch(e.target.value)
    else setBatch("")
  }
  function handleStudentEmailChange(e: any) {
    setStudentEmail(e.target.value)
  }
  const handleStudentChangeType = (e: any) => {
    setStudentType(e.target.value)
  }
  const navigateToEdit = (e: any) => {
    navigate(`${batch}/edit`)
  }
  const navigateToEditByStudent = (e: any) => {
    navigate(`${studentEmail}/edit`)
  }
  return {
    genericTemplateType,
    genericTemplateTypeList,
    studentType,
    studentEmail,
    batch,
    handleGenericBatchChangeType,
    handleBatchChange,
    handleStudentEmailChange,
    handleStudentChangeType,
    navigateToEditGeneric,
    navigateToEdit,
    navigateToEditByStudent,
    getBatchList,
    fetchGenericTemplateTypeList,
    navigateToCreateGeneric,
    templateId,
    getBatches,
  }
}
