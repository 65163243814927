import React, { useEffect, useState } from "react"
import celebrationImage from "assets/images/celebration.png"

interface CelebrationScreenProps {
  isVisible: boolean
  onClose: () => void
}

const CelebrationScreen: React.FC<CelebrationScreenProps> = ({ isVisible, onClose }) => {
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    if (isVisible) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            onClose() // Close the screen when countdown reaches 0
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [isVisible, onClose])

  if (!isVisible) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[1000]">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <img src={celebrationImage} alt="Celebration" className="w-3/4 mx-auto object-cover rounded" />
        <h2 className="text-lg font-semibold text-center text-black">Congratulations from Bosscoder Team</h2>
        <p className="text-sm mb-2 text-center text-black">
          Your LinkedIn post has been copied! Paste (Ctrl+V) it while creating your post.
        </p>
        <p className="text-sm mt-2 text-center text-blue-500">Redirecting you to LinkedIn in {countdown} seconds...</p>
      </div>
    </div>
  )
}

export default CelebrationScreen
