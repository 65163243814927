import React, { useEffect } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import PaymentPopUpBreakdown from "./components/PaymentInvoiceModal"
import PaymentInvoiceViewModel from "./PaymentInvoiceViewModel"
import Button from "core/components/Button"
import Toast from "core/components/Toast"
import CopyIcon from "assets/svgs/CopyIcon"
import PaymentPopUpBreakdowntotal from "./components/PaymentDetails"

export default function PaymentInfluencerInvoiceView() {
  const {
    selectedYear,
    formattedCampaigns,
    selectedInfluencer,
    year,
    month,
    campaignDataArray,
    selectedInvoice,
    inputValuesmodal,
    invoiceData,
    selectedMonth,
    influencerData,
    getMonth,
    CampaignData,
    tableHead,
    openPopUp2,
    currentPage,
    invoicesPerPage,
    scrollContainerRef,
    calculatedTotal,
    filteredInvoices,
    toast,
    openPopUp1,
    setFormattedCampaigns,
    setSelectedInfluencer,
    setMonth,
    setYear,
    setInputValuesmodal,
    setSelectedInvoice,
    calculateBreakdown,
    setCampaignDataArray,
    handleYearChange,
    generateCID,
    getInfluencersData,
    handleMonthChange,
    setOpenPopUp2,
    changeToastVisibility,
    handleCopyToClipboard,
    handleClearFilter,
    submit,
    getCampaignType,
    generateInvoice,
    setOpenPopUp1,
    getInfluencerGeneratedInvoice,
    handlePopUp1,
    handlPopUp2,
    setFilteredInvoices,
  } = PaymentInvoiceViewModel()
  React.useEffect(() => {
    getInfluencersData()
    getCampaignType()
    getInfluencerGeneratedInvoice()
  }, [])

  useEffect(() => {
    if (!selectedMonth && !selectedYear) {
      setFilteredInvoices(invoiceData)
    } else {
      const filtered = invoiceData.filter((invoice: any) => {
        const [invoiceMonth, invoiceYear] = invoice.date.split("-")
        const isMonthMatch = selectedMonth ? invoiceMonth == selectedMonth : true
        const isYearMatch = selectedYear ? invoiceYear == selectedYear : true
        return isMonthMatch && isYearMatch
      })
      setFilteredInvoices(filtered)
    }
  }, [invoiceData, selectedMonth, selectedYear])
  return (
    <DashboardLayout>
      {openPopUp1 && (
        <PaymentPopUpBreakdown
          handlePopUp={handlePopUp1}
          getInfluencersData={getInfluencersData}
          getCampaignType={getCampaignType}
          CampaignData={CampaignData}
          influencerData={influencerData}
          selectedInflunencer={selectedInfluencer}
          setSelectedInfluencer={setSelectedInfluencer}
          month={month}
          changeToastVisibility={changeToastVisibility}
          toast={toast}
          getMonth={getMonth}
          setMonth={setMonth}
          Toast={Toast}
          generateCID={generateCID}
          year={year}
          invoiceData={invoiceData}
          setYear={setYear}
          campaignDataArray={campaignDataArray}
          setInputValuesmodal={setInputValuesmodal}
          inputValuesmodal={inputValuesmodal}
          setCampaignDataArray={setCampaignDataArray}
          submit={submit}
          formattedCampaigns={formattedCampaigns}
          setFormattedCampaigns={setFormattedCampaigns}
        />
      )}
      {openPopUp2 && (
        <PaymentPopUpBreakdowntotal
          handlPopUp2={handlPopUp2}
          selectedInvoice={selectedInvoice}
          calculatedTotal={calculatedTotal}
        />
      )}
      <div className="relative rounded-md shadow-md mt-2">
        <div className="space-y-6 p-6">
          <div>
            <h2 className="text-new-accent">Payment Invoices</h2>
            <p>View, find, manage & update Payment Invoices</p>
          </div>
          <div className="flex w-full flex-wrap gap-4 space-y-6">
            <div className="flex w-full flex-wrap gap-4">
              <div className="space-y-2 flex pr-2">
                <div className="space-y-2 w-full">
                  <button
                    onClick={() => setOpenPopUp1(true)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                  >
                    Add Invoice
                  </button>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-wrap gap-4">
              <div className="space-y-2 flex pr-2">
                <div className="space-y-2 w-full">
                  <label>Select Month</label>
                  <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="w-full border h-[34.6px] text-sm"
                  >
                    <option value="">select month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </div>
              <div className="space-y-2 flex pr-2">
                <div className="space-y-2 w-full">
                  <label>Select Year</label>
                  <select value={selectedYear} onChange={handleYearChange} className="w-full border h-[34.6px] text-sm">
                    <option value="">select year</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
              </div>
              <Button
                className="bg-new-accent text-new-solid-white mt-8 w-[150px] h-[36px] text-sm"
                onClick={handleClearFilter}
              >
                Clear Filters
              </Button>
            </div>
          </div>
        </div>
        <div
          ref={scrollContainerRef}
          className="relative overflow-x-auto rounded-md shadow-md border-2 border-[#ADADAD] overflow-y-auto max-h-[400px] custom-scrollbar-2"
        >
          <table className="w-full text-sm text-new-neutral-dark border-collapse">
            <thead className="bg-new-neutral-dark text-new-solid-white sticky top-0 z-10">
              <tr>
                {tableHead.map((header, index) => (
                  <th key={index} className="px-6 py-3 text-center font-medium">
                    {header === "Amount" ? `${header} ( ₹. )` : header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredInvoices?.map((invoice: any, index: number) => {
                const influencer = influencerData.find((inf: any) => inf.email === invoice.email)
                const name = influencer ? influencer.name : "Name not found"
                return (
                  <tr key={index} className="hover:bg-gray-100 even:bg-gray-50">
                    <td className="px-6 py-3 text-center">{(currentPage - 1) * invoicesPerPage + (index + 1)}</td>
                    <td className="px-6 py-3 justify-center text-left flex items-right">
                      <p>{name}</p>
                      <button
                        className="ml-3 p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-200 rounded-md"
                        title={invoice.email}
                        onClick={() => handleCopyToClipboard(invoice.email)}
                      >
                        <CopyIcon />
                      </button>
                    </td>
                    <td className="px-6 py-3 text-center capitalize">{invoice.date}</td>
                    <td className="px-6 py-3 text-center">
                      ₹{invoice.amount}
                      <>
                        <button
                          className="text-gray-500"
                          onClick={() => {
                            calculateBreakdown(invoice)
                            setSelectedInvoice(invoice)
                            setOpenPopUp2(true)
                          }}
                        >
                          <InfoOutlinedIcon />
                        </button>
                      </>
                    </td>

                    <td className="px-6 py-3 text-center">
                      <div className="flex justify-center items-center gap-4">
                        <button
                          className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
                          onClick={() => {
                            generateInvoice(invoice.email, invoice.posts, invoice.amount, invoice.date)
                          }}
                        >
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
