import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import { Auth } from "domain/model/Auth"
import Input from "core/components/Input"
import Button from "core/components/new/Button"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import { STR_DATE_AND_TIME_FIELD, STR_FAILURE, STR_SUCCESS, STR_TEXT_FIELD } from "core/constants/strings"
// import ConfirmationPopUpMentorRemoval from "./components/ChangesConfirmationPopup"
import Loader from "core/components/Loader"
import Vector from "assets/svgs/Vector"
import useEditContestFlowViewModel from "./EditContestFlowViewModel"
import ConfirmationPopUpModuleRemoval from "./components/ChangesConfirmationPopup"
import ExamChangesConfirmationPopup from "./components/ExamChangesPopup"
import { DatePicker } from "core/components/v2/ui/DatePicker"
import { format } from "date-fns"
export default function EditContestFlowView() {
  const {
    // mentorshipType,
    filterType,
    // studentId,
    items,
    // nonGenericItems,
    indexToBeDeleted,
    setIndexToBeDeleted,
    isOpen,
    setIsOpen,
    isLoading,
    updateExamtemplateGeneric,
    // updateBatchtemplate,
    // getBatchtemplateGeneric,
    // getBatchtemplateNonGeneric,
    handleDeleteModule,
    // handleAddField,
    handleInputChange,
    // handleGenericInputChange,
    toast,
    changeToastVisibility,
    examData,
    setExamData,
    templateDetails,
    setTemplateDetails,
    getExamtemplateGeneric,
    template_id,
    extractTemplateData,
    // handleAddExamField,
    deleteModuleTemplate,
    examIndex,
    setExamIndex,
    // deleteExamInModule,
    isOpenExamDelete,
    setIsOpenExamDelete,
    getBatchExamTemplate,
    updateBatchExamtemplate,
    getStudentExamTemplate,
    updateStudentExamtemplate,
    handleAddMockExam,
    handleDeleteMockExam,
    handleAddPhaseExam,
    handleDeletePhaseExam,
    handleAddPhase,
    handleDeletePhase,
    handleAddAttempt,
    handleDeleteAttempt,
    handleStartTimeChange,
    isFormValid,
    validateForm,

    startTime,
    setStartTime,
    saveTimestamp,
    handleTemplateNameChange,
    templateName,
    auth,
  } = useEditContestFlowViewModel()

  React.useEffect(() => {
    if (auth) {
      extractTemplateData()
    }
  }, [auth])

  React.useEffect(() => {
    if (filterType && template_id) {
      if (filterType === "generic") {
        getExamtemplateGeneric(template_id)
      } else if (filterType === "batch" && template_id) {
        getBatchExamTemplate(template_id)
      }
    }
  }, [filterType, template_id])
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0) // Example index for the selected item
  const updates = items.map((item) => ({
    mock: item.mock.map((mockItem) => ({
      id: mockItem.id || "",
      name: mockItem.name || "",
      link: mockItem.link || "",
    })),
    phases: item.phases.map((phase) => ({
      id: phase.id || "",
      name: phase.name || "",
      exams: phase.exams.map((exam) => ({
        id: exam.id || "",
        name: exam.name || "",
        type: exam.type || "",
        attempts: exam.attempts.map((attempt) => ({
          link: attempt.link || "",
          timestamp: attempt.timestamp || 0,
        })),
      })),
    })),
  }))

  React.useEffect(() => {
    validateForm(items)
  }, [items])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">Edit Exam Flow Template</h2>
          <p className="">You have access to edit Exam Flow Templates here.</p>
        </div>
        <div className="flex items-center flex-col gap-4">
          <div className="flex flex-row gap-4">
            <Input
              className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
              readOnly
              value="Template Name"
            />
            <Input
              value={templateDetails?.name}
              onChange={(e) => {
                if (templateDetails) {
                  setTemplateDetails({ ...templateDetails, name: e.target.value })
                } else {
                  setTemplateDetails({ name: e.target.value, template_id: template_id })
                }
              }}
              required={true}
            />
          </div>
          {items.map((item: any, index: any) => (
            <div className="flex flex-col gap-4 max-w-[700px]" key={index}>
              {/* Mock Exam */}
              {item?.mock?.map((m: any, mockIndex: any) => (
                <div key={mockIndex} className="flex flex-col gap-4">
                  <div className="flex flex-row gap-4">
                    <Input
                      className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                      readOnly
                      value="Mock Exam Name"
                    />

                    <Input
                      value={m.name}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "mock",
                          { name: e.target.value },
                          mockIndex,
                          undefined,
                          undefined,
                          undefined
                        )
                      }
                    />
                  </div>

                  <div className="flex flex-row gap-4">
                    <Input
                      className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                      readOnly
                      value="Mock Exam Link"
                    />
                    <Input
                      value={m.link}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "mock",
                          { link: e.target.value },
                          mockIndex,
                          undefined,
                          undefined,
                          undefined
                        )
                      }
                    />
                  </div>
                  <div className="flex flex-row gap-4">
                    <Button
                      className="max-w-[580px]"
                      failure
                      outlined
                      onClick={() => handleDeleteMockExam(index, mockIndex)}
                    >
                      Delete Mock Exam
                    </Button>
                  </div>
                </div>
              ))}
              <div className="flex flex-row gap-4">
                <Button className="max-w-[580px]" success outlined onClick={() => handleAddMockExam(index)}>
                  Add Mock Exam
                </Button>
              </div>
              {/* Phase Name */}
              {item.phases.map((phase: any, phaseIndex: any) => (
                <div key={phaseIndex} className="flex flex-col gap-4">
                  <div className="flex flex-row gap-4">
                    <Input
                      className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                      readOnly
                      value="Phase Name"
                    />

                    <Input
                      value={phase.name}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "phases",
                          { name: e.target.value },
                          undefined,
                          undefined,
                          phaseIndex,
                          undefined
                        )
                      }
                    />
                  </div>

                  {/* Exams Section */}

                  {phase.exams.map((exam: any, examIndex: any) => (
                    <div key={examIndex} className="flex flex-col gap-4">
                      <div className="flex flex-row gap-4">
                        <Input
                          className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                          readOnly
                          value="Exam Name"
                        />

                        <Input
                          value={exam.name}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "phases",
                              { name: e.target.value },
                              undefined,
                              examIndex,
                              phaseIndex,
                              undefined
                            )
                          }
                        />
                      </div>

                      {/* Exam Type */}

                      <div className="flex flex-row gap-4">
                        <Input
                          className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                          readOnly
                          value="Exam Type (unit/milestone)"
                        />

                        <Input
                          value={exam.type}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "phases",
                              { type: e.target.value },
                              undefined,
                              examIndex,
                              phaseIndex,
                              undefined
                            )
                          }
                        />
                      </div>
                      {filterType === "batch" && (
                        <div className="flex flex-col gap-4">
                          {exam?.attempts?.map((attempt: any, attemptIndex: any) => (
                            <div key={attemptIndex} className="flex flex-col gap-4">
                              <div className="flex flex-row gap-4">
                                <Input
                                  className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                                  readOnly
                                  value="Attempt Link"
                                />

                                <Input
                                  value={attempt.link}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "phases",
                                      { link: e.target.value, timestamp: attempt.timestamp },
                                      undefined,
                                      examIndex,
                                      phaseIndex,
                                      attemptIndex
                                    )
                                  }
                                />
                              </div>

                              <div className="flex flex-row gap-4">
                                <Input
                                  className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
                                  readOnly
                                  value="Attempt Date"
                                />

                                <Input
                                  type={STR_TEXT_FIELD}
                                  value={attempt.timestamp}
                                  onChange={(e) => {
                                    const newTimestamp = Math.floor(new Date(e.target.value).getTime() / 1000)

                                    handleInputChange(
                                      index,
                                      "phases",
                                      { link: attempt.link, timestamp: e.target.value },
                                      undefined,
                                      examIndex,
                                      phaseIndex,
                                      attemptIndex
                                    )
                                  }}
                                  placeholder="Enter start time"
                                  onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
                                  onFocus={(e) => (e.target.type = STR_DATE_AND_TIME_FIELD)}
                                />
                              </div>

                              <div className="flex flex-row gap-4">
                                <Button
                                  className="max-w-[580px]"
                                  failure
                                  outlined
                                  onClick={() => handleDeleteAttempt(index, phaseIndex, examIndex, attemptIndex)}
                                >
                                  Delete Attempt
                                </Button>
                              </div>
                            </div>
                          ))}

                          <div className="flex flex-row gap-4">
                            <Button
                              className="max-w-[580px]"
                              success
                              outlined
                              onClick={() => handleAddAttempt(index, phaseIndex, examIndex)}
                            >
                              Add Attempt
                            </Button>
                          </div>
                        </div>
                      )}

                      <div className="flex flex-col items-center"></div>
                      <div className="flex flex-row gap-4">
                        <Button
                          className="max-w-[300px]"
                          success
                          outlined
                          onClick={() => handleAddPhaseExam(index, phaseIndex, "", "")}
                        >
                          Add Phase Exam
                        </Button>

                        <Button
                          className="max-w-[300px]"
                          failure
                          outlined
                          onClick={() => handleDeletePhaseExam(index, phaseIndex, examIndex)}
                        >
                          Delete Phase Exam
                        </Button>
                      </div>
                    </div>
                  ))}

                  <div className="flex flex-row gap-4">
                    <Button
                      className="max-w-[580px]"
                      failure
                      outlined
                      onClick={() => handleDeletePhase(index, phaseIndex)}
                    >
                      Delete Phase
                    </Button>
                  </div>
                </div>
              ))}
              <div className="flex flex-row gap-4">
                <Button className="max-w-[580px]" success outlined onClick={() => handleAddPhase(index, "", "", "")}>
                  Add Phase
                </Button>
              </div>
            </div>
          ))}

          <Button
            className="max-w-[580px] h-[54px]"
            onClick={async () => {
              if (filterType === "generic") {
                await updateExamtemplateGeneric(updates, templateDetails?.name || "")
              } else if (filterType === "batch") {
                await updateBatchExamtemplate(updates, templateDetails?.name || "")
                await getBatchExamTemplate(template_id)
              } else if (filterType === "student") {
                updateStudentExamtemplate(updates, templateDetails?.name || "")
              }
            }}
            disabled={isLoading || !isFormValid}
          >
            {isLoading ? <Loader height="54 px" /> : "Save Changes"}
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
