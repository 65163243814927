import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface getTemplateIntersectionUseCase {
  invoke(auth: Auth, batch_ids: string[]): Promise<any>
}

export class GetTemplateIntersection implements getTemplateIntersectionUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch_ids: string[]) {
    return await this.repository.getTemplateIntersection(auth, batch_ids)
  }
}
