import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface updateBatchExamTemplateUseCase {
  invoke(auth: Auth, batch_id: string, updates: string[], name: string): Promise<any>
}

export class UpdateBatchExamTemplate implements updateBatchExamTemplateUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, batch_id: string, updates: string[], name: string) {
    return await this.repository.updateBatchExamTemplate(auth, batch_id, updates, name)
  }
}
