import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { ScheduleClassAPIDataSource } from "data/API/Admin/ScheduledClassAPIDataSource"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { ScheduleClassRepositoryImpl } from "data/repository/Admin/ScheduledClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { ClassTemplate } from "domain/useCase/Admin/CreateClass/GetClassTemplate"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { GetModuleClasses } from "domain/useCase/Admin/CreateClass/GetModuleClasses"
import { UpdateScheduledClass } from "domain/useCase/Admin/ScheduledClasses/UpdateScheduledClass"
import { useState } from "react"
import CustomModel from "./CustomModel"
import { UpdateScheduledClassWithoutIcs } from "domain/useCase/Admin/ScheduledClasses/UpdateScheduledClassWithoutIcs"

export default function UpdateClassFormViewModel() {
  const [selectBatch, setSelectBatch] = useState<any>([])
  const [currentSelectBatch, setcurrentSelectBatch] = useState<any>(null)
  const [chapterNames, setChapterNames] = useState<any>(null)
  const [classLink, setClassLink] = useState<any>(null)
  const [selectInstructor, setSelectInstructor] = useState<any>(null)
  const [selectModuleId, setSelectModuleId] = useState<any>(null)
  const [compensationStatus, setCompensationStatus] = useState("")
  const [notes_link, setNotesLink] = useState<any>(null)
  const [selectAssigmentsQuestions, setSelectAssigmentsQuestions] = useState<any>(null)
  const [currentSelectAssignmentsQuestions, setCurrentSelectAssignmentsQuestions] = useState<any>("")
  const [status, setStatus] = useState("")
  const [selectHomeworkQuestions, setSelectHomeworkQuestions] = useState<any>(null)
  const [currentSelectHomeworkQuestions, setCurrentSelectHomeworkQuestions] = useState<any>("")
  const [selectPracticalQuestions, setSelectPracticalQuestions] = useState<any>(null)
  const [currentSelectPracticalQuestions, setCurrentSelectPracticalQuestions] = useState<any>("")
  const [selectWarmupQuestions, setselectWarmupQuestions] = useState<any>(null)
  const [currentSelectWarmupQuestions, setCurrentSelectWarmupQuestions] = useState<any>("")
  const [session_timestamp, setSessionTimestamp] = useState<any>(null)
  const [sessionDuration, setSessionDuration] = useState<string>()
  const [otherSessionDuration, setOtherSessionDuration] = useState<any>()
  const [selectTrack, setSelectTrack] = useState<any>(null)
  const [videoId, setVideoId] = useState<any>(null)
  const [videoSecret, setVideoSecret] = useState<any>(null)
  const [notesSummaryLink, setNotesSummaryLink] = useState<any>(null)
  const [preRequisite, setPreRequisite] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)
  const [loading2, setLoading2] = useState<any>(false)
  const [sessionId, setSessionId] = useState<any>(null)
  const [previousTimestamp, setPreviousTimestamp] = useState<any>(null)
  const [moduleId, setModuleId] = useState<string[]>([])
  const [TrackId, setTrackId] = useState<string[]>([])
  const [, setDataMap] = useState<any>({})
  const [moduleClassesList, setModuleClassesList] = useState<any>([])
  const [selectClassName, setSelectClassName] = useState("")
  const [enableOthersClass, setEnableOthersClass] = useState(false)
  const [othersClassName, setOthersClassName] = useState("")
  const [masterClassType, setMasterClassType] = useState("Software Engineering")
  const [assignmentSolutionLink, setAssignmentSolutionLink] = useState<any>("")
  const [assignmentSolutionActivationTime, setAssignmentSolutionActivationTime] = useState<any>("")
  const [reloadClass, setReloadClass] = useState(false)
  const [assignmentCoins, setAssignmentCoins] = useState<string>("")
  const [firstReminderMessage, setFirstReminderMessage] = useState<string>("")
  const [secondReminderMessage, setSecondReminderMessage] = useState<string>("")
  const [model, setModel] = useState(false)

  const UpdateScheduledClassUseCase = new UpdateScheduledClass(
    new ScheduleClassRepositoryImpl(new ScheduleClassAPIDataSource())
  )

  const updateScheduledClassWithoutIcsUseCase = new UpdateScheduledClassWithoutIcs(
    new ScheduleClassRepositoryImpl(new ScheduleClassAPIDataSource())
  )

  const getModuleBasedClassUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  const getAllTracksUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getModuleClassesUseCase = new GetModuleClasses(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  const getClassTemplateUseCase = new ClassTemplate(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const problems = {
    assignments: selectAssigmentsQuestions,
    homework: selectHomeworkQuestions,
    practice: selectPracticalQuestions,
    warmup: selectWarmupQuestions,
  }
  const handleModalClose = () => setModel(!model)

  async function updateClass() {
    setLoading(true)
    if (chapterNames == "others") {
      setChapterNames(othersClassName)
    }
    const response = await UpdateScheduledClassUseCase.invoke({
      id_token: auth.id_token?.trim(),
      batches: selectBatch,
      session_id: sessionId?.trim(),
      chapter_name: chapterNames === "others" ? othersClassName.trim() : selectClassName,
      class_link: classLink?.trim(),
      instructor: selectInstructor?.trim(),
      module_id: selectModuleId?.trim(),
      problems: problems,
      session_timestamp: session_timestamp === null ? previousTimestamp : Date.parse(session_timestamp) / 1000,
      session_duration: Number(sessionDuration == "other" ? otherSessionDuration : sessionDuration),
      track: selectTrack?.trim(),
      master_class_type: selectTrack.trim() == "master_class" ? masterClassType : "",
      video_id: videoId?.trim(),
      video_secret: videoSecret?.trim(),
      notes_link: notes_link?.trim(),
      notes_summary_link: notesSummaryLink?.trim(),
      pre_requisite: preRequisite?.trim(),
      compensation_status: compensationStatus,
      assignment_solution_activation_time: assignmentSolutionActivationTime?.trim(),
      assignment_solution_link: assignmentSolutionLink?.trim(),
      assignment_points: assignmentCoins === "" ? 0 : parseInt(assignmentCoins),
      status,
      first_reminder: firstReminderMessage,
      second_reminder: secondReminderMessage,
    })

    if (response?.success) {
      changeToastVisibility(true)
      setReloadClass(!reloadClass)
      changeToastDetails(STR_SUCCESS, response?.data)
      setLoading(false)
    } else {
      setLoading(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      setLoading(false)
    }
    setModel(!model)
  }

  async function updateClassWithoutIcs() {
    setLoading2(true)
    if (chapterNames == "others") {
      setChapterNames(othersClassName)
    }

    const response = await updateScheduledClassWithoutIcsUseCase.invoke({
      id_token: auth.id_token?.trim(),
      batches: selectBatch,
      session_id: sessionId?.trim(),
      chapter_name: chapterNames === "others" ? othersClassName?.trim() : chapterNames?.trim(),
      class_link: classLink?.trim(),
      instructor: selectInstructor?.trim(),
      module_id: selectModuleId?.trim(),
      problems: problems,
      session_timestamp: session_timestamp === null ? previousTimestamp : Date.parse(session_timestamp) / 1000,
      session_duration: Number(sessionDuration == "other" ? otherSessionDuration : sessionDuration),
      track: selectTrack?.trim(),
      master_class_type: selectTrack?.trim() == "master_class" ? masterClassType : "",
      video_id: videoId?.trim(),
      video_secret: videoSecret?.trim(),
      notes_link: notes_link?.trim(),
      notes_summary_link: notesSummaryLink?.trim(),
      pre_requisite: preRequisite?.trim(),
      compensation_status: compensationStatus,
      assignment_solution_activation_time: assignmentSolutionActivationTime?.trim(),
      assignment_solution_link: assignmentSolutionLink?.trim(),
      assignment_points: assignmentCoins === "" ? 0 : parseInt(assignmentCoins),
      status,
    })

    if (response?.success) {
      changeToastVisibility(true)
      setReloadClass(!reloadClass)
      changeToastDetails(STR_SUCCESS, response?.data)
      setLoading2(false)
    } else {
      setLoading2(false)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      setLoading2(false)
    }
  }

  function handleAssignmentChange(e: any) {
    setCurrentSelectAssignmentsQuestions(e.target.value)
  }

  function handleAddAssignmentQuestions(e: any) {
    e.preventDefault()
    if (currentSelectAssignmentsQuestions.length > 0) {
      setSelectAssigmentsQuestions((prev: any) => [...prev, currentSelectAssignmentsQuestions])
    }
    setCurrentSelectAssignmentsQuestions("")
  }

  function handleStatusChange(e: any) {
    setStatus(e.target.value)
  }

  function handleSelectHomeworkChange(e: any) {
    setCurrentSelectHomeworkQuestions(e.target.value)
  }

  function handleAddHomeworkQuestions(e: any) {
    e.preventDefault()
    if (currentSelectHomeworkQuestions.length > 0) {
      setSelectHomeworkQuestions([...selectHomeworkQuestions, currentSelectHomeworkQuestions])
    }
    setCurrentSelectHomeworkQuestions("")
  }

  function handlePracticalChange(e: any) {
    setCurrentSelectPracticalQuestions(e.target.value)
  }

  function handleOthersClass(e: any) {
    setOthersClassName(e.target.value)
    setSelectAssigmentsQuestions([])
    setSelectHomeworkQuestions([])
    setSelectPracticalQuestions([])
    setselectWarmupQuestions([])
    setNotesLink("")
    setNotesSummaryLink("")
    setCurrentSelectAssignmentsQuestions([])
  }

  function handleAddPracticalQuestions(e: any) {
    e.preventDefault()
    if (currentSelectPracticalQuestions.length > 0) {
      setSelectPracticalQuestions([...selectPracticalQuestions, currentSelectPracticalQuestions])
    }
    setCurrentSelectPracticalQuestions("")
  }

  function handleWarmupChange(e: any) {
    setCurrentSelectWarmupQuestions(e.target.value)
  }

  function handleAddWarmupQuestions(e: any) {
    e.preventDefault()
    if (currentSelectWarmupQuestions.length > 0) {
      setselectWarmupQuestions([...selectWarmupQuestions, currentSelectWarmupQuestions])
    }
    setCurrentSelectWarmupQuestions("")
  }

  async function getAllTracks() {
    const response = await getAllTracksUseCase.invoke(auth)

    if (response?.success) {
      setTrackId(response?.data)
    }
  }

  function handleBatchChange(e: any) {
    const selectedValue = e.target.value

    setcurrentSelectBatch(selectedValue)

    if (selectBatch.includes(selectedValue)) {
      const updatedSelectBatch = selectBatch.filter((value: any) => value !== selectedValue)
      setSelectBatch(updatedSelectBatch)
    } else {
      setSelectBatch([...selectBatch, selectedValue])
    }
  }

  function handleRemoveBatch(batch: any) {
    const newSelectBatch = selectBatch.filter((item: any) => item !== batch)
    setSelectBatch(newSelectBatch)
  }

  async function getClassTemplate(chapter_name: string, id: number) {
    const response = await getClassTemplateUseCase.invoke({
      id_token: auth.id_token,
      module: chapter_name,
      class_id: id,
    })
    setSelectAssigmentsQuestions([])
    setSelectHomeworkQuestions([])
    setSelectPracticalQuestions([])
    setselectWarmupQuestions([])
    setNotesLink("")
    setNotesSummaryLink("")
    setCurrentSelectAssignmentsQuestions([])
    if (response?.success) {
      setSelectAssigmentsQuestions(response?.data?.[0]?.assignments)
      setCurrentSelectAssignmentsQuestions(response?.data?.[0]?.assignments)
      setSelectHomeworkQuestions(response?.data?.[0]?.homework)
      setSelectPracticalQuestions(response?.data?.[0]?.practice)
      setselectWarmupQuestions(response?.data?.[0]?.warmup)
      setNotesLink(response?.data?.[0]?.notes_v4)
      setNotesSummaryLink(response?.data?.[0]?.notes_summary)
      setFirstReminderMessage(response?.data[0].first_reminder)
      setSecondReminderMessage(response?.data[0].second_reminder)
    }
  }

  function handleChangeChapterName(e: any) {
    const id = e.target.value
    const index = moduleClassesList.findIndex((i: any) => i.topic === e.target.value)

    if (id === "others") {
      setEnableOthersClass(true)
      setCurrentSelectAssignmentsQuestions([])
      setSelectHomeworkQuestions([])
      setSelectPracticalQuestions([])
      setselectWarmupQuestions([])
      setNotesLink("")
      setNotesSummaryLink("")
    } else setEnableOthersClass(false)
    setSelectClassName(e.target.value)
    getClassTemplate(selectModuleId, parseInt(index + 1))
    setChapterNames(e.target.value)
  }

  function handleChangeClassLink(e: any) {
    setClassLink(e.target.value)
  }

  function handleChangeInstructor(e: any) {
    setSelectInstructor(e.target.value)
  }

  function handleChangeModuleId(e: any) {
    setSelectModuleId(e.target.value)
    getModuleClasses(e.target.value, "")
  }

  function handleCompensationStatus(e: any) {
    setCompensationStatus(e.target.value)
  }

  function handleChangeSessionTimestamp(e: any) {
    setSessionTimestamp(e.target.value)
  }

  function handleSessionDuration(e: any) {
    setSessionDuration(e.target.value)
  }

  function handleMasterClassType(e: any) {
    setMasterClassType(e.target.value)
  }

  function handleOtherSessionDuration(e: any) {
    setOtherSessionDuration(e.target.value)
  }

  function handleNewDuration(duration: any, durationArray: any[]) {
    const durationNumber = parseFloat(duration)

    if (!isNaN(durationNumber) && durationArray.includes(durationNumber)) {
      setSessionDuration(durationNumber.toString())
    } else {
      setSessionDuration("other")
      setOtherSessionDuration(duration)
    }
  }

  async function handleChangeTrack(e: any) {
    setSelectTrack(e.target.value)
    if (e.target.value != "others") {
      await getModuleBasedTrack(e.target.value, 1)
    }
  }

  function handleChangeVideoId(e: any) {
    setVideoId(e.target.value)
  }

  function handleChangeVideoSecreat(e: any) {
    setVideoSecret(e.target.value)
  }

  function handleChangeNotesLink(e: any) {
    setNotesLink(e.target.value)
  }

  function handleChangeNotesSummaryLink(e: any) {
    setNotesSummaryLink(e.target.value)
  }
  function handleChangePreRequisite(e: any) {
    setPreRequisite(e.target.value)
  }
  function handleChangeAssignmentSolutionLink(e: any) {
    setAssignmentSolutionLink(e.target.value)
  }

  function handleChangeAssignmentSolutionActivationTime(e: any) {
    setAssignmentSolutionActivationTime(e.target.value)
  }

  function handleChangeAssignmentCoins(e: any) {
    setAssignmentCoins(e.target.value)
  }

  function removeAssignmentQuestion(index: number) {
    setSelectAssigmentsQuestions((prev: any[]) => prev.filter((_, i) => i !== index))
  }

  function removeHomeworkQuestion(index: number) {
    setSelectHomeworkQuestions((prev: any[]) => prev.filter((_, i) => i !== index))
  }

  function removePracticeQuestion(index: number) {
    setSelectPracticalQuestions((prev: any[]) => prev.filter((_, i) => i !== index))
  }

  function removeWarmupQestions(index: number) {
    setselectWarmupQuestions((prev: any[]) => prev.filter((_, i) => i !== index))
  }

  async function getModuleClasses(module: string, newChapterName: string) {
    const response = await getModuleClassesUseCase.invoke({
      id_token: auth.id_token,
      module: module,
    })

    try {
      if (response?.success) {
        setModuleClassesList(response?.data)
        if (newChapterName != "") {
          const isValuePresent = response?.data.some((classItem: any) => classItem.topic === newChapterName)

          if (!isValuePresent) {
            setEnableOthersClass(true)
            setOthersClassName(newChapterName)
            setSelectClassName("others")
            setChapterNames("others")
          }
        } else {
          setEnableOthersClass(false)
          setOthersClassName("")
          setSelectClassName("")
          setChapterNames("")
        }
        setDataMap(
          response?.data.reduce((acc: any, cur: any) => {
            acc[cur.id] = cur.topic
            return acc
          }, {})
        )
      }
    } catch (error) {}
  }

  async function getModuleBasedTrack(track: string, val: number) {
    const response = await getModuleBasedClassUseCase.invoke(auth, track)
    if (response?.success) {
      setModuleId([...[], ...response?.data.modules, "other"])
    } else {
      setModuleId([])
    }
    if (val != 0) {
      setSelectModuleId("")
      setSelectClassName("")
    }
  }

  return {
    status,
    reloadClass,
    selectBatch,
    currentSelectAssignmentsQuestions,
    currentSelectBatch,
    chapterNames,
    classLink,
    selectInstructor,
    selectModuleId,
    notes_link,
    selectAssigmentsQuestions,
    currentSelectHomeworkQuestions,
    selectHomeworkQuestions,
    selectPracticalQuestions,
    currentSelectPracticalQuestions,
    selectWarmupQuestions,
    currentSelectWarmupQuestions,
    session_timestamp,
    model,
    selectTrack,
    videoId,
    notesSummaryLink,
    videoSecret,
    loading,
    toast,
    compensationStatus,
    moduleId,
    TrackId,
    othersClassName,
    moduleClassesList,
    selectClassName,
    enableOthersClass,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    loading2,
    assignmentSolutionActivationTime,
    assignmentSolutionLink,
    assignmentCoins,
    handleStatusChange,
    setStatus,
    setReloadClass,
    setSelectBatch,
    setCurrentSelectAssignmentsQuestions,
    setcurrentSelectBatch,
    setChapterNames,
    setClassLink,
    setSelectInstructor,
    setSelectModuleId,
    setNotesLink,
    setSelectAssigmentsQuestions,
    setSelectHomeworkQuestions,
    setSelectPracticalQuestions,
    setCurrentSelectPracticalQuestions,
    setselectWarmupQuestions,
    setCurrentSelectWarmupQuestions,
    setModel,
    handleModalClose,
    setSessionTimestamp,
    setSelectTrack,
    setVideoId,
    setNotesSummaryLink,
    updateClassWithoutIcs,
    setVideoSecret,
    getAllTracks,
    setLoading2,
    setMasterClassType,
    handleMasterClassType,
    setSessionDuration,
    setOtherSessionDuration,
    handleSessionDuration,
    handleOtherSessionDuration,
    handleNewDuration,
    setCompensationStatus,
    setSelectClassName,
    getModuleClasses,
    preRequisite,
    handleChangePreRequisite,
    setPreRequisite,
    getModuleBasedTrack,
    handleOthersClass,
    handleCompensationStatus,
    handleRemoveBatch,
    setSessionId,
    setPreviousTimestamp,
    handleAssignmentChange,
    handleAddAssignmentQuestions,
    handleSelectHomeworkChange,
    handleAddHomeworkQuestions,
    handlePracticalChange,
    handleAddPracticalQuestions,
    handleWarmupChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeSessionTimestamp,
    handleAddWarmupQuestions,
    handleChangeTrack,
    handleChangeVideoId,
    handleChangeVideoSecreat,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    removeAssignmentQuestion,
    removeHomeworkQuestion,
    removePracticeQuestion,
    removeWarmupQestions,
    updateClass,
    changeToastVisibility,
    changeToastDetails,
    handleChangeAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    setAssignmentSolutionActivationTime,
    setAssignmentSolutionLink,
    setAssignmentCoins,
    handleChangeAssignmentCoins,
    firstReminderMessage,
    secondReminderMessage,
    setFirstReminderMessage,
    setSecondReminderMessage,
  }
}
