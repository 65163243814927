import { Auth } from "domain/model/Auth"
import {
  API_V3_ADMIN,
  API_V3_INFLUENCER,
  API_GET_ALL_PROFILES,
  API_UPDATE_CAMPAIGNS,
  API_UPLOAD_IMAGE,
  API_V3_UPDATE_CAMPAIGN_COMPENSATION,
  API_UPDATE_CAMPAIGN_INVOICE,
  API_GET_ALL_CAMPAIGNS,
} from "core/constants/strings"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import {
  InfluencersDetails,
  UpdateCampaignEntry,
  InfluencerGeneratedInvoice,
  CampaignType,
} from "domain/model/InfluencerDashboard"
import InfluencerDashboardDataSource from "data/dataSource/Admin/InfluencerDashboardDataSource"

const server = new Server()
export class InfluencerDashboardAPIDataSourceImpl implements InfluencerDashboardDataSource {
  async getAllInfluencersDetails(auth: Auth, details: InfluencersDetails): Promise<any> {
    try {
      const response = await server.get(pn(API_V3_ADMIN, API_V3_INFLUENCER, API_GET_ALL_PROFILES), {
        Authorization: `Bearer ${auth?.id_token}`,
      })
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async updateCampaignEntry(id: UpdateCampaignEntry, auth: Auth): Promise<any> {
    const response = await server.post(
      pn(API_V3_ADMIN, API_V3_INFLUENCER, API_UPDATE_CAMPAIGNS),
      {
        campaigns: id.processedArray,
      },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }
  async updateCompensationDetails(
    email: string,
    campaignType: string,
    compensation: number,
    date: string,
    auth: Auth
  ): Promise<any> {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, API_V3_INFLUENCER, API_V3_UPDATE_CAMPAIGN_COMPENSATION),
        {
          email,
          campaignId: campaignType,
          compensation,
          date,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }

  async uploadInfluencerImage(auth: Auth, data: FormData): Promise<any> {
    try {
      const response = await server.postFile(pn(API_V3_ADMIN, API_V3_INFLUENCER, API_UPLOAD_IMAGE), data, {
        Authorization: `Bearer ${auth?.id_token}`,
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getAllCampaignsDetails(auth: Auth, details: CampaignType): Promise<any> {
    try {
      const response = await server.get(pn(API_V3_ADMIN, API_V3_INFLUENCER, API_GET_ALL_CAMPAIGNS), {
        Authorization: `Bearer ${auth?.id_token}`,
      })
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getInfluencersGeneratedInvoice(auth: Auth, details: InfluencerGeneratedInvoice): Promise<any> {
    try {
      const response = await server.post(
        pn(API_V3_ADMIN, API_V3_INFLUENCER, API_UPDATE_CAMPAIGN_INVOICE),
        {
          email: details.email,
          campaigns: details.campaigns,
          date: details.date,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
