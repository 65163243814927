import { InstructorAvailabilityDataSource } from "data/dataSource/Admin/InstructorAvailabilityDataSource"
import { Auth } from "domain/model/Auth"

import { InstructorAvailabilityRepository } from "domain/repository/Admin/InstructorAvailabilityRespository"

export class InstructorAvailabilityRepositoryImpl implements InstructorAvailabilityRepository {
  private dataSource: InstructorAvailabilityDataSource

  constructor(dataSource: InstructorAvailabilityDataSource) {
    this.dataSource = dataSource
  }

  async getInstructorAvailability(auth: Auth) {
    return await this.dataSource.getInstructorAvailability(auth)
  }

  async toggleInstructorAvailability(auth: Auth, availability: boolean) {
    return await this.dataSource.toggleInstructorAvailability(auth, availability)
  }

  async getToggleInstructorAvailability(auth: Auth) {
    return await this.dataSource.getToggleInstructorAvailability(auth)
  }
}
