import React, { useState } from "react"
import { InfluencerDashboardAPIDataSourceImpl } from "data/API/Admin/InfluncerAPIDataSourceImpl"
import { InfluencerDashboardRepositoryImpl } from "data/repository/Admin/InfluencerDashboardRepositoryImpl"
import { UploadInfluencerImage } from "domain/useCase/Admin/InfluencerDashboard/UploadInfluencerImage"
import { useAuth } from "core/context/auth"
import { GetInfluencerProfile as GetProfile } from "domain/useCase/Common/InfluencerCommon/GetInfluencerProfile"
import { UpdateInfluencerProfile } from "domain/useCase/Common/InfluencerCommon/UpdateInfluencerProfile"
import { UpdateInfluencerInvoiceData } from "domain/useCase/Admin/InfluencerDashboard/UpdateCampaignEntry"
import { GetAllCompensation } from "domain/useCase/Common/InfluencerCommon/GetAllCompensation"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_INFLUENCER_INFO_SAVED, STR_SUCCESS } from "core/constants/strings"
import { GetCampaignsDetails } from "domain/useCase/Admin/InfluencerDashboard/GetallCampaignType"
import { UpdateCompensationDetails } from "domain/useCase/Admin/InfluencerDashboard/UpdateInfluencerCompensation"
import { InfluencerAPIDataSourceImpl } from "data/API/Common/InfluencerCommonAPIDataSourceImpl"
import { CommonInfluencerRepositoryImpl } from "data/repository/Common/CommonInfluencerRepositoryImpl"

export default function InfluencerProfilePageViewModel() {
  const [comments, setComments] = useState<any>([])
  const [Influencer, setInfluencer] = useState<any>({})
  const [loading, setLoading] = useState<Boolean>(true)
  const [availability, setAvailability] = useState<any>({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  })

  const [programTypeSuggestions, setprogramTypeSuggestions] = useState([
    "Accelerator",
    "Transformer",
    "Evolve",
    "Data Science",
    "Data Analytics",
    "Data Engineering",
  ])

  const [selected_programType, setselected_programType] = useState("")
  const [selected_campaignType, setselected_campaignType] = useState("")
  const [showDetails, setShowDetails] = useState<string>("")
  const [searchEmail, setSearchEmail] = useState<any>("")
  const [password, setPassword] = useState<any>("")
  const [comment, setComment] = useState<string>("")
  const [isAlumni, setIsAlumni] = useState<boolean>()
  const [CampaignData, setCampaignData] = React.useState<any>([])
  const [Rates, setAllRates] = React.useState<any>([])
  const [isInfluencer, setIsInfluencer] = useState<boolean>()
  const [courseType, setCourseType] = useState<string>("")
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const [textArea1, setTextArea1] = React.useState<string>("")
  const [programType, setprogramType] = React.useState<string[]>([])
  const [textArea2, setTextArea2] = React.useState<string>("")
  const [file, setFile] = React.useState<File | null>(null)
  const [campaignType, setcampaignType] = React.useState<string[]>([])
  const [processedArray, setProcessedArray] = useState<{ name: string; id: string }[]>([])
  const [socialMediaLinks, setSocialMediaLinks] = useState<{ hostname: string; link: string }[]>([])
  const [newLinks, setNewLinks] = useState<string[]>([])
  const [newLink, setNewLink] = useState<string>("")
  const [selected_campaign, setselected_campaign] = useState<string>("")
  const [Compensation, setUpdatedCompensation] = useState<number>(0)
  const [FormattedDate, setUpdatedDuration] = useState<string>("")
  const [formattedUpdatedDuration, setFormattedUpdatedDuration] = useState<string>("")

  const GetInfluencerProfileUseCase = new GetProfile(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )
  const UpdatetInfluencerProfile = new UpdateInfluencerProfile(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )
  const updateInfluencerCampaign = new UpdateInfluencerInvoiceData(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const GetAllRatesUseCase = new GetAllCompensation(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )
  const GetAllCampaignTypeDetailsUseCase = new GetCampaignsDetails(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const UploadInfluencerImageUseCase = new UploadInfluencerImage(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const UploadInfluencerCompensiationUseCase = new UpdateCompensationDetails(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )

  async function GetInfluencerProfile(id: any) {
    const response = await GetInfluencerProfileUseCase.invoke(
      {
        email: id,
      },
      auth
    )
    if (response) {
      setInfluencer(response.data)
      setcampaignType(response.data?.selected_campaign || [])
      setprogramType(response.data?.selected_programs || [])
      setSearchEmail(response.data.email)
      setPassword(response.data.password)
    }
    setLoading(false)
    return response
  }
  async function updateInfluencerProfile() {
    try {
      let updatedInfluencer = { ...Influencer }
      if (programType.length > 0) updatedInfluencer = { ...updatedInfluencer, selected_programs: programType }
      if (campaignType.length > 0) updatedInfluencer = { ...updatedInfluencer, selected_campaign: campaignType }
      const updatedhostname_and_links = mergeLinks(socialMediaLinks, changednewlinks)?.reduce(
        (acc: any, { hostname, link }) => {
          acc[hostname] = link
          return acc
        },
        {}
      )
      updatedInfluencer = { ...updatedInfluencer, hostname_and_links: updatedhostname_and_links }
      if (file) {
        const formdata = new FormData()
        formdata.append("img", file, "image.png")
        if (Influencer.photo) {
          formdata.append("path", Influencer.photo)
        }
        const uploadResponse = await UploadInfluencerImageUseCase.invoke(auth, formdata)

        if (!uploadResponse?.success) {
          changeToastDetails(STR_FAILURE, "Unknown error occurred!")
          changeToastVisibility(true)
          return
        }

        const urlResponse = uploadResponse.data
        updatedInfluencer = { ...Influencer, photo: urlResponse }
        setInfluencer(updatedInfluencer)
      }
      const updateProfileResponse = await UpdatetInfluencerProfile.invoke(
        {
          influencer: updatedInfluencer,
        },
        auth
      )
      if (processedArray && processedArray.length > 0) {
        const influencerCampaignResponse = await updateInfluencerCampaign.invoke(
          {
            processedArray,
          },
          auth
        )
      }
      if (updateProfileResponse.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, STR_INFLUENCER_INFO_SAVED)
      } else {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, updateProfileResponse.error)
      }
    } catch (error) {
      console.error("Error updating influencer profile:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error updating influencer profile")
    }
  }
  async function getCampaignType() {
    const campaign = await GetAllCampaignTypeDetailsUseCase.invoke(auth, {
      Campaign: auth.local_id,
    })
    if (campaign) {
      setCampaignData(campaign.data)
    }
  }

  async function getAllRates(id: any) {
    const Rates = await GetAllRatesUseCase.invoke(auth, {
      email: id,
    })
    if (Rates) {
      setAllRates(Rates)
    }
  }
  const onChangeInfluencerObj = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInfluencerObj = { ...Influencer }
    newInfluencerObj[e.target.id] = e.target.value
    setInfluencer(newInfluencerObj)
  }
  const handleInfluencerChange = (e: any) => {
    const newInfluencerObj = { ...Influencer }
    e.target.value === "True" ? (newInfluencerObj[e.target.id] = true) : (newInfluencerObj[e.target.id] = false)
    setInfluencer(newInfluencerObj)
    setIsInfluencer(e.target.value === "True" ? true : false)
  }
  const handleShowDetails = (showDetailsOf: string) => {
    showDetails === showDetailsOf ? setShowDetails("") : setShowDetails(showDetailsOf)
  }
  const handleProfilePictureUpload = (e: any) => {
    const cfile = e.target.files ? e.target.files[0] : null
    if (cfile) {
      setFile(cfile)
    }
  }
  const handleProgramTypeInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextArea1(e.target.value)
  }

  const handleCampaignTypeInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextArea2(e.target.value)
  }
  const handleAddCampaignType = () => {
    if (selected_campaignType !== "Other") {
      if (!campaignType.includes(selected_campaignType) && selected_campaignType != "") {
        processArray([...campaignType, selected_campaignType])
        setcampaignType([...campaignType, selected_campaignType])
      }
    } else if (textArea2.trim() !== "") {
      if (!campaignType.includes(textArea2)) {
        processArray([...campaignType, textArea2])
        setcampaignType([...campaignType, textArea2])
      }
    }
    setTextArea2("")
    setselected_campaignType("")
  }
  async function updateCampaignDetails() {
    try {
      if (!selected_campaign || !Compensation || !formattedUpdatedDuration) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Please fill out all fields before updating.")
        return
      }
      const updateCampaignResponse = await UploadInfluencerCompensiationUseCase.invoke(
        Influencer.email,
        generateCID(selected_campaign),
        Compensation,
        formattedUpdatedDuration,
        auth
      )

      if (updateCampaignResponse.success) {
        changeToastVisibility(true)

        changeToastDetails(STR_SUCCESS, STR_INFLUENCER_INFO_SAVED)
      } else {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Error updating campaign details:")
      }
    } catch (error) {
      console.error("Error updating campaign details:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error updating campaign details.")
    }
  }
  const generateCID = (name: string): string => {
    name = name.toUpperCase()
    const idArray = name.split(" ")
    const id = idArray.join("_")
    return id
  }
  const processArray = (array: string[]) => {
    const newArray = array.map((value) => ({
      name: value,
      id: generateCID(value),
    }))
    setProcessedArray(newArray)
  }
  const onInputChange = (e: any) => {
    setUpdatedDuration(e.target.value)
    const value = e.target.value
    const [year, month, day] = value.split("-")
    const formattedDate = `${day}-${month}-${year}`
    setFormattedUpdatedDuration(formattedDate)
  }

  const processAndSortCampaignData = (Rates: any) => {
    const sortedCampaignData: { campaign: string; dates: { date: string; value: string }[] }[] = []

    if (!Rates || !Rates.data) {
      console.error("Invalid data format")
      return sortedCampaignData
    }

    const campaigns = Rates.data
    Object.keys(campaigns).forEach((campaign) => {
      const campaignDetails = campaigns[campaign]

      if (campaignDetails) {
        const sortedDates = Object.keys(campaignDetails)
          .sort((a, b) => {
            const [dayA, monthA, yearA] = a.split("-").map(Number)
            const [dayB, monthB, yearB] = b.split("-").map(Number)
            const dateA: Date = new Date(yearA, monthA - 1, dayA)
            const dateB: Date = new Date(yearB, monthB - 1, dayB)
            return dateA.getTime() - dateB.getTime()
          })
          .map((date) => ({
            date,
            value: campaignDetails[date],
          }))

        sortedCampaignData.push({
          campaign: campaign.trim(),
          dates: sortedDates,
        })
      }
    })

    return sortedCampaignData
  }

  function extractHostsnames(links: string[]): { host: string; link: string }[] {
    return links.map((link) => {
      try {
        const url = new URL(link)
        const hostnameParts = url.hostname.split(".")
        const mainDomain = hostnameParts.length > 2 ? hostnameParts[hostnameParts.length - 2] : hostnameParts[0]
        return { host: mainDomain, link }
      } catch (error) {
        console.error(`Invalid URL: ${link}`)
        return { host: "Invalid URL", link }
      }
    })
  }
  const changednewlinks = extractHostsnames(newLinks)
  const mergeLinks = (
    socialMediaLinks: { hostname: string; link: string }[],
    newLinks: { host: string; link: string }[]
  ): { hostname: string; link: string }[] => {
    const merged = [...socialMediaLinks]
    newLinks.forEach(({ host, link }) => {
      const existingLink = merged.find((item) => item.hostname === host)
      if (existingLink) {
        existingLink.link = link
      } else {
        merged.push({ hostname: host, link })
      }
    })
    return merged
  }
  return {
    CampaignData,
    selected_campaign,
    socialMediaLinks,
    Compensation,
    Rates,
    formattedUpdatedDuration,
    toast,
    comments,
    comment,
    Influencer,
    availability,
    isAlumni,
    isInfluencer,
    programType,
    campaignType,
    courseType,
    programTypeSuggestions,
    showDetails,
    loading,
    textArea1,
    textArea2,
    newLink,
    FormattedDate,
    selected_programType,
    newLinks,
    selected_campaignType,
    setselected_programType,
    setselected_campaignType,
    handleProgramTypeInputChange,
    handleCampaignTypeInputChange,
    onChangeInfluencerObj,
    setNewLink,
    handleAddCampaignType,
    processAndSortCampaignData,
    setUpdatedCompensation,
    setUpdatedDuration,
    updateCampaignDetails,
    onInputChange,
    getAllRates,
    setSocialMediaLinks,
    setNewLinks,
    generateCID,
    getCampaignType,
    setselected_campaign,
    updateInfluencerProfile,
    changeToastDetails,
    changeToastVisibility,
    handleInfluencerChange,
    handleProfilePictureUpload,
    GetInfluencerProfile,
    handleShowDetails,
    setTextArea1,
    setTextArea2,
    setprogramType,
    setcampaignType,
  }
}
