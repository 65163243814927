import { ShortRepository } from "domain/repository/Student/ShortRepository"
import ShortDataSource from "data/dataSource/Student/ShortDataSource"
import { Auth } from "domain/model/Auth"

export class ShortRepositoryImpl implements ShortRepository {
  private dataSource: ShortDataSource

  constructor(dataSource: ShortDataSource) {
    this.dataSource = dataSource
  }

  async saveShort(
    auth: Auth,
    sessionID: string,
    chapterName: string,
    shortTitle: string,
    isSaved: boolean
  ): Promise<any> {
    return this.dataSource.saveShort(auth, sessionID, chapterName, shortTitle, isSaved)
  }

  async readShort(auth: Auth, sessionID: string, chapterName: string, shortTitle: string): Promise<any> {
    return this.dataSource.readShort(auth, sessionID, chapterName, shortTitle)
  }
}
