import useToast from "core/hooks/useToast"
import React, { useState } from "react"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import { UpdateInfluencerProfile } from "domain/useCase/Common/InfluencerCommon/UpdateInfluencerProfile"
import { CommonInfluencerRepositoryImpl } from "data/repository/Common/CommonInfluencerRepositoryImpl"
import { InfluencerAPIDataSourceImpl } from "data/API/Common/InfluencerCommonAPIDataSourceImpl"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function HomeViewModel() {
  const { influencer } = useApp()
  const [Influencer, setInfluencer] = useState<any>(influencer)
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const { auth } = useAuth()

  const UpdatetInfluencerProfile = new UpdateInfluencerProfile(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )

  async function updateInfluencerProfile() {
    try {
      const updateProfileResponse = await UpdatetInfluencerProfile.invoke(
        {
          influencer: Influencer,
        },
        auth
      )

      if (updateProfileResponse.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Influencer info saved successfully")
      } else {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, updateProfileResponse.error)
      }
    } catch (error) {
      console.error("Error updating influencer profile:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error updating influencer profile")
    }
  }

  return {
    toast,
    influencer,
    Influencer,
    setInfluencer,
    changeToastDetails,
    changeToastVisibility,
    updateInfluencerProfile,
  }
}
