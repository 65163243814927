import { Auth } from "domain/model/Auth"
import { UpdateStudentExpierenceDetails } from "domain/model/UpdateStudentExpierence"
import { MentorRepository } from "domain/repository/Student/MentorRepository"
import StudentExpirenceRepository from "domain/repository/Student/StudentExpierenceRepository"

export interface UpdateStudentExpirenceUseCase {
  invoke(auth: Auth, details: UpdateStudentExpierenceDetails): Promise<any>
}

export class UpdateStudentExpierence implements UpdateStudentExpirenceUseCase {
  private repository: StudentExpirenceRepository

  constructor(repository: StudentExpirenceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: UpdateStudentExpierenceDetails): Promise<any> {
    return await this.repository.updateStudentExpirence(auth, details)
  }
}
