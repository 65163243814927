import { Auth } from "domain/model/Auth"

import { BatchRepository } from "domain/repository/Admin/BatchRepository"

export interface GetAllExamTemplatesUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetAllExamTemplates implements GetAllExamTemplatesUseCase {
  private repository: BatchRepository

  constructor(repository: BatchRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getAllExamTemplates(auth)
  }
}
