import { Auth } from "domain/model/Auth"
import MentorNotificationsRepository from "domain/repository/Student/MentorNotificationsRepository"
import { CreateMentorNotificationsDetails } from "domain/model/MentorNotifications"
import { MentorNotificationsDataSource } from "data/dataSource/Student/MentorNotificationsDataSource"

export class MentorNotificationsRepositoryImpl implements MentorNotificationsRepository {
  private dataSource: MentorNotificationsDataSource

  constructor(dataSource: MentorNotificationsDataSource) {
    this.dataSource = dataSource
  }

  async createMentorNotifications(auth: Auth, details: CreateMentorNotificationsDetails): Promise<Auth> {
    return this.dataSource.createMentorNotifications(auth, details)
  }
}
