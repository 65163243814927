import React, { useEffect } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import Spinner from "core/components/Spinner"
import { cn } from "core/lib/utils"
import Button from "core/components/new/Button"
import { Link, useNavigate, useParams } from "react-router-dom"
import { GetSessionDetails } from "domain/useCase/Student/Course/GetSessionDetails"
import { ReadShort } from "domain/useCase/Student/MicroResource/ReadShort"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { ToggleLeetCodeProblemComplete } from "domain/useCase/Student/Course/ToggleLeetCodeProblemComplete"
import { GetFeedbackDetails } from "domain/useCase/Student/Course/GetFeedbackDetails"
import { SubmitFeedback } from "domain/useCase/Student/Course/SubmitFeedback"
import { SubmitRecordingFeedback } from "domain/useCase/Student/Course/SubmitFeedback"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { CheckBlankIcon, CheckSuccessIcon } from "core/constants/svgs"
import { isExternalProblem } from "core/utils/others"
import { capitalize, genError } from "core/utils/string"
import { useAuth } from "core/context/auth"
import { TTableData } from "core/constants/types"
import { TogglePlatformProblemStatus } from "domain/useCase/Student/Course/TogglePlatformProblemStatus"
import { GetSSMFeedbackResponse } from "domain/useCase/Student/Course/GetSSMFeedbackResponse"
import TableButton from "core/components/v2/TableButton"
import { Tooltip } from "core/components/v2/Tooltip"
import GetAIFeedback from "domain/useCase/Student/Assignment/GetAIFeedback"
import { SubmissionRepositoryImpl } from "data/repository/Student/SubmissionRepositoryImpl"
import SubmissionAPIDataSourceImpl from "data/API/Student/SubmissionAPIDataSourceImpl"
import StudentAIFeedback from "domain/useCase/Student/Assignment/StudentAIFeedback"
import { SubmitClassFeedbackNew } from "domain/useCase/Student/Course/SubmitClassFeedbackNew"
import { GetClassFeedbackNew } from "domain/useCase/Student/Course/GetClassFeedbackNew"
import { GetDSMLBEGINNERMODULESESSION } from "domain/useCase/Student/Course/GetDsmlBeginnerModuleSessions"
import { useApp } from "core/context/app"
import { GetCourses } from "domain/useCase/Student/Course/GetCourses"
import { GetMasterClass } from "domain/useCase/Student/Course/GetMasterClass"
import { GetAttendanceAndAssignments } from "domain/useCase/Student/Course/GetAttendanceAndAssignments"
import { isEmpty } from "core/utils/misc"
import { ToggleRecordedComplete } from "domain/useCase/Student/Course/ToggleRecordedComplete"
import { ChatbotAPIDataSourceImpl } from "data/API/Student/ChatbotAPIDataSourceImpl"
import { ChatbotRepositoryImpl } from "data/repository/Student/ChatbotRepositoryImpl"
import { Chatbot } from "domain/useCase/Student/Chatbot/Chatbot"
import { SaveShort } from "domain/useCase/Student/MicroResource/SaveShort"
import { ShortRepositoryImpl } from "data/repository/Student/ShortRepositoryImpl"
import { ShortAPIDataSourceImpl } from "data/API/Student/ShortAPIDataSourceImpl"

export default function CourseSessionViewModel() {
  const { auth } = useAuth()
  const { student } = useApp()
  const { session_id }: any = useParams()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const navigate = useNavigate()
  const [courseList, setCourseList] = useLocalStorage<any[]>("course", [])

  const [sessionList, setSessionList] = useLocalStorage<any>("session", {})
  const [assignmentsTableContent, setAssignmentsTableContent] = React.useState<TTableData>({} as TTableData)
  const [warmupTableContent, setWarmupTableContent] = React.useState<TTableData>({} as TTableData)
  const [homeworkTableContent, setHomeworkTableContent] = React.useState<TTableData>({} as TTableData)
  const [practiceTableContent, setPracticeTableContent] = React.useState<TTableData>({} as TTableData)
  const [sessionDetails, setSessionDetails] = React.useState<any>(() => sessionList[session_id])
  const [isChatbotOpen, setIsChatbotOpen] = React.useState<any>(false)
  const [courseIdsLocally] = useLocalStorage<any>("course-ids", {})
  const [coursesLocally] = useLocalStorage<any>("course", {})
  const [session, setSession] = React.useState<any>(null)
  const [courseLectures, setCourseLectures] = React.useState<any>(null)
  const [activeIndex, setActiveIndex] = React.useState<any>(-1)
  const [sessionId, setSessionId] = React.useState<any>(() => session_id)
  const [loadingTable, setLoadingTable] = React.useState<any>({
    index: -1,
    type: "",
  })
  const [isClassFeedbackGiven, setIsClassFeedbackGiven] = React.useState(false)
  const isAccelerator = student?.batch?.includes("Accelerator") && !student?.batch?.includes("KI-Accelerator")

  const [chatbotResponse, setChatbotResponse] = React.useState<string>("")
  const [courseName, setCourseName] = React.useState<any>("")
  const [loading, setLoading] = React.useState<any>(false)
  const [coursesWithIds, setCoursesWithIds] = useLocalStorage<any>("course-ids", {})
  const [feedbackData, setFeedbackData] = React.useState<any>(null)
  const [isFeedbackVisible, setIsFeedbackVisible] = React.useState<boolean>(false)
  const [isRecordedFeedbackVisible, setIsRecordedFeedbackVisible] = React.useState<boolean>(false)
  const [coinsPerProblem, setCoinsPerProblem] = React.useState<number>(0)
  const [isViewSubmissionVisible, setIsViewSubmissionVisible] = React.useState<boolean>(false)
  const [feedbackResponseData, setFeedbackResponseData] = React.useState<any>({})
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [questionName, setQuestionName] = React.useState<string>("")
  const [questionType, setQuestionType] = React.useState<string>("")
  const [selectedQuestion, setSelectedQuestion] = React.useState(0)
  const [chatShort, setChatShort] = React.useState<string>("")
  const [tab, setTab] = React.useState(0)
  const [activeButtonType, setActiveButtonType] = React.useState(0)
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState(true)
  const [isViewSubjectiveSubmissionVisible, setIsViewSubjectiveSubmissionVisible] = React.useState<boolean>(false)
  const [isStudentFeedbackVisible, setIsStudentFeedbackVisible] = React.useState<boolean>(false)
  const [subjectivefeedbackResponseData, setSubjectiveFeedbackResponseData] = React.useState<any>({})
  const [classFeedback, setClassFeedback] = React.useState<any>({})
  const [courseSelectedIndex, setCourseSelectedIndex] = React.useState<number>(0)
  const [isCourseFetched, setIsCourseFetched] = React.useState<boolean>(false)
  const [isAssignmentsFetched, setIsAssignmentsFetched] = React.useState<boolean>(false)
  const [currentCourse, setCurrentCourse] = React.useState<any>(() =>
    isEmpty(courseList) ? courseList[courseSelectedIndex] : null
  )

  const initialTabs = ["Recorded Video", "Assignments", "Pre-Requisite", "Notes", "Shorts"]
  const [TABS, setTABS] = React.useState(initialTabs)

  const AssignmentTabs = [
    warmupTableContent?.content?.length && { value: "Warmup", key: 0 },
    assignmentsTableContent?.content?.length && { value: "Assignment", key: 1 },
    homeworkTableContent?.content?.length && { value: "Homework", key: 2 },
    practiceTableContent?.content?.length && { value: "Practice", key: 3 },
  ].filter(Boolean) as { value: string; key: number }[]
  const [assignmenTab, setAssignmenTab] = React.useState<number>(0)

  useEffect(() => {
    setAssignmenTab(AssignmentTabs[0]?.key ?? 0)
  }, [AssignmentTabs[0]?.key])

  const order = {
    rating: "Assignment Rating",
    marks: "Assignment Marks",
    feedback_response: "SSM Feedback",
    code_snippet: "Code Snippet",
    files: "Refrences",
  }

  const chatbotUseCase = new Chatbot(new ChatbotRepositoryImpl(new ChatbotAPIDataSourceImpl()))
  const saveShortUseCase = new SaveShort(new ShortRepositoryImpl(new ShortAPIDataSourceImpl()))
  const readShortUseCase = new ReadShort(new ShortRepositoryImpl(new ShortAPIDataSourceImpl()))

  const getSessionDetailsUseCase = new GetSessionDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const toggleLeetCodeProblemCompleteUseCase = new ToggleLeetCodeProblemComplete(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const togglePlatformProblemStatusUseCase = new TogglePlatformProblemStatus(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const getFeedbackDetailsUseCase = new GetFeedbackDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const submitFeedbackUseCase = new SubmitFeedback(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const submitRecordingFeedbackUseCase = new SubmitRecordingFeedback(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getSSMFeedbackResponseUseCase = new GetSSMFeedbackResponse(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const getAIFeedbackUseCase = new GetAIFeedback(new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl()))

  const studentAIFeedbackUseCase = new StudentAIFeedback(
    new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl())
  )

  const submitClassFeedbackNewUseCase = new SubmitClassFeedbackNew(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getCoursesUseCase = new GetCourses(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const getClassFeedbackNewUseCase = new GetClassFeedbackNew(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))

  const getDsmlBeginnerModuleSessionsUseCase = new GetDSMLBEGINNERMODULESESSION(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const toggleRecordedCompleteStatusUseCase = new ToggleRecordedComplete(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getMasterClassUseCase = new GetMasterClass(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getAttendanceAndAssignmentsUseCase = new GetAttendanceAndAssignments(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const fetchAttendanceAndAssignments = async () => {
    setIsAssignmentsFetched(false)

    const response = await getAttendanceAndAssignmentsUseCase.invoke(auth)

    setIsAssignmentsFetched(true)

    if (!response?.success) {
      changeToastDetails(
        STR_FAILURE,
        genError(response, genError(response, "Failed to fetch attendance and assignments"))
      )
      changeToastVisibility(true)
      return
    }

    const moduleKeys = Object.keys(response?.data)
    const newCourseList: any[] = courseList

    // mapping of course response
    moduleKeys?.forEach((key: any) => {
      if (key === "assignmentSolved") {
        return
      }
      const moduleIndex = newCourseList.findIndex((item: any) => item?.id === key)
      const recordedLectures = newCourseList[moduleIndex]?.recorded_lectures
      const upcomingLectures = newCourseList[moduleIndex]?.upcoming_lectures

      const moduleData = response?.data[key]
      moduleData?.forEach((data: any) => {
        let index = -1
        if (!isEmpty(recordedLectures)) {
          index = recordedLectures.findIndex((item: any) => item?.session_id === data?.session_id)
          if (index !== -1) recordedLectures[index] = { ...data, ...recordedLectures[index] }
        }
        if (!isEmpty(upcomingLectures)) {
          index = upcomingLectures.findIndex((item: any) => item?.session_id === data?.session_id)
          if (index !== -1) upcomingLectures[index] = { ...data, ...upcomingLectures[index] }
        }
      })

      // updating course list
      newCourseList[moduleIndex] = {
        ...newCourseList[moduleIndex],
        recorded_lectures: recordedLectures,
        upcoming_lectures: upcomingLectures,
        assignment_progress: {
          completed: response?.data[key]?.reduce((total: number, item: any) => total + item?.num_assignment_solved, 0),
          total: response?.data[key]?.reduce((total: number, item: any) => total + item?.num_total_assignments, 0),
        },
      }
    })

    setCourseList([...newCourseList])
  }

  async function toggleRecordedComplete(session_id: string, status: boolean) {
    const response = await toggleRecordedCompleteStatusUseCase.invoke(auth, session_id, status)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Failed to update status")
      changeToastVisibility(true)
      return
    }

    const updatedCourseList = courseList.map((course: any) => ({
      ...course,
      recorded_lectures: course.recorded_lectures.map((lecture: any) =>
        lecture.session_id === session_id ? { ...lecture, is_completed: status } : lecture
      ),
    }))

    setCourseList([...updatedCourseList])

    setCurrentCourse(updatedCourseList[courseSelectedIndex])
  }
  const fetchDsmlBeginnerModuleSessions = async () => {
    const response = await getDsmlBeginnerModuleSessionsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch master class"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any[] = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    // mapping of course response
    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
        },
      }
    })

    const newCourseList: any[] = courseList
    const tempCourseList: any[] = []
    let newCoursesWithIds: any = coursesWithIds
    const courseKeys: any[] = Object.keys(modifiedResponse)
    // custom sorting of courses
    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "dsml_beginner" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    // merging of courses
    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }
    if (student?.tracks?.includes("master_class") && !newCoursesWithIds.hasOwnProperty("Master Class")) {
      newCoursesWithIds["Master Class"] = "master_class"
    }

    setCoursesWithIds(newCoursesWithIds)
    setCourseList([...newCourseList])
  }
  const handleSubjectiveFeedbackBtnClick = (questionName: string, questionType: string) => {
    setIsViewSubjectiveSubmissionVisible(true)
    setQuestionName(questionName)
    setQuestionType(questionType)
  }
  const handleOnCloseBtnSubmitModalClick = () => {
    setIsViewSubjectiveSubmissionVisible(false)
  }
  const defaultindex = () => {
    let latest_timestamp = 1e18
    let ind = -1
    for (let i = 0; i < courseList.length; i++) {
      if (
        courseList[i]["name"] !== "Master Class" &&
        courseList[i]["name"] != "DSML Beginner" &&
        courseList[i]["upcoming_lectures"].length > 0 &&
        courseList[i]["upcoming_lectures"][0]["timestamp"] < latest_timestamp
      ) {
        ind = i
        latest_timestamp = courseList[i]["upcoming_lectures"][0]["timestamp"]
      }
    }
    if (ind === -1) {
      latest_timestamp = 0
      for (let i = 0; i < courseList.length; i++) {
        if (
          courseList[i]["name"] !== "Master Class" &&
          courseList[i]["name"] != "DSML Beginner" &&
          courseList[i]["recorded_lectures"].length > 0 &&
          latest_timestamp < courseList[i]["recorded_lectures"][0]["timestamp"]
        ) {
          ind = i
          latest_timestamp = courseList[i]["recorded_lectures"][0]["timestamp"]
        }
      }
    }

    setCourseSelectedIndex(ind === -1 ? 0 : ind)
  }
  const fetchMasterClass = async () => {
    const response = await getMasterClassUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch master class"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any[] = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    // mapping of course response
    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
        },
      }
    })

    const newCourseList: any[] = courseList
    const tempCourseList: any[] = []
    let newCoursesWithIds: any = coursesWithIds
    const courseKeys: any[] = Object.keys(modifiedResponse)

    // custom sorting of courses
    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "master_class" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    // merging of courses
    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }
    if (
      student?.batch?.includes("DSML") &&
      student?.tracks?.includes("dsml_beginner") &&
      !newCoursesWithIds.hasOwnProperty("DSML Beginner")
    ) {
      newCoursesWithIds["DSML Beginner"] = "dsml_beginner"
    }
    setCoursesWithIds(newCoursesWithIds)
    setCourseList([...newCourseList])
  }
  async function fetchCourse() {
    defaultindex()

    const response = await getCoursesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch courses"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any = []
    const finalResponse = response?.data

    console.log("final res", finalResponse)
    console.log("course list", courseList)
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    // mapping of course response
    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
          upcoming_lectures: resData[key]?.upcoming_lectures,
          module_progress: {
            completed: resData[key]?.completed_chapters,
            total: resData[key]?.total_chapters,
          },
          assignment_progress: {
            completed: resData[key]?.solved_module_assignments,
            total: resData[key]?.total_module_assignments,
          },
        },
      }
    })

    const newCourseList = courseList
    const tempCourseList = []
    let newCoursesWithIds = {}
    const courseKeys = Object.keys(modifiedResponse)

    // custom sorting of courses
    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "master_class" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    // merging of courses
    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }

    defaultindex()
    if (isAccelerator) {
      setActiveButtonType(1)
    }
    setCoursesWithIds(newCoursesWithIds)
    setCourseList([...newCourseList])

    setIsCourseFetched(true)
  }
  const rateAIModal = () => {
    setIsStudentFeedbackVisible(true)
    setIsViewSubjectiveSubmissionVisible(false)
  }

  const fetchViewSubjectiveSubmission = async (questionName: any) => {
    setIsLoading(true)
    setSubjectiveFeedbackResponseData({})

    try {
      const response = await getAIFeedbackUseCase.invoke(auth, {
        questionId: questionName,
      })
      if (!response?.success || !response.data || response.data.length === 0) {
        changeToastDetails(STR_FAILURE, "No Feedback Response Found")
        changeToastVisibility(true)
        setIsViewSubjectiveSubmissionVisible(false)
        setIsLoading(false)
        return
      }

      setSubjectiveFeedbackResponseData(response.data)
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Error fetching feedback response")
      changeToastVisibility(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnCloseFeedbackBtnClick = () => {
    setIsStudentFeedbackVisible(false)
  }

  const submitStudentFeedback = async (
    questionId: string,
    student_rating: string,
    student_feedback: string,
    expected_marks: string
  ) => {
    try {
      const response = await studentAIFeedbackUseCase.invoke(auth, {
        questionId,
        student_rating,
        student_feedback,
        expected_marks,
      })
      if (!response?.success || !response.data || response.data.length === 0) {
        changeToastDetails(STR_FAILURE, "Error submitting student response")
        changeToastVisibility(true)
        setIsStudentFeedbackVisible(false)
        setIsLoading(false)
        return
      }
      changeToastDetails(STR_SUCCESS, "Student feedback submitted successfully")
      changeToastVisibility(true)
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Error submitting student response")
      changeToastVisibility(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnCloseBtnModalClick = () => {
    setIsViewSubmissionVisible(false)
  }

  const handleFeedbackBtnClick = (questionName: string, questionType: string) => {
    setIsViewSubmissionVisible(true)
    setQuestionName(questionName)
    setQuestionType(questionType)
  }

  const handleChatClick = (shortDetails: string) => {
    setIsChatbotOpen(true)
    setChatShort(shortDetails)
  }
  const closeChatbot = () => {
    setIsChatbotOpen(false)
    setChatbotResponse("")
  }
  const handleReadShort = async (sessionID: string, chapterName: string, shortTitle: string) => {
    const response = await readShortUseCase.invoke(auth, sessionID, chapterName, shortTitle)
  }

  const handleSaveClick = async (sessionID: string, chapterName: string, shortTitle: string, save: boolean) => {
    const updatedSessionDetails = {
      ...sessionDetails,
      shorts: sessionDetails.shorts.map((short: any) => {
        if (short.title === shortTitle) {
          return {
            ...short,
            is_saved: save,
          }
          return short
        }
      }),
    }

    setSessionDetails(updatedSessionDetails)

    setSessionList({
      ...sessionList,
      [session_id]: updatedSessionDetails,
    })

    const response = await saveShortUseCase.invoke(auth, sessionID, chapterName, shortTitle, save)
    if (response == true) {
      changeToastDetails(STR_SUCCESS, "Short read status updated successfully")
      changeToastVisibility(true)
      return
    } else if (response == undefined) {
      changeToastDetails(STR_FAILURE, "Failed to mark short read status")
      changeToastVisibility(true)
      return
    }
  }
  const fetchViewSubmission = async (q: string, qType: string) => {
    setIsLoading(true)

    setFeedbackResponseData({})

    try {
      const response = await getSSMFeedbackResponseUseCase.invoke(auth, session_id, questionName, qType)

      if (!response?.success || !response.data || response.data.length === 0) {
        changeToastDetails(STR_FAILURE, "No Feedback Response Found")
        changeToastVisibility(true)
        setIsViewSubmissionVisible(false)
        setIsLoading(false)
        return
      }

      setFeedbackResponseData(response.data)
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Error fetching feedback response")
      changeToastVisibility(true)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchChatbotResponse = async (prompt: string) => {
    setLoading(true)
    const response = await chatbotUseCase.invoke(auth, prompt)

    setChatbotResponse(response)
    setLoading(false)
  }

  const fetchSessionDetails = async () => {
    setLoading(true)
    const data: any = await getSessionDetailsUseCase.invoke(auth, sessionId)
    setLoading(false)
    if ("assignment_points" in data) {
      let externalProblemCount = 0
      data["assignments"]?.forEach((assignment: any) => {
        !assignment["link"].includes("leetcode") && !assignment["link"].includes("bosscoder") && externalProblemCount++
      })
      setCoinsPerProblem(Math.floor(data["assignment_points"] / externalProblemCount))
    }

    setSessionDetails(data)
    setIsFeedbackVisible(
      data?.module_name !== "master_class" &&
        !data?.is_feedback_submitted &&
        data?.is_attended &&
        Date.now() - data?.timestamp < 3 * 24 * 60 * 60 * 1000
    )

    const sessions = { ...sessionList, [sessionId]: data }
    setSessionList(sessions)
  }

  function fetchCourseLectures() {
    const courseIndex = Object.values(courseIdsLocally).findIndex(
      (courseName: any) => courseName === sessionList?.[sessionId]?.module_name
    )
    const courseName = Object.keys(courseIdsLocally)?.[courseIndex]
    const courseLectures =
      Object.keys(coursesLocally || {}).length === 0
        ? courseList
        : coursesLocally?.find((course: any) => course?.name === courseName)?.recorded_lectures

    const newCourseLectures = [...courseLectures]

    setCourseName(courseName)
    setCourseLectures(newCourseLectures)
  }

  function handleSidebarData(sessions = sessionList) {
    setSession(sessions[sessionId])

    const activeIndex = courseLectures?.findIndex((lecture: any) => lecture?.session_id === sessionId)
    setActiveIndex(activeIndex)
  }

  function onLectureClick(lecture: any, index: number) {
    if (index === activeIndex) return
    setActiveIndex(index)
    setSession(lecture)
    setSessionId(lecture?.session_id)
    navigate(`/course/${lecture?.session_id}`)
  }

  async function handleTableClick(type: string, i: number, problem: any) {
    setLoadingTable({ index: i, type: type })

    const prob_name = problem?.name?.split(" ")[0]

    await toggleLeetCodeProblemComplete(
      prob_name === "Assignment" || prob_name === "Homework" || prob_name === "Practice" || prob_name === "Warmup"
        ? problem.link
        : problem.problem_id,
      !problem?.status,
      !problem["link"].includes("leetcode") && !problem["link"].includes("bosscoder") ? coinsPerProblem : 0
    )

    setLoadingTable({ index: -1, type: "" })
  }

  async function handleInternalProblem(type: string, i: number, problem: any) {
    setLoadingTable({ index: i, type: type })

    let prob_name = problem.name.split(" ")[0]

    await togglePlatformProblemStatus(
      prob_name === "Assignment" || prob_name === "Homework" || prob_name === "Practice" || prob_name === "Warmup"
        ? problem.link
        : problem.problem_id,
      !problem?.solved_on_leetcode
    )

    setLoadingTable({ index: -1, type: "" })
  }

  function assignRows(data: any, type: string) {
    return data?.map((problem: any, i: number) => {
      const isExternalProblemLink = isExternalProblem(problem?.link)

      const lastRoute = problem?.link?.split("/")?.at(-1)
      const secondLastRoute = problem?.link?.split("/")?.at(-2)

      const problemType = secondLastRoute === "codeEditor" ? "editor" : secondLastRoute
      const problemLink = isExternalProblemLink ? problem?.link : `/${problemType}/${lastRoute}`

      return [
        <button
          className="flex h-6 w-6 items-center"
          onClick={() =>
            !isExternalProblemLink ? handleInternalProblem(type, i, problem) : handleTableClick(type, i, problem)
          }
          disabled={loadingTable.index === i || (!isExternalProblemLink && problem?.status)}
        >
          {loadingTable.index === i && type === loadingTable.type ? (
            <Spinner small />
          ) : isExternalProblemLink && problem?.status ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#FBBF24]" />
          ) : problem?.status ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : problem?.solved_on_leetcode ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#FBBF24]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span title={problem?.name} className="block w-full max-w-[130px] truncate">
          {problem?.name}
        </span>,
        <span
          className={cn(
            "block w-full max-w-[100px] truncate",
            (isExternalProblemLink && problem?.status) ||
              (problem?.solved_on_leetcode && typeof problem?.status === "boolean" && problem?.status == false)
              ? "text-[#FBBF24]"
              : typeof problem?.status === "boolean"
                ? problem?.status
                  ? "text-new-success"
                  : "text-new-failure"
                : ["mcqs"].includes(problemType)
                  ? "text-new-neutral-light"
                  : "text-new-failure"
          )}
        >
          {problem?.solved_on_leetcode && typeof problem?.status === "boolean" && problem?.status == false
            ? "Solved on LeetCode"
            : typeof problem?.status === "boolean"
              ? problem?.status
                ? "Solved"
                : ["mcqs"].includes(problemType)
                  ? "Incorrect"
                  : "Unsolved"
              : "Unsolved"}
        </span>,
        !["mcqs", "editor"].includes(problemType) ? (
          problemType === "subjective" ? (
            <TableButton
              outlined
              className="ml-auto w-fit"
              onClick={() => handleSubjectiveFeedbackBtnClick(problem?.problem_id, problem?.link?.split("/")?.at(-2))}
            >
              Feedback
            </TableButton>
          ) : (
            <TableButton
              outlined
              className="ml-auto w-fit"
              onClick={() => handleFeedbackBtnClick(problem?.problem_id, problem?.link?.split("/")?.at(-2))}
            >
              Feedback
            </TableButton>
          )
        ) : (
          ""
        ),
        isExternalProblemLink ? (
          <a href={problemLink} target="_blank" rel="noopener noreferrer">
            <TableButton outlined className="ml-auto w-fit">
              Start
            </TableButton>
          </a>
        ) : (
          <Link to={problemLink} target="_blank" rel="noopener noreferrer">
            <TableButton outlined className="ml-auto w-fit">
              Start
            </TableButton>
          </Link>
        ),
      ]
    })
  }
  function assignContent(data: any, type: string) {
    return data?.map((problem: any, i: number) => {
      const isExternalProblemLink = isExternalProblem(problem?.link)

      const lastRoute = problem?.link?.split("/")?.at(-1)
      const secondLastRoute = problem?.link?.split("/")?.at(-2)

      const problemType = secondLastRoute === "codeEditor" ? "editor" : secondLastRoute
      const problemLink = isExternalProblemLink ? problem?.link : `/${problemType}/${lastRoute}`

      let tagList = ""
      let activeUrl = ""

      if (problem?.company_tags?.length > 0) {
        const randomIndex = Math.floor(Math.random() * problem.company_tags.length)
        activeUrl = problem.company_tags[randomIndex].url
      }
      problem?.company_tags?.forEach((tag: any) => {
        tagList += tag.name + ", "
      })
      tagList = tagList.slice(0, -2)

      return (
        <div className="my-[8px] h-[102px] w-full p-[12px] border-[#D3D3D3] border-[0.5px] rounded-[8px] overflow-hidden">
          <div className="flex justify-between">
            <p className="text-[#03249A] text-[10px] font-[600]">Topic Name</p>
            <p className="flex items-center text-[12px] font-[500]">
              <button
                className="flex h-6 w-6 items-center"
                onClick={() =>
                  !isExternalProblemLink ? handleInternalProblem(type, i, problem) : handleTableClick(type, i, problem)
                }
                disabled={loadingTable.index === i || (!isExternalProblemLink && problem?.status)}
              >
                {loadingTable.index === i && type === loadingTable.type ? (
                  <Spinner small />
                ) : isExternalProblemLink && problem?.status ? (
                  <CheckSuccessIcon className="h-4 w-4 text-[#FBBF24]" />
                ) : problem?.status ? (
                  <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
                ) : problem?.solved_on_leetcode ? (
                  <CheckSuccessIcon className="h-4 w-4 text-[#FBBF24]" />
                ) : (
                  <CheckBlankIcon className="h-4 w-4" />
                )}
              </button>
              {problem?.solved_on_leetcode && typeof problem?.status === "boolean" && problem?.status == false ? (
                <span className="text-[#FBBF24]">Solved on LeetCode</span>
              ) : typeof problem?.status === "boolean" ? (
                problem?.status ? (
                  <span className="text-[#07B42D] ">Solved</span>
                ) : ["mcqs"].includes(problemType) ? (
                  <span className="text-[rgb(180,7,19)] ">Incorrect</span>
                ) : (
                  <span className="text-[rgb(180,7,19)] ">Unsolved</span>
                )
              ) : (
                <span className="text-[rgb(180,7,19)] ">Unsolved</span>
              )}
            </p>
          </div>
          <p className="text-[14px] text-[#333] font-[600]">{problem?.name}</p>

          <div className="flex justify-between my-[8px]">
            {problem?.company_tags?.length ? (
              <div className="flex gap-[6px] items-center">
                <img alt="" src={activeUrl} className="w-[82px] h-[30px]" />
                <Tooltip info={tagList}>
                  <button className="bg-[#F9F9F9] rounded-[4px] size-[28px]">+</button>
                </Tooltip>
              </div>
            ) : (
              <div></div>
            )}
            <div className="flex gap-[6px] w-[300px] justify-end">
              {!["mcqs", "editor"].includes(problemType) ? (
                problemType === "subjective" ? (
                  <TableButton
                    outlined
                    className="ml-auto w-fit"
                    onClick={() =>
                      handleSubjectiveFeedbackBtnClick(problem?.problem_id, problem?.link?.split("/")?.at(-2))
                    }
                  >
                    Feedback
                  </TableButton>
                ) : (
                  <TableButton
                    outlined
                    className="ml-auto w-fit"
                    onClick={() => handleFeedbackBtnClick(problem?.problem_id, problem?.link?.split("/")?.at(-2))}
                  >
                    Feedback
                  </TableButton>
                )
              ) : (
                <></>
              )}
              {isExternalProblemLink ? (
                <TableButton
                  outlined
                  className="ml-auto w-[160px] py-[6px]"
                  onClick={() => window.open(problem?.link, "_blank", "noopener,noreferrer")}
                >
                  <span className="text-[12px] font-normal text-gray-500">Solve Assignment</span>
                </TableButton>
              ) : (
                <TableButton
                  outlined
                  className="ml-auto w-[160px] py-[6px]"
                  onClick={() => window.open(problemLink, "_blank", "noopener,noreferrer")}
                >
                  <span className="text-[12px] font-normal text-gray-500">Solve Assignment</span>
                </TableButton>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  function assignHeaders(type: string) {
    return [
      <CheckSuccessIcon className="h-4 w-4 text-new-solid-white" />,
      `${type} problem`,
      "Status",
      "SSM Feedback",
      "Link to assignment",
    ]
  }

  function changeTableData(data: any) {
    const problemTypes = ["assignments", "warmup", "homework", "practice"]
    const setProblemsList = [
      setAssignmentsTableContent,
      setWarmupTableContent,
      setHomeworkTableContent,
      setPracticeTableContent,
    ]
    problemTypes.forEach((type: string, i: number) => {
      setProblemsList[i]({
        header: assignHeaders(capitalize(type)),
        rows: assignRows(data?.[type], type),
        content: assignContent(data?.[type], type),
      })
    })
  }

  async function toggleLeetCodeProblemComplete(problem_id: string, status: boolean, coins: number) {
    function getProblems(type: string) {
      return sessionDetails[type].map((problem: any) => {
        return ((problem?.name.split(" ")[0] === "Assignment" ||
          problem?.name.split(" ")[0] === "Homework" ||
          problem?.name.split(" ")[0] === "Practice" ||
          problem?.name.split(" ")[0] === "Warmup") &&
          problem.link === problem_id) ||
          problem.problem_id === problem_id
          ? { ...problem, status }
          : problem
      })
    }

    await toggleLeetCodeProblemCompleteUseCase.invoke(auth, problem_id, status, coins)

    const updatedSessionDetails = {
      ...sessionDetails,
      assignments: getProblems("assignments"),
      warmup: getProblems("warmup"),
      homework: getProblems("homework"),
      practice: getProblems("practice"),
    }
    setSessionDetails(updatedSessionDetails)
    setSessionList({
      ...sessionList,
      [session_id]: updatedSessionDetails,
    })
  }

  async function togglePlatformProblemStatus(problem_id: string, solved_on_leetcode: boolean) {
    function getProblems(type: string) {
      return sessionDetails[type].map((problem: any) => {
        return ((problem?.name.split(" ")[0] === "Assignment" ||
          problem?.name.split(" ")[0] === "Homework" ||
          problem?.name.split(" ")[0] === "Practice" ||
          problem?.name.split(" ")[0] === "Warmup") &&
          problem.link === problem_id) ||
          problem.problem_id === problem_id
          ? { ...problem, solved_on_leetcode }
          : problem
      })
    }

    await togglePlatformProblemStatusUseCase.invoke(auth, problem_id, solved_on_leetcode)

    let updatedSessionDetails = {
      ...sessionDetails,
      assignments: getProblems("assignments"),
      warmup: getProblems("warmup"),
      homework: getProblems("homework"),
      practice: getProblems("practice"),
    }
    setSessionDetails(updatedSessionDetails)
    setSessionList({
      ...sessionList,
      [session_id]: updatedSessionDetails,
    })
  }

  async function submitFeedback(ratings: number[], suggestion?: string) {
    const isValid = ratings?.every((rating) => rating !== 0)

    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please rate all the questions")
      changeToastVisibility(true)
      return
    }

    const data: any = { session_id, live_class_suggestion: suggestion }
    for (let i = 0; i < ratings.length; ++i) {
      data[`${i + 1}`] = ratings[i]
    }

    const response: any = await submitFeedbackUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Feedback submitted successfully!")
    setIsFeedbackVisible(false)
    changeToastVisibility(true)
    await fetchSessionDetails()
    changeToastVisibility(false)
  }
  async function getFeedbackDetails(class_id: string = ""): Promise<any> {
    const response = await getFeedbackDetailsUseCase.invoke(auth, class_id)
    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback loading failed!"))
      changeToastVisibility(true)
      return
    }
    setFeedbackData(response?.data[0])
    return response
  }

  async function submitRecordingFeedback(ratings: number[], status: any, suggestion?: string) {
    const isValid = ratings?.every((rating) => rating !== 0)

    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please rate all the questions")
      changeToastVisibility(true)
      return
    }

    const data: any = { session_id, recorded_class_suggestion: suggestion }
    for (let i = 0; i < ratings.length; ++i) {
      data[`${i}`] = ratings[i]
    }

    const response: any = await submitRecordingFeedbackUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Recording Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Recording Feedback submitted successfully!")
    setIsRecordedFeedbackVisible(false)
    changeToastVisibility(true)
    await fetchSessionDetails()
    changeToastVisibility(false)
  }

  async function submitClassFeedbackNew(feedbackData: any) {
    feedbackData["session_id"] = sessionId
    const response: any = await submitClassFeedbackNewUseCase.invoke(auth, feedbackData)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Feedback submitted successfully!")
    setIsRecordedFeedbackVisible(false)
    setIsClassFeedbackGiven(true)
    changeToastVisibility(true)
    await fetchSessionDetails()
    changeToastVisibility(false)
  }

  async function getClassFeedbackNew(sessionId: any) {
    // feedbackData["session_id"] = sessionId
    const response: any = await getClassFeedbackNewUseCase.invoke(auth, sessionId)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error fetching class feedback"))
      changeToastVisibility(true)
      return
    }
    setClassFeedback(response?.data)
    setIsClassFeedbackGiven(!isEmpty(response?.data))

    // changeToastDetails(STR_SUCCESS, "Recording Feedback submitted successfully!")
    // setIsRecordedFeedbackVisible(false)
    // changeToastVisibility(true)
    // await fetchSessionDetails()
    // changeToastVisibility(false)
  }

  const handleSelectedQuestionBtnClick = (number: number) => {
    setSelectedQuestion(number)
  }
  const handleTabChange = (selectedTab: number) => {
    setTab(selectedTab)
  }
  const handleAssignmentTabChange = (selectedTab: number) => {
    setAssignmenTab(selectedTab)
  }

  return {
    questionType,
    toast,
    sessionId,
    assignmentsTableContent,
    warmupTableContent,
    homeworkTableContent,
    practiceTableContent,
    sessionDetails,
    setSessionDetails,
    courseLectures,
    session,
    sessionList,
    activeIndex,
    loadingTable,
    courseName,
    loading,
    chatbotResponse,
    fetchChatbotResponse,
    chatShort,
    setChatShort,
    isFeedbackVisible,
    setIsFeedbackVisible,
    isRecordedFeedbackVisible,
    setIsRecordedFeedbackVisible,
    handleSidebarData,
    onLectureClick,
    fetchSessionDetails,
    feedbackData,
    classFeedback,
    getFeedbackDetails,
    submitFeedback,
    submitRecordingFeedback,
    changeToastVisibility,
    fetchCourseLectures,
    changeTableData,
    navigate,
    handleFeedbackBtnClick,
    isViewSubmissionVisible,
    order,
    handleOnCloseBtnModalClick,
    fetchViewSubmission,
    questionName,
    isLoading,
    feedbackResponseData,
    selectedQuestion,
    handleSelectedQuestionBtnClick,
    tab,
    assignmenTab,
    handleTabChange,
    handleAssignmentTabChange,
    TABS,
    AssignmentTabs,
    setTab,
    isExternalProblem,
    handleSubjectiveFeedbackBtnClick,
    isViewSubjectiveSubmissionVisible,
    fetchCourse,
    fetchAttendanceAndAssignments,
    fetchDsmlBeginnerModuleSessions,
    handleOnCloseBtnSubmitModalClick,
    rateAIModal,
    fetchViewSubjectiveSubmission,
    setIsStudentFeedbackVisible,
    isStudentFeedbackVisible,
    subjectivefeedbackResponseData,
    handleOnCloseFeedbackBtnClick,
    submitStudentFeedback,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    submitClassFeedbackNew,
    getClassFeedbackNew,
    fetchMasterClass,
    toggleRecordedComplete,
    isClassFeedbackGiven,
    setIsClassFeedbackGiven,
    isChatbotOpen,
    setIsChatbotOpen,
    closeChatbot,
    handleChatClick,
    handleSaveClick,
    handleReadShort,
  }
}
