import { CrossIcon } from "core/constants/svgs"
import { useEffect } from "react"

export default function BatchShiftModal(props: any) {
  const handleOperationalIssue = () => {
    if (props.topic === "Mentor Change") {
      props.setMentor(props.selectedMentor["email"], props.reason)
      props.setSlidingPopUpText("Mentor Changed Successfully")
    } else if (props.topic === "Pause Course") {
      props.setStudentBatchPause(props.reason)
      props.setSlidingPopUpText("Course Pause Request Raised Successfully")
    } else {
      props.setStudentBatchShift(
        props.futureBatchDes["rows"][props.selectedBatch]["batch"][0] === "B"
          ? props.futureBatchDes["rows"][props.selectedBatch]["batch"]
              .slice(0, 3)
              .concat(props.futureBatchDes["rows"][props.selectedBatch]["batch"][4] === "A" ? "A" : "B")
          : props.futureBatchDes["rows"][props.selectedBatch]["batch"],
        props.reason,
        props.topic === "Resume Course" ? 1 : 0,
        props.discordUsername
      )
      props.topic === "Resume Course"
        ? props.setSlidingPopUpText("Course Resume Request Raised Successfully")
        : props.setSlidingPopUpText("Batch Change Request Raised Successfully")
    }
    props.setIsOpen(false)
  }

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={() => props.setIsOpen(false)} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>

        <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
          <div className="m-auto w-[350px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="relative flex">
              <div className="text-lg font-semibold text-[#312E81]">Confirm Change</div>
              <button onClick={() => props.setIsOpen(false)} className="absolute right-0 mt-[3px]">
                <CrossIcon className="h-6 w-6 text-[#312E81]" />
              </button>
            </div>

            <div className="mt-[6px] text-sm font-normal">{props.heading}</div>
            <div className="mt-3 flex gap-x-1">
              <button
                onClick={handleOperationalIssue}
                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded bg-new-gradient text-white hover:bg-[#212121] hover:bg-none"
              >
                YES
              </button>
              <button
                onClick={() => props.setIsOpen(false)}
                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border border-[#EF4444] text-[#EF4444] hover:bg-red-100"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
