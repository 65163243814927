import MentorDashboardDataSource from "data/dataSource/Admin/MentorDashboardDataSource"
import { Auth } from "domain/model/Auth"
import {
  disableMentors,
  enableInstructors,
  mentorProfile,
  MentorsDetails,
  updateMentorProfile,
} from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export class MentorDashboardRepositoryImpl implements MentorDashboardRepository {
  private dataSource: MentorDashboardDataSource

  constructor(dataSource: MentorDashboardDataSource) {
    this.dataSource = dataSource
  }

  getAllMentorsDetails(details: MentorsDetails): Promise<any> {
    return this.dataSource.getAllMentorsDetails(details)
  }

  disableMentor(auth: Auth, disabled: disableMentors): Promise<any> {
    return this.dataSource.disableMentor(auth, disabled)
  }

  enableInstructors(auth: Auth, enabled: enableInstructors): Promise<any> {
    return this.dataSource.enableInstructors(auth, enabled)
  }

  getMentorProfile(id: mentorProfile): Promise<any> {
    return this.dataSource.getMentorProfile(id)
  }

  updateMentorProfiles(auth: Auth, id: updateMentorProfile): Promise<any> {
    return this.dataSource.updateMentorProfiles(auth, id)
  }

  getMentorsDetailList(auth: Auth): Promise<any> {
    return this.dataSource.getMentorsDetailList(auth)
  }

  updateMenteesMentor(auth: Auth, id: any): Promise<any> {
    return this.dataSource.updateMenteesMentor(auth, id)
  }

  deleteMentee(auth: Auth, email: any, mentor_email: any): Promise<any> {
    return this.dataSource.deleteMentee(auth, email, mentor_email)
  }

  uploadMentorImage(auth: Auth, profile_picture: File): Promise<any> {
    return this.dataSource.uploadMentorImage(auth, profile_picture)
  }
  deleteMentorImage(auth: Auth, imageUrl: string): Promise<any> {
    return this.dataSource.deleteMentorImage(auth, imageUrl)
  }
  toggleGoalSettingMentor(auth: Auth, id: any): Promise<any> {
    return this.dataSource.toggleGoalSettingMentor(auth, id)
  }
  toggleLockMentor(auth: Auth, id: any): Promise<any> {
    return this.dataSource.toggleLockMentor(auth, id)
  }
}
