import { useEffect } from "react"
import Cross from "assets/svgs/Cross"
import Toast from "core/components/Toast"
import PaymentInvoiceViewModel from "../PaymentInvoiceViewModel"

export default function PaymentPopUpBreakdown(props: any) {
  const {
    inputValuesmodal,
    campaigns,
    invoiceData,
    toast,
    month,
    influencerData,
    year,
    selectedInfluencer,
    Rates,
    getAllRates,
    setMonth,
    generateCID,
    getInfluencersData,
    changeToastVisibility,
    handleInputChangemodal,
    setSelectedInfluencer,
    submit,
    handleClear,
    setYear,
    setInputValuesmodal,
  } = PaymentInvoiceViewModel()

  useEffect(() => {
    getInfluencersData()
  }, [])

  useEffect(() => {
    if (selectedInfluencer) {
      getAllRates(selectedInfluencer)
    }
  }, [selectedInfluencer])

  const getRatesForCampaignInMonth = (campaignKey: string, monthStr: string, yearStr: string) => {
    if (!monthStr || !yearStr) return []

    const rates = Rates?.data?.[campaignKey] || {}
    const month = parseInt(monthStr, 10)
    const year = parseInt(yearStr, 10)

    const selectedMonthStart = new Date(year, month - 1, 1)
    const selectedMonthEnd = new Date(year, month, 0)

    let rateEntries = Object.entries(rates)
      .map(([date, rate]: any) => {
        const [day, rateMonth, rateYear] = date.split("-")
        return {
          date: new Date(parseInt(rateYear, 10), parseInt(rateMonth, 10) - 1, parseInt(day, 10)),
          rate: parseInt(rate, 10),
        }
      })
      .sort((a, b) => a.date.getTime() - b.date.getTime())

    let finalRates: any[] = []
    let lastRate = null
    let previousDate = selectedMonthStart

    for (let i = 0; i < rateEntries.length; i++) {
      const { date, rate } = rateEntries[i]

      if (date > selectedMonthEnd) break

      if (date < selectedMonthStart) {
        lastRate = rate
        previousDate = selectedMonthStart
        continue
      }

      if (lastRate !== null) {
        finalRates.push({
          date: `${formatDate(previousDate)} - ${formatDate(new Date(date.getTime() - 1))}`,
          rate: lastRate,
        })
      }

      lastRate = rate
      previousDate = date
    }

    if (lastRate !== null) {
      finalRates.push({
        date: `${formatDate(previousDate)} - ${formatDate(selectedMonthEnd)}`,
        rate: lastRate,
      })
    }

    if (finalRates.length === 0) {
      return [{ date: "No Rate", rate: null }]
    }

    return finalRates
  }

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto p-8">
          <div className="m-auto w-[926px] transform space-y-8 rounded border border-new-accent bg-new-solid-white p-6 font-medium">
            <div className="flex flex-col items-center justify-center p-3">
              <div className="flex w-full justify-between items-center">
                <div className="text-[18px] font-bold text-[#312E81]">Fee Payment Breakdown</div>
                <button
                  className="h-[24px] w-[24px] text-gray-500 hover:text-gray-600 focus:outline-none"
                  onClick={props.handlePopUp}
                >
                  <Cross />
                </button>
              </div>
              <div className="mt-4 w-full">
                <label className="block text-sm font-medium text-gray-700">Select Influencer:</label>
                <select
                  className="mt-1 w-full rounded border-gray-300 p-2 text-gray-700"
                  value={selectedInfluencer}
                  onChange={(e) => {
                    setSelectedInfluencer(e.target.value)
                    setInputValuesmodal({})
                  }}
                >
                  <option value="">Select an Influencer</option>
                  {influencerData.map((influencer: any, index: any) => (
                    <option key={index} value={influencer.email}>
                      {influencer.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-4 flex gap-4 w-full">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Select Month:</label>
                  <select
                    className="mt-1 w-full rounded border-gray-300 p-2 text-gray-700"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  >
                    <option value="">Select Month</option>
                    {["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((m) => (
                      <option key={m} value={m}>
                        {new Date(0, parseInt(m) - 1).toLocaleString("default", { month: "long" })}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">Select Year:</label>
                  <select
                    className="mt-1 w-full rounded border-gray-300 p-2 text-gray-700"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value="">Select Year</option>
                    {["2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027"].map(
                      (yearValue, index) => (
                        <option key={index} value={yearValue}>
                          {yearValue}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              {selectedInfluencer && month && year && campaigns.length > 0 && (
                <div className="mt-6 w-full">
                  <div className="text-[16px] font-semibold text-[#312E81] mb-2">Campaigns:</div>
                  {campaigns.map((campaign: string, index: number) => {
                    const campaignKey = generateCID(campaign)
                    const ratesInMonth = month && year ? getRatesForCampaignInMonth(campaignKey, month, year) : []

                    return (
                      <div key={index}>
                        {ratesInMonth.length > 0 ? (
                          ratesInMonth.map(({ date, rate }, rateIndex) => (
                            <div key={`${index}-${rateIndex}`} className="mb-4 flex items-center justify-between">
                              <div className="text-base font-semibold text-[#414141]">{`${campaign} (${date}) - Rate: ${rate}`}</div>
                              <input
                                type="number"
                                className="h-[40px] w-40 appearance-none rounded border border-gray-300 p-2 text-center"
                                value={inputValuesmodal[`${campaign}-${rate}`] || ""}
                                onChange={(e) => handleInputChangemodal(`${campaign}-${rate}`, Number(e.target.value))}
                                placeholder="Enter quantity"
                              />
                            </div>
                          ))
                        ) : (
                          <div className="mb-4 flex items-center justify-between">
                            <div className="text-base font-semibold text-[#414141]">{`${campaign} (No Rate)`}</div>
                            <input
                              type="number"
                              className="h-[40px] w-40 appearance-none rounded border border-gray-300 p-2 text-center"
                              value={inputValuesmodal[`${campaign}-${0}`] || ""}
                              onChange={(e) => handleInputChangemodal(`${campaign}-${0}`, Number(e.target.value))}
                              placeholder="Enter quantity"
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              <div className="mt-6 w-full flex justify-end">
                <button className="bg-new-accent text-new-solid-white px-4 py-2 rounded" onClick={handleClear}>
                  Clear
                </button>
              </div>
              <div className="mt-6 w-full flex justify-end">
                <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={submit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
