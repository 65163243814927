import { ProfileDataSource } from "data/dataSource/Influencer/ProfileDataSource"
import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Influencer/ProfileRepository"

export class ProfileRepositoryImpl implements ProfileRepository {
  private dataSource: ProfileDataSource

  constructor(dataSource: ProfileDataSource) {
    this.dataSource = dataSource
  }

  async fetchInfluencerProfile(email: string, auth: Auth) {
    return await this.dataSource.fetchInfluencerProfile(email, auth)
  }
}
