import ButtonGroup from "core/components/ButtonGroup"
import React, { act, useRef } from "react"
import AssessmentTable from "./components/ContestTable"
import useContestViewModel from "./ContestViewModel"
import Toast from "core/components/Toast"
import {
  STR_COMPLETED_TEST,
  STR_ONGOING_TEST,
  STR_WEEKLY_TEST_SERIES,
  STR_WEEKLY_TEST_SERIES_SUBTITLE,
} from "core/constants/strings"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import Loader from "core/components/Loader"
import PhasesHeading from "./components/PhasesHeading"
import PhaseCard from "./components/PhaseCard"
import ModuleCard from "./components/ModuleCard"
import Button from "core/components/Button"
import NewButton from "core/components/new/Button"
import DropdownArrow from "assets/svgs/DropdownArrow"
import { isEmpty } from "core/utils/misc"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "core/components/v2/Select"
import close from "assets/svgs/close.svg"
import { format, set } from "date-fns"
import { Tooltip } from "@mui/material"
import ResultModal from "./components/ResultModal"
import HomePageGradient from "core/components/new/HomePageGradient"
import { Close } from "@mui/icons-material"
import UnlockIcon from "assets/svgs/UnlockIcon"
import LockClose from "assets/svgs/LockClose"
import CelebrationScreen from "./components/CelebrationScreen"
import NewfeedbackModal from "core/components/NewFeedbackModal"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function ContestView() {
  const {
    auth,
    loading,
    activeButtonType,
    tableData,
    toast,
    changeActiveButtonType,
    getAllContest,
    changeToastVisibility,
    tab,
    handleTabChange,
    fetchBatchwiseExams,
    phases,
    mocks,
    uniqueBatches,
    selectedBatch,
    setSelectedBatch,
    batch,
    batchHasExamTemplate,
    hasExamTemplate,
    checkExamEligibility,
    handleResultClick,
    attendances,
    initializeAssessments,
    currentContestId,
    selectedContest,
    result,
    handleCloseModal,
    resultType,
    initializeAssessmentsBatchwise,
    phasewiseAttendaces,
    phasewiseCurrentContestId,
    checkPhasewiseExamEligibility,
    batchwiseExams,
    leaderboardData,
    questionwiseSubmissions,
    selectedQuestionId,
    changeBatchwiseActiveButtonType,
    hasPreviousExams,
    setHasPreviousExams,
    selectedExam,
    setSelectedExam,
    startTime,
    setStartTime,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    saveFeedback,
    setBatchwiseExams,
    getStartTime,
    student,
  } = useContestViewModel()

  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [selectedPhaseId, setSelectedPhaseId] = React.useState(null)
  const [examType, setExamType] = React.useState("unit")
  const [passed, setPassed] = React.useState<{ [key: string]: boolean }>({})
  const [isLoading, setIsLoading] = React.useState(false)
  const currentTime = Math.floor(Date.now() / 1000)
  // const hasPhasewiseExams = phases.length > 0
  const hasPhasewiseExams = batchwiseExams && batchwiseExams?.[selectedBatch]?.exams ? true : false
  const [isPassedStateVisible, setIsPassedStateVisible] = React.useState(false)
  const [showCelebration, setShowCelebration] = React.useState(false)
  const [selectedAttempts, setSelectedAttempts] = React.useState<{ [key: string]: number }>({})
  const [studentInfo, setStudentInfo] = useLocalStorage<any>("student", {})
  const [showModal, setShowModal] = React.useState(false)

  React.useEffect(() => {
    if (showCelebration) {
      const timer = setTimeout(() => {
        setShowCelebration(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [showCelebration])

  React.useEffect(() => {
    const fetchData = async () => {
      if (selectedBatch !== null) {
        setIsLoading(true)
        try {
          await getAllContest()
          await fetchBatchwiseExams(selectedBatch)
        } catch (error) {
          console.error("Error fetching data:", error)
        } finally {
          setIsLoading(false)
        }
      } else {
      }
    }

    fetchData()
  }, [selectedBatch])
  const resultTooltipTitle = (
    exam: any,
    selectedAttempts: { [key: string]: number },
    selectedBatch: string,
    batchwiseExams: any,
    phasewiseAttendaces: any,
    currentTime: number,
    duration: number
  ) => {
    const attemptLink = exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
    const isViewable = phasewiseAttendaces[attemptLink + "viewResult"]
    const batchExam =
      Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
      batchwiseExams?.[selectedBatch]?.exams.find((batchExam: any) => batchExam.exam_id === attemptLink)

    if (isViewable && batchExam?.submitted) {
      return ""
    } else if (batchExam) {
      const examStartTime = exam.attempts[selectedAttempts[exam.id]].timestamp
      const durationInSeconds = duration * 60
      if (
        examStartTime > currentTime ||
        (examStartTime <= currentTime && examStartTime + durationInSeconds > currentTime && !batchExam.submitted)
      ) {
        return "This link will be activated after the exam"
      } else {
        return "You have not participated in this exam at the scheduled time"
      }
    }

    return "Error in exam rendering"
  }

  const tooltipTitle = (
    exam: any,
    selectedAttempts: { [key: string]: number },
    selectedBatch: string,
    batchwiseExams: any,
    currentTime: number,
    duration: number
  ) => {
    const attemptLink = exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()

    const batchExam =
      Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
      batchwiseExams?.[selectedBatch]?.exams.find((batchExam: any) => batchExam.exam_id === attemptLink)

    if (batchExam?.submitted_due_to_proctoring) {
      return "Exam submitted automatically due to tab switching"
    } else if (
      exam.attempts[selectedAttempts[exam.id]].timestamp <= currentTime &&
      exam.attempts[selectedAttempts[exam.id]].timestamp + duration * 60 > currentTime &&
      batchExam?.submitted
    ) {
      return "Response Already Submitted"
    } else if (exam.attempts[selectedAttempts[exam.id]].timestamp > currentTime) {
      return "Exam has not started yet"
    }

    return "Attempt now"
  }

  let mockTooltipTitle = "Attempt now" // Default value

  if (!isLoading && selectedBatch) {
    if (batchwiseExams && typeof batchwiseExams === "object") {
      const selectedBatchExams = batchwiseExams[selectedBatch]

      if (Array.isArray(selectedBatchExams)) {
        mocks.forEach((mockTest: any) => {
          const batchExam = selectedBatchExams.find(
            (batchExam: any) => batchExam.exam_id === mockTest?.link.split("/").pop()
          )

          if (batchExam) {
            if (batchExam.submitted_due_to_proctoring) {
              mockTooltipTitle = "Exam submitted automatically due to tab switching"
            } else if (mockTest?.submitted && batchExam.start_time > currentTime) {
              mockTooltipTitle = "Response Already Submitted"
            } else if (parseInt(mockTest?.start_time) * 1000 - Date.now() > 30 * 60 * 1000) {
              mockTooltipTitle = "Exam has not started yet"
            } else {
              mockTooltipTitle = "Attempt now"
            }
          }
        })
      } else {
        console.warn(`No exams found for selectedBatch: ${selectedBatch}`)
      }
    } else {
      console.error("batchwiseExams is not defined or is not an object.")
    }
  }

  React.useEffect(() => {
    if (selectedExam === "previous") {
      initializeAssessments()
    }
  }, [selectedExam])

  React.useEffect(() => {
    const check = async () => {
      if (selectedExam === "exam" && hasPhasewiseExams) {
        await changeBatchwiseActiveButtonType()
        await initializeAssessmentsBatchwise()
      } else if (selectedExam === "previous") {
        await changeActiveButtonType(1)
        await initializeAssessments()
        if (tableData?.rows?.filter((row: any) => row.batches.includes(selectedBatch)).length > 0) {
          setHasPreviousExams(true)
        } else {
          setHasPreviousExams(false)
        }
      }
    }

    check()
  }, [hasPhasewiseExams, selectedExam, tableData])
  React.useEffect(() => {
    if (selectedBatch && hasPhasewiseExams === true) {
      setSelectedExam("exam")
      if (phases.length > 0) {
        setSelectedPhaseId(phases[0]?.id)
      }
    } else if (selectedBatch && hasPhasewiseExams === false) {
      setSelectedExam("previous")
    }
  }, [selectedBatch, hasPhasewiseExams, phases])

  React.useEffect(() => {
    phases.forEach((phase: any) => {
      phase.exams.forEach((exam: any) => {
        if (!(exam.id in selectedAttempts)) {
          setSelectedAttempts((prev) => ({ ...prev, [exam.id]: 0 }))
        }
      })
    })
  }, [phases])

  const [collapsedState, setCollapsedState] = React.useState<{ [key: string]: boolean }>({})
  React.useEffect(() => {
    const initialCollapsedState: { [key: string]: boolean } = {}

    phases.forEach((phase: any) => {
      phase.exams.forEach((exam: any) => {
        initialCollapsedState[exam.id] = false
      })
    })

    setCollapsedState(initialCollapsedState)
  }, [phases, selectedBatch])

  React.useEffect(() => {
    if (selectedExam === "exam") {
      const milestoneExams = phases.flatMap((phase: any) =>
        phase.exams.filter((exam: any) => exam.type === "milestone")
      )
      const newPassedStatus: { [key: string]: boolean } = {}

      milestoneExams.forEach((exam: any) => {
        exam.attempts.forEach((attempt: any) => {
          if (Array.isArray(batchwiseExams?.[selectedBatch]?.exams)) {
            const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
              (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
            )

            if (batchExam) {
              newPassedStatus[batchExam.exam_id] = batchExam.passed // true or false
            }
          }
        })
      })
      setPassed(newPassedStatus)
    }
  }, [phases, batchwiseExams, selectedExam])

  const [ongoing, setOngoing] = React.useState(false)

  React.useEffect(() => {
    if (selectedExam === "exam") {
      const isOngoing = phases.some((phase: any) =>
        phase?.exams?.some((exam: any) =>
          exam?.attempts?.some((attempt: any) => {
            const examStartTime = attempt.timestamp
            const duration =
              (Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                batchwiseExams?.[selectedBatch]?.exams.find(
                  (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                )?.duration) ||
              0
            return examStartTime <= currentTime && examStartTime + duration * 60 > currentTime
          })
        )
      )

      setOngoing(isOngoing)
    }
  }, [selectedExam, phases, batchwiseExams?.[selectedBatch]?.exams, currentTime])

  const [ongoingStates, setOngoingStates] = React.useState<{ [key: string]: boolean }>({})

  React.useEffect(() => {
    if (selectedExam === "exam") {
      const ongoingStates = phases
        .flatMap((phase: any) => phase.exams)
        .reduce((acc: any, exam: any) => {
          exam.attempts.forEach((attempt: any) => {
            const examStartTime = attempt.timestamp
            const duration =
              (Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                batchwiseExams?.[selectedBatch]?.exams.find(
                  (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                )?.duration) ||
              0

            const isOngoing = examStartTime <= currentTime && examStartTime + duration * 60 > currentTime

            const attemptId = attempt.link.split("/").pop()
            acc[attemptId] = isOngoing
          })
          return acc
        }, {})

      setOngoingStates(ongoingStates)
    }
  }, [selectedExam, phases, batchwiseExams?.[selectedBatch]?.exams, currentTime])

  React.useEffect(() => {
    const loadFeedback = async () => {
      const lastFeedbackDate = await fetchLastFeedback("Exam")
      const joining_date = await getJoiningDate()
      setShowModal(shouldShowFeedbackAfter2Months(joining_date, lastFeedbackDate))
    }
    loadFeedback()
  }, [])

  React.useEffect(() => {
    if (true) {
      localStorage.removeItem("allQuestions")
      getAllContest()
    }
  }, [])

  React.useEffect(() => {
    if (selectedContest) {
      const newStartTime = getStartTime()
      setStartTime(newStartTime)
    }
  }, [selectedContest])
  const batches = student.batch_v4
  const studentBatchEndtimestamp = Math.max(
    ...(batches && Array.isArray(batches)
      ? batches.filter((batch: any) => batch.batch_id === selectedBatch).map((batch: any) => batch.timestamp_end)
      : []),
    0
  )
  const studentBatchStartTimestamp = Math.min(
    ...(batches && Array.isArray(batches)
      ? batches.filter((batch: any) => batch.batch_id === selectedBatch).map((batch: any) => batch.timestamp_start)
      : [])
  )

  return (
    <DashboardLayoutv2>
      {showCelebration ? (
        <div className="fixed inset-0 z-[999999] flex items-center justify-center bg-[#0C0C0CCC] bg-opacity-80">
          <CelebrationScreen isVisible={showCelebration} onClose={() => setShowCelebration(false)} />
        </div>
      ) : (
        <></>
      )}
      <div className={` ${showCelebration ? "blur-sm" : ""}`}>
        <div className="space-y-1 px-6 pt-6">
          <h1 className="text-[16px] leading-[19px] text-[#333333] font-inter font-semibold">
            {STR_WEEKLY_TEST_SERIES}
          </h1>
          <p className="font-inter text-[14px]">{STR_WEEKLY_TEST_SERIES_SUBTITLE}</p>
        </div>
        <div className="flex justify-between items-center">
          {hasPhasewiseExams && hasPreviousExams ? (
            <div className="flex mx-6 mt-6 px-[4px] py-[4px] text-[14px] bg-[#EBEBEB] h-[43px] rounded-[4px]">
              <Button
                onClick={() => setSelectedExam("exam")}
                className={`text-[14px] text-[#646464] w-[19%] hover:bg-new-solid-white ${
                  selectedExam === "exam" ? "bg-new-solid-white text-[#333333] rounded-[4px]" : ""
                }`}
              >
                <p>Exams</p>
              </Button>
              <Button
                onClick={() => setSelectedExam("mock")}
                className={`hover:bg-new-solid-white text-[14px] text-[#646464] w-[33%] ${selectedExam === "mock" ? "bg-new-solid-white text-[#333333] rounded-[4px]" : ""}`}
              >
                <p>Mock Test</p>
              </Button>
              <Button
                onClick={() => setSelectedExam("previous")}
                className={`hover:bg-new-solid-white text-[14px] text-[#646464] w-[50%] ${selectedExam === "previous" ? "bg-new-solid-white text-[#333333] rounded-[4px]" : ""}`}
              >
                <p>Previous Exams</p>
              </Button>
            </div>
          ) : hasPhasewiseExams && !hasPreviousExams ? (
            <div className="flex mx-6 mt-6 px-[4px] py-[4px] text-[14px] bg-[#EBEBEB] w-[20.5%] h-[43px] rounded-[4px]">
              <Button
                onClick={() => setSelectedExam("exam")}
                className={`text-[14px] text-[#646464] w-[30%] hover:bg-new-solid-white ${
                  selectedExam === "exam" ? "bg-new-solid-white text-[#333333] rounded-[4px]" : ""
                }`}
              >
                <p>Exams</p>
              </Button>
              <Button
                onClick={() => setSelectedExam("mock")}
                className={`hover:bg-new-solid-white text-[14px] text-[#646464] w-[70%] ${selectedExam === "mock" ? "bg-new-solid-white text-[#333333] rounded-[4px]" : ""}`}
              >
                <p>Mock Test</p>
              </Button>
            </div>
          ) : (
            <div className="flex mx-6 mt-6 px-[4px] py-[4px] text-[14px] bg-[#EBEBEB] w-[16%] h-[43px] rounded-[4px]">
              <Button
                onClick={() => setSelectedExam("previous")}
                className={`hover:bg-new-solid-white text-[14px] text-[#646464]  ${selectedExam === "previous" ? "bg-new-solid-white  text-[#333333] rounded-[4px]" : ""}`}
              >
                Previous Exams
              </Button>
            </div>
          )}
          <div className="flex w-[300px] h-[33px] pr-6 mt-6">
            <Select value={selectedBatch} onValueChange={(batch: any) => setSelectedBatch(batch)}>
              <SelectTrigger>
                <SelectValue placeholder="Select Batch" />
              </SelectTrigger>
              <SelectContent className="bg-new-solid-white z-[30]">
                {uniqueBatches.map((batch: any, index: number) => (
                  <SelectItem value={batch} className="cursor-pointer hover:bg-[#F9F9F9]" key={index}>
                    {batch}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-[300px]">
            <Loader />
          </div>
        ) : (
          <div className="space-y-6 p-6">
            <div className="flex">
              <div className="h-screen w-[30%] p-4 bg-[#F9F9F9] mr-2 rounded-[8px]">
                {selectedExam === "exam" && hasPhasewiseExams ? (
                  phases.map((phase: any, index: number) => {
                    const milestoneExams = phase.exams.filter((exam: any) => exam.type === "milestone")

                    let overallPercentage =
                      milestoneExams.length > 0
                        ? Math.max(
                            ...milestoneExams.map((exam: any) => {
                              if (Array.isArray(batchwiseExams?.[selectedBatch]?.exams)) {
                                const maxPercentage = Math.max(
                                  ...exam.attempts.map((attempt: any) => {
                                    const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                                      (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                                    )

                                    if (batchExam) {
                                      const examStartTime = attempt.timestamp
                                      const duration = batchExam.duration || 0
                                      if (
                                        examStartTime > currentTime ||
                                        (examStartTime < currentTime &&
                                          examStartTime + duration * 60 >= currentTime &&
                                          !batchExam?.submitted)
                                      ) {
                                        return -1
                                      } else {
                                        return Math.max(batchExam.overall_percentage) || 0
                                      }
                                    }
                                    return 0
                                  })
                                )
                                return maxPercentage
                              }
                              return 0
                            })
                          )
                        : 0

                    const isOngoing = milestoneExams.every((exam: any) =>
                      exam.attempts.every((attempt: any) => {
                        if (Array.isArray(batchwiseExams?.[selectedBatch]?.exams)) {
                          const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                            (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                          )
                          if (batchExam) {
                            const examStartTime = attempt.timestamp
                            const duration = batchExam.duration || 0

                            return (
                              examStartTime > currentTime || // Future exam
                              (examStartTime <= currentTime && examStartTime + duration * 60 > currentTime) // Ongoing exam
                            )
                          }
                        }
                        return false
                      })
                    )

                    // setPassed(hasPassed)
                    const previousPhase = phases[index - 1]
                    const previousPhasePassed = previousPhase?.exams
                      .filter((exam: any) => exam.type === "milestone")
                      .some((exam: any) =>
                        exam.attempts.some((attempt: any) => {
                          if (Array.isArray(batchwiseExams?.[selectedBatch]?.exams)) {
                            const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                              (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                            )
                            return batchExam ? batchExam.passed === true : false
                          }
                          return false
                        })
                      )
                    const previousPhaseIsOngoing = previousPhase?.exams
                      .filter((exam: any) => exam.type === "milestone")
                      .every((exam: any) =>
                        exam.attempts.every((attempt: any) => {
                          if (Array.isArray(batchwiseExams?.[selectedBatch]?.exams)) {
                            const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                              (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                            )
                            if (batchExam) {
                              const examStartTime = attempt.timestamp
                              const duration = batchExam.duration || 0
                              return (
                                examStartTime > currentTime || // Future exam
                                (examStartTime <= currentTime && examStartTime + duration * 60 > currentTime) // Ongoing exam
                              )
                            }
                          }
                          return false
                        })
                      )
                    const currentPhase = phases[index]
                    // const firstExamOfCurrentPhase = currentPhase?.exams?.[0]
                    // const firstAttemptOfFirstExam = firstExamOfCurrentPhase?.attempts?.[0]
                    // const currentPhaseStartsOutsideBatchRange =
                    //   firstAttemptOfFirstExam?.timestamp < studentBatchStartTimestamp ||
                    //   firstAttemptOfFirstExam?.timestamp > studentBatchEndtimestamp
                    const currentPhaseStartsOutsideBatchRange = !currentPhase.exams.some((exam: any) =>
                      exam.attempts.some(
                        (attempt: any) =>
                          attempt.timestamp >= studentBatchStartTimestamp &&
                          attempt.timestamp <= studentBatchEndtimestamp
                      )
                    )
                    const nextPhaseIsAllowed =
                      ((previousPhasePassed && !previousPhaseIsOngoing) || index == 0) &&
                      !currentPhaseStartsOutsideBatchRange
                    if (currentPhaseStartsOutsideBatchRange) {
                      overallPercentage = -1
                    }
                    const finalOverallPercentage = overallPercentage === -1 ? "N/A" : overallPercentage.toFixed(1)
                    const hasPassed = milestoneExams.some((exam: any) =>
                      exam.attempts.some((attempt: any) => {
                        if (Array.isArray(batchwiseExams?.[selectedBatch]?.exams)) {
                          const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                            (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                          )
                          return batchExam ? batchExam.passed === true : false
                        }
                        return false
                      })
                    )
                    return (
                      <div
                        key={phase?.id}
                        onClick={() => nextPhaseIsAllowed && setSelectedPhaseId(phase?.id)}
                        className={`p-2 ${!nextPhaseIsAllowed ? "pointer-events-none" : ""}`}
                      >
                        <div className="bg-[#333333] py-1 px-2 rounded-[4px] flex w-[50%] justify-between items-center">
                          <p className="text-new-solid-white font-medium leading-[17px] text-[14px] font-inter justify-between items-center">
                            Phase {index + 1}
                          </p>
                          {nextPhaseIsAllowed ? <UnlockIcon /> : <LockClose />}
                        </div>
                        <div className="">
                          <PhaseCard
                            phase_name={phase?.name}
                            milestone_marks={finalOverallPercentage}
                            passed={hasPassed}
                            ongoing={isOngoing}
                            isAllowed={nextPhaseIsAllowed}
                            selectedPhaseId={selectedPhaseId}
                            id={phase?.id}
                            currentPhaseStartsOutsideBatchRange={currentPhaseStartsOutsideBatchRange}
                          />
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <></>
                )}
              </div>

              <div className="w-[70%]">
                {hasPhasewiseExams && hasPreviousExams ? (
                  <>
                    {selectedExam === "exam" ? (
                      phases
                        .filter((phase: any, index: number) => phase.id === selectedPhaseId)
                        .map((phase: any) => {
                          // const firstExamOfCurrentPhase = phase?.exams?.[0]
                          // const firstAttemptOfCurrentPhase = firstExamOfCurrentPhase?.attempts?.[0]
                          // const currentPhaseStartsOutsideBatchRange =
                          //   firstAttemptOfCurrentPhase?.timestamp < studentBatchStartTimestamp ||
                          //   firstAttemptOfCurrentPhase?.timestamp > studentBatchEndtimestamp
                          const currentPhase = phases.find((phase: any) => phase.id === selectedPhaseId)
                          const currentPhaseStartsOutsideBatchRange = !currentPhase.exams.some((exam: any) =>
                            exam.attempts.some(
                              (attempt: any) =>
                                attempt.timestamp >= studentBatchStartTimestamp &&
                                attempt.timestamp <= studentBatchEndtimestamp
                            )
                          )

                          if (currentPhaseStartsOutsideBatchRange) {
                            return (
                              <>
                                <div className="flex justify-center items-center h-[300px]">
                                  <p className="text-[14px] text-[#333333]">No exams found</p>
                                </div>
                              </>
                            )
                          }
                          return (
                            <div key={phase?.id}>
                              {phase?.exams.map((exam: any, exam_index: number) => {
                                const batchExam = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                                  ? batchwiseExams?.[selectedBatch]?.exams.find(
                                      (batchExam: any) =>
                                        batchExam.exam_id ===
                                        exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                    )
                                  : null

                                const duration = batchExam?.duration
                                const isOngoing = exam?.attempts?.some((attempt: any) => {
                                  const examStartTime = attempt.timestamp
                                  return (
                                    examStartTime > currentTime ||
                                    (examStartTime <= currentTime && examStartTime + duration * 60 > currentTime)
                                  )
                                })
                                const overallPassed = exam?.attempts?.some((attempt: any) => {
                                  const attemptBatchExam = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                                    ? batchwiseExams?.[selectedBatch]?.exams.find(
                                        (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                                      )
                                    : null
                                  return attemptBatchExam
                                    ? attemptBatchExam.passed === true &&
                                        phasewiseAttendaces[attempt.link.split("/").pop()] &&
                                        attemptBatchExam.attempted_live === true &&
                                        attemptBatchExam.submitted === true
                                    : false
                                })
                                const overallFailed = exam?.attempts?.every((attempt: any) => {
                                  const attemptBatchExam = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                                    ? batchwiseExams?.[selectedBatch]?.exams.find(
                                        (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                                      )
                                    : null
                                  const isOngoing =
                                    attempt.timestamp <= currentTime && attempt.timestamp + duration * 60 > currentTime
                                  const isUpcoming = attempt.timestamp > currentTime

                                  return attemptBatchExam
                                    ? attemptBatchExam.passed === false &&
                                        attemptBatchExam.attempted_live === true &&
                                        attemptBatchExam.submitted === true &&
                                        // !isOngoing &&
                                        // !isUpcoming &&
                                        phasewiseAttendaces[attempt.link.split("/").pop()]
                                    : false
                                })
                                const attemptsOutsideBatchRange =
                                  exam?.attempts[selectedAttempts[exam.id]]?.timestamp < studentBatchStartTimestamp ||
                                  exam?.attempts[selectedAttempts[exam.id]]?.timestamp > studentBatchEndtimestamp

                                return (
                                  <div
                                    key={exam?.id}
                                    onClick={() => setExamType(exam.type)}
                                    className="bg-[#F9F9F9] max-h-[231px]  rounded-[8px] p-4 flex flex-col gap-4 mb-[9px]"
                                  >
                                    <div className="font-inter font-semibold text-[#333333] flex justify-between items-center">
                                      <div className="flex text-[14px]">
                                        <p>{exam.type === "unit" ? `Exam ${exam_index + 1}` : `Milestone Exam`}</p>
                                        <p className="flex pl-[6px]">
                                          {collapsedState[exam.id] ? (
                                            <p className="text-[#646464] font-normal">Result</p>
                                          ) : (
                                            <p>
                                              {/* {!isOngoing ? ( */}
                                              {overallPassed === true ? (
                                                <p className="text-[#07B42D] font-normal">Passed</p>
                                              ) : overallFailed === true ? (
                                                <p className="text-[#DC2626] font-normal">Failed</p>
                                              ) : attemptsOutsideBatchRange ? (
                                                <></>
                                              ) : (
                                                <></>
                                              )}
                                            </p>
                                          )}
                                        </p>
                                      </div>

                                      {/* <button onClick={() => toggleCollapse(exam?.id)} key={exam.id}> */}
                                      <button
                                        onClick={() => {
                                          setCollapsedState((prev) => ({
                                            ...prev,
                                            [exam.id]: !prev[exam.id], // Toggle the collapsed state for this exam
                                          }))
                                        }}
                                        key={exam.id}
                                      >
                                        <div className={`${collapsedState[exam.id] ? "rotate-0" : "-rotate-180"}`}>
                                          <DropdownArrow
                                            className={`text-[#646464] w-4 h-4 font-medium transition-transform cursor-pointer`}
                                            key={exam?.id}
                                          />
                                        </div>
                                      </button>
                                    </div>
                                    {!collapsedState[exam.id] && (
                                      <div className="gap-4">
                                        <div className="flex gap-1">
                                          {exam?.attempts?.map((attempt: any, attempt_index: number) => (
                                            <Button
                                              key={attempt_index}
                                              className={`w-auto border-0 text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${
                                                // selectedAttempt === attempt_index
                                                selectedAttempts[exam.id] === attempt_index
                                                  ? "bg-[#162456] text-white "
                                                  : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white "
                                              }`}
                                              onClick={() => {
                                                handleTabChange(`${attempt_index + 1}`)
                                                // setSelectedAttempt(attempt_index)
                                                setSelectedAttempts((prev) => ({
                                                  ...prev,

                                                  [exam.id]: attempt_index,
                                                }))
                                              }}
                                            >
                                              {`Attempt ${attempt_index + 1}`}
                                            </Button>
                                          ))}
                                        </div>
                                        <div className="pt-4">
                                          <ModuleCard
                                            className={`relative p-0 ${exam.type === "unit" ? "" : "bg-[#333333]"}`}
                                          >
                                            {exam.type !== "unit" && (
                                              <div className="absolute inset-0 z-0 flex justify-between overflow-hidden rounded-lg">
                                                <div className="absolute top-0 left-[220px] z-0">
                                                  <HomePageGradient />
                                                </div>
                                                <div className="absolute top-0 left-[295px] z-0">
                                                  <HomePageGradient />
                                                </div>
                                              </div>
                                            )}
                                            <div className="flex justify-between items-center pl-[12px] pt-[12px] z-[10]">
                                              {exam.type === "unit" ? (
                                                <p className="text-[10px] text-[#03249A] font-semibold">{`Exam ${exam_index + 1}`}</p>
                                              ) : (
                                                <div className="bg-[#FBBF24] rounded-[2px] ">
                                                  <p className="text-[10px] text-[#333333] font-semibold px-2">
                                                    Milestone Exam
                                                  </p>
                                                </div>
                                              )}

                                              <span className="pr-[12px] z-[30]">
                                                {phasewiseAttendaces[
                                                  exam?.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                                ] === true ? (
                                                  Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                  (() => {
                                                    const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                                                      (batchExam: any) =>
                                                        batchExam.exam_id ===
                                                        exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                                    )
                                                    if (batchExam) {
                                                      const passed = batchExam.passed

                                                      return passed ? (
                                                        <div className="relative flex items-center">
                                                          <input
                                                            type="checkbox"
                                                            className="rounded-[2px] border-[#D3D3D3] checked:bg-[#00C22B] h-4 w-4"
                                                            checked={true}
                                                            readOnly
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="relative flex items-center">
                                                          <input
                                                            type="checkbox"
                                                            className="rounded-[2px] bg-[#DC2626] border-[#D3D3D3] h-4 w-4"
                                                            checked={false}
                                                            readOnly
                                                          />
                                                          <Close className="absolute text-white left-[-4px] p-1 z-2" />
                                                        </div>
                                                      )
                                                    }
                                                    return null
                                                  })()
                                                ) : exam?.attempts[selectedAttempts[exam.id]]?.timestamp >
                                                    currentTime ||
                                                  (exam?.attempts[selectedAttempts[exam.id]]?.timestamp <=
                                                    currentTime &&
                                                    exam?.attempts[selectedAttempts[exam.id]]?.timestamp +
                                                      duration * 60 >
                                                      currentTime &&
                                                    !exam?.attempts[selectedAttempts[exam.id]]?.submitted) ? (
                                                  <div className="relative flex items-center">
                                                    <input
                                                      type="checkbox"
                                                      className="rounded-[2px] border-[#D3D3D3] h-4 w-4"
                                                      checked={false}
                                                      readOnly
                                                    />
                                                  </div>
                                                ) : attemptsOutsideBatchRange ? (
                                                  <div className="relative flex items-center">
                                                    <input
                                                      type="checkbox"
                                                      className="rounded-[2px] border-[#D3D3D3] h-4 w-4"
                                                      checked={false}
                                                      readOnly
                                                    />
                                                  </div>
                                                ) : (
                                                  <div className="relative flex items-center">
                                                    <input
                                                      type="checkbox"
                                                      className="rounded-[2px] bg-[#DC2626] border-[#D3D3D3] h-4 w-4"
                                                      checked={false}
                                                      readOnly
                                                    />
                                                    <Close className="absolute text-white left-[-4px] p-1" />
                                                  </div>
                                                )}
                                              </span>
                                            </div>
                                            <div className="flex pl-[12px] z-10">
                                              <p
                                                className={`flex text-[14px] text-[#333333] font-semibold ${exam.type === "unit" ? "" : "text-white"}`}
                                              >
                                                {exam?.name}
                                              </p>
                                            </div>

                                            {tab && exam.attempts[selectedAttempts[exam.id]] && (
                                              <p
                                                className={`text-[12px] font-medium text-[#646464] pl-[12px] z-10 ${exam.type === "unit" ? "" : "text-white"}`}
                                              >
                                                {(() => {
                                                  const selectedAttemptData = exam.attempts[selectedAttempts[exam.id]]
                                                  const date = new Date(selectedAttemptData.timestamp * 1000)
                                                  return `${date.getDate()} ${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()} | ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}`
                                                })()}
                                              </p>
                                            )}
                                            <div className="flex pt-[6px] justify-between items-center z-[30]">
                                              {tab && exam.attempts[selectedAttempts[exam.id]] && (
                                                <>
                                                  <p className="flex font-medium text-[12px] text-[#07B42D] pl-[12px]">
                                                    {Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                    batchwiseExams?.[selectedBatch]?.exams.find(
                                                      (batchExam: any) =>
                                                        batchExam.exam_id ===
                                                        exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                                    ) ? (
                                                      (() => {
                                                        const currentBatchExam = batchwiseExams?.[
                                                          selectedBatch
                                                        ]?.exams.find(
                                                          (batchExam: any) =>
                                                            batchExam.exam_id ===
                                                            exam.attempts[selectedAttempts[exam.id]]?.link
                                                              .split("/")
                                                              .pop()
                                                        )

                                                        // Check if the exam is ongoing
                                                        const isOngoing =
                                                          exam.attempts[selectedAttempts[exam.id]].timestamp <=
                                                            currentTime &&
                                                          exam.attempts[selectedAttempts[exam.id]].timestamp +
                                                            duration * 60 >
                                                            currentTime

                                                        const passed = currentBatchExam?.passed

                                                        if (
                                                          exam.attempts[selectedAttempts[exam.id]].timestamp >
                                                          currentTime
                                                        ) {
                                                          return (
                                                            <span
                                                              className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                            >
                                                              Score: N/A
                                                            </span>
                                                          )
                                                        } else if (isOngoing && !currentBatchExam?.submitted) {
                                                          return (
                                                            <span
                                                              className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                            >
                                                              Score: N/A
                                                            </span>
                                                          )
                                                        } else if (
                                                          currentBatchExam.attempted_live === true ||
                                                          currentBatchExam.allow_result === true ||
                                                          currentBatchExam.submitted === true
                                                        ) {
                                                          if (passed === false) {
                                                            return (
                                                              <span className="text-[#DC2626]">
                                                                Failed:{" "}
                                                                {currentBatchExam?.overall_percentage.toFixed(1)}%
                                                              </span>
                                                            )
                                                          } else {
                                                            return (
                                                              <p className="text-[#07B42D] text-[12px]">
                                                                Score: {currentBatchExam.overall_percentage.toFixed(1)}%
                                                              </p>
                                                            )
                                                          }
                                                        } else if (attemptsOutsideBatchRange) {
                                                          return (
                                                            <span
                                                              className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                            >
                                                              Score: N/A
                                                            </span>
                                                          )
                                                        } else {
                                                          return <span className="text-[#DC2626]">Not Attempted</span>
                                                        }
                                                      })()
                                                    ) : (
                                                      <span
                                                        className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                      >
                                                        Score: N/A
                                                      </span>
                                                    )}
                                                  </p>
                                                  <div className="flex z-[8] mt-[-4px]">
                                                    <Tooltip
                                                      className="d-block"
                                                      title={
                                                        attemptsOutsideBatchRange
                                                          ? "You have not participated in this exam at the scheduled time"
                                                          : resultTooltipTitle(
                                                              exam,
                                                              selectedAttempts,
                                                              selectedBatch,
                                                              batchwiseExams,
                                                              phasewiseAttendaces,
                                                              currentTime,
                                                              duration
                                                            )
                                                      }
                                                    >
                                                      <span className="flex pr-[7px] z-[30] ">
                                                        <button
                                                          disabled={
                                                            attemptsOutsideBatchRange ||
                                                            (Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                              batchwiseExams?.[selectedBatch]?.exams.find(
                                                                (batchExam: any) =>
                                                                  (batchExam.exam_id ===
                                                                    exam.attempts[selectedAttempts[exam.id]]?.link
                                                                      .split("/")
                                                                      .pop() &&
                                                                    exam.attempts[selectedAttempts[exam.id]].timestamp >
                                                                      currentTime) ||
                                                                  (exam.attempts[selectedAttempts[exam.id]].timestamp <=
                                                                    currentTime &&
                                                                    exam.attempts[selectedAttempts[exam.id]].timestamp +
                                                                      duration * 60 >
                                                                      currentTime &&
                                                                    Array.isArray(
                                                                      batchwiseExams?.[selectedBatch]?.exams
                                                                    ) &&
                                                                    !batchwiseExams?.[selectedBatch]?.exams.find(
                                                                      (batchExam: any) =>
                                                                        batchExam.exam_id ===
                                                                        exam.attempts[selectedAttempts[exam.id]]?.link
                                                                          .split("/")
                                                                          .pop()
                                                                    ).submitted)
                                                              )) ||
                                                            !phasewiseAttendaces[
                                                              exam.attempts[selectedAttempts[exam.id]].link
                                                                .split("/")
                                                                .pop() + "viewResult"
                                                            ]
                                                          }
                                                          className={`z-[30] w-[122px] disabled:text-[#D3D3D3] bg-new-solid-white text-[12px] border-[#D3D3D3] font-inter text-[#646464] font-medium border-[0.5px] rounded-[4px] px-4 py-[5px]`}
                                                          onClick={() =>
                                                            handleResultClick(
                                                              exam.attempts[selectedAttempts[exam.id]]?.link
                                                                .split("/")
                                                                .pop()
                                                            )
                                                          }
                                                        >
                                                          {selectedContest ===
                                                            exam.attempts[selectedAttempts[exam.id]]?.link
                                                              .split("/")
                                                              .pop() && loading ? (
                                                            <Loader xs={true} height={"100%"} />
                                                          ) : (
                                                            "View Result"
                                                          )}
                                                        </button>
                                                      </span>
                                                    </Tooltip>

                                                    <Tooltip
                                                      title={
                                                        attemptsOutsideBatchRange
                                                          ? "You are not supposed to attempt this exam"
                                                          : tooltipTitle(
                                                              exam,
                                                              selectedAttempts,
                                                              selectedBatch,
                                                              batchwiseExams,
                                                              currentTime,
                                                              duration
                                                            )
                                                      }
                                                    >
                                                      <span className="pr-[12px] flex z-[30]">
                                                        <button
                                                          className="bg-new-solid-white text-[12px] disabled:text-[#D3D3D3] border-[#D3D3D3] w-[122px] font-inter text-[#646464] font-medium h-auto border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                          disabled={
                                                            attemptsOutsideBatchRange ||
                                                            exam.attempts[selectedAttempts[exam.id]].timestamp >
                                                              currentTime ||
                                                            (exam.attempts[selectedAttempts[exam.id]].timestamp <=
                                                              currentTime &&
                                                              exam.attempts[selectedAttempts[exam.id]].timestamp +
                                                                duration * 60 >
                                                                currentTime &&
                                                              Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                              batchwiseExams?.[selectedBatch]?.exams.find(
                                                                (batchExam: any) =>
                                                                  batchExam.exam_id ===
                                                                  exam.attempts[selectedAttempts[exam.id]]?.link
                                                                    .split("/")
                                                                    .pop()
                                                              ).submitted === true)
                                                          }
                                                          onClick={() =>
                                                            checkPhasewiseExamEligibility(
                                                              exam.attempts[selectedAttempts[exam.id]]?.link
                                                                .split("/")
                                                                .pop(),
                                                              false
                                                            )
                                                          }
                                                        >
                                                          {exam.attempts[selectedAttempts[exam.id]]?.timestamp +
                                                            duration * 60 >
                                                            currentTime &&
                                                          batchwiseExams?.[selectedBatch]?.exams[
                                                            exam.attempts[selectedAttempts[exam.id]]
                                                          ]?.submitted === true
                                                            ? "Reattempt"
                                                            : (exam.attempts[selectedAttempts[exam.id]]?.timestamp >
                                                                  currentTime ||
                                                                  (exam.attempts[selectedAttempts[exam.id]]?.timestamp <
                                                                    currentTime &&
                                                                    exam.attempts[selectedAttempts[exam.id]]
                                                                      ?.timestamp +
                                                                      duration * 60 >=
                                                                      currentTime)) &&
                                                                !batchwiseExams?.[selectedBatch]?.exams[
                                                                  exam.attempts[selectedAttempts[exam.id]]
                                                                ]?.submitted
                                                              ? "Start Exam"
                                                              : !phasewiseAttendaces[
                                                                    exam.attempts[selectedAttempts[exam.id]]?.link
                                                                      .split("/")
                                                                      .pop()
                                                                  ]
                                                                ? attemptsOutsideBatchRange
                                                                  ? "Start Exam"
                                                                  : "Practice Exam"
                                                                : "Reattempt"}
                                                        </button>
                                                      </span>
                                                    </Tooltip>
                                                  </div>
                                                  {/* </div> */}
                                                </>
                                              )}
                                            </div>
                                          </ModuleCard>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })
                    ) : selectedExam === "previous" ? (
                      <div className="flex">
                        <div className="w-full">
                          {tableData?.rows?.filter((row: any) => row.batches.includes(selectedBatch)).length === 0 ? (
                            <div className="flex justify-center items-center h-[300px]">
                              <p className="text-[14px] text-[#333333]">No previous exams found</p>
                            </div>
                          ) : (
                            tableData?.rows
                              ?.filter((row: any) => row.batches.includes(selectedBatch))
                              ?.map((exam: any, index: number) => {
                                const isStartDisabled = parseInt(exam.start_time) * 1000 - Date.now() > 30 * 60 * 1000
                                const isResultDisabled = !attendances[`${exam.contest_id}viewResult`]
                                const ts = parseInt(exam?.start_time) * 1000
                                const isLinkDisabled = ts - Date.now() > 30 * 60 * 1000
                                return (
                                  <div
                                    key={exam.contest_id}
                                    onClick={() => setExamType("unit")}
                                    className="bg-[#F9F9F9] max-h-[231px]  rounded-[8px] p-4 flex flex-col gap-4 mb-[9px]"
                                  >
                                    {/* Exam Header */}
                                    <div className="font-inter font-semibold text-[#333333] flex justify-between items-center">
                                      <div className="flex text-[14px]">
                                        <p>Exam {index + 1}</p>
                                        {collapsedState[exam.contest_id] ? (
                                          <p className="text-[#646464] font-normal pl-[6px]">Result</p>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {/* <button onClick={() => toggleCollapse(exam.contest_id)}> */}
                                      <button
                                        onClick={() => {
                                          setCollapsedState((prev) => ({
                                            ...prev,
                                            [exam.contest_id]: !prev[exam.contest_id], // Toggle the collapsed state for this exam
                                          }))
                                        }}
                                        key={exam.contest_id}
                                      >
                                        <div
                                          className={`${collapsedState[exam.contest_id] ? "rotate-0" : "-rotate-180"}`}
                                        >
                                          <DropdownArrow
                                            className={`text-[#646464] w-4 h-4 font-medium transition-transform cursor-pointer`}
                                            key={exam?.contest_id}
                                          />
                                        </div>
                                      </button>
                                    </div>

                                    {!collapsedState[exam.contest_id] && (
                                      <ModuleCard className="relative p-0 z-0">
                                        <div className="flex justify-between items-center">
                                          <p className="text-[10px] text-[#03249A] font-semibold pl-[12px] pt-[12px]">{`Exam ${index + 1}`}</p>
                                          <div className="flex pr-[12px]">
                                            <span>
                                              {attendances[exam?.contest_id] === true ? (
                                                <div className="relative flex items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="rounded-[2px] border-[#D3D3D3] checked:bg-[#00C22B] h-4 w-4"
                                                    checked={true}
                                                    readOnly
                                                  />
                                                </div>
                                              ) : (
                                                <div className="relative flex items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="rounded-[2px] bg-[#DC2626] border-[#D3D3D3] h-4 w-4 "
                                                    checked={false}
                                                    readOnly
                                                  />
                                                  <Close className="absolute text-white left-[-4px] p-1" />
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="flex flex-col pb-[10px]">
                                          {/* Exam Details */}

                                          <p className="pl-[12px] text-[14px] font-semibold text-[#333333]">
                                            {exam.name}
                                          </p>
                                          <p className="text-[12px] text-[#646464] px-[12px]">
                                            {format(new Date(exam.start_time * 1000), "dd MMM yyyy")} |{" "}
                                            {format(new Date(exam.start_time * 1000), "h:mm a")}
                                          </p>

                                          {/* Action Buttons */}
                                          <div className="flex justify-between items-center">
                                            <p className="text-[#07B42D] font-medium text-[12px] pl-[12px] pt-[10px]">
                                              {typeof exam.overall_percentage === "number" ? (
                                                `Score: ${exam.overall_percentage}%`
                                              ) : (
                                                <span className="text-[#DC2626]">Not Attempted</span>
                                              )}
                                            </p>

                                            <div className="flex ">
                                              <Tooltip
                                                className="d-block"
                                                title={
                                                  attendances[exam?.contest_id + "viewResult"]
                                                    ? ""
                                                    : activeButtonType === 1
                                                      ? "You have not participated in this exam at the scheduled time"
                                                      : // : "This link will be activated after the exam"
                                                        "Error in exam rendering"
                                                }
                                              >
                                                <span className="mr-[7px] flex">
                                                  <button
                                                    disabled={
                                                      exam?.submitted !== true ||
                                                      !attendances[exam?.contest_id + "viewResult"]
                                                    }
                                                    className="bg-new-solid-white disabled:text-[#D3D3D3] text-[12px] border-[#D3D3D3] font-inter w-[122px] text-[#646464] font-medium  border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                    style={{
                                                      backgroundColor:
                                                        exam?.submitted !== true ||
                                                        !attendances[exam?.contest_id + "viewResult"]
                                                          ? "white"
                                                          : "",
                                                    }}
                                                    onClick={() => handleResultClick(exam?.contest_id)}
                                                  >
                                                    {selectedContest === exam?.contest_id && loading ? (
                                                      <Loader xs={true} height={"100%"} />
                                                    ) : (
                                                      "View Result"
                                                    )}
                                                  </button>
                                                </span>
                                              </Tooltip>
                                              <Tooltip
                                                title={
                                                  exam?.["submitted_due_to_proctoring"]
                                                    ? "Exam submitted automatically due to tab switching"
                                                    : isLinkDisabled
                                                      ? "Contest has not started yet"
                                                      : "Attempt now"
                                                }
                                              >
                                                <span className="mr-[12px] flex">
                                                  <button
                                                    className="bg-new-solid-white text-[12px] disabled:text-[#D3D3D3] border-[#D3D3D3] font-inter text-[#646464] font-medium w-[122px] border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                    disabled={
                                                      isLinkDisabled
                                                      // exam?.start_time < exam?.last_submission_timestamp ||
                                                      // loading
                                                    }
                                                    onClick={() => checkExamEligibility(exam?.contest_id)}
                                                  >
                                                    {exam?.submitted === true && attendances[exam?.contest_id]
                                                      ? "Reattempt"
                                                      : !exam?.submitted && attendances[exam?.contest_id]
                                                        ? "Start Exam"
                                                        : !attendances[exam?.contest_id]
                                                          ? // ? "Start Exam"
                                                            "Practice Exam"
                                                          : "Reattempt"}
                                                  </button>
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </ModuleCard>
                                    )}
                                  </div>
                                )
                              })
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        {mocks.length > 0 ? (
                          mocks.map((mockTest: any, mock_index: number) => {
                            const mockExamData = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                              ? batchwiseExams?.[selectedBatch]?.exams.find(
                                  (batchExam: any) => batchExam.exam_id === mockTest?.link.split("/").pop()
                                )
                              : null

                            const passed = mockExamData?.passed && mockExamData?.isMock
                            const failed =
                              mockExamData?.passed === false &&
                              mockExamData?.isMock &&
                              mockExamData?.total_obtained_score !== "N/A"

                            return (
                              <div
                                key={mock_index}
                                className="bg-[#F9F9F9] cursor-pointer max-h-[231px] rounded-[8px] p-4 flex flex-col gap-4 mb-[9px]"
                              >
                                <div className="font-inter font-semibold text-[#333333] flex justify-between items-center">
                                  <div className="flex">
                                    <p className="text-[14px] font-semibold">{`Exam ${mock_index + 1}`}</p>
                                    <div className="flex pl-[6px]">
                                      {!collapsedState[mockTest.id] ? (
                                        passed ? (
                                          <p className="text-[#07B42D] font-normal">Passed</p>
                                        ) : failed ? (
                                          <p className="text-[#DC2626] font-normal">Failed</p>
                                        ) : (
                                          <p className="text-[#DC2626] font-normal">Not Attempted</p>
                                        )
                                      ) : (
                                        <p className="text-[14px] font-normal">Result</p>
                                      )}
                                    </div>
                                  </div>

                                  <button
                                    onClick={() => {
                                      setCollapsedState((prev) => ({
                                        ...prev,
                                        [mockTest.id]: !prev[mockTest.id], // Toggle the collapsed state for this exam
                                      }))
                                    }}
                                    key={mockTest.id}
                                  >
                                    <div className={`${collapsedState[mockTest.id] ? "rotate-0" : "-rotate-180"}`}>
                                      <DropdownArrow
                                        className={`text-[#646464] w-4 h-4 font-medium transition-transform cursor-pointer`}
                                        key={mockTest?.id}
                                      />
                                    </div>
                                  </button>
                                </div>
                                {!collapsedState[mockTest.id] && (
                                  <ModuleCard className="relative p-0 z-0">
                                    <p className="text-[10px] text-[#03249A] font-semibold pt-[12px] pl-[12px]">{`Exam ${mock_index + 1}`}</p>
                                    <div className="flex">
                                      <p className="flex text-[14px] text-[#333333] font-semibold pl-[12px]">
                                        {mockTest?.name}
                                      </p>
                                    </div>
                                    <div className="flex pt-[22px] justify-between items-center z-10">
                                      <p className="flex font-medium text-[12px] pl-[12px]">
                                        {passed === true ? (
                                          <p className="text-[#07B42D] text-[12px]">Passed</p>
                                        ) : failed ? (
                                          <p className="text-[#DC2626] text-[12px]">Failed</p>
                                        ) : (
                                          <p className="text-[#DC2626] text-[12px]">Not Attempted</p>
                                        )}
                                      </p>
                                      <Tooltip title={mockTooltipTitle}>
                                        <span className="pr-[12px] flex">
                                          <Button
                                            small
                                            className="bg-new-solid-white text-[12px] w-[122px] border-[#D3D3D3] font-inter text-[#646464] font-medium border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                            disabled={
                                              parseInt(batchwiseExams?.[selectedBatch[mock_index]]?.start_time) * 1000 -
                                                Date.now() >
                                                30 * 60 * 1000 ||
                                              (batchwiseExams?.[selectedBatch[mock_index]]?.start_time <
                                                batchwiseExams?.[selectedBatch[mock_index]]
                                                  ?.last_submission_timestamp &&
                                                batchwiseExams?.[selectedBatch[mock_index]].start_time > currentTime) ||
                                              phasewiseAttendaces[phasewiseCurrentContestId]
                                            }
                                            onClick={() =>
                                              checkPhasewiseExamEligibility(mockTest?.link.split("/").pop(), true)
                                            }
                                          >
                                            Start Exam
                                          </Button>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </ModuleCard>
                                )}
                              </div>
                            )
                          })
                        ) : (
                          <div className="flex justify-center items-center h-[300px]">
                            <p className="text-[14px] text-[#333333]">No Mock Exams Found</p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : hasPhasewiseExams && !hasPreviousExams ? (
                  selectedExam === "exam" ? (
                    phases
                      .filter((phase: any) => phase.id === selectedPhaseId)
                      .map((phase: any) => {
                        // const firstExamOfCurrentPhase = phase?.exams?.[0]
                        // const firstAttemptOfCurrentPhase = firstExamOfCurrentPhase?.attempts?.[0]
                        // const currentPhaseStartsAfterBatchEnds =
                        //   firstAttemptOfCurrentPhase?.timestamp > studentBatchEndtimestamp
                        const currentPhase = phases.find((phase: any) => phase.id === selectedPhaseId)
                        const currentPhaseStartsOutsideBatchRange = !currentPhase.exams.some((exam: any) =>
                          exam.attempts.some(
                            (attempt: any) =>
                              attempt.timestamp >= studentBatchStartTimestamp &&
                              attempt.timestamp <= studentBatchEndtimestamp
                          )
                        )

                        if (currentPhaseStartsOutsideBatchRange) {
                          return (
                            <>
                              <div className="flex justify-center items-center h-[300px]">
                                <p className="text-[14px] text-[#333333]">No exams found</p>
                              </div>
                            </>
                          )
                        }
                        return (
                          <div key={phase?.id}>
                            {phase?.exams.map((exam: any, exam_index: number) => {
                              const batchExam = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                                ? batchwiseExams?.[selectedBatch]?.exams.find(
                                    (batchExam: any) =>
                                      batchExam.exam_id ===
                                      exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                  )
                                : null // Set to null if batchwiseExams?.[selectedBatch]?.exams is not an array

                              const duration = batchExam?.duration
                              const isOngoing = exam?.attempts?.some((attempt: any) => {
                                const examStartTime = attempt.timestamp
                                return (
                                  examStartTime > currentTime ||
                                  (examStartTime <= currentTime && examStartTime + duration * 60 > currentTime)
                                )
                              })
                              const overallPassed = exam?.attempts?.some((attempt: any) => {
                                const attemptBatchExam = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                                  ? batchwiseExams?.[selectedBatch]?.exams.find(
                                      (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                                    )
                                  : null

                                return attemptBatchExam
                                  ? attemptBatchExam.passed === true &&
                                      phasewiseAttendaces[attempt.link.split("/").pop()] &&
                                      attemptBatchExam.attempted_live === true &&
                                      attemptBatchExam.submitted === true
                                  : false
                              })
                              const overallFailed = exam?.attempts?.every((attempt: any) => {
                                const attemptBatchExam = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                                  ? batchwiseExams?.[selectedBatch]?.exams.find(
                                      (batchExam: any) => batchExam.exam_id === attempt.link.split("/").pop()
                                    )
                                  : null
                                const isOngoing =
                                  attempt.timestamp <= currentTime && attempt.timestamp + duration * 60 > currentTime
                                const isUpcoming = attempt.timestamp > currentTime
                                return attemptBatchExam
                                  ? attemptBatchExam.passed === false &&
                                      attemptBatchExam.attempted_live === true &&
                                      attemptBatchExam.submitted === true &&
                                      // !isOngoing &&
                                      // !isUpcoming &&
                                      phasewiseAttendaces[attempt.link.split("/").pop()]
                                  : false
                              })
                              const attemptsOutsideBatchRange =
                                exam?.attempts[selectedAttempts[exam.id]]?.timestamp < studentBatchStartTimestamp ||
                                exam?.attempts[selectedAttempts[exam.id]]?.timestamp > studentBatchEndtimestamp
                              return (
                                <div
                                  key={exam?.id}
                                  onClick={() => setExamType(exam.type)}
                                  className="bg-[#F9F9F9] max-h-[231px]  rounded-[8px] p-4 flex flex-col gap-4 mb-[9px]"
                                >
                                  <div className="font-inter font-semibold text-[#333333] flex justify-between items-center">
                                    <div className="flex text-[14px]">
                                      <p>{exam.type === "unit" ? `Exam ${exam_index + 1}` : `Milestone Exam`}</p>
                                      <p className="flex pl-[6px]">
                                        {collapsedState[exam.id] ? (
                                          <p className="text-[#646464] font-normal">Result</p>
                                        ) : (
                                          <p>
                                            {/* {!isOngoing ? ( */}
                                            {overallPassed ? (
                                              <p className="text-[#07B42D] font-normal">Passed</p>
                                            ) : overallFailed ? (
                                              <p className="text-[#DC2626] font-normal">Failed</p>
                                            ) : attemptsOutsideBatchRange ? (
                                              <></>
                                            ) : (
                                              <></>
                                            )}
                                          </p>
                                        )}
                                      </p>
                                    </div>

                                    {/* <button onClick={() => toggleCollapse(exam?.id)} key={exam.id}> */}
                                    <button
                                      onClick={() => {
                                        setCollapsedState((prev) => ({
                                          ...prev,
                                          [exam.id]: !prev[exam.id], // Toggle the collapsed state for this exam
                                        }))
                                      }}
                                      key={exam.id}
                                    >
                                      <div className={`${collapsedState[exam.id] ? "rotate-0" : "-rotate-180"}`}>
                                        <DropdownArrow
                                          className={`text-[#646464] w-4 h-4 font-medium transition-transform cursor-pointer`}
                                          key={exam?.id}
                                        />
                                      </div>
                                    </button>
                                  </div>
                                  {!collapsedState[exam.id] && (
                                    <div className="gap-4">
                                      <div className="flex gap-1">
                                        {exam?.attempts?.map((attempt: any, attempt_index: number) => (
                                          <Button
                                            key={attempt_index}
                                            className={`w-auto border-0 text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${
                                              // selectedAttempt === attempt_index
                                              selectedAttempts[exam.id] === attempt_index
                                                ? "bg-[#162456] text-white"
                                                : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"
                                            }`}
                                            onClick={() => {
                                              handleTabChange(`${attempt_index + 1}`)
                                              // setSelectedAttempt(attempt_index)
                                              setSelectedAttempts((prev) => ({
                                                ...prev,

                                                [exam.id]: attempt_index,
                                              }))
                                            }}
                                          >
                                            {`Attempt ${attempt_index + 1}`}
                                          </Button>
                                        ))}
                                      </div>
                                      <div className="pt-4">
                                        <ModuleCard
                                          className={`relative p-0 ${exam.type === "unit" ? "" : "bg-[#333333]"}`}
                                        >
                                          {exam.type !== "unit" && (
                                            <div className="absolute inset-0 z-0 flex justify-between overflow-hidden rounded-lg">
                                              <div className="absolute top-0 left-[220px] z-0">
                                                <HomePageGradient />
                                              </div>
                                              <div className="absolute top-0 left-[295px] z-0">
                                                <HomePageGradient />
                                              </div>
                                            </div>
                                          )}
                                          <div className="flex justify-between items-center pl-[12px] pt-[12px] z-[10]">
                                            {exam.type === "unit" ? (
                                              <p className="text-[10px] text-[#03249A] font-semibold">{`Exam ${exam_index + 1}`}</p>
                                            ) : (
                                              <div className="bg-[#FBBF24] rounded-[2px] ">
                                                <p className="text-[10px] text-[#333333] font-semibold px-2 pt-[2px]">
                                                  Milestone Exam
                                                </p>
                                              </div>
                                            )}

                                            <span className="pr-[12px] z-[8]">
                                              {phasewiseAttendaces[
                                                exam?.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                              ] === true ? (
                                                //  &&
                                                // exam.attempts[selectedAttempts[exam.id]].timestamp + duration * 60 <=
                                                //   currentTime
                                                Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                (() => {
                                                  const batchExam = batchwiseExams?.[selectedBatch]?.exams.find(
                                                    (batchExam: any) =>
                                                      batchExam.exam_id ===
                                                      exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                                  )
                                                  if (batchExam) {
                                                    const passed = batchExam.passed

                                                    return passed ? (
                                                      <div className="relative flex items-center">
                                                        <input
                                                          type="checkbox"
                                                          className="rounded-[2px] border-[#D3D3D3] checked:bg-[#00C22B] h-4 w-4"
                                                          checked={true}
                                                          readOnly
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div className="relative flex items-center">
                                                        <input
                                                          type="checkbox"
                                                          className="rounded-[2px] bg-[#DC2626] border-[#D3D3D3] h-4 w-4"
                                                          checked={false}
                                                          readOnly
                                                        />
                                                        <Close className="absolute text-white left-[-4px] p-1 z-2" />
                                                      </div>
                                                    )
                                                  }
                                                  return null
                                                })()
                                              ) : exam?.attempts[selectedAttempts[exam.id]]?.timestamp > currentTime ||
                                                (exam?.attempts[selectedAttempts[exam.id]]?.timestamp <= currentTime &&
                                                  exam?.attempts[selectedAttempts[exam.id]]?.timestamp + duration * 60 >
                                                    currentTime &&
                                                  !exam?.attempts[selectedAttempts[exam.id]]?.submitted) ? (
                                                <div className="relative flex items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="rounded-[2px] border-[#D3D3D3] h-4 w-4"
                                                    checked={false}
                                                    readOnly
                                                  />
                                                </div>
                                              ) : attemptsOutsideBatchRange ? (
                                                <div className="relative flex items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="rounded-[2px] border-[#D3D3D3] h-4 w-4"
                                                    checked={false}
                                                    readOnly
                                                  />
                                                </div>
                                              ) : (
                                                <div className="relative flex items-center">
                                                  <input
                                                    type="checkbox"
                                                    className="rounded-[2px] bg-[#DC2626] border-[#D3D3D3] h-4 w-4"
                                                    checked={false}
                                                    readOnly
                                                  />
                                                  <Close className="absolute text-white left-[-4px] p-1 z-2" />
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                          <div className="flex pl-[12px] z-10">
                                            <p
                                              className={`flex text-[14px] text-[#333333] font-semibold ${exam.type === "unit" ? "" : "text-white"}`}
                                            >
                                              {exam?.name}
                                            </p>
                                          </div>

                                          {tab && exam.attempts[selectedAttempts[exam.id]] && (
                                            <p
                                              className={`text-[12px] font-medium text-[#646464] pl-[12px] z-10 ${exam.type === "unit" ? "" : "text-white"}`}
                                            >
                                              {(() => {
                                                const selectedAttemptData = exam.attempts[selectedAttempts[exam.id]]
                                                const date = new Date(selectedAttemptData.timestamp * 1000)
                                                return `${date.getDate()} ${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()} | ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}`
                                              })()}
                                            </p>
                                          )}
                                          <div className="flex pt-[6px] justify-between items-center z-10">
                                            {tab && exam.attempts[selectedAttempts[exam.id]] && (
                                              <>
                                                <p className="flex font-medium text-[12px] text-[#07B42D] pl-[12px]">
                                                  {Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                  batchwiseExams?.[selectedBatch]?.exams.find(
                                                    (batchExam: any) =>
                                                      batchExam.exam_id ===
                                                      exam.attempts[selectedAttempts[exam.id]]?.link.split("/").pop()
                                                  ) ? (
                                                    (() => {
                                                      const currentBatchExam = batchwiseExams?.[
                                                        selectedBatch
                                                      ]?.exams.find(
                                                        (batchExam: any) =>
                                                          batchExam.exam_id ===
                                                          exam.attempts[selectedAttempts[exam.id]]?.link
                                                            .split("/")
                                                            .pop()
                                                      )

                                                      // Check if the exam is ongoing
                                                      const isOngoing =
                                                        exam.attempts[selectedAttempts[exam.id]].timestamp <=
                                                          currentTime &&
                                                        exam.attempts[selectedAttempts[exam.id]].timestamp +
                                                          duration * 60 >
                                                          currentTime

                                                      const passed = currentBatchExam?.passed

                                                      if (
                                                        exam.attempts[selectedAttempts[exam.id]].timestamp > currentTime
                                                      ) {
                                                        return (
                                                          <span
                                                            className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                          >
                                                            Score: N/A
                                                          </span>
                                                        )
                                                      } else if (isOngoing && !currentBatchExam?.submitted) {
                                                        return (
                                                          <span
                                                            className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                          >
                                                            Score: N/A
                                                          </span>
                                                        )
                                                      } else if (
                                                        currentBatchExam.attempted_live === true ||
                                                        currentBatchExam.allow_result === true ||
                                                        currentBatchExam.submitted === true
                                                      ) {
                                                        if (passed === false) {
                                                          return (
                                                            <span className="text-[#DC2626]">
                                                              Failed: {currentBatchExam?.overall_percentage.toFixed(1)}%
                                                            </span>
                                                          )
                                                        } else {
                                                          return (
                                                            <p className="text-[#07B42D] font-medium text-[12px]">
                                                              Score: {currentBatchExam.overall_percentage.toFixed(1)}%
                                                            </p>
                                                          )
                                                        }
                                                      } else if (attemptsOutsideBatchRange) {
                                                        return (
                                                          <span
                                                            className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                          >
                                                            Score: N/A
                                                          </span>
                                                        )
                                                      } else {
                                                        return <span className="text-[#DC2626]">Not Attempted</span>
                                                      }
                                                    })()
                                                  ) : (
                                                    <span
                                                      className={`${exam.type !== "milestone" ? "text-[#646464]" : "text-[#D3D3D3]"}`}
                                                    >
                                                      Score: N/A
                                                    </span>
                                                  )}
                                                </p>
                                                <div className="flex z-[8] mt-[-4px]">
                                                  <Tooltip
                                                    className="d-block"
                                                    title={
                                                      attemptsOutsideBatchRange
                                                        ? "You have not participated in this exam at the scheduled time"
                                                        : resultTooltipTitle(
                                                            exam,
                                                            selectedAttempts,
                                                            selectedBatch,
                                                            batchwiseExams,
                                                            phasewiseAttendaces,
                                                            currentTime,
                                                            duration
                                                          )
                                                    }
                                                  >
                                                    <span className="flex pr-[7px] z-[30]">
                                                      <button
                                                        disabled={
                                                          attemptsOutsideBatchRange ||
                                                          (Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                            batchwiseExams?.[selectedBatch]?.exams.find(
                                                              (batchExam: any) =>
                                                                (batchExam.exam_id ===
                                                                  exam.attempts[selectedAttempts[exam.id]]?.link
                                                                    .split("/")
                                                                    .pop() &&
                                                                  exam.attempts[selectedAttempts[exam.id]].timestamp >
                                                                    currentTime) ||
                                                                (exam.attempts[selectedAttempts[exam.id]].timestamp <=
                                                                  currentTime &&
                                                                  exam.attempts[selectedAttempts[exam.id]].timestamp +
                                                                    duration * 60 >
                                                                    currentTime &&
                                                                  Array.isArray(
                                                                    batchwiseExams?.[selectedBatch]?.exams
                                                                  ) &&
                                                                  !batchwiseExams?.[selectedBatch]?.exams.find(
                                                                    (batchExam: any) =>
                                                                      batchExam.exam_id ===
                                                                      exam.attempts[selectedAttempts[exam.id]]?.link
                                                                        .split("/")
                                                                        .pop()
                                                                  ).submitted)
                                                            )) ||
                                                          // !phasewiseAttendaces[phasewiseCurrentContestId] +
                                                          //   "viewResult"
                                                          !phasewiseAttendaces[
                                                            exam.attempts[selectedAttempts[exam.id]].link
                                                              .split("/")
                                                              .pop() + "viewResult"
                                                          ]
                                                        }
                                                        className="bg-new-solid-white z-[30] w-[122px] disabled:text-[#D3D3D3]  text-[12px] border-[#D3D3D3] font-inter text-[#646464] font-medium border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                        onClick={() =>
                                                          handleResultClick(
                                                            exam.attempts[selectedAttempts[exam.id]]?.link
                                                              .split("/")
                                                              .pop()
                                                          )
                                                        }
                                                      >
                                                        {/* <div className="flex items-center w-full"> */}
                                                        {selectedContest ===
                                                          exam.attempts[selectedAttempts[exam.id]]?.link
                                                            .split("/")
                                                            .pop() && loading ? (
                                                          <Loader xs={true} height={"100%"} />
                                                        ) : (
                                                          "View Result"
                                                        )}
                                                        {/* </div> */}
                                                      </button>
                                                    </span>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title={
                                                      attemptsOutsideBatchRange
                                                        ? "You are not supposed to attempt this exam"
                                                        : tooltipTitle(
                                                            exam,
                                                            selectedAttempts,
                                                            selectedBatch,
                                                            batchwiseExams,
                                                            currentTime,
                                                            duration
                                                          )
                                                    }
                                                  >
                                                    <span className="pr-[12px] flex z-[30]">
                                                      <button
                                                        className="bg-new-solid-white text-[12px] disabled:text-[#D3D3D3] border-[#D3D3D3] font-inter text-[#646464] font-medium w-[122px] border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                        disabled={
                                                          attemptsOutsideBatchRange ||
                                                          exam.attempts[selectedAttempts[exam.id]].timestamp >
                                                            currentTime ||
                                                          (exam.attempts[selectedAttempts[exam.id]].timestamp <=
                                                            currentTime &&
                                                            exam.attempts[selectedAttempts[exam.id]].timestamp +
                                                              duration * 60 >
                                                              currentTime &&
                                                            Array.isArray(batchwiseExams?.[selectedBatch]?.exams) &&
                                                            batchwiseExams?.[selectedBatch]?.exams.find(
                                                              (batchExam: any) =>
                                                                batchExam.exam_id ===
                                                                exam.attempts[selectedAttempts[exam.id]]?.link
                                                                  .split("/")
                                                                  .pop()
                                                            ).submitted === true)
                                                        }
                                                        onClick={() =>
                                                          checkPhasewiseExamEligibility(
                                                            exam.attempts[selectedAttempts[exam.id]]?.link
                                                              .split("/")
                                                              .pop(),
                                                            false
                                                          )
                                                        }
                                                      >
                                                        {exam.attempts[selectedAttempts[exam.id]]?.timestamp +
                                                          duration * 60 >
                                                          currentTime &&
                                                        batchwiseExams?.[selectedBatch]?.exams[
                                                          exam.attempts[selectedAttempts[exam.id]]
                                                        ]?.submitted === true
                                                          ? "Reattempt"
                                                          : (exam.attempts[selectedAttempts[exam.id]]?.timestamp >
                                                                currentTime ||
                                                                (exam.attempts[selectedAttempts[exam.id]]?.timestamp <
                                                                  currentTime &&
                                                                  exam.attempts[selectedAttempts[exam.id]]?.timestamp +
                                                                    duration * 60 >=
                                                                    currentTime)) &&
                                                              !batchwiseExams?.[selectedBatch]?.exams[
                                                                exam.attempts[selectedAttempts[exam.id]]
                                                              ]?.submitted
                                                            ? "Start Exam"
                                                            : !phasewiseAttendaces[
                                                                  exam.attempts[selectedAttempts[exam.id]]?.link
                                                                    .split("/")
                                                                    .pop()
                                                                ]
                                                              ? attemptsOutsideBatchRange
                                                                ? "Start Exam"
                                                                : " Practice Exam"
                                                              : "Reattempt"}
                                                      </button>
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                                {/* </div> */}
                                              </>
                                            )}
                                          </div>
                                        </ModuleCard>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        )
                      })
                  ) : (
                    <>
                      <div className="flex flex-col">
                        {mocks.length > 0 ? (
                          mocks.map((mockTest: any, mock_index: number) => {
                            const mockExamData = Array.isArray(batchwiseExams?.[selectedBatch]?.exams)
                              ? batchwiseExams?.[selectedBatch]?.exams.find(
                                  (batchExam: any) => batchExam.exam_id === mockTest?.link.split("/").pop()
                                )
                              : null

                            const passed = mockExamData?.passed && mockExamData?.isMock
                            const failed =
                              mockExamData?.passed === false &&
                              mockExamData?.isMock &&
                              mockExamData?.total_obtained_score !== "N/A"

                            return (
                              <div
                                key={mock_index}
                                className="bg-[#F9F9F9] cursor-pointer max-h-[231px] rounded-[8px] p-4 flex flex-col gap-4 mb-[9px]"
                              >
                                <div className="font-inter font-semibold text-[#333333] flex justify-between items-center">
                                  <div className="flex">
                                    <p className="text-[14px] font-semibold">{`Exam ${mock_index + 1}`}</p>
                                    <div className="flex pl-[6px]">
                                      {!collapsedState[mockTest.id] ? (
                                        passed ? (
                                          <p className="text-[#07B42D] font-normal">Passed</p>
                                        ) : failed ? (
                                          <p className="text-[#DC2626] font-normal">Failed</p>
                                        ) : (
                                          <p className="text-[#DC2626] font-normal">Not Attempted</p>
                                        )
                                      ) : (
                                        <p className="text-[14px] font-normal text-[#646464]">Result</p>
                                      )}
                                    </div>
                                  </div>
                                  <button
                                    onClick={() => {
                                      setCollapsedState((prev) => ({
                                        ...prev,
                                        [mockTest.id]: !prev[mockTest.id], // Toggle the collapsed state for this exam
                                      }))
                                    }}
                                    key={mockTest.id}
                                  >
                                    <div className={`${collapsedState[mockTest.id] ? "rotate-0" : "-rotate-180"}`}>
                                      <DropdownArrow
                                        className={`text-[#646464] w-4 h-4 font-medium transition-transform cursor-pointer`}
                                        key={mockTest?.id}
                                      />
                                    </div>
                                  </button>
                                </div>
                                {!collapsedState[mockTest.id] && (
                                  <ModuleCard className="relative p-0 z-0">
                                    <p className="text-[10px] text-[#03249A] font-semibold pt-[12px] pl-[12px]">{`Exam ${mock_index + 1}`}</p>
                                    <div className="flex">
                                      <p className="flex text-[14px] text-[#333333] font-semibold pl-[12px]">
                                        {mockTest?.name}
                                      </p>
                                    </div>
                                    <div className="flex pt-[22px] justify-between items-center z-10">
                                      <p className="flex font-medium text-[12px] pl-[12px]">
                                        {passed === true ? (
                                          <p className="text-[#07B42D] text-[12px]">Passed</p>
                                        ) : failed ? (
                                          <p className="text-[#DC2626] text-[12px]">Failed</p>
                                        ) : (
                                          <p className="text-[#DC2626] text-[12px]">Not Attempted</p>
                                        )}
                                      </p>
                                      <Tooltip title={mockTooltipTitle}>
                                        <span className="pr-[12px] flex">
                                          <Button
                                            small
                                            className="bg-new-solid-white text-[12px] border-[#D3D3D3] font-inter text-[#646464] font-medium w-[122px] border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                            disabled={
                                              parseInt(batchwiseExams?.[selectedBatch[mock_index]]?.start_time) * 1000 -
                                                Date.now() >
                                                30 * 60 * 1000 ||
                                              (batchwiseExams?.[selectedBatch[mock_index]]?.start_time <
                                                batchwiseExams?.[selectedBatch[mock_index]]
                                                  ?.last_submission_timestamp &&
                                                batchwiseExams?.[selectedBatch[mock_index]].start_time > currentTime) ||
                                              phasewiseAttendaces[phasewiseCurrentContestId]
                                            }
                                            onClick={() =>
                                              checkPhasewiseExamEligibility(mockTest?.link.split("/").pop(), true)
                                            }
                                          >
                                            Start Exam
                                          </Button>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </ModuleCard>
                                )}
                              </div>
                            )
                          })
                        ) : (
                          <div className="flex justify-center items-center h-[300px]">
                            <p className="text-[14px] text-[#333333]">No Mock Exams Found</p>
                          </div>
                        )}
                      </div>
                    </>
                  )
                ) : !hasPhasewiseExams && hasPreviousExams && selectedExam === "previous" ? (
                  <div className="flex">
                    <div className="w-full">
                      {tableData?.rows?.filter((row: any) => row.batches.includes(selectedBatch)).length === 0 ? (
                        <div className="flex justify-center items-center h-[300px]">
                          <p className="text-[14px] text-[#333333]">No previous exams found</p>
                        </div>
                      ) : (
                        tableData?.rows
                          ?.filter((row: any) => row.batches.includes(selectedBatch))
                          ?.map((exam: any, index: number) => {
                            const isStartDisabled = parseInt(exam.start_time) * 1000 - Date.now() > 30 * 60 * 1000
                            const isResultDisabled = !attendances[`${exam.contest_id}viewResult`]
                            const ts = parseInt(exam?.start_time) * 1000
                            const isLinkDisabled = ts - Date.now() > 30 * 60 * 1000

                            return (
                              <div
                                key={exam.contest_id}
                                onClick={() => setExamType("unit")}
                                className="bg-[#F9F9F9] max-h-[231px]  rounded-[8px] p-4 flex flex-col gap-4 mb-[9px]"
                              >
                                {/* Exam Header */}

                                <div className="font-inter font-semibold text-[#333333] flex justify-between items-center">
                                  <div className="flex text-[14px]">
                                    <p>Exam {index + 1}</p>
                                    {collapsedState[exam.contest_id] ? (
                                      <p className="text-[#646464] font-normal pl-[6px]">Result</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  {/* <button onClick={() => toggleCollapse(exam.contest_id)}> */}
                                  <button
                                    onClick={() => {
                                      setCollapsedState((prev) => ({
                                        ...prev,
                                        [exam.contest_id]: !prev[exam.contest_id], // Toggle the collapsed state for this exam
                                      }))
                                    }}
                                    key={exam.contest_id}
                                  >
                                    <div className={`${collapsedState[exam.contest_id] ? "rotate-0" : "-rotate-180"}`}>
                                      <DropdownArrow
                                        className={`text-[#646464] w-4 h-4 font-medium transition-transform cursor-pointer`}
                                        key={exam?.contest_id}
                                      />
                                    </div>
                                  </button>
                                </div>

                                {!collapsedState[exam.contest_id] && (
                                  <ModuleCard className="relative p-0 z-0">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[10px] text-[#03249A] font-semibold pl-[12px] pt-[12px]">{`Exam ${index + 1}`}</p>
                                      <div className="flex pr-[12px]">
                                        <span>
                                          {attendances[exam?.contest_id] === true ? (
                                            <div className="relative flex items-center">
                                              <input
                                                type="checkbox"
                                                className="rounded-[2px] border-[#D3D3D3] checked:bg-[#00C22B] h-4 w-4"
                                                checked={true}
                                                readOnly
                                              />
                                            </div>
                                          ) : (
                                            <div className="relative flex items-center">
                                              <input
                                                type="checkbox"
                                                className="rounded-[2px] bg-[#DC2626] border-[#D3D3D3] h-4 w-4 "
                                                checked={false}
                                                readOnly
                                              />
                                              <Close className="absolute text-white left-[-4px] p-1 z-2" />
                                            </div>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex flex-col pb-[10px]">
                                      {/* Exam Details */}

                                      <p className="pl-[12px] text-[14px] font-semibold text-[#333333]">{exam.name}</p>
                                      <p className="text-[12px] text-[#646464] px-[12px]">
                                        {format(new Date(exam.start_time * 1000), "dd MMM yyyy")} |{" "}
                                        {format(new Date(exam.start_time * 1000), "h:mm a")}
                                      </p>

                                      {/* Action Buttons */}
                                      <div className="flex justify-between items-center">
                                        <p className="text-[#07B42D] font-medium text-[12px] pl-[12px] pt-[6px]">
                                          {typeof exam.overall_percentage === "number" ? (
                                            `Score: ${exam.overall_percentage}%`
                                          ) : (
                                            <span className="text-[#DC2626]">Not Attempted</span>
                                          )}
                                        </p>

                                        <div className="flex">
                                          <Tooltip
                                            className="d-block"
                                            title={
                                              attendances[exam?.contest_id + "viewResult"]
                                                ? ""
                                                : activeButtonType === 1
                                                  ? "You have not participated in this exam at the scheduled time"
                                                  : // : "This link will be activated after the exam"
                                                    "Error in exam rendering"
                                            }
                                          >
                                            <span className="mr-[7px] flex">
                                              <button
                                                disabled={!attendances[exam?.contest_id + "viewResult"]}
                                                className="bg-new-solid-white text-[12px] disabled:text-[#D3D3D3] border-[#D3D3D3] font-inter w-[122px] text-[#646464] font-medium border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                onClick={() => handleResultClick(exam?.contest_id)}
                                              >
                                                {selectedContest === exam?.contest_id && loading ? (
                                                  <Loader xs={true} height={"100%"} />
                                                ) : (
                                                  "View Result"
                                                )}
                                              </button>
                                            </span>
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              exam?.["submitted_due_to_proctoring"]
                                                ? "Exam submitted automatically due to tab switching"
                                                : isLinkDisabled
                                                  ? "Contest has not started yet"
                                                  : "Attempt now"
                                            }
                                          >
                                            <span className="mr-[12px] flex">
                                              <button
                                                className="bg-new-solid-white text-[12px] disabled:text-[#D3D3D3] border-[#D3D3D3] w-[122px] font-inter text-[#646464] font-medium  border-[0.5px] rounded-[4px] px-4 py-[5px]"
                                                disabled={
                                                  isLinkDisabled
                                                  // exam?.start_time < exam?.last_submission_timestamp ||
                                                  // loading
                                                }
                                                onClick={() => checkExamEligibility(exam?.contest_id)}
                                              >
                                                {exam?.submitted === true && attendances[exam?.contest_id]
                                                  ? "Reattempt"
                                                  : !exam?.submitted && attendances[exam?.contest_id]
                                                    ? "Start Exam"
                                                    : !attendances[exam?.contest_id]
                                                      ? // ? "Start Exam"
                                                        " Practice Exam"
                                                      : "Reattempt"}
                                              </button>
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </div>
                                  </ModuleCard>
                                )}
                              </div>
                            )
                          })
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[300px]">
                    <p className="text-[14px] text-[#333333]">No exams found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-center">
          <ResultModal
            result={result}
            onClose={handleCloseModal}
            resultType={resultType}
            leaderboardData={leaderboardData}
            questionwiseSubmissions={questionwiseSubmissions}
            contestId={selectedContest || ""}
            questionId={selectedQuestionId || ""}
            examType={examType}
            passed={passed}
            oldFlowDate={startTime}
            ongoingStates={ongoingStates}
            setShowCelebration={setShowCelebration}
            showCelebration={showCelebration}
          />
        </div>
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      {showModal && <NewfeedbackModal saveFeedback={saveFeedback} message="Exam" />}
    </DashboardLayoutv2>
  )
}
