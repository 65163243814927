import StarRating from "core/components/StarRating"
import { STR_MENTOR_ALLOTED } from "core/constants/strings"
import Avatar from "react-avatar"
import { LinkedInIcon } from "core/constants/svgs"
import Alumni from "assets/svgs/Alumni"
import { useState } from "react"
import { cn } from "core/lib/utils"
import ChevronUp from "assets/svgs/ChevronUp"
import ChevronDown from "assets/svgs/ChevronDown"
import HomePageGradient from "core/components/new/HomePageGradient"
import { Button } from "core/components/v2/Button"

export default function MentorCard({
  selectionModalOpen,
  index,
  selectedMentors,
  mentor,
  alloted,
  handleMentorSelection,
  isOpen,
  fetchTempMentorAvailability,
  session_id,
  setBookSlotModal,
}: any) {
  const [isHovered, setIsHovered] = useState(false)
  const [showTechStack, setShowTechStack] = useState(false)
  return (
    <div
      className={cn(
        "relative border-[#D3D3D3] border-[0.5px] rounded-[8px] over",
        showTechStack ? "h-fit" : "h-[100px]",
        !selectionModalOpen && "shadow-[0px_3px_7px_rgba(0,0,0,0.1)]"
      )}
    >
      {!selectionModalOpen && (
        <>
          <div className="absolute top-0 left-[220px]">
            <HomePageGradient className={"#E0E8ED"} />
          </div>
          <div className="absolute top-0 left-[300px]">
            <HomePageGradient className={"#E0E8ED"} />
          </div>
        </>
      )}

      {mentor?.is_alumni && <Alumni className="absolute top-2" setIsHovered={setIsHovered} />}
      {isHovered && (
        <div className="absolute left-[80px] top-[-16px] flex h-[28px] w-[110px] items-center justify-center bg-black">
          <h5 className="text-white">Bosscoder Alumni</h5>
        </div>
      )}

      <div className="mx-6 my-4 flex gap-4">
        <Avatar className="shrink-0 rounded-sm object-cover" src={mentor?.photo} name={mentor?.name} size="67" />
        <div className="flex w-full flex-1 justify-between">
          <div className="flex flex-col w-full">
            <div className="flex justify-between w-full items-start">
              <div>
                <div className="flex gap-[12px] items-center">
                  <p className="text-[14px] text-[#333] font-[600]">{mentor?.name} </p>

                  <StarRating
                    rating={Math.round(mentor?.rating * 10) / 10}
                    total={5}
                    className="text-[#FBBF24] text-[14px] font-[500]"
                  />
                </div>

                <p className="text-[12px] font-[400] flex">
                  {mentor?.current_company} | {mentor?.experience}yrs |{" "}
                  {index < 3 && <p className=" text-green-400 text-[12px] font-[400] ml-2"> Recommended</p>}
                </p>

                <div className="flex w-full gap-[4px] justify-between items-center my-[4px]">
                  <a href={mentor?.linkedin} target="_blank" rel="noreferrer">
                    <LinkedInIcon className="h-5 w-5" />
                  </a>
                </div>
              </div>

              {isOpen === "temp" ? (
                <button
                  // success={true}
                  // selected={selectedMentors?.includes(index)}
                  onClick={() => {
                    fetchTempMentorAvailability(false, session_id?.id, mentor?.email)
                    setBookSlotModal(mentor)
                  }}
                  className="shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] text-[14px] font-[500] text-[#646464] w-[126px] h-[35px] border-[0.5px] border-[#D3D3D3] px-[16px] rounded-[4px] flex justify-center items-center hover:bg-[#D3D3D3]"
                >
                  View Slots
                </button>
              ) : alloted ? (
                <div className="h-full flex flex-col justify-between items-end">
                  <p className="text-[#07B42D] text-[14px] font-[600]">{STR_MENTOR_ALLOTED}</p>
                  <button
                    className="hover:bg-[#F9F9F9] rounded-sm z-20"
                    onClick={() => setShowTechStack(!showTechStack)}
                  >
                    {showTechStack ? <ChevronUp className="size-[24px]" /> : <ChevronDown className="size-[24px]" />}
                  </button>
                </div>
              ) : (
                <div className="h-full flex flex-col justify-between items-end">
                  <Button
                    // selected={selectedMentors?.includes(index)}
                    border={"thin"}
                    variant={"ternary"}
                    onClick={() => handleMentorSelection(index)}
                    className={cn(
                      "w-[125px] px-0 h-[35px]  focus:text-v2-gray-500",
                      selectedMentors?.includes(index)
                        ? "bg-[#07B42D] focus:text-white hover:bg-[#07B42D] text-white focus:bg-[#07B42D] hvoer:bg-[#07B42D]"
                        : "focus:bg-white"
                    )}
                  >
                    {selectedMentors?.includes(index) ? "Selected" : "Select Mentor"}
                  </Button>

                  <button
                    className="hover:bg-[#F9F9F9] rounded-sm z-20"
                    onClick={() => setShowTechStack(!showTechStack)}
                  >
                    {showTechStack ? <ChevronUp className="size-[24px]" /> : <ChevronDown className="size-[24px]" />}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showTechStack && mentor?.tech_stack?.length > 0 && (
        <div className="flex flex-wrap gap-[4px] p-[16px] mx-6 my-4 bg-[#F9F9F9] rounded-[8px]">
          {mentor?.tech_stack?.map((tech: any, i: number) => (
            <>
              <div key={i} className="flex gap-[12px] items-center">
                <button className="h-[29px] px-[16px]  rounded-[4px] text-[12px] font-[400] bg-[#333] text-white">
                  {tech}{" "}
                </button>
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  )
}
