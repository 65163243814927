import Cross from "assets/svgs/Cross"
import ReferralModal from "./ReferralModal"
import ReferralTableTabGroup from "./ReferralTableTabGroup"
import { useEffect, useState } from "react"
import { CustomScroll } from "react-custom-scroll"

const ReferralRewardsModal = ({ isOpen, onClose, student }: any) => {
  const activeTableButtons = ["Referrer", "Referee"]
  const [activeTableButtonType, setActiveTableButtonType] = useState<string>("Referrer")
  const [courseType, setCourseType] = useState<string>("")
  const [referralCourseType, setReferralCourseType] = useState<string>(student?.program_type || "")
  const courses = {
    Accelerator: "₹3,000",
    Transformer: "₹5,000",
    Evolve: "₹6,000",
    "Data Science": "₹5,000",
    "Data Analytics": "₹4,000",
    "Data Engineering": "₹5,000",
  }

  useEffect(() => {
    if (student?.program_type) {
      setReferralCourseType(student?.program_type)
      setCourseType(student?.program_type)
    } else {
      const batch = student?.batch
      let program_type = "Evolve"

      if (batch?.startsWith("B")) {
        program_type = "Transformer"
      } else if (batch?.startsWith("AC") || batch === "Accelerator") {
        program_type = "Accelerator"
      } else if (batch === "DSML") {
        program_type = "Data Analytics"
      } else if (batch?.startsWith("EV")) {
        program_type = "Evolve"
      } else if (batch?.startsWith("DE")) {
        program_type = "Data Engineering"
      }

      setReferralCourseType(program_type)
      console.log(student, "kkkk")
      setCourseType(program_type)
    }
  }, [])

  function changeActiveTableButtonType(index: number) {
    const tabType = ["Referrer", "Referee"][index]
    setActiveTableButtonType(tabType)
  }
  return (
    <div className="p-[24px] bg-[#F9F9F9]">
      <div className="flex justify-between py-2">
        <p className="text-[18px] font-[600] text-[#162456] mb-1">Referral Rewards</p>
      </div>
      <div className="bg-white  border-[#D3D3D3] border-[0.5px] rounded-[6px] shadow-md">
        <div className="mx-[16px]">
          <p className="text-[var(--color-shades-bw-gray-600, #333)] text-[16px] font-[500] my-[16px]">
            What is Referral cash back Structure ?
          </p>
          <ReferralTableTabGroup
            buttons={activeTableButtons}
            active={activeTableButtons.indexOf(activeTableButtonType)}
            onChange={changeActiveTableButtonType}
          />
        </div>
        {activeTableButtonType === "Referrer" && (
          <div className="bg-white p-4 rounded-md shadow-md">
            <table className="w-full border border-gray-300 border-collapse">
              <thead>
                <tr className="text-sm text-gray-600 border-b border-gray-300">
                  <th className="py-2 px-4 text-left">Course Type</th>
                  <th className="py-2 px-4 text-right">₹ Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-3 px-4 border-b border-gray-300">
                    <div className="bg-gray-100 text-gray-800 py-2 px-3 rounded-md shadow-sm">{referralCourseType}</div>
                  </td>
                  <td className="py-3 px-4 border-b border-gray-300">
                    <div className="bg-gray-100 text-gray-900 font-semibold py-2 px-3 rounded-md text-right shadow-sm">
                      {courses[referralCourseType as keyof typeof courses]}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {activeTableButtonType === "Referee" && (
          <div className="bg-white p-4 rounded-md shadow-md">
            <table className="w-full border border-gray-300 border-collapse bg-white rounded-md">
              <thead>
                <tr className="text-sm text-gray-600 border-b border-gray-300">
                  {Object.keys(courses).map((courseType) => (
                    <th key={courseType} className="py-2 px-4 font-medium text-center">
                      {courseType}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.values(courses).map((amount, index) => (
                    <td key={index} className="p-2 border-b border-gray-300">
                      <div className="bg-gray-200 text-gray-800 text-center py-2 px-4 rounded-md shadow-md w-full max-w-xs mx-auto">
                        {amount}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReferralRewardsModal
