import { API_V4_SIGNUP } from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import { UserSignUp } from "domain/model/UserSignup"
import UserSignUpDataSource from "data/dataSource/Admin/UserSignUpDataSourse"
import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"

export class UserSignUpAPIDataSourceImpl implements UserSignUpDataSource {
  async signup(userSignUp: UserSignUp, auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_SIGNUP), {
        course: userSignUp.courseType,
        selected_campaigns: userSignUp.selected_campaigns,
        selected_programs: userSignUp.selected_programs,
        program: userSignUp.programType,
        company_name: userSignUp.company_name,
        batch: userSignUp.batch,
        onboardingManagerId: userSignUp.onboardingManagerId,
        milestoneSessionMentor: userSignUp.milestoneSessionMentor,
        role: userSignUp.role,
        email: userSignUp.email,
        name: userSignUp.fullName,
        phone: userSignUp.phoneNumber,
        country_code: userSignUp.countryCode,
        country: userSignUp.country,
        state: userSignUp.state,
        experience: userSignUp.yearsOfExperience,
        fees: userSignUp.fees,
        walk_through_date: userSignUp.walkThroughDate,
        isAlumni: userSignUp.isAlumni,
        isinfluencer: userSignUp.isinfluencer,
        isMentor: userSignUp.isMentor,
        bandwidth: userSignUp.bandwidth,
        standard_discount: userSignUp.standardDiscount,
        seat_booking_amount: userSignUp.seat_booking_amount,
        paymentId_of_seat_booking: userSignUp.paymentId_of_seat_booking,
        seat_booking_received_date: userSignUp.seat_booking_received_date,
        payment_submethod: userSignUp.payment_submethod,
        unique_code: userSignUp.unique_code,
        hostname_and_links: userSignUp.hostname_and_links,
        photo: userSignUp.photo,
        team: userSignUp.team,
        id_token: auth.id_token,
        is_superadmin: userSignUp.isSuperAdmin,
        isTestUser: userSignUp.isTestUser,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
