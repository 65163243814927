import { Tooltip } from "@mui/material"
import Checkbox from "assets/svgs/v2/Checkbox"
import CheckboxSuccess from "assets/svgs/v2/checkboxSuccess"
import Loader from "core/components/Loader"
import Button from "core/components/new/Button"
import { CrossIcon } from "core/constants/svgs"
import React from "react"
import { CustomScroll } from "react-custom-scroll"

export default function ContestModal({ loading, data, open, onClose, onClick }: any) {
  const [enterContestDisabled, setEnterContestDisabled] = React.useState(true)
  const [tab, setTab] = React.useState<any>("general")
  const [isChecked, setIsChecked] = React.useState<Boolean>(false)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked)
  }
  const handleTabChange = (newTab: string) => {
    setTab(newTab)
  }
  const getQuestionType = (questionList: any[]) => {
    if (questionList === data.mcq_lis) return "Multiple Choice Questions"
    if (questionList === data.question_lis) return "Coding Problem Details"
    if (questionList === data.subjective_lis) return "Subjective Questions"
    if (questionList === data.casestudy_lis) return "Casestudy Questions"
    return ""
  }

  React.useEffect(() => {
    if (open) {
      const checkContestStatus = () => {
        const currTimestamp = Math.floor(Date.now() / 1000)
        const startTimestamp = parseInt(data?.start_time)
        const duration = parseInt(data?.duration)
        const endTimestamp = startTimestamp + duration * 60
        const passing_criteria = parseInt(data?.passing_criteria)
        const isContestRunning =
          (currTimestamp >= startTimestamp && currTimestamp <= endTimestamp) || currTimestamp > endTimestamp

        const isLiveContest = data?.type === "live"

        if (isLiveContest) {
          setEnterContestDisabled(!isContestRunning)
        } else {
          setEnterContestDisabled(!(currTimestamp >= startTimestamp))
        }
      }

      checkContestStatus()

      const interval = setInterval(checkContestStatus, 1000)

      return () => clearInterval(interval)
    }
  }, [open, data])

  React.useEffect(() => {
    handleTabChange("general")
  }, [])

  if (!open) return null

  return (
    <div className="fixed inset-0 z-20">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          {loading ? (
            <Loader />
          ) : (
            <div className="m-auto flex w-[475px] h-[538px] transform flex-col gap-4 rounded-lg bg-new-solid-white p-6 text-new-neutral">
              <div className="flex justify-between">
                <h3 className="text-new-neutral-dark">Challenge Details</h3>
                {/* <button type="button" onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button> */}
              </div>
              <div className="flex flex-col space-y-4 ">
                <div className="flex gap-[8px] flex-wrap ">
                  <button
                    className={`text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${tab === "general" ? "bg-[#162456] text-white" : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"}`}
                    onClick={() => handleTabChange("general")}
                  >
                    General Information
                  </button>
                  <button
                    className={`text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${tab === "question" ? "bg-[#162456] text-white" : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"}`}
                    onClick={() => handleTabChange("question")}
                  >
                    Questions
                  </button>
                  <button
                    className={`text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${tab === "passing_criteria" ? "bg-[#162456] text-white" : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"}`}
                    onClick={() => handleTabChange("passing_criteria")}
                  >
                    Passing Criteria
                  </button>
                </div>
                <div className="">
                  {tab === "general" && (
                    <div className="space-y-4">
                      <div className="bg-[#F9F9F9] rounded-[8px] h-[252px] overflow-hidden">
                        <CustomScroll heightRelativeToParent="100%" className="rounded-[4px]">
                          {/* <p className="text-new-neutral-dark font-inter font-medium text-md">General Information</p> */}
                          <div className="p-4">
                            <ol className="space-y-[2ch]">
                              {data?.general_information?.split("\n").map((info: string, index: number) => (
                                <li key={index} className="text-[#333333]">
                                  {index + 1}. {info}
                                </li>
                              ))}
                            </ol>
                          </div>
                        </CustomScroll>
                      </div>
                    </div>
                  )}
                  {tab === "question" && (
                    <div className="space-y-2 text-new-neutral-dark">
                      <p className="text-[#333333] font-inter font-semibold text-[14px]">
                        Time limit: <span> {data?.duration} mins</span>
                      </p>

                      {data && (
                        <div className="space-y-[2ch] font-medium bg-[#F9F9F9] h-[252px] rounded-[8px]">
                          <CustomScroll heightRelativeToParent="100%" className="rounded-[4px]">
                            <div className="p-4">
                              {[data.mcq_lis, data.question_lis, data.subjective_lis, data.casestudy_lis]
                                .filter((questionList) => questionList && questionList.length > 0)
                                .map((questionList, index) => (
                                  <div key={index} className="space-y-[1ch]">
                                    <p className="text-[#1C3474] font-inter font-semibold text-sm mb-[9px]">
                                      {index + 1}. {getQuestionType(questionList)}
                                    </p>
                                    {/* <p className="text-[#646464] font-inter font-normal text-[14px] leading-[21px]">
                                      {getQuestionDescription(questionList)}
                                    </p> */}
                                    <ul className="list-disc pl-8 font-inter text-[14px] text-[#333333] font-normal">
                                      <li>{questionList.length} Questions</li>
                                      <li className="pb-4">
                                        {questionList.reduce(
                                          (total: number, question: any) => total + question.score,
                                          0
                                        )}{" "}
                                        Marks
                                      </li>
                                    </ul>
                                  </div>
                                ))}
                            </div>
                          </CustomScroll>
                        </div>
                      )}

                      {!(
                        data?.mcq_lis?.length > 0 ||
                        data?.question_lis?.length > 0 ||
                        data?.subjective_lis?.length > 0 ||
                        data?.casestudy_lis?.length > 0
                      ) && <p>No questions found</p>}
                    </div>
                  )}
                  {tab === "passing_criteria" && (
                    <div className="space-y-2 bg-[#F9F9F9] rounded-[8px] h-[81px] p-4">
                      <h3 className="text-[#1C3474] font-inter font-semibold text-sm ">
                        1. Maximum Marks:{" "}
                        <span>
                          {data.mcq_lis.reduce((total: number, mcq: any) => total + mcq.score, 0) +
                            data.question_lis.reduce((total: number, question: any) => total + question.score, 0) +
                            data.subjective_lis.reduce((total: number, q: any) => total + q.score, 0) +
                            data.casestudy_lis.reduce((total: number, q: any) => total + q.score, 0)}
                        </span>
                      </h3>
                      <h3 className="text-[#1C3474] font-inter font-semibold text-sm  pb-4">
                        2. Passing Criteria: <span>{data?.passing_criteria}</span>
                      </h3>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center top-[410px] absolute ">
                <div onClick={() => setIsChecked(!isChecked)} className=" mr-[6px] mb-[20px]">
                  {isChecked ? (
                    <input
                      type="checkbox"
                      className="rounded-[2px] cursor-pointer border-[#D3D3D3] checked:bg-[#00C22B] h-4 w-4"
                      checked={true}
                    />
                  ) : (
                    <input type="checkbox" className="rounded-[2px] border-[#D3D3D3] h-4 w-4" checked={false} />
                  )}
                </div>
                <p className="text-[#646464] font-inter font-normal text-sm">
                  I’ve gone through all the general guideline and the contest information.
                </p>
              </div>
              <div className="flex">
                <div className="flex top-[472px] right-[123px] pr-6 absolute">
                  <Button
                    onClick={onClose}
                    className="w-fit text-[#646464] rounded-[4px] px-4 py-[9px] font-medium bg-new-solid-white border-[#D3D3D3] border-[1px] hover:bg-[#001665] hover:text-new-solid-white"
                  >
                    Cancel
                  </Button>
                </div>
                <div className="flex top-[472px] right-0 pr-6 absolute">
                  <Tooltip title={enterContestDisabled ? "Exam has not started yet" : ""} placement="top">
                    <span>
                      <Button
                        onClick={onClick}
                        disabled={enterContestDisabled || !isChecked}
                        className={`w-fit rounded-[4px] px-4 py-[9px] font-medium ${enterContestDisabled || !isChecked ? "cursor-not-allowed" : "bg-[#001665]"}`}
                      >
                        Start Exam
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
