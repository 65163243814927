import MoreOptionsIcon from "assets/svgs/placement/MoreOptions"
import UploadIcon from "assets/svgs/placement/Upload"
import Toggle from "core/components/Toggle"
import { EditIcon, EyeIcon, LinkedInIcon, NoEyeIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import usePlacementConstant from "presentation/Admin/PlacementDashboard/components/PlacementConstants"
import usePlacementListViewModel from "presentation/Admin/PlacementDashboard/components/PlacementListViewModel"
import { useEffect } from "react"
import StatusSelectDropdown from "./StatusSelectDropdown"
import UploadResumeCard from "./popUpCards/UploadResumeCard"

export default function PlacementstudentsListViewModel({ filteredPlacementList }: any) {
  const {
    toast,
    changeToastDetails,
    changeToastVisibility,
    redirectToPlacement,
    handleCheckboxChange,
    selectedIds,
    mockStatus,
    setMockStatus,
    resumeStatus,
    setResumeStatus,
    linkedinStatus,
    setLinkedinStatus,
    handleMockStatusChange,
    handleResumeStatusChange,
    handleLinkedinStatusChange,
    handleUploadResume,
    handlePlacedToggle,
    handleEdit,
    handleMoreOptions,
    rows,
    setRows,
    uploadResumeCardPopup,
    setUploadResumeCardPopup,
    resumeStudentData,
    setResumeStudentData,
    handleResumeSave,
  } = usePlacementListViewModel()

  const { tableHead, statusOptions } = usePlacementConstant()

  useEffect(() => {
    if (Array.isArray(filteredPlacementList)) {
      setRows(filteredPlacementList)
    } else {
      setRows([])
    }
  }, [filteredPlacementList])

  useEffect(() => {
    const initialMockStatus = rows.reduce((acc, row) => {
      return {
        ...acc,
        [row.general_information.email]: row.mock_status ?? "Select Status", // Default to 'Cleared' if no mock_status
      }
    }, {})
    setMockStatus(initialMockStatus)

    const initialResumeStatus = rows.reduce(
      (acc, row) => ({
        ...acc,
        [row.general_information.email]: row.resume_status ?? "Select Status",
      }),
      {}
    )
    setResumeStatus(initialResumeStatus)

    const initialLinkedinStatus = rows.reduce(
      (acc, row) => ({
        ...acc,
        [row.general_information.email]: row.linkedin_status ?? "Select Status",
      }),
      {}
    )
    setLinkedinStatus(initialLinkedinStatus)
  }, [rows])

  return (
    <div className="py-4">
      <div className="max-w-[90vw] overflow-x-scroll mx-auto">
        {rows === null || rows === undefined ? (
          <div className="text-center text-[20px] font-semibold text-red-600 py-[16px]">Loading...</div>
        ) : rows?.length !== 0 ? (
          <table className="whitespace-nowrap border-separate border-spacing-y-[12px]">
            <thead className="sticky top-0 bg-[#333333] z-20 text-white">
              <tr className="overflow-hidden">
                {tableHead.map((trow: string, id: number) => (
                  <th
                    className={cn(
                      "text-start py-3 px-6 font-normal text-[16px]",
                      trow === "General Information" && "sticky left-0 bg-[#333333] rounded-l-[4px]",
                      id === tableHead.length - 1 ? "rounded-r-[4px]" : ""
                    )}
                    key={id}
                  >
                    {trow}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="hover:rounded-md text-black">
              {rows?.map((data: any, id: number) => (
                <tr
                  className="bg-[#F5F5F5] hover:bg-[#EBEBEB] group transition-colors duration-300 "
                  key={data.general_information.email}
                >
                  <td className="px-6 py-6 sticky left-0 z-10 bg-[#F5F5F5] group-hover:bg-[#EBEBEB] transition-colors duration-300">
                    <div className="flex flex-row justify-start items-center gap-4">
                      <input
                        type="checkbox"
                        className=""
                        checked={selectedIds.includes(data.general_information.email)}
                        onChange={() => handleCheckboxChange(data.general_information.email)}
                      />
                      <div className="">
                        <div className="underline underline-offset-2">{data.general_information.name}</div>
                        <div className="">
                          {data.general_information.phone} | {data.general_information.program}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-6">
                    {data?.placement_batch?.cohort_batch_name ? data.placement_batch?.bridge_batch_name : "N/A"}
                  </td>
                  <td className="px-6 py-6">
                    <StatusSelectDropdown
                      options={statusOptions.mockStatus}
                      value={mockStatus[data.general_information.email] || "Select Status"}
                      onChange={(e) => handleMockStatusChange(data.general_information.email, e.target.value)}
                    />
                  </td>
                  <td className="px-6 py-6">
                    <div className="flex items-center justify-center gap-1">
                      <StatusSelectDropdown
                        options={statusOptions.linkedinStatus}
                        value={linkedinStatus[data.general_information.email] || "Select Status"}
                        onChange={(e) => handleLinkedinStatusChange(data.general_information.email, e.target.value)}
                      />

                      {data.linkedin_profile ? (
                        <a href={data.linkedin_profile} target="_blank" rel="noopener noreferrer">
                          <LinkedInIcon className={cn("h-8 w-8")} />
                        </a>
                      ) : (
                        <LinkedInIcon className={cn("h-8 w-8 fill-[#D3D3D3]")} />
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-6">
                    <div className="flex items-center justify-center gap-1">
                      <button className="" onClick={(e) => handleUploadResume(data)}>
                        <div className="border border-black flex items-center justify-center gap-1 py-2 px-4">
                          <UploadIcon className={cn("h-5 w-5")} />
                          <p className="text-[#312E81]">{data.resume_link ? "Update Resume" : "Upload Resume"}</p>
                        </div>
                      </button>

                      {data.resume_link ? (
                        <a href={data.resume_link} target="_blank" rel="noopener noreferrer">
                          <EyeIcon className={cn("h-6 w-6")} />
                        </a>
                      ) : (
                        <NoEyeIcon className={cn("h-6 w-6 fill-[#D3D3D3]")} />
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-6">
                    <StatusSelectDropdown
                      options={statusOptions.resumeStatus}
                      value={resumeStatus[data.general_information.email] || "Select Status"}
                      onChange={(e) => handleResumeStatusChange(data.general_information.email, e.target.value)}
                    />
                  </td>
                  <td className="px-6 py-6">{data.working_or_fresher ? data.working_or_fresher : "N/A"}</td>
                  <td className="px-6 py-6">{data.skills ? data.skills : "N/A"}</td>
                  <td className="px-6 py-6">{data.review.length ? "Given" : "Not Given"}</td>
                  <td className="px-6 py-6">{data.yoe}</td>
                  <td className="px-6 py-6">{data.bca_rating}</td>
                  <td className="px-6 py-6">{data.notice_period ? `${data.notice_period} days` : "N/A"}</td>
                  <td className="px-6 py-6">
                    <Toggle
                      checked={!!data.placed}
                      onChange={() => handlePlacedToggle(data.general_information.email)}
                    />
                  </td>
                  <td className="px-6 py-6">
                    <button className="" onClick={() => handleEdit(data.id)}>
                      <EditIcon className="w-6" />
                    </button>
                  </td>
                  <td className="px-6 py-6">
                    <button className="" onClick={handleMoreOptions}>
                      <MoreOptionsIcon className="w-6" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center text-[20px] font-semibold text-red-600 py-[16px]">No Data Available</div>
        )}
      </div>

      {uploadResumeCardPopup && resumeStudentData && (
        <div className="fixed inset-0 bg-[rgba(0,0,0,0.6)] flex items-center justify-center z-50">
          <div className="rounded-lg shadow-lg">
            <UploadResumeCard
              resumeStudentData={resumeStudentData}
              onCancel={() => setUploadResumeCardPopup(false)}
              handleResumeSave={handleResumeSave}
            />
          </div>
        </div>
      )}
    </div>
  )
}
