import { STR_FAILURE } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { PlacementDashboardAPIDataSourceImpl } from "data/API/Admin/PlacementDashboardAPIDataSourceImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { PlacementDashboardRepositoryImpl } from "data/repository/Admin/PlacementDashboardRepositoryImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { GetStudentList } from "domain/useCase/Admin/Placement/GetStudentList"
import GetAllContestsList from "domain/useCase/User/Contest/GetAllContestsList"
import GetPlacementContestsList from "domain/useCase/User/Contest/GetPlacementContestList"
import { useState } from "react"

export default function DashboardViewModel() {
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [filteredPlacementList, setFilteredPlacementList] = useState<any>([])
  const { auth } = useAuth()
  const [searchPlacement, setSearchPlacement] = useState<string>("")
  const [allContestsList, setAllContestsList] = useState<any>([])
  const [allPlacementContests, setAllPlacementContests] = useState<any>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pBatchContestMap, setPBatchContestMap] = useState<any>({})
  const [allPlacementBatch, setAllPlacementBatch] = useState<any>([])
  const [isEmailPopup, setIsEmailPopup] = useState<boolean>(false)
  const [filters, setFilters] = useState({
    currentTab: "All Students",
    queryCohortPlacementTest: "",
    queryPlacementBatch: "",
    queryEmail: "",
    querySkills: [],
    queryBatch: "",
    queryProgram: "",
    queryYoe: 0,
    queryRating: 0,
    queryNoticePeriod: 0,
    queryStatus: "",
    queryMockStatus: "",
    queryIsPlacementDone: "",
    queryResumeStatus: "",
    queryLinkedInStatus: "",
    pageIndex: 1,
    totalPage: 0,
  })

  const GetStudentListUsecase = new GetStudentList(
    new PlacementDashboardRepositoryImpl(new PlacementDashboardAPIDataSourceImpl())
  )

  const getAllContestsUseCase = new GetAllContestsList(new ContestRepositoryImpl(new ContestAPIDataSourceImpl())) //to get contest name
  const getPlacementContestsUseCase = new GetPlacementContestsList(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )

  async function getStudentList() {
    const response = await GetStudentListUsecase.invoke(auth, {
      queryTab: filters.currentTab,
      queryCohortPlacementTest: filters.queryCohortPlacementTest,
      queryPlacementBatch: filters.queryPlacementBatch,
      queryEmail: filters.queryEmail,
      querySkills: filters.querySkills,
      queryBatch: filters.queryBatch,
      queryProgram: filters.queryProgram,
      queryYoe: filters.queryYoe,
      queryRating: filters.queryRating,
      queryNoticePeriod: filters.queryNoticePeriod,
      queryStatus: filters.queryStatus,
      pageIndex: filters.pageIndex,
      queryMockStatus: filters.queryMockStatus,
      queryIsPlacementDone: filters.queryIsPlacementDone,
      queryResumeStatus: filters.queryResumeStatus,
      queryLinkedInStatus: filters.queryLinkedInStatus,
    })
    if (response?.success) {
      setTotalPages(response?.data?.totalPages)
      setTotalCount(response?.data?.totalCount)
      setFilteredPlacementList(response?.data?.data)
    }
  }

  const handelFiltersChange = (value: string | number, key: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageIndex: 1,
      [key]: value,
    }))
  }

  const handelBatchFiltersChange = (value: string, key: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageIndex: 1,
      [key]: value,
    }))
    if (key === "queryPlacementBatch") {
      if (value) {
        setAllPlacementContests(pBatchContestMap?.[value])
      } else {
        const uniqueIds = new Set()
        const placementContestsList: any = []
        for (const key in pBatchContestMap) {
          const contests = pBatchContestMap[key]
          contests.forEach((contest: any) => {
            if (!uniqueIds.has(contest.id)) {
              uniqueIds.add(contest.id)
              placementContestsList.push(contest)
            }
          })
        }
        setAllPlacementContests(placementContestsList)
      }
    }

    if (key === "queryCohortPlacementTest") {
      if (value) {
        if (filters.queryPlacementBatch === "") {
          setAllPlacementBatch([])
        } else {
          setAllPlacementBatch([filters.queryPlacementBatch])
        }
      } else {
        const placementBatch: any = []
        for (const key in pBatchContestMap) {
          placementBatch.push(key)
          console.log("key", key)
        }
        setAllPlacementBatch(placementBatch)
      }
    }
  }

  async function fetchAllContests() {
    const allContestsListResponse = await getAllContestsUseCase.invoke(auth)
    if (!allContestsListResponse?.success) {
      return
    }
    setAllContestsList(allContestsListResponse?.data)
  }

  async function fetchAllPlacementContests() {
    const PBatchContestsMapResponse = await getPlacementContestsUseCase.invoke(auth)
    if (!PBatchContestsMapResponse?.success) {
      return
    }
    setPBatchContestMap(PBatchContestsMapResponse?.data)
    const uniqueIds = new Set()
    const placementContestsList: any = []
    const placementBatch: any = []
    for (const key in PBatchContestsMapResponse?.data) {
      const contests = PBatchContestsMapResponse?.data[key]
      placementBatch.push(key)
      contests.forEach((contest: any) => {
        if (!uniqueIds.has(contest.id)) {
          uniqueIds.add(contest.id)
          placementContestsList.push(contest)
        }
      })
    }

    setAllPlacementContests(placementContestsList)
    setAllPlacementBatch(placementBatch)
  }

  const handleChangePageNumber = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageIndex: pageNumber,
    }))
  }

  const handleSendEmail = () => {
    if (totalCount <= 2000) {
      setIsEmailPopup(true)
    } else {
      changeToastDetails(STR_FAILURE, "Cannot send email to >=200 students")
      changeToastVisibility(true)
    }
  }

  return {
    getStudentList,
    toast,
    searchPlacement,
    filteredPlacementList,
    changeToastDetails,
    changeToastVisibility,
    filters,
    setFilters,
    handelFiltersChange,
    handelBatchFiltersChange,
    fetchAllContests,
    allContestsList,
    allPlacementContests,
    handleSendEmail,
    totalPages,
    totalCount,
    handleChangePageNumber,
    fetchAllPlacementContests,
    allPlacementBatch,
    isEmailPopup,
    setIsEmailPopup,
  }
}
