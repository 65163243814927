import Sidebar from "core/components/v2/Sidebar"
import Loader from "core/components/Loader"
import { useAuth } from "core/context/auth"
import { ReactNode, useEffect, useState } from "react"
import { useApp } from "core/context/app"
import RightPanel from "core/components/v2/RightPanel"
import Header from "core/components/Header/HeaderView"
import { cn } from "core/lib/utils"
import NotificationToast from "core/components/v2/NotificationToast"
import { format } from "date-fns"
import { useLocation } from "react-router-dom"
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react"
import { CustomScroll } from "react-custom-scroll"
import { CrossIcon } from "core/constants/svgs"
import { UpdateLeetcode } from "domain/useCase/Student/Profile/UpdateLeetcode"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"
import Toast from "core/components/Toast"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { API_STUDENT, STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { Button } from "core/components/v2/Button"
import { GetProfileDetails } from "domain/useCase/Student/Profile/GetProfileDetails"

type TProps = {
  children: ReactNode
  isLoading?: boolean
  rightpanelEnabled?: boolean
  header?: ReactNode | string
}
export default function DashboardLayout({ children, isLoading, rightpanelEnabled, header }: TProps) {
  const { user, auth } = useAuth()
  const { student, rightPanelDetails, remainder, setStudent, notificationToastDetails, fetchRightPanelDetails } =
    useApp()

  const { pathname } = useLocation()
  const [leetcodeChangeModel, setLeetcodeChangeModel] = useState<boolean>(false)
  const [newId, setNewId] = useState<string>("")

  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const UpdateLeetcodeUseCase = new UpdateLeetcode(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const getProfileDetailsUseCase = new GetProfileDetails(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const initialNotifications = [
    !student?.is_payment_done && user?.role === "user" && student?.instalmentsLength < 2 && (
      <NotificationToast
        type="Course Payment"
        description="Complete your course payment to get full access of your portal."
        color="#FF6767"
      />
    ),
    remainder["Onboarding Meet"]?.start_time + 45 * 60 > Math.floor(Date.now() / 1000) && (
      <NotificationToast
        type="Onboarding Meeting"
        description="Complete your onboarding meeting for better  understanding of your portal."
        color="#07B42D"
        time={remainder["Onboarding Meet"]?.start_time}
      />
    ),
    student?.is_batch_paused === true && user?.role == "user" && (
      <NotificationToast
        type="Course Paused"
        description={`Your course has been successfully paused on ${format(new Date(student?.batch_v4.at(-1)["timestamp_end"] * 1000), "do MMM yyyy")} `}
        color="#A27CF3"
      />
    ),
    notificationToastDetails?.["onboarding_data"] &&
      Object.keys(notificationToastDetails?.["onboarding_data"]).length > 0 && (
        <NotificationToast
          type="Onboarding Meet"
          description="Complete your onboarding meeting for better understanding of your portal."
          color="#07B42D"
          time={format(new Date(notificationToastDetails?.["onboarding_data"]?.["start_time"] * 1000), "do MMM yyyy")}
        />
      ),
    remainder?.["Placement Cohort"] && (
      <NotificationToast
        type="Placement Cohort"
        description="Your upcoming Placement Cohort."
        color="#07B42D"
        time={format(new Date(remainder?.["Placement Cohort"]["start_time"] * 1000), "do MMM yyyy")}
      />
    ),
    remainder?.["Exam"] && !remainder?.["Exam"].isCancelled && (
      <NotificationToast
        type="Exam"
        description={`Your upcoming Exam: ${remainder?.["Exam"]?.["instructor"]}`}
        color="#D270E5"
        time={format(new Date(remainder?.["Exam"]?.["start_time"] * 1000), "do MMM yyyy")}
      />
    ),
    notificationToastDetails?.["GSS_data"] && Object.keys(notificationToastDetails?.["GSS_data"]).length > 0 && (
      <NotificationToast
        type="Mentor Session"
        description="You have an upcoming Mentor session with your mentor."
        color="#5DC1F3"
        time={format(new Date(notificationToastDetails?.["GSS_data"]?.["start_date"] * 1000), "do MMM yyyy")}
      />
    ),
  ].filter(Boolean)

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleNext = () => {
    if (currentIndex < initialNotifications.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex < initialNotifications.length - 1 ? prevIndex + 1 : 0))
    }, 5000)

    return () => clearInterval(interval)
  }, [initialNotifications.length])

  const leetcodeChangeHandler = (showLeetcodeModel: boolean) => {
    setLeetcodeChangeModel(showLeetcodeModel)
    setNewId("")
  }

  const submitHandler = async () => {
    if (newId.trim().length) {
      try {
        await UpdateLeetcodeUseCase.invoke(newId, user.email, auth, API_STUDENT)
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Leetcode ID Updated Successfully!")
        const response = await getProfileDetailsUseCase.invoke(auth)
        setStudent(response?.data)
        await fetchRightPanelDetails(auth)
      } catch (err) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, genError(err, "Enter a valid Leetcode Id!"))
      }
    }
  }

  return (
    <div className="flex relative h-screen w-full justify-between gap-6 p-6">
      <div className="flex h-full w-[15%] min-w-[200px] max-w-[287px] flex-1 flex-col">
        <Sidebar />
      </div>
      <div
        className={cn(
          "no-scrollbar flex h-full flex-col rounded-md border-2 border-gray-100",
          !rightpanelEnabled ? "w-[calc(70%-96px)]" : "w-full"
        )}
      >
        {header || <Header onLeetcodeChange={leetcodeChangeHandler} />}
        <div className=" h-full overflow-y-auto">
          <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
            <div className="h-full flex-1 text-new-neutral" id="dashboard">
              {isLoading ? (
                <Loader />
              ) : (
                <div className="overflow-hidden">
                  {initialNotifications.length > 0 && pathname === "/home" && (
                    <div className="flex gap-4 px-6 pt-6">
                      <div className="self-center">
                        <button
                          className="h-6 w-6 text-white rounded bg-[#162456] p-1 hover:bg-v2-accent-200"
                          onClick={handlePrev}
                          disabled={currentIndex === 0}
                        >
                          <ChevronLeftIcon size={16} />
                        </button>
                      </div>
                      <div className="flex flex-1 transition-transform duration-500 ease-out">
                        {initialNotifications[currentIndex]}
                      </div>
                      <div className="self-center">
                        <button
                          className="h-6 w-6 rounded text-white bg-[#162456] p-1 hover:bg-v2-accent-200"
                          onClick={handleNext}
                          disabled={currentIndex === initialNotifications.length - 1}
                        >
                          <ChevronRightIcon size={16} />
                        </button>
                      </div>
                    </div>
                  )}
                  {children}
                </div>
              )}
            </div>
          </CustomScroll>
        </div>
      </div>
      {!rightpanelEnabled && (
        <div className="flex h-full w-[15%] min-w-[200px] max-w-[287px] flex-1 flex-col">
          <RightPanel rightBar={rightPanelDetails} student={student} />
        </div>
      )}
      {leetcodeChangeModel && (
        <div className="absolute w-[100%] h-[100vh] left-0 top-0 z-30">
          <div
            className="left-0 top-0 w-[100%] h-[100vh] opacity-90 bg-[#0b0b0b]"
            onClick={() => {
              leetcodeChangeHandler(false)
            }}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 w-[450px] rounded-lg shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)] border border-[#dedede] bg-white opacity-100">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <div className="text-[#02239a] text-base font-semibold font-['Inter']">
                  Change your Leetcode username
                </div>
                <button
                  onClick={() => {
                    leetcodeChangeHandler(false)
                  }}
                >
                  <CrossIcon className="size-[24px]" />
                </button>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <div className="text-[#333232] text-sm font-semibold font-['Inter']">Current Leetcode username</div>
                <div className="w-full px-4 py-2 bg-white rounded border border-[#d3d3d3] justify-start items-start gap-[13px] inline-flex overflow-hidden">
                  <div className="text-[#636363] text-sm font-normal font-['Inter']">
                    {student?.leetcode || "Your Leetcode Id"}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <div className="text-[#333232] text-sm font-semibold font-['Inter']">Enter new Leetcode username</div>
                <input
                  type="text"
                  className="text-[#636363] text-sm font-normal font-['Inter'] w-full px-4 py-2 bg-white rounded border border-[#d3d3d3] justify-start items-start gap-[13px] inline-flex overflow-hidden"
                  value={newId}
                  onChange={(e) => {
                    setNewId(e.target.value)
                  }}
                  placeholder="Username Without URL Eg: ABC@123"
                />
              </div>
              <div className="flex flex-col gap-3 p-[10px] w-full bg-[#f8f8f8] rounded">
                <div className="w-[382px] text-red-600 text-sm font-medium font-['Inter'] leading-[21px]">
                  ACKNOWLEDGEMENT FOR LEETCODE USERNAME CHANGE
                </div>
                <div className="text-[#636363] text-[14px] font-normal font-['Inter'] leading-[21px]">
                  <span className="font-semibold">Progress Reset Warning:</span> By changing your LeetCode ID, you
                  acknowledge that all your previous progress, submission history, badges earned, and streak records
                  will be reset to zero. This action cannot be undone, and your previous achievements will not be
                  transferred to the new username.
                </div>
                <div className="text-[#636363] text-[14px] font-normal font-['Inter'] leading-[21px]">
                  <span className="font-semibold">Time Restriction:</span> You understand that LeetCode username changes
                  are limited to once every 30 days. After changing your username, you will need to wait for a full
                  30-day period before you can make another change. This restriction is in place to maintain platform
                  stability and prevent username abuse.
                </div>
              </div>
              <div className="flex justify-end gap-4">
                <Button
                  onClick={() => {
                    leetcodeChangeHandler(false)
                  }}
                  variant={"ternary"}
                  border={"thin"}
                >
                  Cancel
                </Button>
                <Button onClick={submitHandler} variant={"primary"}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
