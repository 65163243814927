import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_STUDENT, STR_CHATBOT_RESPONSE } from "core/constants/strings"
import ChatbotDataSource from "data/dataSource/Student/ChatbotDataSource"
import { Auth } from "domain/model/Auth"

const server = new Server()

export class ChatbotAPIDataSourceImpl implements ChatbotDataSource {
  async getChatbotResponse(auth: Auth, prompt: string): Promise<string> {
    try {
      const response = await server.post(
        pn(API_STUDENT, STR_CHATBOT_RESPONSE),
        {
          prompt,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      const data = response.data

      if (data) {
        console.log("Chatbot response data:", data)
        return data.answer
      } else {
        throw new Error("API response was not successful.")
      }
    } catch (error) {
      console.error("Error in chatbot response:", error)
      throw new Error(
        error instanceof Error
          ? `Chatbot API call failed: ${error.message}`
          : "An unknown error occurred while fetching the chatbot response."
      )
    }
  }
}
