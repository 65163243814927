import { StudentToPlacementBatch } from "domain/model/PlacementBatch"
import { PlacementBatchRepository } from "domain/repository/Admin/PlacementBatchRepository"

export interface AddPlacementStudentUseCase {
  invoke(addPlacementStudent: StudentToPlacementBatch): Promise<any>
}

export class AddPlacementStudent implements AddPlacementStudentUseCase {
  private repository: PlacementBatchRepository

  constructor(repository: PlacementBatchRepository) {
    this.repository = repository
  }

  async invoke(addStudent: StudentToPlacementBatch) {
    return await this.repository.addPlacementStudent(addStudent)
  }
}
