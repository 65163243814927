import pn from "core/utils/pn"
import { STUDENT, LEADERBOARD } from "core/constants/strings"
import { postAPI, Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"

import { MentorNotificationsDataSource } from "data/dataSource/Student/MentorNotificationsDataSource"
import { CreateMentorNotificationsDetails } from "domain/model/MentorNotifications"

const server = new Server()
export class MentorNotificationsAPIDataSource implements MentorNotificationsDataSource {
  async createMentorNotifications(auth: Auth, details: CreateMentorNotificationsDetails): Promise<any> {
    try {
      const response = await server.post(
        pn(STUDENT, "create_mentor_notifications"),
        {
          details: details,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
