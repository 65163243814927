import { useRef, useState } from "react"
import { useAuth } from "core/context/auth"
// import { ContestApproveRepositoryImpl } from "data/repository/Admin/ContestApproveRepositoryImpl"
// import { GetTop100ApproveRequests } from "domain/useCase/Admin/ContestApprove/GetTop100ApproveRequests"
// import { GetApprovalByEmailRequests } from "domain/useCase/Admin/ContestApprove/GetApprovalByEmailRequests"
// import { GetApprovalByDateRequests } from "domain/useCase/Admin/ContestApprove/GetApproveByDateRequests"
// import { ContestApproveAPIDataSourceImpl } from "data/API/Admin/ContestApproveAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { ReferandEarnRepositoryImpl } from "data/repository/Admin/ReferandEarnRepositoryImpl"
import { ReferandEarnAPIDataSourceImpl } from "data/API/Admin/ReferandEarnDataSourceImpl"
import { GetTop100ReferandEarnRequests } from "domain/useCase/Admin/ReferandEarn/GetTop100ReferandEarnRequests"
import { UpdateReferandEarnRequests } from "domain/useCase/Admin/ReferandEarn/UpdateReferandEarnRequests"
import { GetFilterReferandEarnRequests } from "domain/useCase/Admin/ReferandEarn/GetFilterReferandEarnRequests"
import Papa from "papaparse"
import { saveAs } from "file-saver"
// import { isEmpty } from "core/utils/misc"
// import { TDisableStudentReason } from "core/constants/types"
// import { approveData } from "domain/model/ContestApprove"
// import { ApproveToDisapproveRequests } from "domain/useCase/Admin/ContestApprove/ApproveToDisapprove"

export default function ReferandEarnViewModel() {
  const tableHeaders = [
    "S.No.",
    "Referee Details",
    "Referrer Details",
    "Referred Date",
    "Referee Amount",
    "Referrer Amount",
    "Expected Date",
    "Referee Bank",
    "Referrer Bank",
    "Referee Redeem",
    "Referrer Redeem",
    "Referee Opted Out",
  ]

  const [referralData, setReferralData] = useState<any>([])
  const [nextStudentsLoading, setNextStudentsLoading] = useState(false)
  const courseOptions = ["Accelerator", "Transformer", "Evolve", "Data Science", "Data Analytics", "Data Engineering"]
  const [fromDateValue, setFromDateValue] = useState<number>(0)
  const [toDateValue, setToDateValue] = useState<number>(0)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const emailInputRef = useRef<HTMLInputElement>(null)

  const getTop100ReferandEarnUseCase = new GetTop100ReferandEarnRequests(
    new ReferandEarnRepositoryImpl(new ReferandEarnAPIDataSourceImpl())
  )
  const getFilterReferandEarnUseCase = new GetFilterReferandEarnRequests(
    new ReferandEarnRepositoryImpl(new ReferandEarnAPIDataSourceImpl())
  )

  const updateReferandEarnUseCase = new UpdateReferandEarnRequests(
    new ReferandEarnRepositoryImpl(new ReferandEarnAPIDataSourceImpl())
  )

  const handleFromDateChange = (e: any) => {
    const time = e.target.value
    const date = new Date(time)

    // Set time to 00:00:00.000 (midnight)
    date.setHours(0, 0, 0, 0)

    const timestamp = date.getTime() / 1000 // Convert to seconds (Unix timestamp)
    setFromDateValue(timestamp)
  }
  const handleToDateChange = (e: any) => {
    const time = e.target.value
    const date = new Date(time)

    // Set hours, minutes, seconds, and milliseconds to 23:59:59.999 (end of the day)
    date.setHours(23, 59, 59, 999)

    const timestamp = date.getTime() / 1000 // Convert to seconds
    setToDateValue(timestamp)
  }

  async function getTop100ReferandEarn() {
    setNextStudentsLoading(true)
    const response = await getTop100ReferandEarnUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setNextStudentsLoading(false)
      return
    }
    setNextStudentsLoading(false)
    setReferralData(response?.data)
  }

  async function getFilterReferandEarn(data: any) {
    setNextStudentsLoading(true)
    const response = await getFilterReferandEarnUseCase.invoke(auth, data)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      setNextStudentsLoading(false)
      return
    }
    setNextStudentsLoading(false)
    setReferralData(response?.data)
  }

  async function updateReferandEarn(data: any) {
    const index = data.index
    delete data["index"]
    const response = await updateReferandEarnUseCase.invoke(auth, data)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    delete data["email"]
    const newReferral: any = [...referralData]
    newReferral[index] = {
      ...referralData[index],
      ...data,
    }
    setReferralData(newReferral)
  }

  const DownloadCSV = () => {
    // Convert the data to CSV format
    const newReferralData: any = referralData.map((data: any) => {
      return {
        refereeName: data.referName,
        refereeEmail: data.referEmail,
        refereeNumber: data.referNumber,
        referrerName: data.yourName,
        referrerEmail: data.yourEmail,
        referrerNumber: data.yourNumber,
        studentEnrolled: data.referFinalized,
        referee_amount: data.referee_amount,
        referrer_amount: data.referrer_amount,
        referrer_bonus: data.bonus,
        referee_reedem: data?.referee_reedem ? true : false,
        referrer_reedem: data?.referrer_reedem ? true : false,
        opted_out: data?.opted_out ? true : false,
      }
    })

    const csv = Papa.unparse(newReferralData)

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })

    // Download the CSV file
    saveAs(blob, "data.csv")
  }

  return {
    courseOptions,
    tableHeaders,
    referralData,
    nextStudentsLoading,
    toast,
    changeToastVisibility,
    changeToastDetails,

    emailInputRef,
    getTop100ReferandEarn,
    updateReferandEarn,
    getFilterReferandEarn,
    fromDateValue,
    toDateValue,
    handleFromDateChange,
    handleToDateChange,
    DownloadCSV,
  }
}
