import React, { useEffect, useState } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { genError } from "core/utils/string"
import { isEmpty } from "core/utils/misc"
import { GetQuestionBank } from "domain/useCase/Student/QuestionBank/GetQuestionBank"
import { QuestionBankRepositoryImpl } from "data/repository/Student/QuestionBankRepositoryImpl"
import { QuestionBankAPIDataSourceImpl } from "data/API/Student/QuestionBankAPIDataSourceImpl"

export default function QuestionBankViewModel() {
  const { auth } = useAuth()
  const { student } = useApp()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const [activeButtonType, setActiveButtonType] = useState(0)
  const [questionBank, setQuestionBank] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedQuestion, setSelectedQuestion] = useState<number>(0)
  const [isFeedbackVisible, setIsFeedbackVisible] = useState<boolean>(false)
  const [feedbackData, setFeedbackData] = useState<any>(null)
  const [topicList, setTopicList] = useState<string[]>([])

  const getQuestionBankUseCase = new GetQuestionBank(
    new QuestionBankRepositoryImpl(new QuestionBankAPIDataSourceImpl())
  )

  function changeActiveButtonType(index: number) {
    if (activeButtonType !== index) setActiveButtonType(index)
  }

  const fetchQuestionBankData = async () => {
    setLoading(true)
    const response = await getQuestionBankUseCase.invoke(auth)
    setLoading(false)

    // if (!response?.success) {
    //   changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch question bank"));
    //   changeToastVisibility(true);
    //   return;
    // }

    console.log("This is question bank", response)
    setQuestionBank(response)
  }

  useEffect(() => {
    const uniqueTopics = new Set<string>()

    questionBank.forEach((question: any) => {
      if (question.topics && Array.isArray(question.topics)) {
        question.topics.forEach((topic: string) => uniqueTopics.add(topic))
      }
    })

    setTopicList(["Select Topic", ...Array.from(uniqueTopics)])
  }, [questionBank])

  //   const handleSelectedQuestionBtnClick = (number: number) => {
  //     setSelectedQuestion(number);
  //   };

  //   const handleFeedbackBtnClick = (questionId: string) => {
  //     setIsFeedbackVisible(true);
  //     setFeedbackData(questionBank.find((q: any) => q.id === questionId));
  //   };

  //   const handleOnCloseFeedbackBtnClick = () => {
  //     setIsFeedbackVisible(false);
  //   };

  return {
    questionBank,
    loading,
    selectedQuestion,
    // handleSelectedQuestionBtnClick,
    fetchQuestionBankData,
    isFeedbackVisible,
    setIsFeedbackVisible,
    feedbackData,
    // handleFeedbackBtnClick,
    // handleOnCloseFeedbackBtnClick,
    activeButtonType,
    changeActiveButtonType,
    toast,
    topicList,
  }
}
