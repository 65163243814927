import pn from "core/utils/pn"
import { postAPI, Server } from "core/utils/axios"
import {
  API_GET_ALL_CLASS_TOPICS,
  API_GET_ALL_QUESTIONS,
  API_GET_DOUBT_QUERY_SOLUTION,
  API_UPDATE_DOUBT_STATUS,
  API_GET_STUDENT_DOUBT_HISTORY,
  API_V4_STUDENT,
  API_SEARCH_STUDENT_DOUBT,
  API_GET_STUDENT_DOUBT_PENDING,
  API_GET_FUTURE_BATCHES,
  API_STUDENT_BATCH_SHIFT,
  API_IS_ELIGIBLE_FOR_BATCH_CHANGE,
  API_STUDENT_BATCH_PAUSE,
  STR_GET_ELIGIBLE_MENTORS,
  STUDENT,
  DOUBT_SUPPORT,
  STR_UPDATE_MENTOR,
} from "core/constants/strings"
import { Auth } from "domain/model/Auth"
import { DoubtSupportDataSource } from "data/dataSource/Student/DoubtSupportDataSource"

const server = new Server()
export default class DoubtSupportAPIDataSourceImpl implements DoubtSupportDataSource {
  async getAllClassTopics(auth: Auth) {
    const response = await server.get(pn(STUDENT, DOUBT_SUPPORT, API_GET_ALL_CLASS_TOPICS), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async getAllQuestions(auth: Auth, class_name_id: any) {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, API_GET_ALL_QUESTIONS),
      { class_name_id },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async getDoubtQuerySolution(auth: Auth, problem_id: any) {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, API_GET_DOUBT_QUERY_SOLUTION),
      { problem_id },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async updateDoubtStatus(auth: Auth, like: any, problem_id: any): Promise<any> {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, API_UPDATE_DOUBT_STATUS),
      { like, problem_id },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async getStudentDoubtHistory(auth: Auth) {
    const response = await server.get(pn(STUDENT, DOUBT_SUPPORT, API_GET_STUDENT_DOUBT_HISTORY), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async searchStudentDoubt(auth: Auth, problem_id: any) {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, API_SEARCH_STUDENT_DOUBT),
      { problem_id },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async getStudentDoubtPending(auth: Auth) {
    const response = await server.get(pn(STUDENT, DOUBT_SUPPORT, API_GET_STUDENT_DOUBT_PENDING), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async getFutureBatches(auth: Auth) {
    const response = await server.get(pn(STUDENT, DOUBT_SUPPORT, API_GET_FUTURE_BATCHES), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async studentBatchShift(auth: Auth, batch: any, reason: any, is_batch_resume: any, discord_username: any) {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, API_STUDENT_BATCH_SHIFT),
      { batch, discord_username, is_batch_resume, reason },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async isEligibleForBatchChange(auth: Auth) {
    const response = await server.get(pn(STUDENT, DOUBT_SUPPORT, API_IS_ELIGIBLE_FOR_BATCH_CHANGE), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }

  async studentBatchPause(auth: Auth, reason: any) {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, API_STUDENT_BATCH_PAUSE),
      { reason },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }

  async getEligibleMentors(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, STR_GET_ELIGIBLE_MENTORS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateMentor(auth: Auth, mentor_id: any, reason: any) {
    const response = await server.post(
      pn(STUDENT, DOUBT_SUPPORT, STR_UPDATE_MENTOR),
      { mentor_id, reason },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }
}
