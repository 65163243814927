import React, { useState, useEffect, useRef } from "react"
import { Modal } from "@mui/material"
import ArrowGoBack from "assets/svgs/ArrowGoBack"
import arrow from "assets/svgs/arrow.svg"
import { CustomScroll } from "react-custom-scroll"
import { Tooltip } from "@mui/material"
import { InfoIcon } from "lucide-react"
import ModuleCompletionCard from "./ModuleCompletionCard"
import { useApp } from "core/context/app"
import useLocalStorage from "core/hooks/useLocalStorage"

type Result = {
  [key: string]: any
}

type ResultModalProps = {
  result: Result | null
  onClose: () => void
  resultType: string
  leaderboardData: any[]
  questionwiseSubmissions: { [key: string]: any }
  contestId: string
  questionId: string
  examType: string
  passed: { [key: string]: boolean }
  oldFlowDate: number | null
  ongoingStates: { [key: string]: boolean }
  setShowCelebration: (value: boolean) => void
  showCelebration: boolean
  // getQuestionwiseSubmissionData: (email: string, contestId: string, questionId: string) => void
}

const isQuestion = (key: string, selectedType: string): boolean => {
  if (selectedType === "Coding Questions" && key.startsWith("Coding Q")) return true
  if (selectedType === "MCQ" && key.startsWith("MCQ Q")) return true
  if (selectedType === "Subjective" && key.startsWith("Subjective Q")) return true
  if (selectedType === "Case Study" && key.startsWith("Casestudy Q")) return true
  return false
}

const findMaxMarks: any = (key: string): number => {
  const match = key.match(/Out of (\d+)/)
  if (match) {
    return parseInt(match[1])
  }
  return 0
}

const calculateTotals = (results: Record<string, any>, type: string) => {
  let liveTotals = { totalMaxMarks: 0, totalReceivedMarks: 0 }
  let recordedTotals = { totalMaxMarks: 0, totalReceivedMarks: 0 }

  if (results.live_results) {
    Object.entries(results.live_results).forEach(([key, value]) => {
      if (isQuestion(key, type)) {
        const maxMarks = findMaxMarks(key)
        liveTotals.totalMaxMarks += maxMarks
        liveTotals.totalReceivedMarks += Number(value)
      }
    })
  }

  if (results.recorded_results) {
    Object.entries(results.recorded_results).forEach(([key, value]) => {
      if (isQuestion(key, type)) {
        const maxMarks = findMaxMarks(key)
        recordedTotals.totalMaxMarks += maxMarks
        recordedTotals.totalReceivedMarks += Number(value)
      }
    })
  }

  return { live: liveTotals, recorded: recordedTotals }
}

const removeMaxMarks = (key: string): string => {
  return key.replace(/\s\(Out of \d+\)/, "")
}

const renderQuestion = (result: Record<string, any>, currentKey: string): string | undefined => {
  const filteredKeys = Object.keys(result).filter((key) => key.startsWith("$"))
  const current = currentKey.replace(/\s\(Out of \d+\)/, "").split(" Q")
  const type = current[0]
  const number = current[1]
  let matchingKey = ""
  if (type === "MCQ") {
    matchingKey = `$mcqQuestion-${number}`
  } else {
    matchingKey = `$coding-${number}`
  }
  return matchingKey
}
const ResultModal: React.FC<ResultModalProps> = ({
  result,
  onClose,
  resultType,
  leaderboardData,
  questionwiseSubmissions,
  contestId,
  questionId,
  examType,
  passed,
  oldFlowDate,
  ongoingStates,
  setShowCelebration,
  showCelebration,
  // getQuestionwiseSubmissionData,
  // onQuestionClick,
}) => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(null)
  const [isActive, setIsActive] = useState(resultType)
  const [selectedType, setSelectedType] = useState("Coding Questions")
  // const [sectionTotals, setSectionTotals] = useState<{
  //   [key: string]: { totalMaxMarks: number; totalReceivedMarks: number }
  // }>({})
  const [sectionTotals, setSectionTotals] = useState<{
    [key: string]: {
      live: { totalMaxMarks: number; totalReceivedMarks: number }
      recorded: { totalMaxMarks: number; totalReceivedMarks: number }
    }
  }>({})
  const [liveTotals, setLiveTotals] = useState<{ totalMaxMarks: number; totalReceivedMarks: number }>({
    totalMaxMarks: 0,
    totalReceivedMarks: 0,
  })
  const [recordedTotals, setRecordedTotals] = useState<{ totalMaxMarks: number; totalReceivedMarks: number }>({
    totalMaxMarks: 0,
    totalReceivedMarks: 0,
  })
  const [overallTotals, setOverallTotals] = useState<{ totalMaxMarks: number; totalReceivedMarks: number }>({
    totalMaxMarks: 0,
    totalReceivedMarks: 0,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const scrollableRef = useRef<HTMLDivElement>(null)

  const { student } = useApp()
  const [currentStudent] = useLocalStorage<any>("student", {} as any)

  useEffect(() => {
    if (result) {
      setIsModalVisible(true)
      const sections = ["Coding Questions", "MCQ", "Subjective", "Case Study"]
      let overallLiveMaxMarks = 0
      let overallLiveReceivedMarks = 0
      let overallRecordedMaxMarks = 0
      let overallRecordedReceivedMarks = 0

      const sectionwiseTotals: {
        [key: string]: {
          live: { totalMaxMarks: number; totalReceivedMarks: number }
          recorded: { totalMaxMarks: number; totalReceivedMarks: number }
        }
      } = {}

      sections.forEach((type) => {
        const sectionTotals = calculateTotals(result, type)
        sectionwiseTotals[type] = sectionTotals

        overallLiveMaxMarks += sectionTotals.live.totalMaxMarks
        overallLiveReceivedMarks += sectionTotals.live.totalReceivedMarks
        overallRecordedMaxMarks += sectionTotals.recorded.totalMaxMarks
        overallRecordedReceivedMarks += sectionTotals.recorded.totalReceivedMarks
      })

      setSectionTotals(sectionwiseTotals)
      setLiveTotals({ totalMaxMarks: overallLiveMaxMarks, totalReceivedMarks: overallLiveReceivedMarks })
      setRecordedTotals({ totalMaxMarks: overallRecordedMaxMarks, totalReceivedMarks: overallRecordedReceivedMarks })
    }

    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight
    }
  }, [result])

  const handleQuestionButtonHover = (index: number) => {
    setOpenQuestionIndex(index)
  }

  const handleQuestionButtonLeave = () => {
    setOpenQuestionIndex(null)
  }

  const getStars = (stars: any) => {
    return "★".repeat(stars) + "☆".repeat(3 - stars)
  }

  const resetState = () => {
    setOpenQuestionIndex(null)
    setIsActive("live")
    setSelectedType("Coding Questions")
    setSectionTotals({})
    setOverallTotals({ totalMaxMarks: 0, totalReceivedMarks: 0 })
  }

  const handleGoBack = () => {
    setIsModalVisible(false)
    setTimeout(() => {
      resetState()
      onClose()
    }, 500)
  }

  const [filteredResults, setFilteredResults] = useState<any[]>([])
  const [submissionKeys, setSubmissionKeys] = useState<any[]>([])

  useEffect(() => {
    if (result) {
      const resultsToFilter = isActive === "recorded" ? result.recorded_results : result.live_results
      const keys = Object.entries(resultsToFilter || {})
      const filteredSubmissionKeys = keys.filter(
        ([key]) => key.startsWith("$coding-") || key.startsWith("$mcqQuestion-")
      )
      const filteredQuestions = keys.filter(([key]) => isQuestion(key, selectedType))
      setFilteredResults(filteredQuestions)
      setSubmissionKeys(filteredSubmissionKeys)
    }
  }, [result, isActive, selectedType])

  return (
    <Modal open={!!result} onClose={handleGoBack}>
      {/* <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-all duration-500 ease-in-out z-0"> */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-all duration-500 ease-in-out ${
          showCelebration ? "bg-black bg-opacity-80 pointer-events-none blur-sm backdrop-blur-sm " : ""
        }`}
      >
        <div className="fixed inset-0 h-screen flex justify-end items-center overflow-y-auto z-0">
          <div
            className={`bg-white w-[636px] h-full flex flex-col justify-between overflow-hidden transition-transform duration-500 ease-in-out ${
              isModalVisible ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ zIndex: 0 }}
          >
            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
              <div className="bg-white w-[636px] h-full  flex flex-col justify-between overflow-hidden ">
                <div className="flex justify-between h-[91px] w-[636px] px-[24px] p-6 bg-[#F9F9F9]">
                  <div className="w-[588px] h-[43px] flex items-center justify-between">
                    <div>
                      <h2 className="text-[18px] font-semibold">Your Result</h2>
                      <p className="text-[14px] text-gray-500">Track your contest result from here.</p>
                    </div>
                    <button
                      className="flex h-[35px] w-[108px] items-center justify-center text-[14px] gap-1 text-gray-700 border px-2 rounded bg-white hover:bg-gray-100"
                      onClick={handleGoBack}
                    >
                      <ArrowGoBack className="w-4 h-[1.5rem]" />
                      Go Back
                    </button>
                  </div>
                </div>
                <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                  <div className="flex-grow pl-6  w-full  overflow-hidden mt-4">
                    <div className="flex pt-[4px] pr-[6px] pb-[4px] pl-[6px] border-t-0 rounded bg-[#EBEBEB] w-[447px] h-[43px] gap-[2px]">
                      <button
                        className={`flex items-center pt-[9px] pr-[16px] pb-[9px] pl-[16px] rounded ${
                          isActive === "live" ? "bg-[#FFFFFF]" : "bg-[#EBEBEB] hover:bg-[#FFFFFF]"
                        } opacity-100`}
                        style={{ width: "115px", height: "35px" }}
                        onClick={() => setIsActive("live")}
                      >
                        <span className="text-[#333333] text-[14px] font-medium leading-[16.94px]">Result - Live</span>
                      </button>
                      <button
                        className={`flex items-center px-4 py-2 rounded ${
                          isActive === "recorded" ? "bg-[#FFFFFF]" : "bg-[#EBEBEB] hover:bg-[#FFFFFF]"
                        } opacity-100`}
                        style={{ width: "152px", height: "35px" }}
                        onClick={() => setIsActive("recorded")}
                      >
                        <span className="text-[#333333] text-[14px] font-medium leading-[16.94px]">
                          Result - Recorded
                        </span>
                      </button>
                      <Tooltip
                        className="d-block"
                        title={ongoingStates[contestId] ? "This link will be active after exam" : ""}
                      >
                        <button
                          className={`flex items-center px-4 py-2 rounded ${
                            isActive === "leaderboard" ? "bg-[#FFFFFF]" : "bg-[#EBEBEB] hover:bg-[#FFFFFF]"
                          } opacity-100`}
                          style={{ width: "164px", height: "35px" }}
                          onClick={() => setIsActive("leaderboard")}
                          disabled={ongoingStates[contestId]}
                        >
                          <span className="text-[#333333] text-[14px] font-medium leading-[16.94px]">
                            Leader Board (Live)
                          </span>
                        </button>
                      </Tooltip>
                    </div>

                    {(isActive === "live" || isActive === "recorded") && (
                      <div
                        className="flex mt-4 gap-[12px]"
                        style={{ width: "446px", height: "27px", opacity: "100", alignItems: "center" }}
                      >
                        {["Coding Questions", "MCQ", "Subjective", "Case Study"].map((type, index) => {
                          const buttonStyles: React.CSSProperties = {
                            width:
                              type === "Coding Questions"
                                ? "150px"
                                : type === "MCQ"
                                  ? "66px"
                                  : type === "Subjective"
                                    ? "103px"
                                    : "109px",
                            height: "27px",
                            padding: "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "4px",
                          }

                          const textStyles: React.CSSProperties = {
                            textAlign: "center" as const,
                            lineHeight: "normal",
                            fontSize: "14px",
                            width:
                              type === "Coding Questions"
                                ? "118px"
                                : type === "MCQ"
                                  ? "34px"
                                  : type === "Subjective"
                                    ? "71px"
                                    : "77px",
                            height: "17px",
                          }

                          const buttonClass = `flex items-center justify-center ${
                            selectedType === type
                              ? "bg-[#001665] text-[white] hover:bg-[#03249A]"
                              : "bg-white text-[#848484] border border-[#D3D3D3] hover:bg-[#EBEBEB]"
                          } gap-2 rounded`

                          return (
                            <button
                              key={index}
                              onClick={() => setSelectedType(type)}
                              className={buttonClass}
                              style={buttonStyles}
                            >
                              <span style={textStyles}>{type}</span>
                            </button>
                          )
                        })}
                      </div>
                    )}

                    {(isActive === "live" || isActive === "recorded") && result && (
                      <>
                        <div className="mt-4 w-[587px] h-[343px]">
                          <div className="h-[343px] w-fill overflow-hidden rounded-[4px] border-[#ADADAD] shadow-[0_0_0_0.5px_rgba(211,211,211,1)]">
                            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                              <table className="w-full relative rounded-[4px]">
                                <thead className="h-[31px] bg-[#303030] sticky top-0 ">
                                  <tr>
                                    <th className="text-left p-0 text-[12px] font-medium text-[#FFF] px-[16px] w-[69px] h-[15px] gap-[16px]">
                                      Sr. No.
                                    </th>
                                    <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[15px] w-[92px] h-[15px]">
                                      Question
                                    </th>
                                    <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[66px] h-[15px]">
                                      Max Marks
                                    </th>
                                    <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[92px] h-[15px]">
                                      Marks Obtained
                                    </th>
                                    <th className="text-left p-0 text-[12px] font-medium text-[#FFF] pl-[14px] w-[67px] h-[15px]">
                                      Submission
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {filteredResults.length > 0 ? (
                                    filteredResults.map(([key, value], index) => {
                                      const submissionKey = submissionKeys.find(([subKey]) => {
                                        if (selectedType === "Coding Questions") {
                                          return subKey === `$coding-${index + 1}` // For coding questions
                                        } else {
                                          return subKey === `$mcqQuestion-${index + 1}` // For MCQ questions
                                        }
                                      })

                                      return (
                                        <tr key={key} className="hover:bg-gray-100">
                                          <td className="py-2 px-4 text-[12px] font-normal">{String(index + 1)}</td>
                                          <td className="py-2 px-4 text-[12px] font-normal">
                                            <span className="py-2">{removeMaxMarks(key)}</span>
                                          </td>
                                          <td className="py-2 px-4 text-[12px] font-normal">{findMaxMarks(key)}</td>
                                          <td className="py-2 px-4 text-[12px] font-normal">
                                            {value === "Not evaluated" ? value : `${value}/${findMaxMarks(key)}`}
                                          </td>
                                          <td className="py-2 px-4 text-center text-[12px] font-normal border-0">
                                            {isActive === "live" ? (
                                              <button
                                                className="flex items-center justify-center gap-[14px] w-[32px] h-[32px] bg-white rounded-[4px] hover:bg-gray-200"
                                                onClick={() => {
                                                  const submissionId = submissionKey ? submissionKey[1] : ""
                                                  {
                                                    selectedType === "Coding Questions"
                                                      ? window.open(
                                                          `/contests/${contestId}/${currentStudent?.email}/${submissionId}/coding`,
                                                          "_blank"
                                                        )
                                                      : window.open(
                                                          `/contests/${contestId}/${currentStudent?.email}/${submissionId}/mcq`,
                                                          "_blank"
                                                        )
                                                  }
                                                }}
                                              >
                                                <img className="w-[16px] h-[16px]" src={arrow} alt="submission_link" />
                                              </button>
                                            ) : (
                                              <Tooltip
                                                className="d-block"
                                                title={
                                                  selectedType === "Coding Questions" &&
                                                  (oldFlowDate === null || oldFlowDate < 1730917800)
                                                    ? "Not Accessible"
                                                    : ""
                                                }
                                              >
                                                <button
                                                  //disable if the start time is less than the old flow date
                                                  disabled={
                                                    selectedType === "Coding Questions" &&
                                                    (oldFlowDate === null || oldFlowDate < 1730917800)
                                                  }
                                                  className="flex items-center justify-center gap-[14px] w-[32px] h-[32px] bg-white rounded-[4px] hover:bg-gray-200"
                                                  onClick={() => {
                                                    const submissionId = submissionKey ? submissionKey[1] : ""
                                                    {
                                                      selectedType === "Coding Questions"
                                                        ? window.open(
                                                            `/contests/${contestId}/rec/${currentStudent?.email}/${submissionId}/coding`,
                                                            "_blank"
                                                          )
                                                        : window.open(
                                                            `/contests/${contestId}/rec/${currentStudent?.email}/${submissionId}/mcq`,
                                                            "_blank"
                                                          )
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    className="w-[16px] h-[16px]"
                                                    src={arrow}
                                                    alt="submission_link"
                                                  />
                                                </button>
                                              </Tooltip>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={5}
                                        className="text-center py-8 text-[12px] font-normal text-gray-500"
                                      >
                                        No questions from this section.
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </CustomScroll>
                          </div>
                        </div>

                        <div className="w-[587px] h-[265px] flex flex-col gap-8px mt-4 mb-4">
                          {selectedType && sectionTotals[selectedType] && (
                            <div className="w-[587px] h-[33px] flex items-end justify-between p-[8px_16px] rounded bg-[#F9F9F9]">
                              <span className="flex items-start w-[218px] h-[17px] font-normal text-[14px] leading-[16.94px] text-left text-[#1C3474]">
                                Total Marks ( {selectedType} )
                              </span>
                              <span className="w-[64px] h-[17px] font-semibold text-[14px] leading-[16.94px] text-center text-[#1C3474]">
                                {isActive === "live"
                                  ? sectionTotals[selectedType].live.totalReceivedMarks
                                  : sectionTotals[selectedType].recorded.totalReceivedMarks}{" "}
                                / {/* {isActive === "live" */}
                                {sectionTotals[selectedType].live.totalMaxMarks}
                                {/* : sectionTotals[selectedType].recorded.totalMaxMarks} */}
                              </span>
                            </div>
                          )}

                          <div className="w-[587px] h-[49px] flex items-center justify-between p-[16px_18px] mt-4 rounded bg-[#07B42D]">
                            <span className="flex items-start w-[169px] h-[17px] font-normal text-[14px] leading-[16.94px] text-left text-[#FFFFFF]">
                              Total Marks Received
                              <Tooltip
                                title={
                                  <span
                                    className="flex items-center justify-center p-[8px_12px] rounded text-[12px] leading-[14.52px] text-[#FFFFFF]"
                                    style={{ width: "164px", height: "61px" }}
                                  >
                                    Total marks carry all the marks received in the entire exam.
                                  </span>
                                }
                                arrow
                                placement="right"
                              >
                                <InfoIcon className="w-4 h-4 ml-2 cursor-pointer" />
                              </Tooltip>
                            </span>
                            <span className="w-[64px] h-[17px] font-semibold text-[14px] leading-[16.94px] text-center text-[#FFFFFF]">
                              {isActive === "live" ? liveTotals.totalReceivedMarks : recordedTotals.totalReceivedMarks}{" "}
                              / {liveTotals.totalMaxMarks}
                            </span>
                          </div>

                          <div className="w-[587px] h-[151px] flex items-end justify-between rounded mt-4 mb-4">
                            {examType === "milestone" ? (
                              <ModuleCompletionCard
                                courseCompleted={passed[contestId]}
                                certificate_url={result?.module_certificate}
                                setShowCelebration={setShowCelebration}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {isActive === "leaderboard" && (
                      <>
                        <div className="mt-4 w-[587px] h-[460px] relative">
                          <div className="max-h-[460px] w-full no-scrollbar overflow-y-auto rounded-[4px] shadow-[0_0_0_0.5px_rgba(211,211,211,1)] border-[#ADADAD]">
                            <table className="w-full relative rounded-[4px]">
                              <thead className="h-[31px] bg-[#303030] sticky top-0">
                                <tr>
                                  <th className="text-left p-0 text-[12px] font-normal text-[#FFF] px-[16px] w-[69px] h-[31px] gap-[16px]">
                                    Position
                                  </th>
                                  <th className="text-left p-0 text-[12px] font-normal text-[#FFF] pl-[15px] w-[92px] h-[31px]">
                                    Name
                                  </th>
                                  <th className="text-left p-0 text-[12px] font-normal text-[#FFF] pl-[14px] w-[66px] h-[31px]">
                                    Max Marks
                                  </th>
                                  <th className="text-center p-0 text-[12px] font-normal text-[#FFF] w-[92px] h-[31px]">
                                    Marks Obtained
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {leaderboardData.map((row, index) => (
                                  <tr
                                    key={index}
                                    className={` ${row.name === currentStudent?.name ? "bg-[#1B3E99] hover:bg-[#1B3E99] sticky bottom-0 text-white font-semibold" : ""} ${index < 3 && row.name !== currentStudent?.name ? "bg-[#FEFFE4] font-normal hover:bg-[#F9F9F9]" : "font-normal "} `}
                                  >
                                    <td className="py-2 px-4 text-[12px] ">{row.rank}</td>
                                    <td className="py-2 px-4 text-[12px]">
                                      {row.name} {row.rank === 1 && <span className="text-yellow-400">{" ★★★"}</span>}
                                      {row.rank === 2 && <span className="text-yellow-400">{" ★★"}</span>}
                                      {row.rank === 3 && <span className="text-yellow-400">{" ★"}</span>}
                                      {/* {row.stars > 0 && <span className="text-yellow-400">{getStars(row.stars)}</span>} */}
                                    </td>
                                    <td className="py-2 px-4 text-[12px] ">{row.maxMarks}</td>
                                    <td className="text-center py-2 px-4 text-[12px]">
                                      {row.marksObtained}/{row.maxMarks}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </CustomScroll>
              </div>
            </CustomScroll>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ResultModal
