import { act, Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import Editor, { OnMount } from "@monaco-editor/react"
import Button from "core/components/editor/Button"
import { cn } from "core/lib/utils"
import useContestQuestionwiseSubmissionViewModel from "./ContestQuestionwiseSubmissionViewModal"
import Toast from "core/components/Toast"
import Loader from "core/components/Loader"
import { capitalize } from "core/utils/string"
import { useAuth } from "core/context/auth"
import { CheckboxEmptyIcon, CheckboxFilledIcon, MainLogo } from "core/constants/svgs"
import {
  STR_CODING_PROBLEMS,
  STR_CASE_STUDY_PROBLEMS,
  STR_SUBJECTIVE_PROBLEMS,
  STR_DESCRIPTION,
  STR_MCQS,
  STR_SUBMISSIONS,
  STR_SOLUTION,
  STR_ACCEPTED,
} from "core/constants/strings"
import SubmissionDisclosure from "core/components/editor/SubmissionDisclosure"
import Spinner from "core/components/Spinner"
import { isEmpty } from "core/utils/misc"
import { useApp } from "core/context/app"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "core/components/v2/ui/Resizable"
import { useNavigate, useParams } from "react-router-dom"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function ContestQuestionwiseSubmissionView() {
  const {
    toast,
    question,
    questionWithAnswer,
    language,
    live_submissions,
    recorded_submissions,
    codingProblems,
    isCodeSubmitting,
    submitCodeDetails,
    activeCodingTabIndex,
    codingProblemSolution,
    activeMcqQuestionIndex,
    isAllSubmissionsLoading,
    activeMcqQuestionDetails,
    activeCodingProblemIndex,
    handleCodingTabChange,
    changeToastDetails,
    changeToastVisibility,
    handleLanguageChange,
    fetchQuestionWithAnswer,
    fetchMcqQuestionDetails,
    fetchQuestion,
    getQuestionwiseSubmissionData,
    fetchMcqId,
    mcqId,
    live_response,
    recorded_response,
  } = useContestQuestionwiseSubmissionViewModel()

  const TABS = [STR_CODING_PROBLEMS, STR_MCQS, STR_SUBJECTIVE_PROBLEMS, STR_CASE_STUDY_PROBLEMS]
  const CODING_TABS = [STR_DESCRIPTION, STR_SUBMISSIONS, STR_SOLUTION]
  const { contest_id } = useParams()
  const [savedContest, setSavedContest] = useLocalStorage<any>(`contest-${contest_id}`, {})
  const LANGUAGE_LIST = {
    cpp: "C++",
    java: "Java",
    python: "Python",
    javascript: "JavaScript",
  }
  const contestId = window.location.pathname.split("/")?.[2]
  //check if recorded in url or not
  const isRecorded = window.location.pathname.split("/")?.[3] === "rec"
  let questionId: string
  let email: string
  let type: string
  if (isRecorded) {
    email = window.location.pathname.split("/")?.[4]
    questionId = window.location.pathname.split("/")?.[5]
    type = window.location.pathname.split("/")?.[6]
  } else {
    email = window.location.pathname.split("/")?.[3]
    questionId = window.location.pathname.split("/")?.[4]
    type = window.location.pathname.split("/")?.[5]
  }

  useEffect(() => {
    if (contestId && email && questionId && type) {
      if (type === "coding") {
        getQuestionwiseSubmissionData(email, contestId, questionId)
      } else if (mcqId && type === "mcq") {
        getQuestionwiseSubmissionData(email, contestId, mcqId)
      } else {
        getQuestionwiseSubmissionData(email, contestId, questionId)
      }
    }
  }, [contestId, email, questionId, type, mcqId])

  const [errorLine, setErrorLine] = useState<number | null>(null)
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null)
  const editorRef = useRef(null)
  const monacoRef = useRef(null)
  const decorationRef = useRef([])

  const { auth } = useAuth()
  const { student } = useApp()
  const handleEditorDidMount: OnMount = (editor: any, monacoInstance: any) => {
    editorRef.current = editor
    monacoRef.current = monacoInstance

    editor.onKeyDown((event: KeyboardEvent) => {
      if (event.code === monacoInstance.KeyCode.Enter) {
        editor.deltaDecorations(decorationRef.current, [])
      }
    })
  }

  const [showSolution, setShowSolution] = useState(false)

  const handleToggleSolution = () => {
    setShowSolution((prev) => !prev)
  }
  const handleErrorInfo = (info: { lineNumber?: number }) => {
    setErrorLine(info?.lineNumber!)
  }

  const highlightErrorLine = (errorLine: number) => {
    if (!editorRef.current || !monacoRef.current || !errorLine) return

    const editor = editorRef.current as any
    const monacoInstance = monacoRef.current as any

    if (decorationRef.current) {
      editor.deltaDecorations(decorationRef.current, [])
    }

    const highlightDecoration = editor.deltaDecorations(
      [],
      [
        {
          range: new monacoInstance.Range(
            language === "cpp" ? errorLine - 3 : language === "java" ? errorLine - 2 : errorLine,
            1,
            language === "cpp" ? errorLine - 3 : language === "java" ? errorLine - 2 : errorLine,
            1
          ),
          options: {
            isWholeLine: true,
            className: "myLineHighlight",
          },
        },
      ]
    )
    decorationRef.current = highlightDecoration
  }

  const navigate = useNavigate()

  useEffect(() => {
    const style = document.createElement("style")
    style.innerHTML = `
      .myLineHighlight {
        background-color: rgba(255, 0, 0, 0.3);
      }
    `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault()
        changeToastDetails(STR_ACCEPTED, "Code is being saved locally. No need to save it manually.")
        changeToastVisibility(true)
        console.log("Ctrl+S was pressed, but default action is prevented.")
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  useEffect(() => {
    highlightErrorLine(errorLine!)
  }, [errorLine])

  useLayoutEffect(() => {
    const launcherFrame = document.querySelector("#launcher-frame") as HTMLElement
    if (launcherFrame) {
      launcherFrame.style.display = "none"
    }
  })
  useEffect(() => {
    if (type && type === "coding" && questionId) {
      if (CODING_TABS[activeCodingTabIndex] === STR_SOLUTION) {
        fetchQuestionWithAnswer(questionId)
      }
    }
  }, [questionId, activeCodingTabIndex])

  useEffect(() => {
    if (type && type === "mcq" && questionId) {
      fetchMcqId(questionId)
    }
  }, [questionId, type])

  useEffect(() => {
    if (type && type === "mcq" && mcqId) {
      fetchMcqQuestionDetails(mcqId)
    } else if (type && type === "mcq" && questionId) {
      fetchMcqQuestionDetails(questionId)
    }
  }, [mcqId, type])

  useEffect(() => {
    if (type && type === "coding" && questionId) {
      fetchQuestion(questionId)
    }
  }, [questionId, activeCodingProblemIndex, type])

  useEffect(() => {
    if (!isRecorded) {
      if (live_submissions.length > 0) {
        setSelectedSubmission(live_submissions[0])
      }
    }
  }, [live_submissions])

  useEffect(() => {
    if (isRecorded) {
      if (recorded_submissions.length > 0) {
        setSelectedSubmission(recorded_submissions[0])
      }
    }
  }, [recorded_submissions])

  useEffect(() => {
    const disablePaste = (e: ClipboardEvent) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const container = document.getElementById("editor-container") as HTMLElement
    if (container) {
      container.addEventListener("paste", disablePaste, true)
      return () => container.removeEventListener("paste", disablePaste, true)
    }
  }, [])

  const ProblemHeader = useCallback(
    () => (
      <div className="space-y-6">
        <div className="flex items-center gap-2 text-new-neutral-light">
          {!isRecorded ? (
            live_submissions.length > 0 ? (
              <CheckboxFilledIcon className="h-6 w-6 text-new-solid-white" />
            ) : (
              <CheckboxEmptyIcon className="h-6 w-6" />
            )
          ) : recorded_submissions.length > 0 ? (
            <CheckboxFilledIcon className="h-6 w-6 text-new-solid-white" />
          ) : (
            <CheckboxEmptyIcon className="h-6 w-6" />
          )}
          <h3 className="text-new-solid-white">
            {("0" + (Number(activeCodingProblemIndex) + 1)).slice(-2)}. {question?.problem_name}
          </h3>
        </div>
        <div className="item-center flex gap-4">
          {question?.tags?.map((tag: string, i: number) => (
            <h5 key={i} className="rounded-sm bg-new-editor-dark-500 px-3 py-1 text-new-neutral-light">
              {capitalize(tag)}
            </h5>
          ))}
        </div>
      </div>
    ),
    [activeCodingProblemIndex, codingProblemSolution, codingProblems, question]
  )
  const formatTime = (time: any) => {
    if (time && typeof time === "object") {
      return `${time._seconds} seconds and ${time._nanoseconds} nanoseconds`
    }
    return time // or handle it as needed
  }
  return (
    <div className="h-screen">
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel defaultSize={40} minSize={20}>
          <div className="editor relative flex h-full w-full flex-col overflow-y-scroll bg-new-editor-dark-700">
            <div className="no-scrollbar sticky top-0 z-20 flex shrink-0 overflow-x-auto"></div>

            <div className="flex-1 space-y-6 p-6">
              {type === "coding" ? (
                <div className="space-y-6">
                  <div className="flex gap-2">
                    {CODING_TABS.map((tab, i) => (
                      <Button
                        key={i}
                        className={cn(
                          "flex-1",
                          CODING_TABS[activeCodingTabIndex] === tab
                            ? "bg-new-editor-dark-150 text-new-solid-white"
                            : "bg-new-editor-dark-200 text-new-neutral-light"
                        )}
                        onClick={() => handleCodingTabChange(i)}
                      >
                        <h4>{tab}</h4>
                      </Button>
                    ))}
                  </div>

                  <>
                    <ProblemHeader />
                    {CODING_TABS[activeCodingTabIndex] === STR_DESCRIPTION ? (
                      <div className="space-y-6 text-new-neutral-light">
                        <div
                          className="text-new-neutral-light"
                          dangerouslySetInnerHTML={{
                            __html: question?.question_details,
                          }}
                        />
                        {question?.input &&
                          question?.input?.map((eachInput: { value: string }, i: number) => (
                            <div
                              key={i}
                              className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                              dangerouslySetInnerHTML={{
                                __html: eachInput.value,
                              }}
                            />
                          ))}
                        {question?.constraints && (
                          <div
                            className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50"
                            dangerouslySetInnerHTML={{
                              __html: question?.constraints,
                            }}
                          />
                        )}
                      </div>
                    ) : CODING_TABS[activeCodingTabIndex] === STR_SOLUTION ? (
                      <div className="flex flex-col gap-6 text-new-neutral-light">
                        {questionWithAnswer?.solution &&
                          questionWithAnswer?.solution?.map(
                            (sol: { heading: string; description: string }, i: number) => (
                              <>
                                <h4>{sol.heading}</h4>
                                <pre
                                  key={i}
                                  className="rounded-sm bg-new-editor-dark-500 p-5 text-new-editor-dark-50 whitespace-pre-wrap text-sm"
                                  dangerouslySetInnerHTML={{
                                    __html: sol.description,
                                  }}
                                />
                              </>
                            )
                          )}
                      </div>
                    ) : (
                      <div className="flex flex-col gap-6 text-new-neutral-light">
                        <div className="flex items-center gap-4 rounded-sm bg-new-editor-dark-500 px-6 py-3">
                          <h4 className="text-new-solid-white">
                            {!isRecorded ? (
                              <>
                                {live_submissions?.length > 0
                                  ? Math.max(...live_submissions?.map((s: { score?: number }) => s?.score))
                                  : 0}
                                /
                                {live_submissions?.length > 0
                                  ? Math.max(
                                      ...live_submissions?.map((s: { total_testcase: string }) =>
                                        parseInt(s?.total_testcase)
                                      )
                                    )
                                  : 0}
                              </>
                            ) : (
                              <>
                                {recorded_submissions?.length > 0
                                  ? Math.max(...recorded_submissions?.map((s: { score?: number }) => s?.score))
                                  : 0}
                                /
                                {recorded_submissions?.length > 0
                                  ? Math.max(
                                      ...recorded_submissions?.map((s: { total_testcase: string }) =>
                                        parseInt(s?.total_testcase)
                                      )
                                    )
                                  : 0}
                              </>
                            )}
                          </h4>
                          <p>Your Best score</p>
                        </div>
                        <h4 className="mt-2 text-new-solid-white">Your submissions :</h4>
                        {isAllSubmissionsLoading && isEmpty(codingProblems) ? (
                          <Loader dark />
                        ) : (
                          <div className="flex flex-col gap-6">
                            {isCodeSubmitting && (
                              <div className="mb-6 flex flex-col items-center gap-2 rounded-sm bg-new-editor-dark-500 px-6 py-3">
                                <Spinner dark small />
                                <h4 className="text-new-solid-white">
                                  {submitCodeDetails?.description?.length > 0
                                    ? submitCodeDetails?.description
                                    : "Submitting..."}
                                </h4>
                              </div>
                            )}
                            {!isRecorded ? (
                              live_submissions.length > 0 ? (
                                live_submissions?.map((submission: any, i: number) => {
                                  const isAccepted =
                                    submission?.total_testcase > 0 && submission?.score === submission?.total_testcase
                                  const isPartiallyAccepted = !isAccepted && submission?.score > 0
                                  const isTimeLimitExceeded = submission?.description === "Time Limit Exceeded"
                                  const isError = submission?.total_testcase === 0 && submission?.stderr?.length > 0
                                  return (
                                    <div
                                      key={i}
                                      className="space-y-2 rounded-sm cursor-pointer bg-new-editor-dark-500 px-6 py-3 text-new-editor-dark-50"
                                      onClick={() => setSelectedSubmission(submission)}
                                    >
                                      {/* {selectedSubmission && ( */}
                                      <div className="flex justify-between gap-1 flex-wrap">
                                        <h5>
                                          Language:{" "}
                                          {submission?.language?.length > 0
                                            ? LANGUAGE_LIST?.[submission?.language as keyof typeof LANGUAGE_LIST]
                                            : "Not Specified"}
                                        </h5>
                                        <h5 className="text-new-editor-dark-100">{formatTime(submission?.time)}</h5>
                                      </div>
                                      {/* )} */}
                                      {submission?.compile_output?.length > 0 || isError ? (
                                        <SubmissionDisclosure label="Error">
                                          <p>{isError ? submission?.stderr[0] : submission?.compile_output}</p>
                                        </SubmissionDisclosure>
                                      ) : (
                                        <div className="space-y-1">
                                          <h4
                                            className={cn(
                                              isAccepted
                                                ? "text-new-success"
                                                : isPartiallyAccepted
                                                  ? "text-new-misc-yellow"
                                                  : "text-new-failure"
                                            )}
                                          >
                                            {isAccepted
                                              ? "Accepted"
                                              : isPartiallyAccepted
                                                ? "Partially Accepted"
                                                : isTimeLimitExceeded
                                                  ? "Time Limit Exceeded"
                                                  : "Wrong Answer"}
                                          </h4>
                                          {!isTimeLimitExceeded && (
                                            <p>
                                              Test cases passed {submission?.score}/{submission?.total_testcase}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )
                                })
                              ) : (
                                <div className="flex justify-center items-center h-[300px]">
                                  <p className="text-[14px] text-new-neutral-light">No submissions found</p>
                                </div>
                              )
                            ) : recorded_submissions.length > 0 ? (
                              recorded_submissions?.map((submission: any, i: number) => {
                                const isAccepted =
                                  submission?.total_testcase > 0 && submission?.score === submission?.total_testcase
                                const isPartiallyAccepted = !isAccepted && submission?.score > 0
                                const isTimeLimitExceeded = submission?.description === "Time Limit Exceeded"
                                const isError = submission?.total_testcase === 0 && submission?.stderr?.length > 0
                                return (
                                  <div
                                    key={i}
                                    className="space-y-2 rounded-sm bg-new-editor-dark-500 px-6 py-3 text-new-editor-dark-50"
                                    onClick={() => setSelectedSubmission(submission)}
                                  >
                                    {/* {selectedSubmission && ( */}
                                    <div className="flex justify-between gap-1 flex-wrap">
                                      <h5>
                                        Language:{" "}
                                        {submission?.language?.length > 0
                                          ? LANGUAGE_LIST?.[submission?.language as keyof typeof LANGUAGE_LIST]
                                          : "Not Specified"}
                                      </h5>
                                      <h5 className="text-new-editor-dark-100">{submission?.time}</h5>
                                    </div>
                                    {/* )} */}

                                    {submission?.compile_output?.length > 0 || isError ? (
                                      <SubmissionDisclosure label="Error">
                                        <p>{isError ? submission?.stderr[0] : submission?.compile_output}</p>
                                      </SubmissionDisclosure>
                                    ) : (
                                      <div className="space-y-1">
                                        <h4
                                          className={cn(
                                            isAccepted
                                              ? "text-new-success"
                                              : isPartiallyAccepted
                                                ? "text-new-misc-yellow"
                                                : "text-new-failure"
                                          )}
                                        >
                                          {isAccepted
                                            ? "Accepted"
                                            : isPartiallyAccepted
                                              ? "Partially Accepted"
                                              : isTimeLimitExceeded
                                                ? "Time Limit Exceeded"
                                                : "Wrong Answer"}
                                        </h4>
                                        {!isTimeLimitExceeded && (
                                          <p>
                                            Test cases passed {submission?.score}/{submission?.total_testcase}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )
                              })
                            ) : (
                              <div className="flex justify-center items-center h-[300px]">
                                <p className="text-[14px] text-new-neutral-light">No submissions found</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </div>
              ) : type === "mcq" ? (
                <div className="flex flex-col gap-3 text-new-editor-dark-50">
                  <div
                    className={cn(
                      "flex items-center gap-2 rounded-sm border bg-new-editor-dark-500 px-6 py-3 border-new-neutral-light "
                    )}
                  >
                    {!isRecorded ? (
                      !isEmpty(live_response) ? (
                        <CheckboxFilledIcon className="h-4 w-4 text-new-solid-white" />
                      ) : (
                        <CheckboxEmptyIcon className="h-4 w-4" />
                      )
                    ) : !isEmpty(recorded_response) ? (
                      <CheckboxFilledIcon className="h-4 w-4 text-new-solid-white" />
                    ) : (
                      <CheckboxEmptyIcon className="h-4 w-4" />
                    )}

                    <div className="flex flex-1 items-center justify-between">
                      <h4 className="line-clamp-1">Question 1</h4>
                      {/* <h5 className="shrink-0">Score: {activeMcqQuestionDetails?.score}</h5> */}
                    </div>
                  </div>
                  {/* )) */}
                  {/* } */}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={60} minSize={20}>
          <div className="editor flex-1 flex h-full flex-col overflow-y-auto bg-new-editor-dark-700">
            <div className="flex shrink-0 items-center gap-4 p-6"></div>
            {type === "coding" ? (
              <>
                <div className="flex-1 px-6 pb-6">
                  <div
                    id="editor-container"
                    className="flex h-full flex-col divide-y divide-new-neutral-light rounded-sm border border-new-neutral-dark"
                  >
                    <div className="flex items-center gap-4 bg-new-editor-dark-600 py-2 px-4">
                      <select
                        className="w-28 rounded-sm bg-new-editor-dark-700 py-1 px-4 text-sm font-medium leading-normal text-new-neutral-light"
                        value={language}
                        onChange={handleLanguageChange}
                      >
                        {Object.entries(LANGUAGE_LIST).map(([langId, langName]) => (
                          <option key={langId} value={langId}>
                            {langName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      {selectedSubmission ? (
                        <div className="space-y-2 rounded-sm bg-new-editor-dark-800 px-6 py-3 text-new-editor-dark-50">
                          <div className="flex justify-between gap-1 flex-wrap">
                            <pre className="whitespace-pre-wrap text-sm">{selectedSubmission?.code}</pre>
                          </div>
                        </div>
                      ) : (
                        <div className="space-y-2 rounded-sm bg-new-editor-dark-800 px-6 py-3 text-new-editor-dark-50 flex justify-between gap-1 flex-wrap">
                          <pre className="whitespace-pre-wrap text-sm ">No code submissions</pre>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : type === "mcq" ? (
              <>
                <div className="flex px-6 gap-6 flex-col">
                  <div className="w-full space-y-4 rounded-sm border border-new-neutral-light bg-new-editor-dark-500 px-6 py-4 text-new-editor-dark-50">
                    <div className="flex gap-2 text-new-solid-white">
                      <h3 className="h-6 w-6 shrink-0">{activeMcqQuestionIndex + 1}.</h3>
                      <div className="flex w-full max-w-[calc(100%-32px)] flex-col gap-4">
                        {!isEmpty(activeMcqQuestionDetails?.question) &&
                          activeMcqQuestionDetails?.question?.map((content: any, i: number) => (
                            <Fragment key={i}>
                              {content?.type === "text" ? (
                                <h3>{content?.content}</h3>
                              ) : content?.type === "image" ? (
                                <img
                                  src={content?.content}
                                  alt="question banner"
                                  className="w-fit max-w-full"
                                  key={i}
                                />
                              ) : (
                                <div
                                  className="w-full overflow-x-auto whitespace-pre border bg-new-editor-dark-700 p-2 font-mono"
                                  key={i}
                                >
                                  <p>{content?.content}</p>
                                </div>
                              )}
                            </Fragment>
                          ))}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      {!isEmpty(activeMcqQuestionDetails?.options) &&
                        Object.keys(activeMcqQuestionDetails?.options)?.length > 0 &&
                        Object.keys(activeMcqQuestionDetails?.options)?.map((optionKey, i) => (
                          <div key={i} className="flex w-full items-center gap-2">
                            <span className="h-6 w-6 shrink-0 self-start">{i + 1})</span>
                            <div className="flex w-full max-w-[calc(100%-64px)] flex-col gap-2">
                              {activeMcqQuestionDetails?.options[optionKey]?.map((option: any) =>
                                option?.type === "text" ? (
                                  <p key={option?.id} className="text-sm font-medium">
                                    {option?.content}
                                  </p>
                                ) : option?.type === "image" ? (
                                  <img
                                    key={option?.id}
                                    src={option?.content}
                                    alt="question banner"
                                    className="w-fit max-w-full"
                                  />
                                ) : (
                                  <div
                                    key={option?.id}
                                    className="w-full overflow-x-auto whitespace-pre border bg-new-editor-dark-700 p-2 font-mono"
                                  >
                                    <p className="">{option?.content}</p>
                                  </div>
                                )
                              )}
                            </div>

                            <button
                              className="h-6 w-6 shrink-0 hover:text-new-solid-white "
                              // onClick={() => handleOptionSelect(optionKey)}
                            >
                              {!isRecorded ? (
                                live_response === optionKey ? (
                                  <CheckboxFilledIcon className="h-6 w-6 text-new-solid-white" />
                                ) : (
                                  <CheckboxEmptyIcon className="h-6 w-6" />
                                )
                              ) : recorded_response === optionKey ? (
                                <CheckboxFilledIcon className="h-6 w-6 text-new-solid-white" />
                              ) : (
                                <CheckboxEmptyIcon className="h-6 w-6" />
                              )}
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* )} */}
                  <div className="relative flex flex-col">
                    <div className="w-full space-y-4 rounded-sm border border-new-neutral-light bg-new-editor-dark-500 px-6 py-4 text-new-editor-dark-50">
                      <div className="w-full">
                        <button
                          onClick={handleToggleSolution}
                          className="bg-blue-500 text-white p-2 rounded-sm hover:bg-blue-600 w-full"
                        >
                          {showSolution ? "Hide Solution" : "Show Solution"}
                        </button>
                      </div>
                      {showSolution && activeMcqQuestionDetails && (
                        <div className="flex flex-col gap-2 mt-4">
                          <h3 className="text-new-solid-white">Solution:</h3>
                          <p className="text-sm font-medium">
                            {`${activeMcqQuestionDetails.solution}) `}
                            {activeMcqQuestionDetails.options[activeMcqQuestionDetails.solution]?.[0]?.content}
                          </p>
                          <h3 className="text-new-solid-white">
                            {activeMcqQuestionDetails?.solution_description &&
                            activeMcqQuestionDetails?.solution_description?.length > 0
                              ? "Solution Description:"
                              : ""}
                          </h3>
                          {activeMcqQuestionDetails?.solution_description &&
                            activeMcqQuestionDetails?.solution_description?.length > 0 && (
                              <p className="text-sm font-medium">{`${activeMcqQuestionDetails.solution_description?.map(
                                (a: any) => {
                                  a
                                }
                              )}`}</p>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
