import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import { API_STUDENT, STR_SAVE_SHORT, STR_READ_SHORT } from "core/constants/strings"
import ShortDataSource from "data/dataSource/Student/ShortDataSource"
import { Auth } from "domain/model/Auth"

const server = new Server()

export class ShortAPIDataSourceImpl implements ShortDataSource {
  async saveShort(
    auth: Auth,
    sessionID: string,
    chapterName: string,
    shortTitle: string,
    isSaved: boolean
  ): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, STR_SAVE_SHORT),
        {
          sessionID,
          chapterName,
          shortTitle,
          isSaved,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      console.log("Error in saving short in api file:", error)
    }
  }

  async readShort(auth: Auth, sessionID: string, chapterName: string, shortTitle: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, STR_READ_SHORT),
        {
          sessionID,
          chapterName,
          shortTitle,
        },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )

      return response
    } catch (error) {
      console.log("Error in reading short in API file:", error)
    }
  }
}
