import { useAuth } from "core/context/auth"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import GetAllContestsList from "domain/useCase/User/Contest/GetAllContestsList"
import { useState } from "react"

export default function CreateBatchViewModel() {
  const [_placementBatchName, _setPlacementBatchName] = useState("")
  const [_bridgeBatchName, _setBridgeBatchName] = useState("")
  const [selectBatch, setSelectBatch] = useState<any>([])
  const [allContestsList, setAllContestsList] = useState<any>([])
  const [_currentSelectContest, _setCurrentSelectContest] = useState<any>([])
  const [passingScore, setPassingScore] = useState<any>()
  const [loading, setLoading] = useState(false)
  const { auth } = useAuth()

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const getAllContestsUseCase = new GetAllContestsList(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))

  function handlePlacementCourseName(e: any) {
    _setPlacementBatchName(e.target.value)
  }

  function handleBridgeCourseName(e: any) {
    _setBridgeBatchName(e.target.value)
  }

  function handleContestChange(e: any) {
    const selectedContest = allContestsList.find(
      (contest: { id: string; name: string }) => contest.name === e.target.value
    )
    if (
      selectedContest &&
      !_currentSelectContest.some((contest: { id: string }) => contest.id === selectedContest.id)
    ) {
      _setCurrentSelectContest((prevSelectContest: any) => [...prevSelectContest, selectedContest])
    }
  }

  async function fetchAllContests() {
    const allContestsListResponse = await getAllContestsUseCase.invoke(auth)
    if (!allContestsListResponse?.success) {
      return
    }
    setAllContestsList(allContestsListResponse?.data)
  }

  async function getBatches() {
    const response = await getAllBatchesUseCase.invoke(auth)
    if (response.success) {
      setSelectBatch(response.data)
    }
  }

  return {
    selectBatch,
    _currentSelectContest,
    allContestsList,
    _placementBatchName,
    _bridgeBatchName,
    loading,
    handleContestChange,
    handlePlacementCourseName,
    handleBridgeCourseName,
    _setCurrentSelectContest,
    getBatches,
    fetchAllContests,
    passingScore,
    setPassingScore,
  }
}
