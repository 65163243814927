import { LineChartIcon } from "core/constants/svgs"
import { CustomScroll } from "react-custom-scroll"

const DetailedProgressGraphView = ({
  detailedProgress,
  activeButton,
  graphYAxisString,
}: {
  detailedProgress: any[]
  activeButton: string
  graphYAxisString: string
}) => {
  return (
    <div className="gap-[15px] h-[85%] flex justify-start relative pr-4 pb-4">
      <div className="flex flex-col-reverse gap-0 w-[21px] items-center justify-center bg-[#f8f8f8] text-[#636363] rounded-sm text-sm font-medium">
        <div className="w-[313px] text-center text-sm -rotate-90">{graphYAxisString}</div>
      </div>
      <div className="flex flex-col w-[100%] justify-end gap-4 relative">
        <div className="flex justify-around items-end h-[80%]">
          {detailedProgress.map((item: any) => {
            if (activeButton === "Mentor Sessions") {
              return <div className="bg-[#e3e3e3] h-1 w-9 rounded-tl rounded-tr rounded-bl-sm rounded-br-sm" />
            }
            let height = 0
            let calculatedHeight = 0
            if (activeButton === "Problems Solved") {
              height = detailedProgress.reduce((acc: number, item: any) => Math.max(acc, item.progress.attempted), 0)
              calculatedHeight = (item.progress.attempted * 100) / height
            }
            if (activeButton === "Classes Scheduled") {
              if (item.progress.classes.present + item.progress.classes.absent === 0) {
                height = 0
              } else {
                height = item.progress.classes.present + item.progress.classes.absent
                calculatedHeight = (item.progress.classes.present * 100) / height
              }
            }
            if (activeButton === "Exams") {
              if (item.progress.exams.averageTotalScore === 0) {
                height = 0
              } else {
                height = 100
                calculatedHeight = item.progress.exams.averageScore
              }
            }
            const dynamicHeightClass = `${calculatedHeight}%`
            return height > 0 ? (
              <div
                style={{ height: dynamicHeightClass }}
                className={`bg-[#152356] p-0 w-9 rounded-tl rounded-tr rounded-bl-sm rounded-br-sm ${dynamicHeightClass}`}
              ></div>
            ) : (
              activeButton !== "Problems Solved" && (
                <div className="bg-[#e3e3e3] h-1 w-9 rounded-tl rounded-tr rounded-bl-sm rounded-br-sm" />
              )
            )
          })}
        </div>
        <div className="flex justify-around">
          {detailedProgress.map((item: any) => (
            <div className="text-[#636363] text-[10px] font-normal">{item.name}</div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DetailedProgressGraphView
