import React from "react"
import Button from "core/components/new/Button"
import Toast from "core/components/Toast"
import ContestPopup from "../components/ContestPopup"
import ConditionalLoader from "core/components/ConditionalLoader"
import useViewModel from "./ViewModel"
import { STR_PLACEHOLDER_IMAGE } from "core/constants/strings"
import { addMinutes, format, set } from "date-fns"
import { Tooltip } from "@mui/material"
import { isEmpty } from "core/utils/misc"
import { useEffect } from "react"
import contestImage from "assets/images/contest.png"
import { CustomScroll } from "react-custom-scroll"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import ContestConfirmationPopup from "../components/ContestConfirmationPopup"
import ArrowGoBack from "assets/svgs/ArrowGoBack"

export default function ContestStart() {
  const {
    loadingContest,
    contest,
    openPopup,
    contest_id,
    isPast,
    isMock,
    isRegistered,
    isApproved,
    enterContestDisabled,
    fetchedRegisteredStatus,
    registering,
    toast,

    student,
    openConfirmationModal,
    setOpenConfirmationModal,
    reattempt,
    secondAttempt,
    setSecondAttempt,
    onOpenPopup,
    handleRegister,
    redirect,
    onClosePopup,
    changeToastVisibility,
    reattemptAttemptedContest,
    checkContestStatus,
    fetchStudentContestDetails,
    fetchContest,
    page,
    limit,
    navigate,
    tab,
    handleTabChange,
    getQuestionType,
    getQuestionDescription,
    updateRegisteredStatus,
    setIsMock,
    checkNewestFlow,
    handleGoBack,
  } = useViewModel()

  const handleModal = (visible: boolean) => {
    setOpenConfirmationModal(visible)
  }

  useEffect(() => {
    checkContestStatus()
    const interval = setInterval(checkContestStatus, 1000)
    return () => clearInterval(interval)
  }, [registering, contest])

  useEffect(() => {
    if (contest_id) {
      fetchContest()
      if (!isEmpty(contest) && (!contest?.flowType || contest?.flowType !== "newFlow")) {
        fetchStudentContestDetails()
      }
    }
  }, [contest_id])

  useEffect(() => {
    if (contest_id) {
      updateRegisteredStatus(contest_id)
    }
  }, [contest_id, registering])

  useEffect(() => {
    handleTabChange("general")
  }, [])

  useEffect(() => {
    if (!isPast) return
    if (isMock) return
  }, [student, contest])

  const duration = parseInt(contest?.duration)
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)
  useEffect(() => {
    const contestUrl = window.location.pathname.split("/")?.[3]
    if (contestUrl === "mock") {
      setIsMock(true)
    } else {
      setIsMock(false)
    }
  }, [isMock])
  const batch = window.location.pathname.split("/")?.[2]
  return (
    <DashboardLayoutv2 isLoading={loadingContest}>
      <ContestPopup
        data={contest}
        open={openPopup}
        onClose={onClosePopup}
        onClick={() => {
          isMock && isMock === true
            ? navigate(`/contests/${batch}/mock/${contest_id}/editor`)
            : secondAttempt === true
              ? setOpenConfirmationModal(true)
              : redirect()
        }}
      />
      {openConfirmationModal ? (
        <ContestConfirmationPopup
          show={openConfirmationModal}
          isAlertPopup={true}
          onSubmit={() => {
            handleModal(false)
            reattemptAttemptedContest()
            redirect()
          }}
          onClose={() => {
            handleModal(false)
          }}
        />
      ) : (
        <></>
      )}

      <ConditionalLoader
        isLoading={isEmpty(contest)}
        loader={
          <div className="flex h-[100px] w-full items-center justify-center">No contest found/Not registered</div>
        }
      >
        <div className="relative flex">
          <div className="flex w-[65%] flex-col">
            <div className="space-y-6 p-6">
              <div className="w-full max-w-full rounded-[8px]">
                <img src={contestImage} alt="banner" className="h-full w-full object-cover" />
              </div>
              <div className="space-y-2">
                <h2 className="text-[#162456] font-inter font-semibold">{contest?.name}</h2>
                <h4 className="text-[#646464] font-normal font-inter">
                  {isMock === false ? (
                    contest?.start_time &&
                    `${format(new Date(contest?.start_time * 1000), "do MMM yyyy")} | ${format(
                      addMinutes(new Date(contest?.start_time * 1000), 0),
                      "hh:mm a"
                    )} IST | `
                  ) : (
                    <></>
                  )}
                  {contest?.duration && (
                    <>
                      Duration {hours > 0 && `${hours}Hr${hours > 1 ? "s " : " "}`}
                      {minutes > 0 && `${minutes}min${minutes > 1 ? "s" : ""}`}
                    </>
                  )}
                </h4>
              </div>
              <div className="flex gap-[4px] flex-wrap ">
                <button
                  className={`text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${tab === "general" ? "bg-[#162456] text-white" : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"}`}
                  onClick={() => handleTabChange("general")}
                >
                  General Information
                </button>
                <button
                  className={`text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${tab === "question" ? "bg-[#162456] text-white" : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"}`}
                  onClick={() => handleTabChange("question")}
                >
                  Question Pattern
                </button>
                <button
                  className={`text-[#646464] font-inter font-medium text-[12px] rounded-[4px] py-[6px] px-4 ${tab === "passing_criteria" ? "bg-[#162456] text-white" : "bg-[#F9F9F9] hover:bg-[#162456] hover:text-white"}`}
                  onClick={() => handleTabChange("passing_criteria")}
                >
                  Passing Criteria
                </button>
              </div>

              {tab === "general" && (
                <div className="space-y-2">
                  <p className="text-new-neutral-dark font-inter font-medium text-md">General Information</p>
                  <ol className="space-y-[2ch]">
                    {contest?.general_information?.split("\n").map((info: string, index: number) => (
                      <li key={index} className="text-[#333333]">
                        {index + 1}. {info}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
              {tab === "question" && (
                <div className="space-y-2 text-new-neutral-dark">
                  {contest && (
                    <div className="space-y-[2ch] font-medium">
                      {[contest.mcq_lis, contest.question_lis, contest.subjective_lis, contest.casestudy_lis]
                        .filter((questionList) => questionList && questionList.length > 0)
                        .map((questionList, index) => (
                          <div key={index} className="space-y-[1ch]">
                            <p className="text-[#1C3474] font-inter font-semibold text-sm mb-[9px]">
                              {index + 1}. {getQuestionType(questionList)}
                            </p>
                            <p className="text-[#646464] font-inter font-normal text-[14px] leading-[21px]">
                              {getQuestionDescription(questionList)}
                            </p>
                            <ul className="list-disc pl-8 font-inter text-[14px] text-[#333333] font-normal">
                              <li>{questionList.length} Questions</li>
                              <li>
                                {questionList.reduce((total: number, question: any) => total + question.score, 0)} Marks
                              </li>
                            </ul>
                          </div>
                        ))}
                    </div>
                  )}
                  {!(
                    contest?.mcq_lis?.length > 0 ||
                    contest?.question_lis?.length > 0 ||
                    contest?.subjective_lis?.length > 0 ||
                    contest?.casestudy_lis?.length > 0
                  ) && <p>No questions found</p>}
                </div>
              )}
              {tab === "passing_criteria" && (
                <div className="space-y-2">
                  <h3 className="text-[#1C3474] font-inter font-semibold text-sm mb-[9px]">
                    Passing Criteria: <span>{contest?.passing_criteria}</span>
                  </h3>
                </div>
              )}
            </div>
          </div>

          <div className="mt-6 w-[35%] mr-6 overflow-hidden">
            <div className="flex justify-between items-center mb-[14px]">
              <div className="flex justify-between items-center"></div>
              <button
                className="flex h-[35px] w-[108px] items-center justify-center text-[14px] gap-1 text-gray-700 border px-2 rounded bg-white hover:bg-gray-100"
                onClick={handleGoBack}
              >
                <ArrowGoBack className="w-4 h-[1.5rem]" />
                Go Back
              </button>
            </div>
            <div className="sticky flex h-[426px] flex-col justify-between border-[0.5px] border-[#D3D3D3] rounded-[8px] shadow-[-1px_1px_3px_0px_rgba(34,41,48,0.15)]">
              <CustomScroll heightRelativeToParent="100%" className="rounded-[4px] h-[50px]">
                <div className="p-6">
                  <div className="space-y-2">
                    <p className="text-[#162456] font-inter text-lg font-semibold leading-[21px] mb-4">
                      General Guideline
                    </p>
                    <ol className="space-y-[2ch] font-inter text-[#646464] font-normal leading-[21px] text-[14px]">
                      <li>
                        <strong>No Tab/Window Switching:</strong> Switching between tabs or windows during the exam is
                        prohibited. Doing so will result in the automatic submission of your exam.
                      </li>
                      <li>
                        <strong>Window Management:</strong> Do not minimize or restore the exam window, as this action
                        will also lead to the automatic submission of your exam.
                      </li>
                      <li>
                        <strong>No Developer Tools:</strong> Attempting to open developer tools (inspect element) will
                        cause your exam to end automatically.
                      </li>
                      <li>
                        <strong>Disable Notifications:</strong> Please disable desktop notifications before starting the
                        exam. Any notifications that appear during the exam will be considered a violation of the
                        guidelines and will result in the automatic submission of your exam.
                      </li>
                      <li>
                        <strong>Stable Internet Connection:</strong> Ensure a stable internet connection to complete the
                        exam smoothly and without interruptions.
                      </li>
                    </ol>
                  </div>
                </div>
              </CustomScroll>
            </div>

            <div className="space-y-4 bg-[#EEF8FD] border-[#5DC1F3] border-[0.5px] rounded-[8px] p-4 mt-4">
              <p className="text-[#162456] font-inter font-semibold text-lg flex justify-center">{contest?.name}</p>
              <p className="font-inter font-medium text-[#646464] text-[12px] flex justify-center">
                {!isMock ? (
                  contest?.start_time &&
                  `${format(new Date(contest?.start_time * 1000), "do MMM, yyyy")} | ${format(
                    addMinutes(new Date(contest?.start_time * 1000), 0),
                    "hh:mm a"
                  )} IST | `
                ) : (
                  <></>
                )}
                {contest?.duration && (
                  <>
                    Duration: {hours > 0 && `${hours}Hr${hours > 1 ? "s " : " "}`}
                    {minutes > 0 && `${minutes}min${minutes > 1 ? "s" : ""}`}
                  </>
                )}
              </p>
              <div className="flex justify-center items-center px-4">
                {contest?.type === "instant" || !isPast ? (
                  isRegistered ? (
                    <Tooltip title={enterContestDisabled ? "Contest has not started yet." : ""} placement="top">
                      <span>
                        <Button
                          className="disabled:opacity-50 font-inter font-medium leading-[17px] rounded-[4px] px-4 py-[9px] text-[14px]"
                          disabled={enterContestDisabled}
                          onClick={() => onOpenPopup(contest_id as string)}
                          success
                        >
                          Start Exam
                        </Button>
                      </span>
                    </Tooltip>
                  ) : fetchedRegisteredStatus ? (
                    <Button
                      loading={registering}
                      disabled={registering}
                      onClick={handleRegister}
                      className="bg-[#07B42D] rounded-[4px] px-4 py-[9px] text-[14px] font-inter font-medium leading-[17px]"
                    >
                      Register Now
                    </Button>
                  ) : (
                    <Button disabled>Fetching registration status</Button>
                  )
                ) : (
                  <Button
                    onClick={onOpenPopup}
                    className="bg-[#07B42D] rounded-[4px] px-4 py-[9px] text-[14px] font-inter font-medium leading-[17px]"
                  >
                    {reattempt == true ? "Re-attempt Exam" : "Start Exam"}
                  </Button>
                )}
              </div>
              <div className="space-y-0">
                {!isMock ? (
                  <p className="font-inter font-normal text-[#646464] text-[12px] flex justify-center">Last date:</p>
                ) : (
                  <></>
                )}
                {!isMock ? (
                  contest?.start_time &&
                  contest?.duration && (
                    <p className="text-[#DC2626] flex justify-center font-inter font-semibold text-[14px]">
                      {`${format(addMinutes(new Date(contest?.start_time * 1000), contest?.duration), "do MMM yyyy")}
                    | ${format(addMinutes(new Date(contest?.start_time * 1000), contest?.duration), "hh:mm a")}`}
                    </p>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </ConditionalLoader>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
