import { STR_FAILURE, STR_OPTIONS, STR_QUESTION, STR_SOLUTION, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { useNavigate, useParams } from "react-router-dom"
import { genError } from "core/utils/string"
import React from "react"
import McqAPIDataSourceImpl from "data/API/Admin/McqAPIDataSourceImpl"
import { McqRepositoryImpl } from "data/repository/Admin/McqRepositoryImpl"
import { CreateMcqQuestion } from "domain/useCase/Admin/Mcq/CreateMcqQuestion"
import { GetMcqQuestion } from "domain/useCase/Admin/Mcq/GetMcqQuestion"
import { UpdateMcqQuestion } from "domain/useCase/Admin/Mcq/UpdateMcqQuestion"
import DocDataSourceImpl from "data/API/Admin/DocDataSourceImpl"
import { DocRepositoryImpl } from "data/repository/Admin/DocRepositoyImpl"
import { UploadImage } from "domain/useCase/Admin/Document/UploadImage"
import { UploadDocument } from "domain/useCase/Admin/Document/UploadDocument"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { GetModuleClasses } from "domain/useCase/Admin/CreateClass/GetModuleClasses"
import { UploadImageMCQ } from "domain/useCase/Admin/Document/UploadImageMCQ"

export default function McqEditViewModel() {
  interface Field {
    type: string
    content: string
    file?: Blob
  }

  const [activeMcqTab, setActiveMcqTab] = React.useState<number>(0)
  const [tags, setTags] = React.useState<any[]>([])
  const [tag, setTag] = React.useState<string>("")
  const [course, setCourse] = React.useState<string>("")
  const [module, setModule] = React.useState<string>("")
  const [topic, setTopic] = React.useState<string>("")
  const [difficulty, setDifficulty] = React.useState<string>("")
  const [name, setName] = React.useState<string>("")
  const [question, setQuestion] = React.useState<any>([])
  const [activeOptionTab, setActiveOptionTab] = React.useState<number>(1)
  const [options, setOptions] = React.useState<any>({
    "1": [],
    "2": [],
    "3": [],
    "4": [],
  })
  const [solution, setSolution] = React.useState<string>("")
  const [allFieldsValid, setAllFieldsValid] = React.useState<boolean>(false)
  const [pageLoading, setPageLoading] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const TABS = [STR_QUESTION, STR_OPTIONS, STR_SOLUTION]

  const navigate = useNavigate()
  const { id } = useParams()
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const [allCourses, setAllCourses] = React.useState<any[]>([])
  const [allModules, setAllModules] = React.useState<any[]>([])
  const [allTopics, setAllTopics] = React.useState<any[]>([])
  const [solutionFields, setSolutionFields] = React.useState<Field[]>([])

  const createMcqQuestionUseCase = new CreateMcqQuestion(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const getMcqQuestionUseCase = new GetMcqQuestion(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const updateMcqQuestionUseCase = new UpdateMcqQuestion(new McqRepositoryImpl(new McqAPIDataSourceImpl()))

  const uploadImageUseCase = new UploadImage(new DocRepositoryImpl(new DocDataSourceImpl()))

  const uploadImageMCQUseCase = new UploadImageMCQ(new DocRepositoryImpl(new DocDataSourceImpl()))

  const uploadDocumentUseCase = new UploadDocument(new DocRepositoryImpl(new DocDataSourceImpl()))

  const getAllCoursesUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getAllModulesUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )

  const getAllTopicsUseCase = new GetModuleClasses(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  /**
   * Fetch all the courses or tracks
   */
  const fetchAllCourses = async () => {
    setLoading(true)
    const response = await getAllCoursesUseCase.invoke(auth)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the courses"))
      changeToastVisibility(true)
      return
    }
    setAllCourses(response.data)
  }
  /**
   * Fetch all the Modules based on the selected course
   * @param course
   */
  const fetchAllModules = async (course: string) => {
    if (!course) return

    setLoading(true)
    const response = await getAllModulesUseCase.invoke(auth, course)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the modules"))
      changeToastVisibility(true)
      return
    }
    setAllModules([...response.data?.modules, "other"])
  }

  /**
   * Fetch all the topics based on selected course
   * @param module
   */
  const fetchAllTopics = async (module: string) => {
    if (!module || module == "other") return

    setLoading(true)
    const response = await getAllTopicsUseCase.invoke({
      id_token: auth.id_token,
      module: module,
    })
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Failed to load the topics"))
      changeToastVisibility(true)
      return
    }
    const data = response.data.map((d: any) => d.topic)
    setAllTopics(data)
  }

  const fetchMcqQuestionDetails = async () => {
    setPageLoading(true)
    const response = await getMcqQuestionUseCase.invoke(auth, id as string)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in fetching question details"))
      changeToastVisibility(true)
      setPageLoading(false)
      return
    }

    const questionData = response?.data
    setTags(
      questionData?.tags.map((tag: string, index: number) => ({
        tag,
        id: index,
      }))
    )

    setCourse(questionData?.course ?? "")
    await fetchAllModules(questionData?.course)
    setModule(questionData?.module ?? "")
    await fetchAllTopics(questionData?.module)
    setTopic(questionData?.topic ?? "")

    setPageLoading(false)
    setDifficulty(questionData?.difficulty)
    setName(questionData?.name)
    setQuestion(
      questionData?.question.map((content: any, index: number) => ({
        ...content,
        id: index,
      }))
    )

    const newOptions: any = {}
    Object.keys(questionData?.options).forEach((key: any) => {
      newOptions[key] = questionData?.options[key].map((option: any, index: number) => ({
        ...option,
        id: index,
      }))
    })
    setOptions(newOptions)
    setSolution(questionData?.solution)
    setSolutionFields(questionData?.solution_description || [])
  }

  const handleMcqTabChange = (index: number) => {
    setActiveMcqTab(index)
  }

  function readImageAsDataURL(file: File, filename: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (evt) => {
        const data = evt?.target?.result
        resolve(data)
      }

      reader.onerror = () => {
        reject("Failed to load the image")
      }

      reader.readAsDataURL(file)
    })
  }

  /**
   * Add and remove solution fields
   * @param op - string
   * @param id number
   */
  const handleSolutionFields: Function = (op: string, id: number) => {
    if (op === "Add") {
      setSolutionFields([
        ...solutionFields!,
        {
          type: "text",
          content: "",
        },
      ])
    } else {
      setSolutionFields(solutionFields.filter((_, index) => index !== id))
    }
  }

  /**
   * @description Handle solution field change (type, content, image and document)
   * @param e
   * @param id
   * @param name
   */
  const handleSolutionFieldChange: Function = (e: any, id: number, name: string) => {
    if (name === "type") {
      setSolutionFields(
        solutionFields?.map((solutionField, index) =>
          id === index ? { ...solutionField, type: e.target.value, content: "" } : solutionField
        )
      )
    } else if (name === "content") {
      setSolutionFields(
        solutionFields?.map((solutionField: any, index: number) =>
          id === index ? { ...solutionField, content: e.target.value } : solutionField
        )
      )
    } else if (name === "image") {
      const file = e.target.files[0]
      const filename = file.name

      const allowedTypes = ["image/png", "image/jpg", "image/jpeg"]

      if (!allowedTypes.includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .png, .jpg and .jpeg is alllowed")
      } else {
        /**
         * Image handling logic goes here
         */
        readImageAsDataURL(file, filename)
          .then((data) => {
            setSolutionFields(
              solutionFields.map((solutionField, index) =>
                id === index ? { ...solutionField, content: data, file, filename } : solutionField
              )
            )
          })
          .catch((error) => {
            changeToastVisibility(true)
            changeToastDetails(STR_FAILURE, genError(error, "Something went wrong to process the image!"))
          })
      }
    } else if (name === "document") {
      /**
       * File will be .doc, .xls, .pdf
       */
      const file = e.target.files[0]
      const filename = file.name

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]

      if (!allowedTypes.includes(file.type)) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Only .doc, .xls, .xlsx and .pdf is alllowed")
      } else {
        /**
         * File handling logic goes here
         */

        setSolutionFields(
          solutionFields.map((solutionField, index) =>
            id === index ? { ...solutionField, content: filename, file, filename } : solutionField
          )
        )
      }
    }
  }

  const redirectToMcqs = () => {
    navigate("/admin/mcqs")
  }

  const handleAddTag = (e: any) => {
    e.preventDefault()

    if (tag === "") return

    setTags([...tags, { tag, id: tags.length > 0 ? tags.at(-1).id + 1 : 0 }])
    setTag("")
  }

  const handleRemoveTag = (id: number) => {
    setTags(tags.filter((tag: any) => tag.id !== id))
  }

  const handleTagChange = (e: any) => {
    setTag(e.target.value)
  }

  const handleCourseChange = async (e: any) => {
    setCourse(e.target.value)
    setModule("")
    setTopic("")
    fetchAllModules(e.target.value)
  }

  const handleModuleChange = async (e: any) => {
    setModule(e.target.value)
    setTopic("")
    await fetchAllTopics(e.target.value)
  }

  const handleTopicChange = (e: any) => {
    setTopic(e.target.value)
  }

  const handleDifficultyChange = (e: any) => {
    setDifficulty(e.target.value)
  }

  const handleNameChange = (e: any) => {
    setName(e.target.value)
  }

  const handleAddQuestionContent = () => {
    setQuestion([
      ...question,
      {
        id: question.length > 0 ? question.at(-1).id + 1 : 0,
        type: "text",
        content: "",
      },
    ])
  }

  const addAndRemoveoption = (op: string, currOption: number) => {
    if (op === "add") {
      const newOptions = {
        ...options,
        [currOption.toString()]: [],
      }
      setOptions(newOptions)
    } else {
      const newOptions = options
      if (Object.keys(newOptions).length === 1) {
        changeToastDetails(STR_FAILURE, "You can not delete a single option.")
        changeToastVisibility(true)
        return
      }
      delete newOptions[currOption.toString()]
      const _options: any = {}
      Object.keys(newOptions).forEach((key: any, index: number) => {
        _options[(index + 1).toString()] = newOptions[key]
      })
      setOptions(_options)
    }
  }

  const handleQuestionTypeChange = (e: any, id: number) => {
    setQuestion(question.map((content: any) => (content.id === id ? { ...content, type: e.target.value } : content)))
  }

  const handleImageChange = async (e: any, id: any, isQuestion: boolean) => {
    const file = e.target.files[0]
    const filename = file.name
    const result = await readImageAsDataURL(file, filename)

    if (isQuestion) {
      const newQuestion = question.map((content: any) =>
        content.id === id ? { ...content, content: result, file, filename } : content
      )
      setQuestion(newQuestion)
    } else {
      const newOptions = { ...options }
      newOptions[activeOptionTab] = newOptions[activeOptionTab].map((content: any) =>
        content.id === id ? { ...content, content: result, file, filename } : content
      )
      setOptions(newOptions)
    }
  }

  const handleQuestionContentChange = (e: any, id: number) => {
    setQuestion(question.map((content: any) => (content.id === id ? { ...content, content: e.target.value } : content)))
  }

  const handleRemoveQuestionContent = (id: number) => {
    setQuestion(question.filter((content: any) => id !== content?.id))
  }

  const handleOptionTabChange = (index: number) => {
    setActiveOptionTab(index)
  }

  const handleAddOptionContent = () => {
    const newOptions = { ...options }
    newOptions[activeOptionTab] = [
      ...newOptions[activeOptionTab],
      {
        id: newOptions[activeOptionTab].length > 0 ? newOptions[activeOptionTab].at(-1).id + 1 : 0,
        type: "text",
        content: "",
      },
    ]
    setOptions(newOptions)
  }

  const handleRemoveOptionContent = (id: number) => {
    const newOptions = { ...options }
    newOptions[activeOptionTab] = newOptions[activeOptionTab].filter((content: any) => content.id !== id)
    setOptions(newOptions)
  }

  const handleOptionTypeChange = (e: any, id: number) => {
    const newOptions = { ...options }
    newOptions[activeOptionTab] = newOptions[activeOptionTab].map((content: any) =>
      content.id === id ? { ...content, type: e.target.value } : content
    )
    setOptions(newOptions)
  }

  const handleOptionContentChange = (e: any, id: number) => {
    const newOptions = { ...options }
    newOptions[activeOptionTab] = newOptions[activeOptionTab].map((content: any) =>
      content.id === id ? { ...content, content: e.target.value } : content
    )
    setOptions(newOptions)
  }

  const handleSolutionChange = (e: any) => {
    setSolution(e.target.value)
  }

  const checkIfAllFieldsAreFilled = () => {
    if (
      name === "" ||
      difficulty === "" ||
      course === "" ||
      module === "" ||
      topic === "" ||
      question.length === 0 ||
      question.some((content: any) => content?.content === "") ||
      Object.values(options).some((content: any) => content.length === 0) ||
      Object.values(options).some((content: any) => content.some((content: any) => content?.content === "")) ||
      solution === "" ||
      solutionFields.some((content: any) => content?.content === "")
    ) {
      if (topic == "" && module == "other") {
        setAllFieldsValid(true)
        return
      }
      setAllFieldsValid(false)
      return
    }
    setAllFieldsValid(true)
  }

  const handleImageUpload = async (img: File) => {
    const form = new FormData()
    form.append("img", img)

    const response = await uploadImageUseCase.invoke(auth, form)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in uploading image"))
      changeToastVisibility(true)
      return
    }

    return response.data
  }

  const handleImageUploadMCQ = async (img: File, q_url: string) => {
    const form = new FormData()
    form.append("img", img)
    form.append("q_url", q_url)

    const response = await uploadImageMCQUseCase.invoke(auth, form)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in uploading image"))
      changeToastVisibility(true)
      return
    }

    return response.data
  }

  const handleDocumentUpload = async (doc: File) => {
    const file = doc
    const formData = new FormData()
    formData.append("doc", file)

    const response = await uploadDocumentUseCase.invoke(auth, formData)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in uploading document"))
      changeToastVisibility(true)
      return
    }

    return response.data
  }

  const segregateQuestionData = async () => {
    const modifiedOptions: any = {}

    await Promise.all(
      Object.keys(options).map(async (key: any, index: number) => {
        modifiedOptions[key] = await Promise.all(
          options[key].map(async (option: any) => {
            if (option.type === "image" && option.file) {
              const data = {
                type: option?.type,
                filename: option?.filename,
                content: await handleImageUploadMCQ(option?.file, name.replace(/\s+/g, "") + "_" + index.toString()),
              }
              delete option["file"]
              return data
            } else {
              return {
                type: option?.type,
                content: option?.content,
              }
            }
          })
        )
      })
    )

    const modifiedQuestion: any = await Promise.all(
      question.map(async (content: any, index: number) => {
        if (content.type === "image" && content.file) {
          const data = {
            type: content?.type,
            filename: content?.filename,
            content: await handleImageUploadMCQ(content?.file, name.replace(/\s+/g, "") + "_" + index.toString()),
          }
          delete content["file"]
          return data
        } else {
          return {
            type: content?.type,
            content: content?.content,
          }
        }
      })
    )

    const modifiedSolutionFields: any = await Promise.all(
      solutionFields.map(async (content: any, index: number) => {
        if (content.type === "image" && content.file) {
          const data = {
            type: content.type,
            filename: content.filename,
            content: await handleImageUploadMCQ(
              content.file,
              name.replace(/\s+/g, "") + "_solution" + index.toString()
            ),
          }
          delete content["file"]
          return data
        } else if (content.type === "document" && content.file) {
          const data = {
            type: content.type,
            filename: content.filename,
            content: await handleDocumentUpload(content.file),
          }
          delete content["file"]
          return data
        } else {
          return {
            type: content.type,
            content: content.content,
          }
        }
      })
    )

    const questionData: any = {
      name,
      course,
      module,
      topic,
      difficulty,
      tags: tags.map((tag: any) => tag.tag),
      question: modifiedQuestion,
      options: modifiedOptions,
      solution,
      solution_description: modifiedSolutionFields,
    }
    return questionData
  }

  const resetAllValues = () => {
    setTags([])
    setTag("")
    setDifficulty("")
    setName("")
    setQuestion([])
    setOptions({
      "1": [],
      "2": [],
      "3": [],
      "4": [],
    })
    setSolution("")
    setAllFieldsValid(false)
    setSolutionFields([])
  }

  const handleSubmitQuestion = async () => {
    setLoading(true)
    const questionData = await segregateQuestionData()

    const response = await createMcqQuestionUseCase.invoke(auth, questionData)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in creating question"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Question created successfully")
    changeToastVisibility(true)

    resetAllValues()
  }

  const handleUpdateMcqQuestion = async () => {
    setLoading(true)
    const questionData = await segregateQuestionData()

    const response = await updateMcqQuestionUseCase.invoke(auth, id as string, questionData)

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response?.message, "Error in updating question"))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Question updated successfully")
    changeToastVisibility(true)
  }

  return {
    id,
    tag,
    tags,
    name,
    question,
    difficulty,
    activeMcqTab,
    activeOptionTab,
    options,
    TABS,
    solution,
    allFieldsValid,
    toast,
    pageLoading,
    loading,
    changeToastVisibility,
    handleOptionTabChange,
    handleAddTag,
    redirectToMcqs,
    handleTagChange,
    handleRemoveTag,
    handleNameChange,
    handleMcqTabChange,
    handleDifficultyChange,
    handleRemoveQuestionContent,
    handleAddQuestionContent,
    handleQuestionTypeChange,
    handleQuestionContentChange,
    handleAddOptionContent,
    handleRemoveOptionContent,
    addAndRemoveoption,
    handleOptionTypeChange,
    handleOptionContentChange,
    handleSolutionChange,
    checkIfAllFieldsAreFilled,
    handleSubmitQuestion,
    fetchMcqQuestionDetails,
    handleUpdateMcqQuestion,
    handleImageChange,
    fetchAllCourses,
    allCourses,
    allModules,
    allTopics,
    course,
    module,
    topic,
    handleCourseChange,
    handleModuleChange,
    handleTopicChange,
    solutionFields,
    handleSolutionFieldChange,
    handleSolutionFields,
  }
}
