import React from "react"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import { CrossIcon } from "core/constants/svgs"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import Toast from "core/components/Toast"
import {
  STR_BANDWIDTH,
  STR_COMPANY_NAME,
  STR_EMAIL,
  STR_EMAIL_FIELD,
  STR_FEES,
  STR_FULLNAME,
  STR_NUMBER_FILED,
  STR_SIGN_UP,
  STR_TEXT_FIELD,
  STR_UNIQUE_CODE,
  STR_YEARS_OF_EXPERIENCE,
} from "core/constants/strings"
import useStudentViewModel from "./UserSignupViewModel"
import DashboardLayout from "core/layouts/DashboardLayout"
import Select from "core/components/new/Select"
import PhoneInput from "react-phone-number-input"
import useStudentProfileViewModel from "../StudentDashboard/StudentProfileViewModel"

export default function AddStudentView() {
  const {
    courseType,
    programType,
    batch,
    selected_campaigns,
    selected_programs,
    onboardingManagerId,
    milestoneSessionMentor,
    role,
    email,
    setNewLink,
    company_name,
    unique_code,
    fullName,
    phoneNumber,
    yearsOfExperience,
    fees,
    filterOptions,
    programOptions,
    campaignOption,
    roleOptions,
    handleAddCampaign,
    handleRemoveCampaign,
    handleAddProgram,
    handleRemoveProgram,
    batches,
    onboardingManagersList,
    mentorList,
    loading,
    toast,
    timeValue,
    timeValue1,
    bandwidth,
    standardDiscount,
    seat_booking_amount,
    paymentId_of_seat_booking,
    payment_submethod,
    countryOptions,
    CampaignData,
    country,
    stateOptions,
    state,
    team,
    teamOptions,
    PAYMENT_SUBMETHOD_OPTIONS,
    otherLinks,
    newLink,
    getOnboardingManagerList,
    seatBookingAmountChange,
    getMentorsList,
    standardDiscountChange,
    emailChange,
    fullNameChange,
    unique_codeChange,
    company_nameChange,
    handlePhoneNumber,
    yearsOfExperienceChange,
    bandwidthChange,
    feesChange,
    batchChange,
    onSelectChangeOnboardingManager,
    onSelectMilestoneSessionMentor,
    roleChange,
    walkThroughDateChange,
    coursetypeOnChange,
    teamOnChange,
    programtypeOnChange,
    signup,
    changeToastVisibility,
    fetchBatches,
    getCampaignType,
    handleRemoveLink,
    handleAddLink,
    alumniOnChange,
    mentorOnChange,
    handleProfilePhotoChange,
    showNextComponent,
    paymentId_of_seat_bookingChange,
    Seat_booking_received_dateChange,
    payment_submethodChange,
    handleCountryChange,
    handleProfilePictureUpload,
    handlestateChange,
    isSuperAdminOnChange,
    isTestUser,
    IS_TEST_USER_OPTIONS,
    handleUserTypeChange,
  } = useStudentViewModel()

  const { id } = useStudentProfileViewModel()

  React.useEffect(() => {
    fetchBatches()
    getOnboardingManagerList()
    getMentorsList(id)
    getCampaignType()
  }, [])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">User SignUp</h2>
          <p>Create a new user credential</p>
        </div>
        <form onSubmit={signup} className="round-2 mx-auto flex w-full max-w-xl flex-col items-center gap-4 p-6 shadow">
          <h3>User Signup</h3>
          <Select
            required
            className="w-full"
            options={roleOptions}
            value={role}
            onChange={roleChange}
            defaultOption="Select Role"
          />
          <Input
            containerClass="w-full"
            type={STR_TEXT_FIELD}
            value={fullName}
            onChange={fullNameChange}
            placeholder={STR_FULLNAME}
            name={STR_FULLNAME}
            required
          />
          <Input
            containerClass="w-full"
            type={STR_EMAIL_FIELD}
            value={email}
            onChange={emailChange}
            placeholder={STR_EMAIL}
            name={STR_EMAIL}
            required
          />
          <PhoneInput
            defaultCountry="IN"
            value={phoneNumber}
            onChange={handlePhoneNumber}
            placeholder="Phone Number"
            limitMaxLength
          />
          {role == "user" && (
            <>
              <Select
                className="w-full"
                options={countryOptions}
                value={country}
                onChange={handleCountryChange}
                defaultOption="Select Country"
              />
              <Select
                className="w-full"
                options={stateOptions}
                value={state}
                onChange={handlestateChange}
                defaultOption="Select State"
              />
            </>
          )}

          {role === "admin" && (
            <Select required className="w-full" onChange={isSuperAdminOnChange}>
              <option value="" disabled selected hidden>
                Is_Superadmin
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </Select>
          )}

          {role === "developer" && (
            <Select
              required
              className="w-full"
              options={teamOptions}
              defaultOption="Select Team"
              onChange={teamOnChange}
              value={team}
            />
          )}
          {role !== "admin" && role !== "influencer" && role !== "developer" && (
            <Select
              required
              className="w-full"
              options={filterOptions}
              defaultOption="Select Course Type"
              onChange={coursetypeOnChange}
              value={courseType}
            />
          )}
          {role !== "admin" && role !== "developer" && role !== "influencer" && (
            <Select
              required
              className="w-full"
              options={programOptions}
              defaultOption="Select Program Type"
              onChange={programtypeOnChange}
              value={programType}
            />
          )}

          {role !== "admin" && role !== "developer" && (
            <Input
              containerClass="w-full"
              type={STR_NUMBER_FILED}
              value={yearsOfExperience}
              onChange={yearsOfExperienceChange}
              placeholder={STR_YEARS_OF_EXPERIENCE}
              name={STR_YEARS_OF_EXPERIENCE}
              disabled={courseType === "student" ? true : false}
              required
            />
          )}

          {role !== "admin" && role !== "mentor" && role !== "developer" && role !== "influencer" && (
            <Select
              required
              className="w-full"
              defaultOption="Select Batch"
              value={batch}
              options={batches}
              onChange={batchChange}
            />
          )}

          {role !== "admin" && role !== "mentor" && role !== "developer" && role !== "influencer" && (
            <Select
              // required
              className="w-full"
              defaultOption="Select Milestone Session Mentor"
              value={milestoneSessionMentor}
              options={mentorList}
              onChange={onSelectMilestoneSessionMentor}
            />
          )}
          {role !== "admin" && role !== "mentor" && role !== "developer" && role !== "influencer" && (
            <Select
              required
              className="w-full"
              defaultOption="Select Onboarding Manager"
              options={onboardingManagersList}
              onChange={onSelectChangeOnboardingManager}
              value={onboardingManagerId}
            />
          )}
          {role == "influencer" && (
            <Input
              containerClass="w-full"
              type={STR_TEXT_FIELD}
              value={unique_code}
              onChange={unique_codeChange}
              placeholder={STR_UNIQUE_CODE}
              name={STR_UNIQUE_CODE}
              required
            />
          )}
          {role === "influencer" && (
            <Input
              containerClass="w-full"
              type={STR_TEXT_FIELD}
              value={company_name}
              onChange={company_nameChange}
              placeholder={STR_COMPANY_NAME}
              name={STR_COMPANY_NAME}
              required
            />
          )}
          {role === "user" && (
            <Input
              containerClass="w-full"
              type={STR_TEXT_FIELD}
              value={fees}
              onChange={feesChange}
              placeholder="Select Fees"
              name={STR_FEES}
              required
            />
          )}
          {role !== "admin" && role !== "influencer" && (
            <Input
              containerClass="w-full"
              type="text"
              value={timeValue}
              onChange={walkThroughDateChange}
              placeholder={role === "mentor" || role === "developer" ? "Joining Date" : "Walk Through Date"}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          )}
          {role === "mentor" && (
            <Select required className="w-full" onChange={alumniOnChange}>
              <option value="" disabled selected hidden>
                Is_Alumni
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </Select>
          )}
          {role === "mentor" && (
            <Select required className="w-full" onChange={mentorOnChange}>
              <option value="" disabled selected hidden>
                Is_Mentor
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </Select>
          )}
          {role === "mentor" && (
            <Input
              containerClass="w-full"
              type={STR_NUMBER_FILED}
              value={bandwidth}
              onChange={bandwidthChange}
              placeholder={STR_BANDWIDTH}
            />
          )}
          {role === "user" && (
            <Input
              containerClass="w-full"
              type="text"
              value={seat_booking_amount}
              onChange={seatBookingAmountChange}
              placeholder={"Seat Booking Amount"}
            />
          )}
          {role === "user" && (
            <Input
              required
              type="text"
              containerClass="w-full"
              value={timeValue1}
              placeholder={"Seat Booking Amount Received Date"}
              onChange={Seat_booking_received_dateChange}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          )}
          {role == "influencer" && (
            <>
              <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
                <ConditionalLoader
                  isLoading={isEmpty(selected_programs)}
                  loader={<em className="text-xs text-new-neutral-light">No program selected</em>}
                >
                  {selected_programs.map((program) => (
                    <li key={program} className="inline-flex items-center gap-2">
                      {program}
                      <button
                        className="rounded bg-new-solid-black p-1 text-new-solid-white"
                        onClick={() => {
                          handleRemoveProgram(program)
                        }}
                      >
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </li>
                  ))}
                </ConditionalLoader>
              </ul>
              <Select
                className="w-full"
                options={programOptions}
                defaultOption={"Select Program Type"}
                onChange={handleAddProgram}
                value={""}
              />
            </>
          )}
          {role == "influencer" && (
            <>
              <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
                <ConditionalLoader
                  isLoading={isEmpty(selected_campaigns)}
                  loader={<em className="text-xs text-new-neutral-light">No campaign selected</em>}
                >
                  {selected_campaigns.map((campaign) => (
                    <li key={campaign} className="inline-flex items-center gap-2">
                      {campaign}
                      <button
                        className="rounded bg-new-solid-black p-1 text-new-solid-white"
                        onClick={() => {
                          handleRemoveCampaign(campaign)
                        }}
                      >
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </li>
                  ))}
                </ConditionalLoader>
              </ul>
              <Select
                className="w-full"
                options={campaignOption}
                defaultOption={"Select Campaign Type"}
                onChange={handleAddCampaign}
                value={""}
              />
            </>
          )}
          {role == "influencer" && (
            <Input
              containerClass="w-full"
              type="file"
              id="image"
              onChange={handleProfilePictureUpload}
              placeholder="Upload Profile Photo"
              label="Upload Profile Photo"
            />
          )}
          {role === "user" && (
            <Select
              value={payment_submethod}
              className="w-full py-1 px-4 text-[14px]"
              onChange={payment_submethodChange}
            >
              <option value="" disabled>
                Select Seat Booking Payment Sub-method
              </option>
              {PAYMENT_SUBMETHOD_OPTIONS.map((method) => (
                <option key={method} value={method}>
                  {method}
                </option>
              ))}
            </Select>
          )}

          {role === "user" && (
            <Input
              containerClass="w-full"
              type="text"
              value={paymentId_of_seat_booking}
              onChange={paymentId_of_seat_bookingChange}
              placeholder={"Payment ID of Seat Booking"}
            />
          )}

          {role === "user" && (
            <Input
              containerClass="w-full"
              type="text"
              value={standardDiscount}
              onChange={standardDiscountChange}
              placeholder={"Standard Discount"}
            />
          )}
          {role === "influencer" && (
            <>
              <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
                <ConditionalLoader
                  isLoading={isEmpty(otherLinks)}
                  loader={<em className="text-xs text-gray-500">No links added yet. Add a link to get started!</em>}
                >
                  {otherLinks.map((link, index) => (
                    <li key={index} className="inline-flex items-center gap-2 overflow-hidden">
                      <span className="truncate max-w-[200px]">{link}</span> {/* Truncate long links */}
                      <button
                        className="rounded bg-red-500 p-1 text-white hover:bg-red-600"
                        onClick={() => handleRemoveLink(index)}
                      >
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </li>
                  ))}
                </ConditionalLoader>
              </ul>
              <Input
                className="w-full truncate"
                type="text"
                value={newLink}
                onChange={(e) => setNewLink(e.target.value)}
                placeholder="Add Social Media Links"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />

              <Button
                type="button"
                onClick={handleAddLink}
                className="mt-2 px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
              >
                Add
              </Button>
            </>
          )}
          <Select
            className="w-full"
            defaultOption="Is Test User"
            options={IS_TEST_USER_OPTIONS}
            value={isTestUser}
            onChange={handleUserTypeChange}
          />
          <Button loading={loading}>{STR_SIGN_UP}</Button>
        </form>
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
