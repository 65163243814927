import { Auth } from "domain/model/Auth"
import { enableInstructors } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface EnableInstructorsUseCase {
  invoke(auth: Auth, enabled: enableInstructors): Promise<any>
}

export class EnableInstructors implements EnableInstructorsUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, enabled: enableInstructors): Promise<any> {
    return await this.repository.enableInstructors(auth, enabled)
  }
}
