import { Button } from "core/components/v2/Button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "core/components/v2/ui/dropdown-menu"
import ReferralModal from "./ReferralModal"
import { useEffect, useState } from "react"
import Input from "core/components/Input"
import StandardSelect from "core/components/v2/StandardSelect"
import ConfirmationPopUpDelete from "presentation/Admin/StudentDashboard/components/ConfirmationPopUpDelete"
import Spinner from "core/components/Spinner"
import { useApp } from "core/context/app"
import CopyIcon from "assets/svgs/CopyIcon"
import PhoneInput from "react-phone-number-input"
import { CustomScroll } from "react-custom-scroll"

export default function ReferralTable(props: any) {
  const [editReferModel, setEditReferModel] = useState<any>("")
  const [deleteModel, setDeleteModel] = useState<any>("")
  const [bankModal, setBankModal] = useState<any>(false)
  const [bankEditModal, setBankEditModal] = useState<any>(false)

  const { student } = useApp()

  const handleChange = (e: any) => {
    const { name, value, type } = e.target
    props?.setEditReferDetails((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleBankChange = (e: any) => {
    const { name, value, type } = e.target
    props?.setBankDetails((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleEditBankChange = (e: any) => {
    const { name, value, type } = e.target
    props?.setEditBankDetails((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }

  function handlePhoneNumber(e: any) {
    props?.setEditReferDetails((prev: any) => ({
      ...prev,
      referNumber: e,
    }))
  }

  useEffect(() => {
    setDeleteModel(false)
    setEditReferModel(false)
    setBankModal(false)
    setBankEditModal(false)
  }, [props?.myreferredCandidates, props?.referrerDetails])
  return (
    <div className="relative overflow-x-auto rounded-md shadow-md">
      <ReferralModal
        isOpen={editReferModel}
        onClose={() => {
          setEditReferModel(false)
        }}
      >
        <div className="w-[700px] h-[556px] bg-white p-[24px] rounded-[8px] flex">
          <div className="w-1/2 pr-4">
            <p className="text-[#1C3474] text-[16px] font-[600] mb-[16px]">Refer Details</p>
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Name (You want to refer)
              <span className="text-red-600">*</span>
            </div>

            <Input
              placeholder="Type here"
              type="text"
              name="referName"
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              value={props?.editReferDetails?.referName}
              onChange={handleChange}
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
              Email (You want to refer)
              <span className="text-red-600">*</span>
            </div>
            <Input
              placeholder="Type here"
              type="text"
              name="referEmail"
              value={props?.editReferDetails?.referEmail}
              disabled={true}
              onChange={handleChange}
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            />
            <div className="text-new-solid-black text-[14px] h-[17px] mb-[3px]">
              Contact Number (You want to refer)
              <span className="text-red-600">*</span>
            </div>
            <PhoneInput
              defaultCountry="IN"
              value={props?.editReferDetails?.referNumber}
              onChange={handlePhoneNumber}
              placeholder="Phone Number"
              limitMaxLength
              className="mb-[16px]"
            />

            <div className="flex flex-col justify-between gap-0 font-semibold">
              <div className="text-new-solid-black text-[14px] font-normal">
                Relationship<span className="text-red-600">*</span>
              </div>
              <StandardSelect
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="relation"
                value={props?.editReferDetails?.relation}
                onChange={(value: string) => {
                  props?.setEditReferDetails((prev: any) => ({
                    ...prev,
                    relation: value,
                  }))
                }}
                options={props?.relationOptions}
                required
              ></StandardSelect>
            </div>

            <div className="my-[16px] flex flex-col justify-between gap-0 font-semibold">
              <div className="text-new-solid-black text-[14px] font-normal">
                Course<span className="text-red-600">*</span>
              </div>
              <StandardSelect
                className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                placeholder={"Select here"}
                name="course"
                value={props?.editReferDetails?.course}
                onChange={(value: string) => {
                  props?.setEditReferDetails((prev: any) => ({
                    ...prev,
                    course: value,
                  }))
                }}
                options={props?.courseOptions}
                required
              ></StandardSelect>
            </div>

            <div className="flex flex-row justify-between my-[16px]">
              <label className="flex items-start cursor-pointer">
                <input
                  type="checkbox"
                  className="mr-1 m-[2px] checked:bg-[#07B42D]"
                  checked={props?.editReferDetails?.agreedToTerms}
                  onChange={() => {
                    props?.setEditReferDetails((prevFormData: any) => ({
                      ...prevFormData,
                      agreedToTerms: !prevFormData.agreedToTerms,
                    }))
                  }}
                />
                <span className="pl-3 text-[14px] font-['Inter'] text-[#222]">
                  I agree to the{" "}
                  <span className="text-[#222] font-semibold cursor-pointer hover:underline">Terms & Conditions</span>{" "}
                  mentioned here.
                </span>
              </label>
            </div>

            <div className="absolute bottom-[20px] right-[24px] flex gap-[14px] mt-[8px]">
              <Button
                onClick={() => setEditReferModel(false)}
                size="sm"
                border={"thin"}
                variant={"cancel"}
                className="w-[80px] h-[35px]"
              >
                Cancel
              </Button>
              <Button
                onClick={props?.handleEditSubmit}
                className="w-[80px] h-[35px]"
                size="sm"
                border={"thin"}
                variant={"primary"}
                disabled={
                  !props?.editReferDetails?.referName ||
                  !props?.editReferDetails?.referEmail ||
                  !props?.editReferDetails?.referNumber ||
                  !props?.editReferDetails?.relation ||
                  !props?.editReferDetails?.course ||
                  !props?.editReferDetails?.agreedToTerms ||
                  props?.loading
                }
              >
                {props?.loading ? <Spinner small={true} /> : "Submit"}
              </Button>
            </div>
          </div>
          <div className="w-[50%] h-full bg-white px-[24px] pt-[24px] rounded-[8px] border border-[#D3D3D3] shadow-[rgba(34,41,48,0.15)_-1px_1px_3px_0px] overflow-hidden">
            <CustomScroll heightRelativeToParent="100%" className="rounded-lg no-scrollbar">
              <div className="h-full pr-3">
                <p className="text-[#1C3474] text-[16px] font-[600] mb-[8px]">
                  Refer and Earn Program Terms and Conditions
                </p>

                <p className="text-[14px] font-bold">• Eligibility:</p>
                <p className="text-[14px] mb-[8px]">
                  The program is available to students who are currently enrolled in the course.
                </p>

                <p className="text-[14px] font-bold">• Referral Process:</p>
                <p className="text-[14px] mb-[8px]">
                  To refer someone, the referrer must fill out the referral form available on the student portal.
                </p>
                <p className="text-[14px] mb-[8px]">
                  Once the referee successfully enrolls and completes one month of their course duration (starting from
                  the batch commencement), both the referrer and referee will receive their respective rewards.
                </p>

                <p className="text-[14px] font-bold">• Reward Criteria:</p>
                <p className="text-[14px] mb-[8px]">
                  The reward for both the referrer and the referee depends on the specific course each is enrolled in.
                </p>
                <p className="text-[14px] mb-[8px]">
                  Cashback and rewards vary for each course, and both the referrer and referee will receive rewards
                  based on the course they are in.
                </p>

                <p className="text-[14px] font-bold">• Reward Distribution:</p>
                <p className="text-[14px] mb-[8px]">
                  Rewards for both the referrer and the referee will be issued after the referee has completed one month
                  of their course, starting from the batch class commencement.
                </p>

                <p className="text-[14px] font-bold">• TDS Deduction:</p>
                <p className="text-[14px] mb-[8px]">
                  A 10% Tax Deducted at Source (TDS) will be deducted on any reward amount exceeding ₹3000, in
                  accordance with financial regulations.
                </p>
                <p className="text-[14px] mb-[8px]">
                  If a referrer has received a reward of ₹3000 or more without TDS being deducted, and later refers
                  someone else, TDS will be deducted on the new referral reward, including any previous amounts where
                  TDS was not applied.
                </p>

                <p className="text-[14px] font-bold">• Exclusions:</p>
                <p className="text-[14px] mb-[8px]">
                  These terms apply to standard referral rewards only. Different terms and conditions apply to special
                  offers, which are visible on the offer application form.
                </p>

                <p className="text-[14px] font-bold">• Program Modifications:</p>
                <p className="text-[14px] mb-[8px]">
                  The program’s terms and conditions are subject to change at any time, with updates communicated
                  through official channels.
                </p>

                <p className="text-[14px] font-normal">
                  By participating in the Refer and Earn program, you acknowledge and agree to these terms and
                  conditions. For any queries, please contact support via the help desk.
                </p>
              </div>
            </CustomScroll>
          </div>
        </div>
      </ReferralModal>

      <ReferralModal
        isOpen={bankModal}
        onClose={() => {
          setBankModal(false)
        }}
      >
        <div className="w-[347px] h-[630px] bg-white p-[24px] rounded-[8px]">
          <p className="text-[#1C3474] text-[16px] font-[600] mb-[16px]">Bank Details</p>

          <div className="flex flex-col justify-between gap-0 font-semibold">
            <div className="text-new-solid-black text-[14px] font-normal">
              Bank Name<span className="text-red-600">*</span>
            </div>
            <Input
              placeholder="Type here"
              type="text"
              name="bankName"
              value={props?.bankDetails?.bankName}
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              onChange={handleBankChange}
            />
          </div>
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            Account Holder Name
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="accountHolderName"
            value={props?.bankDetails?.accountHolderName}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleBankChange}
          />
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            Account Number
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="accountNumber"
            value={props?.bankDetails?.accountNumber}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleBankChange}
          />
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            Re-type Account Number
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="accountNumber2"
            value={props?.bankDetails?.accountNumber2}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleBankChange}
          />
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            IFSC Code
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="iffciCode"
            value={props?.bankDetails?.iffciCode}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleBankChange}
          />

          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            PAN Card Number
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="panCardNumber"
            value={props?.bankDetails?.panCardNumber}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleBankChange}
          />

          <div className="flex flex-row justify-between mb-[16px]">
            <label className="flex items-start cursor-pointer">
              <input
                type="checkbox"
                className="mr-1 m-[2px] checked:bg-[#07B42D]"
                checked={props?.bankDetails?.agreedToTerms}
                onChange={() => {
                  props?.setBankDetails((prevFormData: any) => ({
                    ...prevFormData,
                    agreedToTerms: !prevFormData.agreedToTerms,
                  }))
                }}
              />
              <span className="pl-3 pt-0 w-[265px] h-[34px] font-['Inter'] text-[14px] leading-[17px]">
                I have cross-checked the information provided above.
              </span>
            </label>
          </div>

          <div className="absolute bottom-[20px] right-[24px] flex gap-[14px] mt-[8px]">
            <div></div>
            <Button
              onClick={() => setBankModal(false)}
              size="sm"
              border={"thin"}
              variant={"cancel"}
              className="w-[80px] h-[35px]"
            >
              Cancel
            </Button>
            <Button
              onClick={(e: any) => props?.handleBankSubmit(e, bankModal)}
              className="w-[80px] h-[35px]"
              size="sm"
              border={"thin"}
              variant={"primary"}
              disabled={
                !props?.bankDetails?.bankName ||
                !props?.bankDetails?.accountHolderName ||
                !props?.bankDetails?.accountNumber ||
                !props?.bankDetails?.accountNumber2 ||
                !props?.bankDetails?.agreedToTerms ||
                props?.loading
              }
            >
              {props?.loading ? <Spinner small={true} /> : "Submit"}
            </Button>
          </div>
        </div>
      </ReferralModal>

      <ReferralModal
        isOpen={bankEditModal}
        onClose={() => {
          setBankEditModal(false)
        }}
      >
        <div className="w-[347px] h-[610px] bg-white p-[24px] rounded-[8px]">
          <p className="text-[#1C3474] text-[16px] font-[600] mb-[16px]">Bank Details</p>

          <div className="flex flex-col justify-between gap-0 font-semibold">
            <div className="text-new-solid-black text-[14px] font-normal">
              Bank Name<span className="text-red-600">*</span>
            </div>
            <Input
              placeholder="Type here"
              type="text"
              name="bankName"
              value={props?.editBankDetails?.bankName}
              className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              onChange={handleEditBankChange}
            />
          </div>
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            Account Holder Name
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="accountHolderName"
            value={props?.editBankDetails?.accountHolderName}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleEditBankChange}
          />
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            Account Number
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="accountNumber"
            value={props?.editBankDetails?.accountNumber}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleEditBankChange}
          />
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            Re-type Account Number
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="accountNumber2"
            value={props?.editBankDetails?.accountNumber2}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleEditBankChange}
          />
          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            IFSC Code
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="iffciCode"
            value={props?.editBankDetails?.iffciCode}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleEditBankChange}
          />

          <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
            PAN Card Number
            <span className="text-red-600">*</span>
          </div>
          <Input
            placeholder="Type here"
            type="text"
            name="panCardNumber"
            value={props?.bankDetails?.panCardNumber}
            className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
            onChange={handleBankChange}
          />

          <div className="flex flex-row justify-between mb-[16px]">
            <label className="flex items-start cursor-pointer">
              <input
                type="checkbox"
                className="mr-1 checked:bg-[#07B42D]"
                checked={props?.editBankDetails?.agreedToTerms}
                onChange={() => {
                  props?.setEditBankDetails((prevFormData: any) => ({
                    ...prevFormData,
                    agreedToTerms: !prevFormData.agreedToTerms,
                  }))
                }}
              />
              <span className="pl-3 pt-0 w-[265px] h-[34px] font-['Inter'] text-[14px] leading-[17px]">
                I have cross-checked the information provided above.
              </span>
            </label>
          </div>

          <div className="absolute bottom-[20px] right-[24px] flex gap-[14px] mt-[8px]">
            <div></div>
            <Button
              onClick={() => setBankEditModal(false)}
              size="sm"
              border={"thin"}
              variant={"cancel"}
              className="w-[80px] h-[35px]"
            >
              Cancel
            </Button>
            <Button
              onClick={(e: any) => props?.handleEditBankSubmit(e, bankEditModal)}
              className="w-[80px] h-[35px]"
              size="sm"
              border={"thin"}
              variant={"primary"}
              disabled={
                !props?.editBankDetails?.bankName ||
                !props?.editBankDetails?.accountHolderName ||
                !props?.editBankDetails?.accountNumber ||
                !props?.editBankDetails?.accountNumber2 ||
                !props?.editBankDetails?.agreedToTerms ||
                props?.loading
              }
            >
              {props?.loading ? <Spinner small={true} /> : "Submit"}
            </Button>
          </div>
        </div>
      </ReferralModal>

      <ConfirmationPopUpDelete isOpen={deleteModel} width="w-[575px]">
        <div className="p-[24px]">
          <p className="text-[16px] font-[600] text-[#F00]">Delete Confirmation</p>
          <div className="text-[14px] font-[400] mt-[10px]">
            <p className="text-[#DC2626]">This action cannot be undone</p>
            <p className="">You are about to save changes </p>
          </div>

          <div className="mt-[10px] w-full flex justify-end gap-[8px]">
            <button
              onClick={() => {
                setDeleteModel(false)
              }}
              className="bg-[#D3D3D3] py-[9px] px-[16px] rounded-[4px] text-[#333] hover:bg-[#878787]"
            >
              Cancel
            </button>

            <Button
              onClick={() => {
                props?.handleDeleteSubmit(deleteModel)
              }}
              className="w-[100px] py-[9px] px-[16px] rounded-[4px] text-white bg-new-failure hover:bg-red-800"
              disabled={props?.loading}
            >
              {props?.loading ? <Spinner small={true} /> : "Delete"}
            </Button>
          </div>
        </div>
      </ConfirmationPopUpDelete>

      <table className="w-full text-left text-sm text-new-neutral-dark">
        <thead className="bg-new-neutral-dark text-new-solid-white">
          <tr>
            {props.tableHeaders?.map((title: any, index: number) => (
              <th key={index} className="whitespace-nowrap px-[16px] py-[8px] text-[12px] font-[500]">
                {title}
              </th>
            ))}
          </tr>
        </thead>

        {props?.fetchLoading && (
          <tr>
            <td colSpan={10}>
              <div className="flex justify-center p-[4px]">
                <Spinner />
              </div>
            </td>
          </tr>
        )}
        {!props?.fetchLoading &&
          !props?.myreferredCandidates?.length &&
          !props?.referrerDetails?.hasOwnProperty("studentEmail") && (
            <tr>
              <td>
                <div className="p-[10px]">No Data Found</div>
              </td>
            </tr>
          )}
        <tbody>
          {props?.referrerDetails?.studentEmail && (
            <>
              <tr className="bg-new-solid-white duration-200 hover:bg-[#F5F5F5]">
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">{props?.referrerDetails?.studentName}</td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {props?.referrerDetails?.relation ? props?.referrerDetails?.relation : "-"}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {props?.referrerDetails?.amount ? props?.referrerDetails?.amount : "-"}
                </td>

                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {props?.referrerDetails?.expected_date
                    ? new Date(props.referrerDetails.expected_date * 1000).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : "-"}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {props?.referrerDetails?.referee_reedem ? (
                    <span className="text-[12px] font-[400] text-[#07B42D]">Redeemed</span>
                  ) : (
                    <span className="text-[12px] font-[400] text-[#DC2626]">Not Redeem</span>
                  )}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  <Button
                    onClick={() => {
                      setBankModal({ email: student?.email, details: "referee" })
                    }}
                    variant={"ternary"}
                    border={"thin"}
                    size={"sm"}
                    className="py-[6px] px-[16px]"
                    disabled={
                      props?.referrerDetails?.refereeBankDetails?.accountNumber || props?.referrerDetails?.opted_out
                    }
                  >
                    Redeem
                  </Button>
                </td>
                <td className="relative px-[16px] py-[8px] text-[12px] font-[400]">
                  {props?.referrerDetails?.refereeBankDetails?.accountNumber && !props?.referrerDetails?.opted_out && (
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <button className="hover:bg-[#ebebeb] py-[5px] px-[12px] rounded-[2px]">⁝</button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white w-[200px]">
                        <DropdownMenuItem>
                          <Button
                            onClick={() => {
                              setBankEditModal({ email: props?.referrerDetails?.studentEmail, details: "referrer" })
                              props?.setEditBankDetails({
                                bankName: "",
                                accountHolderName: "",
                                accountNumber: "",
                                accountNumber2: "",
                                iffciCode: "",
                                panCardNumber: "",
                                ...props?.referrerDetails?.refereeBankDetails,
                                agreedToTerms: false,
                              })
                            }}
                            variant={"ternary"}
                            className="w-full flex justify-start"
                          >
                            <span className="text-[12px]">Update Bank Details</span>
                          </Button>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </td>
              </tr>
            </>
          )}
          {props.myreferredCandidates?.map((data: any, index: any) => {
            return (
              <tr className="bg-new-solid-white duration-200 hover:bg-[#F5F5F5]" key={index}>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">{index + 1}</td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">{data?.studentName}</td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  <button onClick={() => props?.handleCopyToClipboard(data?.studentEmail)}>
                    <CopyIcon className="w-5 h-5" />
                  </button>
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400] ">
                  {data?.courseType ? data.courseType : "-"}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {" "}
                  {data?.expected_date
                    ? new Date(data.expected_date * 1000).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : "-"}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {data?.opted_out ? (
                    <span className="text-[#DC2626]">Opted Out</span>
                  ) : data?.studentFinalized ? (
                    <span className="text-[#07B42D]">Completed</span>
                  ) : (
                    <span className="text-[#FBBF24]">Pending</span>
                  )}
                </td>

                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {!data?.opted_out &&
                    (data?.amount === -1 ? "Remaining Fees Refund" : data?.amount ? data?.amount : "-")}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  {data?.opted_out ? (
                    <span className="text-[12px] font-[400]">N/A</span>
                  ) : data?.referrer_reedem ? (
                    <span className="text-[12px] font-[400] text-[#07B42D]">Redeemed</span>
                  ) : (
                    <span className="text-[12px] font-[400] text-[#DC2626]">Not Redeem</span>
                  )}
                </td>
                <td className="px-[16px] py-[8px] text-[12px] font-[400]">
                  <Button
                    onClick={() => {
                      setBankModal({ email: data?.studentEmail, details: "referrer" })
                    }}
                    variant={"ternary"}
                    border={"thin"}
                    size={"sm"}
                    className="py-[6px] px-[16px]"
                    disabled={data?.referrerBankDetails?.accountNumber || data?.opted_out || !data?.studentFinalized}
                  >
                    Redeem
                  </Button>
                </td>

                <td className="relative px-[16px] py-[8px] text-[12px] font-[400]">
                  {!data?.opted_out && !data?.referrer_reedem && (
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <button className="hover:bg-[#ebebeb] py-[5px] px-[12px] rounded-[2px]">⁝</button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="bg-white w-[200px]">
                        <DropdownMenuItem>
                          <Button
                            onClick={() => {
                              setEditReferModel(props?.editReferDetails)
                              props?.setEditReferDetails({
                                ...props?.editReferDetails,
                                referName: data?.studentName,
                                referEmail: data?.studentEmail,
                                referNumber: data?.studentNumber,
                                relation: data?.studentRelation,
                                course: data?.course,
                                agreedToTerms: false,
                              })
                            }}
                            variant={"ternary"}
                            className="w-full flex justify-start"
                          >
                            <span className="text-[12px]">Update Refer Details</span>
                          </Button>
                        </DropdownMenuItem>
                        {data?.referrerBankDetails?.accountNumber && !data?.referrer_reedem && (
                          <DropdownMenuItem>
                            <Button
                              onClick={() => {
                                setBankEditModal({ email: data?.studentEmail, details: "referee" })
                                props?.setEditBankDetails({
                                  bankName: "",
                                  accountHolderName: "",
                                  accountNumber: "",
                                  accountNumber2: "",
                                  iffciCode: "",
                                  ...data?.referrerBankDetails,
                                  agreedToTerms: false,
                                })
                              }}
                              variant={"ternary"}
                              className="w-full flex justify-start"
                            >
                              <span className="text-[12px]">Update Bank Details</span>
                            </Button>
                          </DropdownMenuItem>
                        )}
                        {!data?.studentFinalized && (
                          <DropdownMenuItem>
                            <Button
                              onClick={() => {
                                setDeleteModel(data?.studentEmail)
                              }}
                              variant={"cancel"}
                              className="w-full flex justify-start"
                            >
                              <span className="text-[12px]">Delete Refer Details</span>
                            </Button>
                          </DropdownMenuItem>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
