import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { useEffect, useState } from "react"
import TabGroup from "./components/TabGroup"
import { Button } from "core/components/v2/Button"
import ReferralTable from "./components/ReferralTable"
import ReferralModal from "./components/ReferralModal"
import Input from "core/components/Input"
import StandardSelect from "core/components/v2/StandardSelect"
import ReferralWorkingModal from "./components/ReferralWorkingModal"
import useReferViewModel from "./ReferViewModel"
import ReferralTableTabGroup from "./components/ReferralTableTabGroup"
import Toast from "core/components/Toast"
import ReferBanner from "assets/svgs/ReferBanner"
import LineBottom from "core/components/payment-method/LineBottom"
import Line from "core/components/payment-method/Line"
import ExclusiveBanner from "assets/svgs/ExclusiveBanner"
import HomePageGradient from "core/components/new/HomePageGradient"
import Spinner from "core/components/Spinner"
import GoldCoin from "assets/svgs/GoldCoin"
import ReferralRewardsModal from "./components/ReferralRewardsModal"
import LinearGradientBottomLeft from "assets/svgs/LinearGradientBottomLeft"
import PhoneInput from "react-phone-number-input"
import { Head } from "core/components/seo"
import webEngageTrack from "core/utils/webEngageTrack"
import { CustomScroll } from "react-custom-scroll"
const ReferEarnView = () => {
  const {
    myReferralCode,
    ReferredByActive,
    getReferralData,
    postReferralFormData,
    candiDate,
    myreferredCandidates,
    handleCopy,
    toast,
    setReferredByActive,
    changeToastVisibility,
    handleViewReward,
    editReferDetails,
    setEditReferDetails,
    bankDetails,
    setBankDetails,
    editBankDetails,
    setEditBankDetails,
    courseOptions,
    setCourseOptions,
    relationOptions,
    isModalOpen,
    setIsModalOpen,
    student,
    formData,
    handleCourseOptions,
    handleRealationOptions,
    setFormData,
    handleSubmit,
    handleEditSubmit,
    handleDeleteSubmit,
    handleBankSubmit,
    handleEditBankSubmit,
    handleChange2,
    handlePhoneNumber,
    cancelForm,
    toggleCheckbox,
    loading,
    fetchLoading,
    setLoading,
    handleCopyToClipboard,
    handleLinkCopyToClipboard,
    handelEmailChange,
  } = useReferViewModel()

  const [activeButtonType, setActiveButtonType] = useState<string>("Refer & Earn")
  const [activeTableButtonType, setActiveTableButtonType] = useState<string>("My Referrals")
  const activeButtons = ["Refer & Earn", "Exclusive Offers"]
  const activeTableButtons = ["My Referrals", "Referred By"]

  useEffect(() => {
    getReferralData()
  }, [])

  function changeActiveButtonType(index: number) {
    const tabType = ["Refer & Earn", "Exclusive Offers"][index]
    setActiveButtonType(tabType)
  }
  function changeActiveTableButtonType(index: number) {
    const tabType = ["My Referrals", "Referred By"][index]
    setActiveTableButtonType(tabType)
  }

  const [referWorkingModal, setReferWorkingModal] = useState<boolean>(false)
  const [referRewardsModal, setReferRewardsModal] = useState<boolean>(false)

  return (
    <DashboardLayoutv2>
      <Head title="Refer & Earn" />

      <div className="p-[24px]">
        <ReferralModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="w-[700px] h-[556px] bg-white p-[24px] rounded-[8px] flex">
            <div className="w-1/2 pr-4">
              <p className="text-[#1C3474] text-[16px] font-[600] mb-[16px]">Refer Details</p>
              <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
                Name (You want to refer)
                <span className="text-red-600">*</span>
              </div>

              <Input
                placeholder="Type here"
                type="text"
                name="referName"
                value={formData.referName}
                className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                onChange={handleChange2}
              />
              <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
                Email (You want to refer)
                <span className="text-red-600">*</span>
              </div>
              <Input
                placeholder="Type here"
                type="text"
                name="referEmail"
                value={formData.referEmail}
                onChange={handelEmailChange}
                className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              />
              <div className="text-new-solid-black text-[14px] h-[17px] mb-[3px]">
                Contact Number (You want to refer)
                <span className="text-red-600">*</span>
              </div>
              <PhoneInput
                defaultCountry="IN"
                value={formData.referNumber}
                onChange={handlePhoneNumber}
                placeholder="Phone Number"
                limitMaxLength
                className="my-[8px]"
              />

              <div className="flex flex-col justify-between gap-0 font-semibold">
                <div className="text-new-solid-black text-[14px] font-normal">
                  Relationship<span className="text-red-600">*</span>
                </div>
                <StandardSelect
                  className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                  placeholder={"Select here"}
                  name="relation"
                  value={formData.relation}
                  onChange={handleRealationOptions}
                  options={relationOptions}
                  required
                ></StandardSelect>
              </div>

              <div className="my-[16px] flex flex-col justify-between gap-0 font-semibold">
                <div className="text-new-solid-black text-[14px] font-normal">
                  Course<span className="text-red-600">*</span>
                </div>
                <StandardSelect
                  className="px-4 font-['Inter'] text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                  placeholder={"Select here"}
                  name="course"
                  value={formData.course}
                  onChange={handleCourseOptions}
                  options={courseOptions}
                  required
                ></StandardSelect>
              </div>

              <div className="flex flex-row justify-between my-[16px]">
                <label className="flex items-start cursor-pointer">
                  <input
                    type="checkbox"
                    className="mr-1 m-[2px] checked:bg-[#07B42D]"
                    checked={formData.agreedToTerms}
                    onChange={toggleCheckbox}
                  />
                  <span className="pl-3 text-[14px] font-['Inter'] text-[#222]">
                    I agree to the{" "}
                    <span className="text-[#222] font-semibold cursor-pointer hover:underline">Terms & Conditions</span>{" "}
                    mentioned here.
                  </span>
                </label>
              </div>
              <div className="absolute bottom-[20px] left-[150px] flex gap-[14px] mt-[8px]">
                <Button
                  onClick={() => {
                    window.webengage.track("Cancel Refer Modal - Platform", {
                      name: formData.referName,
                      number: formData.referNumber,
                      email: formData.referEmail,
                      relation: formData.relation,
                      course: formData.course,
                    })
                    setIsModalOpen(false)
                  }}
                  size="sm"
                  border={"thin"}
                  variant={"cancel"}
                  className="w-[80px] h-[35px]"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  className="w-[80px] h-[35px]"
                  size="sm"
                  border={"thin"}
                  variant={"primary"}
                  disabled={
                    !formData.referName ||
                    !formData.referEmail ||
                    !formData.referNumber ||
                    !formData.relation ||
                    !formData.course ||
                    !formData.agreedToTerms ||
                    loading
                  }
                >
                  {loading ? <Spinner small={true} /> : "Submit"}
                </Button>
              </div>
            </div>

            <div className="w-[50%] h-full bg-white px-[24px] pt-[24px] rounded-[8px] shadow-sm overflow-hidden">
              <CustomScroll heightRelativeToParent="100%" className="rounded-lg no-scrollbar">
                <div className="h-full pr-3">
                  <p className="text-[#1C3474] text-[16px] font-[600] mb-[8px]">
                    Refer and Earn Program Terms and Conditions
                  </p>

                  <p className="text-[14px] font-bold">• Eligibility:</p>
                  <p className="text-[14px] mb-[8px]">
                    The program is available to students who are currently enrolled in the course.
                  </p>

                  <p className="text-[14px] font-bold">• Referral Process:</p>
                  <p className="text-[14px] mb-[8px]">
                    To refer someone, the referrer must fill out the referral form available on the student portal.
                  </p>
                  <p className="text-[14px] mb-[8px]">
                    Once the referee successfully enrolls and completes one month of their course duration (starting
                    from the batch commencement), both the referrer and referee will receive their respective rewards.
                  </p>

                  <p className="text-[14px] font-bold">• Reward Criteria:</p>
                  <p className="text-[14px] mb-[8px]">
                    The reward for both the referrer and the referee depends on the specific course each is enrolled in.
                  </p>
                  <p className="text-[14px] mb-[8px]">
                    Cashback and rewards vary for each course, and both the referrer and referee will receive rewards
                    based on the course they are in.
                  </p>

                  <p className="text-[14px] font-bold">• Reward Distribution:</p>
                  <p className="text-[14px] mb-[8px]">
                    Rewards for both the referrer and the referee will be issued after the referee has completed one
                    month of their course, starting from the batch class commencement.
                  </p>

                  <p className="text-[14px] font-bold">• TDS Deduction:</p>
                  <p className="text-[14px] mb-[8px]">
                    A 10% Tax Deducted at Source (TDS) will be deducted on any reward amount exceeding ₹3000, in
                    accordance with financial regulations.
                  </p>
                  <p className="text-[14px] mb-[8px]">
                    If a referrer has received a reward of ₹3000 or more without TDS being deducted, and later refers
                    someone else, TDS will be deducted on the new referral reward, including any previous amounts where
                    TDS was not applied.
                  </p>

                  <p className="text-[14px] font-bold">• Exclusions:</p>
                  <p className="text-[14px] mb-[8px]">
                    These terms apply to standard referral rewards only. Different terms and conditions apply to special
                    offers, which are visible on the offer application form.
                  </p>

                  <p className="text-[14px] font-bold">• Program Modifications:</p>
                  <p className="text-[14px] mb-[8px]">
                    The program’s terms and conditions are subject to change at any time, with updates communicated
                    through official channels.
                  </p>

                  <p className="text-[14px] font-normal">
                    By participating in the Refer and Earn program, you acknowledge and agree to these terms and
                    conditions. For any queries, please contact support via the help desk.
                  </p>
                </div>
              </CustomScroll>
            </div>
          </div>
        </ReferralModal>

        <TabGroup
          buttons={activeButtons}
          active={activeButtons.indexOf(activeButtonType)}
          onChange={changeActiveButtonType}
        />

        {activeButtonType === "Refer & Earn" && (
          <div className="my-[24px]">
            <div className="relative bg-[#333] h-[220px] w-full rounded-[8px] shadow-md overflow-hidden">
              <div className="absolute -top-[130px] animate-moveWhite">
                <Line />
              </div>
              <div className="absolute -top-[130px] animate-moveWhiteDelayed">
                <Line />
              </div>

              <div className="absolute -bottom-[130px] animate-moveBottomWhite">
                <LineBottom />
              </div>
              <div className="absolute -bottom-[130px] animate-moveBottomWhiteDelayed">
                <LineBottom />
              </div>

              <div
                style={{
                  background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="text-[43.643px] font-[700] mt-[35px] ml-[42px] max-xl:text-[25px]"
              >
                Refer & Earn
              </div>
              <hr className="w-[258.597px] h-[1.134px] ml-[42px]" />
              <p className="text-[16px] text-[#fff] font-[400] w-[270px] mt-[15px] ml-[42px]">
                Help your friends switch to a product-based company and get rewarded with up to ₹1 lakh!
              </p>
              <ReferBanner className="absolute  -top-[95px] right-0" />
              <span className="absolute text-[6px] font-[400] bottom-[4px] right-[11px] text-white">* T&C applied</span>
            </div>
            <div className="flex w-full justify-between my-[24px]">
              <div className="flex-1 overflow-hidden relative w-[280px] bg-[#333] rounded-[8px] py-[16px] px-[18px] min-[1920px]:w-[340px] mr-4">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-[#FFF] ">Your current referral balance!</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[43.643px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹{" "}
                  {myreferredCandidates?.reduce((acc: number, user: any) => {
                    if (user?.studentFinalized && !user?.referrer_reedem) {
                      return acc + (user?.amount || 0)
                    }
                    return acc
                  }, 0) + (candiDate ? (candiDate.referee_redeem ? 0 : candiDate?.amount) : 0)}{" "}
                </div>

                <p className="absolute bottom-[15px] text-[14px] font-[400] text-[#fff]">
                  Total Earning : ₹{" "}
                  {myreferredCandidates?.reduce((acc: any, user: any) => {
                    if (user?.studentFinalized) {
                      return acc + (user?.amount || 0)
                    }
                    return acc
                  }, 0) + (candiDate?.amount ? candiDate?.amount : 0)}{" "}
                </p>
              </div>

              <div className="flex-1 w-[580px] min-[1920px]:w-[780px]bg-[#F9F9F9] shadow-md rounded-[8px]">
                <p className="text-[14px] px-[13px] font-[600] pt-[12px] pb-[6px] text-[#333]">
                  Copy and share your link
                </p>
                <div className="flex items-center gap-4 px-[12px]">
                  <input
                    type="text"
                    value={`https://platform.bosscoderacademy.com/referlink/${student?.email}`}
                    readOnly
                    className="w-full h-8 px-2 py-2 text-[14px] bg-gray-200 rounded-md text-gray-700 border-none"
                  />
                  <Button
                    size="sm"
                    className="bg-blue-950 text-white px-[16px] py-[9px] rounded-md"
                    onClick={() => {
                      webEngageTrack("Refer and Earn - Copy Referral Link", "Copied Referral Link")
                      handleLinkCopyToClipboard(`https://platform.bosscoderacademy.com/referlink/${student?.email}`)
                    }}
                  >
                    Copy
                  </Button>
                </div>

                <div className="flex items-center my-[2px] px-[12px] text-gray-500 text-sm">
                  <div className="flex-grow border-t border-dashed border-gray-300"></div>
                  <span className="px-[12px] py-[4px]">Or</span>
                  <div className="flex-grow border-t border-dashed border-gray-300"></div>
                </div>

                <div className=" bg-[#262626] text-white px-4 py-2 rounded-b-[8px] flex justify-between items-center">
                  <p className="text-[14px] font-[600] text-white">Refer by filling the form</p>
                  <Button
                    onClick={() => {
                      webEngageTrack("Refer and Earn - Refer From Here", "Refer From Here")
                      setIsModalOpen(true)
                    }}
                    size="sm"
                    variant="ternary"
                    border="thin"
                    className="text-black bg-white hover:text-[#333] focus:text-[#333] focus:bg-v2-gray-200"
                  >
                    + Refer from Here
                  </Button>
                </div>
              </div>
              <ReferralWorkingModal isOpen={referWorkingModal} onClose={() => setReferWorkingModal(false)} />
            </div>
            <div className="flex items-center justify-between mb-[24px] h-[52px] bg-[#F9F9F9] rounded-[8px] px-[24px] py-[12px]">
              <div className="flex gap-[8px] items-center">
                <p className="text-[#414141] text-[14px] font-[500]">
                  Want to know more about your referral rewards, Click to view more!{" "}
                </p>
              </div>
              <Button
                className="px-[16px] bg-[#333] text-white hover:text-white hover:bg-[#161616]"
                border="thin"
                size="sm"
                variant={"secondary"}
                onClick={() => {
                  setReferWorkingModal(true),
                    webEngageTrack(
                      "Refer and Earn - Click Here FAQ",
                      "Click here to know the referral process and FAQ's."
                    )
                }}
              >
                Know more
              </Button>
            </div>
            <div>
              <p className="text-[16px] font-[600] text-[#333]">Referral List</p>
              <p className="text-[14px] font-[400] text-[#646464]">Track all your referrals from here!</p>
            </div>

            <div>
              <div className="my-[24px]">
                <ReferralTableTabGroup
                  buttons={activeTableButtons}
                  active={activeTableButtons.indexOf(activeTableButtonType)}
                  onChange={changeActiveTableButtonType}
                />
              </div>

              {activeTableButtonType === "My Referrals" && (
                <ReferralTable
                  bankDetails={bankDetails}
                  editBankDetails={editBankDetails}
                  setEditBankDetails={setEditBankDetails}
                  setBankDetails={setBankDetails}
                  handleBankSubmit={handleBankSubmit}
                  handleEditBankSubmit={handleEditBankSubmit}
                  editReferDetails={editReferDetails}
                  handleEditSubmit={handleEditSubmit}
                  handleDeleteSubmit={handleDeleteSubmit}
                  loading={loading}
                  fetchLoading={fetchLoading}
                  relationOptions={relationOptions}
                  courseOptions={courseOptions}
                  setEditReferDetails={setEditReferDetails}
                  handleCopyToClipboard={handleCopyToClipboard}
                  tableHeaders={[
                    "Sr. No.",
                    "Student Name",
                    "Copy Email",
                    "Course",
                    "Expected Date",
                    "Enrollment Status",
                    "Amount",
                    "Redeem status",
                    "",
                    "",
                  ]}
                  myreferredCandidates={myreferredCandidates}
                />
              )}

              {activeTableButtonType === "Referred By" && (
                <ReferralTable
                  bankDetails={bankDetails}
                  editBankDetails={editBankDetails}
                  setEditBankDetails={setEditBankDetails}
                  setBankDetails={setBankDetails}
                  handleBankSubmit={handleBankSubmit}
                  handleEditBankSubmit={handleEditBankSubmit}
                  tableHeaders={["Student Name", "Relationship", "Amount", "Expected Date", "Redeem status", "", ""]}
                  referrerDetails={candiDate}
                  fetchLoading={fetchLoading}
                  loading={loading}
                />
              )}
            </div>
          </div>
        )}
        {activeButtonType === "Exclusive Offers" && (
          <div className="py-[24px]">
            <div className="relative bg-[#333] h-[220px] w-full rounded-[8px] shadow-md overflow-hidden">
              <div className="absolute -top-[130px] animate-moveWhite">
                <Line />
              </div>
              <div className="absolute -top-[130px] animate-moveWhiteDelayed">
                <Line />
              </div>

              <div className="absolute -bottom-[130px] animate-moveBottomWhite">
                <LineBottom />
              </div>
              <div className="absolute -bottom-[130px] animate-moveBottomWhiteDelayed">
                <LineBottom />
              </div>

              <div
                style={{
                  background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="text-[43.643px] font-[700] mt-[35px] ml-[42px] max-xl:text-[25px]"
              >
                OFFERS
              </div>
              <hr className="w-[258.597px] h-[1.134px] ml-[42px]" />
              <p className="text-[16px] text-[#fff] font-[400] w-[270px] mt-[15px] ml-[42px]">
                Help your friends switch to a product-based company and get rewarded with up to ₹1 lakh!
              </p>
              <ExclusiveBanner className="absolute right-0 top-0" />
              <span className="absolute text-[6px] font-[400] bottom-[4px] right-[11px] text-white">* T&C applied</span>
            </div>
            <div className="flex justify-between gap-[10px] my-[24px]">
              <div className="relative overflow-hidden w-1/3 p-[16px] bg-[#333] hover:bg-[#310000] duration-1000 rounded-[8px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-white">On 3 Successful Referrals</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[30px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹ 50,000
                </div>

                <Button
                  onClick={() => {
                    webEngageTrack("Refer and Earn - 50,000", "Refer and Earn - 50,000"),
                      window.open("https://forms.gle/3wHYC4tFxtyLMUyU7", "_blank", "noopener,noreferrer")
                  }}
                  className="mt-[18px] px-[16px] relative z-10"
                  size="sm"
                  variant={"ternary"}
                >
                  Redeem Now
                </Button>

                <p className="text-[8px] font-[400] text-[#FFF] my-[4px]">
                  <sup>*</sup>T & C Mentioned in the form
                </p>
              </div>
              <div className="relative overflow-hidden w-1/3  p-[16px] bg-[#333] hover:bg-[#310000] duration-1000 rounded-[8px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-white">On 2 Successful Referrals</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[30px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  ₹ 25,000
                </div>

                <Button
                  onClick={() => {
                    webEngageTrack("Refer and Earn - 25,000", "Refer and Earn - 25,000")
                    window.open("https://forms.gle/3wHYC4tFxtyLMUyU7", "_blank", "noopener,noreferrer")
                  }}
                  className="mt-[18px] px-[16px] relative z-10"
                  size="sm"
                  variant={"ternary"}
                >
                  Redeem Now
                </Button>

                <p className="text-[8px] font-[400] text-[#FFF] my-[4px]">
                  <sup>*</sup>T & C Mentioned in the form
                </p>
              </div>
              <div className="relative overflow-hidden w-1/3 p-[16px] bg-[#333] hover:bg-[#310000] duration-1000 rounded-[8px]">
                <div className="absolute bottom-0 -right-[35px]">
                  <LinearGradientBottomLeft />
                </div>
                <p className="text-[12px] font-[500] text-white">In your 1st month</p>
                <div
                  style={{
                    background: "linear-gradient(89deg, #FFBB0C 10.2%, #FEE14E 106.58%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="text-[24px] leading-[1] w-[170px] font-[700] mt-[2px] max-xl:text-[25px]"
                >
                  Double Your Reward
                </div>

                <Button
                  onClick={() => {
                    webEngageTrack("Refer and Earn - 12,000", "Refer and Earn - 12,000"),
                      window.open("https://forms.gle/3wHYC4tFxtyLMUyU7", "_blank", "noopener,noreferrer")
                  }}
                  className="mt-[1.2vw] px-[16px] relative z-10"
                  size="sm"
                  variant={"ternary"}
                >
                  Redeem Now
                </Button>

                <p className="text-[8px] font-[400] text-[#FFF] my-[4px]">
                  <sup>*</sup>T & C Mentioned in the form
                </p>
              </div>
            </div>

            <div className="flex justify-between items-center py-[16px] px-[24px] bg-[#333] text-white rounded-[8px]">
              <div>
                <p className="text-[14px] font-[600]">Do you want to refer?</p>
                <p className="text-[12px] font-[500]">Click on the refer to proceed with referral process.</p>
              </div>
              <div className="flex gap-[8px]">
                <Button
                  size="md"
                  variant={"ternary"}
                  onClick={() => {
                    handleLinkCopyToClipboard(`https://platform.bosscoderacademy.com/referlink/${student?.email}`)
                    webEngageTrack("Refer and Earn - Share Referral Link", "Share Refreel Link")
                  }}
                >
                  Share referral Link
                </Button>
                <Button
                  className="bg-[#333] text-white hover:text-[#333] focus:text-[#333] focus:bg-v2-gray-200"
                  border={"thin"}
                  size="md"
                  variant={"ternary"}
                  onClick={() => {
                    webEngageTrack("Refer and Earn - Refer From Here", "Refer From Here"), setIsModalOpen(true)
                  }}
                >
                  + Refer from Here{" "}
                </Button>
              </div>
            </div>
          </div>
        )}
        <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      </div>
    </DashboardLayoutv2>
  )
}

export default ReferEarnView
