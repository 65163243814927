import { MentorNotificationsRepository } from "domain/repository/Mentor/MentorNotificationsRepository.ts"
export interface RemoveMentorNotificationsUseCase {
  invoke(auth: any, id: string): Promise<any>
}

export class RemoveMentorNotifications implements RemoveMentorNotificationsUseCase {
  private repository: MentorNotificationsRepository

  constructor(repository: MentorNotificationsRepository) {
    this.repository = repository
  }

  async invoke(auth: any, id: string) {
    return await this.repository.removeMentorNotifications(auth, id)
  }
}
