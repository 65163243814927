import { Auth } from "domain/model/Auth"
import { disableMentors } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface DiableMentorUseCase {
  invoke(auth: Auth, disabled: disableMentors): Promise<any>
}

export class DisableMentors implements DiableMentorUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, disabled: disableMentors): Promise<any> {
    return await this.repository.disableMentor(auth, disabled)
  }
}
