import { MentorNotificationsDataSource } from "data/dataSource/Mentor/MentorNotificationsDataSource"
import { Auth } from "domain/model/Auth"
import { MentorNotificationsRepository } from "domain/repository/Mentor/MentorNotificationsRepository.ts"

export class MentorNotificationsRepositoryImpl implements MentorNotificationsRepository {
  private dataSource: MentorNotificationsDataSource

  constructor(dataSource: MentorNotificationsDataSource) {
    this.dataSource = dataSource
  }

  async getMentorNotifications(auth: Auth) {
    return await this.dataSource.getMentorNotifications(auth)
  }
  async removeMentorNotifications(auth: Auth, id: string): Promise<any> {
    return await this.dataSource.removeMentorNotifications(auth, id)
  }
  async openSlotNotification(auth: any): Promise<any> {
    return await this.dataSource.openSlotNotification(auth)
  }
}
