import React, { useEffect, useState } from "react"
import EMIOptionCard from "./EMIOptionCard"
import UploadFiles from "./UploadFiles"
import { cn } from "core/lib/utils"
import ConfirmPaymentPopup from "./ConfirmPaymentPopup"
import TickCircle from "../assets/TickCircle"
import CrossCircle from "../assets/CrossCircle"
import BlankCircle from "../assets/BlankCircle"
import { useApp } from "core/context/app"
import { STR_SUCCESS } from "core/constants/strings"

interface ProgressItem {
  name: string
  status: number
  note: string
}

export default function EMIOptions({
  amount,
  paymentDocuments,
  studentEMI,
  setStudentEMI,
  setPaymentDocuments,
  getPaymentDocuments,
  uploadPaymentDocument,
  deletePaymentDocument,
  getStudentEMI,
  changeToastVisibility,
  createStudentEMI,
  changeToastDetails,
}: any) {
  const { student } = useApp()
  const [selectedEMIOptionToView, setSelectedEMIOptionToView] = React.useState<number>(0)
  const [selectedEMIOptionToChoose, setSelectedEMIOptionToChoose] = React.useState<number>(-1)
  const [allowSubmit, setAllowSubmit] = React.useState<boolean>(false)
  const [allFilesStatus, setAllFilesStatus] = React.useState<boolean>(false)
  const [showPopup, setShowPopup] = React.useState<boolean>(false)
  // const months =
  //   student?.batch[0] === "B"
  //     ? student?.batch?.includes("Accelerator")
  //       ? [6, 9, 12]
  //       : [6, 9, 12, 15]
  //     : [6, 9, 12, 15, 18, 24]

  const [isDSML, setIsDSML] = useState(true)
  const [isEV, setIsEV] = useState(false)
  const [isAccelerator, setIsAccelerator] = useState(false)
  const [isAC2, setIsAC2] = React.useState(false)
  const [isAC, setIsAC] = React.useState(false)

  useEffect(() => {
    const batch = student?.batch

    setIsDSML(batch?.includes("DSML") || student?.tracks.includes("data_engineer"))
    setIsEV(batch?.includes("EV"))
    setIsAccelerator(batch?.includes("Accelerator") && !batch?.includes("KI-Accelerator"))
    setIsAC2(batch?.includes("AC2"))
    setIsAC(batch?.startsWith("AC"))
  }, [])

  const months = [
    (isAC || isAccelerator) && 6,
    (isAC || isAccelerator) && 9,
    !(isAC || isAccelerator) && (isDSML ? [6, 9] : 6),
    isEV && 9,
    !(isAC || isAccelerator) && (isDSML || isEV ? 12 : 9),
    !(isDSML || isEV) && 12,
    !(isAC || isAccelerator) && !(isDSML || isEV || isAccelerator) && 15,
    (isDSML || isEV) && 15,
    (isDSML || isEV) && 18,
    (isDSML || isEV) && 24,
  ]
    .flat()
    .filter(Boolean)
  const [interest, setInterest] = React.useState<number>(0)
  const interestRate =
    isAC || isAccelerator
      ? [0, 6.9, 8.9]
      : student?.batch[0] === "B"
        ? student?.batch?.includes("Accelerator") && !student?.batch?.includes("KI-Accelerator")
          ? [0, 1.9, 8.9]
          : [0, 1.9, 8.9, 11.9]
        : student?.batch[0] === "D"
          ? [0, 0, 8.9, 11.9, 14.9, 16.9]
          : [0, 1.9, 8.9, 11.9, 14.9, 16.9]
  React.useEffect(() => {
    getStudentEMI()
  }, [])
  React.useEffect(() => {
    if (studentEMI?.is_submitted === true) {
      setSelectedEMIOptionToChoose(studentEMI.duration_index)
      getPaymentDocuments()
    }
  }, [studentEMI])

  React.useEffect(() => {
    if (selectedEMIOptionToChoose !== -1) {
      setAllowSubmit(paymentDocuments?.some((file: any) => file.is_uploaded))
      if (studentEMI?.is_submitted === true) {
        setAllowSubmit(false)
      }
    } else setAllowSubmit(false)
    setAllFilesStatus(paymentDocuments?.every((file: any) => file.is_uploaded))
  }, [paymentDocuments])

  const handleEMIOptionToView = (index: number) => {
    if (selectedEMIOptionToChoose === -1) setSelectedEMIOptionToView(index)
  }
  const handleEMIOptionToChoose = (index: number) => {
    if (selectedEMIOptionToChoose === index) {
      setSelectedEMIOptionToChoose(-1)
      setAllowSubmit(false)
    } else {
      getPaymentDocuments()
      setSelectedEMIOptionToChoose(index)
    }
    setInterest(index)
    setSelectedEMIOptionToView(index)
  }

  const handleCancelPaymentConfirmation = () => {
    setShowPopup(false)
  }

  const handleConfirmPaymentConfirmation = async () => {
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Documents uploaded successfully")
    const newStudentEMI = studentEMI
    newStudentEMI.is_submitted = true
    newStudentEMI.duration_index = selectedEMIOptionToChoose
    newStudentEMI.progress[0].status = 1
    newStudentEMI.progress[1].status = 1
    await createStudentEMI(newStudentEMI)
    setShowPopup(false)
  }

  const handleSubmitDocuments = () => {
    setShowPopup(true)
  }

  return (
    <React.Fragment>
      {showPopup && (
        <ConfirmPaymentPopup
          onCancel={handleCancelPaymentConfirmation}
          onConfirm={handleConfirmPaymentConfirmation}
          months={months[selectedEMIOptionToChoose]}
        />
      )}
      <div className="my-6 rounded p-6 shadow-[0_0_10px_0_rgba(141,141,141,0.2)]">
        <div className="grid grid-cols-2 gap-8">
          <div className="flex flex-col gap-2">
            {months.map((month, index) => (
              <EMIOptionCard
                key={index}
                index={index}
                month={month}
                onView={handleEMIOptionToView}
                onChoose={handleEMIOptionToChoose}
                active={
                  studentEMI?.is_submitted === false
                    ? selectedEMIOptionToView === index
                    : studentEMI.duration_index === index
                }
                checked={
                  studentEMI?.is_submitted === false
                    ? selectedEMIOptionToChoose === index
                    : studentEMI.duration_index === index
                }
                disabled={studentEMI?.is_submitted === true}
              />
            ))}
            {studentEMI?.is_submitted === false && selectedEMIOptionToChoose === -1 && (
              <div className="text-sm font-semibold text-[#DC2626]">
                *Choose suitable payment option to proceed further
              </div>
            )}
          </div>
          <div className="text-sm">
            <div className="text-base font-semibold leading-[150%] text-[#312E81]">
              EMI{" "}
              {selectedEMIOptionToChoose === -1 ? months[selectedEMIOptionToView] : months[selectedEMIOptionToChoose]}{" "}
              months - Pay with a loan through our NBFC partners:
            </div>

            {studentEMI?.is_submitted ? (
              <div className="mt-2 grid grid-cols-1 gap-2 text-sm leading-[160%] text-[#414141]">
                {studentEMI?.progress.map((progressItem: ProgressItem, index: number) => (
                  <div className="flex items-center gap-2" key={index}>
                    <div>
                      {progressItem.status === 1 ? (
                        <TickCircle />
                      ) : progressItem.status === -1 ? (
                        <CrossCircle />
                      ) : (
                        <BlankCircle />
                      )}
                    </div>
                    <div className="leading-normal">
                      {progressItem.name}
                      {progressItem.note && (
                        <div className="text-[10px] font-medium text-[#ADADAD]">{progressItem.note}</div>
                      )}
                    </div>
                    {index === 0 &&
                      (allFilesStatus === true ? (
                        <div className="ml-auto text-[10px] font-medium text-[#00C22B]">Upload Completed</div>
                      ) : (
                        <div className="ml-auto text-[10px] font-medium text-[#DC2626]">Upload Not Completed</div>
                      ))}
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-max-[50%] mt-2 leading-[160%] text-[#414141]">
                <ul className="list-disc pl-4">
                  <li key={0}>Pay your fees in monthly instalments as per your convenience.</li>
                  <li key={1}>Upload your documents as required to complete the loan process.</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {(studentEMI?.is_submitted === true || selectedEMIOptionToChoose !== -1) && (
          <UploadFiles
            month={
              studentEMI?.is_submitted === false
                ? months[selectedEMIOptionToChoose]
                : months[studentEMI?.duration_index]
            }
            allFilesStatus={allFilesStatus}
            studentEMI={studentEMI}
            paymentDocuments={paymentDocuments}
            getPaymentDocuments={getPaymentDocuments}
            uploadPaymentDocument={uploadPaymentDocument}
            deletePaymentDocument={deletePaymentDocument}
          />
        )}
        <div className="grid grid-cols-2 pt-6">
          <div className="text-[10px] font-medium">
            Total Loan Amount:
            <div className="text-base font-semibold text-[#312E81]">
              Rs.
              {Math.ceil(
                amount +
                  (amount * interestRate[studentEMI?.duration_index !== -1 ? studentEMI?.duration_index : interest]) /
                    100
              )
                ? Math.ceil(
                    amount +
                      (amount *
                        interestRate[studentEMI?.duration_index !== -1 ? studentEMI?.duration_index : interest]) /
                        100
                  )
                : amount}
            </div>
          </div>
          <div>
            {studentEMI?.progress?.length > 0 &&
              (studentEMI?.progress?.[2]?.status !== 1 ? (
                <button
                  className={cn(
                    "ml-auto block w-fit rounded-sm border border-new-accent bg-new-solid-white px-4 py-3 text-sm font-medium leading-4",
                    allowSubmit ? "hover:bg-new-accent hover:text-new-solid-white" : "opacity-[0.3]"
                  )}
                  disabled={!allowSubmit}
                  onClick={handleSubmitDocuments}
                >
                  Submit Documents
                </button>
              ) : (
                <div className="ml-auto block w-fit text-sm font-semibold leading-normal text-[#00C22B]">
                  You have successfully selected {months?.[selectedEMIOptionToChoose]} month EMI payment option.
                </div>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
