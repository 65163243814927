import { useState, useEffect } from "react"
import { ReactComponent as UserIcon } from "assets/svgs/user.svg"
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg"
import { BotIcon } from "lucide-react"

interface ChatModalProps {
  isOpen: boolean
  onClose: () => void
  response: string
  onSendMessage: (input: string) => void
  shorts: string
}

export const ChatModal: React.FC<ChatModalProps & { shorts: string }> = ({
  isOpen,
  onClose,
  response,
  onSendMessage,
  shorts,
}) => {
  const [messages, setMessages] = useState<{ sender: string; text: string; isStyled?: boolean; isShorts?: boolean }[]>(
    []
  )
  const [input, setInput] = useState("")

  useEffect(() => {
    // Clear messages whenever the modal is opened or closed
    setMessages([])
  }, [isOpen])

  useEffect(() => {
    if (response) {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages]
        const lastMessageIndex = updatedMessages.findIndex((msg) => msg.sender === "system" && msg.text === "typing")
        if (lastMessageIndex !== -1) {
          updatedMessages[lastMessageIndex] = { sender: "system", text: response }
        } else {
          updatedMessages.push({ sender: "system", text: response })
        }
        return updatedMessages
      })
    }
  }, [response])

  const handleSendMessage = () => {
    if (input.trim() === "") return

    setMessages((prevMessages) => [...prevMessages, { sender: "user", text: input }])
    setMessages((prevMessages) => [...prevMessages, { sender: "system", text: "typing" }])
    onSendMessage(input)
    setInput("")
  }

  const renderMessageWithPreTag = (text: string) => {
    const cleanedText = text
      .split("\n")
      .filter((line) => !line.includes("```"))
      .join("\n")
    return (
      <pre className="whitespace-pre-wrap break-words font-sans text-[14px] font-normal leading-[130%]">
        <div dangerouslySetInnerHTML={{ __html: cleanedText }} />
      </pre>
    )
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex justify-end bg-[#0C0C0CCC]">
      <div className="w-[30%] h-full bg-[#FFF] flex flex-col shadow-lg">
        <div className="flex justify-between items-center py-[16px] px-[34px] border-gray-300 bg-[#F9F9F9]">
          <h2 className="text-[18px] font-semibold text-[#333333]">Welcome to Orion</h2>
          <button
            onClick={() => {
              setMessages([])
              onClose()
            }}
            className="text-gray-600 hover:text-gray-800"
          >
            ✕
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4 group scrollbar-hide hover:scrollbar-thin hover:scrollbar-thumb-gray-400 hover:scrollbar-thumb-rounded">
          <div>
            <div className="flex mr-[20px] ml-[20px] mt-[10px]">
              <div className="flex justify-center items-center w-[24px] h-[24px] border rounded-[3px] bg-[#FFF] flex-shrink-0 mr-[20px]">
                <BotIcon className="w-4 h-4 text-white" />
              </div>
              <div className="p-4 rounded-[4px] flex bg-[#F9F9F9] justify-between items-center text-[#646464] font-[400] text-[14px] leading-[130%] font-inter max-w-[339px] h-auto">
                {renderMessageWithPreTag(shorts)}
              </div>
            </div>

            <div className="flex flex-col mr-[20px] ml-[65px] mt-[10px] space-y-2">
              <button
                onClick={() => {
                  const userMessage = `${shorts}\nExplain me more about it?`
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { sender: "user", text: `<b>Explain me more about it?</b>\n${shorts}`, isStyled: true },
                    { sender: "system", text: "typing" },
                  ])
                  onSendMessage(userMessage)
                }}
                className="px-[10px] py-[4px] bg-[#F9F9F9] text-black font-[400] text-[11px] rounded-[4px] hover:bg-[#EBEBEB] text-left h-auto w-[331px]"
              >
                Explain me more about it?
              </button>

              <button
                onClick={() => {
                  const userMessage = `${shorts}\nProvide me some simple examples`
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { sender: "user", text: `<b>Provide me some simple examples</b>\n${shorts}`, isStyled: true },
                    { sender: "system", text: "typing" },
                  ])
                  onSendMessage(userMessage)
                }}
                className="px-[10px] py-[4px] bg-[#F9F9F9] text-black font-[400] text-[11px] rounded-[4px] hover:bg-[#EBEBEB] text-left h-auto w-[331px]"
              >
                Provide me some simple examples?
              </button>
            </div>
            <div className="justify-self-center my-[24px] w-[372px] border-b border-gray-300"></div>
          </div>

          {messages.map((message, index) => (
            <div key={index} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
              {message.sender === "user" ? (
                <div className="flex mr-[20px] ml-[20px]">
                  <div
                    className={`p-4 rounded-[4px] flex justify-between items-center max-w-[339px] h-auto ${
                      message.isStyled
                        ? "text-[#646464] font-[700] text-[11px] leading-[130%] font-inter bg-[#F9F9F9]"
                        : "text-[#646464] font-[400] text-[14px] leading-[130%] font-inter bg-[#F9F9F9]"
                    }`}
                  >
                    {message.text === "typing" ? (
                      <div className="flex space-x-1">
                        <span className="h-2 w-2 bg-gray-600 rounded-full animate-bounce"></span>
                        <span className="h-2 w-2 bg-gray-600 rounded-full animate-bounce delay-100"></span>
                        <span className="h-2 w-2 bg-gray-600 rounded-full animate-bounce delay-200"></span>
                      </div>
                    ) : (
                      renderMessageWithPreTag(message.text)
                    )}
                  </div>
                  <div className="ml-[20px] flex justify-center items-center w-[24px] h-[24px] rounded-[3px] bg-[#5DC1F3] flex-shrink-0">
                    <UserIcon className="w-4 h-4 text-white" />
                  </div>
                </div>
              ) : (
                <div className="flex mr-[20px] ml-[20px]">
                  <div className="flex justify-center items-center w-[24px] h-[24px] border border-[0.5px] rounded-[3px] bg-[#FFF] flex-shrink-0 mr-[20px]">
                    <BotIcon className="w-4 h-4 text-white" />
                  </div>
                  <div className="p-4 rounded-[4px] flex bg-[#F9F9F9] justify-between items-center text-[#646464] font-[400] text-[14px] leading-[130%] font-inter max-w-[339px] h-auto">
                    {message.text === "typing" ? (
                      <div className="flex space-x-1">
                        <span className="h-2 w-2 bg-gray-600 rounded-full animate-bounce"></span>
                        <span className="h-2 w-2 bg-gray-600 rounded-full animate-bounce delay-100"></span>
                        <span className="h-2 w-2 bg-gray-600 rounded-full animate-bounce delay-200"></span>
                      </div>
                    ) : (
                      renderMessageWithPreTag(message.text)
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="border-t border-gray-300 py-[16px] px-[24px]">
          <div className="flex items-center space-x-2">
            <button
              onClick={handleSendMessage}
              className="w-[28px] h-[28px] bg-[#FFF] flex justify-center items-center rounded-full border border-[#FFF] hover:bg-gray-100 z-10 relative"
            >
              <SearchIcon className="w-[16px] h-[16px] text-[#646464]" />
            </button>
            <input
              type="text"
              placeholder="Type your query here!"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage()
                }
              }}
              className="p-[10px] flex-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatModal
