import Avatar from "react-avatar"
import { Tooltip } from "@mui/material"
import Button from "core/components/new/Button"
import { minsDiff } from "core/utils/date"
import { isEmpty } from "core/utils/misc"
import { format } from "date-fns"
import React, { useState } from "react"
import Session from "assets/svgs/payments/Session"
import axios from "axios"

const SessionCard = ({ session, isMentor }: any) => {
  const [rating, setRating] = useState("")
  const [ratingColor, setRatingColor] = useState("red")
  function formatDuration(durationInMinutes: number) {
    const exactMinutes = Math.floor(durationInMinutes)

    if (exactMinutes >= 60) {
      const hours = Math.floor(exactMinutes / 60)
      const minutes = exactMinutes % 60
      return `${hours} hour${hours > 1 ? "s" : ""} ${minutes > 0 ? `${minutes} min` : ""}`.trim()
    } else {
      return `${exactMinutes} min`
    }
  }

  function roundToNearestHalfHour(durationInMinutes: any) {
    const roundedDuration = Math.round(durationInMinutes * 30) / 30
    return roundedDuration
  }

  const formattedDate = session?.date
    ? format(new Date(session.date), "dd MMMM yyyy")
    : session?.session_date
      ? format(new Date(session.session_date), "dd MMMM yyyy")
      : "Date not available"

  const renderFeedbackStatus = (data: any) => {
    if (!isEmpty(data?.mentor_feedback)) {
      return (
        <div className="space-y-2">
          <p className="text-green-500 font-semibold">Feedback Provided</p>
        </div>
      )
    } else {
      return <p className="text-red-500 font-semibold">Feedback Not Provided</p>
    }
  }
  return (
    <>
      {isMentor ? (
        // Mentor's session Card View
        <div className="flex flex-col rounded-[4px] p-6 shadow gap-4 justify-between mb-1">
          <div className="flex flex-row justify-between items-center">
            <h3 className="text-new-accent">Student: {session.student_id}</h3>
          </div>
          <div className="flex flex-row justify-between items-center">
            <p className="text-[14px] leading-[17.07px]">Date: {formattedDate}</p>

            <div className="flex flex-row justify-between items-center">
              <p className="text-[14px] leading-[17.07px]">Batch: {session.batch}</p>
            </div>
            <div className="flex gap-x-4">{renderFeedbackStatus(session)}</div>
          </div>
        </div>
      ) : (
        // instructor session card view
        <div className="p-2">
          <div className="flex flex-col justify-between gap-4 rounded-[4px] p-6 shadow">
            <div className="flex flex-row justify-between">
              <h3 className="text-new-accent">{session?.chapter_name}</h3>
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-montserrat font-semibold text-[14px] leading-[17.07px]">
                Batches:
                {session?.batches?.join(" / ")} | {session.track}|{" "}
                {format(new Date(session?.session_timestamp * 1000), "dd MMMM yyyy | h:mm a")}
              </p>

              <div className="relative">
                <p className="font-montserrat font-semibold text-[14px] leading-[17.07px]">
                  <span className="text-black p-1 group relative">
                    {session.considered_duration
                      ? formatDuration(roundToNearestHalfHour(Number(session.considered_duration)))
                      : session.duration
                        ? formatDuration(roundToNearestHalfHour(Number(session.duration) / 60))
                        : formatDuration(roundToNearestHalfHour(Number(session.session_duration) * 60))}

                    <div className="absolute top-0 left-[-50px] mt-[-1.5rem] w-max px-2 py-1 text-sm bg-gray-700 text-white rounded opacity-0 group-hover:opacity-100 transition-opacity">
                      Considered Duration
                    </div>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SessionCard
