import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface checkTemplateTypeUseCase {
  invoke(auth: Auth, template_id: string): Promise<any>
}

export class CheckTemplateType implements checkTemplateTypeUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, template_id: string) {
    return await this.repository.checkTemplateType(auth, template_id)
  }
}
