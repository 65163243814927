import DashboardLayout from "core/layouts/DashboardLayout"
import useCertificateViewModel from "./CertificateViewModel"
import Spinner from "core/components/Spinner"
import Toast from "core/components/Toast"

const CertificateView = () => {
  const {
    email,
    setEmail,
    course,
    setCourse,
    months,
    setMonths,
    loading,
    generateCertificate,
    toast,
    changeToastVisibility,
  } = useCertificateViewModel()

  return (
    <DashboardLayout>
      <div className="p-6">
        <h2 className="text-new-accent">Generate certificate for specific student</h2>
        <div className="mt-1">You can generate the course completion certificate for a student</div>
        <div className="w-[35%]">
          <input
            className="rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-4 text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent block w-full mt-6"
            type="text"
            placeholder="Student Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          ></input>
          <input
            className="rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-4 text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent block w-full mt-6"
            type="number"
            min="1"
            step="1"
            placeholder="Enter Course Duration (in months)"
            value={months}
            onChange={(e) => {
              setMonths(e.target.value as unknown as number)
            }}
          ></input>
          <select
            className="cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-4 text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent block w-full mt-6"
            value={course}
            onChange={(e) => {
              setCourse(e.target.value)
            }}
          >
            <option value="">Select Course</option>
            <option className="bg-neutral-50 text-neutral-500" value="Evolve">
              Evolve
            </option>
            <option className="bg-neutral-50 text-neutral-500" value="Transformer">
              Transformer
            </option>
            <option className="bg-neutral-50 text-neutral-500" value="Data_Scientist">
              Data Science
            </option>
            <option className="bg-neutral-50 text-neutral-500" value="Data_Analyst">
              Data Analysis
            </option>
            <option className="bg-neutral-50 text-neutral-500" value="Data-(Scientist+Analyst)">
              Data Science + Data Analysis
            </option>
          </select>
          <button
            onClick={generateCertificate}
            className="max-h-[52px] flex w-full select-none items-center justify-center rounded-sm font-medium py-4 px-8 text-base leading-5 bg-new-gradient text-new-solid-white hover:bg-new-accent hover:bg-none mt-6"
          >
            {loading ? <Spinner /> : "Generate Cerificate"}
          </button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}

export default CertificateView
