import { Auth } from "domain/model/Auth"
import { UpdateStudentExpierenceDetails } from "domain/model/UpdateStudentExpierence"

import StudentExpirenceRepository from "domain/repository/Student/StudentExpierenceRepository"

export interface GetStudentExpirenceUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetStudentExpierence implements GetStudentExpirenceUseCase {
  private repository: StudentExpirenceRepository

  constructor(repository: StudentExpirenceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getStudentExpirence(auth)
  }
}
