import { useState } from "react"
import { useAuth } from "core/context/auth"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import GetMcqQuestionDetails from "domain/useCase/User/Contest/GetMcqQuestionDetails"
import useToast from "core/hooks/useToast"
import { STR_ACCEPTED, STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import SubmissionAPIDataSourceImpl from "data/API/Student/SubmissionAPIDataSourceImpl"
import { SubmissionRepositoryImpl } from "data/repository/Student/SubmissionRepositoryImpl"
import SubmitAssignmentQuestion from "domain/useCase/Student/Assignment/SubmitAssignmentQuestion"
import GetSubmission from "domain/useCase/Student/Assignment/GetSubmission"
import { genError } from "core/utils/string"
import { SaveQuestion } from "domain/useCase/Student/QuestionBank/SaveQuestion"
import { QuestionBankRepositoryImpl } from "data/repository/Student/QuestionBankRepositoryImpl"
import { QuestionBankAPIDataSourceImpl } from "data/API/Student/QuestionBankAPIDataSourceImpl"

export default function ViewModel() {
  const { auth } = useAuth()
  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [question, setQuestion] = useState<any>({})
  const [optionSelected, setOptionSelected] = useState<number>(-1)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [viewSolution, setViewSolution] = useState<boolean>(false)
  const [isSolutionViewed, setIsSolutionViewed] = useState<boolean>(false)
  const [scoredPointsAfterSubmission, setScoredPointsAfterSubmission] = useState<number | null>(null)
  const [result, setResult] = useState<boolean | null>(null)

  const TABS = ["Description", "Solution"]

  const saveQuestionUseCase = new SaveQuestion(new QuestionBankRepositoryImpl(new QuestionBankAPIDataSourceImpl()))
  const getMcqQuestionUseCase = new GetMcqQuestionDetails(new ContestAPIDataSourceImpl())

  const submitMcqQuestionUseCase = new SubmitAssignmentQuestion(
    new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl())
  )

  const getSubmissionUseCase = new GetSubmission(new SubmissionRepositoryImpl(new SubmissionAPIDataSourceImpl()))

  async function fetchQuestion(id: string) {
    setPageLoading(true)

    const response = await getMcqQuestionUseCase.invoke(auth, id)

    setPageLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Failed to load the question data")
      changeToastVisibility(true)
      return
    }

    setQuestion(response?.data)
  }

  //for saving to question bank
  const handleAddToQuestionBank = async () => {
    try {
      if (!question) {
        console.error("Question data is missing")
        return
      }

      //console.log("This is question fields",question)

      // console.log("Fields for saveQuestionUseCase:", {
      //   problem_name: question?.name,
      //   problem_id: question?.topic,
      //   problem_link: `https://platform.bosscoderacademy.com/mcqs/${question?.question_id}`,
      //   status: question?.status,
      // });

      const response = await saveQuestionUseCase.invoke(
        auth, // Assuming `auth` is available in your context
        question.name,
        question.question_id,
        "MCQ",
        `https://platform.bosscoderacademy.com/mcqs/${question.question_id}`,
        question.status,
        Array.isArray(question.topic) ? question.topic : question.topic ? [question.topic] : []
      )
      if (response == true) {
        changeToastDetails(STR_SUCCESS, "Question Added to Question Bank Successfully")
        changeToastVisibility(true)
        return
      } else if (response == undefined) {
        changeToastDetails(STR_FAILURE, "Failed to Add Question to Question Bank")
        changeToastVisibility(true)
        return
      }

      //console.log("Question successfully added to the question bank:", response);
    } catch (error) {
      console.error("Failed to add question to the question bank:", error)
    }
  }

  async function fetchSubmission(id: string) {
    setPageLoading(true)

    const response = await getSubmissionUseCase.invoke(auth, { question_id: id })

    setPageLoading(false)

    if (!response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Failed to load the submission")
      return
    }

    const answer = response?.data?.answer
    const isSolutionViewed = response?.data?.isSolutionViewed || false
    if (answer !== "") {
      setOptionSelected(parseInt(answer))
    }

    setViewSolution(isSolutionViewed)
    setIsSolutionViewed(isSolutionViewed)
  }

  function confirmViewSoluton() {
    setActiveTab(1)
    setViewSolution(false)
    setIsSolutionViewed(true)
  }

  function denyViewSolution() {
    setActiveTab(0)
    setViewSolution(false)
  }

  function handelTabChange(index: number) {
    if (index === 0) {
      setActiveTab(0)
    } else {
      if (isSolutionViewed) {
        setActiveTab(1)
      } else {
        setViewSolution(true)
      }
    }
  }

  function handleOptionChange(e: any) {
    setOptionSelected(parseInt(e.target.value))
  }

  async function handleSolutionSubmit(id: string) {
    if (isNaN(optionSelected) || optionSelected === -1) {
      changeToastDetails(STR_FAILURE, "Select the option to submit the question")
      changeToastVisibility(true)
      return
    }

    setLoading(true)

    const response = await submitMcqQuestionUseCase.invoke(auth, {
      question_id: id,
      question_type: "mcq",
      answer: optionSelected,
      is_solution_viewed: isSolutionViewed,
    })

    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    setScoredPointsAfterSubmission(response?.data?.scored_points)

    const result = response?.data?.result

    changeToastDetails(result ? STR_ACCEPTED : STR_FAILURE, result ? "Correct" : "Incorrect")
    changeToastVisibility(true)
  }

  return {
    toast,
    pageLoading,
    loading,
    question,
    optionSelected,
    TABS,
    activeTab,
    viewSolution,
    isSolutionViewed,
    scoredPointsAfterSubmission,
    changeToastVisibility,
    fetchQuestion,
    fetchSubmission,
    handleOptionChange,
    handleSolutionSubmit,
    setActiveTab,
    setViewSolution,
    setIsSolutionViewed,
    confirmViewSoluton,
    denyViewSolution,
    handelTabChange,
    handleAddToQuestionBank,
  }
}
