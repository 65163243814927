import React, { useState } from "react"
import IconButton from "core/components/IconButton"
import Toggle from "core/components/Toggle"
import { Link } from "react-router-dom"
import Avatar from "react-avatar"
import CopyIcon from "assets/svgs/Copy"
import { STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import { copyToClipboard } from "core/utils/copyToClipboard"

export default function InfluencerDashboardTable(props: any) {
  const [showFullEmail, setShowFullEmail] = useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const copyEmail = (email: string) => {
    copyToClipboard(email)
      .then(() => {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Email Copied to clipboard")
      })
      .catch((err) => {
        console.error("Failed to copy email: ", err)
      })
  }

  return (
    <div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="relative mx-[24px] overflow-x-auto rounded-md shadow-md">
        <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 ">
          <thead className="h-[40px] bg-gradient-to-r from-[#06A1F9] to-[#2246C3] text-[16px] text-white">
            <tr>
              {props.tableHeaders?.map((title: any, index: any) => (
                <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.influencerData
              ?.filter((data: any) => {
                const emailMatches = props.searchingforEmail
                  ? data.email?.toLowerCase().includes(props.searchByEmail?.toLowerCase())
                  : true
                const campaignMatches = props.searchingByCampaign
                  ? Array.isArray(data.selected_campaign)
                    ? data.selected_campaign.some((campaign: string) =>
                        campaign.toLowerCase().includes(props.searchByCampaign?.toLowerCase())
                      )
                    : data.selected_campaign?.toLowerCase().includes(props.searchByCampaign?.toLowerCase())
                  : true
                const programMatches = props.searchingByProgram
                  ? Array.isArray(data.selected_programs)
                    ? data.selected_programs.some((program: string) =>
                        program.toLowerCase().includes(props.searchByProgram?.toLowerCase())
                      )
                    : data.selected_programs?.toLowerCase().includes(props.searchByProgram?.toLowerCase())
                  : true

                return emailMatches && campaignMatches && programMatches
              })
              .map((data: any, i: number) => (
                <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={data.email}>
                  <td className="px-6 py-4">
                    <Avatar name={data.name} src={data.photo} round size="60" />
                  </td>
                  <td className="px-6 py-4">
                    <p>{data.name}</p>
                    <p>{data.phone}</p>
                    <p>{data.program}</p>
                    <p
                      className="max-w-xs cursor-pointer overflow-hidden font-medium"
                      id="email"
                      onMouseEnter={() => setShowFullEmail(true)}
                      onMouseLeave={() => setShowFullEmail(false)}
                    >
                      <button onClick={() => copyEmail(data.email)}>
                        <CopyIcon />
                      </button>
                    </p>
                  </td>
                  <td className="px-6 py-4 text-[16px]">{data.unique_code}</td>
                  <td className="px-6 py-4 text-[16px]">{data.company_name}</td>
                  <td className="px-6 py-4 text-[16px]">{data.experience}</td>

                  <td className="px-6 py-4 text-[16px]">
                    <span
                      className={`px-3 py-1 text-white text-sm rounded-md ${
                        data.isRazorpaySignedIn ? "bg-green-500" : "bg-red-500"
                      }`}
                    >
                      {data.isRazorpaySignedIn ? "Signed In" : "Not Signed In"}
                    </span>
                  </td>

                  <td className="px-6 py-4 text-[16px]">
                    <Link to={`/admin/influencer/profile/edit/${data.email}`}>
                      <IconButton
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit"
                          >
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                          </svg>
                        }
                      />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
