import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Influencer/ProfileRepository"

export interface FetchInfluencerProfileUseCase {
  invoke(email: string, auth: Auth): Promise<any>
}

export default class FetchInfluencerProfile implements FetchInfluencerProfileUseCase {
  private repository: ProfileRepository
  constructor(repository: ProfileRepository) {
    this.repository = repository
  }
  async invoke(email: string, auth: Auth): Promise<any> {
    return await this.repository.fetchInfluencerProfile(email, auth)
  }
}
