import Input from "core/components/Input"
import { Button } from "core/components/v2/Button"
import StandardSelect from "core/components/v2/StandardSelect"
import useReferViewModel from "./ReferViewModel"
import { useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import LoginPageGradient from "core/components/new/LoginPageGradient"
import LineBottom from "core/components/payment-method/LineBottom"
import LineBottomRot from "assets/svgs/LineBottomRot"
import BosscoderReferLogo from "assets/svgs/BosscoderReferLogo"
import Toast from "core/components/Toast"
import Spinner from "core/components/Spinner"
import BosscoderLogoDoted from "assets/svgs/BosscoderLogoDoted"
import PhoneInput from "react-phone-number-input"
import { CustomScroll } from "react-custom-scroll"

const ReferLinkView = () => {
  const {
    formData,
    getReferrerData,
    handleChange2,
    handlePhoneNumber,
    handleRealationOptions,
    handleCourseOptions,
    handleSubmitLink,
    toast,
    loading,
    changeToastVisibility,
    courseOptions,
  } = useReferViewModel()
  const { email } = useParams()
  useEffect(() => {
    console.log(email)
    getReferrerData(email || "")
  }, [])
  return (
    <div className="flex justify-center items-center min-h-screen ">
      <div className="flex justify-between items-center w-full h-[100vh] bg-[#fff]">
        <div className="relative w-1/3 h-full bg-[#333]">
          <div className="absolute top-[36px] left-[36px]">
            <BosscoderReferLogo />
          </div>
          <div className="absolute top-[20%] -right-[50%] min-[1920px]:-right-[40%]">
            <BosscoderLogoDoted />
          </div>
          <div className="absolute bottom-0">
            <LineBottomRot />
          </div>
          <div className="absolute bottom-0 -left-[80px]">
            <LineBottomRot />
          </div>
        </div>
        <div className="relative overflow-hidden flex justify-center py-[40px] items-center w-2/3 h-full bg-white rounded-[16px]">
          <div className="absolute top-0 right-0">
            <LoginPageGradient className={"#C9E5F5"} />
          </div>

          <div className="absolute top-0 -right-[60px]">
            <LoginPageGradient className={"#C9E5F5"} />
          </div>

          <div className="absolute bottom-0 left-0 scale-y-[-1] scale-x-[-1] w-[42%]">
            <div className="absolute">
              <LoginPageGradient className={"#C9E5F5"} />
            </div>
            <div className="absolute left-[60px]">
              <LoginPageGradient className={"#C9E5F5"} />
            </div>
          </div>
          <div className="flex justify-center gap-8 p-6">
            <div className="flex w-[70%] p-6 bg-white rounded-[8px] border-[1px]  border-[solid var(--color-shades-bw-gray-400, #D3D3D3) z-30">
              <div className="w-[50%] pr-8 h-[556px] bg-white p-[24px] rounded-[8px] flex flex-col">
                <div className="text-new-solid-black text-[14px] mb-[1px]">
                  {formData?.yourName ? (
                    <>
                      <p
                        className="text-[14px] mb-[6px] 
                    font-[600]"
                      >
                        Congratulations!
                      </p>
                      <p className="text-[12px] font-[500]">
                        {formData?.yourName} has referred you to Bosscoder Academy!{" "}
                      </p>
                    </>
                  ) : (
                    <div className="mb-[16px]">
                      <p className="text-[14px] font-[600]">Welcome To Bosscoder Academy</p>
                    </div>
                  )}
                </div>
                <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
                  Name
                  <span className="text-red-600">*</span>
                </div>
                <Input
                  placeholder="Type here"
                  type="text"
                  name="referName"
                  className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                  value={formData.referName}
                  onChange={handleChange2}
                />
                <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
                  Email
                  <span className="text-red-600">*</span>
                </div>
                <Input
                  placeholder="Type here"
                  type="text"
                  name="referEmail"
                  value={formData.referEmail}
                  onChange={handleChange2}
                  className="my-[16px] font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                />
                <div className="text-new-solid-black text-[14px] h-[17px] mb-[3px]">
                  Phone Number
                  <span className="text-red-600">*</span>
                </div>
                <PhoneInput
                  defaultCountry="IN"
                  value={formData.referNumber}
                  onChange={handlePhoneNumber}
                  placeholder="Phone Number"
                  limitMaxLength
                  className="mb-[16px]"
                />
                <div className="text-new-solid-black text-[14px] h-[17px] mb-[1px]">
                  Name of the Referrer
                  <span className="text-red-600">*</span>
                </div>
                <Input
                  placeholder="Type here"
                  type="text"
                  name="yourName"
                  value={formData.yourName}
                  onChange={handleChange2}
                  className="font-Inter text-sm rounded-[4px] h-[38px] mt-1 w-full border border-gray-300 bg-new-solid-white px-4 py-2 leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                />

                <div className="my-[16px] flex flex-col justify-between gap-0 font-semibold">
                  <div className="text-new-solid-black text-[14px] font-normal">
                    Relationship<span className="text-red-600">*</span>
                  </div>
                  <StandardSelect
                    dimensions="w-full"
                    className="px-4 font-Inter text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                    placeholder={"Select here"}
                    name="relation"
                    value={formData.relation}
                    onChange={handleRealationOptions}
                    options={["Friend", "Relative", "Other"]}
                    required
                  />
                </div>

                <div className="flex flex-col justify-between gap-0 font-semibold">
                  <div className="text-new-solid-black text-[14px] font-normal">
                    Course Type<span className="text-red-600">*</span>
                  </div>
                  <StandardSelect
                    dimensions="w-full"
                    className="px-4 text-sm font-normal rounded-[4px] mt-1 text-new-neutral border border-gray-300 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                    placeholder={"Select here"}
                    name="course"
                    value={formData.course}
                    onChange={handleCourseOptions}
                    options={courseOptions}
                    required
                  />
                </div>

                <div className="flex flex-row justify-between my-[16px]">
                  <label className="flex items-start">
                    <span className="text-[10px] text-[#E00000] font-[500]">
                      ** You will receive a call from our career expert within 24 hours.
                    </span>
                  </label>
                </div>

                <Button
                  onClick={handleSubmitLink}
                  className="w-full h-[35px]"
                  size="sm"
                  border={"thin"}
                  variant={"primary"}
                  disabled={
                    loading ||
                    !formData.referName ||
                    !formData.referEmail ||
                    !formData.referNumber ||
                    !formData.relation ||
                    !formData.course
                  }
                >
                  {loading ? <Spinner small={true} /> : "Submit"}
                </Button>
              </div>

              <div className="w-[50%] mt-5 h-[584px] bg-white px-[24px] pt-[24px] rounded-[8px] border border-[#D3D3D3] shadow-[rgba(34,41,48,0.15)_-1px_1px_3px_0px] overflow-hidden">
                <CustomScroll heightRelativeToParent="100%" className="rounded-lg no-scrollbar">
                  <div className="h-full pr-4">
                    <p className="text-[#1C3474] text-[16px] font-[600] mb-[8px]">
                      Refer and Earn Program Terms and Conditions
                    </p>

                    <p className="text-[14px] font-bold">• Eligibility:</p>
                    <p className="text-[14px] mb-[8px]">
                      The program is available to students who are currently enrolled in the course.
                    </p>

                    <p className="text-[14px] font-bold">• Referral Process:</p>
                    <p className="text-[14px] mb-[8px]">
                      To refer someone, the referrer must fill out the referral form available on the student portal.
                    </p>
                    <p className="text-[14px] mb-[8px]">
                      Once the referee successfully enrolls and completes one month of their course duration (starting
                      from the batch commencement), both the referrer and referee will receive their respective rewards.
                    </p>

                    <p className="text-[14px] font-bold">• Reward Criteria:</p>
                    <p className="text-[14px] mb-[8px]">
                      The reward for both the referrer and the referee depends on the specific course each is enrolled
                      in.
                    </p>
                    <p className="text-[14px] mb-[8px]">
                      Cashback and rewards vary for each course, and both the referrer and referee will receive rewards
                      based on the course they are in.
                    </p>

                    <p className="text-[14px] font-bold">• Reward Distribution:</p>
                    <p className="text-[14px] mb-[8px]">
                      Rewards for both the referrer and the referee will be issued after the referee has completed one
                      month of their course, starting from the batch class commencement.
                    </p>

                    <p className="text-[14px] font-bold">• TDS Deduction:</p>
                    <p className="text-[14px] mb-[8px]">
                      A 10% Tax Deducted at Source (TDS) will be deducted on any reward amount exceeding ₹3000, in
                      accordance with financial regulations.
                    </p>
                    <p className="text-[14px] mb-[8px]">
                      If a referrer has received a reward of ₹3000 or more without TDS being deducted, and later refers
                      someone else, TDS will be deducted on the new referral reward, including any previous amounts
                      where TDS was not applied.
                    </p>

                    <p className="text-[14px] font-bold">• Exclusions:</p>
                    <p className="text-[14px] mb-[8px]">
                      These terms apply to standard referral rewards only. Different terms and conditions apply to
                      special offers, which are visible on the offer application form.
                    </p>

                    <p className="text-[14px] font-bold">• Program Modifications:</p>
                    <p className="text-[14px] mb-[8px]">
                      The program’s terms and conditions are subject to change at any time, with updates communicated
                      through official channels.
                    </p>

                    <p className="text-[14px] font-normal">
                      By participating in the Refer and Earn program, you acknowledge and agree to these terms and
                      conditions. For any queries, please contact support via the help desk.
                    </p>
                  </div>
                </CustomScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}

export default ReferLinkView
