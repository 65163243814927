import React, { useEffect } from "react"
import Button from "core/components/Button"
import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useinfluencerProfileViewModel from "./InfluencerProfilePageViewModel"
import Toast from "core/components/Toast"
import { DropDown } from "assets/svgs/Dropdown"
import { Textarea } from "core/components/v2/Textarea"
import Spinner from "core/components/Spinner"
import { STR_TEXT_FIELD, STR_DATE_FIELD } from "core/constants/strings"

export default function InfluencerProfilePageView() {
  const {
    CampaignData,
    socialMediaLinks,
    selected_campaignType,
    loading,
    Influencer,
    toast,
    programType,
    Rates,
    FormattedDate,
    newLink,
    programTypeSuggestions,
    selected_programType,
    campaignType,
    newLinks,
    showDetails,
    textArea1,
    Compensation,
    selected_campaign,
    textArea2,
    setNewLink,
    setselected_programType,
    getCampaignType,
    handleAddCampaignType,
    setselected_campaignType,
    handleProgramTypeInputChange,
    handleCampaignTypeInputChange,
    onChangeInfluencerObj,
    updateInfluencerProfile,
    changeToastVisibility,
    GetInfluencerProfile,
    generateCID,
    handleProfilePictureUpload,
    handleShowDetails,
    setTextArea1,
    setTextArea2,
    getAllRates,
    setprogramType,
    setcampaignType,
    setselected_campaign,
    setNewLinks,
    setUpdatedCompensation,
    onInputChange,
    processAndSortCampaignData,
    updateCampaignDetails,
    setSocialMediaLinks,
  } = useinfluencerProfileViewModel()

  useEffect(() => {
    const email = window?.location?.pathname?.split("/")[5]
    GetInfluencerProfile(email)
    getAllRates(email)
    getCampaignType()
  }, [])

  useEffect(() => {
    if (Influencer?.hostname_and_links) {
      if (typeof Influencer.hostname_and_links === "object" && !Array.isArray(Influencer.hostname_and_links)) {
        const formattedLinks = Object.entries(Influencer.hostname_and_links).map(([hostname, link]: any) => ({
          hostname,
          link,
        }))
        setSocialMediaLinks(formattedLinks)
      } else {
        setSocialMediaLinks(Influencer.hostname_and_links)
      }
    }
  }, [Influencer])
  const handleNewLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLink(e.target.value)
  }
  const handleAddLink = () => {
    if (newLink.trim() !== "") {
      setNewLinks((prevLinks: any) => [...prevLinks, newLink.trim()])
      setNewLink("")
    }
  }
  const handleRemoveLink = (index: number) => {
    setNewLinks((prevLinks) => prevLinks.filter((_, i) => i !== index))
  }
  const subtractOneDay = (dateStr: string) => {
    const [day, month, year] = dateStr.split("-")
    const date = new Date(`${year}-${month}-${day}`)
    date.setDate(date.getDate() - 1)

    const newDay = String(date.getDate()).padStart(2, "0")
    const newMonth = String(date.getMonth() + 1).padStart(2, "0")
    const newYear = date.getFullYear()
    return `${newDay}-${newMonth}-${newYear}`
  }
  return (
    <DashboardLayout>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      {loading ? (
        <div className="flex justify-center align-middle items-center">
          <Spinner></Spinner>
        </div>
      ) : (
        <div className="px-12 py-10">
          <div>
            <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-2 cursor-pointer">
              <div
                className="flex flex-row justify-between items-center p-2 cursor-pointer"
                onClick={() => handleShowDetails("basicInformation")}
              >
                <div className="text-[20px] font-semibold">Basic Information</div>
                <button className={showDetails !== "basicInformation" ? "rotate-180" : ""}>
                  <DropDown />
                </button>
              </div>
              {showDetails === "basicInformation" && (
                <div
                  className={`grid grid-cols-4 gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                    showDetails === "basicInformation" ? "opacity-100 max-h-full" : "opacity-0 max-h-0"
                  } overflow-hidden`}
                >
                  <Input placeholder="Email" value={Influencer?.email} id="email" />
                  <Input placeholder="Name" value={Influencer?.name} onChange={onChangeInfluencerObj} id="name" />
                  <Input placeholder="Phone" value={Influencer?.phone} onChange={onChangeInfluencerObj} id="phone" />
                  <Input
                    placeholder="Current company"
                    value={Influencer?.company_name}
                    onChange={onChangeInfluencerObj}
                    id="company_name"
                  />
                  <Input
                    placeholder="Experience"
                    value={Influencer?.experience}
                    onChange={onChangeInfluencerObj}
                    id="experience"
                  />
                  <Input
                    type="file"
                    onChange={handleProfilePictureUpload}
                    id="Influencer_profile_image"
                    className="file:bg-none file:p-0 file:border-none"
                  />
                  <Input type="text" value={Influencer?.photo} containerClass="col-span-2" />
                  <Input placeholder="Password" value={Influencer?.password} id="password" />
                  <Input
                    placeholder="Unique code"
                    type="text"
                    onChange={onChangeInfluencerObj}
                    value={Influencer?.unique_code}
                    id="unique_code"
                  />
                  <div></div>
                  <div></div>
                  <div className="flex">
                    <div className="mb-4 mt-4 py-1 px-2 text-[20px] font-semibold col-span-4">
                      <ul className="ml-4 list-disc">
                        <li>Program Type</li>
                      </ul>

                      <select
                        className="w-[320px] h-[40px] my-[10px] rounded focus:border focus:border-blue-500"
                        value={selected_programType}
                        onChange={(e) => {
                          setselected_programType(e.target.value)
                          if (e.target.value !== "Other") {
                            setTextArea1(e.target.value)
                          } else {
                            setTextArea1("")
                          }
                        }}
                      >
                        <option value="">Select Program Type</option>
                        {programTypeSuggestions.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>

                      {selected_programType === "Other" && (
                        <textarea
                          placeholder="Add Program Type..."
                          className="w-[320px] h-[100px] overflow-y-auto my-[10px] rounded focus:border focus:border-blue-500"
                          onChange={handleProgramTypeInputChange}
                          value={textArea1}
                        />
                      )}

                      <Button
                        className="h-8 w-20 mb-[16px]"
                        onClick={() => {
                          if (selected_programType !== "Other" && selected_programType != "") {
                            if (!programType.includes(selected_programType)) {
                              setprogramType([...programType, selected_programType])
                            }
                          } else if (textArea1.trim() !== "") {
                            setprogramType([...programType, textArea1])
                          }
                          setTextArea1("")
                          setselected_programType("")
                        }}
                      >
                        Add
                      </Button>

                      <div className="flex gap-[4px] mb-[16px]">
                        {programType.map((oneStack1, i) => (
                          <button
                            key={i}
                            className="border-2 rounded pl-2 flex justify-between items-center w-fit gap-[4px]"
                          >
                            <p>{oneStack1}</p>
                            <span
                              className="hover:text-red-400 px-1"
                              onClick={() => {
                                const newProgramType = programType.filter((tech) => tech !== oneStack1)
                                setprogramType(newProgramType)
                              }}
                            >
                              x
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className="mb-4 mt-4 py-1 px-2 text-[20px] font-semibold col-span-4">
                      <ul className="ml-4 list-disc">
                        <li>Campaign Type</li>
                      </ul>

                      <select
                        className="w-[320px] h-[40px] my-[10px] rounded focus:border focus:border-blue-500"
                        value={selected_campaignType}
                        onChange={(e) => {
                          setselected_campaignType(e.target.value)
                          if (e.target.value !== "Other") {
                            setTextArea2(e.target.value)
                          } else {
                            setTextArea2("")
                          }
                        }}
                      >
                        <option value="">Select Campaign Type</option>
                        {CampaignData.map((type: any, index: any) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>

                      {selected_campaignType === "Other" && (
                        <textarea
                          placeholder="Add Campaign Type..."
                          className="w-[320px] h-[100px] overflow-y-auto my-[10px] rounded focus:border focus:border-blue-500"
                          onChange={handleCampaignTypeInputChange}
                          value={textArea2}
                        />
                      )}

                      <Button className="h-8 w-20 mb-[16px]" onClick={handleAddCampaignType}>
                        Add
                      </Button>

                      <div className="flex gap-[4px] mb-[16px]">
                        {campaignType.map((oneStack2, i) => (
                          <button
                            key={i}
                            className="border-2 rounded pl-2 flex justify-between items-center w-fit gap-[4px]"
                          >
                            <p>{oneStack2}</p>
                            <span
                              className="hover:text-red-400 px-1"
                              onClick={() => {
                                const newCampaignType = campaignType.filter((tech) => tech !== oneStack2)
                                setcampaignType(newCampaignType)
                              }}
                            >
                              x
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-4 cursor-pointer">
            <div
              className="flex flex-row justify-between items-center p-2 cursor-pointer"
              onClick={() => handleShowDetails("invoicingDetails")}
            >
              <div className="text-[20px] font-semibold">Invoicing Details</div>
              <button className={showDetails !== "invoicingDetails" ? "rotate-180" : ""}>
                <DropDown />
              </button>
            </div>
            {showDetails === "invoicingDetails" && (
              <div
                className={`flex flex-wrap gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                  showDetails === "invoicingDetails" ? "opacity-100 h-auto" : "opacity-0 h-0"
                } overflow-hidden`}
              >
                <Textarea
                  placeholder="Address"
                  value={Influencer?.address}
                  id="address"
                  onChange={(e: any) => onChangeInfluencerObj(e)}
                />
                <Input
                  placeholder="Beneficiary Name"
                  value={Influencer?.beneficiary_name}
                  onChange={onChangeInfluencerObj}
                  id="beneficiary_name"
                />
                <Input placeholder="IFSC Code" value={Influencer?.IFSC} onChange={onChangeInfluencerObj} id="IFSC" />
                <Input
                  placeholder="Account Number"
                  value={Influencer?.account_number}
                  onChange={onChangeInfluencerObj}
                  id="account_number"
                  containerClass="flex-1"
                />
              </div>
            )}
          </div>
          <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-4 cursor-pointer">
            <div
              className="flex flex-row justify-between items-center p-2 cursor-pointer"
              onClick={() => handleShowDetails("compensiationdetails")}
            >
              <div className="text-[20px] font-semibold">Compensation details</div>
              <button className={showDetails !== "compensiationdetails" ? "rotate-180" : ""}>
                <DropDown />
              </button>
            </div>
            {showDetails === "compensiationdetails" && (
              <div
                className={`flex flex-col gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                  showDetails === "compensiationdetails" ? "opacity-100 h-auto" : "opacity-0 h-0"
                } overflow-hidden`}
              >
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700">Select Campaign:</label>
                  <select
                    className="mt-1 w-full rounded border-gray-300 p-2 text-gray-700"
                    value={selected_campaign}
                    onChange={(e) => setselected_campaign(e.target.value)}
                  >
                    <option value="">Select a Campaign</option>
                    {Influencer?.selected_campaign?.map((campaign: any, index: number) => (
                      <option key={index} value={campaign}>
                        {campaign}
                      </option>
                    ))}
                  </select>
                </div>

                {selected_campaign && (
                  <>
                    <div className="mt-4 border rounded-lg p-4 bg-gray-50">
                      <h3 className="text-lg font-semibold text-gray-800">Current Details</h3>

                      <div className="mt-4 overflow-x-auto">
                        <table className="min-w-full border-collapse border border-gray-300">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                                S.no
                              </th>
                              <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                                From - To
                              </th>
                              <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                                Rate
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(() => {
                              const campaignData = processAndSortCampaignData(Rates) || {}
                              const x = generateCID(selected_campaign)
                              const selected_campaignData = campaignData.find((value: any) => value.campaign === x)

                              if (!selected_campaignData || Object.keys(selected_campaignData).length === 0) {
                                return (
                                  <tr>
                                    <td colSpan={3} className="text-center text-gray-500">
                                      No data available for the selected campaign
                                    </td>
                                  </tr>
                                )
                              }

                              const dates = selected_campaignData.dates.map((entry: any) => entry.date)
                              const rates = selected_campaignData.dates.map((entry: any) => Number(entry.value))

                              let serial = 0
                              return dates.map((date, i) => {
                                const fromDate = date
                                const toDate = dates[i + 1] ? subtractOneDay(dates[i + 1]) : "Ongoing"
                                serial++

                                return (
                                  <tr key={`row-${serial}`}>
                                    <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">{serial}</td>
                                    <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                                      {fromDate} to {toDate}
                                    </td>
                                    <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                                      {rates[i]}
                                    </td>
                                  </tr>
                                )
                              })
                            })()}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="mt-4 border rounded-lg p-4 bg-gray-50">
                      <h3 className="text-lg font-semibold text-gray-800">Update Details</h3>
                      <Input
                        placeholder="Compensation⟨₹⟩"
                        value={Compensation}
                        onChange={(e: any) => setUpdatedCompensation(e.target.value)}
                        id="updatedCompensation"
                        containerClass="mb-2"
                      />
                      <Input
                        placeholder="Appicable Date"
                        type={STR_TEXT_FIELD}
                        value={FormattedDate}
                        onChange={(e: any) => onInputChange(e)}
                        id="updatedDuration"
                        onBlur={(e) => (e.target.type = STR_TEXT_FIELD)}
                        onFocus={(e) => (e.target.type = STR_DATE_FIELD)}
                      />
                      <button
                        className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={updateCampaignDetails}
                      >
                        Update Campaign
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-2 cursor-pointer">
            <div
              className="flex flex-row justify-between items-center p-2 cursor-pointer"
              onClick={() => handleShowDetails("socialMediaLinks")}
            >
              <div className="text-[20px] font-semibold">Social Media Links</div>
              <button className={showDetails !== "socialMediaLinks" ? "rotate-180" : ""}>
                <DropDown />
              </button>
            </div>

            {showDetails === "socialMediaLinks" && (
              <div className="flex flex-col gap-4 p-4 transition-all duration-700 ease-in-out transform opacity-100 overflow-hidden">
                <div className="mb-4 py-1 px-2 text-[20px] font-semibold col-span-4">
                  <table className="w-full table-auto border-separate border-spacing-2">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 text-left border-b text-sm font-semibold">Platform</th>
                        <th className="py-2 px-4 text-left border-b text-sm font-semibold">Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {socialMediaLinks.length > 0 ? (
                        socialMediaLinks.map(({ hostname, link }: any, index) => (
                          <tr key={index} className="border-b hover:bg-gray-100">
                            <td className="py-2 px-4 text-sm">{hostname}</td>
                            <td className="py-2 px-4 text-sm">
                              <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                {link}
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} className="text-center py-4 text-gray-500">
                            No links added yet.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <Input
                      placeholder="Enter a new social media link"
                      value={newLink}
                      onChange={handleNewLinkChange}
                      className="w-[300px] border-[0.4px] border-[#ADADAD] p-2 rounded"
                    />
                    <Button className="h-8 px-4 w-[100px] bg-green-500 text-white rounded" onClick={handleAddLink}>
                      Add
                    </Button>
                  </div>

                  <ul className="w-full space-y-2">
                    {newLinks.map((link, index) => (
                      <li key={index} className="flex items-center justify-between rounded-md bg-gray-100 p-2">
                        <span className="text-sm text-gray-700 truncate">{link}</span>
                        <Button
                          className="h-8 px-4 w-[100px] bg-red-500 text-white rounded"
                          onClick={() => handleRemoveLink(index)}
                        >
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <div className="flex w-40 flex-col items-center">
          <Button className="bg-new-accent text-white rounded border" onClick={updateInfluencerProfile}>
            Submit
          </Button>
        </div>
      </div>
    </DashboardLayout>
  )
}
