import { User } from "domain/model/User"
import { AuthRepository } from "domain/repository/Admin/AuthRepository"

export interface CheckUserAuthUseCase {
  invoke(user: User): Promise<any>
}

export class CheckUserAuth implements CheckUserAuthUseCase {
  private authRepository: AuthRepository

  constructor(authRepository: AuthRepository) {
    this.authRepository = authRepository
  }

  async invoke(user: User) {
    return this.authRepository.checkUserAuth(user)
  }
}
