import { Auth } from "domain/model/Auth"
import { PlacementDashboardRepository } from "domain/repository/Admin/PlacementDashboardRepository"

export interface GetEmailsTempleteUsecase {
  invoke(auth: Auth): Promise<any>
}

export class GetEmailsTemplete implements GetEmailsTempleteUsecase {
  private repository: PlacementDashboardRepository

  constructor(repository: PlacementDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return await this.repository.getEmailsTemplete(auth)
  }
}
