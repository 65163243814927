import { Auth } from "domain/model/Auth"
import { Server } from "core/utils/axios"
import StudentProgressDataSource from "data/dataSource/Student/StudentProgressDataSource"
import { STUDENT } from "core/constants/strings"
import pn from "core/utils/pn"
import StudentExpierenceDataSource from "data/dataSource/Student/StudentExpierenceDataSource"
import { UpdateStudentExpierenceDetails } from "domain/model/UpdateStudentExpierence"

const server = new Server()
export default class StudentExpirenceAPIDataSourceImpl implements StudentExpierenceDataSource {
  async updateStudentExpierence(auth: Auth, details: UpdateStudentExpierenceDetails): Promise<any> {
    const response = await server.post(
      pn(STUDENT, "update_student_expierence"),
      { details: details },
      {
        Authorization: `Bearer ${auth?.id_token}`,
      }
    )
    return response
  }
  async getStudentExpierence(auth: Auth): Promise<any> {
    const response = await server.get(pn(STUDENT, "get_student_expierence"), {
      Authorization: `Bearer ${auth?.id_token}`,
    })
    return response
  }
}
