import { Auth } from "domain/model/Auth"

import { InstructorAvailabilityRepository } from "domain/repository/Admin/InstructorAvailabilityRespository"

export interface ToggleInstructorAvailabilityUseCase {
  invoke(auth: Auth, availability: boolean): Promise<any>
}

export class ToggleInstructorAvailability implements ToggleInstructorAvailabilityUseCase {
  private repository: InstructorAvailabilityRepository

  constructor(repository: InstructorAvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, availability: boolean) {
    return await this.repository.toggleInstructorAvailability(auth, availability)
  }
}
