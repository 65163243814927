import StudentExpierenceDataSource from "data/dataSource/Student/StudentExpierenceDataSource"
import StudentProgressDataSource from "data/dataSource/Student/StudentProgressDataSource"
import { Auth } from "domain/model/Auth"
import { UpdateStudentExpierenceDetails } from "domain/model/UpdateStudentExpierence"
import StudentExpirenceRepository from "domain/repository/Student/StudentExpierenceRepository"

export class StudentExpirenceRepositoryImpl implements StudentExpirenceRepository {
  private dataSource: StudentExpierenceDataSource
  constructor(dataSource: StudentExpierenceDataSource) {
    this.dataSource = dataSource
  }
  async updateStudentExpirence(auth: Auth, details: UpdateStudentExpierenceDetails): Promise<any> {
    return this.dataSource.updateStudentExpierence(auth, details)
  }
  async getStudentExpirence(auth: Auth): Promise<any> {
    return this.dataSource.getStudentExpierence(auth)
  }
}
