import { Auth } from "domain/model/Auth"
import { updateMentorProfile } from "domain/model/MentorDashboard"
import { MentorDashboardRepository } from "domain/repository/Admin/MentorDashboardRepository"

export interface DeleteMenteeUseCase {
  invoke(auth: Auth, email: string, mentor_email: string): Promise<any>
}

export class DeleteMentee implements DeleteMenteeUseCase {
  private repository: MentorDashboardRepository

  constructor(repository: MentorDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string, mentor_email: any): Promise<any> {
    return await this.repository.deleteMentee(auth, email, mentor_email)
  }
}
