import InfluencerDashboardDataSource from "data/dataSource/Admin/InfluencerDashboardDataSource"
import { Auth } from "domain/model/Auth"
import {
  InfluencersDetails,
  UpdateCampaignEntry,
  CampaignType,
  InfluencerGeneratedInvoice,
} from "domain/model/InfluencerDashboard"
import { InfluencerDashboardRepository } from "domain/repository/Admin/InfluencerDashboardRepository"

export class InfluencerDashboardRepositoryImpl implements InfluencerDashboardRepository {
  private dataSource: InfluencerDashboardDataSource

  constructor(dataSource: InfluencerDashboardDataSource) {
    this.dataSource = dataSource
  }
  generateInfluencerInvoice(auth: Auth, details: InfluencerGeneratedInvoice): Promise<any> {
    return this.dataSource.getInfluencersGeneratedInvoice(auth, details)
  }
  getAllInfluencersDetails(auth: Auth, details: InfluencersDetails): Promise<any> {
    return this.dataSource.getAllInfluencersDetails(auth, details)
  }

  updateCampaignEntry(id: UpdateCampaignEntry, auth: Auth): Promise<any> {
    return this.dataSource.updateCampaignEntry(id, auth)
  }
  uploadInfluencerImage(auth: Auth, data: FormData): Promise<any> {
    return this.dataSource.uploadInfluencerImage(auth, data)
  }
  updateCompensationDetails(
    email: string,
    campaignType: string,
    compensation: number,
    date: string,
    auth: Auth
  ): Promise<any> {
    return this.dataSource.updateCompensationDetails(email, campaignType, compensation, date, auth)
  }

  getAllCampaignsDetails(auth: Auth, details: CampaignType): Promise<any> {
    return this.dataSource.getAllCampaignsDetails(auth, details)
  }
}
