import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Student/ContestRepository"

export interface checkBatchHasExamTemplateUseCase {
  invoke(auth: Auth, batch_id: string): Promise<any>
}

export default class checkBatchHasExamTemplate implements checkBatchHasExamTemplateUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, batch_id: string): Promise<any> {
    return this.repository.checkBatchHasExamTemplate(auth, batch_id)
  }
}
