import DottedCircleLoder from "assets/svgs/DottedCircleLoder"
import { CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import { useEffect } from "react"

const MeetingWaitingModal = ({
  isOpen,
  onClose,
  waitingTime,
  activeSession,
  activeSessionIndex,
  mentorDetails,
  setRefreshMentorData,
  refreshMentorData,
  cancel,
  updateSessionStatusHandler,
  mentorJoinDuration,
  duration,
}: any) => {
  if (!isOpen) return null
  return (
    <div className="relative z-40 ">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0 "></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 flex min-h-screen items-center justify-center">
        <div className="bg-white w-[450px] h-[270px] p-[24px] rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]">
          <div className="flex justify-between">
            <p className="text-[#333] text-[16px]">Meeting Lobby</p>
            <button
              className={cn("hover:bg-slate-200 rounded-[4px]", waitingTime <= 0 || cancel ? "block" : "hidden")}
              onClick={async () => {
                if (duration > 0) {
                  updateSessionStatusHandler(activeSession.id)
                }
                setRefreshMentorData(!refreshMentorData)
                onClose()
              }}
            >
              <CrossIcon className="size-[24px]" />
            </button>
          </div>

          <p className="text-[#646464] text-[14px] font-[500]">Mentor Session {activeSessionIndex + 1}</p>

          <div className="flex my-[16px] gap-[8px] items-center">
            <DottedCircleLoder />
            <div className="rounded-[4px] bg-[#F5F5F5] px-[6px] py-[4px]">
              <span className="text-[#DC2626] text-[14px] font-[600]">
                {waitingTime > 0 ? (
                  <>
                    {Math.floor(waitingTime / 60)}:{Math.floor(waitingTime % 60)} Min
                  </>
                ) : (
                  <>00:00 Min</>
                )}
              </span>
            </div>
          </div>
          <div className="py-[12px] px-[16px] rounded-[8px] bg-[#F9F9F9] text-[#646464]">
            {waitingTime > 0 ? (
              <>
                <p>
                  <span className=" font-[600]">Please wait,</span> as your mentor maybe in another meeting. It may take
                  up to 15 mins.
                </p>
                <p className="my-[8px]">You will be redirected to meeting portal once your mentor joins the meeting.</p>
              </>
            ) : (
              <div>
                <p className="font-[600]">Mentor Details</p>
                <p>
                  {mentorDetails?.name} -{" "}
                  <a href={`mailto:${mentorDetails?.email}`}>
                    <span className="font-[600] underline">{mentorDetails?.email}</span>
                  </a>
                </p>
                <p>{mentorDetails?.phone}</p>
                <p className="font-[600]">
                  WhatsApp Group link : <span className="underline">Click Here</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetingWaitingModal
