import { QuestionBankRepository } from "domain/repository/Student/QuestionBankRepository"
import { Auth } from "domain/model/Auth"

export interface SaveQuestionUseCase {
  invoke(
    auth: Auth,
    problem_name: string,
    problem_id: string,
    problem_type: string,
    problem_link: string,
    status: boolean,
    topics: string[]
  ): Promise<any>
}

export class SaveQuestion implements SaveQuestionUseCase {
  private repository: QuestionBankRepository

  constructor(repository: QuestionBankRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    problem_name: string,
    problem_id: string,
    problem_type: string,
    problem_link: string,
    status: boolean,
    topics: string[]
  ): Promise<any> {
    return this.repository.saveQuestion(auth, problem_name, problem_id, problem_type, problem_link, status, topics)
  }
}
