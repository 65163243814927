import { RatesDetails } from "domain/model/InfluencerDashboard"
import { CommonInfluencerRepository } from "domain/repository/Common/CommonInfluencerRepository"
import { Auth } from "domain/model/Auth"

export interface GetAllcompensationUseCase {
  invoke(auth: Auth, details: RatesDetails): Promise<any>
}

export class GetAllCompensation implements GetAllcompensationUseCase {
  private repository: CommonInfluencerRepository
  constructor(repository: CommonInfluencerRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: RatesDetails): Promise<any> {
    return await this.repository.getAllCompensationDetails(auth, details)
  }
}
