import React, { useState } from "react"
import "react-quill/dist/quill.snow.css"

interface EmailCardProps {
  emailSubject: string
  emailBody: string
  onCancel: () => void
}

const PreviewEmailCard: React.FC<EmailCardProps> = ({ emailSubject, emailBody, onCancel }) => {
  const [isMobileView, setIsMobileView] = useState(false)

  return (
    <div>
      <div
        className={`overflow-y-scroll ${isMobileView ? "max-w-[500px]" : ""}`}
        style={{
          transform: isMobileView ? "scale(1)" : "scale(1)",
          transformOrigin: "top center",
        }}
      >
        <div className="flex justify-between mb-4">
          <h3 className="text-xl font-semibold">Email Preview</h3>
          <div className="space-x-2">
            <button
              onClick={() => setIsMobileView(false)}
              className={`px-4 py-2 rounded ${
                !isMobileView ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
              } hover:bg-blue-600`}
            >
              Desktop View
            </button>
            <button
              onClick={() => setIsMobileView(true)}
              className={`px-4 py-2 rounded ${
                isMobileView ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
              } hover:bg-blue-600`}
            >
              Mobile View
            </button>
          </div>
        </div>
        <div className={`border p-4 mb-4 ${isMobileView ? "" : "overflow-x-auto"}`}>
          <h4 className="font-bold">Subject:</h4>
          <p>{emailSubject}</p>

          <h4 className="font-bold mt-4">Email Body:</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: `${emailBody}`,
            }}
            className={`${isMobileView ? "scale(1)" : ""}`}
          />
        </div>
        <div className="flex justify-end">
          <button onClick={() => onCancel()} className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400">
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default PreviewEmailCard
