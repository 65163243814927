import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface CreateExamTemplateUseCase {
  invoke(
    auth: Auth,
    template_name: string,
    phase_name: string,
    exam_name: string,
    mock_name: string,
    exam_type: string
  ): Promise<any>
}

export class CreateExamTemplate implements CreateExamTemplateUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(
    auth: Auth,
    template_name: string,
    phase_name: string,
    exam_name: string,
    mock_name: string,
    exam_type: string
  ): Promise<any> {
    return await this.repository.createExamTemplate(auth, template_name, phase_name, exam_name, mock_name, exam_type)
  }
}
