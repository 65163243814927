import { ChangeEvent, FormEvent, useRef, useState } from "react"
import { useAuth } from "core/context/auth"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { GetTop100Students } from "domain/useCase/Admin/StudentDashboard/GetTop100Students"
import { loadNext100Students } from "domain/useCase/Admin/StudentDashboard/LoadNextData"
import { UpdatePaidToUnpaid } from "domain/useCase/Admin/StudentDashboard/UpdatePaidToUnpaid"
import { sendEmailToStudents } from "domain/useCase/Admin/StudentDashboard/SendEmail"
import { UpdateState } from "domain/useCase/Admin/StudentDashboard/UpdateDisableState"
import { GetStudentByEmail } from "domain/useCase/Admin/StudentDashboard/GetStudentByEmail"
import { StudentDashboardRepositoryImpl as AdminStudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { StudentDashboardAPIDataSourceImpl as AdminStudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import useToast from "core/hooks/useToast"
import { NOT_PAID, STR_FAILURE, STR_PAID, STR_SUCCESS } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import { GetStudentsByName } from "domain/useCase/Admin/StudentDashboard/GetStudentsByName"
import { GetStudentsByBatch } from "domain/useCase/Admin/StudentDashboard/GetStudentsByBatch"
import { TDisableStudentReason, TPermanentDisableStudentReason } from "core/constants/types"
import DisableStudentReasonUpdate from "domain/useCase/Admin/StudentDashboard/DisableStudentReasonUpdate"
import { genError } from "core/utils/string"
import { DeleteStudent } from "domain/useCase/Admin/StudentDashboard/DeleteStudent"
import { set } from "date-fns"
import { TogglePermanentDisableStudent } from "domain/useCase/Admin/StudentDashboard/TogglePermanentDisableStudent"
import PermanentDisableStudentReasonUpdate from "domain/useCase/Admin/StudentDashboard/PermanentDisableStudentReasonUpdate"

export default function StudentDashboardViewModel() {
  const tableHeaders = [
    "General Info",
    "Batch",
    "Leetcode Details",
    "Unpaid To Paid",
    "Disable User",
    "Permanently Disable User",
    "Confirmation Mail",
    "Edit Details",
  ]

  const tableHeaderSuperAdmin = [
    "General Info",
    "Batch",
    "Leetcode Details",
    "Unpaid To Paid",
    "Disable User",
    "Permanently Disable User",
    "Confirmation Mail",
    "Edit Details",
    "Delete User",
  ]
  const [students, setStudents] = useState<any>([])
  const [mailSendLoading, setMailSendLoading] = useState<number>(-1)
  const [nextStudentsLoading, setNextStudentsLoading] = useState(false)
  const [lastStudentId, setLastStudentId] = useState<string>("")
  const [searchByEmail, setSearchByEmail] = useState<string>("")
  const [searchByName, setSearchByName] = useState<string>("")
  const [searchByBatch, setSearchByBatch] = useState<string>("")
  const [disableStudentEmail, setDisableStudentEmail] = useState<string>("")
  const [permanentDisableStudentEmail, setPermanentDisableStudentEmail] = useState<string>("")

  const [disableReason, setDisableReason] = useState<TDisableStudentReason>({
    user: "",
    reason: "",
  } as TDisableStudentReason)
  const [permanentDisableReason, setPermanentDisableReason] = useState<TDisableStudentReason>({
    user: "",
    reason: "",
  } as TPermanentDisableStudentReason)
  const [updateReasonLoading, setUpdateReasonLoading] = useState<boolean>(false)
  const [viewDisableStudentReason, setViewDisableStudentReason] = useState<boolean>(false)
  const [viewPermanentDisableStudentReason, setViewPermanentDisableStudentReason] = useState<boolean>(false)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [deleteModel, setDeleteModel] = useState<boolean>(false)
  const [deleteEmail, setDeleteEmail] = useState<string>("")
  // const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const getTop100StudentsUseCase = new GetTop100Students(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const updateDisableStateUseCase = new UpdateState(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const updatePaidToUnpaidUseCase = new UpdatePaidToUnpaid(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const loadNextDataUseCase = new loadNext100Students(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const sendMailUseCase = new sendEmailToStudents(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const getStudentByEmailUseCase = new GetStudentByEmail(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )

  const getStudentsByNameUseCase = new GetStudentsByName(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )

  const getStudentsByBatchUseCase = new GetStudentsByBatch(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )

  const disableStudentReasonUpdateUseCase = new DisableStudentReasonUpdate(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )

  const permanentDisableReasonUpdateUseCase = new PermanentDisableStudentReasonUpdate(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )

  const deleteStudentUseCase = new DeleteStudent(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )

  const togglePermanentDisableStudentUseCase = new TogglePermanentDisableStudent(
    new AdminStudentDashboardRepositoryImpl(new AdminStudentDashboardAPIDataSourceImpl())
  )
  const fetchStudentsByName = async (name: string) => {
    const response = await getStudentsByNameUseCase.invoke({ name })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }
    if (!isEmpty(response?.data)) setStudents(response?.data)
    else setStudents([])
  }

  const fetchStudentsByBatch = async (batch: string) => {
    const response = await getStudentsByBatchUseCase.invoke({ batch })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    if (!isEmpty(response?.data)) setStudents(response?.data)
    else setStudents([])
  }

  const fetchStudentByEmail = async (email: string) => {
    const response = await getStudentByEmailUseCase.invoke({ email })
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    }

    if (!isEmpty(response?.data)) setStudents([response?.data])
    else setStudents([])
  }

  async function getTop100Students() {
    const response = await getTop100StudentsUseCase.invoke(auth.local_id)

    if (response) {
      setStudents(response.response.students)
      setLastStudentId(response.response.students[response.response.students.length - 1].email)
    }
  }

  async function convertPaidToUnpaid(id: string, status: boolean) {
    const response = await updatePaidToUnpaidUseCase.invoke({
      id_token: auth.id_token,
      email: id,
      status,
    })

    return response
  }

  async function updateDisableState(id: any, status: any) {
    const response = await updateDisableStateUseCase.invoke(auth, {
      admin_uid: auth.local_id,
      email: id,
      status: status,
    })

    return response
  }

  async function sendConfirmationMail(id: any) {
    const response = await sendMailUseCase.invoke({
      email: id,
      admin_uid: auth.local_id,
    })

    return response
  }

  async function loadNext100StudentsData() {
    const response = await loadNextDataUseCase.invoke({
      email: lastStudentId,
      admin_uid: auth.local_id,
    })
    return response.response.students
  }

  function paidToUnpaid(id: any) {
    const updatedStudents = students.map((student: any) => {
      if (student.email === id) {
        if (student.payment_status === STR_PAID) {
          return { ...student, payment_status: NOT_PAID }
        } else {
          return { ...student, payment_status: STR_PAID }
        }
      }
      return student
    })

    setStudents(updatedStudents)

    const toggleState = updatedStudents.find((student: any) => student.email === id).payment_status === STR_PAID

    convertPaidToUnpaid(id, toggleState)
  }

  async function deleteStudent(email: string) {
    const response = await deleteStudentUseCase.invoke({
      id_token: auth.id_token,
      email: email,
    })
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, response?.error)
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Student Deleted Successfully")
      changeToastVisibility(true)
    }
  }

  async function toggleDisableStudent(email: string) {
    const updatedStudents = students.map((student: any) => {
      if (student.email === email) {
        return { ...student, disabled: !student.disabled }
      }
      return student
    })

    setStudents(updatedStudents)

    const disabledState = updatedStudents.find((student: any) => student.email === email).disabled

    await updateDisableState(email, disabledState)
  }

  async function togglePermanentDisableStudent(email: any) {
    const updatedStudents = students.map((student: any) => {
      if (student.email === email) {
        return { ...student, pemanent_disable: !student.pemanent_disable }
      }
      return student
    })

    setStudents(updatedStudents)

    // const disabledState = updatedStudents.find((student: any) => student.email === email).disabled
    const response = await togglePermanentDisableStudentUseCase.invoke(auth, email)
    return response
  }

  const openDisableStudentModal = (email: any) => {
    const student = students?.find((s: any) => s?.email === email)
    setDisableStudentEmail(email)
    setDisableReason(student?.disabled_reason || { user: "", reason: "" })
  }

  const openPermanentDisableStudentModal = (email: any) => {
    const student = students?.find((s: any) => s?.email === email)
    setPermanentDisableStudentEmail(email)
    setPermanentDisableReason(student?.permanent_disable_student_reason || { user: "", reason: "" })
  }

  const closeDisableStudentModal = () => {
    setDisableStudentEmail("")
    setDisableReason({ user: "", reason: "" })
    setViewDisableStudentReason(false)
  }

  const closePermanentDisableStudentModal = () => {
    setPermanentDisableStudentEmail("")
    setPermanentDisableReason({ user: "", reason: "" })
    setViewPermanentDisableStudentReason(false)
  }

  const handleDisableStudentReasonChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisableReason((prevReason) => ({
      ...prevReason,
      [e.target.name]: e.target.value,
    }))
  }

  const handlePermanentDisableStudentReasonChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPermanentDisableReason((prevReason) => ({
      ...prevReason,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubmitDisableStudentReason = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isEmpty(disableReason?.user) || isEmpty(disableReason?.reason)) {
      changeToastDetails(STR_FAILURE, "Both field values are required")
      changeToastVisibility(true)
      return
    }

    setUpdateReasonLoading(true)

    const student = students?.find((s: any) => s?.email === disableStudentEmail)

    if (!viewDisableStudentReason) {
      await toggleDisableStudent(student?.email)
    }

    setStudents((prev: any) =>
      prev.map((s: any, key: number) => {
        if (s.email !== disableStudentEmail) return s
        return { ...s, disabled_reason: disableReason }
      })
    )

    const response = await disableStudentReasonUpdateUseCase.invoke(auth, {
      email: student?.email,
      user: disableReason?.user,
      reason: disableReason?.reason,
    })

    setUpdateReasonLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Updated disable status with reason")
    changeToastVisibility(true)

    setDisableStudentEmail("")
  }

  const handleSubmitPermanentDisableStudentReason = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isEmpty(permanentDisableReason?.user) || isEmpty(permanentDisableReason?.reason)) {
      changeToastDetails(STR_FAILURE, "Both field values are required")
      changeToastVisibility(true)
      return
    }

    setUpdateReasonLoading(true)

    const student = students?.find((s: any) => s?.email === permanentDisableStudentEmail)

    if (!viewPermanentDisableStudentReason) {
      await togglePermanentDisableStudent(student?.email)
    }

    setStudents((prev: any) =>
      prev.map((s: any, key: number) => {
        if (s.email !== permanentDisableStudentEmail) return s
        return { ...s, permanent_disable_student_reason: permanentDisableReason }
      })
    )

    const response = await permanentDisableReasonUpdateUseCase.invoke(auth, {
      email: student?.email,
      user: permanentDisableReason?.user,
      reason: permanentDisableReason?.reason,
    })

    setUpdateReasonLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Updated disable status with reason")
    changeToastVisibility(true)

    setPermanentDisableStudentEmail("")
  }

  const viewDisableReason = () => {
    setViewDisableStudentReason(true)
  }

  const viewPermanentDisableReason = () => {
    setViewPermanentDisableStudentReason(true)
  }

  async function loadNextData() {
    setNextStudentsLoading(true)

    const data = await loadNext100StudentsData()
    if (data.length > 0) {
      const students_data = data
      const previous_data = students
      const new_data = previous_data.concat(students_data)
      setStudents(new_data)
      setLastStudentId(students_data[students_data.length - 1].email)
      setNextStudentsLoading(false)
    } else {
      setNextStudentsLoading(false)
      alert("No more data to load")
    }
  }

  function handleSearchByEmail(e: any) {
    if (e.target.value) {
      setSearchByEmail(e.target.value)
    } else setSearchByEmail("")
  }

  function handleSearchByName(e: any) {
    if (e.target.value) setSearchByName(e.target.value)
    else setSearchByName("")
  }

  function handleSearchByBatch(e: any) {
    if (e.target.value) setSearchByBatch(e.target.value)
    else setSearchByBatch("")
  }

  function searchingforEmail(searchByEmail: string) {
    return function (x: any) {
      return x.email.toLowerCase().includes(searchByEmail.toLowerCase()) || !searchByEmail
    }
  }

  function searchingForName(searchByName: string) {
    return function (x: any) {
      return x.name.toLowerCase().includes(searchByName.toLowerCase()) || !searchByName
    }
  }

  function searchingForBatch(searchByBatch: string) {
    return function (x: any) {
      return x.batch.toLowerCase().includes(searchByBatch.toLowerCase()) || !searchByBatch
    }
  }

  function deleteconfirmationModel() {
    setDeleteModel(true)
    return true
  }
  return {
    tableHeaders,
    students,
    setStudents,
    mailSendLoading,
    nextStudentsLoading,
    lastStudentId,
    searchByEmail,
    searchByBatch,
    searchByName,
    toast,

    emailInputRef,
    disableStudentEmail,
    disableReason,
    updateReasonLoading,
    viewDisableStudentReason,
    deleteModel,
    deleteEmail,
    permanentDisableStudentEmail,
    permanentDisableReason,
    handlePermanentDisableStudentReasonChange,
    setDeleteEmail,
    setDeleteModel,
    fetchStudentsByName,
    fetchStudentsByBatch,
    fetchStudentByEmail,
    changeToastVisibility,
    setMailSendLoading,
    searchingforEmail,
    searchingForBatch,
    searchingForName,
    paidToUnpaid,
    toggleDisableStudent,
    sendConfirmationMail,
    loadNextData,
    handleSearchByEmail,
    handleSearchByName,
    handleSearchByBatch,
    getTop100Students,
    openDisableStudentModal,
    closeDisableStudentModal,
    handleDisableStudentReasonChange,
    handleSubmitDisableStudentReason,
    viewDisableReason,
    deleteStudent,
    deleteconfirmationModel,
    togglePermanentDisableStudent,
    openPermanentDisableStudentModal,
    closePermanentDisableStudentModal,
    handleSubmitPermanentDisableStudentReason,
    viewPermanentDisableReason,
    tableHeaderSuperAdmin,
  }
}
