import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface updateStudentExamTemplateUseCase {
  invoke(auth: Auth, student_id: string, updates: string[], name: string): Promise<any>
}

export class UpdateStudentExamTemplate implements updateStudentExamTemplateUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, student_id: string, updates: string[], name: string) {
    return await this.repository.updateStudentExamTemplate(auth, student_id, updates, name)
  }
}
