import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import Toast from "core/components/Toast"
import { STR_FAILURE, STR_SUCCESS, STR_TEXT_FIELD } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { PlacementBatchAPIDataSourceImpl } from "data/API/Admin/PlacementBatchAPIDataSourceImpl"
import { PlacementBatchRepositoryImpl } from "data/repository/Admin/PlacementBatchRepositoryImpl"
import { AddPlacementStudent } from "domain/useCase/Admin/Placement/AddStudentToPlacement"
import useCreateBatchViewModel from "presentation/Admin/PlacementCreateBatch/CreateBatchViewModel"
import React, { useState } from "react"

export default function addStudentForm() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [contest, setContest] = useState<any>()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const addStudentUseCase = new AddPlacementStudent(
    new PlacementBatchRepositoryImpl(new PlacementBatchAPIDataSourceImpl())
  )

  const {
    _placementBatchName,
    _bridgeBatchName,
    handlePlacementCourseName,
    handleBridgeCourseName,
    fetchAllContests,
    allContestsList,
  } = useCreateBatchViewModel()

  React.useEffect(() => {
    fetchAllContests()
  }, [])

  async function addStudent(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await addStudentUseCase.invoke({
        id_token: auth.id_token.trim(),
        cohort_batch_name: _placementBatchName.trim(),
        bridge_batch_name: _bridgeBatchName.trim(),
        student_email: email.trim().toLowerCase(),
        contest: contest,
        created_on: Date.now(),
      })

      setLoading(false)
      if (!response?.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, response?.response?.data?.error)
        return
      }

      if (response?.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Added student successfully!")
      }
    } catch (error) {
      console.error("Error in adding Student to placement cohort:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error occurred while adding Student to placement cohort!")
    }
  }

  return (
    <div>
      <div className="">
        <form onSubmit={(e) => addStudent(e)}>
          <div className="flex w-full max-w-[400px] flex-col gap-4">
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Placement course name"
              onChange={handlePlacementCourseName}
              value={_placementBatchName}
              containerClass="w-full"
              required
            />
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Bridge course name"
              onChange={handleBridgeCourseName}
              value={_bridgeBatchName}
              containerClass="w-full"
              required
            />
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Student's Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              containerClass="w-full"
              required
            />
            <div className="">
              <select
                className="w-full rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                value={contest?.id || "Select Contest"}
                onChange={(e) => setContest(JSON.parse(e.target.value))}
              >
                <option value="Select Contest" disabled>
                  Select Contest
                </option>
                {allContestsList.map((contest: { id: string; name: string }) => (
                  <option key={contest.id} value={JSON.stringify(contest)}>
                    {contest.name}
                  </option>
                ))}
              </select>
            </div>
            <Button type="submit" disabled={loading} loading={loading}>
              Add Student to Cohort
            </Button>
          </div>
        </form>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
