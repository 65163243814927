import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import Input from "core/components/Input"
import Button from "core/components/new/Button"
import Loader from "core/components/Loader"
import Toast from "core/components/Toast"
import useCreateExamTemplateViewModel from "./CreateContestFlowViewModel"

export default function CreateExamTemplateView() {
  const {
    templateName,
    setTemplateName,
    phaseName,
    setPhaseName,
    mockName,
    setMockName,
    examType,
    setExamType,
    examName,
    setExamName,
    isLoading,
    handleCreateExamTemplate,
    toast,
    changeToastVisibility,
  } = useCreateExamTemplateViewModel()

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <div className="space-y-1">
          <h2 className="text-new-accent">Create Exam Template</h2>
          <p className="">Fill in the details to create a new exam template.</p>
        </div>
        <div className="flex flex-col gap-4 items-center">
          <div className="flex gap-4">
            <Input
              className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
              readOnly
              value="Template Name"
            />
            <Input value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
          </div>

          <div className="flex gap-4">
            <Input
              className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
              readOnly
              value="Mock Exam Name"
            />
            <Input value={mockName} onChange={(e) => setMockName(e.target.value)} />
          </div>

          <div className="flex gap-4">
            <Input
              className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
              readOnly
              value="Phase Name"
            />
            <Input value={phaseName} onChange={(e) => setPhaseName(e.target.value)} />
          </div>
          <div className="flex gap-4">
            <Input
              className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
              readOnly
              value="Phase Exam Name"
            />
            <Input value={examName} onChange={(e) => setExamName(e.target.value)} />
          </div>
          <div className="flex gap-4">
            <Input
              className="!bg-white rounded-none border-none font-medium !text-[#646464] focus:outline-none focus:ring-0 focus:border-none"
              readOnly
              value="Phase Exam Type"
            />
            <Input value={examType} onChange={(e) => setExamType(e.target.value)} />
          </div>
          <Button className="max-w-[580px]" success outlined onClick={handleCreateExamTemplate}>
            {isLoading ? <Loader height="54 px" /> : "Create Template"}
          </Button>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
