import SearchInput from "core/components/SearchInput"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import InfluencerDashboardTable from "./components/InfluencerDashboardTable"
import useInfluencerViewMode from "./InfluencerDashboardViewModel"
export default function InfluencerDashboardView() {
  const {
    influencerData,
    searchByEmail,
    searchByCampaign,
    searchByProgram,
    getCampaignType,
    getInfluencersData,
    CampaignData,
    handleSearchByEmail,
    handleSearchByProgram,
    handleSearchByCampaign,
    searchingByProgram,
    searchingforEmail,
    searchingByCampaign,
  } = useInfluencerViewMode()

  const tableHeaders = ["", "General Info", "Unique Code", "Current Company", "Experience", "Razorpay Status", "Edit"]

  React.useEffect(() => {
    getCampaignType()
    getInfluencersData()
  }, [])
  return (
    <DashboardLayout>
      <div className="mt-[40px] mb-8 flex flex-row">
        <SearchInput
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
          placeholder="Search By Email"
          value={searchByEmail}
          onChange={handleSearchByEmail}
        />
        <select
          name=""
          id=""
          onChange={handleSearchByProgram}
          value={searchByProgram}
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
        >
          <option value="">Select Program</option>
          <option value="Accelerator">Accelerator</option>
          <option value="Transformer">Transformer</option>
          <option value="Evolve">Evolve</option>
          <option value="Data Science">Data Science</option>
          <option value="Data Analytics">Data Analytics</option>
          <option value="Data Engineering">Data Engineering</option>
        </select>
        <select
          name=""
          id=""
          onChange={handleSearchByCampaign}
          value={searchByCampaign}
          className="ml-[24px] h-[40px] w-[292px] rounded-[8px]"
        >
          <option value="">Select Campaign</option>
          {CampaignData.map((campaign: any, index: any) => (
            <option key={index} value={campaign}>
              {campaign}
            </option>
          ))}
        </select>
      </div>

      <InfluencerDashboardTable
        tableHeaders={tableHeaders}
        influencerData={influencerData}
        searchByEmail={searchByEmail}
        searchByProgram={searchByProgram}
        searchByCampaign={searchByCampaign}
        searchingforEmail={searchingforEmail}
        searchingByCampaign={searchingByCampaign}
        searchingByProgram={searchingByProgram}
      />
    </DashboardLayout>
  )
}
