import { Auth } from "domain/model/Auth"
import { sendStates } from "domain/model/StudentDashboard"
import StudentDashboardRepository from "domain/repository/Admin/StudentDashboardRepository"

export interface updateDisableStateUseCase {
  invoke(auth: Auth, details: sendStates): Promise<any>
}

export class UpdateState implements updateDisableStateUseCase {
  private repository: StudentDashboardRepository

  constructor(repository: StudentDashboardRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, details: sendStates): Promise<any> {
    return await this.repository.updateDisableState(auth, details)
  }
}
