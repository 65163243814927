import React, { useState, useRef } from "react"
import { useAuth } from "core/context/auth"
import { GetCampaignsDetails } from "domain/useCase/Admin/InfluencerDashboard/GetallCampaignType"
import { GetInfluencersDetails } from "domain/useCase/Admin/InfluencerDashboard/GetAllInfuencerDetails"
import { InfluencerDashboardRepositoryImpl } from "data/repository/Admin/InfluencerDashboardRepositoryImpl"
import { InfluencerAPIDataSourceImpl } from "data/API/Common/InfluencerCommonAPIDataSourceImpl"
import { CommonInfluencerRepositoryImpl } from "data/repository/Common/CommonInfluencerRepositoryImpl"
import { GetInvoiceGeneratedDetails } from "domain/useCase/Common/InfluencerCommon/GetAllInfuencerGeneratedInvoice"
import { GetInvoiceGenerated } from "domain/useCase/Admin/InfluencerDashboard/GetInvoiceGenerated"
import { InfluencerDashboardAPIDataSourceImpl } from "data/API/Admin/InfluncerAPIDataSourceImpl"
import { PaymentInvoice } from "domain/model/PaymentInvoice"
import useToast from "core/hooks/useToast"
import { GetAllCompensation } from "domain/useCase/Common/InfluencerCommon/GetAllCompensation"
import { STR_FAILURE, STR_INFLUENCER_INVOICE_GENERATED, STR_SUCCESS } from "core/constants/strings"
import influencerInvoice from "core/utils/influencerInvoice"
import { copyToClipboard } from "core/utils/copyToClipboard"

export default function PaymentInvoiceViewModel() {
  const [inputValues, setInputValues] = useState<{ [influencerId: string]: { [campaignType: string]: number } }>({})
  const [savedValues, setSavedValues] = useState<{ [influencerId: string]: { [campaignType: string]: number } }>({})
  const [selectedYear, setSelectedYear] = useState("")
  const [selectedMonth, setSelectedMonth] = useState("")
  const [showPopup, setShowPopup] = useState(false)
  const [paymentInvoices, setPaymentInvoices] = useState<PaymentInvoice[]>([])
  const [editable, setEditable] = useState(false)
  const [updateData, setUpdateData] = useState<PaymentInvoice>({} as PaymentInvoice)
  const [searchByEmail, setSearchByEmail] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [invoicesPerPage, setInvoicesPerPage] = useState<number>(20)
  const [totalInvoices, setTotalInvoices] = useState<number>(0)
  const [previousApi, setPreviousApi] = useState("")
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { auth } = useAuth()
  const emailInputRef = React.useRef<HTMLInputElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [filtering, setFiltering] = useState(false)
  const [selectedInfluencer, setSelectedInfluencer] = useState<string>("")
  const [month, setMonth] = useState<string>("")
  const [year, setYear] = useState<string>("")
  const [Rates, setAllRates] = React.useState<any>([])
  const [inputValuesmodal, setInputValuesmodal] = useState<{ [campaign: string]: number }>({})
  const [formattedCampaigns, setFormattedCampaigns] = useState<any[]>([])
  const [campaignDataArray, setCampaignDataArray] = useState<any[]>([])
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [influencerData, setInfluencerData] = React.useState<any>([])
  const [invoiceData, setGeneratedInvoiceData] = React.useState<any>([])
  const [CampaignData, setCampaignData] = React.useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null)
  const [openPopUp2, setOpenPopUp2] = useState(false)
  const [calculatedTotal, setCalculatedTotal] = useState(0)
  const [openPopUp1, setOpenPopUp1] = React.useState<any>(false)
  const [filteredInvoices, setFilteredInvoices] = useState(invoiceData)
  const tableHead = ["Sr. No.", "Influencer Name", "Date(MM/YYYY)", "Total Amount", "View"]
  const getMonth: any = {
    "01": "January",
    "02": "Febuary",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  }
  const GetAllInfluencersDetailsUseCase = new GetInfluencersDetails(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const GetAllCampaignTypeDetailsUseCase = new GetCampaignsDetails(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const GetAllGeneratedInvoiceDataUseCase = new GetInvoiceGeneratedDetails(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )
  const GenerateInvoiceDataUseCase = new GetInvoiceGenerated(
    new InfluencerDashboardRepositoryImpl(new InfluencerDashboardAPIDataSourceImpl())
  )
  const GetAllRatesUseCase = new GetAllCompensation(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )

  async function getAllRates(id: any) {
    const Rates = await GetAllRatesUseCase.invoke(auth, {
      email: id,
    })
    if (Rates) {
      setAllRates(Rates)
    }
  }
  async function getInfluencerGeneratedInvoice() {
    const response = await GetAllGeneratedInvoiceDataUseCase.invoke(auth)
    if (response) {
      setGeneratedInvoiceData(response.data)
    }
  }
  async function getInfluencersData() {
    const response = await GetAllInfluencersDetailsUseCase.invoke(auth, {
      admin_uid: auth.local_id,
    })
    if (response) {
      setInfluencerData(response.data)
    }
  }
  async function getCampaignType() {
    const campaign = await GetAllCampaignTypeDetailsUseCase.invoke(auth, {
      Campaign: auth.local_id,
    })
    if (campaign) {
      setCampaignData(campaign.data)
    }
  }
  async function GenerateInvoice() {
    try {
      if (!month || !year) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Please fill out all fields before updating.")
        return
      }

      const campaigns: Record<string, Record<string, number>> = {}

      Object.entries(inputValuesmodal).forEach(([key, value]) => {
        const campaignId = generateCID(key).trim()
        const match = campaignId.match(/^([^\d\n-]+)[-_]?(\d+|NO-RATE)?$/)
        if (!match) {
          console.error("Invalid campaign ID format:", campaignId)
          return
        }

        const campaignName = match[1].trim()
        const rate = match[2] ? match[2].trim() : "default"

        if (!campaigns[campaignName]) {
          campaigns[campaignName] = {}
        }

        campaigns[campaignName][rate] = (campaigns[campaignName][rate] || 0) + value
      })

      const GenerateInvoiceResponse = await GenerateInvoiceDataUseCase.invoke(auth, {
        email: selectedInfluencer,
        campaigns,
        date: convertToMMYYYY(month, year) as any,
      })

      if (GenerateInvoiceResponse.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, STR_INFLUENCER_INVOICE_GENERATED)
      } else {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Error Generating Invoice:")
      }
    } catch (error) {
      console.error("Error Generating Invoice:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error Generating Invoice.")
    }
  }

  const generateInvoice = (email: string, posts: number, amount: number, date: string) => {
    const data = influencerData.filter((doc: any) => doc.email === email)[0]
    const props: any = {}
    props.email = email
    props.name = data.name
    props.phone = data.phone
    props.address = data.address
    props.month = date.slice(0, 2)
    props.year = date.slice(3)
    props.posts = posts
    props.amount = amount
    props.ifsc = data.IFSC
    props.benificiary_name = data.beneficiary_name
    props.account = data.account_number
    influencerInvoice(props)
  }
  const handleMonthChange = (e: any) => {
    setSelectedMonth(e.target.value)
  }
  const handleYearChange = (e: any) => {
    setSelectedYear(e.target.value)
  }
  const convertToMMYYYY = (month: string, year: string) => {
    const monthNum = parseInt(month, 10)
    const yearNum = parseInt(year, 10)
    if (isNaN(monthNum) || isNaN(yearNum)) {
      return
    }
    if (monthNum < 1 || monthNum > 12) {
      return
    }
    if (yearNum.toString().length !== 4) {
      return
    }
    const formattedMonth = monthNum.toString().padStart(2, "0")
    const result = `${formattedMonth}-${yearNum}`
    return result
  }
  function submit() {
    GenerateInvoice()
  }

  const handleSearchByEmail = (e: any) => {
    if (e.target.value) {
      setSearchByEmail(e.target.value)
      setCurrentPage(1)
      setInvoicesPerPage(20)
      setFiltering(true)
    } else setSearchByEmail("")
  }
  const handleClearFilter = () => {
    setInputValues({})
    setSavedValues({})
    setSelectedMonth("")
    setSelectedYear("")
  }
  const generateCID = (name: string): string => {
    name = name.toUpperCase()
    const idArray = name.split(" ")
    const id = idArray.join("_")
    return id
  }
  const handleCopyToClipboard = (text: string) => {
    copyToClipboard(text)
    changeToastDetails(STR_SUCCESS, "Copied to clipboard")
    changeToastVisibility(true)
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdateData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const calculateBreakdown = (invoice: any) => {
    let total = 0

    if (!invoice || !invoice.campaign) {
      console.error("Invalid invoice data:", invoice)
      return
    }

    for (const campaignType in invoice.campaign) {
      const postsData = invoice.campaign[campaignType]

      if (!postsData || typeof postsData !== "object") continue

      for (const pricePerPost in postsData) {
        const numPosts = postsData[pricePerPost]
        const cost = parseFloat(pricePerPost)

        if (!isNaN(cost)) {
          total += numPosts * cost
        }
      }
    }

    setCalculatedTotal(total)
    setOpenPopUp2(true)
  }

  const handleInputChangemodal = (campaign: string, newValue: number) => {
    setInputValuesmodal((prev) => ({
      ...prev,
      [campaign]: newValue,
    }))
  }
  const handleClear = () => {
    setSelectedInfluencer("")
    setMonth("")
    setYear("")
    setInputValuesmodal({})
  }
  const selectedInfluencerData = influencerData.find((influencer: any) => influencer.email === selectedInfluencer)
  const campaigns = selectedInfluencerData?.selected_campaign || []

  function handlePopUp1() {
    setOpenPopUp1(!openPopUp1)
  }
  function handlPopUp2() {
    setOpenPopUp2(!openPopUp2)
  }

  return {
    filteredInvoices,
    setFilteredInvoices,
    openPopUp1,
    setOpenPopUp1,
    handlePopUp1,
    handlPopUp2,
    handleClear,
    campaigns,
    selectedInfluencerData,
    handleInputChangemodal,
    openPopUp2,
    calculateBreakdown,
    setOpenPopUp2,
    selectedInfluencer,
    setSelectedInfluencer,
    month,
    formattedCampaigns,
    setFormattedCampaigns,
    setMonth,
    Rates,
    getInfluencerGeneratedInvoice,
    year,
    setYear,
    campaignDataArray,
    setInputValuesmodal,
    inputValuesmodal,
    setCampaignDataArray,
    savedValues,
    invoiceData,
    setSavedValues,
    getAllRates,
    influencerData,
    CampaignData,
    inputValues,
    setInputValues,
    getInfluencersData,
    handleSearchByEmail,
    calculatedTotal,
    selectedInvoice,
    setSelectedInvoice,
    editable,
    setEditable,
    submit,
    handleInputChange,
    isLoading,
    setIsLoading,
    showPopup,
    isDownloading,
    setIsDownloading,
    setShowPopup,
    paymentInvoices,
    setPaymentInvoices,
    generateCID,
    tableHead,
    getMonth,
    searchByEmail,
    emailInputRef,
    toast,
    selectedMonth,
    handleMonthChange,
    changeToastVisibility,
    handleCopyToClipboard,
    handleClearFilter,
    currentPage,
    invoicesPerPage,
    totalInvoices,
    previousApi,
    setPreviousApi,
    timeoutRef,
    filtering,
    scrollContainerRef,
    getCampaignType,
    handleYearChange,
    selectedYear,
    generateInvoice,
  }
}
