import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface DeleteExamInModuleUseCase {
  invoke(auth: Auth, template_id: string, module_id: string, exam_id: string): Promise<void>
}

export class DeleteExamInModule implements DeleteExamInModuleUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, template_id: string, module_id: string, exam_id: string) {
    return await this.repository.deleteExamInModule(auth, template_id, module_id, exam_id)
  }
}
