import ConditionalLoader from "core/components/ConditionalLoader"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import Toast from "core/components/Toast"
import { STR_FAILURE, STR_NUMBER_FILED, STR_SUCCESS, STR_TEXT_FIELD } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { isEmpty, removeAtIndex } from "core/utils/misc"
import { PlacementBatchAPIDataSourceImpl } from "data/API/Admin/PlacementBatchAPIDataSourceImpl"
import { PlacementBatchRepositoryImpl } from "data/repository/Admin/PlacementBatchRepositoryImpl"
import { CreatePlacementBatches } from "domain/useCase/Admin/Placement/CreatePlacementBatch"
import useContestViewModel from "presentation/Admin/Contest/ContestViewModel"
import useCreateBatchViewModel from "presentation/Admin/PlacementCreateBatch/CreateBatchViewModel"
import React, { useState } from "react"

export default function addStudentForm() {
  const [loading, setLoading] = useState(false)
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const { handleUpdatePlacementCohortBatch } = useContestViewModel()
  const createBatchUseCase = new CreatePlacementBatches(
    new PlacementBatchRepositoryImpl(new PlacementBatchAPIDataSourceImpl())
  )

  async function createBatches(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setLoading(true)
    const response = await createBatchUseCase.invoke({
      id_token: auth.id_token.trim(),
      cohort_batch_name: _placementBatchName.trim(),
      bridge_batch_name: _bridgeBatchName.trim(),
      contests: _currentSelectContest,
      passing_score: passingScore,
      test_passed_list: [],
      mock_passed_list: [],
      is_attended_list: [],
      created_on: Date.now(),
    })

    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.response?.data?.error)
      return
    }

    if (response?.success) {
      try {
        await Promise.all(
          _currentSelectContest.map((contest: any) =>
            handleUpdatePlacementCohortBatch(
              contest,
              _placementBatchName.trim(),
              _bridgeBatchName.trim(),
              passingScore,
              Date.now()
            )
          )
        )

        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Batch created successfully!")
      } catch (error) {
        console.error("Error updating placement cohort batches:", error)
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Error occurred while updating placement cohort batches!")
      }
    }
  }

  React.useEffect(() => {
    fetchAllContests()
  }, [])

  const {
    _currentSelectContest,
    _placementBatchName,
    _bridgeBatchName,
    _setCurrentSelectContest,
    handlePlacementCourseName,
    handleBridgeCourseName,
    handleContestChange,
    fetchAllContests,
    allContestsList,
    passingScore,
    setPassingScore,
  } = useCreateBatchViewModel()

  return (
    <div>
      <div className="">
        <form onSubmit={(e) => createBatches(e)}>
          <div className="flex w-full max-w-[400px] flex-col gap-4">
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Placement course name"
              onChange={handlePlacementCourseName}
              value={_placementBatchName}
              containerClass="w-full"
              required
            />
            <Input
              type={STR_TEXT_FIELD}
              placeholder="Bridge course name"
              onChange={handleBridgeCourseName}
              value={_bridgeBatchName}
              containerClass="w-full"
              required
            />
            <Input
              type={STR_NUMBER_FILED}
              placeholder="Passing Score"
              onChange={(e) => setPassingScore(Number(e.target.value))}
              value={passingScore}
              containerClass="w-full"
              required
            />

            <ul className="flex w-full flex-wrap gap-4 border py-2 px-4">
              <ConditionalLoader
                isLoading={isEmpty(_currentSelectContest)}
                loader={<em className="text-xs text-new-neutral-light">No Selected Contest</em>}
              >
                {_currentSelectContest.map((contest: any, i: number) => (
                  <li key={i} className="inline-flex items-center gap-2">
                    {contest.name}
                    <button
                      type="button"
                      className="rounded bg-new-solid-black p-1 text-new-solid-white"
                      onClick={() => {
                        _setCurrentSelectContest(removeAtIndex(_currentSelectContest, i))
                      }}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </button>
                  </li>
                ))}
              </ConditionalLoader>
            </ul>
            <StandaredSelect
              required
              options={allContestsList.map((contest: { id: string; name: string }) => contest.name)}
              placeholder="Select Contests"
              onChange={handleContestChange}
              value={
                _currentSelectContest.length > 0 ? _currentSelectContest[_currentSelectContest.length - 1].name : ""
              }
            />

            <Button type="submit" disabled={loading} loading={loading}>
              Create Placement Cohort
            </Button>
          </div>
        </form>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
