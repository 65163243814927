import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { CustomScroll } from "react-custom-scroll"
import { ReactComponent as SaveIcon } from "assets/svgs/save.svg"
import { ReactComponent as UnsaveIcon } from "assets/svgs/unsaved_icon.svg"
import { ReactComponent as StarIcon } from "assets/svgs/star.svg"
import useMicroResourceViewModel from "./MircroResourceViewModel"
import { ReactComponent as FrameIcon } from "assets/svgs/Frame.svg"
import { ReactComponent as GreenCheck } from "assets/svgs/green_check.svg"
import { ReactComponent as GreyCheck } from "assets/svgs/grey_check.svg"
import { ReactComponent as GoBack } from "assets/svgs/go_back.svg"
import { ChatbotAPIDataSourceImpl } from "data/API/Student/ChatbotAPIDataSourceImpl"
import { ChatbotRepositoryImpl } from "data/repository/Student/ChatbotRepositoryImpl"
import { Chatbot } from "domain/useCase/Student/Chatbot/Chatbot"
import { ChatModal } from "./components/ChatModal"
import { useNavigate } from "react-router-dom"

function MicroResourceShortsView() {
  const {
    isChatbotOpen,
    closeChatbot,
    fetchChatbotResponse,
    chatShort,
    chatbotResponse,
    handleChatClick,
    handleSaveClick,
    handleReadShort,
  } = useMicroResourceViewModel()

  const location = useLocation()
  const navigate = useNavigate()
  const { all_lectures = [], course_name, chapter_name } = location.state || {}

  const [lectures, setLectures] = React.useState<any[]>([])
  const [sessionID, setSessionID] = React.useState<string>("")
  const [firstChapter, setFirstChapter] = React.useState<string>("")
  const [chapterNames, setChapterNames] = React.useState<string[]>([])
  const [currentChapter, setCurrentChapter] = React.useState<string>("")
  const [chapterShorts, setChapterShorts] = React.useState<{ title: string; details: string }[]>([])
  const extractChapterNames = (lecturesData: any[]) => {
    const names = lecturesData.map((lecture: any) => lecture.chapter_name || "Unknown Chapter")
    setChapterNames(names)
    return names
  }

  useEffect(() => {
    setLectures(all_lectures)
  }, [all_lectures])

  const manageTimer = (): number => {
    let timeLeft = 60
    let readIndex = 0
    const shorts = chapterShorts

    const timer = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft -= 1
      } else {
        if (readIndex < shorts.length) {
          const currentShort = shorts[readIndex]

          const updatedLectures = lectures.map((lecture: any) => {
            if (lecture.chapter_name === currentChapter) {
              const updatedShorts = lecture.shorts.map((short: any) => {
                if (short.title === currentShort.title) {
                  return {
                    ...short,
                    is_read: true,
                  }
                }
                return short
              })

              return {
                ...lecture,
                shorts: updatedShorts,
              }
            }
            return lecture
          })

          setLectures(updatedLectures)

          if (currentChapter) {
            const updatedCurrentChapter = updatedLectures.find(
              (lecture: any) => lecture.chapter_name === currentChapter
            )
            setChapterShorts(updatedCurrentChapter?.shorts || [])
          }

          handleReadShort(sessionID, currentChapter, currentShort.title)

          readIndex += 1

          timeLeft = 60
        } else {
          clearInterval(timer)
        }
      }
    }, 1000)

    return timer as unknown as number
  }

  useEffect(() => {
    const timer = manageTimer()

    return () => {
      clearInterval(timer)
    }
  }, [currentChapter])

  useEffect(() => {
    const names = extractChapterNames(all_lectures)
    setCurrentChapter(chapter_name)

    const firstLecture = all_lectures.find((lecture: any) => lecture.chapter_name === chapter_name)

    setChapterShorts(firstLecture?.shorts || [])
    setSessionID(firstLecture?.session_id || "")
    setFirstChapter(chapter_name)
  }, [chapter_name, all_lectures])

  useEffect(() => {}, [chapterShorts])

  const handleChapterSelection = (chapter: string) => {
    const selectedLecture = lectures.find((lecture: any) => lecture.chapter_name === chapter)
    if (selectedLecture) {
      setSessionID(selectedLecture.session_id)
      setChapterShorts(selectedLecture.shorts)
    } else {
      setChapterShorts([])
    }
    setCurrentChapter(chapter)
  }

  const handleSendChat = (input: string) => {
    fetchChatbotResponse(input + "Replace ** ** with <b> </b> in the response")
  }

  const updateShortSaveStatus = (chapterName: string, shortTitle: string, save: boolean) => {
    const updatedLectures = lectures.map((lecture: any) => {
      if (lecture.chapter_name === chapterName) {
        const updatedShorts = lecture.shorts.map((short: any) => {
          if (short.title === shortTitle) {
            return {
              ...short,
              is_saved: save,
            }
          }
          return short
        })

        return {
          ...lecture,
          shorts: updatedShorts,
        }
      }
      return lecture
    })

    setLectures(updatedLectures)

    if (chapterName === currentChapter) {
      const updatedCurrentChapter = updatedLectures.find((lecture: any) => lecture.chapter_name === chapterName)
      setChapterShorts(updatedCurrentChapter?.shorts || [])
    }
  }

  return (
    <DashboardLayoutv2>
      {isChatbotOpen && (
        <ChatModal
          isOpen={isChatbotOpen}
          onClose={closeChatbot}
          response={chatbotResponse}
          onSendMessage={handleSendChat}
          shorts={chatShort}
        />
      )}
      <div className="pl-[24px] pr-[24px] pt-[24px] flex justify-between">
        <div>
          <div className="text-[14px] font-semibold text-[var(--white-and-gray-Gray-600,#333)] font-inter">
            {course_name}
          </div>
          <div className="text-[14px] font-[400]">{chapterNames.length} Chapters</div>
        </div>
        <div>
          <button
            className="flex items-center justify-center gap-[4px] px-[16px] py-[9px] bg-white hover:bg-gray-200 rounded-[4px] text-[14px] font-medium text-gray-700 border-[1px] border-[var(--Black-and-white-Gray-400,#D3D3D3)]"
            onClick={() => navigate(-1)}
          >
            <GoBack className="w-[16px] h-[16px]" />
            Go-Back
          </button>
        </div>
      </div>
      <div className="pl-[24px] mt-[10px] flex w-full">
        <div className="flex-wrap w-full mr-[20px]">
          {chapterShorts.map((short: any, index: number) => (
            <div
              key={index}
              className="w-full h-[173px] flex rounded-[8px] border border-0.5 border-shades-bw-gray-400 bg-white mb-[30px] rounded-tr-[8.3px] rounded-br-[8.3px]"
            >
              <div className="pb-[16px] pl-[16px] pr-[16px] pt-[12px] w-full">
                <div className="flex justify-between items-center relative">
                  <h3 className="text-[14px] font-semibold text-[#333] font-inter mb-[3px]">
                    {short.title || `Short ${index + 1}`}
                  </h3>

                  <div className="flex items-center space-x-[4px]">
                    {short.is_saved ? (
                      <FrameIcon className="w-[16px] h-[16px] text-[#333]" />
                    ) : (
                      <UnsaveIcon className="w-[16px] h-[16px] text-[#333]" />
                    )}
                    {short.is_read ? (
                      <GreenCheck className="w-[16px] h-[16px]" title="Marked as read" />
                    ) : (
                      <GreyCheck className="w-[16px] h-[16px]" title="Unread" />
                    )}
                  </div>
                </div>

                <div className="w-full mt-[9px] pt-[10px] pb-[0px] pl-[10px] bg-[#F9F9F9] rounded-[4px] h-[110px]">
                  <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                    <p className="text-[11px] mr-[11px] text-[#646464] font-[400] font-inter leading-normal">
                      {short.details || "No additional details available."}
                    </p>
                  </CustomScroll>
                </div>
              </div>

              <div className="flex justify-end">
                <div className="h-[171px] w-[4px] bg-[#646464]"></div>
                <div className="w-[31px] h-[171px] bg-black rounded-tr-[7.6px] rounded-br-[7.6px] pt-[6px] pl-[6px] pr-[6px]">
                  <div className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]">
                    <span className="text-white text-[10px] font-bold">{index + 1}</span>
                  </div>
                  <div className="mt-[98px] ">
                    <button
                      className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]"
                      onClick={() => {
                        updateShortSaveStatus(currentChapter, short.title, !short.is_saved)
                        handleSaveClick(sessionID, currentChapter, short.title, !short.is_saved)
                      }}
                    >
                      <SaveIcon className="w-4 h-4 text-white" />
                    </button>
                    <button
                      className="w-auto h-auto bg-[#FFFFFF4D] mt-[4px] flex items-center justify-center p-[2px] rounded-[4px]"
                      onClick={() => handleChatClick(short.details)}
                    >
                      <StarIcon className="w-4 h-4 text-white" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="h-auto w-[282px] bg-gray-100 mr-[24px]">
          <div className="space-y-2 rounded-lg bg-[#e3e3e3] pb-2 pr-1 shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)]">
            <div className="p-[16px] bg-[#e3e3e3] rounded">
              <p className="text-start text-[14px] font-[600] capitalize text-[#333] font-inter leading-normal">
                {course_name}
              </p>
              <p className="text-start text-[14px] font-[600] capitalize text-[#333] font-inter leading-normal">
                {chapterNames.length} Chapters
              </p>
            </div>

            <div className="relative flex h-[calc(100vh-300px)] w-full flex-col items-center gap-y-2 overflow-y-auto px-2 pr-1">
              <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                {chapterNames.map((chapter, index) => (
                  <div
                    key={index}
                    className={`flex h-[134px] min-h-[134px] w-full flex-col gap-2 pt-[12px] pl-[12px] pr-[12px] pb-[16px] mb-4 rounded-lg border-[0.5px] bg-white p-3 
              ${
                currentChapter === chapter
                  ? "shadow-[0px_1px_3px_0px_rgba(34,41,48,0.15)] border-green-500 bg-green-100"
                  : "shadow-[-1px_1px_3px_0px_rgba(132,136,139,0.05)] border-gray-300"
              }
            `}
                    onClick={() => handleChapterSelection(chapter)}
                  >
                    <div className="flex justify-between">
                      <h3
                        className="w-4/5 text-sm font-semibold text-[#333] font-[Inter] text-[14px] leading-normal"
                        title={chapter}
                      >
                        {chapter}
                      </h3>
                      <input
                        type="checkbox"
                        className="border-2 border-[#E3E3E3] h-[16px] w-[16px] checked:bg-v2-success"
                        checked={currentChapter === chapter}
                        readOnly
                      />
                    </div>

                    <div className="mt-auto flex">
                      <button
                        className={`mt-2 px-4 py-1 text-sm font-inter rounded-lg h-auto w-full ${
                          currentChapter === chapter
                            ? "border-[#07B42D] text-[#07B42D] border"
                            : "border-gray-300 text-gray-600 border"
                        }`}
                      >
                        {currentChapter === chapter ? "Selected" : "View Detail"}
                      </button>
                    </div>
                  </div>
                ))}
              </CustomScroll>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayoutv2>
  )
}

export default MicroResourceShortsView
