import { useEffect, useState } from "react"
import CourseCard from "./components/CourseCard"
import useMicroResourceViewModel from "./MircroResourceViewModel"
import { Button } from "core/components/v2/Button"
import ButtonNew from "core/components/new/Button"
import ButtonGroup from "core/components/v2/ButtonGroup"
import CourseLeetCodeModal from "./components/CourseLeetCodeModal"
import Toast from "core/components/Toast"
import colors from "core/constants/colors"
import Loader from "core/components/Loader"
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeader } from "core/components/v2/Table"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty } from "core/utils/misc"
import { capitalize } from "core/utils/string"
import {
  STR_DATA_SCIENTIST,
  STR_MASTER_CLASS,
  STR_PAST_SESSIONS_AND_ASSIGNMENT,
  STR_SOFTWARE_ENGINEERING,
  STR_UPCOMING_LECTURES,
  STR_CURRENTLY_READING,
  STR_SAVED_TOPICS,
  STR_DSML_BEGINNER,
} from "core/constants/strings"
import Modal from "core/components/Modal"
import { toDateTime } from "core/utils/date"
import CourseSessionFeedback from "./components/CourseSessionFeedback"
import ProjectModuleComponent from "./components/ProjectModuleComponent"
import { useLocation } from "react-router-dom"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { Head } from "core/components/seo"
import { ReactComponent as SaveIcon } from "assets/svgs/save.svg"
import { ReactComponent as UnsaveIcon } from "assets/svgs/unsaved_icon.svg"
import { ReactComponent as FrameIcon } from "assets/svgs/Frame.svg"
import { ReactComponent as GreenCheck } from "assets/svgs/green_check.svg"
import { ReactComponent as GreyCheck } from "assets/svgs/grey_check.svg"
// import { ReactComponent as ChatbotIcon } from "assets/svgs/chatbot.svg"
import { CustomScroll } from "react-custom-scroll"
import { useNavigate } from "react-router-dom"
import { ChatModal } from "./components/ChatModal"
import { BotIcon } from "lucide-react"

export default function MicroResourceView() {
  const {
    toast,
    courseList,
    currentCourse,
    activeButtonType,
    tableData,
    isFullCourseContentActive,
    fullCourseTableData,
    hasLeetCodeId,
    courseSelectedIndex,
    loadingTable,
    student,
    isCourseFetched,
    isAssignmentsFetched,
    isLeetcodeChecked,
    isLeetcodeFetched,
    isAttendanceModalOpen,
    attendanceModalData,
    isChatbotOpen,
    closeChatbot,
    fetchChatbotResponse,
    chatShort,
    chatbotResponse,
    handleChatClick,
    moduleShorts,
    filterButtonType,
    setFilterButtonType,
    feedbackOpen,
    attendanceSessionLoading,
    showProjectModule,
    checkLeetCodeProfile,
    fetchMasterClass,
    fetchAttendanceAndAssignments,
    fetchDsmlBeginnerModuleSessions,
    fetchCourse,
    changeCourseSelected,
    changeActiveButtonType,
    changeFilterButtonType,
    showFullCourseContent,
    handleLeetCodeId,
    changeToastVisibility,
    handleMasterClassTable,
    handleCloseAttendanceModal,
    handleAttendanceChange,
    submitFeedback,
    handleModuleShorts,
    setCurrentCourse,
    setIsFullCourseContentActive,
    handleSaveClick,
  } = useMicroResourceViewModel()

  const [trackName, setTrackName] = useState("")
  const [isFrameLoading, setIsFrameLoading] = useState(true)
  const [isFrameLoading1, setIsFrameLoading1] = useState(true)
  const [filteredLectures, setFilteredLectures] = useState<any[]>([])
  const isCourseEmpty = isEmpty(currentCourse?.recorded_lectures) && isEmpty(currentCourse?.upcoming_lectures)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isEmpty(student?.tracks)) {
      setTrackName(capitalize(student?.tracks?.[0]?.split("_")?.join(" ")))
    }
  }, [student])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFrameLoading(false)
    }, 6000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    checkLeetCodeProfile()
  }, [isLeetcodeChecked])

  useEffect(() => {
    if (isLeetcodeFetched) {
      fetchCourse()
    }
  }, [isLeetcodeFetched])

  useEffect(() => {
    if (isCourseFetched) {
      fetchAttendanceAndAssignments()
    }
  }, [isCourseFetched])

  useEffect(() => {
    if (isCourseFetched) {
      setIsFrameLoading1(false)
    }
  }, [isCourseFetched])

  useEffect(() => {
    if (isAssignmentsFetched) {
      fetchMasterClass()
      if (student?.batch?.includes("DSML") && student?.tracks?.includes("dsml_beginner")) {
        fetchDsmlBeginnerModuleSessions()
      }
    }
  }, [isAssignmentsFetched])

  useEffect(() => {
    changeCourseSelected(courseList[courseSelectedIndex], courseSelectedIndex)
  }, [isCourseFetched, isAssignmentsFetched])

  useEffect(() => {
    if (!isEmpty(currentCourse)) {
      if (isMasterClass || isDsmlBeginner) {
        handleMasterClassTable()
      }
      handleModuleShorts()
    }
  }, [loadingTable, activeButtonType, currentCourse, isAssignmentsFetched])

  useEffect(() => {
    if (isFullCourseContentActive) {
      showFullCourseContent()
    }
  }, [isFullCourseContentActive])

  useEffect(() => {
    if (!currentCourse?.all_lectures) return

    const updatedLectures = currentCourse.all_lectures.map((lecture: any) => {
      const filteredShorts = lecture.shorts.filter((short: any) => {
        if (filterButtonType === 1) {
          return short.is_saved
        }
        return true
      })

      return {
        ...lecture,
        shorts: filteredShorts,
      }
    })

    setFilteredLectures(updatedLectures)
  }, [currentCourse, filterButtonType])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get("tab")

  useEffect(() => {
    if (tab === "Upcoming Classes") {
      changeActiveButtonType(0)
    }

    if (tab === "Past Classes") {
      changeActiveButtonType(1)
    }
  }, [tab])

  const isMasterClass = currentCourse?.name === STR_MASTER_CLASS
  const isDsmlBeginner = currentCourse?.name === STR_DSML_BEGINNER
  const isKCE = student?.batch?.includes("KCE")
  const isEvolve = student?.batch?.includes("EV")
  const isAccelerator = student?.batch?.includes("Accelerator")

  const handleSendChat = (input: string) => {
    fetchChatbotResponse(input + "Replace ** ** with <b> </b> in the response")
  }

  return (
    <DashboardLayoutv2>
      <Head title="Curriculum" />
      {isChatbotOpen && (
        <ChatModal
          isOpen={isChatbotOpen}
          onClose={closeChatbot}
          response={chatbotResponse}
          onSendMessage={handleSendChat}
          shorts={chatShort}
        />
      )}

      {hasLeetCodeId === false && <CourseLeetCodeModal loading={!isLeetcodeChecked} onSubmit={handleLeetCodeId} />}
      <ConditionalLoader isLoading={isEmpty(currentCourse)} loader={<Loader />}>
        <div className="space-y-6 p-6">
          <div className="space-y-6">
            <div className="space-y-1">
              {student?.batch != "EV2" ? (
                <h2 className="text-base font-semibold text-[#333]">{trackName}</h2>
              ) : (
                <h2 className="text-base font-semibold text-[#333]">Evolve</h2>
              )}
              <p className="text-sm font-normal text-[#646464]">
                You have access to <b>{courseList?.length} modules</b> in{" "}
                {student?.batch != "EV2" ? <b>{trackName}</b> : <b>Evolve</b>} course.
              </p>
            </div>
            <div className="rounded-lg border-[0.5px] border-[#d3d3d3] p-4">
              <div className="gray-scrollbar flex w-full gap-5 overflow-x-auto pb-6">
                {!isEmpty(courseList) &&
                  courseList?.map((course: any, i: number) =>
                    student?.batch === "KCE"
                      ? course?.id !== "master_class" &&
                        (course?.recorded_lectures?.length !== 0 || course?.upcoming_lectures?.length !== 0) && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors[Object.keys(colors)[i % 6]]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                      : course?.id !== "master_class" &&
                        (course?.recorded_lectures?.length !== 0 || course?.upcoming_lectures?.length !== 0) && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors[Object.keys(colors)[i % 6]]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                  )}

                {!isEmpty(courseList) &&
                  courseList?.map((course: any, i: number) =>
                    student?.batch === "KCE"
                      ? course?.id !== "master_class" &&
                        course?.recorded_lectures?.length === 0 &&
                        course?.upcoming_lectures?.length === 0 && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors["grey"]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                      : course?.id !== "master_class" &&
                        course?.recorded_lectures?.length === 0 &&
                        course?.upcoming_lectures?.length === 0 && (
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors["grey"]}
                            masterClass={course?.name === "Master Class"}
                          />
                        )
                  )}
                {student?.batch?.includes("DSML") && (
                  <CourseCard
                    projectModule
                    index={courseList.length}
                    active={courseSelectedIndex === courseList.length}
                    course={{ name: "Projects", id: "project_module" }}
                    onClick={changeCourseSelected}
                    color={colors["white"]}
                  />
                )}
                {!isEmpty(courseList) &&
                  courseList?.map(
                    (course: any, i: number) =>
                      student?.batch !== "KCE" &&
                      !student?.tracks?.includes("data_engineer") &&
                      course?.id === "master_class" && (
                        <>
                          <CourseCard
                            key={i}
                            index={i}
                            active={courseSelectedIndex === i}
                            course={course}
                            onClick={changeCourseSelected}
                            color={colors["grey"]}
                            masterClass={course?.name === "Master Class"}
                          />
                        </>
                      )
                  )}

                {(isEmpty(courseList) || courseList?.at(-1)?.id !== "master_class") && (
                  <CourseCard course={{ name: "Master Class" }} loading masterClass />
                )}
              </div>
            </div>
          </div>
          {!showProjectModule && !isEmpty(currentCourse) && (
            <div className="flex flex-col gap-4" id="course-session">
              <div className="space-y-1">
                <h2 className="text-base font-semibold text-[#333]">{currentCourse?.name}</h2>
                {isAccelerator ? (
                  student?.is_payment_done == false ? (
                    currentCourse?.name == "Beginner Course" ? (
                      <p>You have access to 4 chapters in {currentCourse?.name} module.</p>
                    ) : (
                      <p>You have access to 0 chapters in {currentCourse?.name} module.</p>
                    )
                  ) : (
                    <p className="text-sm text-[#646464]">
                      You have access to {currentCourse?.all_lectures?.length} chapters in {currentCourse?.name} module.
                    </p>
                  )
                ) : (
                  <p className="text-sm text-[#646464]">
                    You have access to {currentCourse?.all_lectures?.length} chapters in this module.
                  </p>
                )}
              </div>
              <div className="space-y-4">
                <div
                  className={`flex ${isFullCourseContentActive ? "justify-end" : "gray-scrollbar items-start justify-between"}`}
                >
                  {!isFullCourseContentActive && (
                    <div>
                      {!isMasterClass && !isAccelerator && !isDsmlBeginner && (
                        <ButtonGroup
                          className="w-fit"
                          buttons={[STR_CURRENTLY_READING, STR_SAVED_TOPICS]}
                          active={filterButtonType}
                          onChange={changeFilterButtonType}
                        />
                      )}

                      {isMasterClass && !isAccelerator && (
                        <ButtonGroup
                          buttons={[STR_DATA_SCIENTIST, STR_SOFTWARE_ENGINEERING]}
                          active={activeButtonType}
                          onChange={changeActiveButtonType}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {isFrameLoading1 ? (
                <div className="w-[40px] h-[40px] flex items-center ml-[400px] mt-[50px] justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="flex gap-x-8 gap-y-3 flex-wrap mt-[20px]">
                  {filteredLectures
                    ?.flatMap((lecture: any, lectureIndex: number) =>
                      (lecture.shorts || []).map((module: any) => ({
                        module,
                        sessionID: lecture.session_id,
                        chapterName: lecture.chapter_name, // Include chapter_name for each module
                        lectureIndex, // Track lecture index if needed
                      }))
                    )
                    .map(({ module, sessionID, chapterName }: any, moduleIndex: number) => (
                      <div
                        key={moduleIndex}
                        style={{ width: "27.44vw" }}
                        className="h-[218px] flex rounded-[8px] border border-0.5 border-shades-bw-gray-400 bg-white mb-[30px] rounded-tr-[8.3px] rounded-br-[8.3px] hover:border-[#D3D3D3] transition-colors duration-150"
                      >
                        <div
                          className="pb-[16px] pl-[16px] pr-[16px] pt-[12px] w-full cursor-pointer"
                          onClick={() =>
                            navigate("/microresourceshorts", {
                              state: {
                                chapter_name: chapterName,
                                all_lectures: currentCourse.all_lectures,
                                course_name: currentCourse.name,
                              },
                            })
                          }
                        >
                          <div className="flex justify-between items-center">
                            <h3 className="text-[14px] font-semibold text-[#333] font-inter mb-[3px]">
                              {module.title || `Module ${moduleIndex + 1}`}
                            </h3>

                            {isFrameLoading ? (
                              <div className="w-[16px] h-[16px] flex items-center justify-center">
                                <Loader />
                              </div>
                            ) : (
                              <div className="flex items-center space-x-[4px]">
                                {module.is_saved ? (
                                  <FrameIcon className="w-[16px] h-[16px] text-[#333]" />
                                ) : (
                                  <UnsaveIcon className="w-[16px] h-[16px] text-[#333]" />
                                )}
                                {module.is_read ? (
                                  <GreenCheck className="w-[16px] h-[16px]" title="Read" />
                                ) : (
                                  <GreyCheck className="w-[16px] h-[16px]" title="Unread" />
                                )}
                              </div>
                            )}
                          </div>

                          <div className="w-full mt-[9px] pt-[7px] pb-[7px] pl-[10px] bg-[#F9F9F9] rounded-[4px] h-[157px]">
                            <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
                              <p className="text-[11px] mr-[11px] text-[#646464] font-[400] font-inter leading-normal">
                                {module.details || "No additional details available."}
                              </p>
                            </CustomScroll>
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <div className="h-[216px] w-[4px] bg-[#646464]"></div>
                          <div className="w-[31px] h-[216px] bg-black rounded-tr-[7.6px] rounded-br-[7.6px] pt-[6px] pl-[6px] pr-[6px]">
                            <div className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]">
                              <span className="text-white text-[10px] font-bold">{moduleIndex + 1}</span>
                            </div>
                            <div className="mt-[143px] ">
                              <button
                                className="w-auto h-auto bg-[#FFFFFF4D] flex items-center justify-center p-[2px] rounded-[4px]"
                                onClick={() => handleSaveClick(sessionID, chapterName, module.title, !module.is_saved)}
                              >
                                <SaveIcon title="Save" className="w-4 h-4 text-white" />
                              </button>
                              <button
                                className="w-auto h-auto bg-[#FFFFFF4D] mt-[4px] flex items-center justify-center p-[2px] rounded-[4px]"
                                onClick={() => handleChatClick(module.details)}
                              >
                                <BotIcon className="w-4 h-4 text-white" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}{" "}
            </div>
          )}
        </div>
      </ConditionalLoader>
      {showProjectModule && <ProjectModuleComponent />}
      <Modal open={isAttendanceModalOpen} onClose={handleCloseAttendanceModal}>
        <ConditionalLoader isLoading={feedbackOpen} loader={<CourseSessionFeedback onSubmit={submitFeedback} />}>
          <div className="flex flex-col gap-2">
            <div className="mb-2 flex flex-col gap-2">
              <h3 className="text-new-accent">{attendanceModalData?.chapter_name}</h3>
              <div className="flex items-center gap-4">
                {!isEmpty(attendanceModalData) && <p>{toDateTime(parseInt(attendanceModalData?.timestamp) * 1000)}</p>}
                {attendanceModalData?.is_attended ? (
                  <h4 className="text-success">Present</h4>
                ) : (
                  <h4 className="text-failure">Absent</h4>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <h3>Did you attend this class?</h3>
              <div className="flex gap-2">
                <ButtonNew
                  outlined
                  className="w-28"
                  onClick={() => handleAttendanceChange(true)}
                  loading={attendanceSessionLoading === 1}
                  disabled={attendanceSessionLoading !== -1}
                >
                  Yes
                </ButtonNew>
                <ButtonNew
                  outlined
                  failure
                  className="w-28"
                  onClick={() => handleAttendanceChange(false)}
                  loading={attendanceSessionLoading === 0}
                  disabled={attendanceSessionLoading !== -1}
                >
                  No
                </ButtonNew>
              </div>
            </div>
          </div>
        </ConditionalLoader>
      </Modal>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayoutv2>
  )
}
