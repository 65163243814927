import { DatePicker } from "core/components/v2/ui/DatePicker"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import ContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import ContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import { format, parse, set } from "date-fns"
import { Auth } from "domain/model/Auth"
import { getBatchDetails } from "domain/useCase/Admin/Batch/getBatchDetails"
import { BatchDeleteExamInModule } from "domain/useCase/Admin/Contest/BatchDeleteExamInModule"
import { BatchDeleteModuleTemplate } from "domain/useCase/Admin/Contest/BatchDeleteModuleTemplate"
import { CheckTemplateType } from "domain/useCase/Admin/Contest/CheckTempateType"
import { DeleteExamInModule } from "domain/useCase/Admin/Contest/DeleteExamInModule"
import { DeleteModuleExamTemplate } from "domain/useCase/Admin/Contest/DeleteModuleExamTemplate"
import { GetBatchExamTemplate } from "domain/useCase/Admin/Contest/GetBatchExamTemplate"
import { GetExamTemplate } from "domain/useCase/Admin/Contest/GetExamTemplate"
import { GetStudentExamTemplate } from "domain/useCase/Admin/Contest/GetStudentExamTemplate"
import { StudentDeleteExamInModule } from "domain/useCase/Admin/Contest/StudentDeleteExamInModule"
import { StudentDeleteModuleTemplate } from "domain/useCase/Admin/Contest/StudentDeleteModuleTemplate"
import { UpdateBatchExamTemplate } from "domain/useCase/Admin/Contest/UpdateBatchExamTemplate"
import { UpdateExamTemplate } from "domain/useCase/Admin/Contest/UpdateExamTemplate"
import { UpdateStudentExamTemplate } from "domain/useCase/Admin/Contest/UpdateStudentExamTemplate"

import React from "react"

export default function EditContestFlowViewModel() {
  const { auth } = useAuth()
  const [template_id, setTemplateId] = React.useState("")
  const [studentId, setStudentId] = React.useState("")
  const [examData, setExamData] = React.useState<
    {
      phase_name: string
      exams: { exam_id: string; exam_name: string }[]
    }[]
  >([])
  const [mockData, setMockData] = React.useState<
    {
      id: string
      name: string
      link: string
    }[]
  >([])
  const [startTime, setStartTime] = React.useState<any>("")
  const [items, setItems] = React.useState<
    {
      id: string
      name: string
      template_id: string
      phases: {
        id: string
        name: string
        exams: {
          id: string
          name: string
          type: string
          attempts: {
            link: string
            timestamp: number
          }[]
        }[]
      }[]
      mock: {
        id: string
        name: string
        link: string
      }[]
      created_at: number
      updated_at: string
    }[]
  >([])
  const [templateDetails, setTemplateDetails] = React.useState<{ name: string; template_id: string }>()

  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [indexToBeDeleted, setIndexToBeDeleted] = React.useState(-1)
  const [examIndex, setExamIndex] = React.useState(-1)

  const [isOpen, setIsOpen] = React.useState<any>(false)
  const [isOpenExamDelete, setIsOpenExamDelete] = React.useState<any>(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [module_id, setModuleId] = React.useState("")
  const [exam_id, setExamId] = React.useState("")
  const [filterType, setFilterType] = React.useState("")
  function extractTemplateData() {
    const currentPath = window.location.pathname
    const pathSegments = currentPath.split("/")
    const templateIdIndex = pathSegments.indexOf("edit") - 1
    let templateId = ""
    if (templateIdIndex !== -1) {
      templateId = pathSegments[templateIdIndex]

      setTemplateId(templateId)
      getTemplateType(templateId)
    }
  }

  React.useEffect(() => {
    if (template_id) {
      getTemplateType(template_id)
    }
  }, [template_id])

  const GetExamTemplateUseCase = new GetExamTemplate(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const UpdateExamTemplateUseCase = new UpdateExamTemplate(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const DeleteModuleUseCase = new DeleteModuleExamTemplate(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const DeleteExamInModuleUseCase = new DeleteExamInModule(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const BatchDeleteModuleUseCase = new BatchDeleteModuleTemplate(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const BatchDeleteExamInModuleUseCase = new BatchDeleteExamInModule(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const GetBatchExamTemplateUseCase = new GetBatchExamTemplate(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const UpdateBatchExamTemplateUseCase = new UpdateBatchExamTemplate(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const CheckTemplateTypeUseCase = new CheckTemplateType(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const GetStudentExamTemplateUseCase = new GetStudentExamTemplate(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const UpdateStudentExamTemplateUseCase = new UpdateStudentExamTemplate(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const StudentDeleteModuleTemplateUseCase = new StudentDeleteModuleTemplate(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const StudentDeleteExamInModuleUseCase = new StudentDeleteExamInModule(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const handleStartTimeChange = (event: any) => {
    setStartTime(event.target.value)
  }

  async function getTemplateType(template_id: string) {
    const response = await CheckTemplateTypeUseCase.invoke(auth, template_id)
    if (response !== null) {
      setFilterType(response?.type)
    }
  }

  async function updateExamtemplateGeneric(
    updates: {
      mock?: { id: string; name?: string; link?: string }[]
      phases?: {
        id: string
        name?: string
        exams?: { id: string; name?: string; type?: string }[]
      }[]
    }[],
    name: string
  ) {
    setIsLoading(true)

    const response = await UpdateExamTemplateUseCase.invoke(auth, template_id, name, updates as [])
    setIsLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Error in updating template")
      changeToastVisibility(true)
    }
    changeToastDetails(STR_SUCCESS, "Updated Exam Template successfully")
    changeToastVisibility(true)
  }
  const segregateUpdates = () => {
    const updates = items.map((item) => ({
      id: item.id,
      phases: item.phases.map((phase) => ({
        id: phase.id,
        exams: phase.exams.map((exam) => ({
          id: exam.id,
          attempts: exam.attempts.map((attempt) => ({
            ...attempt,
            timestamp: Math.floor(new Date(attempt.timestamp).getTime() / 1000), // convert timestamp
          })),
        })),
      })),
    }))

    return updates
  }

  async function updateBatchExamtemplate(
    updates: {
      mock?: { id: string; name?: string; link?: string }[]
      phases?: {
        id: string
        name?: string
        exams?: {
          id: string
          name?: string
          type?: string
          attempts?: {
            link?: string
            timestamp?: number
          }[]
        }[]
      }[]
    }[],
    name: string
  ) {
    setIsLoading(true)
    const updatedData = updates.map((update) => ({
      ...update,

      phases: update.phases?.map((phase) => ({
        ...phase,
        exams: phase.exams?.map((exam) => ({
          ...exam,
          attempts: exam.attempts?.map((attempt) => {
            const convertedTimestamp = attempt.timestamp
              ? Math.floor(new Date(attempt.timestamp).getTime() / 1000)
              : undefined

            return {
              ...attempt,
              timestamp: convertedTimestamp,
            }
          }),
        })),
      })),
    }))
    const response = await UpdateBatchExamTemplateUseCase.invoke(auth, template_id, updatedData as [], name)
    setIsLoading(false)

    if (response?.success == false) {
      changeToastDetails(STR_FAILURE, "Error in updating batch template")
      changeToastVisibility(true)
      return
    } else {
      changeToastDetails(STR_SUCCESS, "Updated batch template")
      changeToastVisibility(true)
      return
    }
  }

  async function updateStudentExamtemplate(
    updates: {
      mock?: { id: string; name?: string; link?: string }[]
      phases?: {
        id: string
        name?: string
        exams?: {
          id: string
          name?: string
          type?: string
          attempts?: {
            link?: string
            timestamp?: number
          }[]
        }[]
      }[]
    }[],
    name: string
  ) {
    setIsLoading(true)
    const updatedData = updates.map((update) => ({
      ...update,
      phases: update.phases?.map((phase) => ({
        ...phase,
        exams: phase.exams?.map((exam) => ({
          ...exam,
          attempts: exam.attempts?.map((attempt) => {
            const convertedTimestamp = attempt.timestamp
              ? Math.floor(new Date(attempt.timestamp).getTime() / 1000)
              : undefined

            return {
              ...attempt,
              timestamp: convertedTimestamp,
            }
          }),
        })),
      })),
    }))
    const response = await UpdateStudentExamTemplateUseCase.invoke(auth, template_id, updatedData as [], name)
    setIsLoading(false)

    if (response?.success) {
      changeToastDetails(STR_FAILURE, "Error in updating student template")
      changeToastVisibility(true)
    }
    changeToastDetails(STR_SUCCESS, "Upated Student Template")
    changeToastVisibility(true)
  }
  const [isFormValid, setIsFormValid] = React.useState(true)
  const [templateName, setTemplateName] = React.useState("")

  const validateForm = (itemsToValidate: any) => {
    const allFieldsFilled = itemsToValidate.every((item: any) => {
      const mockFilled = item.mock.every((mock: any) => mock.name.trim() !== "" && mock.link.trim() !== "")

      const phasesFilled = item.phases.every((phase: any) => {
        const phaseNameFilled = phase.name.trim() !== ""

        const examsFilled = phase.exams.every((exam: any) => {
          const examNameFilled = exam.name.trim() !== ""

          const examTypeFilled = exam.type.trim() !== ""

          const attemptsFilled = exam.attempts.every((attempt: any) => {
            const isLinkValid = attempt.link.trim() !== ""
            const isTimestampValid = isValidDate(attempt.timestamp)
            return isLinkValid && isTimestampValid
          })

          return examNameFilled && examTypeFilled && attemptsFilled
        })

        return phaseNameFilled && examsFilled
      })

      return mockFilled && phasesFilled
    })

    setIsFormValid(allFieldsFilled)
  }
  const isValidDate = (time: string) => {
    const date = new Date(time)
    const epochTimestamp = Math.floor(date.getTime() / 1000)
    return epochTimestamp > 0
  }
  const handleTemplateNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value
    setTemplateName(newName)
    validateForm(items)
  }
  async function getExamtemplateGeneric(template_id: string) {
    const response = await GetExamTemplateUseCase.invoke(auth, template_id)
    if (response !== null) {
      const templateData = response
      const templateDetails = {
        name: templateData.name,
        template_id: templateData.template_id,
        created_at: templateData.created_at,
        updated_at: templateData.updated_at,
      }
      interface MockDetails {
        id: string
        name: string
        link: string
      }

      interface ExamDetails {
        id: string
        name: string
        type: string
        attempts: { id: string; name: string }[]
      }

      interface PhaseDetails {
        id: string
        name: string
        exams: ExamDetails[]
      }

      const mockData = templateData.mock.map((mock: MockDetails) => ({
        id: mock.id,
        name: mock.name,
        link: mock.link,
      }))
      const phaseData = templateData.phases.map((phase: PhaseDetails) => ({
        ...phase,
        exams: phase.exams.map((exam: ExamDetails) => ({
          ...exam,
        })),
      }))
      const itemsData = [
        {
          id: templateData.template_id,
          name: templateData.name,
          phases: phaseData,
          mock: mockData,
          created_at: templateData.created_at,
          updated_at: templateData.updated_at,
          template_id: templateData.template_id,
        },
      ]
      setMockData(mockData)
      setExamData(phaseData)
      setTemplateDetails(templateDetails)
      setItems(itemsData)
    } else {
    }
  }
  async function getBatchExamTemplate(batch_id: string) {
    const response = await GetBatchExamTemplateUseCase.invoke(auth, batch_id)
    // if (response?.success) {
    if (response !== null) {
      const templateData = response

      const templateDetails = {
        name: templateData.name,
        template_id: templateData.template_id,
        created_at: templateData.created_at,
        updated_at: templateData.updated_at,
      }
      interface MockDetails {
        id: string
        name: string
        link: string
      }

      interface ExamDetails {
        id: string
        name: string
        type: string
        attempts: { link: string; timestamp: number }[]
      }

      interface PhaseDetails {
        id: string
        name: string
        exams: ExamDetails[]
      }
      // const name = templateName
      const mockData = templateData.mock.map((mock: MockDetails) => ({
        id: mock.id,
        name: mock.name,
        link: mock.link,
      }))

      const phaseData = templateData.phases.map((phase: PhaseDetails) => ({
        ...phase,
        exams: phase?.exams?.map((exam: ExamDetails) => ({
          ...exam,
          attempts: exam?.attempts?.map((attempt: any) => ({
            link: attempt?.link || "",
            timestamp: attempt?.timestamp
              ? format(Math.floor(new Date(attempt.timestamp).getTime() + 5.5 * 60 * 60 * 1000), "yyyy-MM-dd'T'HH:mm")
              : "",
          })),
        })),
      }))

      const itemsData = [
        {
          id: templateData.template_id,
          name: templateName,
          phases: phaseData,
          mock: mockData,
          created_at: templateData.created_at,
          updated_at: templateData.updated_at,
          template_id: templateData.template_id,
        },
      ]
      setMockData(mockData)
      setExamData(phaseData)
      setTemplateDetails(templateDetails)
      setItems(itemsData)
      setStartTime(Math.floor(new Date(startTime).getTime()) / 1000)
    } else {
    }
    // }
  }

  async function getStudentExamTemplate(student_id: string) {
    const response = await GetStudentExamTemplateUseCase.invoke(auth, student_id)

    if (response?.success) {
      if (response.data !== null) {
        const templateData = response.data
        const templateDetails = {
          name: templateData.name,
          template_id: templateData.template_id,
          created_at: templateData.created_at,
          updated_at: templateData.updated_at,
        }
        interface MockDetails {
          id: string
          name: string
          link: string
        }

        interface ExamDetails {
          id: string
          name: string
          type: string
          attempts: { id: string; name: string }[]
        }

        interface PhaseDetails {
          id: string
          name: string
          exams: ExamDetails[]
        }

        const mockData = templateData.mock.map((mock: MockDetails) => ({
          id: mock.id,
          name: mock.name,
          link: mock.link,
        }))
        const phaseData = templateData.phases.map((phase: PhaseDetails) => ({
          ...phase,
          exams: phase.exams.map((exam: ExamDetails) => ({
            ...exam,
            attempts: exam.attempts.map((attempt: any) => ({
              link: attempt.link,
              timestamp: attempt.timestamp,
            })),
          })),
        }))
        const itemsData = [
          {
            id: templateData.template_id,
            name: templateData.name,
            phases: phaseData,
            mock: mockData,
            created_at: templateData.created_at,
            updated_at: templateData.updated_at,
            template_id: templateData.template_id,
          },
        ]
        setMockData(mockData)
        setExamData(phaseData)
        setTemplateDetails(templateDetails)
        setItems(itemsData)
        // setTimestamp(format(Number(timestamp), "yyyy-MM-dd'T'HH:mm"))
      } else {
      }
    }
  }
  async function deleteModuleTemplate(template_id: string, module_id: string) {
    setIsLoading(true)

    let response
    if (filterType === "batch") {
      response = await BatchDeleteModuleUseCase.invoke(auth, template_id, module_id)
    } else if (filterType === "generic") {
      response = await DeleteModuleUseCase.invoke(auth, template_id, module_id)
    } else if (filterType === "student") {
      response = await StudentDeleteModuleTemplateUseCase.invoke(auth, template_id, module_id)
    } else {
      console.error("Unknown filterType:", filterType)
      setIsLoading(false)
      return
    }

    if (response?.success) {
      handleDeleteModule(indexToBeDeleted)
      changeToastDetails(STR_SUCCESS, "Module deleted")
      changeToastVisibility(true)
    } else {
      changeToastDetails(STR_FAILURE, "Failed to delete module")
      changeToastVisibility(true)
    }
    setIsLoading(false)
  }

  const handleDeleteModule = (index: number) => {
    const updatedItems = [...items]
    updatedItems.splice(index, 1)
    setItems(updatedItems)
  }

  const handleInputChange = (
    index: number,
    field: keyof (typeof items)[0],
    value: any,
    mockIndex?: number,
    examIndex?: number,
    phaseIndex?: number,
    attemptIndex?: number
  ) => {
    const updatedItems = [...items]

    if (index < 0 || index >= updatedItems.length) {
      console.error("Index is out of bounds:", index)
      return
    }

    if (field === "phases" && typeof phaseIndex === "number") {
      if (typeof examIndex === "number") {
        if (typeof attemptIndex === "number") {
          updatedItems[index].phases[phaseIndex].exams[examIndex].attempts[attemptIndex] = {
            ...updatedItems[index].phases[phaseIndex].exams[examIndex].attempts[attemptIndex],

            link: value.link,
            timestamp: value.timestamp,
            // timestamp: value.timestamp ? saveTimestamp(value.timestamp) : 0,
          }
        } else {
          // Update exam (without modifying attempts)
          updatedItems[index].phases[phaseIndex].exams[examIndex] = {
            ...updatedItems[index].phases[phaseIndex].exams[examIndex],
            ...value, // (id, name, type)
          }
        }
      } else {
        updatedItems[index].phases[phaseIndex] = {
          ...updatedItems[index].phases[phaseIndex],
          ...value, //(name)
        }
      }
    } else if (field === "mock" && typeof mockIndex === "number") {
      updatedItems[index].mock[mockIndex] = {
        ...updatedItems[index].mock[mockIndex],
        ...value, //(id, name, link)
      }
    }
    setItems(updatedItems)
    validateForm(updatedItems)
  }

  const handleAddMockExam = (index: number) => {
    const updatedItem = { ...items[index], mock: [...items[index].mock, { id: "", name: "", link: "" }] }
    setItems([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
    validateForm([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
  }

  const handleDeleteMockExam = (index: number, mockIndex: number) => {
    const updatedItem = { ...items[index], mock: items[index].mock.filter((_, i) => i !== mockIndex) }

    setItems([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
    validateForm([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
  }

  const handleAddPhaseExam = (index: number, phaseIndex: number, examName: string, examType: string) => {
    const newExam = { id: "", name: examName, type: examType, attempts: [] }
    const updatedItems = [...items]
    updatedItems[index].phases[phaseIndex].exams.push(newExam)
    setItems(updatedItems)
    validateForm(updatedItems)
  }
  const handleDeletePhaseExam = (index: number, phaseIndex: number, examIndex: number) => {
    const updatedItem = {
      ...items[index],
      phases: items[index].phases.map((phase, i) => {
        if (i === phaseIndex) {
          return { ...phase, exams: phase.exams.filter((exam, j) => j !== examIndex) }
        }
        return phase
      }),
    }
    setItems([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
    validateForm([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
  }
  const handleAddPhase = (index: number, phaseName: string, examName: string, examType: string) => {
    const newExam = { id: "", name: examName, type: examType, attempts: [] }
    const newPhase = { id: "", name: phaseName, exams: [newExam] }
    const updatedItem = {
      ...items[index],
      phases: [...items[index].phases, newPhase],
    }
    setItems([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
    validateForm([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
  }
  const handleDeletePhase = (index: number, phaseIndex: number) => {
    const updatedItem = {
      ...items[index],
      phases: items[index].phases.filter((phase, i) => i !== phaseIndex),
    }
    setItems([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
    validateForm([...items.slice(0, index), updatedItem, ...items.slice(index + 1)])
  }
  const handleAddAttempt = (index: number, phaseIndex: number, examIndex: number) => {
    const updatedItems = [...items]
    updatedItems[index].phases[phaseIndex].exams[examIndex].attempts.push({
      link: "",
      timestamp: 0,
    })
    setItems(updatedItems)
    validateForm(updatedItems)
  }
  const handleDeleteAttempt = (index: number, phaseIndex: number, examIndex: number, attemptIndex: number) => {
    const updatedItems = [...items]
    updatedItems[index].phases[phaseIndex].exams[examIndex].attempts.splice(attemptIndex, 1)
    setItems(updatedItems)
    validateForm(updatedItems)
  }
  const saveTimestamp = (timestamp: string) => {
    const timestampInSeconds = Math.floor(new Date(timestamp).getTime() / 1000)
    return timestampInSeconds
  }
  return {
    STR_FAILURE,
    STR_SUCCESS,
    useToast,
    studentId,
    setStudentId,
    items,
    setItems,
    indexToBeDeleted,
    setIndexToBeDeleted,
    isOpen,
    setIsOpen,
    isLoading,
    setIsLoading,
    extractTemplateData,
    updateExamtemplateGeneric,
    handleDeleteModule,
    handleInputChange,
    toast,
    changeToastVisibility,
    examData,
    setExamData,
    templateDetails,
    setTemplateDetails,
    getExamtemplateGeneric,
    template_id,
    module_id,
    setModuleId,
    exam_id,
    setExamId,
    deleteModuleTemplate,
    examIndex,
    setExamIndex,
    isOpenExamDelete,
    setIsOpenExamDelete,
    getBatchExamTemplate,
    updateBatchExamtemplate,
    filterType,
    getTemplateType,
    getStudentExamTemplate,
    updateStudentExamtemplate,
    mockData,
    handleAddMockExam,
    handleDeleteMockExam,
    handleAddPhaseExam,
    handleDeletePhaseExam,
    handleAddPhase,
    handleDeletePhase,
    handleAddAttempt,
    handleDeleteAttempt,
    handleStartTimeChange,
    startTime,
    setStartTime,
    saveTimestamp,
    isFormValid,
    templateName,
    handleTemplateNameChange,
    setTemplateName,
    validateForm,
    auth,
  }
}
