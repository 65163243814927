import { MentorNotificationsRepository } from "domain/repository/Mentor/MentorNotificationsRepository.ts"
export interface GetMentorNotificationsUseCase {
  invoke(auth: any): Promise<any>
}

export class GetMentorNotifications implements GetMentorNotificationsUseCase {
  private repository: MentorNotificationsRepository

  constructor(repository: MentorNotificationsRepository) {
    this.repository = repository
  }

  async invoke(auth: any) {
    return await this.repository.getMentorNotifications(auth)
  }
}
