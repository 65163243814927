import DashboardLayout from "core/layouts/DashboardLayout"
import useInstructorAvailabilityDashboardViewModel from "./InstructorAvailabilityDashboardViewModel"
import Avatar from "react-avatar"
import { useEffect } from "react"
import { ToggleButton } from "core/components/v2/ToggleButton"

interface Instructor {
  photo: string
  name: string
  current_company: string
  experience: number
  email: string
  phone: string
  linkedin_profile: string
  instructorAvailability: string[]
  instructorAvailabilityUpdatedAt: string
}

const InstructorAvailabilityDashboardView = () => {
  const {
    toggleStatus,
    instructorAvailability,
    getAllInstructorAvailability,
    formatDate,
    formatDayWithDate,
    toggleInstructorAvailabilityHandler,
    getToggleInstructorAvailability,
  } = useInstructorAvailabilityDashboardViewModel()

  useEffect(() => {
    getToggleInstructorAvailability()
    getAllInstructorAvailability()
  }, [])

  return (
    <DashboardLayout>
      <div className="px-8 py-4">
        <h1 className="text-2xl font-bold text-new-accent mb-6">Instructor Availability Dashboard</h1>
        <div className="grid grid-cols-1 gap-6">
          <div className=" flex items-center  ">
            <p className=" text-lg ">Instructor Availability : </p>
            <ToggleButton
              className="  ml-2"
              checked={Boolean(toggleStatus)}
              onChange={() => {
                toggleInstructorAvailabilityHandler(toggleStatus ? false : true)
              }}
            />
          </div>

          {instructorAvailability.map((instructor: Instructor, index: number) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-md flex">
              <div className="w-2/5 p-4 border-r border-gray-200 flex flex-col items-center">
                <Avatar
                  size="40"
                  src={instructor.photo}
                  alt={`${instructor.name}'s profile`}
                  className="shadow-lg mb-4"
                  name={instructor.name}
                />
                <h2 className="text-xl font-semibold text-gray-700 text-center">{instructor.name}</h2>
                <p className="text-gray-600 text-sm mb-1 text-center">Current Company: {instructor.current_company}</p>
                <p className="text-gray-600 text-sm mb-1 text-center">Experience: {instructor.experience} years</p>
                <p className="text-gray-600 text-sm mb-1 text-center">Email: {instructor.email}</p>
                <p className="text-gray-600 text-sm mb-2 text-center">Phone: {instructor.phone}</p>
                <a
                  href={instructor.linkedin_profile}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline text-sm text-center"
                >
                  LinkedIn Profile
                </a>
                <p className="text-red-500 text-sm mt-2 text-center">
                  Last Updated: {formatDate(instructor.instructorAvailabilityUpdatedAt)}
                </p>
              </div>
              <div className="w-3/5 p-4">
                <h3 className="text-gray-700 font-medium mb-2 text-center">Availability:</h3>
                <ul className="text-gray-600 text-sm flex flex-wrap justify-center gap-2">
                  {instructor.instructorAvailability.map((date: string, i: number) => (
                    <li key={i} className="bg-blue-200 text-blue-800 rounded-full px-3 py-1 text-xs shadow-sm">
                      {formatDayWithDate(date)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DashboardLayout>
  )
}

export default InstructorAvailabilityDashboardView
