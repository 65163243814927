import React, { useState } from "react"
import InstructorAvailabilityAPIDataSourceImpl from "data/API/Admin/InstructorAvailabilityAPIDataSourceImpl"
import { InstructorAvailabilityRepositoryImpl } from "data/repository/Admin/InstructorAvailabilityRepositoryImpl"
import { GetInstructorAvailability } from "domain/useCase/Admin/InstructorAvailability/GetInstructorAvailability"
import { ToggleInstructorAvailability } from "domain/useCase/Admin/InstructorAvailability/ToggleInstructorAvailability"
import { GetToggleInstructorAvailability } from "domain/useCase/Admin/InstructorAvailability/GetToggleInstructorAvailability"
import { useAuth } from "core/context/auth"

const InstructorAvailabilityDashboardViewModel = () => {
  const { auth } = useAuth()
  const [instructorAvailability, setInstructorAvailability] = useState<any>([])
  const [toggleStatus, setToggleStatus] = useState<boolean>(false)

  const getInstructorAvailabilityUseCase = new GetInstructorAvailability(
    new InstructorAvailabilityRepositoryImpl(new InstructorAvailabilityAPIDataSourceImpl())
  )

  const toggleInstructorAvailabilityUseCase = new ToggleInstructorAvailability(
    new InstructorAvailabilityRepositoryImpl(new InstructorAvailabilityAPIDataSourceImpl())
  )

  const getToggleInstructorAvailabilityUseCase = new GetToggleInstructorAvailability(
    new InstructorAvailabilityRepositoryImpl(new InstructorAvailabilityAPIDataSourceImpl())
  )

  const getAllInstructorAvailability = async () => {
    const res = await getInstructorAvailabilityUseCase.invoke(auth)
    setInstructorAvailability(res.data)
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = String(date.getFullYear()).slice(-2)
    const hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const ampm = hours >= 12 ? "PM" : "AM"
    const formattedHours = hours % 12 || 12
    return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`
  }

  const formatDayWithDate = (dateString: string) => {
    try {
      const [day, month, year] = dateString.split("-")
      const formattedDateString = `20${year}-${month}-${day}`
      const date = new Date(formattedDateString)
      const dayOfWeek = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date)
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      return `${dayOfWeek}, ${formattedDate}`
    } catch (error) {
      return "Invalid Date"
    }
  }

  const getToggleInstructorAvailability = async () => {
    const res = await getToggleInstructorAvailabilityUseCase.invoke(auth)
    setToggleStatus(Boolean(res?.data?.availability))
  }

  const toggleInstructorAvailabilityHandler = async (toggle: boolean) => {
    const res = await toggleInstructorAvailabilityUseCase.invoke(auth, toggle)
    if (res?.data === true) {
      setToggleStatus(!toggleStatus)
    }
  }

  return {
    toggleStatus,
    instructorAvailability,
    getAllInstructorAvailability,
    formatDate,
    formatDayWithDate,
    toggleInstructorAvailabilityHandler,
    getToggleInstructorAvailability,
  }
}

export default InstructorAvailabilityDashboardViewModel
