import React from "react"
import ReferralModal from "./ReferralModal"
import Cross from "assets/svgs/Cross"
import Stage1 from "assets/svgs/Stage1"
import Stage2 from "assets/svgs/Stage2"
import Stage3 from "assets/svgs/Stage3"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "core/components/v2/Accordion"
import LinearGradientTopLeft from "assets/svgs/LinearGradientTopLeft"
import { CustomScroll } from "react-custom-scroll"
import ReferralRewardsModal from "./ReferralRewardsModal"
import { Button } from "core/components/v2/Button"
import webEngageTrack from "core/utils/webEngageTrack"
import ReferralTableTabGroup from "./ReferralTableTabGroup"
import useLocalStorage from "core/hooks/useLocalStorage"

const ReferralWorkingModal = ({ isOpen, onClose }: any) => {
  const activeTableButtons = ["Referral Rewards", "FAQ"]
  const [activeTableButtonType, setActiveTableButtonType] = React.useState<string>("Referral Rewards")
  const [isReferRewardsModalOpen, setReferRewardsModal] = React.useState(false)
  const [student] = useLocalStorage<any>("student", {} as any)
  const TABS = ["Referral Rewards", "FAQ"]
  function changeActiveTableButtonType(index: number) {
    const tabType = ["Referral Rewards", "FAQ"][index]
    setActiveTableButtonType(tabType)
  }
  return (
    <div>
      <ReferralModal isOpen={isOpen}>
        <div className="w-[740px] h-[642px] overflow-y-auto bg-white rounded-[8px] ">
          <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
            <div className="p-[24px]">
              <div className="flex justify-between">
                <p className="text-[18px] font-[600] text-[#162456]">Know How Referral works?</p>
                <button className="hover:bg-[#D3D3D3] duration-500 rounded-sm" onClick={onClose}>
                  <Cross />
                </button>
              </div>

              <div className="flex gap-[15px] justify-between mt-[16px]">
                <div className="relative overflow-hidden h-[200px] w-1/3 border-[#D3D3D3] border-[0.5px] rounded-[8px] p-[12px]">
                  <div className="absolute top-0 right-0">
                    <LinearGradientTopLeft />
                  </div>
                  <Stage1 className="size-[32px]" />

                  <p className="text-[#D3D3D3] text-[10px] font-[600] mt-[10px]">Step 1</p>
                  <p className="text-[#162456] text-[14px] font-[600]">Refer Stage</p>
                  <p className="text-[#333] text-[12px] font-[400] leading-[16px] mt-[5px]">
                    Share or Submit: Share the referral link with the referee or fill out the referral form with the
                    referee’s details.
                  </p>
                </div>
                <div className="relative overflow-hidden h-[200px] w-1/3 border-[#D3D3D3] border-[0.5px] rounded-[8px] p-[12px]">
                  <div className="absolute top-0 right-0">
                    <LinearGradientTopLeft />
                  </div>
                  <Stage2 className="size-[32px]" />

                  <p className="text-[#D3D3D3] text-[10px] font-[600] mt-[10px]">Step 2</p>
                  <p className="text-[#162456] text-[14px] font-[600]">Enrolment Stage</p>
                  <p className="text-[#333] text-[12px] font-[400] leading-[16px] mt-[5px]">
                    Registration: Our team will contact the person you referred to complete their course registration.
                  </p>
                </div>
                <div className="relative overflow-hidden h-[200px] w-1/3 border-[#D3D3D3] border-[0.5px] rounded-[8px] p-[12px]">
                  <div className="absolute top-0 right-0">
                    <LinearGradientTopLeft />
                  </div>
                  <Stage3 className="size-[32px]" />

                  <p className="text-[#D3D3D3] text-[10px] font-[600] mt-[10px]">Step 3</p>
                  <p className="text-[#162456] text-[14px] font-[600]">Reward Stage</p>
                  <p className="text-[#333] text-[12px] font-[400] leading-[16px] mt-[5px]">
                    Earn Rewards: After the referee finishes their first month in Bosscoder, both the referrer (YOU) and
                    the referee will receive rewards.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full max-w-2xl px-[24px]">
              <div className="mb-[16px] flex justify-between">
                <ReferralTableTabGroup
                  buttons={activeTableButtons}
                  active={activeTableButtons.indexOf(activeTableButtonType)}
                  onChange={changeActiveTableButtonType}
                />
              </div>
            </div>

            <div className="w-full">
              {activeTableButtonType === "Referral Rewards" && (
                <>
                  <ReferralRewardsModal
                    isOpen={isReferRewardsModalOpen}
                    onClose={() => setReferRewardsModal(false)}
                    student={student}
                  />
                </>
              )}
            </div>

            <div className="w-full">
              {activeTableButtonType === "FAQ" && (
                <div className="p-[24px] bg-[#F9F9F9]">
                  <p className="text-[18px] font-[600] text-[#162456]">Frequently Asked Questions</p>

                  <div className="p-[16px] bg-white rounded-[4px] my-[10px]">
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">
                            What is the referral cash back structure?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <a
                              href="https://docs.google.com/document/d/1T6Wkt-bZyqu_ofycbYrNRccg8o0G6zjbGnXEc_5dXeI/edit"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500"
                            >
                              Click here
                            </a>{" "}
                            to view the Referral cashback structure
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>

                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">Who can I refer to for the course?</p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              You can refer anyone who meets the eligibility criteria for our courses. This may include
                              friends, colleagues, or professional connections.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>

                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">Why should I refer to someone?</p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              Referring someone earns you additional rewards and helps others discover valuable
                              resources. Plus, your referral could provide a helping hand to someone in need, making a
                              positive impact while increasing your benefits.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>

                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">
                            How soon will I receive my reward after referring to someone?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              You will receive your reward after the referred person completes their Observation period
                              and their registration is verified by our team. Rewards are typically processed shortly
                              after this verification.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">
                            How long is the referral offer valid for?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              The referral remains available for you as long as you are a Bosscoder student or alumni.
                              You can keep referring to people even after finishing your course.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">Where will I receive my referral reward?</p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              If the referral reward is monetary, it will be deposited into your bank account. For other
                              rewards, you will receive an online voucher or, if it's a physical item, it will be
                              delivered to your address.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]">
                            Is there any limit to the number of people I can refer to?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              No, there is no limit to the number of people you can refer to. The more referrals you
                              make, the more rewards you can earn. For more details, please check the reward structure.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500] text-left">
                            What happens if I told my friend about the referral program, but they registered directly
                            without using my referral link or code? Is there any chance I’ll still receive my reward?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              If your friend registered directly without using your referral link or code, then they
                              will be registered as direct enrollment and you will not get any referral rewards.{" "}
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]  text-left">
                            I referred my friend successfully, but I haven’t received my reward even after 1 month of
                            the course. What should I do?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              If you need to reach out for assistance, you can email{" "}
                              <button
                                onClick={() => (window.location.href = `mailto:help@bosscoderacademy.com`)}
                                className="text-blue-500"
                              >
                                help@bosscoderacademy.com
                              </button>
                              . Be sure to include your details and explain the situation regarding your referral
                              reward. They should be able to help you resolve the issue!
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <Accordion type="single" collapsible className="my-[10px]">
                      <AccordionItem value="item-1">
                        <AccordionTrigger className="bg-[#F3F3F3] rounded-[4px] px-[16px] py-[8px] [&[data-state=open]]:bg-white">
                          <p className="text-[#333] text-[14px] font-[500]  text-left">
                            My query is not covered here and I have a problem with my referral, whom can I get in touch
                            with?
                          </p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="px-[16px]  my-[5px] ">
                            <p>
                              If your query isn't covered here or if you have an issue with your referral, please email
                              <button
                                onClick={() => (window.location.href = `mailto:help@bosscoderacademy.com`)}
                                className="text-blue-500"
                              >
                                help@bosscoderacademy.com
                              </button>{" "}
                              for assistance. They’ll be able to help you with your concerns.
                            </p>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              )}
            </div>
          </CustomScroll>
        </div>
      </ReferralModal>
    </div>
  )
}

export default ReferralWorkingModal
