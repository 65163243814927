import { useState } from "react"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { GetAllTracks } from "domain/useCase/Admin/CreateClass/GetAllTracks"
import { GetModuleBasedTrack } from "domain/useCase/Admin/CreateClass/GetModuleBasedTrack"
import { CreateBatches } from "domain/useCase/Admin/Batch/CreateBatch"
import { BatchRepositoryImpl } from "data/repository/Admin/BatchRepositoryImpl"
import { BatchAPIDataSourceImpl } from "data/API/Admin/BatchAPIDataSourceImpl"
import { GetAllExamTemplates } from "domain/useCase/Admin/Batch/GetAllExamTemplates"
import { GetAllMentorshipTemplates } from "domain/useCase/Admin/Batch/GetAllMentorshipTemplates"

export default function CreateBatchViewModel() {
  const [_selectTrack, _setSelectTrack] = useState<any>([])
  const [_selectModule, _setSelectModule] = useState<any>([])
  const [_batchName, _setBatchName] = useState("")
  const [_modules, _setModules] = useState<any>({})
  const [_showModules, _setShowModules] = useState<any>([])
  const [allExamTemplates, setAllExamTemplates] = useState<any>([])
  const [selectedExamtemplate, setSelectedExamtemplate] = useState("")
  const [selectedTracks, setSelectedTracks] = useState<string[]>([])
  const [_currentSelectTrack, setCurrentSelectTrack] = useState<any>([])
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const [_currentSelectModule, setCurrentSelectModule] = useState<any>([])
  const [batchName, setBatchName] = useState("")
  const [_offerDiscount, _setOfferDiscount] = useState("")
  const [_oneShotDiscount, _setOneShotDiscount] = useState("")
  const [_meetingId, _setMeetingId] = useState("82906613084")
  const [_batchStartDate, _setBatchStartDate] = useState<any>("")
  const [_payable_start_date, _setpayable_start_date] = useState<any>("")
  const [_googleGroupEmail, _setGoogleGroupEmail] = useState<any>("manish.garg@bosscoderacademy.com")
  const [modules, setModules] = useState<any>({})
  const [showModules, setShowModules] = useState<any>([])
  const [allMentorshipTemplates, setAllMentorshipTemplates] = useState<any>([])
  const [selectedMentorshiptemplate, setSelectedMentorshipTemplate] = useState("")
  const [tracks, setTracks] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()

  const getAllTracksUseCase = new GetAllTracks(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))
  const createBatchUseCase = new CreateBatches(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))
  const getAllMentorshiptemplatesUseCase = new GetAllMentorshipTemplates(
    new BatchRepositoryImpl(new BatchAPIDataSourceImpl())
  )
  const getModuleBasedClassUseCase = new GetModuleBasedTrack(
    new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl())
  )
  const getAllExamTemplatesUseCase = new GetAllExamTemplates(new BatchRepositoryImpl(new BatchAPIDataSourceImpl()))

  function handleBatchName(e: any) {
    setBatchName(e.target.value)
  }

  function handleOneShotDiscount(e: any) {
    const input = e.target.value
    const discount = parseInt(input)
    if (isNaN(discount) || (discount >= 0 && discount <= 100)) {
      _setOneShotDiscount(e.target.value)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Invalid format!")
    }
  }

  function handleOfferDiscount(e: any) {
    const input = e.target.value
    const discount = parseInt(input)
    if (isNaN(discount) || (discount >= 0 && discount <= 100)) {
      _setOfferDiscount(e.target.value)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Invalid format!")
    }
  }

  function handleMeetingId(e: any) {
    _setMeetingId(e.target.value)
  }

  function handleGoogleGroupEmail(e: any) {
    _setGoogleGroupEmail(e.target.value)
  }

  function handleTrackChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const track = e.target.value
    setSelectedTracks((prev: string[]) => (prev.includes(track) ? prev : [...prev, track]))
    setCurrentSelectTrack(track)
  }

  function handleModuleChange(e: any) {
    const module = e.target.value
    setSelectedModules((prev: string[]) => (prev.includes(module) ? prev : [...prev, module]))
    setCurrentSelectModule(module)
  }
  function handleExamTemplateChange(e: any) {
    setSelectedExamtemplate(e.target.value)
  }

  function handleBatchStartDate(e: any) {
    _setBatchStartDate(e.target.value)
  }

  function handleMentorshipTemplateChange(e: any) {
    setSelectedMentorshipTemplate(e.target.value)
  }

  function handlePayable_start_date(e: any) {
    _setpayable_start_date(e.target.value)
  }

  async function getAllTracks() {
    const response = await getAllTracksUseCase.invoke(auth)

    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Unable to fetch tracks")
      changeToastVisibility(true)
      return
    }

    setTracks(response.data)
    response.data.forEach((track: string) => getModulesBasedOnTrack(track))
  }

  async function getAllMentorshipTemplates() {
    const response = await getAllMentorshiptemplatesUseCase.invoke(auth)

    if (response?.success) {
      setAllMentorshipTemplates(response?.data)
    }
  }

  async function getModulesBasedOnTrack(track: string) {
    const response = await getModuleBasedClassUseCase.invoke(auth, track)

    if (response.success) {
      setModules((prevModule: any) => ({
        ...prevModule,
        [track]: response.data.modules,
      }))
    }
  }
  async function getAllExamTemplates() {
    const response = await getAllExamTemplatesUseCase.invoke(auth)
    setAllExamTemplates(response?.data)
  }

  function removeHyphens(inputString: any) {
    return inputString.replace(/-/g, "")
  }

  async function createBatches() {
    if (!batchName || !_batchStartDate) {
      changeToastDetails(STR_FAILURE, "Complete the form details")
      changeToastVisibility(true)
      return
    }
    if (batchName.includes(" ")) {
      changeToastDetails(STR_FAILURE, "Space not allowed in batch name")
      changeToastVisibility(true)
      return
    }

    setLoading(true)

    const sortedModules = [...selectedModules].sort((a, b) =>
      a === "master_class" ? 1 : b === "master_class" ? -1 : 0
    )

    const response = await createBatchUseCase.invoke({
      id_token: auth.id_token.trim(),
      batch_name: batchName.trim(),
      start_date: removeHyphens(_batchStartDate),
      meeting_id: _meetingId.trim(),
      modules: sortedModules,
      tracks: selectedTracks,
      google_group_email: _googleGroupEmail.trim(),
      payable_start_date: removeHyphens(_payable_start_date),
      one_shot_payment_discount: _oneShotDiscount,
      offer_discount_in_percent: _offerDiscount,
      exam_template_name: selectedExamtemplate,
      mentorship_template_to_inherit: selectedMentorshiptemplate,
    })

    setLoading(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Batch created successfully!")
  }

  return {
    selectedTracks,
    _currentSelectTrack,
    batchName,
    _oneShotDiscount,
    _offerDiscount,
    _meetingId,
    _batchStartDate,
    modules,
    showModules,
    _currentSelectModule,
    selectedModules,
    _googleGroupEmail,
    _payable_start_date,
    toast,
    tracks,
    loading,

    allMentorshipTemplates,
    selectedMentorshiptemplate,
    setSelectedMentorshipTemplate,
    handleMentorshipTemplateChange,
    getAllMentorshipTemplates,
    setSelectedTracks,
    setShowModules,
    setSelectedModules,
    handleModuleChange,
    handleBatchName,
    handleGoogleGroupEmail,
    handlePayable_start_date,
    handleMeetingId,
    handleBatchStartDate,
    handleOfferDiscount,
    handleOneShotDiscount,
    setCurrentSelectModule,
    setCurrentSelectTrack,
    handleTrackChange,
    changeToastVisibility,
    getAllTracks,
    createBatches,
    allExamTemplates,
    setAllExamTemplates,
    handleExamTemplateChange,
    selectedExamtemplate,
    getAllExamTemplates,
    setSelectedExamtemplate,
  }
}
