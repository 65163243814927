import React from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import Toast from "core/components/Toast"
import { useInfluencerCompensationViewModel } from "./CompensiationViewModel"
import { isEmpty } from "core/utils/misc"

export default function InfluencerCompensation() {
  const { toast, Rates, getAllRates, influencer, changeToastVisibility, processAndSortCampaignData } =
    useInfluencerCompensationViewModel()
  React.useEffect(() => {
    getAllRates(influencer.email)
  }, [])
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="divide-y divide-new-neutral-light rounded-sm bg-new-solid-white shadow">
          <div className="p-6">
            <h2 className="text-2xl font-bold">Compensation Details</h2>
            {isEmpty(Rates) || isEmpty(Rates.data) ? (
              <p className="text-center text-gray-500 mt-6">No compensation details available.</p>
            ) : (
              processAndSortCampaignData(Rates).map((campaignData, index) => {
                const { campaign, dates } = campaignData

                return (
                  <div key={index} className="mb-6 border rounded-lg p-4 bg-gray-50 shadow">
                    <h3 className="text-lg font-semibold text-gray-800">{campaign}</h3>

                    <div className="mt-4 overflow-x-auto">
                      <table className="min-w-full border-collapse border border-gray-300">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                              S.No
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                              From - To
                            </th>
                            <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                              Rate
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dates.map((entry, idx) => {
                            const fromDate = entry.date
                            const toDate = idx + 1 < dates.length ? dates[idx + 1].date : "Ongoing"

                            return (
                              <tr key={idx}>
                                <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">{idx + 1}</td>
                                <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                                  {fromDate} to {toDate}
                                </td>
                                <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                                  ₹{String(entry.value)}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
