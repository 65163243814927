import { InfluencersDetails } from "domain/model/InfluencerDashboard"
import { InfluencerDashboardRepository } from "domain/repository/Admin/InfluencerDashboardRepository"
import { Auth } from "domain/model/Auth"

export interface GetAllInfluencersInfoUseCase {
  invoke(auth: Auth, details: InfluencersDetails): Promise<any>
}

export class GetInfluencersDetails implements GetAllInfluencersInfoUseCase {
  private repository: InfluencerDashboardRepository
  constructor(repository: InfluencerDashboardRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: InfluencersDetails): Promise<any> {
    return await this.repository.getAllInfluencersDetails(auth, details)
  }
}
