import jsPDF from "jspdf"
import IconButton from "core/components/IconButton"
import Spinner from "core/components/Spinner"
import Toggle from "core/components/Toggle"
import { API_MENTOR, STR_PAID, MONTH_INVOICE_DATA, MENTOR_INSTRUCTOR } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Button from "core/components/new/Button"
import { stringToAmount } from "core/utils/stringToAmount"
import axios from "axios"
import Modal from "core/components/Modal"
import { useApp } from "core/context/app"
import Cross from "assets/svgs/Cross"
import GetInstructorSessions from "domain/useCase/Mentor/Session/GetInstructorSessions"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"

import GetMentorSession from "domain/useCase/Mentor/Session/GetMentorSession"
import { Server } from "core/utils/axios"
import pn from "core/utils/pn"
import pdfMake from "core/lib/pdf"

const server = new Server()

export default function MonthlySessionTable({ role }: any) {
  const { page } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const TableHeader: string[] = ["Month", "Average Rating", "Total Sessions", "Amount", "View All Sessions", "Download"]
  const [loading, setLoading] = useState(false)
  const [Loading, setloading] = useState(false)

  const MonthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const { auth } = useAuth()
  const [searchYear, setSearchYear] = useState<string>(new Date().getFullYear().toString())
  const [searchYearInput, setSearchYearInput] = useState<string>(new Date().getFullYear().toString())

  function handleSearchYear(e: any) {
    if (e.target.value) setSearchYear(e.target.value)
    else setSearchYear("2024")
  }

  const [apiData, setApiData] = useState<any[]>([])
  const [year, setYear] = useState<string>(new Date().getFullYear().toString())
  const { id } = useParams()
  const navigate = useNavigate()

  const getInstructorSessionsUseCase = new GetInstructorSessions(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )
  const getMentorSessionsUseCase = new GetMentorSession(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))

  function handleSubmit() {
    setloading(true)
    setSearchYear(searchYearInput)
    setYear(searchYearInput)
    setloading(false)
  }

  const redirectToSessions = (month: string) => {
    navigate(`/mentor/invoicing-sessions/${role}/${month.toLocaleLowerCase()}`)
  }

  const fetchData = async () => {
    setLoading(true)
    setApiData([]) // Clear previous data

    const path = role === "mentor" ? "mentor" : "mentor/instructor"
    const res = await server.post(
      pn(path, MONTH_INVOICE_DATA),
      { searchYear },
      {
        Authorization: `Bearer ${auth.id_token}`,
      }
    )
    const data = res?.data
    const sortedData = data.sort((a: any, b: any) => b.year_month - a.year_month)
    setApiData(sortedData) // Set the API data to state
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [searchYear, role])
  const { mentor } = useApp()
  function convertTimestampToDate(timestamp: any) {
    const date = new Date(timestamp * 1000)
    const options: any = { year: "numeric", month: "long", day: "numeric" }
    return date.toLocaleDateString(undefined, options)
  }

  const getFiscalYear = (monthName: string, year: any) => {
    const currentYear = year
    const fiscalYearStartMonth = 4
    const monthNumber = new Date(`${monthName} 1`).getMonth() + 1

    if (monthNumber >= fiscalYearStartMonth) {
      return `${currentYear}-${currentYear + 1}`
    }
    return `${currentYear - 1}-${currentYear}`
  }

  const getMonthRange = (month: string, data: any) => {
    const yearMonth = String(data.year_month)
    let year = parseInt(yearMonth.slice(0, 4), 10)
    let startDate = `1st ${capitalizeFirstLetter(month)}`
    let endDate = ""

    const monthsWith30Days = ["april", "june", "september", "november"]
    const monthsWith31Days = ["january", "march", "may", "july", "august", "october", "december"]

    if (month.toLowerCase() === "february") {
      endDate = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? `29th February` : `28th February`
    } else if (monthsWith30Days.includes(month.toLowerCase())) {
      endDate = `30th ${capitalizeFirstLetter(month)}`
    } else if (monthsWith31Days.includes(month.toLowerCase())) {
      endDate = `31st ${capitalizeFirstLetter(month)}`
    }

    return `${startDate} to ${endDate} (FY ${getFiscalYear(month, year)})`
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const getCurrentYear = () => new Date().getFullYear()

  const formatCurrency = (amount: number) => `Rs.${parseInt(amount.toString(), 10).toLocaleString()}`

  const generateMentorDetails = (mentor: any) => [
    { text: mentor.beneficiary_name, style: "mentorHeader", margin: [0, 20, 0, 0] },
    { text: mentor.address || "Address along with Pincode", style: "subText" },
    { text: mentor.email, style: "subText" },
  ]

  const generateInvoiceDetails = (data: any) => {
    const yearMonth = String(data.year_month)
    let year = parseInt(yearMonth.slice(0, 4), 10)
    let month = parseInt(yearMonth.slice(4, 6), 10)

    month += 1
    if (month > 12) {
      month = 1
      year += 1
    }

    const formattedMonth = String(month).padStart(2, "0")
    let formattedDate
    if (formattedMonth == "04") {
      formattedDate = `25/03/${year}`
    } else {
      formattedDate = `01/${formattedMonth}/${year}`
    }

    return [
      {
        text: `Invoice ID: ${data.invoice_id}`,
        style: "invoiceDetails",
        alignment: "right",
        margin: [0, 20, 0, 0],
      },
      {
        text: `Date: ${formattedDate}`,
        style: "invoiceDetails",
        alignment: "right",
      },
    ]
  }

  const generateBankDetails = (mentor: any) => [
    [
      { text: "Account holder name:", bold: true, margin: [0, 2, 0, 2] },
      { text: mentor.beneficiary_name || "Your Beneficiary Name", margin: [0, 2, 0, 2] },
    ],
    [
      { text: "IFSC:", bold: true, margin: [0, 2, 0, 2] },
      { text: mentor.IFSC || "Your IFSC Code", margin: [0, 2, 0, 2] },
    ],
    [
      { text: "Account Number:", bold: true, margin: [0, 2, 0, 2] },
      { text: mentor.account_number || "Your Account Number", margin: [0, 2, 0, 2] },
    ],
  ]

  const generatePDFInvoice = (data: any, monthName: string) => {
    monthName = monthName.toLowerCase()

    const docDefinition: any = {
      content: [
        {
          canvas: [{ type: "rect", x: 0, y: 0, w: 595, h: 110, color: "#1F2937" }],
          absolutePosition: { x: 0, y: 0 },
        },
        { text: "Invoice", style: "header", margin: [0, 10, 0, 10] },

        // Mentor Details and Invoice Details
        {
          columns: [generateMentorDetails(mentor.mentor), generateInvoiceDetails(data)],
          margin: [0, 20, 0, 20],
        },

        { text: "To:", style: "subheader", margin: [0, 20, 0, 0] },
        { text: "Bosscoder Software Services Private Limited", style: "receiverDetails" },
        { text: "E-401, Dasnac, The Jewel,", style: "receiverDetails" },
        { text: "Sec-75, Noida", style: "receiverDetails", margin: [0, 0, 0, 20] },

        {
          table: {
            headerRows: 1,
            widths: ["auto", "*", "auto", "auto"],
            body: [
              [
                { text: "Sr.", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
                { text: "No. of hours", style: "tableHeader" },
                { text: "Value in INR", style: "tableHeader" },
              ],
              [
                { text: "1", style: "tableBody" },
                {
                  text: [
                    `${role === "mentor" ? "Mentor Session" : "Instructor Session"} Professional Fee\n`,
                    { text: getMonthRange(monthName, data), fontSize: 12, color: "#4B5563" },
                  ],
                  style: "tableBody",
                },
                { text: data.total_hours.toString(), style: "tableBody" },
                { text: formatCurrency(data.total_amount), style: "tableBody" },
              ],
              [
                { text: "", style: "tableBody" },
                { text: "Total", style: "tableTotal", alignment: "right" },
                { text: "", style: "tableBody" },
                { text: formatCurrency(data.total_amount), style: "tableTotal", alignment: "right", color: "black" },
              ],
            ],
          },
          layout: {
            fillColor: (rowIndex: number) => (rowIndex === 0 ? "#E5E7EB" : null),
          },
          margin: [0, 20, 0, 20],
        },

        {
          text: "Please transfer the invoice amount to the following bank account:",
          fontSize: 16,
          margin: [0, 30, 0, 20],
        },
        {
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            fillColor: "#F3F4F6",
            borderColor: "#D1D5DB",
          },
          table: { widths: ["auto", "*"], body: generateBankDetails(mentor.mentor) },
          margin: [0, 0, 0, 30],
        },
      ],
      styles: {
        header: { fontSize: 32, bold: true, color: "#F9FAFB", alignment: "center" },
        mentorHeader: { fontSize: 18, bold: true, color: "#1F2937" },
        subheader: { fontSize: 16, bold: true, color: "#374151" },
        invoiceDetails: { fontSize: 14, color: "#6B7280" },
        subText: { fontSize: 14, color: "#4B5563" },
        receiverDetails: { fontSize: 14, color: "#4B5563" },
        tableHeader: { bold: true, fontSize: 14, color: "#1F2937", fillColor: "#F3F4F6" },
        tableBody: { fontSize: 14, color: "#1F2937" },
        tableTotal: { bold: true, fontSize: 18, color: "#000000" },
      },
    }

    pdfMake.createPdf(docDefinition).download(`Invoice_${monthName}.pdf`)
  }

  function getFirstDateOfMonthTimestamp(yearMonth: string) {
    const year = parseInt(yearMonth.slice(0, 4), 10)
    const monthIndex = parseInt(yearMonth.slice(4), 10) - 1
    const date = new Date(year, monthIndex, 1)
    const timestamp = Math.floor(date.getTime() / 1000)
    return timestamp
  }

  const [sessions, setSessions] = useState([])
  const [loadingSessions, setLoadingSessions] = useState(false)

  const [totalSessions, setTotalSessions] = useState(0)
  const [totalCompletedSessions, setTotalCompletedSessions] = useState(0)
  const [sessionDetailLoading, setSessionDetailsLoading] = useState(false)
  const [sessionTaken, setSessionTaken] = useState(0)

  const getAllSessions = async (time_given: any) => {
    setSessionDetailsLoading(true)
    const time_giving = getFirstDateOfMonthTimestamp(time_given.toLocaleLowerCase())
    try {
      setLoadingSessions(true)
      const res =
        role === "instructor" ? await fetchInstructorSessions(time_giving) : await fetchMentorSession(time_giving)
      const data = await res.data
      setSessions(data.data)
      setTotalSessions(data.data.length)
      setTotalCompletedSessions(data.data.filter((session: any) => session.duration > 0).length)
      setLoadingSessions(false)
      calculateSessionStats(data.data)
      setSessionDetailsLoading(false)
    } catch (error) {
      console.error("Error fetching sessions:", error)
      setLoadingSessions(false)
    }
  }

  const fetchInstructorSessions = async (month: number, batch: string = "", module: string = "") => {
    const response = await getInstructorSessionsUseCase.invoke(auth, month, batch, module)
    return response
  }

  const fetchMentorSession = async (month: number, batch: string = "", module: string = "") => {
    const response = await getMentorSessionsUseCase.invoke(auth, month, batch, module)
    return response
  }

  const handleDownload = (data: any, monthName: string) => {
    generatePDFInvoice(data, monthName)
    setSessionDetailsLoading(false)
  }
  const [totalDuration, setTotalDuration] = useState(0)
  const [canceledSessions, setCanceledSessions] = useState(0)
  const [takenSessions, setTakenSessions] = useState(0)
  const [canceledOnTime, setCanceledOnTime] = useState(0)
  const [canceledLastMin, setCanceledLastMin] = useState(0)
  const [canceledPostTime, setCanceledPostTime] = useState(0)
  const [substitutedSessions, setSubstitutedSessions] = useState(0)
  const [menteeNotJoined, setMenteeNotJoined] = useState(0)
  const [mentorNotJoined, setMentorNotJoined] = useState(0)
  const [cancelledByMentee, setCancelledByMentee] = useState(0)
  const [cancelledByMentor, setCancelledByMentor] = useState(0)
  const [invoiceMonth, setInvoiceMonth] = useState("")
  const [newInvoiceData, setNewInvoiceData] = useState<any>()

  function calculateSessionStats(sessions: any) {
    let totalDuration = 0
    let takenSessions = 0
    let canceledOnTime = 0
    let canceledPostTime = 0
    let substitutedSessions = 0
    let menteeNotJoined = 0
    let mentorNotJoined = 0
    let cancelledByMentor = 0
    let cancelledByMentee = 0
    let cancelledLastMin = 0

    sessions.forEach((session: any) => {
      if (page === "mentor-invoice") {
        totalDuration += session.end_timestamp - session.start_timestamp
      } else {
        totalDuration += session.duration
      }
      switch (session.status) {
        case "Class Taken":
        case "Session Taken":
        case "Completed":
          takenSessions++
          break
        case "Cancelled On Time":
          canceledOnTime++
          break
        case "Cancelled Post Time":
          canceledPostTime++
          break
        case "Substituted Session":
          substitutedSessions++
          break
        case "Mentee Not Joined":
        case "Mentee Absent":
          menteeNotJoined++
          break
        case "Mentor Not Joined":
        case "Mentor Absent":
          mentorNotJoined++
          break
        case "Cancelled by Mentor":
          cancelledByMentor++
          break
        case "Cancelled by Mentee":
          cancelledByMentee++
          break
        case "Cancelled Last Min":
          cancelledLastMin++
          break
        default:
          break
      }
    })

    let totalHours = totalDuration / 3600
    setTotalDuration(totalHours)
    setTakenSessions(takenSessions)
    setCanceledOnTime(canceledOnTime)
    setCanceledPostTime(canceledPostTime)
    setCanceledLastMin(cancelledLastMin)
    setSubstitutedSessions(substitutedSessions)
    setMenteeNotJoined(menteeNotJoined)
    setMentorNotJoined(menteeNotJoined)
    setCancelledByMentee(cancelledByMentee)
    setCancelledByMentor(cancelledByMentor)
  }

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex gap-x-10">
        <input
          type="text"
          placeholder="Search by year..."
          value={searchYearInput}
          onChange={(e) => setSearchYearInput(e.target.value)}
        />
        <Button loading={Loading} className="w-fit" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <div className="relative overflow-x-auto shadow-md border-[0.4px] border-[#ADADAD] rounded-sm">
        <table className="w-full text-left text-sm text-new-neutral-dark">
          <thead className="bg-new-neutral-dark text-new-solid-white">
            <tr>
              {TableHeader?.map((title: any, index: any) => (
                <th key={index} className="whitespace-nowrap px-4 py-4 text-[16px] font-medium ">
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={6} className="py-4">
                  <div className="flex justify-center items-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="font-montserrat">
              {apiData.length > 0 ? (
                apiData.map((data: any, index: any) => (
                  <tr className="bg-new-solid-white even:bg-[#F5F5F5]" key={index}>
                    <td className="px-6 py-4 text-base font-normal leading-[160%]">
                      <p>{MonthName[data.year_month.toString().slice(4) - 1]}</p>
                    </td>
                    <td
                      className={`px-6 py-4 text-[16px] font-semibold ${
                        data.average_rating <= 4.4
                          ? data.average_rating <= 3
                            ? "text-red-500"
                            : "text-yellow-300"
                          : "text-green-500"
                      }`}
                    >
                      {data.average_rating !== -1 ? `${data.average_rating} out of 5` : "Not Rated"}
                    </td>
                    <td className="px-6 text-[var(--Primary-color-BS1, #414141)] font-montserrat text-base font-normal leading-[22.4px]">
                      {data.number_of_classes}
                    </td>
                    <td className="py-4 text-[#414141] text-center font-montserrat text-base font-medium leading-normal">
                      <p className="flex justify-start px-5 text-[#414141] text-center font-montserrat text-base font-medium leading-normal">
                        {" "}
                        {parseInt(data.total_amount ? data.total_amount : 0, 10)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </td>
                    <td className="px-4 text-[16px]">
                      <p className="flex justify-start px-4">
                        <Button
                          onClick={() => redirectToSessions(data.year_month.toString())}
                          outlined
                          className="w-[90px]"
                        >
                          View
                        </Button>
                      </p>
                    </td>
                    <td className="px-2 py-4 text-[16px] w-6">
                      <p className="flex justify-start">
                        <Button
                          outlined
                          onClick={() => {
                            setIsModalOpen(true)
                            setNewInvoiceData(data)
                            setInvoiceMonth(MonthName[data.year_month.toString().slice(4) - 1])
                            getAllSessions(data.year_month.toString())
                          }}
                        >
                          Download
                        </Button>
                        <Modal open={isModalOpen} className="min-h-[552px]">
                          {sessionDetailLoading ? (
                            <div className="flex justify-center items-center">
                              <Spinner />
                            </div>
                          ) : (
                            <div className="relative p-6">
                              <div className="flex flex-row justify-between mb-4">
                                <h2 className="text-lg font-bold">Session Details</h2>
                                <button
                                  className=" text-gray-500 hover:text-gray-700"
                                  onClick={() => setIsModalOpen(false)}
                                >
                                  <Cross></Cross>
                                </button>
                              </div>
                              {/* <p className="mb-2">
                              <strong>Total Duration:</strong> {totalDuration.toFixed(2)} hours
                            </p> */}
                              <p className="mb-2">
                                <strong>Total Sessions:</strong> {totalSessions}
                              </p>
                              <p className="mb-2">
                                <strong>Taken Sessions:</strong> {totalCompletedSessions}
                              </p>
                              <p className="mb-2">
                                <strong>Cancelled Last Min:</strong> {canceledLastMin}
                              </p>
                              <p className="mb-2">
                                <strong>{role === "mentor" ? "Mentor not joined: " : "Cancelled on Time:"}</strong>{" "}
                                {role === "mentor" ? mentorNotJoined : canceledOnTime}
                              </p>

                              <p className="mb-2">
                                <strong>{role === "mentor" ? "Mentee not joined: " : "Cancelled Post Time:"}</strong>{" "}
                                {role === "mentor" ? menteeNotJoined : canceledPostTime}
                              </p>
                              {role === "mentor" && (
                                <>
                                  <p className="mb-2">
                                    <strong>Cancelled by Mentee:</strong> {cancelledByMentee}
                                  </p>
                                  <p className="mb-2">
                                    <strong>Cancelled by Mentor:</strong> {cancelledByMentor}
                                  </p>
                                </>
                              )}
                              <Button
                                onClick={() => {
                                  handleDownload(newInvoiceData, invoiceMonth)
                                }}
                                className="mt-4 round-[4px]"
                              >
                                Download Invoice
                              </Button>
                            </div>
                          )}
                        </Modal>
                      </p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center py-4">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}
