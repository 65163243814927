import { CrossIcon } from "core/constants/svgs"
import React, { useState } from "react"
import MentorSchedule from "./MentorSchedule"
import { useApp } from "core/context/app"
import { formatDate } from "core/utils/date"
import NewMentorCalendar from "./NewMentorCalendar"
import MentorshipCard from "./MentorshipCard"
import { STR_MENTOR_NOT_AVAILABLE } from "core/constants/strings"
import NewMentorshipCard from "./NewMentorShipCard"
import StandardSelect from "core/components/v2/StandardSelect"
import Spinner from "core/components/Spinner"
import TempMentorCard from "./TempMentorCard"
import { cn } from "core/lib/utils"
import { Button } from "core/components/v2/Button"
import ArrowGoBack from "assets/svgs/ArrowGoBack"
const header = ["S", "M", "T", "W", "Th", "F", "Sa"]

const BookSlotModal = ({
  freezeData,
  freezeSession,
  isOpen,
  onClose,
  availability,
  mentor,
  isTemp,
  onBooking,
  mentorSessionDetails,
  setBookingDetails,
  setConfirmSessionIndex,
  loading,
  setConfirmModal,
  timer,
  setTimer,
  isTimerRunning,
  setIsTimerRunning,
}: any) => {
  if (!isOpen) return null
  const [daysList, setDaysList] = React.useState<any>([])
  const [activeDate, setActiveDate] = React.useState(new Date())
  const [selectedSession, setSelectedSession] = useState(null)
  const [lastMonth, setLastMonth] = React.useState<any>({
    lastDate: new Date(activeDate.getFullYear(), activeDate.getMonth(), 0),
  })
  const [activeMonth, setActiveMonth] = React.useState<any>({
    firstDate: new Date(activeDate.getFullYear(), activeDate.getMonth(), 1),
    lastDate: new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0),
  })
  const [nextMonth, setNextMonth] = React.useState<any>({
    firstDate: new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1),
  })
  const [mentorSessions, setMentorSessions] = React.useState<any>([])
  const [isModalOpen, setModalOpen] = React.useState(false)

  function getAllDaysInCalendar() {
    let days: any[] = []
    for (let i = activeMonth.firstDate.getDay() - 1; i >= 0; i--) {
      days = [
        ...days,
        {
          date: formatDate(
            new Date(
              lastMonth.lastDate.getFullYear(),
              lastMonth.lastDate.getMonth(),
              lastMonth.lastDate.getDate() - i + 1
            )
          ),
        },
      ]
    }
    for (let i = 1; i <= activeMonth.lastDate.getDate(); i++) {
      let formattedDate = formatDate(
        new Date(
          activeMonth.firstDate.getFullYear(),
          activeMonth.firstDate.getMonth(),
          activeMonth.firstDate.getDate() + i
        )
      )
      days = [
        ...days,
        {
          date: formattedDate,
          isActiveDate: formattedDate === formatDate(new Date(activeDate)),
          isCurrentMonth: true,
          sessions: [],
        },
      ]
      if (formattedDate === formatDate(new Date(activeDate))) handleMentorSessions(formattedDate)
    }
    for (let i = 0; i < 6 - activeMonth.lastDate.getDay(); i++) {
      days = [
        ...days,
        {
          date: formatDate(
            new Date(
              nextMonth.firstDate.getFullYear(),
              nextMonth.firstDate.getMonth(),
              nextMonth.firstDate.getDate() + i + 1
            )
          ),
        },
      ]
    }
    for (let i = 0; i < availability.length; i++) {
      if (new Date(availability[i].date).getMonth() !== activeDate.getMonth()) {
        continue
      }
      const index = days.findIndex((day: any) => day.date === formatDate(new Date(availability[i].date)))
      if (index !== -1) {
        days[index] = {
          ...days[index],
          sessions: [
            ...days[index].sessions,
            {
              start_timestamp: availability[i].start_timestamp * 1000,
              end_timestamp: availability[i].end_timestamp * 1000,
              date: availability[i].date,
              start: availability[i].start,
              end: availability[i].end,
            },
          ],
        }
      }
    }
    setDaysList(days)
    if (availability.length === 0) setMentorSessions([])
  }
  function handleMentorSessions(date: string) {
    let index = daysList.findIndex((day: any) => day.date === date)
    const sessions = daysList[index]?.sessions
    setMentorSessions(sessions)
    console.log(daysList, "yyy")
    setDaysList((daysList: any) =>
      daysList.map((day: any) => {
        return day.date === date ? { ...day, isActiveDate: true } : { ...day, isActiveDate: false }
      })
    )
    // setDaysList((daysList: any) =>
    //   daysList.map((day: any) => {
    //     // console.log("day", day.date)
    //     const formattedDate = new Date().toISOString().split("T")[0]
    //     // console.log(formattedDate)
    //     return day.date === formattedDate ? { ...day, isCurrentDate: true } : { ...day, isCurrentDate: false }
    //   })
    // )
  }

  function handlePrevMonth() {
    let updatedDate = new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1)
    handleSetMonths(updatedDate)
  }

  function handleNextMonth() {
    let updatedDate = new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1)
    handleSetMonths(updatedDate)
  }

  function handleSetMonths(updatedDate: Date) {
    setActiveDate(updatedDate)
    setLastMonth({
      lastDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth(), 0),
    })
    setActiveMonth({
      firstDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth(), 1),
      lastDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 0),
    })
    setNextMonth({
      firstDate: new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1, 1),
    })
  }

  React.useEffect(() => {
    getAllDaysInCalendar()
  }, [activeDate, availability])

  console.log("Availabilty is here")
  console.log(availability)
  return (
    <div className="relative z-40 ">
      {/* Blur Overlay */}
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.75)] backdrop-blur-[2px] z-0 "></div>

      {/* Main Content */}
      <div className="fixed inset-0 z-10 flex min-h-screen items-center justify-center">
        <div
          className={cn(
            "bg-white w-[650px] p-[24px] rounded-[8px] shadow-[-1px_1px_4px_0px_rgba(34,41,48,0.20)]",
            isTemp ? "h-[540px]" : "h-[521px]"
          )}
        >
          <div className="border-[0.5px] boder-[#D3D3D3] rounded-[8px] px-[24px] py-[16px] mb-[24px]">
            <div className="flex justify-between">
              <div className="space-y-1">
                <p className="text-[#333] text-[16px] font-[600]">
                  {isTemp ? "Available Slots" : "Schedule Mentorship"}
                </p>
                <p className="text-[14px] font-[400]">
                  {isTemp ? "Slots available for your temporary mentor." : "Book all your mentor sessions hassle free!"}
                </p>
              </div>
              {isTemp ? (
                <Button onClick={onClose} variant={"cancel"} border={"thin"} className="flex gap-[4px]">
                  <ArrowGoBack className="size-[16px]" /> <span>Back</span>
                </Button>
              ) : (
                <button className="hover:bg-slate-200 rounded-[4px] size-[24px]" onClick={onClose}>
                  <CrossIcon className="size-[24px]" />
                </button>
              )}
            </div>
          </div>

          {isTemp && <TempMentorCard temp_mentor={mentor} isTemp={isTemp} />}

          <div>
            <div className={`relative z-0 `}>
              <div className={`rounded-[8px] border-[1px] border-[#D3D3D3] shadow`}>
                <div className="flex  gap-[8px] h-[260px]">
                  <div className="w-1/2 p-[16px] ">
                    <NewMentorCalendar
                      header={header}
                      days={daysList}
                      setSelectedSession={setSelectedSession}
                      activeDate={activeDate}
                      setActiveDate={setActiveDate}
                      selectedSession={selectedSession}
                      onClick={handleMentorSessions}
                      onPrevMonth={handlePrevMonth}
                      onNextMonth={handleNextMonth}
                      disabled={availability?.length === 0}
                      loading={loading}
                    />
                  </div>

                  <div className="relative h-full w-1/2 p-[16px] border-l-[0.5px] border-[#D3D3D3]">
                    <p className="text-[12px] font-[600] text-[#646464]">Available Time Slots</p>

                    <div>
                      {mentorSessions?.length > 0 ? (
                        <NewMentorshipCard
                          // key={i}
                          freezeData={freezeData}
                          timer={timer}
                          setTimer={setTimer}
                          isTimerRunning={isTimerRunning}
                          setIsTimerRunning={setIsTimerRunning}
                          freezeMentorSlot={freezeSession}
                          setConfirmModal={setConfirmModal}
                          mentorSessions={mentorSessions}
                          selectedSession={selectedSession}
                          isTemp={isTemp}
                          setSelectedSession={setSelectedSession}
                          session={mentorSessions[0]}
                          mentor={mentor}
                          onClick={() => setModalOpen(true)}
                          mentorSessionDetails={mentorSessionDetails}
                          setBookingDetails={setBookingDetails}
                          setConfirmSessionIndex={setConfirmSessionIndex}
                        />
                      ) : loading ? (
                        <div className="flex flex-col justify-center items-center my-[100px] gap-[6px]">
                          <p className="m-auto">Loading mentor availability...</p>
                          <Spinner small={true} />
                        </div>
                      ) : (
                        <p className="m-auto my-[100px] text-center">{STR_MENTOR_NOT_AVAILABLE}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isTemp && (
            <div className="my-[24px]">
              <p className="text-[#333] text-[16px] font-[600]">Agenda of the meeting:</p>
              <div className="my-[6px] p-[12px] bg-[#F9F9F9] rounded-[4px] text-[14px] font-[400] overflow-y-auto">
                {mentorSessionDetails?.agenda}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BookSlotModal
