import { Auth } from "domain/model/Auth"
import pn from "core/utils/pn"
import { postAPI, postAPIForFiles } from "core/utils/axios"
import PaymentAdminDataSource from "data/dataSource/Admin/PaymentAdmin"
import {
  API_CREATE_PAYMENT_ADMIN,
  API_UPDATE_PAYMENT_OPTIONS,
  API_V4_ADMIN,
  API_PAYMENT_DETAIL_ADMIN,
  API_GET_STUDENT_EMI,
  API_GET_PAYMENT_DOCUMENTS,
  API_UPDATE_PAYMENT_DOCUMENT,
  API_UPLOAD_PAYMENT_DOCUMENT,
  API_UPDATE_TRANSACTION_ADMIN,
  API_DOCUMENT_VERIFIED,
  API_GET_PAYMENT_METHODS,
  API_UPDATE_PAYMENT_METHOD,
  STR_FAILURE,
} from "core/constants/strings"
import { TPaymentDocumentItem } from "domain/model/Payment"
import { genError } from "core/utils/string"

export default class PaymentAdminAPIDataSourceImpl implements PaymentAdminDataSource {
  async updatePaymentOptionsMenu(auth: Auth, student_email: string, option: boolean) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_PAYMENT_OPTIONS), {
        id_token: auth?.id_token,
        student_email: student_email,
        option: option,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async updatepaymentMethod(auth: Auth, student_email: string, method: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_PAYMENT_METHOD), {
        id_token: auth?.id_token,
        student_email: student_email,
        method: method,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async createPayment(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_PAYMENT_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async documentVerified(auth: Auth, student_email: string, status: boolean) {
    try {
      await postAPI(pn(API_V4_ADMIN, API_DOCUMENT_VERIFIED), {
        id_token: auth?.id_token,
        student_email,
        status: status,
      })
    } catch (err) {
      console.error(err)
    }
  }
  async getPaymentDetailAdmin(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_PAYMENT_DETAIL_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentEMI(auth: Auth, student_email: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_STUDENT_EMI), {
        id_token: auth?.id_token,
        student_email,
      })
      return response
    } catch (error) {
      changeToastDetails(STR_FAILURE, genError(error))
      changeToastVisibility(true)
      return error
    }
  }

  async getPaymentDocuments(auth: Auth, student_email: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_PAYMENT_DOCUMENTS), {
        id_token: auth?.id_token,
        student_email,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getPaymentMethods(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_PAYMENT_METHODS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updatePaymentDocument(auth: Auth, student_email: string, document: TPaymentDocumentItem, id: number) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_PAYMENT_DOCUMENT), {
        id_token: auth?.id_token,
        student_email,
        document,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async uploadPaymentDocument(auth: Auth, student_email: string, file: File, filename: string, id: number) {
    try {
      let data = new FormData()
      data.append("id_token", auth?.id_token)
      data.append("file", file)
      data.append("filename", filename)
      data.append("student_email", student_email)
      data.append("documentId", id.toString()) // Convert id to string
      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_UPLOAD_PAYMENT_DOCUMENT), data)
      return response
    } catch (error) {
      return error
    }
  }

  async updateTransactionDetailAdmin(auth: Auth, paymentData: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_TRANSACTION_ADMIN), {
        id_token: auth?.id_token,
        ...paymentData,
      })
      return response
    } catch (error) {
      return error
    }
  }
}
function changeToastDetails(STR_SUCCESS: any, arg1: string) {
  throw new Error("Function not implemented.")
}

function changeToastVisibility(arg0: boolean) {
  throw new Error("Function not implemented.")
}
