import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface GetStudentExamDetailsUseCase {
  invoke(auth: Auth, exam_id: string, batch_id?: string): Promise<any>
}

export default class GetStudentExamDetails implements GetStudentExamDetailsUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, exam_id: string, batch_id?: string) {
    return this.repository.getStudentExamDetails(auth, exam_id, batch_id)
  }
}
