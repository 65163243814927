import { STR_MENTOR_SESSION } from "core/constants/strings"
import { format } from "date-fns"
import Avatar from "react-avatar"
import { cn } from "core/lib/utils"
import useTimer from "core/hooks/useTimer"
import { Button } from "core/components/v2/Button"
import HomePageGradient from "core/components/new/HomePageGradient"

export default function MentorSessionCard({ session, onJoin }: any) {
  const [timer] = useTimer(session?.start_timestamp * 1000)
  const minsLeft = (session?.start_timestamp * 1000 - Date.now()) / 1000 / 60
  return (
    <div className="relative border-[0.5px] rounded-[8px] py-[16px] px-[24px] overflow-hidden">
      <div className="absolute top-0 left-[200px]">
        <HomePageGradient className={"#E5F1C3"} />
      </div>
      <div className="absolute top-0 left-[280px]">
        <HomePageGradient className={"#E5F1C3"} />
      </div>

      <div className="w-full">
        <div className="flex justify-between w-full">
          <p className="text-[#333] text-[14px] font-[600] ">Mentor Session</p>
          <p className="text-[#DC2626] text-[14px] font-[600]">{timer}</p>
        </div>
        <div className="my-[4px]">
          <p className="text-[12px] font-[400] text-[#646464] leading-4">{session?.mentor_name}</p>
          <p className="text-[12px] font-[400] text-[#646464] leading-4">
            {session?.start_timestamp ? format(new Date(session?.start_timestamp * 1000), "do MMMM, hh:mm a") : ""}
          </p>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-end justify-between">
        {/* <a
          className={cn(minsLeft > 60 && "pointer-events-none")}
          href={session?.meet_link}
          target="_blank"
          rel="noreferrer"
        > */}
        <Button
          onClick={() => onJoin(session)}
          variant={"primary"}
          disabled={minsLeft > 10}
          className="w-[142px] h-[35px] z-20"
        >
          Join Meet
        </Button>
        {/* </a> */}
      </div>
    </div>
  )
}
