import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Admin/ContestRepository"

export interface updateExamTemplateUseCase {
  invoke(auth: Auth, template_id: string, name: string, updates: string[]): Promise<any>
}

export class UpdateExamTemplate implements updateExamTemplateUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, template_id: string, name: string, updates: string[]) {
    return await this.repository.updateExamTemplate(auth, template_id, name, updates)
  }
}
