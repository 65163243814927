import { Auth } from "domain/model/Auth"
import { InstructorAvailability } from "domain/model/Mentor"
import { AvailabilityRepository } from "domain/repository/Mentor/AvailabilityRepository"
import { InstructorAvailabilityRepository } from "domain/repository/Mentor/InstructorAvailabilityRespository"

export interface UpdateInstructorAvailabilityUseCase {
  invoke(auth: Auth, data: any): Promise<any>
}

export class UpdateInstructorAvailability implements UpdateInstructorAvailabilityUseCase {
  private repository: InstructorAvailabilityRepository

  constructor(repository: InstructorAvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, date: InstructorAvailability) {
    return await this.repository.updateInstructorAvailability(auth, date)
  }
}
