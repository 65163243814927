import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface GetLiveContestsUseCase {
  invoke(auth: Auth, page: number, limit: number): Promise<any>
}

export default class GetLiveContests implements GetLiveContestsUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, page: number, limit: number) {
    return this.repository.getLiveContests(auth, page, limit)
  }
}
