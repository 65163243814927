import { Auth } from "domain/model/Auth"

import { InstructorAvailabilityRepository } from "domain/repository/Admin/InstructorAvailabilityRespository"

export interface GetInstructorAvailabilityUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetInstructorAvailability implements GetInstructorAvailabilityUseCase {
  private repository: InstructorAvailabilityRepository

  constructor(repository: InstructorAvailabilityRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth) {
    return await this.repository.getInstructorAvailability(auth)
  }
}
