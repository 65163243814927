import pdfMake from "core/lib/pdf"

const influencerInvoice = (props: any) => {
  const MonthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const formatCurrency = (amount: any) => `Rs.${parseInt(amount, 10).toLocaleString()}`

  const getFiscalYear = (monthName: any, year: any) => {
    const currentYear = year as number
    const fiscalYearStartMonth = 4 // April
    const monthNumber = new Date(`${monthName} 1`).getMonth() + 1
    const nextYear = currentYear - 1 + 2
    if (monthNumber >= fiscalYearStartMonth) {
      return `${currentYear}-${nextYear}`
    }
    return `${currentYear - 1}-${currentYear}`
  }

  const getMonthRange = (monthNumber: number, year: number) => {
    let month = MonthName[monthNumber - 1]
    let startDate = `1st ${capitalizeFirstLetter(month)}`
    let endDate = ""
    const monthsWith30Days = ["april", "june", "september", "november"]
    const monthsWith31Days = ["january", "march", "may", "july", "august", "october", "december"]
    if (month.toLowerCase() === "february") {
      endDate = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? `29th February` : `28th February`
    } else if (monthsWith30Days.includes(month.toLowerCase())) {
      endDate = `30th ${capitalizeFirstLetter(month)}`
    } else if (monthsWith31Days.includes(month.toLowerCase())) {
      endDate = `31st ${capitalizeFirstLetter(month)}`
    }
    return `${startDate} to ${endDate} (FY ${getFiscalYear(month, year)})`
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const generatePDFInvoice = () => {
    const docDefinition: any = {
      content: [
        {
          canvas: [{ type: "rect", x: 0, y: 0, w: 595, h: 110, color: "#1F2937" }],
          absolutePosition: { x: 0, y: 0 },
        },
        { text: "Invoice", style: "header", margin: [0, 10, 0, 10] },
        {
          columns: [
            [
              { text: props.benificiary_name, style: "mentorHeader", margin: [0, 20, 0, 0] },
              { text: props.address || "Address along with Pincode", style: "subText" },
              { text: props.email, style: "subText" },
              { text: props.phone, style: "subText" },
            ],
            [
              {
                text: `Invoice ID: ${props.email}_${props.month}_${props.year}`,
                style: "invoiceDetails",
                alignment: "right",
                margin: [0, 20, 0, 0],
              },
              { text: `Date: 01-${props.month}-${props.year}`, style: "invoiceDetails", alignment: "right" },
            ],
          ],
          margin: [0, 20, 0, 20],
        },

        { text: "To :", style: "subheader", margin: [0, 30, 0, 0] },
        { text: "Bosscoder Software Services Private Limited", style: "receiverDetails" },
        { text: "E-401, Dasnac, The Jewel,", style: "receiverDetails" },
        { text: "Sec-75, Noida", style: "receiverDetails", margin: [0, 0, 0, 20] },

        {
          table: {
            headerRows: 1,
            widths: ["auto", "*", "auto", "auto"],
            body: [
              [
                { text: "Sr.", style: "tableHeader" },
                { text: "Description", style: "tableHeader" },
                { text: "Qty", style: "tableHeader" },
                { text: "Value in INR", style: "tableHeader" },
              ],
              [
                { text: "1.", style: "tableBody" },
                {
                  text: [
                    `Influencer Marketing Fees \n`,
                    { text: getMonthRange(props.month, props.year), fontSize: 12, color: "#4B5563" },
                  ],
                  style: "tableBody",
                },
                { text: props.posts.toString(), style: "tableBody" },
                { text: formatCurrency(props.amount), style: "tableBody" },
              ],
              [
                { text: "", style: "tableBody" },
                { text: "Total", style: "tableTotal", alignment: "right" },
                { text: "", style: "tableBody" },
                {
                  text: formatCurrency(props.amount),
                  style: "tableTotal",
                  alignment: "right",
                  color: "black",
                },
              ],
            ],
          },
          layout: {
            fillColor: (rowIndex: any) => (rowIndex === 0 ? "#E5E7EB" : null),
          },
          margin: [0, 20, 0, 20],
        },

        {
          text: "Please transfer the invoice amount to the following bank account:",
          fontSize: 16,
          margin: [0, 30, 0, 20],
        },
        {
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            fillColor: "#F3F4F6",
            borderColor: "#D1D5DB",
          },
          table: {
            widths: ["auto", "*"],
            body: [
              [
                { text: "Account holder name:", bold: true, margin: [0, 2, 0, 2] },
                { text: props.benificiary_name || "Benificiary Name", margin: [0, 2, 0, 2] },
              ],
              [
                { text: "IFSC:", bold: true, margin: [0, 2, 0, 2] },
                { text: props.ifsc || "IFSC", margin: [0, 2, 0, 2] },
              ],
              [
                { text: "Account Number:", bold: true, margin: [0, 2, 0, 2] },
                { text: props.account || "Account Number", margin: [0, 2, 0, 2] },
              ],
            ],
          },
          margin: [0, 0, 0, 30],
        },
      ],
      styles: {
        header: { fontSize: 32, bold: true, color: "#F9FAFB", alignment: "center" },
        mentorHeader: { fontSize: 18, bold: true, color: "#1F2937" },
        subheader: { fontSize: 16, bold: true, color: "#374151" },
        invoiceDetails: { fontSize: 14, color: "#6B7280" },
        subText: { fontSize: 14, color: "#4B5563" },
        receiverDetails: { fontSize: 14, color: "#4B5563" },
        tableHeader: { bold: true, fontSize: 14, color: "#1F2937", fillColor: "#F3F4F6" },
        tableBody: { fontSize: 14, color: "#1F2937" },
        tableTotal: { bold: true, fontSize: 18, color: "#000000" },
      },
    }

    pdfMake.createPdf(docDefinition).open()
  }

  generatePDFInvoice()
}

export default influencerInvoice
