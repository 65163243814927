import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import useCreateBatchViewModel from "presentation/Admin/PlacementCreateBatch/CreateBatchViewModel"
import Pagination from "presentation/Admin/PlacementDashboard/components/Pagination"
import usePlacementConstant from "presentation/Admin/PlacementDashboard/components/PlacementConstants"
import useDashboardViewModel from "presentation/Admin/PlacementDashboard/DashboardViewModel"
import { useEffect } from "react"
import FilterSection from "./components/FilterSection"
import MoreFilterSection from "./components/MoreFilterSection"
import PlacementListView from "./components/PlacementListView"
import ComposeEmailCard from "./components/popUpCards/ComposeEmailCard"

export default function DashboardView() {
  const TABS = ["All Students", "Selected", "Rejected"]

  const {
    toast,
    searchPlacement,
    filteredPlacementList,
    changeToastVisibility,
    filters,
    setFilters,
    handelFiltersChange,
    fetchAllContests,
    fetchAllPlacementContests,
    allPlacementBatch,
    allPlacementContests,
    getStudentList,
    totalPages,
    totalCount,
    handleSendEmail,
    handleChangePageNumber,
    handelBatchFiltersChange,
    isEmailPopup,
    setIsEmailPopup,
  } = useDashboardViewModel()

  const { getBatches, selectBatch } = useCreateBatchViewModel()
  const { programs } = usePlacementConstant()

  useEffect(() => {
    fetchAllPlacementContests()
    fetchAllContests()
    getStudentList()
    getBatches()
  }, [])

  useEffect(() => {
    getStudentList()
  }, [filters])
  return (
    <DashboardLayout>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="space-y-1">
          <h2 className="text-new-accent">Placement Student Dashboard</h2>
          <p>Track and maintain placement data.</p>
        </div>

        <div className="flex flex-row gap-8">
          <div className="flex items-center justify-center">
            <select
              className="w-[280px] rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              value={filters.queryPlacementBatch || ""}
              onChange={(e) => handelBatchFiltersChange(e.target.value, "queryPlacementBatch")}
            >
              <option value="">Select Placement Batch</option>
              {allPlacementBatch.map((batch: string, index: number) => (
                <option key={index} value={batch}>
                  {batch}
                </option>
              ))}
            </select>
          </div>
          <div className="w-fit flex flex-row items-start content-start gap-2">
            {TABS.map((tab, index) => (
              <button
                key={index}
                className={`text-sm py-[6px] px-4 border border-solid border-new-neutral rounded-sm ${
                  filters.currentTab === tab ? "bg-new-accent text-white" : ""
                }`}
                onClick={() => handelFiltersChange(tab, "currentTab")}
              >
                {tab}
              </button>
            ))}
          </div>
          <button
            className={`text-sm py-[6px] px-4 border border-solid border-[#00AB14] rounded-sm text-[#00AB14]`}
            onClick={handleSendEmail}
          >
            Send Email
          </button>
        </div>
        <hr />
        <FilterSection
          filters={filters}
          setFilters={setFilters}
          selectBatch={selectBatch}
          programs={programs}
          allPlacementContests={allPlacementContests}
          handelBatchFiltersChange={handelBatchFiltersChange}
        />
        <MoreFilterSection filters={filters} setFilters={setFilters} />
        <PlacementListView filteredPlacementList={filteredPlacementList} />
        <Pagination currentPage={filters.pageIndex} totalPages={totalPages} onPageChange={handleChangePageNumber} />
        {isEmailPopup && (
          <div className="fixed inset-0 bg-[rgba(0,0,0,0.6)] flex items-center justify-center z-50">
            <div className="rounded-lg shadow-lg">
              <ComposeEmailCard filters={filters} onCancel={() => setIsEmailPopup(false)} totalCount={totalCount} />
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}
