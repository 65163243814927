import { Auth } from "domain/model/Auth"
import { InfluencerDashboardRepository } from "domain/repository/Admin/InfluencerDashboardRepository"

export interface UpdateCompensationDetailsUseCase {
  invoke(email: string, campaignType: string, compensation: number, date: string, auth: Auth): Promise<any>
}

export class UpdateCompensationDetails implements UpdateCompensationDetailsUseCase {
  private repository: InfluencerDashboardRepository

  constructor(repository: InfluencerDashboardRepository) {
    this.repository = repository
  }
  async invoke(email: string, campaignType: string, compensation: number, date: string, auth: Auth): Promise<any> {
    if (!email || !campaignType || !compensation || !date) {
      throw new Error("Invalid parameters. Please provide all required fields.")
    }
    return await this.repository.updateCompensationDetails(email, campaignType, compensation, date, auth)
  }
}
