import React, { useEffect, useState } from "react"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import StudentContestAPIDataSourceImpl from "data/API/Student/ContestAPIDataSourceImpl"
import StudentContestRepositoryImpl from "data/repository/Student/ContestRepositoryImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import GetAllContest from "domain/useCase/Student/Assessment/GetAllContest"
import getBatchwiseExams from "domain/useCase/Student/Assessment/GetBatchwiseExams"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { SuccessCheckboxIcon } from "core/constants/svgs"
import { useAuth } from "core/context/auth"
import { genError } from "core/utils/string"
import GetAllExams from "domain/useCase/Student/Assessment/GetAllExams"
import { useApp } from "core/context/app"
import { isEmpty } from "core/utils/misc"
import { set } from "date-fns"
import GetStudentBatchHistory from "domain/useCase/User/Contest/GetStudentBatchHistory"
import checkBatchHasExamTemplate from "domain/useCase/Student/Assessment/checkBatchHasExamTemplate"
import ExamResult from "domain/useCase/Student/Assessment/ExamResult"
import { useNavigate } from "react-router-dom"
import SubmitContestApproval from "domain/useCase/User/Contest/SubmitContestApproval"
import getExamwiseLeaderboard from "domain/useCase/Student/Assessment/GetExamwiseLeaderboard"
import GetQuestionwiseSubmission from "domain/useCase/User/Contest/GetQuestionwiseSubmission"
import useFeatureFeedbackViewModel from "../FeatureFeedback/FeatureFeedbackViewModel"

import GetUpcomingExams from "domain/useCase/Student/Assessment/GetUpcomingExams"
import { AppContext } from "core/context/app/AppContext"

export default function ContestViewModel() {
  const { fetchLastFeedback, getJoiningDate, saveFeedback, shouldShowFeedbackAfter2Months } =
    useFeatureFeedbackViewModel()
  const [tableData, setTableData] = useState<any>({
    header: [<SuccessCheckboxIcon className="h-4 w-4 text-[#FAFAFA]" />, "Name", "Date", "Time", "Link", "Exam Result"],
    rows: [],
  })
  const [activeButtonType, setActiveButtonType] = useState(0)
  const [allContest, setAllContest] = useState<any>({})
  const [totalContest, setTotalContest] = useLocalStorage<any>("contest", {})
  const [openExamId, setOpenExamId] = useState<number[]>([])
  const [tab, setTab] = React.useState<any>("attempt1")

  const { contestList } = useApp()
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const [totalDataList, setTotalDataList] = useState(0)
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [phases, setPhases] = React.useState<any>([])
  const [mocks, setMocks] = React.useState([])
  const [batch, setBatch] = React.useState("")
  const [student] = useLocalStorage<any>("student", {} as any)
  const email = student?.email
  const [selectedBatch, setSelectedBatch] = React.useState(student?.batch)
  const [hasExamTemplate, setHasExamTemplate] = React.useState(true)
  const [attendances, setAttendances] = React.useState<{
    [key: string]: boolean | null
  }>({})
  const [phasewiseAttendaces, setPhasewiseAttendaces] = React.useState<any>({})
  const [examAttendance, setExamAttendance] = React.useState<{ [key: string]: boolean | null }>({})
  const [reason, setReason] = React.useState("")
  const [approveModal, setApproveModal] = React.useState(false)
  const [currentContestId, setCurrentContestId] = React.useState("")
  const [selectedContest, setSelectedContest] = React.useState<string | null>(null)
  const [phasewiseReason, setPhasewiseReason] = React.useState("")
  const [phasewiseApproveModal, setPhasewiseApproveModal] = React.useState(false)
  const [phasewiseCurrentContestId, setPhasewiseCurrentContestId] = React.useState("")
  const [resultType, setResultType] = React.useState("live")
  const [questionwiseSubmissions, setQuestionwiseSubmissions] = React.useState<any>({})
  const [result, setResult] = React.useState<any>(null)

  const GetBatchwiseExamsUseCase = new getBatchwiseExams(
    new StudentContestRepositoryImpl(new StudentContestAPIDataSourceImpl())
  )
  const GetAllExamsUseCase = new GetAllExams(new StudentContestRepositoryImpl(new StudentContestAPIDataSourceImpl()))
  // const GetStudentBatchHistoryUseCase = new GetStudentBatchHistory(
  //   new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  // )
  const CheckBatchHasExamTemplate = new checkBatchHasExamTemplate(
    new StudentContestRepositoryImpl(new StudentContestAPIDataSourceImpl())
  )
  const GetExamResultUseCase = new ExamResult(new StudentContestRepositoryImpl(new StudentContestAPIDataSourceImpl()))
  const GetExamwiseLeaderBoardUseCase = new getExamwiseLeaderboard(
    new StudentContestRepositoryImpl(new StudentContestAPIDataSourceImpl())
  )
  const GetQuestionwiseSubmissionUseCase = new GetQuestionwiseSubmission(
    new ContestRepositoryImpl(new ContestAPIDataSourceImpl())
  )
  const GetUpcomingExamsUseCase = new GetUpcomingExams(
    new StudentContestRepositoryImpl(new StudentContestAPIDataSourceImpl())
  )

  const batches = student?.batch_v4
  const navigate = useNavigate()

  const checkExamEligibility = (contest_id: string) => {
    // this is upcomming contest dont show popup
    if (activeButtonType === 0) return navigate(`/contests/${selectedBatch}/${contest_id}`)

    // already attempted just reattempt
    if (attendances[contest_id]) {
      return navigate(`/contests/${selectedBatch}/${contest_id}`)
    }
    //if not attempted live exam then also redirect to correct URL
    return navigate(`/contests/${selectedBatch}/${contest_id}`)

    // if approved by admin then redirect
    // if (attendances.hasOwnProperty(contest_id + "is_approved") && attendances[contest_id + "is_approved"]) {
    //   return navigate(`/contests/${selectedBatch}/${contest_id}`)
    // }

    // Not approved yet
    // const approval_reason = attendances[contest_id + "approval_reason"]
    //   ? String(attendances[contest_id + "approval_reason"])
    //   : ""
    // setReason(approval_reason)
    setCurrentContestId(contest_id)
  }
  const checkPhasewiseExamEligibility = (contest_id: string, isMock: boolean) => {
    if (isMock) {
      return checkMockExamEligibility(contest_id)
    }

    let startTime = null
    let duration = null

    for (const phase of phases) {
      for (const exam of phase?.exams) {
        for (const attempt of exam?.attempts) {
          const attemptContestId = attempt?.link.split("/").pop()
          if (attemptContestId === contest_id) {
            startTime = attempt.timestamp
            break
          }
        }
        if (startTime) break
      }
      if (startTime) break
    }

    const batchExam = Array.isArray(batchwiseExams)
      ? batchwiseExams.find((batchExam: any) => batchExam.exam_id === contest_id)
      : null

    if (batchExam) {
      duration = batchExam.duration
    }
    const endTime = startTime + duration * 60
    const currentTime = Math.floor(Date.now() / 1000)

    // if (startTime < currentTime && currentTime < endTime) {
    return navigate(`/contests/${selectedBatch}/${contest_id}`)
    // }
    // already attempted just reattempt
    // if (phasewiseAttendaces[contest_id]) {
    return navigate(`/contests/${selectedBatch}/${contest_id}`)
    // }

    // if approved by admin then redirect
    // if (
    //   phasewiseAttendaces.hasOwnProperty(contest_id + "is_approved") &&
    //   phasewiseAttendaces[contest_id + "is_approved"]
    // ) {
    //   return navigate(`/contests/${selectedBatch}/${contest_id}`)
    // }

    // Not approved yet
    // const approval_reason = phasewiseAttendaces[contest_id + "approval_reason"]
    //   ? String(phasewiseAttendaces[contest_id + "approval_reason"])
    //   : ""
    // setReason(approval_reason)
    setPhasewiseCurrentContestId(contest_id)
    // setApproveModal(true)
  }

  const checkMockExamEligibility = (contest_id: string) => {
    return navigate(`/contests/${selectedBatch}/mock/${contest_id}`)
  }

  async function fetchResultData(contestId: any) {
    try {
      const resultData = await GetExamResultUseCase.invoke(auth, contestId)
      if (!resultData?.success) {
        return null
      }

      const liveResults = resultData?.response?.live_results || {}
      const recordedResults = resultData?.response?.recorded_results || {}
      const moduleCertificate = resultData?.response?.module_certificate_url || ""
      const newResult = {
        live_results: liveResults,
        recorded_results: recordedResults,
        module_certificate: moduleCertificate,
      }

      setResult(newResult)
      if (Object.keys(liveResults).length > 0) {
        setResultType("live")
      }
      if (Object.keys(recordedResults).length > 0) {
        setResultType("recorded")
      } else {
        console.warn("No results found for the given contest ID.")
      }

      return newResult
    } catch (error) {
      console.error("Error:", error)
      return null
    }
  }

  const [selectedQuestionId, setSelectedQuestionId] = React.useState<string | null>(null)
  const [selectedExam, setSelectedExam] = React.useState("exam")
  const [startTime, setStartTime] = React.useState<number | null>(null)

  const getStartTime = () => {
    if (selectedExam === "previous") {
      if (totalContest[1]?.recorded?.length > 0) {
        const previousExams = tableData?.rows.filter((row: any) => row.batches.includes(selectedBatch))
        if (previousExams) {
          const specificExam = previousExams.find((exam: any) => exam.contest_id === selectedContest)
          if (specificExam) {
            return parseInt(specificExam.start_time)
          }
        }
      }
    }
    return null
  }
  const handleResultClick = async (contestId: string) => {
    setSelectedContest(contestId)

    setLoading(true)

    const result = await fetchResultData(contestId)
    await getLeaderboardData(contestId)

    if (result?.recorded_results.length === 0) {
    }
    setLoading(false)
  }

  // const [batchwiseExams, setBatchwiseExams] = useLocalStorage<any>(`batchwiseExams`, {})
  const [batchwiseExams, setBatchwiseExams] = useState<any>({})
  const fetchAllAssessments = async () => {
    if (!totalContest?.isEmpty) {
      return totalContest
    }
    setLoading(true)
    const response = await GetAllExamsUseCase.invoke(auth)
    setLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }
    setAllContest(response?.data)
    setTotalContest((prev: any) => ({
      ...prev,
      [1]: response?.data,
    }))
    return response?.data
  }
  const fetchAllPhasewiseExams = async () => {
    setLoading(true)
    // if (batchwiseExams?.[selectedBatch]) {
    //   setBatchwiseExams((prev: any) => ({
    //     ...prev,
    //     [selectedBatch]: batchwiseExams?.[selectedBatch],
    //   }))
    //   return batchwiseExams[selectedBatch]?.exams
    // }
    const response = await GetBatchwiseExamsUseCase.invoke(auth, student?.email as string, selectedBatch)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      setLoading(false)
      return
    }
    setBatchwiseExams(
      // ...prev,
      { [selectedBatch]: response.data }
    )

    setLoading(false)
    return response.data?.exams
  }

  const initializeAssessments = async () => {
    try {
      const assessments = await fetchAllAssessments()
      if (assessments[1]?.upcoming || assessments[1]?.recorded) {
        const updatedAttendances: any = {}
        const contests = [...assessments[1]?.upcoming, ...assessments[1]?.recorded]
        contests.forEach((contest) => {
          updatedAttendances[contest.contest_id] = contest?.attempted_live || false
          updatedAttendances[contest.contest_id + "viewResult"] = contest?.allow_result || false
          if (contest.hasOwnProperty("attempted_recorded")) {
            updatedAttendances[contest.contest_id + "attempted_recorded"] = contest.attempted_recorded
          }
          if (contest.hasOwnProperty("is_approved")) {
            updatedAttendances[contest.contest_id + "is_approved"] = contest.is_approved
          }
          if (contest.hasOwnProperty("approval_reason")) {
            updatedAttendances[contest.contest_id + "approval_reason"] = contest.approval_reason
          }
        })
        setAttendances(updatedAttendances)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }
  const initializeAssessmentsBatchwise = async () => {
    try {
      const assessments = await fetchAllPhasewiseExams()
      if (assessments && Array.isArray(assessments)) {
        const updatedAttendances: Record<string, any> = {}

        assessments.forEach((contest: any) => {
          updatedAttendances[contest.exam_id] = contest?.attempted_live || false
          updatedAttendances[contest.exam_id + "viewResult"] = contest?.allow_result || false

          if (contest.hasOwnProperty("attempted_recorded")) {
            updatedAttendances[contest.exam_id + "attempted_recorded"] = contest.attempted_recorded
          }
          if (contest.hasOwnProperty("is_approved")) {
            updatedAttendances[contest.exam_id + "is_approved"] = contest.is_approved
          }
          if (contest.hasOwnProperty("approval_reason")) {
            updatedAttendances[contest.exam_id + "approval_reason"] = contest.approval_reason
          }
        })

        setPhasewiseAttendaces(updatedAttendances)
      } else {
        console.warn("Assessments data is invalid or empty")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const getUniqueBatches = () => {
    const batchMap = new Map()

    batches?.forEach((batch: any) => {
      const { batch_id, timestamp_end } = batch
      if (!batchMap.has(batch_id) || batchMap.get(batch_id) < timestamp_end) {
        batchMap.set(batch_id, timestamp_end)
      }
    })
    const uniqueBatchesWithTimestamps = Array.from(batchMap.entries())
    const sortedUniqueBatches = uniqueBatchesWithTimestamps.sort((a, b) => b[1] - a[1])
    const sortedBatchIds = sortedUniqueBatches.map(([batch_id]) => batch_id)
    return sortedBatchIds
  }

  const uniqueBatches = getUniqueBatches()
  async function batchHasExamTemplate(batch_id: string) {
    setLoading(true)

    const response = await CheckBatchHasExamTemplate.invoke(auth, batch_id)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      setLoading(false)
      return
    }
    setHasExamTemplate(response?.data)
  }

  async function fetchBatchwiseExams(batch_id: string) {
    setLoading(true)
    if (!student?.batch) {
      changeToastDetails(STR_FAILURE, "Batch information is missing. Please login again.")
      changeToastVisibility(true)
      setLoading(false)
      return
    }
    // if (batchwiseExams?.[batch_id]) {
    //   setPhases(batchwiseExams?.[batch_id]?.batchData?.phases || [])
    //   setMocks(batchwiseExams?.[batch_id]?.batchData?.mock || [])
    //   setBatch(student?.batch)
    //   setBatchwiseExams((prev: any) => ({
    //     ...prev,
    //     [batch_id]: batchwiseExams?.[batch_id],
    //   }))
    //   setLoading(false)
    //   return
    // }
    const response = await GetBatchwiseExamsUseCase.invoke(auth, student?.email as string, batch_id)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      setLoading(false)
      return
    }
    setPhases(response?.data?.batchData?.phases || [])
    setMocks(response?.data?.batchData?.mock || [])
    setBatch(student?.batch)
    setBatchwiseExams(
      // ...prev,
      { [selectedBatch]: response.data }
    )

    setLoading(false)
  }
  const [hasPreviousExams, setHasPreviousExams] = React.useState(false)

  async function getAllContest() {
    setLoading(true)
    if (isEmpty(totalContest)) {
      const response = await GetAllExamsUseCase.invoke(auth)
      if (!response?.success) {
        changeToastDetails(STR_FAILURE, genError(response))
        changeToastVisibility(true)
        return
      }
      setAllContest(response?.data)
      setTotalDataList(response?.data?.total)
      setTotalContest((prev: any) => ({
        ...prev,
        [page]: response?.data,
      }))
      const tabType = ["upcoming", "recorded"][activeButtonType]
      let contestsForSelectedBatch: any[] = []
      if (totalContest[page]?.recorded && totalContest[page]?.recorded.length > 0) {
        contestsForSelectedBatch = totalContest[page].recorded.filter((contest: any) =>
          contest.batches.includes(selectedBatch)
        )
      }
      const hasPreviousExams = contestsForSelectedBatch.length > 0
      setHasPreviousExams(hasPreviousExams)
      setTableData((prev: any) => ({
        ...prev,
        rows: contestsForSelectedBatch,
      }))
    } else {
      setTotalDataList(totalContest?.total)
      const tabType = ["upcoming", "recorded"][activeButtonType]
      let contestsForSelectedBatch: any[] = []
      if (totalContest[page]?.recorded && totalContest[page]?.recorded.length > 0) {
        contestsForSelectedBatch = totalContest[page].recorded.filter((contest: any) =>
          contest.batches.includes(selectedBatch)
        )
      }
      const hasPreviousExams = contestsForSelectedBatch.length > 0
      setHasPreviousExams(hasPreviousExams)
      setTableData((prev: any) => ({
        ...prev,
        rows: contestsForSelectedBatch,
      }))
    }

    setLoading(false)
  }

  function changeActiveButtonType(index: number) {
    const tabType = ["upcoming", "recorded"][index]
    if (totalContest?.[page]) {
      setTableData({ ...tableData, rows: totalContest?.[page][tabType] })
    } else {
      setTableData({ ...tableData, rows: allContest[tabType] })
    }
    setActiveButtonType(index)
  }

  function changeBatchwiseActiveButtonType() {
    setBatchwiseExams(batchwiseExams)
    setPhases([...phases])
    setMocks([...mocks])
  }

  const [selectedAttempt, setSelectedAttempt] = React.useState(0)

  const [openMockId, setOpenMockId] = React.useState<number | null>(mocks.flatMap((mock: any) => mock.id)[0] || null)
  const toggleCollapse = (examId: number) => {
    setOpenExamId((prev: any) => (prev.includes(examId) ? prev.filter((id: any) => id !== examId) : [...prev, examId]))
  }

  const handleTabChange = (newTab: string) => {
    setTab(newTab)
  }
  const sendApprovalUseCase = new SubmitContestApproval(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const sendApproval = async () => {
    if (
      attendances.hasOwnProperty(currentContestId + "is_approved") &&
      !attendances[currentContestId + "is_approved"]
    ) {
      return
    }

    if (!reason) {
      changeToastDetails(STR_FAILURE, "Reason is required")
      changeToastVisibility(true)
      return
    }

    try {
      setLoading(true)
      const resultData = await sendApprovalUseCase.invoke(auth, {
        contest_id: currentContestId,
        reason,
      })
      if (resultData?.data) {
        setAttendances((prevAttendances) => ({
          ...prevAttendances,
          ...(resultData.data.hasOwnProperty("is_approved") && {
            [resultData.data.contest_id + "is_approved"]: resultData?.data?.is_approved,
          }),
        }))
        changeToastDetails(STR_SUCCESS, "Request sent! Please wait for Admin approval.")
        changeToastVisibility(true)
        setReason("")
      }
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Something went wrong")
      changeToastVisibility(true)
    }
    setLoading(false)
    setApproveModal(false)
  }
  const sendPhasewiseApproval = async () => {
    if (
      phasewiseAttendaces.hasOwnProperty(phasewiseCurrentContestId + "is_approved") &&
      !phasewiseAttendaces[phasewiseCurrentContestId + "is_approved"]
    ) {
      return
    }

    if (!reason) {
      changeToastDetails(STR_FAILURE, "Reason is required")
      changeToastVisibility(true)
      return
    }

    try {
      setLoading(true)
      const resultData = await sendApprovalUseCase.invoke(auth, {
        contest_id: phasewiseCurrentContestId,
        reason,
      })

      if (resultData?.data) {
        setPhasewiseAttendaces((prevAttendances: any) => ({
          ...prevAttendances,
          ...(resultData.data.hasOwnProperty("is_approved") && {
            [resultData.data.contest_id + "is_approved"]: resultData?.data?.is_approved,
          }),
        }))
        changeToastDetails(STR_SUCCESS, "Request sent! Please wait for Admin approval.")
        changeToastVisibility(true)
        setReason("")
      }
    } catch (error) {
      changeToastDetails(STR_FAILURE, "Something went wrong")
      changeToastVisibility(true)
    }
    setLoading(false)
    setApproveModal(false)
  }
  const handleCloseModal = () => {
    setSelectedContest(null)
    setResult(null)
  }
  const [leaderboardData, setLeaderboardData] = useState([])
  async function getLeaderboardData(emailId: string) {
    try {
      const response = await GetExamwiseLeaderBoardUseCase.invoke(auth, emailId)
      if (response?.success) {
        setLeaderboardData(response?.data)
      }
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const approveModalClose = () => setApproveModal(!approveModal)
  const phasewiseApproveModalClose = () => setPhasewiseApproveModal(!phasewiseApproveModal)

  async function getQuestionwiseSubmissionData(email: string, examId: string, questionId: string) {
    try {
      const response = await GetQuestionwiseSubmissionUseCase.invoke(auth, email, examId, questionId)
      setQuestionwiseSubmissions((prev: any) => ({
        ...prev,
        [questionId]: response?.data,
      }))
      setSelectedQuestionId(questionId)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    auth,
    tableData,
    allContest,
    loading,
    activeButtonType,
    toast,
    page,
    totalDataList,
    changeActiveButtonType,
    getAllContest,
    changeToastVisibility,
    setActiveButtonType,
    openExamId,
    openMockId,
    setOpenMockId,
    toggleCollapse,
    tab,
    handleTabChange,
    fetchBatchwiseExams,
    batch,
    phases,
    mocks,
    selectedAttempt,
    setSelectedAttempt,
    selectedBatch,
    setSelectedBatch,
    uniqueBatches,
    batchHasExamTemplate,
    hasExamTemplate,
    checkExamEligibility,
    fetchResultData,
    handleResultClick,
    attendances,
    initializeAssessments,
    reason,
    setReason,
    sendApproval,
    currentContestId,
    selectedContest,
    result,
    handleCloseModal,
    resultType,
    initializeAssessmentsBatchwise,
    phasewiseAttendaces,
    checkPhasewiseExamEligibility,
    phasewiseReason,
    setPhasewiseReason,
    phasewiseApproveModal,
    phasewiseCurrentContestId,
    sendPhasewiseApproval,
    approveModalClose,
    setPhasewiseApproveModal,
    setApproveModal,
    approveModal,
    batchwiseExams,
    phasewiseApproveModalClose,
    getLeaderboardData,
    leaderboardData,
    checkMockExamEligibility,
    questionwiseSubmissions,
    selectedQuestionId,
    getQuestionwiseSubmissionData,
    email,
    setPhases,
    changeBatchwiseActiveButtonType,
    hasPreviousExams,
    setHasPreviousExams,
    setLoading,
    totalContest,
    selectedExam,
    setSelectedExam,
    startTime,
    setStartTime,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    saveFeedback,
    setBatchwiseExams,
    getStartTime,
    student,
  }
}
