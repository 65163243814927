import useToast from "core/hooks/useToast"
import { STR_COPIED, STR_SUCCESS } from "core/constants/strings"
import { useApp } from "core/context/app"
import AvailabilityAPIDataSourceImpl from "data/API/Mentor/AvailabilityAPIDataSourceImpl"
import { AvailabilityRepositoryImpl } from "data/repository/Mentor/AvailabilityRepositoryImpl"
import { GetAvailability } from "domain/useCase/Mentor/Availability/GetAvailability"
import { UpdateAvailability } from "domain/useCase/Mentor/Availability/UpdateAvailability"
import { BlockDates } from "domain/useCase/Mentor/Availability/BlockDates"
import { useState } from "react"
import { useAuth } from "core/context/auth"
import { GetMentorNotifications } from "domain/useCase/Mentor/MentorNotifications/GetMentorNotifications"
import { MentorNotificationsRepositoryImpl } from "data/repository/Mentor/MentorNotificationsRepositoryImpl"
import MentorNotificationsAPIDataSourceImpl from "data/API/Mentor/MentorNotificationsAPIDataSourceImpl"
import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import GetMentorSessions from "domain/useCase/Mentor/Session/GetMentorSessions"
import { RemoveMentorNotifications } from "domain/useCase/Mentor/MentorNotifications/RemoveMentorNotifications"
import { OpenSlotNotification } from "domain/useCase/Mentor/MentorNotifications/OpenSlotNotification"

export default function HomeViewModel() {
  const { mentor } = useApp()
  const { auth } = useAuth()
  const [availability, setAvailability] = useState<any>(null)
  const [notificatons, setNotifications] = useState([])
  const [totalAvailableSlots, setTotalAvailableSlots] = useState(0)
  const [refreshData, setRefreshData] = useState(false)
  const [upcomingSessionNotification, setUpcomingSessionNotification] = useState<any>(null)
  const [openSlotReminder, setOpenSlotReminder] = useState<any>(null)
  const getAvailabilityUseCase = new GetAvailability(
    new AvailabilityRepositoryImpl(new AvailabilityAPIDataSourceImpl())
  )

  const getMentorNotificationsUseCase = new GetMentorNotifications(
    new MentorNotificationsRepositoryImpl(new MentorNotificationsAPIDataSourceImpl())
  )

  const removeMentorNotificationsUseCase = new RemoveMentorNotifications(
    new MentorNotificationsRepositoryImpl(new MentorNotificationsAPIDataSourceImpl())
  )

  const openSlotMentorNotificationsUseCase = new OpenSlotNotification(
    new MentorNotificationsRepositoryImpl(new MentorNotificationsAPIDataSourceImpl())
  )
  const getMentorSessionsUseCase = new GetMentorSessions(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))

  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const referralCode = mentor?.mentor?.referralCode

  const fetchAvailability = async () => {
    const response = await getAvailabilityUseCase.invoke(auth)
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = response.availability
    const totalSlots =
      (sunday?.length || 0) +
      (monday?.length || 0) +
      (tuesday?.length || 0) +
      (wednesday?.length || 0) +
      (thursday?.length || 0) +
      (friday?.length || 0) +
      (saturday?.length || 0)
    setTotalAvailableSlots(totalSlots)
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referralCode)
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, STR_COPIED)
  }

  const fetchMentorNotifications = async () => {
    const res = await getMentorNotificationsUseCase.invoke(auth)
    setNotifications(res.data)
  }

  const removeMentorNotificationsHandler = async (id: string) => {
    const res = await removeMentorNotificationsUseCase.invoke(auth, id)
    setRefreshData(!refreshData)
  }

  const fetchOpenSlotNotification = async () => {
    const res = await openSlotMentorNotificationsUseCase.invoke(auth)

    if (res.success && res.data?.startDate) {
      setOpenSlotReminder({
        type: "slot open",
        date: res.data.startDate,
      })
    }
  }
  const fetchMentorSessions = async () => {
    const response = await getMentorSessionsUseCase.invoke(auth)

    const sessions = response?.response

    const upcoming = sessions?.mentor_sessions?.filter((s: any) => !s?.done && !s?.id?.includes("msid1"))
    if (response.success && upcoming.length > 0) {
      const notification = {
        type: "upcoming session",
        date: upcoming[0].date,
        time: upcoming[0].start,
      }

      setUpcomingSessionNotification(notification)
    }
  }

  function formatDate(inputDate: any) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    if (!dateRegex.test(inputDate)) return inputDate

    const dateObject = new Date(inputDate)

    const dayOfMonth = dateObject.getDate()
    const monthName = dateObject.toLocaleString("default", { month: "short" })

    const getOrdinal = (number: number) => {
      const suffixes = ["th", "st", "nd", "rd"]
      const v = number % 100
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }

    const dayWithOrdinal = getOrdinal(dayOfMonth)

    const formattedDate = `${dayWithOrdinal} ${monthName}`

    return formattedDate
  }

  function formatTime(hour24: any) {
    hour24 = parseFloat(hour24)

    const hours = Math.floor(hour24)
    const minutes = (hour24 % 1) * 60

    const period = hours < 12 ? "AM" : "PM"

    let hour12 = hours % 12
    hour12 = hour12 === 0 ? 12 : hour12

    return `${hour12}:${minutes.toString().padStart(2, "0")} ${period}`
  }
  return {
    toast,
    referralCode,
    mentor,
    totalAvailableSlots,
    notificatons,
    upcomingSessionNotification,
    refreshData,
    openSlotReminder,
    formatDate,
    formatTime,
    removeMentorNotificationsHandler,
    changeToastDetails,
    changeToastVisibility,
    handleCopyClick,
    fetchAvailability,
    fetchMentorNotifications,
    fetchMentorSessions,
    fetchOpenSlotNotification,
  }
}
