import pn from "core/utils/pn"
import { ProfileDataSource } from "data/dataSource/Influencer/ProfileDataSource"
import { API_V3_ADMIN, API_V3_INFLUENCER, API_GET_PROFILE } from "core/constants/strings"
import { Server } from "core/utils/axios"
import { Auth } from "domain/model/Auth"

const server = new Server()

export default class ProfileAPIDataSourceImpl implements ProfileDataSource {
  async fetchInfluencerProfile(email: string, auth: Auth) {
    try {
      const response = await server.post(
        pn(API_V3_INFLUENCER, API_GET_PROFILE),
        { email },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
