import { Auth } from "domain/model/Auth"
import { UpdateCampaignEntry } from "domain/model/InfluencerDashboard"
import { InfluencerDashboardRepository } from "domain/repository/Admin/InfluencerDashboardRepository"

export interface updateInfluencerInvoiceUseCase {
  invoke(id: UpdateCampaignEntry, auth: Auth): Promise<any>
}

export class UpdateInfluencerInvoiceData implements updateInfluencerInvoiceUseCase {
  private repository: InfluencerDashboardRepository

  constructor(repository: InfluencerDashboardRepository) {
    this.repository = repository
  }

  async invoke(id: UpdateCampaignEntry, auth: Auth): Promise<any> {
    return await this.repository.updateCampaignEntry(id, auth)
  }
}
