import React, { useState } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import InvoiceTable from "./components/InvoicingTable"
import { useAuth } from "core/context/auth"
import { GetInvoiceGeneratedDetails } from "domain/useCase/Common/InfluencerCommon/GetAllInfuencerGeneratedInvoice"
import { InfluencerAPIDataSourceImpl } from "data/API/Common/InfluencerCommonAPIDataSourceImpl"
import { CommonInfluencerRepositoryImpl } from "data/repository/Common/CommonInfluencerRepositoryImpl"
import useHomeViewModel from "../Home/HomeViewModel"

const InvoicingView = () => {
  const { influencer } = useHomeViewModel()
  const { auth } = useAuth()
  const [invoiceData, setGeneratedInvoiceData] = React.useState<any>([])
  const [selectedYear, setSelectedYear] = useState<string>("")
  const GetAllGeneratedInvoiceDataUseCase = new GetInvoiceGeneratedDetails(
    new CommonInfluencerRepositoryImpl(new InfluencerAPIDataSourceImpl())
  )
  async function getInfluencerGeneratedInvoice() {
    const response = await GetAllGeneratedInvoiceDataUseCase.invoke(auth, influencer.email)
    if (response) {
      setGeneratedInvoiceData(response.data)
    }
  }
  React.useEffect(() => {
    getInfluencerGeneratedInvoice()
  }, [])
  const filteredData = invoiceData.filter((invoice: any) => {
    const invoiceYear = invoice.date.slice(3)
    return selectedYear ? invoiceYear === selectedYear : true
  })
  return (
    <DashboardLayout>
      <div className="flex flex-col m-6 gap-6 font-montserrat">
        <div className="flex-row items-end">
          <h1 className="text-[24px] font-semibold text-[#312E81]">All Sessions Invoicing</h1>
          <p className="text-[#525252] font-montserrat text-base font-normal leading-160 text-[14px]">
            You have access to all your sessions invoicing data.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <label htmlFor="yearInput" className="text-[16px] font-medium text-gray-700">
            Enter Year:
          </label>
          <input
            type="number"
            id="yearInput"
            className="h-[40px] w-40 appearance-none rounded border border-gray-300 p-2 text-center"
            placeholder="e.g. 2024"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          />
        </div>
        <div>
          <InvoiceTable invoiceData={filteredData} influencerData={influencer} />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default InvoicingView
