import { Button } from "core/components/v2/Button"
import SignOut from "assets/svgs/HomePage/SignOut"
import { LogOutIcon } from "lucide-react"

const ProfileMenu = ({ listdata, handleButtonClick, Ref }: any) => {
  return (
    <div
      className="absolute top-full right-0 z-30 mt-1 h-fit w-[177px] space-y-1 rounded bg-white p-2 shadow-lg"
      ref={Ref}
    >
      <ul className="space-y-1 border-b pb-1">
        {listdata.map((item: any, index: number) => (
          <li key={index}>
            {item && (
              <Button variant={"ternary"} className="w-full" onClick={() => handleButtonClick(item)}>
                {item}
              </Button>
            )}
          </li>
        ))}
      </ul>
      <Button variant={"ternary"} className="w-full" onClick={() => handleButtonClick("Sign out")}>
        <div className="flex items-center gap-2">
          <LogOutIcon size={16} />
          <span>Sign out</span>
        </div>
      </Button>
    </div>
  )
}

export default ProfileMenu
