import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface SubmitContestUseCase {
  invoke(auth: Auth, contest_id: string, batch: string, passedPopup: boolean): Promise<any>
}

export default class SubmitContest implements SubmitContestUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, contest_id: string, batch: string, passedPopup: boolean) {
    return this.repository.submitContest(auth, contest_id, batch, passedPopup)
  }
}
