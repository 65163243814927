import { format, set } from "date-fns"
import { STR_CANCEL, STR_SUBMIT } from "core/constants/strings"
import { CrossIcon, StarIcon } from "core/constants/svgs"
import getOrdinal from "core/utils/ordinal"
import { isEmpty } from "core/utils/misc"
import { Close } from "@mui/icons-material"
import React from "react"
import Loader from "core/components/Loader"
import { Button } from "core/components/v2/Button"
import Spinner from "core/components/Spinner"

export default function PopUp({
  session,
  onClose,
  setCancelReasonPopUpModal,
  onCancel,
  type,
  setIsRescheduleEnabled,
  isCancellationInProgress,
  setBookSlotModal,
}: any) {
  async function handleCancelSession() {
    try {
      if (type === "mark session completed") {
        await onCancel(session?.id)
        // setCancelSessionIndex(-1)
        // setBookingIndex(-1)
        onClose()
        setCancelReasonPopUpModal(false)
      }
    } catch (error) {
      console.error("Error cancelling session:", error)
    }
  }

  const sessionStartTime: string = session?.session_start_time || "" // Ensure it's a string
  function formatFractionalTime(hour: string | number): string {
    const hour24 = parseFloat(hour.toString()) // Ensure it's a number
    const hours = Math.floor(hour24) // Extract the integer part (hours)
    const minutes = Math.round((hour24 % 1) * 60) // Convert the fractional part to minutes
    const formattedHours = hours.toString().padStart(2, "0") // Ensure two digits for hours
    const formattedMinutes = minutes.toString().padStart(2, "0") // Ensure two digits for minutes
    return `${formattedHours}:${formattedMinutes}` // Return time in "HH:MM" format
  }
  const sessionStartTimeFormatted: string = formatFractionalTime(sessionStartTime)
  const sessionDateTime: Date = new Date(`${session?.session_date}T${sessionStartTimeFormatted}`)
  console.log(sessionDateTime, "uuuuu")
  const dateString: string = `${sessionDateTime.getDate()} ${sessionDateTime.toLocaleString("default", { month: "short" })} ${sessionDateTime.getFullYear()}`
  let hours: number = sessionDateTime.getHours()
  const amPm: string = hours >= 12 ? "PM" : "AM"
  hours = hours % 12 || 12 // Convert 0 to 12-hour format

  const timeString: string = `${hours}:${sessionDateTime.getMinutes().toString().padStart(2, "0")} ${amPm}`

  const formattedDateTime: string = `${dateString} | ${timeString}`
  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div onClick={onClose} className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="font-montserrat m-auto flex w-[304px] transform flex-col gap-3 rounded border bg-new-solid-white p-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-new-success">{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
              </div>
              <div>
                <button onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <p className="text-[#414141] text-[12px] font-[400]">
              {" "}
              You are about to mark completed your mentor session with{" "}
            </p>

            <div className="leading-5">
              <p className="text-[#414141] text-[14px] font-[600]">
                {!isEmpty(session?.temp_mentor) ? session?.temp_mentor?.name : session?.mentor_name}
              </p>
              <h4 className="font-montserrat w-[218px] font-[600] text-[14px] text-[#414141]">{formattedDateTime}</h4>
            </div>
            {/* <h5 className="whitespace-wrap text-new-failure w-[235px]">
                            Note ** Session {type === "cancel" ? "cancelation" : "rescheduling"} is not allowed within 12Hr of mentor
                            session.
                        </h5> */}
            <div className="flex w-full justify-end">
              {/* <Button
                outlined
                className="h-9 w-[134px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                onClick={onClose}
              >
                No
              </Button> */}
              <Button
                // className="h-9 w-[134px] whitespace-nowrap rounded-sm bg-transparent px-4 py-2"
                variant={"cancel"}
                className="px-[16px] text-new-success"
                size="sm"
                border="thin"
                onClick={() => {
                  handleCancelSession()
                }}
                disabled={isCancellationInProgress}
              >
                {isCancellationInProgress ? (
                  <Spinner small={true} />
                ) : (
                  <> {type === "cancel" ? "Confirm Cancel" : "Mark Session Completed"}</>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
