import { InfluencerGeneratedInvoice } from "domain/model/InfluencerDashboard"
import { InfluencerDashboardRepository } from "domain/repository/Admin/InfluencerDashboardRepository"
import { Auth } from "domain/model/Auth"

export interface GetAllInfluencerGeneratedInvoiceUseCase {
  invoke(auth: Auth, details: InfluencerGeneratedInvoice): Promise<any>
}

export class GetInvoiceGenerated implements GetAllInfluencerGeneratedInvoiceUseCase {
  private repository: InfluencerDashboardRepository
  constructor(repository: InfluencerDashboardRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, details: InfluencerGeneratedInvoice): Promise<any> {
    return await this.repository.generateInfluencerInvoice(auth, details)
  }
}
