import {
  API_ADMIN_GET_CONTEST_RESULT,
  API_CASE_STUDIES_QUESTION_LIST,
  API_CREATE_CONTEST,
  API_CREATE_EXAM,
  API_DELETE_CONTEST,
  API_DELETE_EXAM,
  API_GET_EXAM_DETAILS,
  API_GET_MCQ_NAME_FROM_ID,
  API_GET_QUESTION_LIST,
  API_SUBJECTIVE_QUESTION_LIST,
  API_UPDATE_CONTEST,
  API_UPDATE_EXAM,
  API_V4_ADMIN,
  API_V4_CONTEST_DELETE_FEEDBACK_FILE,
  API_V4_CREATE_CONTEST_FEEDBACK_RESPONSE,
  API_V4_GET_CONTEST_FEEDBACK_DETAILS,
  API_V4_GET_CONTEST_SSM_SUBMISSION_ENABLE_STATUS,
  API_V4_GET_CONTEST_STUDENT_LIST,
  API_V4_GET_STUDENT_CONTEST_FEEDBACK_RESPONSE,
  API_V4_GET_STUDENT_CONTEST_SUBMISSIONS,
  API_V4_TOGGLE_PROCTORING,
  API_V4_VALIDATE_CODING_QUESTION,
  API_V4_VALIDATE_MCQ_QUESTION,
  API_V4_VALIDATE_SUBJECTIVE_QUESTION,
  API_V4_GET_BATCHWISE_MODULES,
  API_V4_GET_MODULEWISE_EXAMS,
  API_V4_GET_TEMPLATE_INTERSECTION,
  API_V4_ADMIN_CREATE_EXAM_TEMPLATE,
  API_V4_ADMIN_GET_EXAM_TEMPLATE,
  API_V4_ADMIN_UPDATE_EXAM_TEMPLATE,
  API_V4_ADMIN_DELETE_MODULE_EXAM_TEMPLATE,
  API_V4_ADMIN_DELETE_EXAM_IN_MODULE,
  API_V4_ADMIN_GET_BATCH_EXAM_TEMPLATE,
  API_V4_ADMIN_CHECK_TEMPLATE_TYPE,
  API_V4_ADMIN_UPDATE_BATCH_EXAM_TEMPLATE,
  API_V4_ADMIN_BATCH_DELETE_EXAM_IN_MODULE,
  API_V4_ADMIN_BATCH_DELETE_MODULE_TEMPLATE,
  API_V4_ADMIN_GET_STUDENT_EXAM_TEMPLATE,
  API_V4_ADMIN_UPDATE_STUDENT_EXAM_TEMPLATE,
  API_V4_ADMIN_STUDENT_DELETE_MODULE_TEMPLATE,
  API_V4_ADMIN_STUDENT_DELETE_EXAM_IN_MODULE,
  API_V3_ADMIN,
  API_EXAM,
} from "core/constants/strings"
import { postAPI, postAPIForFiles, Server } from "core/utils/axios"
import pn from "core/utils/pn"
import ContestDataSource from "data/dataSource/Admin/ContestDataSource"
import { Auth } from "domain/model/Auth"
import { FeedbackResponse } from "domain/model/FeedbackResponse"

const server = new Server()
export default class ContestAPIDataSourceImpl implements ContestDataSource {
  async getQuestionList(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_QUESTION_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getcaseStudyQuestionList(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CASE_STUDIES_QUESTION_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getsubjectiveQuestionList(auth: Auth) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_SUBJECTIVE_QUESTION_LIST), {
        id_token: auth?.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async createContest(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_CONTEST), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateContest(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_CONTEST), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteContest(auth: Auth, contestId: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_DELETE_CONTEST), {
        id_token: auth?.id_token,
        contest_id: contestId, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }

  async getMcqNameFromId(auth: Auth, mcq_id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_MCQ_NAME_FROM_ID), {
        id_token: auth?.id_token,
        mcq_id: mcq_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }

  async toggleProctoring(auth: Auth, student: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_TOGGLE_PROCTORING), {
        id_token: auth?.id_token,
        student: student,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async validateCodingQuestion(auth: Auth, id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_VALIDATE_CODING_QUESTION), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async validateMcqQuestion(auth: Auth, id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_VALIDATE_MCQ_QUESTION), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }

  async validateSubjectiveQuestion(auth: Auth, id: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_VALIDATE_SUBJECTIVE_QUESTION), {
        id_token: auth?.id_token,
        id: id,
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestStudentList(
    auth: Auth,
    contest_id: string,
    // batch: string,
    page: number,
    pageLimit: number
  ) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CONTEST_STUDENT_LIST), {
        id_token: auth?.id_token,
        contest_id: contest_id, // Pass the contest_id to the backend
        // batch: batch,
        page: page,
        pageLimit: pageLimit,
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getStudentContestSubmissions(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_STUDENT_CONTEST_SUBMISSIONS), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async createContestFeedbackResponse(
    auth: Auth,
    contest_id: string,
    student_email: string,
    formData: any,
    feedbackResponse: FeedbackResponse
  ): Promise<any> {
    try {
      // Append additional fields directly to the existing formData
      formData.append("id_token", auth?.id_token)
      formData.append("contest_id", contest_id)
      formData.append("student_email", student_email)
      formData.append("feedbackResponse", JSON.stringify(feedbackResponse))

      const response = await postAPIForFiles(pn(API_V4_ADMIN, API_V4_CREATE_CONTEST_FEEDBACK_RESPONSE), formData)

      return response // Assuming response structure is { data: any }
    } catch (error) {
      console.error("Error creating feedback response:", error)
      throw new Error("An error occurred while creating feedback response")
    }
  }
  async getStudentContestFeedbackResponse(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_STUDENT_CONTEST_FEEDBACK_RESPONSE), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestFeedbackDetails(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CONTEST_FEEDBACK_DETAILS), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getContestSSMSubmissionEnableStatus(auth: Auth, student_email: string, contest_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_CONTEST_SSM_SUBMISSION_ENABLE_STATUS), {
        id_token: auth?.id_token,
        student_email: student_email, // Pass the student
        contest_id: contest_id, // Pass the contest_id to the backend
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteContestFeedbackFile(
    auth: Auth,
    contest_id: string,
    student_email: string,
    fileurl: string,
    questionName: string,
    type: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_CONTEST_DELETE_FEEDBACK_FILE), {
        id_token: auth?.id_token,
        contest_id,
        student_email,
        fileurl,
        questionName,
        type,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async createExam(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_CREATE_EXAM), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateExam(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_UPDATE_EXAM), {
        id_token: auth?.id_token,
        ...data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getExamDetails(auth: Auth, exam_id: string, batch_id?: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_GET_EXAM_DETAILS), {
        id_token: auth?.id_token,
        exam_id: exam_id,
        //pass the batch_id as optional param
        batch_id: batch_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async deleteExam(auth: Auth, exam_id: string) {
    try {
      const response = await server.delete(
        pn(API_V3_ADMIN, API_EXAM, API_DELETE_EXAM),
        {
          exam_id,
        },
        {
          Authorization: `Bearer ${auth?.id_token}`,
        }
      )

      return response
    } catch (error) {
      return error
    }
  }
  async getContestResult(auth: Auth, data: any) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_ADMIN_GET_CONTEST_RESULT), {
        id_token: auth?.id_token,
        data: data, // Pass the contest_id to the backend
      })

      return response
    } catch (error) {
      return error
    }
  }
  async getBatchwiseModules(auth: Auth, batch_ids: string[]): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_BATCHWISE_MODULES), {
        id_token: auth.id_token,
        batch_ids: batch_ids,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getModulewiseExams(auth: Auth, template_name: string, module_name: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_MODULEWISE_EXAMS), {
        id_token: auth.id_token,
        template_name: template_name,
        module_name: module_name,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getTemplateIntersection(auth: Auth, batch_ids: string[]): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_GET_TEMPLATE_INTERSECTION), {
        id_token: auth.id_token,
        batch_ids: batch_ids,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }

  async createExamTemplate(
    auth: Auth,
    template_name: string,
    phase_name: string,
    exam_name: string,
    mock_name: string,
    exam_type: string
  ) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_CREATE_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        template_name: template_name,
        phase_name: phase_name,
        exam_name: exam_name,
        mock_name: mock_name,
        exam_type: exam_type,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getExamTemplate(auth: Auth, template_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_GET_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        template_id: template_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async updateExamTemplate(auth: Auth, template_id: string, name: string, updates: string[]) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_UPDATE_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        template_id: template_id,
        name: name,
        // module_id: module_id,
        // exam_id: exam_id,
        updates: updates,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async deleteModuleExamTemplate(auth: Auth, template_id: string, module_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_DELETE_MODULE_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        template_id: template_id,
        module_id: module_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async deleteExamInModule(auth: Auth, template_id: string, module_id: string, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_DELETE_EXAM_IN_MODULE), {
        id_token: auth.id_token,
        template_id: template_id,
        module_id: module_id,
        exam_id: exam_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getBatchExamTemplate(auth: Auth, batch_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_GET_BATCH_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        batch_id: batch_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async updateBatchExamTemplate(auth: Auth, batch_id: string, updates: string[], name: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_UPDATE_BATCH_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        batch_id: batch_id,
        updates: updates,
        name: name,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async checkTemplateType(auth: Auth, template_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_CHECK_TEMPLATE_TYPE), {
        id_token: auth.id_token,
        template_id: template_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async batchdeleteModuleTemplate(auth: Auth, batch_id: string, module_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_BATCH_DELETE_MODULE_TEMPLATE), {
        id_token: auth.id_token,
        batch_id: batch_id,
        module_id: module_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async batchdeleteExamInModule(auth: Auth, batch_id: string, module_id: string, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_BATCH_DELETE_EXAM_IN_MODULE), {
        id_token: auth.id_token,
        batch_id: batch_id,
        module_id: module_id,
        exam_id: exam_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async getStudentExamTemplate(auth: Auth, student_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_GET_STUDENT_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        student_id: student_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async updateStudentExamTemplate(auth: Auth, student_id: string, updates: string[], name: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_UPDATE_STUDENT_EXAM_TEMPLATE), {
        id_token: auth.id_token,
        student_id: student_id,
        updates: updates,
        name: name,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async studentDeleteModuleTemplate(auth: Auth, student_id: string, module_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_STUDENT_DELETE_MODULE_TEMPLATE), {
        id_token: auth.id_token,
        student_id: student_id,
        module_id: module_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  async studentDeleteExamInModule(auth: Auth, student_id: string, module_id: string, exam_id: string) {
    try {
      const response = await postAPI(pn(API_V4_ADMIN, API_V4_ADMIN_STUDENT_DELETE_EXAM_IN_MODULE), {
        id_token: auth.id_token,
        student_id: student_id,
        module_id: module_id,
        exam_id: exam_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
}
