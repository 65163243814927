import { Auth } from "domain/model/Auth"
import SessionRescheduleRequestRepository from "domain/repository/Admin/SessionRescheduleRequestRepository"
import GetSessionRescheduleRequestDetails from "domain/model/GetSessionRescheduleRequest"
export interface GetSessionRescheduleRequestUseCase {
  invoke(auth: Auth, details: GetSessionRescheduleRequestDetails): Promise<any>
}

export class GetSessionRescheduleRequest implements GetSessionRescheduleRequestUseCase {
  private repository: SessionRescheduleRequestRepository

  constructor(repository: SessionRescheduleRequestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: GetSessionRescheduleRequestDetails): Promise<any> {
    return this.repository.getSessionRescheduleRequest(auth, details)
  }
}
