import { Auth } from "domain/model/Auth"
import { ProfileRepository } from "domain/repository/Student/ProfileRepository"
import ProfileDataSource from "data/dataSource/Student/ProfileDataSource"

export class ProfileRepositoryImpl implements ProfileRepository {
  private dataSource: ProfileDataSource

  constructor(dataSource: ProfileDataSource) {
    this.dataSource = dataSource
  }

  async getProfileDetails(auth: Auth): Promise<any> {
    return this.dataSource.getProfileDetails(auth)
  }

  async updateProfile(auth: Auth, data: any): Promise<any> {
    return this.dataSource.updateProfile(auth, data)
  }

  async uploadProfilePicture(auth: Auth, file: File): Promise<any> {
    return this.dataSource.uploadProfilePicture(auth, file)
  }

  async percentCompletedProfile(auth: Auth): Promise<any> {
    return this.dataSource.percentCompletedProfile(auth)
  }

  async getStudentProfileDetails(auth: Auth): Promise<any> {
    return this.dataSource.getStudentProfileDetails(auth)
  }

  async uploadResume(auth: Auth, blob: any, filename: string): Promise<any> {
    return this.dataSource.uploadResume(auth, blob, filename)
  }

  async getResume(auth: Auth): Promise<any> {
    return this.dataSource.getResume(auth)
  }

  async getStudentPlacementScore(auth: Auth): Promise<any> {
    return this.dataSource.getStudentPlacementScore(auth)
  }

  async updateLeetcode(leetcodeId: string, email: string, auth: Auth, user: string): Promise<any> {
    return this.dataSource.updateLeetcode(leetcodeId, email, auth, user)
  }
}
