import { Auth } from "domain/model/Auth"
import GenerateInvoiceRepository from "domain/repository/Admin/GenerateInvoiceRepository"

export interface UpdateBookedSessionsUseCase {
  invoke(auth: Auth, mentor_id: string): Promise<any>
}

export default class UpdateBookedSessions implements UpdateBookedSessionsUseCase {
  private repository: GenerateInvoiceRepository

  constructor(repository: GenerateInvoiceRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, mentor_id: string) {
    return await this.repository.updateBookedSessions(auth, mentor_id)
  }
}
