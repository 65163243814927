import { Auth } from "domain/model/Auth"
import { CreateSessionRescheduleRequestDetails } from "domain/model/SessionRescheduleRequest"
import SessionRescheduleRequestRepository from "domain/repository/Student/SessionRescheduleRequestRepository"

export interface GetSessionRescheduleRequestUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetSessionRescheduleRequest implements GetSessionRescheduleRequestUseCase {
  private repository: SessionRescheduleRequestRepository

  constructor(repository: SessionRescheduleRequestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getSessionRescheduleRequest(auth)
  }
}
