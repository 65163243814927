import {
  API_CONTEST,
  API_GET_APPROVAL,
  API_GET_CODING_QUESTION_DETAILS,
  API_GET_CONTEST_DETAILS,
  API_GET_CONTEST_LEADERBOARD,
  API_GET_INSTANT_CONTESTS,
  API_GET_LIVE_CONTESTS,
  API_GET_MCQ_QUESTION_DETAILS,
  API_GET_STUDENT_CONTEST_DETAILS,
  API_GET_STUDENT_PAST_CONTESTS,
  API_GET_SUB_CASE_QUESTION_DETAILS,
  API_HAS_STUDENT_STARTED_CONTEST,
  API_IS_STUDENT_REGISTERED,
  API_REGISTER_CONTEST,
  API_STUDENT,
  API_SUBMIT_BATCH_MCQ_QUESTION,
  API_SUBMIT_CASESTUDY_QUESTIONS,
  API_SUBMIT_CODING_QUESTION,
  API_SUBMIT_CONTEST,
  API_SUBMIT_MCQ_QUESTION,
  API_SUBMIT_SUBJECTIVE_QUESTIONS,
  API_UNREGISTER_CONTEST,
  API_V4_MARK_SUBMIT_PROCTORED,
  API_V4_GET_BATCHWISE_MODULES_EXAMS,
  API_V4_REATTEMPT_ATTEMPTED_CONTEST,
  API_V4_STUDENT,
  CONTEST_LIST,
  PLACEMENT_CONTEST_LIST,
  API_V4_GET_ALL_QUESTIONS,
  API_V4_GET_ALL_MCQ__QUESTIONS,
  API_REGISTER_EXAM,
  API_IS_STUDENT_REGISTERED_EXAM,
  API_GET_STUDENT_EXAM_DETAILS,
  API_GET_STUDENT_PAST_EXAMS,
  API_V4_MARK_EXAM_SUBMIT_PROCTORED,
  API_V4_GET_QUESTIONWISE_SUBMISSION,
  API_V4_GET_MCQ_ID_FROM_NAME,
} from "core/constants/strings"
import { postAPI } from "core/utils/axios"
import pn from "core/utils/pn"
import ContestDataSource from "data/dataSource/User/ContestDataSource"
import { Auth } from "domain/model/Auth"

import { Server } from "core/utils/axios"
const server = new Server()
export class ContestAPIDataSourceImpl implements ContestDataSource {
  async getLiveContests(auth: Auth, page: number, limit: number): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_LIVE_CONTESTS), {
        id_token: auth.id_token,
        page,
        limit,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllContestsList(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(CONTEST_LIST), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getInstantContests(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_INSTANT_CONTESTS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getContestDetails(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_CONTEST_DETAILS), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentPastContests(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_PAST_CONTESTS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getStudentContestDetails(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_CONTEST_DETAILS), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async registerContest(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_REGISTER_CONTEST), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async unregisterContest(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_UNREGISTER_CONTEST), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getContestLeaderboard(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_CONTEST_LEADERBOARD), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async isStudentRegistered(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_IS_STUDENT_REGISTERED), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async hasStudentStartedContest(auth: Auth, contest_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_HAS_STUDENT_STARTED_CONTEST), {
        id_token: auth.id_token,
        contest_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getMcqQuestionDetails(auth: Auth, question_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_MCQ_QUESTION_DETAILS), {
        id_token: auth.id_token,
        question_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getSubCaseQuestionDetails(auth: Auth, question_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_SUB_CASE_QUESTION_DETAILS), {
        id_token: auth.id_token,
        question_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getCodingQuestionDetails(auth: Auth, question_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_CODING_QUESTION_DETAILS), {
        id_token: auth.id_token,
        question_id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitCodingQuestion(
    auth: Auth,
    contest_id: string,
    coding_question_key: string,
    status: string
  ): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_CODING_QUESTION), {
        id_token: auth.id_token,
        contest_id,
        coding_question_key,
        status,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitMcqQuestion(auth: Auth, contest_id: string, mcq_question_key: string, solution: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_MCQ_QUESTION), {
        id_token: auth.id_token,
        contest_id,
        mcq_question_key,
        solution,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitSubjectiveQuestions(auth: Auth, contest_id: string, solutions: any[]): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_SUBJECTIVE_QUESTIONS), {
        id_token: auth.id_token,
        contest_id,
        solutions,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitCasestudyQuestions(auth: Auth, contest_id: string, solutions: any[]): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_CASESTUDY_QUESTIONS), {
        id_token: auth.id_token,
        contest_id,
        solutions,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitBatchMcqQuestion(auth: Auth, contest_id: string, batch_mcq_obj: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_BATCH_MCQ_QUESTION), {
        id_token: auth.id_token,
        contest_id,
        batch_mcq_obj,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitContest(auth: Auth, contest_id: string, batch: string, passedPopup: boolean): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_SUBMIT_CONTEST), {
        id_token: auth.id_token,
        contest_id,
        batch,
        passedPopup,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async submitContestApproval(auth: Auth, data: any): Promise<any> {
    try {
      const response = await server.post(pn(API_STUDENT, API_CONTEST, API_GET_APPROVAL), data, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async markSubmitProctored(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_MARK_SUBMIT_PROCTORED), {
        id_token: auth.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async reattemptAttemptedContest(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_REATTEMPT_ATTEMPTED_CONTEST), {
        id_token: auth.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getPlacementContestsList(auth: Auth): Promise<any> {
    try {
      const response = await server.get(pn(PLACEMENT_CONTEST_LIST), {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllQuestions(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_ALL_QUESTIONS), {
        id_token: auth.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async getAllMcqQuestions(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_ALL_MCQ__QUESTIONS), {
        id_token: auth.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }

  async registerExam(auth: Auth, exam_id: string, batch_id?: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_REGISTER_EXAM), {
        id_token: auth.id_token,
        exam_id,
        batch_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async unregisterExam(auth: Auth, exam_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_UNREGISTER_CONTEST), {
        id_token: auth.id_token,
        exam_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async isStudentRegisteredExam(auth: Auth, exam_id: string, batch_id?: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_IS_STUDENT_REGISTERED_EXAM), {
        id_token: auth.id_token,
        exam_id,
        batch_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getStudentExamDetails(auth: Auth, exam_id: string, batch_id?: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_EXAM_DETAILS), {
        id_token: auth.id_token,
        exam_id,
        batch_id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getStudentPastExams(auth: Auth): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_GET_STUDENT_PAST_EXAMS), {
        id_token: auth.id_token,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async markExamSubmitProctored(auth: Auth, id: any): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_MARK_EXAM_SUBMIT_PROCTORED), {
        id_token: auth.id_token,
        id,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getBatchwiseModulesExams(auth: Auth, batch_id: string): Promise<any> {
    try {
      const response = await postAPI(pn(API_V4_STUDENT, API_V4_GET_BATCHWISE_MODULES_EXAMS), {
        id_token: auth.id_token,
        batch_id: batch_id,
      })
      return response.data
    } catch ({ response: { data: error } }: any) {
      return error
    }
  }
  //might not be needed
  async getStudentBatchHistory(auth: Auth, email: string): Promise<any> {
    try {
      const response = await server.post(pn(API_STUDENT, API_CONTEST, API_GET_APPROVAL), email, {
        Authorization: `Bearer ${auth.id_token}`,
      })
      return response
    } catch (error) {
      return error
    }
  }
  async getQuestionwiseSubmission(auth: Auth, email: string, examId: string, questionId: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_CONTEST, API_V4_GET_QUESTIONWISE_SUBMISSION),
        { email, examId, questionId },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
  async getMcqIdFromName(auth: Auth, name: string): Promise<any> {
    try {
      const response = await server.post(
        pn(API_STUDENT, API_CONTEST, API_V4_GET_MCQ_ID_FROM_NAME),
        { name },
        {
          Authorization: `Bearer ${auth.id_token}`,
        }
      )
      return response
    } catch (error) {
      return error
    }
  }
}
