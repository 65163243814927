import { Auth } from "domain/model/Auth"
import { ContestRepository } from "domain/repository/User/ContestRepository"

export interface GetQuestionwiseSubmissionUseCase {
  invoke(auth: Auth, email: string, examId: string, questionId: string): Promise<any>
}

export default class GetQuestionwiseSubmission implements GetQuestionwiseSubmissionUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, email: string, examId: string, questionId: string) {
    return this.repository.getQuestionwiseSubmission(auth, email, examId, questionId)
  }
}
