import React, { useState, ChangeEvent, useEffect } from "react"
import { useAuth } from "core/context/auth"
import { AddPlacementRepositoryImpl } from "data/repository/Admin/AddPlacementRepositoryImpl"
import { AddPlacementAPIDataSourceImpl } from "data/API/Admin/AddPlacementAPIDataSourceImpl"
import { GetCompanies } from "domain/useCase/Admin/Placement/GetCompnaiesList"
import { AddCompany } from "domain/useCase/Admin/Placement/AddNewPlacement"
import { UploadCompanyLogo } from "domain/useCase/Admin/Placement/UploadCompnayLogo"
import { UploadStudentPicture } from "domain/useCase/Admin/Placement/UploadProfilePicture"
import { useNavigate, useParams } from "react-router-dom"
import { GetStudentPlacement } from "domain/useCase/Admin/Placement/GetStudentPlacement"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function AddPlacementFormViewModel() {
  const [testimonial, setTestimonial] = useState<string>("")
  const [selectedCompany, setSelectedCompany] = useState<string>("")
  const [logoFile, setLogoFile] = useState<any>("")
  const [profilePicture, setProfilePicture] = useState<any>("")
  const [name, setName] = useState<string>("")
  const [yoe, setYOE] = useState<number | undefined>(undefined)
  const [position, setPosition] = useState<string>("")
  const [company_name, setcompany_name] = useState<string>("")
  const [companiesList, setCompaniesList] = useState<any>([])
  const [placementId, setPlacementId] = useState<any>("")
  const { auth } = useAuth()
  const { id } = useParams()
  const isEditMode = Boolean(id)
  const [profilePicPrev, setProfilePicPrev] = useState<any>("")
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [loading, setLoading] = useState<any>(false)
  const navigate = useNavigate()

  const GetCompaniesListUseCase = new GetCompanies(new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl()))
  const AddNewPlacementUseCase = new AddCompany(new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl()))
  const UploadCompanyLogoUseCase = new UploadCompanyLogo(
    new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl())
  )
  const UploadStudentPictureUseCase = new UploadStudentPicture(
    new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl())
  )
  const GetStudentPlacementUsecase = new GetStudentPlacement(
    new AddPlacementRepositoryImpl(new AddPlacementAPIDataSourceImpl())
  )

  const handleCompanyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(event.target.value)
    setcompany_name(event.target.value)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setLogoFile(event.target.files[0])
    }
  }
  const goBack: Function = () => {
    try {
      navigate(-1)
    } catch (error) {
      console.error("Navigation error:", error)
    }
  }
  const handleProfilePictureChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePicture(event?.target?.files[0])
    }
  }
  async function fetchPlacmentDetials(id: any) {
    const response = await GetStudentPlacementUsecase.invoke({
      id_token: auth.id_token,
      placement_id: id,
    })

    if (response?.success) {
      const data = response.data
      setName(data.name)
      setYOE(data.yoe)
      setTestimonial(data.testimonial)
      setPosition(data.position)
      setSelectedCompany(data.company_name)
      setcompany_name(data.company_name)
      setPlacementId(data.placement_id)
      // setProfilePicture(data.profile_picture)
      setProfilePicPrev(data.profile_picture)
    }
  }
  async function getCompaniesList() {
    const response = await GetCompaniesListUseCase.invoke({
      id_token: auth.id_token,
    })
    if (response.success) {
      setCompaniesList(response.data)
    }
  }

  async function uploadStudentPicture() {
    if (!profilePicture) {
      throw new Error("Profile picture is required")
    }
    const response = await UploadStudentPictureUseCase.invoke({
      id_token: auth.id_token,
      picture: profilePicture,
      name: name,
      prev_url: profilePicPrev,
    })
    if (response.success) {
      return response.data
    }
  }

  async function uploadCompanyLogo() {
    const response = await UploadCompanyLogoUseCase.invoke({
      id_token: auth.id_token,
      logo: logoFile,
      name: company_name,
    })
    if (response.success) {
      return response.data
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    try {
      setLoading(true)
      if (selectedCompany === "Other" && logoFile) {
        await uploadCompanyLogo()
      }
      let profilePictureUrl = profilePicPrev
      if (profilePicture !== "") {
        profilePictureUrl = await uploadStudentPicture()
      }
      const response = await AddNewPlacementUseCase.invoke({
        id_token: auth.id_token,
        name: name,
        picture: profilePictureUrl,
        yoe: yoe!,
        company: company_name,
        position: position,
        testimonial: testimonial,
        isEdit: isEditMode,
        placement_id: placementId,
      })

      if (response.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, "Student's Details Added Successfully")
      }
      setLoading(false)
    } catch (error) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown Error Occured")
      setLoading(false)
      console.error("Error Adding Placement:", error)
    }
  }

  return {
    toast,
    loading,
    changeToastVisibility,
    name,
    yoe,
    position,
    selectedCompany,
    companiesList,
    testimonial,
    isEditMode,
    profilePicPrev,
    handleFileChange,
    handleSubmit,
    setName,
    setTestimonial,
    setYOE,
    setPosition,
    handleCompanyChange,
    setcompany_name,
    handleProfilePictureChange,
    getCompaniesList,
    fetchPlacmentDetials,
    id,
    goBack,
  }
}
