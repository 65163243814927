import { useAuth } from "core/context/auth"
import useLocalStorage from "core/hooks/useLocalStorage"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import { PlacementBatchAPIDataSourceImpl } from "data/API/Admin/PlacementBatchAPIDataSourceImpl"
import { ContestAPIDataSourceImpl } from "data/API/User/ContestAPIDataSourceImpl"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import { PlacementBatchRepositoryImpl } from "data/repository/Admin/PlacementBatchRepositoryImpl"
import { ContestRepositoryImpl } from "data/repository/User/ContestRepositoryImpl"
import { DeleteContest } from "domain/useCase/Admin/Contest/DeleteContest"
import { AddCohortStudentList } from "domain/useCase/Admin/Placement/AddCohortStudentList"
import { DeleteExam } from "domain/useCase/Admin/Contest/DeleteExam"
import GetContestResult from "domain/useCase/Admin/Contest/GetContestResult"
import GetInstantContests from "domain/useCase/User/Contest/GetInstantContests"
import GetLiveContests from "domain/useCase/User/Contest/GetLiveContests"
import React from "react"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { useNavigate } from "react-router-dom"
export default function ContestViewModel() {
  const TABS = ["live", "past"]

  const [activeTab, setActiveTab] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [searchContest, setSearchQuestion] = React.useState<any>("")
  const [page, setPage] = React.useState(1)

  const [contests, setContests] = useLocalStorage<any>("contests", {
    live: [],
    past: [],
  })
  const pageLimit = 20

  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const getLiveContestsUseCase = new GetLiveContests(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const getInstantContestsUseCase = new GetInstantContests(new ContestRepositoryImpl(new ContestAPIDataSourceImpl()))
  const addCohortStudentListUseCase = new AddCohortStudentList(
    new PlacementBatchRepositoryImpl(new PlacementBatchAPIDataSourceImpl())
  )

  const fetchContests = async (page: number, pageLimit: number) => {
    setLoading(true)

    const liveContestsResponse = await getLiveContestsUseCase.invoke(auth, page, pageLimit)

    setLoading(false)

    if (!liveContestsResponse?.success) {
      return
    }

    const contestsObj = {
      live: liveContestsResponse?.data?.recent,
      past: liveContestsResponse?.data?.past,
      total_past: liveContestsResponse?.data?.total_past,
    }
    setContests(contestsObj)
  }
  const handlePageChange = (page: number) => {
    setPage(page)
    fetchContests(page, pageLimit)
  }
  const handleTabChange = (index: number) => {
    setActiveTab(index)
  }

  const handleSearchContestChange = (e: any) => {
    setSearchQuestion(e.target.value)
  }

  const handleSearchContest = () => {
    return function (contest: any) {
      return searchContest === "" || contest?.name?.toLowerCase()?.includes(searchContest?.toLowerCase())
    }
  }

  const redirectToContest = (id: string) => {
    navigate(`/admin/contests/${id}`)
  }
  const copyToClipboard = (id: string) => {
    if (id) {
      try {
        const origin = "https://platform.bosscoderacademy.com"
        changeToastDetails(STR_SUCCESS, `Exam Link Copied To Clipboard!`)
        changeToastVisibility(true)
        const str = `${origin}/contests/${id}`
        navigator.clipboard.writeText(str)
      } catch (error) {
        changeToastDetails(STR_FAILURE, genError(error, "Unable to copy text to clipboard"))
        changeToastVisibility(true)
      }
    }
  }

  const deleteContestUseCase = new DeleteContest(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const GetContestResultUseCase = new GetContestResult(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )

  const deleteExamUseCase = new DeleteExam(new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl()))
  const handleDeleteContest = async (id: string) => {
    setLoading(true)

    const response = await deleteExamUseCase.invoke(auth, id)
    setLoading(false)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to delete contest"))
      changeToastVisibility(true)
      return
    }
    setContests((prevContests: any) => {
      const updatedContests = {
        ...prevContests,
        live: prevContests.live.filter((contest: any) => contest.id !== id),
        past: prevContests.past.filter((contest: any) => contest.id !== id),
      }
      return updatedContests
    })
    changeToastDetails("Success", "Contest deleted successfully")
    changeToastVisibility(true)
    navigate("/admin/contests")
  }

  const generateCSV = (data: any[]) => {
    if (!data || !data.length) return ""

    const prioritizedHeaders = ["Email", "Phone", "Batch", "Name"]

    const otherHeaders = Object.keys(data[0]).filter((key) => !key.startsWith("$") && !prioritizedHeaders.includes(key))

    const headers = [...prioritizedHeaders, ...otherHeaders]

    let csvContent = `data:text/csv;charset=utf-8,${headers.join(",")}\n`

    data.forEach((row) => {
      const csvRow = headers
        .map((header) => {
          return row[header] !== undefined && row[header] !== null ? row[header] : ""
        })
        .join(",")
      csvContent += `${csvRow}\n`
    })

    return encodeURI(csvContent)
  }

  const triggerCSVDownload = (encodedUri: string, fileName: string) => {
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getContestResult = async (id: any) => {
    try {
      setLoading(true)

      const response = await GetContestResultUseCase.invoke(auth, id)
      setLoading(false)

      if (response?.success) {
        const data = response.data || []

        if (!data.length) {
          changeToastDetails("Failure", "No data available for the contest result")
          changeToastVisibility(true)
          setTimeout(() => changeToastVisibility(false), 5000)
          return
        }

        // Generate and download CSV
        const encodedUri = generateCSV(data)
        triggerCSVDownload(encodedUri, "Contest_Result.csv")

        changeToastDetails("Success", "Contest result downloaded successfully")
        changeToastVisibility(true)
        setTimeout(() => changeToastVisibility(false), 5000)
      } else {
        changeToastDetails("Failure", "Unable to download contest result")
        changeToastVisibility(true)
        setTimeout(() => changeToastVisibility(false), 5000)
      }
    } catch (error) {
      setLoading(false)
      changeToastDetails("Failure", "An error occurred while downloading contest result")
      changeToastVisibility(true)
      setTimeout(() => changeToastVisibility(false), 5000)
      console.error("Error fetching contest result:", error)
    }
  }

  const handleUpdatePlacementCohortBatch = async (
    contestId: string,
    placementBatchName: string,
    bridgeBatchName: string,
    passingScore: number,
    created_on: number
  ) => {
    try {
      const response = await addCohortStudentListUseCase.invoke(
        auth,
        contestId,
        placementBatchName,
        bridgeBatchName,
        passingScore,
        created_on
      )
    } catch (error) {
      console.error("Failed to update placement cohort batch", error)
    }
  }

  return {
    TABS,
    activeTab,
    loading,
    contests,
    searchContest,

    fetchContests,
    getContestResult,
    handleSearchContestChange,
    handleSearchContest,
    handleTabChange,
    redirectToContest,
    handleDeleteContest,
    copyToClipboard,
    changeToastDetails,
    changeToastVisibility,
    toast,
    handlePageChange,
    page,
    pageLimit,
    handleUpdatePlacementCohortBatch,
  }
}
