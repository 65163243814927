import Toast from "core/components/Toast"
import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import useContestDuplicateViewModel from "./ContestDuplicateViewModel"
import Button from "core/components/new/Button"
import ConditionalLoader from "core/components/ConditionalLoader"
import Select from "core/components/new/Select"
import Input from "core/components/new/Input"
import Spinner from "core/components/Spinner"
import { STR_ADD, STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import { CrossIcon } from "core/constants/svgs"

export default function ContestDuplicateView() {
  const {
    id,
    toast,
    loading,
    name,
    nickname,
    duration,
    casestudyQuestion,
    subjectiveQuestion,
    type,
    generalInfo,
    mcqList,
    questions,
    mcqs,
    question,
    mcq,
    allFieldsValid,
    questionScore,
    mcqScore,
    batches,
    casestudyQuestions,
    subjectiveQuestions,
    casestudyQuestionScore,
    subjectiveQuestionScore,
    casestudyQuestinonList,
    subjectiveQuestionList,
    fetchContestDetails,
    checkIfAllFieldsAreFilled,
    handleRemoveQuestion,
    handleRemoveMcq,
    handleGeneralInfoChange,
    handleAddQuestion,
    handleAddMcq,
    handleQuestionScoreChange,
    handleCaseStudyQuestionScoreChange,
    handleSubjectiveQuestionScoreChange,
    handleCaseStudyQuestionChange,
    handleSubjectiveQuestionChange,
    handleMcqScoreChange,
    handleQuestionChange,
    handleMcqChange,
    changeToastVisibility,
    redirectToContests,
    handleNameChange,
    handleNickNameChange,
    handleDurationChange,
    handleStartTimeChange,
    handleTypeChange,
    fetchQuestionList,
    fetchCaseStudiesQuestion,
    fetchSubjectiveQuestion,
    fetchMcqQuestionList,
    handleDuplicateContest,
    fetchBatchList,
    isICPContest,
    handleIsICPContestChange,
    passing_criteria,
    handlePassingCriteriaChange,
    module,
    fetchModulewiseExamList,
    isProctored,
    handleProctoringChange,
    proctoringOptions,
  } = useContestDuplicateViewModel()

  React.useEffect(() => {
    checkIfAllFieldsAreFilled()
  }, [name, duration, type, generalInfo, questions, mcqs, passing_criteria])
  React.useEffect(() => {
    if (true) {
      fetchQuestionList()
      fetchMcqQuestionList()
      fetchCaseStudiesQuestion()
      fetchSubjectiveQuestion()
      fetchBatchList()
      if (id !== STR_ADD) {
        fetchContestDetails()
      }
    }
  }, [])

  React.useEffect(() => {
    if (batches.length > 0) {
      fetchModulewiseExamList(module, batches)
    }
  }, [module, batches])

  return (
    <DashboardLayout>
      {loading ? (
        <div className="flex justify-center items-center" style={{ width: "100%", height: "100vh" }}>
          <Spinner small />
        </div>
      ) : (
        <>
          <div className="space-y-6 p-6 text-new-neutral-dark">
            <div className="flex justify-between gap-6">
              <h2 className="text-new-accent">Duplicate Contest</h2>
              <Button className="h-fit w-fit" failure outlined onClick={redirectToContests}>
                Go Back
              </Button>
            </div>
            <div className="flex max-w-[400px] flex-col gap-4">
              <Input
                containerClass="w-full"
                type="text"
                placeholder="Title of the contest"
                value={name}
                onChange={handleNameChange}
              />
              <Input
                containerClass="w-full"
                type="text"
                placeholder="Nickname of the contest"
                value={nickname}
                onChange={handleNickNameChange}
              />

              <Input
                containerClass="w-full"
                type="number"
                placeholder="Enter duration in minutes"
                value={duration}
                onChange={handleDurationChange}
              />
              <select
                className="w-full cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                value={type}
                onChange={handleTypeChange}
              >
                <option value="">Select type</option>
                <option value="live">Live</option>
                <option value="instant">Instant</option>
              </select>
              <select
                className="w-full cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                value={isICPContest}
                onChange={handleIsICPContestChange}
              >
                <option value="false">For every student in the batch</option>
                <option value="true">Only for ICP students</option>
              </select>

              <Input
                containerClass="w-full"
                type="number"
                placeholder="Enter Passing Marks"
                value={passing_criteria}
                onChange={handlePassingCriteriaChange}
              />
            </div>
            <form className="space-y-4" onSubmit={(e) => handleAddQuestion(e, "question")}>
              <div className="space-y-2">
                <h4 className="text-new-accent">Add Questions</h4>
                <div className="flex gap-4">
                  <Input
                    type="text"
                    placeholder="Enter question"
                    containerClass="w-full max-w-[400px]"
                    value={question}
                    onChange={handleQuestionChange}
                  />
                  <Input
                    type="number"
                    placeholder="Score"
                    containerClass="max-w-[200px]"
                    value={questionScore}
                    onChange={handleQuestionScoreChange}
                  />
                  <Button outlined className="w-fit" disabled={!question || !questionScore}>
                    Add
                  </Button>
                </div>
              </div>

              {questions.length === 0 ? (
                <em className="text-xs text-new-neutral-light">No assignment problem added</em>
              ) : (
                questions.map((questionObj, index) => {
                  const key = questionObj?.problem_name

                  if (key) {
                    return (
                      <div
                        key={index}
                        className="flex w-fit items-center justify-between gap-4 rounded-sm border border-new-neutral-light px-3 py-2"
                      >
                        <div className="space-y-1">
                          <h4>{questionObj.key}</h4>
                          <h5>Score: {questionObj.score}</h5>
                        </div>
                        <button
                          type="button"
                          className="p-2"
                          onClick={() => handleRemoveQuestion(questionObj.key, "question")}
                        >
                          <CrossIcon className="h-4 w-4" />
                        </button>
                      </div>
                    )
                  }
                  return null
                })
              )}
            </form>

            <form className="space-y-4" onSubmit={handleAddMcq}>
              <div className="space-y-2">
                <h4 className="text-new-accent">Add MCQs</h4>
                <div className="flex gap-4">
                  <Input
                    type="text"
                    placeholder="Enter mcq"
                    containerClass="w-full max-w-[400px]"
                    value={mcq}
                    onChange={handleMcqChange}
                  />
                  <Input
                    type="number"
                    placeholder="Score"
                    containerClass="max-w-[200px]"
                    value={mcqScore}
                    onChange={handleMcqScoreChange}
                  />
                  <Button outlined className="w-fit" disabled={!mcq || !mcqScore}>
                    Add
                  </Button>
                </div>
              </div>

              {mcqs.length === 0 ? (
                <em className="text-xs text-new-neutral-light">No MCQ problems added</em>
              ) : (
                mcqs.map((mcq) => {
                  const mcqItem = mcqList.find((item) => item.id === mcq.key)
                  return (
                    <div
                      key={mcq.key}
                      className="flex w-fit items-center justify-between gap-4 rounded-sm border border-new-neutral-light px-3 py-2"
                    >
                      <div className="space-y-1">
                        <h4>{mcq.mcq_name}</h4>
                        <h5>Score: {mcq.score}</h5>
                      </div>
                      <button type="button" className="p-2" onClick={() => handleRemoveMcq(mcq.key)}>
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </div>
                  )
                })
              )}
            </form>

            <form className="space-y-4" onSubmit={(e) => handleAddQuestion(e, "caseStudy")}>
              <div className="space-y-2">
                <h4 className="text-new-accent">Add Case Study Questions</h4>
                <div className="flex gap-4">
                  <Input
                    type="text"
                    placeholder="Enter Case Study Question"
                    containerClass="w-full max-w-[400px]"
                    value={casestudyQuestion}
                    onChange={handleCaseStudyQuestionChange}
                  />
                  <Input
                    type="number"
                    placeholder="Score"
                    containerClass="max-w-[200px]"
                    value={casestudyQuestionScore}
                    onChange={handleCaseStudyQuestionScoreChange}
                    disabled
                  />
                  <Button
                    outlined
                    className="w-fit"
                    disabled={isEmpty(casestudyQuestinonList) || isEmpty(casestudyQuestionScore)}
                  >
                    Add
                  </Button>
                </div>
              </div>

              {casestudyQuestions.length === 0 ? (
                <em className="text-xs text-new-neutral-light">No Case Study problems added</em>
              ) : (
                casestudyQuestions.map((caseQuestion) => {
                  const quesTitle = casestudyQuestinonList.find((item) => item.id === caseQuestion?.key)?.questionTitle

                  return (
                    <div
                      key={caseQuestion?.key}
                      className="flex w-fit items-center justify-between gap-4 rounded-sm border border-new-neutral-light px-3 py-2"
                    >
                      <div className="space-y-1">
                        <h4>{quesTitle}</h4>
                        <h5>Score: {caseQuestion?.score}</h5>
                      </div>
                      <button
                        type="button"
                        className="p-2"
                        onClick={() => handleRemoveQuestion(caseQuestion?.key, "casestudy")}
                      >
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </div>
                  )
                })
              )}
            </form>

            <form className="space-y-4" onSubmit={(e) => handleAddQuestion(e, "subjective")}>
              <div className="space-y-2">
                <h4 className="text-new-accent">Add Subjective Questions</h4>
                <div className="flex gap-4">
                  <Input
                    type="text"
                    placeholder="Enter Subjective Questions "
                    containerClass="w-full max-w-[400px]"
                    value={subjectiveQuestion}
                    onChange={handleSubjectiveQuestionChange}
                  />
                  <Input
                    type="number"
                    placeholder="Score"
                    containerClass="max-w-[200px]"
                    value={subjectiveQuestionScore}
                    onChange={handleSubjectiveQuestionScoreChange}
                    disabled
                  />
                  <Button
                    outlined
                    className="w-fit"
                    disabled={isEmpty(subjectiveQuestionList) || isEmpty(subjectiveQuestionScore)}
                  >
                    Add
                  </Button>
                </div>
              </div>

              {subjectiveQuestions.length === 0 ? (
                <em className="text-xs text-new-neutral-light">No Case Study problems added</em>
              ) : (
                subjectiveQuestions.map((subQuestion) => {
                  const questionTitle = subjectiveQuestionList.find(
                    (item) => item.id === subQuestion?.key
                  )?.questionTitle
                  return (
                    <div
                      key={subQuestion?.key}
                      className="flex w-fit items-center justify-between gap-4 rounded-sm border border-new-neutral-light px-3 py-2"
                    >
                      <div className="space-y-1">
                        <h4>{questionTitle}</h4>
                        <h5>Score: {subQuestion?.score}</h5>
                      </div>
                      <button
                        type="button"
                        className="p-2"
                        onClick={() => handleRemoveQuestion(subQuestion?.key, "casestudy")}
                      >
                        <CrossIcon className="h-4 w-4" />
                      </button>
                    </div>
                  )
                })
              )}
            </form>
            <textarea
              className="w-full max-w-[400px] py-2 px-3 focus:border-new-accent focus:ring-1 focus:ring-new-accent"
              placeholder="Write general information"
              value={generalInfo}
              onChange={handleGeneralInfoChange}
            />
            <h4 className="text-new-accent">Select Proctoring Status</h4>

            <Select options={proctoringOptions} value={isProctored} onChange={handleProctoringChange}></Select>
            <div className="flex items-center gap-4">
              <Button outlined className="h-fit w-fit" failure onClick={redirectToContests}>
                Discard
              </Button>
              <Button
                outlined
                disabled={!allFieldsValid}
                className="h-fit w-40"
                onClick={handleDuplicateContest}
                loading={loading}
              >
                Duplicate Contest
              </Button>
            </div>
          </div>
          <Toast data={toast} onClick={() => changeToastVisibility(false)} />
        </>
      )}
    </DashboardLayout>
  )
}
