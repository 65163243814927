import React from "react"
import Spinner from "core/components/Spinner"
import { CrossIcon } from "core/constants/svgs"
import { Button } from "core/components/v2/Button"
import { Link } from "react-router-dom"
const OpenSlotsPopup = ({ onClose }: any) => {
  return (
    <div className="fixed inset-0 z-50">
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0" aria-hidden="true">
          <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
        </div>
        <div className="no-scrollbar flex h-screen overflow-y-auto py-5">
          <div className="font-montserrat m-auto flex h-[200px] w-[304px] transform flex-col gap-3 rounded border bg-new-solid-white p-4">
            <div className="flex items-start  justify-end">
              <div className=" w-full flex  justify-end ">
                <button onClick={onClose}>
                  <CrossIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <p className=" text-red-500 text-[14px] font-[400]">
              You Can't Cancel this Session Because your Slots are not Open Please Open atleast your 3 Slots.
            </p>

            <div className="flex gap-4 my-[16px] justify-center">
              <Link to={"/mentor/availability"}>
                <Button
                  // className="h-9 w-[134px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white"

                  variant={"primary"}
                  border={"thin"}
                >
                  Open Slots
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenSlotsPopup
