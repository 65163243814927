import ButtonGroup from "core/components/ButtonGroup"
import DashboardLayout from "core/layouts/DashboardLayout"
import AddStudentForm from "presentation/Admin/PlacementCreateBatch/components/addStudentForm"
import CreateBatchForm from "presentation/Admin/PlacementCreateBatch/components/createBatchForm"
import { useState } from "react"

export default function CreateBatchView() {
  const handleTabChange = (index: number) => {
    setActiveTab(index)
  }
  const [activeTab, setActiveTab] = useState(0)
  return (
    <DashboardLayout>
      <div className="space-y-6 p-6">
        <ButtonGroup
          buttons={["Create Placement Cohort", "Add Students to Cohort"]}
          active={activeTab}
          onChange={handleTabChange}
        />
        {activeTab === 0 && <CreateBatchForm />}
        {activeTab === 1 && <AddStudentForm />}
      </div>
    </DashboardLayout>
  )
}
