import React, { useState } from "react"
import { CreateExamTemplate } from "domain/useCase/Admin/Contest/CreateExamTemplate"
import AdminContestAPIDataSourceImpl from "data/API/Admin/ContestAPIDataSourceImpl"
import AdminContestRepositoryImpl from "data/repository/Admin/ContestRepositoryImpl"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"

export default function CreateContestFlowViewModel() {
  const { auth } = useAuth()

  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [isLoading, setIsLoading] = React.useState(false)

  const CreateExamTemplateUseCase = new CreateExamTemplate(
    new AdminContestRepositoryImpl(new AdminContestAPIDataSourceImpl())
  )
  const [templateName, setTemplateName] = useState("")
  const [phaseName, setPhaseName] = useState("")
  const [examName, setExamName] = useState("")
  const [mockName, setMockName] = useState("")
  const [examType, setExamType] = useState("")
  async function handleCreateExamTemplate() {
    if (!templateName || !phaseName) {
      changeToastDetails(STR_FAILURE, "Please fill in Template and Phase name fields")
      changeToastVisibility(true)
      return
    }

    setIsLoading(true)

    const response = await CreateExamTemplateUseCase.invoke(auth, templateName, phaseName, examName, mockName, examType)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Failed to create exam template")
      changeToastVisibility(true)
    }
    changeToastDetails(STR_SUCCESS, "Successfully created exam template")
    changeToastVisibility(true)

    setIsLoading(false)
  }

  return {
    templateName,
    setTemplateName,
    phaseName,
    setPhaseName,
    mockName,
    setMockName,

    examType,
    setExamType,
    examName,
    setExamName,
    isLoading,
    handleCreateExamTemplate,
    toast,
    changeToastVisibility,
  }
}
