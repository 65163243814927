import PlacementBatchDataSource from "data/dataSource/Admin/PlacementBatchDataSource"
import { Auth } from "domain/model/Auth"
import { PlacementBatch, StudentToPlacementBatch } from "domain/model/PlacementBatch"
import { PlacementBatchRepository } from "domain/repository/Admin/PlacementBatchRepository"
export class PlacementBatchRepositoryImpl implements PlacementBatchRepository {
  private Datasource: PlacementBatchDataSource

  constructor(Datasource: PlacementBatchDataSource) {
    this.Datasource = Datasource
  }

  async createBatch(createBatch: PlacementBatch): Promise<any> {
    return await this.Datasource.createPlacementBatch(createBatch)
  }

  async addCohortStudent(
    auth: Auth,
    contestId: string,
    placementBatchName: string,
    bridgeBatchName: string,
    passingScore: number,
    created_on: number
  ): Promise<any> {
    return await this.Datasource.addCohortStudent(
      auth,
      contestId,
      placementBatchName,
      bridgeBatchName,
      passingScore,
      created_on
    )
  }

  async addPlacementStudent(addStudent: StudentToPlacementBatch): Promise<any> {
    return await this.Datasource.addPlacementStudent(addStudent)
  }
}
