import { GetAllBatches } from "domain/useCase/Admin/CreateClass/GetAllBatches"
import { CreateClassRepositoryImpl } from "data/repository/Admin/CreateClassRepositoryImpl"
import { CreateClassAPIDataSourceImpl } from "data/API/Admin/CreateClassAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { useState } from "react"
import { SessionRescheduleRequestRepositoryImpl } from "data/repository/Admin/SessionRescheduleRequestRepositoryImpl"
import SessionRescheduleRequestAPIDataSourceImpl from "data/API/Admin/SessionRescheduleRequestAPIDataSourceImpl"
import { GetSessionRescheduleRequest } from "domain/useCase/Admin/SessionRescheduleRequest/GetSessionRescheduleRequest"
import { Mentor } from "domain/model/Mentor"
import { UpdateMentor } from "domain/useCase/Student/Mentor/UpdateMentor"
import { format, set } from "date-fns"
import { GetAllMentors } from "domain/useCase/Student/Mentor/GetAllMentors"
import { GetAllTempMentors } from "domain/useCase/Student/Mentor/GetAllTempMentors"
import { MentorRepositoryImpl } from "data/repository/Student/MentorRepositoryImpl"
import { MentorAPIDataSourceImpl } from "data/API/Student/MentorAPIDataSourceImpl"
import { CancelMentorSession } from "domain/useCase/Student/Mentor/CancelMentorSession"

import { UpdateSessionRescheduleRequest } from "domain/useCase/Admin/SessionRescheduleRequest/UpdateSessionRescheduleRequest"
import { id } from "date-fns/locale"
const SessionRescheduleRequestViewModal = () => {
  const [email, setEmail] = useState("")
  const [batches, setBatches] = useState<any>([])
  const [batch, setBatch] = useState<string>("")
  const [requestStatus, setRequestStatus] = useState("all")
  const [rescheduleRequest, setRescheduleRequest] = useState([])
  const [rescheduleRequestCpy, setRescheduleRequestCpy] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const { auth } = useAuth()

  const statusBtns = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Denied",
      value: "denied",
    },
  ]

  const getAllBatchesUseCase = new GetAllBatches(new CreateClassRepositoryImpl(new CreateClassAPIDataSourceImpl()))

  const getSessionRescheduleRequestUseCase = new GetSessionRescheduleRequest(
    new SessionRescheduleRequestRepositoryImpl(new SessionRescheduleRequestAPIDataSourceImpl())
  )

  const updateSessionRescheduleRequestUseCase = new UpdateSessionRescheduleRequest(
    new SessionRescheduleRequestRepositoryImpl(new SessionRescheduleRequestAPIDataSourceImpl())
  )
  const cancelMentorSessionUseCase = new CancelMentorSession(new MentorRepositoryImpl(new MentorAPIDataSourceImpl()))

  const fetchBatches = async () => {
    const response = await getAllBatchesUseCase.invoke(auth)

    if (!response.success) {
      return
    }

    setBatches(response?.data?.map((b: any) => ({ value: b, label: b })))
  }

  const fetchSessionRescheduleRequest = async () => {
    const res = await getSessionRescheduleRequestUseCase.invoke(auth, {
      email: email,
      batch: batch,
    })
    if (res.success) {
      setRescheduleRequest(res.data)
      const filteredData =
        requestStatus === "all" ? res.data : res.data.filter((req: any) => req.status === requestStatus)

      setRescheduleRequestCpy(filteredData)
      setRescheduleRequestCpy(res.data)
    }
  }

  function batchChange(e: any) {
    setBatch(e.target.value)
  }

  function statusChange(value: string) {
    setRequestStatus(value)

    const filteredData =
      value === "all" ? rescheduleRequest : rescheduleRequest.filter((req: any) => req.status === value)

    setRescheduleRequestCpy(filteredData)
  }

  const updateRequestStatusHandler = async (id: string, status: string) => {
    const res = await updateSessionRescheduleRequestUseCase.invoke(auth, {
      id: id,
      status: status,
    })
    if (res.success) {
      setRefresh(!refresh)
      setLoading(false)
    }
  }
  async function cancelMentorSession(id: string, status: string, session_id: string, type: string) {
    setLoading(true)
    const response = await cancelMentorSessionUseCase.invoke(auth, session_id, type, "reschedule-admin")
    if (response.success) {
      updateRequestStatusHandler(id, status)
    }
  }

  return {
    email,
    batch,
    batches,
    statusBtns,
    requestStatus,
    rescheduleRequest,
    rescheduleRequestCpy,
    refresh,
    loading,
    statusChange,
    fetchBatches,
    fetchSessionRescheduleRequest,
    batchChange,
    setEmail,
    updateRequestStatusHandler,
    cancelMentorSession,
  }
}

export default SessionRescheduleRequestViewModal
