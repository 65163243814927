import { Auth } from "domain/model/Auth"
import SessionRescheduleRequestRepository from "domain/repository/Admin/SessionRescheduleRequestRepository"
import { SessionRescheduleRequestDataSource } from "data/dataSource/Admin/SessionRescheduleRequestDataSource"
import GetSessionRescheduleRequestDetails from "domain/model/GetSessionRescheduleRequest"
import UpdateSessionRescheduleRequestDetails from "domain/model/UpdateSessionRescheduleRequest"
export class SessionRescheduleRequestRepositoryImpl implements SessionRescheduleRequestRepository {
  private dataSource: SessionRescheduleRequestDataSource

  constructor(dataSource: SessionRescheduleRequestDataSource) {
    this.dataSource = dataSource
  }

  async getSessionRescheduleRequest(auth: Auth, details: GetSessionRescheduleRequestDetails): Promise<any> {
    return this.dataSource.getSessionRescheduleRequest(auth, details)
  }

  async updateSessionRescheduleRequest(auth: Auth, details: UpdateSessionRescheduleRequestDetails): Promise<any> {
    return this.dataSource.updateSessionRescheduleRequest(auth, details)
  }
}
