import React, { useState } from "react"
import Confetti from "assets/svgs/Confettinew.gif"
import tick from "assets/svgs/tick.svg"
import { CrossIcon } from "core/constants/svgs"

const PassedState: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true)

  const closeModal = () => {
    setIsOpen(false)
  }

  if (!isOpen) return null

  return (
    <div className="z-[999] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="relative w-[274px] h-[346px] rounded-lg border border-gray-400 bg-white shadow-sm overflow-hidden">
        <button className="absolute top-3 right-3 z-20" onClick={closeModal}>
          <CrossIcon className="h-6 w-6" />
        </button>

        <div className="relative flex flex-col items-center justify-center h-full z-10">
          <img src={tick} alt="Tick" className="w-[152px] h-[152px] mb-4 z-10" />

          <div className="flex flex-col items-center text-center w-[179px] h-[60px]">
            <h2 className="text-green-800 font-bold text-xl mt-2 z-10">Passed!!</h2>
            <p className="text-black font-normal text-[12px] mt-1 z-10 w-[179px] leading-[14.52px] ">
              You have successfully passed the exam.
            </p>
          </div>
        </div>

        <img src={Confetti} alt="Confetti" className="absolute w-[273px] h-[218px] top-[100px] left-[0.5px] z-0" />
      </div>
    </div>
  )
}

export default PassedState
