import { Auth } from "domain/model/Auth"
import ContestRepository from "domain/repository/Student/ContestRepository"

export interface getExamwiseLeaderboardUseCase {
  invoke(auth: Auth, emailId: string): Promise<any>
}

export default class getExamwiseLeaderboard implements getExamwiseLeaderboardUseCase {
  private repository: ContestRepository

  constructor(repository: ContestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, emailId: string): Promise<any> {
    return this.repository.getExamwiseLeaderboard(auth, emailId)
  }
}
