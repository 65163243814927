import { QuestionBankRepository } from "domain/repository/Student/QuestionBankRepository"
import { Auth } from "domain/model/Auth"

export interface GetQuestionBankUseCase {
  invoke(auth: Auth): Promise<any>
}

export class GetQuestionBank implements GetQuestionBankUseCase {
  private repository: QuestionBankRepository

  constructor(repository: QuestionBankRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth): Promise<any> {
    return this.repository.getQuestionBank(auth)
  }
}
