import React, { useState, useEffect } from "react"
import CertificateGradient from "core/components/new/CertificateGradient"
import { Button } from "core/components/v2/Button"
import lockImage from "assets/images/lock.png"
import CertificateImage from "assets/images/JohnDoe.jpg"
import ModuleCompletionJohnDoe from "assets/images/ModuleCompletionJohnDoe.png"
import celebrationImage from "assets/images/celebration.png"
import Confetti from "assets/svgs/Confettinew.gif"

interface ModuleCompletionCardProps {
  courseCompleted: boolean
  certificate_url: string
  setShowCelebration: (value: boolean) => void
}

const ModuleCompletionCard: React.FC<ModuleCompletionCardProps> = ({
  courseCompleted,
  certificate_url,
  setShowCelebration,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState("")
  const [countdown, setCountdown] = useState(5)

  const cardStyles = courseCompleted === true ? "bg-[#162456] border-[#2C689F]" : "bg-[#333333] border-[#D3D3D3]"

  const shareOnLinkedIn = () => {
    const addcertificate_url = `https://www.linkedin.com/profile/add?startTask=CourseCompletedByBosscoder&organizationName=${encodeURIComponent(
      "Bosscoder Academy"
    )}&issueYear=${new Date().getFullYear()}&issueMonth=${
      new Date().getMonth() + 1
    }&certUrl=${encodeURIComponent(certificate_url)}`

    setRedirectUrl(addcertificate_url)
    setShowModal(true)
    setShowCelebration(true)
    setCountdown(5)

    const message =
      "I'm excited to share that I've successfully completed a key module as part of my ongoing journey with Bosscoder Academy! 🎓 This module was packed with valuable insights and practical skills, which have further strengthened my expertise. A big shoutout to the Bosscoder team for their continued support and guidance. I can't wait to put this new knowledge into action and keep pushing forward in my learning journey. Here's to growth and endless possibilities! 💡 #BosscoderAcademy #SkillDevelopment #KeepLearning #CareerGrowth"
    navigator.clipboard.writeText(message).then(() => {
      console.log("Message copied to clipboard!")
    })
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (showModal && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else if (showModal && countdown === 0) {
      try {
        window.open(redirectUrl, "_blank")
      } catch (e) {
        console.warn("Pop-ups blocked. Please use the link provided.")
      }
      setShowModal(false)
    }

    return () => clearTimeout(timer)
  }, [showModal, countdown, redirectUrl])

  const animationStyle = {
    animation: "slide 3s linear infinite",
  }

  return (
    <div
      className={`relative w-full overflow-hidden rounded-lg border-[0.5px] ${cardStyles} px-6 py-4 shadow-[-1px_1px_3px_0_rgba(34,41,48,0.15)]`}
    >
      <div className="flex items-start justify-between">
        <div>
          <div className="font-semibold">
            <p className="font-semibold text-[14px] leading-[22.94px] text-white">Module Completion Certificate</p>
          </div>
          {courseCompleted ? (
            <>
              <p className="font-normal text-[12px] leading-[22.94px] text-white">
                Congratulations! You have completed the module.
              </p>
              <p className="font-normal text-[12px] leading-[8.94px] text-white">
                Share your Certificate with your peers
              </p>
            </>
          ) : (
            <>
              <p className="font-normal text-[12px] leading-[22.94px] text-white">
                Certificate will be issued upon completing the module.
              </p>
              <p className="font-normal text-[12px] leading-[8.94px] text-white">
                Complete the milestones to earn your certificate!
              </p>
            </>
          )}
          <div className="mt-[14px] font-semibold">
            <Button
              variant={"icon"}
              size={"md"}
              disabled={!courseCompleted}
              onClick={shareOnLinkedIn}
              className="py-[9px] px-[16px] text-center"
            >
              {courseCompleted ? (
                "Share on LinkedIn"
              ) : (
                <span className="flex items-center w-120px h-17px">
                  <img src={lockImage} alt="Lock" className="w-16px h-16px mr-2" />
                  Share on LinkedIn
                </span>
              )}
            </Button>
          </div>
        </div>
        <img
          src={ModuleCompletionJohnDoe}
          alt="John Doe"
          className="w-[134px] h-[103px] flex-shrink-0 rounded-[4.12px] border-[0.162px] border-[rgba(14,45,126,0.50)] bg-white ml-4"
        />
      </div>

      <div className="absolute top-0 left-[275px]">
        <CertificateGradient className={courseCompleted ? "bg-blue-200" : "bg-gray-500"} />
      </div>
      <div className="absolute top-0 left-[350px]">
        <CertificateGradient className={courseCompleted ? "bg-blue-200" : "bg-gray-500"} />
      </div>
    </div>
  )
}

export default ModuleCompletionCard
