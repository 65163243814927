import { useState } from "react"
import { useApp } from "core/context/app"
import { useAuth } from "core/context/auth"
import { PaymentAPIDataSourceImpl } from "data/API/Student/PaymentAPIDataSourceImpl"
import { PaymentRepositoryImpl } from "data/repository/Student/PaymentRepositoryImpl"
import { GetFeeAfterDiscount } from "domain/useCase/Student/Payment/GetFeeAfterDiscount"
import React from "react"
import { GetMethodDiscount } from "domain/useCase/Student/Payment/GetMethodDiscount"
import { FetchScratchCardDetails } from "domain/useCase/Student/Payment/FetchScratchCardDetails"
import { TPaymentDocumentItem, TStudentEMI } from "domain/model/Payment"
import { GetStudentPaymentDetailsAll } from "domain/useCase/Student/Payment/GetStudentDetailsAll"
import { capitalize } from "core/utils/string"
import { GetStudentPaymentDetails } from "domain/useCase/Student/Payment/GetStudentPaymentDetails"
import { GetPaymentDocuments } from "domain/useCase/Student/Payment/GetPaymentDocuments"
import { UploadPaymentDocument } from "domain/useCase/Student/Payment/UploadPaymentDocument"
import { DeletePaymentDocument } from "domain/useCase/Student/Payment/DeletePaymentDocument"
import { GetStudentEMI } from "domain/useCase/Student/Payment/GetStudentEmi"
import { PDFDownloadLink } from "@react-pdf/renderer"
import InvoiceTemplate from "../PaymentOptions/components/InvoiceTemplate"
import useToast from "core/hooks/useToast"
import { CreateStudentEMI } from "domain/useCase/Student/Payment/CreateStudentEMI"
import { PaymentOneShot } from "domain/useCase/Student/Payment/CreatePaymentOneShot"
import Download from "assets/svgs/payments/Download"

export default function PaymentMethodsViewModel() {
  const { auth } = useAuth()
  const { student } = useApp()

  const [scratchCardDetails, setScratchCardDetails] = React.useState<any>({})
  const [loading, setLoading] = React.useState<any>(false)
  const [paymentDiscount, setPaymentDiscount] = React.useState<any>({})
  const [paymentMethod, setPaymentMethod] = React.useState<any>({})
  const [paidOrNot, setPaid] = React.useState<any>({})
  const [payableMonth, setPayableMonth] = useState("")

  const [isDSML, setIsDSML] = useState(true)
  const [isEV, setIsEV] = useState(false)
  const [isAccelerator, setIsAccelerator] = useState(false)
  const [isAC2, setIsAC2] = React.useState(false)
  const [isAC, setIsAC] = React.useState(false)
  const [isElite, setIsElite] = React.useState(false)
  const [payDataAll, setPayDataAll] = React.useState<any>({})
  const [payData, setPayData] = React.useState<any>([])

  const [isCompletelyPaid, setIsCompletelyPaid] = React.useState<any>(false)

  const getPaymentDocumentsUseCase = new GetPaymentDocuments(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const createPaymentOneShotUseCase = new PaymentOneShot(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  const [paymentDocuments, setPaymentDocuments] = React.useState<TPaymentDocumentItem[]>([])

  const { toast, changeToastDetails, changeToastVisibility } = useToast()

  const getPaymentDocuments = async () => {
    const response = await getPaymentDocumentsUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
    setPaymentDocuments(response?.data?.documents)
  }

  const getStudentPaymentAllUseCase = new GetStudentPaymentDetailsAll(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )

  const createStudentEMIUseCase = new CreateStudentEMI(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  const getStudentEMIUseCase = new GetStudentEMI(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  const createStudentEMI = async (studentEMI: TStudentEMI) => {
    const response = await createStudentEMIUseCase.invoke(auth, studentEMI)

    if (!response?.success) {
      return
    }
    setStudentEMI(response?.data)
  }

  const [activeInstallment, setActiveInstallment] = React.useState({
    number: 0,
    due_time: "",
  })

  const [disableInitiateNow, setDisableInitiateNow] = React.useState<any>(false)
  const [paymentLink, setPaymentLink] = React.useState<any>("")

  const handleGeneratePaylink = async () => {
    setDisableInitiateNow(true)
    const response = await createPaymentOneShotUseCase.invoke(auth)
    setPaymentLink(response?.data?.paymentLink)
    if (response?.data?.paymentLink) {
      setShowPayNow(true)
      setDisableInitiateNow(false)
    }
  }

  const [paymentOptionDiscount, setPaymentOptionDiscount] = React.useState<any>({})
  const [activeDirectPaymentButtonType, setActiveDirectPaymentButtonType] = useState<any>("One Shot")

  const [studentEMI, setStudentEMI] = React.useState<any>({
    duration_index: -1,
    is_submitted: false,
    progress: [],
  })
  const [showPayNow, setShowPayNow] = React.useState<any>(false)

  const getStudentEMI = async () => {
    const response = await getStudentEMIUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
    setStudentEMI(response?.data)
  }

  const [emiAmount, setEmiAmount] = React.useState<number>(0)
  const interestRate =
    student?.batch[0] === "B"
      ? [0, 3.9, 8.9, 11.9]
      : student?.batch[0] === "D"
        ? [0, 3.9, 8.9, 11.9, 14.9, 16.9]
        : [0, 3.9, 8.9, 11.9, 14.9, 16.9]

  const getMethodDiscountUseCase = new GetMethodDiscount(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))
  const getFeeAfterDiscountUseCase = new GetFeeAfterDiscount(new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl()))

  const fetchScratchCardDetailsUseCase = new FetchScratchCardDetails(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )

  const getStudentPaymentUseCase = new GetStudentPaymentDetails(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )
  const uploadPaymentDocumentUseCase = new UploadPaymentDocument(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )
  const deletePaymentDocumentUseCase = new DeletePaymentDocument(
    new PaymentRepositoryImpl(new PaymentAPIDataSourceImpl())
  )

  const [studentTransactionTableData, setStudentTransactionTableData] = React.useState<any>({
    header: [],
    rows: [],
  })

  const uploadPaymentDocument = async (file: File, filename: string, documentId: number) => {
    const response = await uploadPaymentDocumentUseCase.invoke(auth, file, filename, documentId)
    if (!response?.success) {
      return
    }
  }

  const deletePaymentDocument = async (docuemntId: number) => {
    const response = await deletePaymentDocumentUseCase.invoke(auth, docuemntId)
    if (!response?.success) {
      return
    }
  }

  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp * 1000)
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions
    return date.toLocaleDateString("en-US", options)
  }

  async function getStudentPaymentDetailsAll() {
    const response = await getStudentPaymentAllUseCase.invoke(auth)
    if (response?.success) {
      setPayDataAll(response?.data)
      const header = ["Sr. No.", "Payment Type", "UTR Number", "Date", "Amount", "Status", "Invoice"]

      const instalments = response?.data?.instalments
      let rownumber = 1
      const rows = response?.data?.instalments
        .filter((payment: any) => payment.status === "paid")
        .map((payment: any, index: any) => {
          const dueDateParts = payment.due_time.split(" ")
          const formattedDueDate = dueDateParts.slice(0, 4).join(" ")
          const feeType = payment.payment_type === "seat_booking" ? "Seat booking" : `Installment ${rownumber}`

          if (payment.payment_type !== "seat_booking") rownumber++

          payment.amount =
            studentEMI.duration_index !== -1 && payment.payment_type !== "seat_booking" ? emiAmount : payment.amount

          const paymentDescription = response?.data?.instalments.length > 2 ? "Bosscoder Direct" : "Direct to Bosscoder"
          return {
            "No.": index + 1,
            "Fee Type": feeType,
            "Reference No.": payment.invoice_number,
            "Received on": formatDate(payment.paid_at),
            Amount: `${payment.amount} ₹`,
            Status: (
              <span className={`p-4 ${payment.status === "paid" ? "text-500 text-[#00C22B]" : ""}`}>
                {capitalize(payment.status)}
              </span>
            ),
            "Download Receipts": (
              <span
                className={`p-4 ${payment.status === "paid" ? "text-500-14px border-[#312E81] text-[#312E81]" : ""}`}
              >
                <PDFDownloadLink
                  className="font-medium text-new-accent"
                  document={
                    <InvoiceTemplate
                      data={{
                        payment: payment,
                        index: rownumber,
                        payment_type: payment.payment_type,
                        description: paymentDescription,
                      }}
                    />
                  }
                  fileName="Invoice.pdf"
                >
                  <div className="rounded-[4px] border-[0.5px] border-[#D3D3D3] w-6 h-6 flex justify-center items-center">
                    <Download />
                  </div>
                </PDFDownloadLink>
              </span>
            ),
          }
        })
      setStudentTransactionTableData({ header, rows })
      if (instalments) {
        let number_of_paid = 0
        for (let index = 0; index < instalments.length; index++) {
          const item = instalments[index]
          if (item.status === "paid") {
            number_of_paid++
          }
        }
        for (let index = 0; index < instalments.length; index++) {
          const item = instalments[index]
          if (item.status === "created") {
            setActiveInstallment({
              due_time: item.due_time,
              number: number_of_paid + 1,
            })
            break
          }
        }
      }

      let paidNo = 0
      response?.data?.instalments?.map((item: any) => {
        if (item.status === "paid") {
          paidNo++
        }
      })
      if (paidNo === response?.data?.instalments?.length && paidNo !== 1) setIsCompletelyPaid(true)
    }
  }

  async function getStudentPaymentDetails() {
    const response = await getStudentPaymentUseCase.invoke(auth)
    if (response?.success) {
      setPayData(response?.data)
      for (let i = 0; i < response?.data.length; i++) {
        if (response?.data[i].status === "created") {
          setPaymentLink(response?.data[i].link)
          break
        }
      }
    }
  }

  const fetchPaymentDetails = async () => {
    const response = await getMethodDiscountUseCase.invoke(auth)
    if (!response?.success) {
      return
    }
  }

  const fetchScratchCardDetails = async () => {
    setLoading(true)
    const response = await fetchScratchCardDetailsUseCase.invoke(auth)
    if (!response?.success) {
      setLoading(false)

      return
    }
    setLoading(false)
    setScratchCardDetails(response?.data)
  }

  const calculateOneshotDiscount = () => {
    const discountAmount =
      (payDataAll?.fees - payDataAll?.standard_discount - payDataAll?.seat_booking_amount) *
      (payDataAll?.one_shot_discount / 100)

    return Math.max(0, Math.round(discountAmount))
  }

  function parseDate(inputString: any) {
    if (!inputString) return ""
    const year = inputString?.substring(0, 4)
    const month = inputString?.substring(4, 6)
    const dateObject = new Date(`${year}-${month}-01`)
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
    }).format(dateObject)
    return formattedDate
  }

  function formatDueDateWithOutGMT(dueTime: string): string {
    const dueDateParts = dueTime.split(" ")
    return dueDateParts.slice(0, 4).join(" ")
  }

  const fetchPaymentOptionDetails = async () => {
    const response = await getFeeAfterDiscountUseCase.invoke(auth)

    if (!response?.success) {
      return
    }
    setPaymentOptionDiscount(response?.data)
    const amount = response?.data?.fees_after_discount
    if (studentEMI.duration_index !== -1) {
      setEmiAmount(Math.ceil(amount + (amount * interestRate[studentEMI?.duration_index]) / 100))
    }
  }
  return {
    student,
    paidOrNot,
    paymentMethod,
    paymentDiscount,
    isEV,
    isDSML,
    isAccelerator,
    payableMonth,
    isAC2,
    isAC,
    isElite,
    scratchCardDetails,
    loading,
    setIsAC2,
    setIsAC,
    setIsAccelerator,
    setIsEV,
    setIsDSML,
    setIsElite,
    setPayableMonth,
    fetchPaymentDetails,
    parseDate,
    fetchScratchCardDetails,
    setScratchCardDetails,
    fetchPaymentOptionDetails,
    studentEMI,
    paymentOptionDiscount,
    payDataAll,
    getStudentPaymentDetailsAll,
    calculateOneshotDiscount,
    payData,
    getStudentPaymentDetails,
    isCompletelyPaid,
    getPaymentDocuments,
    paymentDocuments,
    uploadPaymentDocument,
    deletePaymentDocument,
    getStudentEMI,
    studentTransactionTableData,
    toast,
    changeToastDetails,
    changeToastVisibility,
    createStudentEMI,
    activeInstallment,
    formatDueDateWithOutGMT,
    handleGeneratePaylink,
    paymentLink,
    showPayNow,
    activeDirectPaymentButtonType,
    setActiveDirectPaymentButtonType,
    disableInitiateNow,
  }
}
