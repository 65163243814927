import { useEffect, useState } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import useHomeViewModel from "./HomeViewModel"
import Toast from "core/components/Toast"
import Avatar from "react-avatar"
import Input from "core/components/new/Input"
import { isEmpty } from "core/utils/misc"
import ReferralModal from "presentation/Student/ReferandEarn/components/ReferralModal"
import { Button } from "core/components/v2/Button"
import { X } from "lucide-react"

export default function HomeView() {
  const { toast, influencer, changeToastVisibility, Influencer, updateInfluencerProfile, setInfluencer } =
    useHomeViewModel()
  const [razorpayStatusChanged, setRazorpayStatusChanged] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Sync Influencer state when influencer prop updates
  useEffect(() => {
    if (influencer) {
      setInfluencer({
        ...influencer,
        address: influencer.address || "",
        beneficiary_name: influencer.beneficiary_name || "",
        IFSC: influencer.IFSC || "",
        account_number: influencer.account_number || "",
        isRazorpaySignedIn: influencer.isRazorpaySignedIn || false,
      })
    }
  }, [influencer, setInfluencer])

  // Handle input changes
  const onChangeInfluencerObj = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    field?: string,
    value?: any
  ) => {
    if (field) {
      setInfluencer((prev: any) => ({
        ...prev,
        [field]: value,
      }))
    } else {
      const { id, value } = e!.target
      setInfluencer((prev: any) => ({
        ...prev,
        [id]: value,
      }))
    }
  }

  // Toggle Razorpay status
  const toggleRazorpayStatus = () => {
    const newStatus = !Influencer.isRazorpaySignedIn
    setInfluencer((prev: any) => ({
      ...prev,
      isRazorpaySignedIn: newStatus,
    }))
    setRazorpayStatusChanged(true)
  }

  // Trigger API call when Razorpay status changes
  useEffect(() => {
    if (razorpayStatusChanged) {
      updateInfluencerProfile()
      setRazorpayStatusChanged(false)
    }
  }, [razorpayStatusChanged])

  return (
    <DashboardLayout options={{ isBorderVisible: true }}>
      <div className="relative bg-white rounded-lg shadow-md divide-new-neutral-light">
        {/* Profile Section */}
        <div className="flex gap-6 p-6">
          <Avatar
            src={influencer?.photo}
            alt="profile pic"
            className="shadow-lg border-2 border-gray-200"
            name={influencer.name}
          />
          <div className="flex flex-col gap-2 w-full">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">{influencer?.name}</h2>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Email:</span> <strong>{influencer?.email}</strong>
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Phone:</span> <strong>{influencer?.phone}</strong>
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Current Company:</span> <strong>{influencer?.company_name}</strong>
            </p>
            <p className="text-sm text-gray-600">
              <span className="font-medium">Experience:</span> <strong>{influencer?.experience}</strong>
              <span>{influencer?.experience > 1 ? " years" : " year"}</span>
            </p>
          </div>
        </div>

        {/* Razorpay Toggle Button */}
        <div className="absolute top-6 right-6 flex items-center gap-2">
          <p className="text-sm text-gray-600 font-medium">Razorpay Sign-In Status:</p>
          <div
            role="switch"
            aria-checked={Influencer.isRazorpaySignedIn}
            onClick={toggleRazorpayStatus}
            className={`relative w-14 h-7 flex items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out ${
              Influencer.isRazorpaySignedIn ? "bg-green-500" : "bg-red-500"
            }`}
          >
            <div
              className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                Influencer.isRazorpaySignedIn ? "translate-x-7" : "translate-x-0"
              }`}
            ></div>
          </div>
        </div>

        {/* Show Invoicing Details Button */}
        <button
          className="absolute bottom-4 right-6 px-4 py-2 text-sm text-black border border-gray-400 rounded-md"
          onClick={() => setIsModalOpen(true)}
        >
          Add Bank Details
        </button>
      </div>

      {/* Modal for Bank Details */}
      <ReferralModal isOpen={isModalOpen} backgroundOpacity="0.7" onClose={() => setIsModalOpen(false)}>
        <div className="w-[348px] h-[533px] bg-white rounded-[8px] flex flex-col">
          {/* Upper Section with Padding */}
          <div className="pt-[24px] px-[24px] flex-grow">
            <div className="flex justify-between items-center ">
              <p className="text-[#333] text-[16px] font-[600]">Bank Details</p>
              {/* Close Button */}
              <button
                className="text-[#646464] text-[18px] font-bold focus:outline-none"
                onClick={() => setIsModalOpen(false)}
              >
                <X />
              </button>
            </div>
            <p className="text-sm text-[14px] text-[#646464] mb-4">Please enter your bank details</p>

            {/* Beneficiary Name Input */}
            <div className="mb-4">
              <label
                htmlFor="beneficiary_name"
                className="text-sm text-[14px] text-[#333] font-semibold block mb-[0.2rem]"
              >
                Beneficiary Name
              </label>
              <Input
                placeholder="Type here"
                value={Influencer?.beneficiary_name}
                onChange={onChangeInfluencerObj}
                id="beneficiary_name"
              />
            </div>

            {/* IFSC Code Input */}
            <div className="mb-4">
              <label htmlFor="IFSC" className="text-sm text-[14px] text-[#333] font-semibold block mb-[0.2rem]">
                IFSC Code
              </label>
              <Input placeholder="Type here" value={Influencer.IFSC} onChange={onChangeInfluencerObj} id="IFSC" />
            </div>

            {/* Account Number Input */}
            <div className="mb-4">
              <label
                htmlFor="account_number"
                className="text-sm text-[14px] text-[#333] font-semibold block mb-[0.2rem]"
              >
                Account Number
              </label>
              <Input
                placeholder="Type here"
                value={Influencer.account_number}
                onChange={onChangeInfluencerObj}
                id="account_number"
              />
            </div>

            {/* Address Textarea */}
            <div className="mb-4">
              <label htmlFor="address" className="text-sm text-[14px] text-[#333] font-semibold block mb-[0.2rem]">
                Address
              </label>
              <textarea
                className="w-[299px] h-[127px] rounded-[4px] border border-[#D3D3D3] bg-white focus:outline-none focus:border-[#D3D3D3] focus:ring-1 focus:ring-[#D3D3D3]"
                placeholder="Type here"
                value={Influencer?.address}
                id="address"
                onChange={onChangeInfluencerObj}
              />
            </div>
          </div>

          {/* Lower Section Without Padding */}
          <div className="flex justify-end bg-[#F9F9F9] w-full rounded-[8px]">
            <div className="flex gap-[14px] p-[8px]">
              <Button
                onClick={() => setIsModalOpen(false)}
                className="w-[80px] h-[35px] text-[14px] leading-[9px]"
                variant="cancel"
                border="thin"
              >
                Cancel
              </Button>
              <Button
                onClick={updateInfluencerProfile}
                className="w-[80px] h-[35px] text-[14px] leading-[9px]"
                border="thin"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </ReferralModal>

      {/* Campaigns & Programs Section */}
      <div className="flex flex-col gap-4 p-6 bg-gray-50 rounded-lg mt-4">
        <p className="font-semibold text-lg text-gray-800">Campaigns Enrolled:</p>
        <ul className="list-disc pl-6">
          {isEmpty(influencer?.selected_campaign) ? (
            <p className="text-sm text-gray-500">No campaigns</p>
          ) : (
            influencer?.selected_campaign.map((item: any, index: any) => (
              <li key={index} className="text-sm text-gray-800 mb-2">
                {item}
              </li>
            ))
          )}
        </ul>

        <p className="font-semibold text-lg text-gray-800 mt-4">Programs Enrolled:</p>
        <ul className="list-disc pl-6">
          {isEmpty(influencer?.selected_programs) ? (
            <p className="text-sm text-gray-500">No programs</p>
          ) : (
            influencer?.selected_programs.map((item: any, index: any) => (
              <li key={index} className="text-sm text-gray-800 mb-2">
                {item}
              </li>
            ))
          )}
        </ul>
      </div>

      {/* Toast Notification */}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
