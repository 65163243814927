import { InstructorAvailabilityDataSource } from "data/dataSource/Mentor/InstructorAvailabilityDataSource"
import { Auth } from "domain/model/Auth"
import { InstructorAvailability } from "domain/model/Mentor"

import { InstructorAvailabilityRepository } from "domain/repository/Mentor/InstructorAvailabilityRespository"

export class InstructorAvailabilityRepositoryImpl implements InstructorAvailabilityRepository {
  private dataSource: InstructorAvailabilityDataSource

  constructor(dataSource: InstructorAvailabilityDataSource) {
    this.dataSource = dataSource
  }

  async updateInstructorAvailability(auth: Auth, date: InstructorAvailability) {
    return await this.dataSource.updateInstructorAvailability(auth, date)
  }

  async getInstructorAvailability(auth: Auth) {
    return await this.dataSource.getInstructorAvailability(auth)
  }
}
