import { CommonInfluencerRepository } from "domain/repository/Common/CommonInfluencerRepository"
import { Auth } from "domain/model/Auth"

export interface GetAllInfluencerGeneratedInvoiceUseCase {
  invoke(auth: Auth, email?: string): Promise<any>
}

export class GetInvoiceGeneratedDetails implements GetAllInfluencerGeneratedInvoiceUseCase {
  private repository: CommonInfluencerRepository
  constructor(repository: CommonInfluencerRepository) {
    this.repository = repository
  }
  async invoke(auth: Auth, email?: string): Promise<any> {
    return await this.repository.getAllInfluencerGeneratedInvoiceDetails(auth, email)
  }
}
