import card_1 from "assets/images/payment-method/card_1.png"
import card_2 from "assets/images/payment-method/card_2.png"
import card_3 from "assets/images/payment-method/card_3.png"
import scratch1 from "assets/images/payment-method/scratch1.png"
import scratch2 from "assets/images/payment-method/scratch2.png"
import scratch3 from "assets/images/payment-method/scratch3.png"
import scratch_goodies from "assets/images/payment-method/scratch_card_goodies.png"
import { Button } from "core/components/v2/Button"
import LineFull from "core/components/payment-method/LineFull"
import { Link } from "react-router-dom"
import { useApp } from "core/context/app"

const OfferBanner = ({ remaining_time, scratchCardDetails }: any) => {
  const { student } = useApp()
  const oneTimeDiscount = student?.batch?.startsWith("EL") === true ? "5,000" : "10,000"
  return (
    <div className="flex relative overflow-hidden justify-between h-[241px]  rounded-[8px] bg-[#333] ">
      <div className="absolute top-0 animate-moveWhite">
        <LineFull />
      </div>
      <div className="absolute top-0 animate-moveWhiteDelayed">
        <LineFull />
      </div>
      <div className="mx-[32px] w-full my-[24px]">
        <div className="flex justify-between">
          <div>
            <span className="flex justify-center items-center gap-[10px] rounded-[4px]  bg-[#ffffff4f] w-[121px] h-[29px] p-[8px] text-[17px] text-[#FFF] font-[500] font-[Inter] shadow-[4px_4px_0px_0px_rgba(0,0,0,0.25)]">
              Off Upto
            </span>

            <div className=" text-white">
              <span style={{ textShadow: "-3px 5px 0px #2AA469" }} className="text-[65px] font-[800] font-[Inter]">
                {oneTimeDiscount}
              </span>
              <span className="text-[17px] text-[#FFF] font-[500] font-[Inter] size[21px]">Rs</span>
            </div>
          </div>
          <div className="flex">
            {scratchCardDetails?.hasOwnProperty("card1") ? (
              <div className="relative w-[120px] h-[132px]">
                <p className="absolute bottom-[33px] left-[36px] max-xl:left-[40px] z-10 text-[10px] text-[#108730] font-[500]">
                  Rs {scratchCardDetails?.card1?.amount} Off
                </p>

                <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500`}>
                  <img alt="" src={scratch1} className="w-[120px] h-[132px]" />
                </div>
              </div>
            ) : (
              <img src={card_1} alt="" className="h-[132px] w-[120px]" />
            )}

            {scratchCardDetails?.hasOwnProperty("card2") ? (
              <img src={scratch2} alt="" className="h-[132px] w-[120px]" />
            ) : (
              <img src={card_2} alt="" className="h-[132px] w-[120px]" />
            )}

            {scratchCardDetails?.hasOwnProperty("card3") ? (
              oneTimeDiscount === "5,000" ? (
                <img src={scratch_goodies} alt="" className="h-[132px] w-[120px]" />
              ) : (
                <img src={scratch3} alt="" className="h-[132px] w-[120px]" />
              )
            ) : (
              <img src={card_3} alt="" className="h-[132px] w-[120px]" />
            )}
          </div>
        </div>

        <div className="flex justify-between mt-[5px]">
          <div className="text-[#F9F9F9]">
            <p className="text-[12px] font-[400] font-[Inter]">
              Upto <span className="font-[600]">Rs {oneTimeDiscount}/-</span> off on Early Fee Payment
            </p>
            <Link to="/payment">
              <Button variant={"icon"} size={"sm"} className="relative z-10 mt-[8px] focus:bg-v2-gray-200">
                <span className="w-[122px] text-[#646464] text-[12px] font-[400] font-[Inter]">View Detail</span>
              </Button>
            </Link>
          </div>

          <div className="shadow-[-5px_5px_0px_0px_rgba(0,0,0,0.63)] bg-white rounded-[4px] px-[8px] py-[4px] my-auto w-[180px]">
            <div className="flex items-center flex-col">
              <span className="text-[12px] text-[#646464] font-[600] font-[Inter]">Offer Ends in</span>
              <span className="text-[#DC2626] text-[12px] font-[700]">
                {remaining_time.hours} Hrs : {remaining_time.minutes} Mins : {remaining_time.seconds} Secs
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferBanner
