import { Auth } from "domain/model/Auth"
import { UpdateInfluencerProfile as UpdateProfile } from "domain/model/InfluencerDashboard"
import { CommonInfluencerRepository } from "domain/repository/Common/CommonInfluencerRepository"

export interface UpdatetInfluencerProfile {
  invoke(id: UpdateProfile, auth: Auth): Promise<any>
}

export class UpdateInfluencerProfile implements UpdatetInfluencerProfile {
  private repository: CommonInfluencerRepository

  constructor(repository: CommonInfluencerRepository) {
    this.repository = repository
  }

  async invoke(id: UpdateProfile, auth: Auth): Promise<any> {
    return await this.repository.updateInfluencerProfiles(id, auth)
  }
}
