import { QuestionBankRepository } from "domain/repository/Student/QuestionBankRepository"
import QuestionBankDataSource from "data/dataSource/Student/QuestionBankDataSource"
import { Auth } from "domain/model/Auth"

export class QuestionBankRepositoryImpl implements QuestionBankRepository {
  private dataSource: QuestionBankDataSource

  constructor(dataSource: QuestionBankDataSource) {
    this.dataSource = dataSource
  }

  async saveQuestion(
    auth: Auth,
    problem_name: string,
    problem_id: string,
    problem_type: string,
    problem_link: string,
    status: boolean,
    topics: string[]
  ): Promise<any> {
    return this.dataSource.saveQuestion(auth, problem_name, problem_id, problem_type, problem_link, status, topics)
  }

  async getQuestionBank(auth: Auth): Promise<any> {
    return this.dataSource.getQuestionBank(auth)
  }
}
