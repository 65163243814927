import React, { useState } from "react"
import useToast from "core/hooks/useToast"
import Button from "core/components/new/Button"
import Spinner from "core/components/Spinner"
import useLocalStorage from "core/hooks/useLocalStorage"
import { Link, useNavigate } from "react-router-dom"
import { GetCourses } from "domain/useCase/Student/Course/GetCourses"
import { SaveShort } from "domain/useCase/Student/MicroResource/SaveShort"
import { ReadShort } from "domain/useCase/Student/MicroResource/ReadShort"
import { CourseRepositoryImpl } from "data/repository/Student/CourseRepositoryImpl"
import { ShortRepositoryImpl } from "data/repository/Student/ShortRepositoryImpl"
import { CourseAPIDataSourceImpl } from "data/API/Student/CourseAPIDataSourceImpl"
import { ShortAPIDataSourceImpl } from "data/API/Student/ShortAPIDataSourceImpl"
import { GetFullCourseContent } from "domain/useCase/Student/Course/GetFullCourseContent"
import { minsDiff, toDate, toTime } from "core/utils/date"
import { MarkAttendance } from "domain/useCase/Student/Course/MarkAttendance"
import { FetchLeetCodeDetails } from "domain/useCase/Student/Course/FetchLeetCodeDetails"
import { SetLeetCodeId } from "domain/useCase/Student/Course/SetLeetCodeId"
import { ToggleRecordedComplete } from "domain/useCase/Student/Course/ToggleRecordedComplete"
import { STR_DSML_BEGINNER, STR_FAILURE, STR_SUCCESS, STR_UNKNOWN_ERROR_OCCURRED } from "core/constants/strings"
import { Tooltip } from "@mui/material"
import { CheckBlankIcon, CheckSuccessIcon, InfoIcon } from "core/constants/svgs"
import { genError } from "core/utils/string"
import { GetMasterClass } from "domain/useCase/Student/Course/GetMasterClass"
import { GetAttendanceAndAssignments } from "domain/useCase/Student/Course/GetAttendanceAndAssignments"
import { isEmpty } from "core/utils/misc"
import { useAuth } from "core/context/auth"
import { useApp } from "core/context/app"
import delay from "core/utils/delay"
import { SubmitFeedback } from "domain/useCase/Student/Course/SubmitFeedback"
import { GetSessionDetails } from "domain/useCase/Student/Course/GetSessionDetails"
import { MarkAttendanceManually } from "domain/useCase/Student/Course/MarkAttendanceManually"
import { TTableData } from "core/constants/types"
import UnlockIcon from "assets/svgs/UnlockIcon"
import LockIcon from "assets/svgs/LockIcon"
import TableButton from "core/components/v2/TableButton"
import Info from "assets/svgs/v2/Info"
import Checkbox from "assets/svgs/v2/Checkbox"
import CheckboxSuccess from "assets/svgs/v2/checkboxSuccess"
import { GetDSMLBEGINNERMODULESESSION } from "domain/useCase/Student/Course/GetDsmlBeginnerModuleSessions"
import webEngageTrack from "core/utils/webEngageTrack"
import { ChatbotAPIDataSourceImpl } from "data/API/Student/ChatbotAPIDataSourceImpl"
import { ChatbotRepositoryImpl } from "data/repository/Student/ChatbotRepositoryImpl"
import { Chatbot } from "domain/useCase/Student/Chatbot/Chatbot"

export default function MicroResourceViewModel() {
  const { auth } = useAuth()
  const { student } = useApp()
  const isAccelerator = student?.batch?.includes("Accelerator")
  const { toast, changeToastDetails, changeToastVisibility } = useToast()
  const [isChatbotOpen, setIsChatbotOpen] = React.useState<any>(false)
  const [chatShort, setChatShort] = React.useState<string>("")
  const [courseList, setCourseList] = useLocalStorage<any[]>("course", [])
  const [chatbotResponse, setChatbotResponse] = useState<string>("")
  const [fullCourse, setFullCourse] = useLocalStorage<any>("full_course", {})
  const [coursesWithIds, setCoursesWithIds] = useLocalStorage<any>("course-ids", {})

  const [courseSelectedIndex, setCourseSelectedIndex] = useState<number>(0)
  const [currentCourse, setCurrentCourse] = useState<any>(() =>
    isEmpty(courseList) ? courseList[courseSelectedIndex] : null
  )

  const [activeButtonType, setActiveButtonType] = useState(0)
  const [filterButtonType, setFilterButtonType] = useState(0)
  const [isFullCourseContentActive, setIsFullCourseContentActive] = useState(false)
  const [hasLeetCodeId, setHasLeetCodeId] = useState(true)
  const [loadingTable, setLoadingTable] = useState(-1)
  const [tableData, setTableData] = useState<TTableData>({} as TTableData)
  const [fullCourseTableData, setFullCourseTableData] = useState<TTableData>({
    header: ["Class Name"],
  } as TTableData)
  const [isCourseFetched, setIsCourseFetched] = useState<boolean>(false)
  const [isAssignmentsFetched, setIsAssignmentsFetched] = useState<boolean>(false)
  const [isLeetcodeFetched, setIsLeetcodeFetched] = useState<boolean>(false)
  const [isLeetcodeChecked, setIsLeetcodeChecked] = useState<boolean>(true)
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState<boolean>(false)
  const [attendanceModalData, setAttendanceModalData] = useState<any>(null)
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)
  const [attendanceSessionLoading, setAttendanceSessionLoading] = useState<number>(-1)
  const [showProjectModule, setShowProjectModule] = useState(false)
  const [loading, setLoading] = useState<any>(false)
  const [moduleShorts, setModuleShorts] = useState([])
  const navigate = useNavigate()

  const saveShortUseCase = new SaveShort(new ShortRepositoryImpl(new ShortAPIDataSourceImpl()))
  const readShortUseCase = new ReadShort(new ShortRepositoryImpl(new ShortAPIDataSourceImpl()))
  const chatbotUseCase = new Chatbot(new ChatbotRepositoryImpl(new ChatbotAPIDataSourceImpl()))
  const getCoursesUseCase = new GetCourses(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getFullCourseContentUseCase = new GetFullCourseContent(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const markAttendanceUseCase = new MarkAttendance(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const fetchLeetCodeDetailsUseCase = new FetchLeetCodeDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const setLeetCodeIdUseCase = new SetLeetCodeId(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const toggleRecordedCompleteStatusUseCase = new ToggleRecordedComplete(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getMasterClassUseCase = new GetMasterClass(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getAttendanceAndAssignmentsUseCase = new GetAttendanceAndAssignments(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const submitFeedbackUseCase = new SubmitFeedback(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const getSessionDetailsUseCase = new GetSessionDetails(new CourseRepositoryImpl(new CourseAPIDataSourceImpl()))
  const markAttendanceManuallyUseCase = new MarkAttendanceManually(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )
  const getDsmlBeginnerModuleSessionsUseCase = new GetDSMLBEGINNERMODULESESSION(
    new CourseRepositoryImpl(new CourseAPIDataSourceImpl())
  )

  const defaultindex = () => {
    let latest_timestamp = 1e18
    let ind = -1
    for (let i = 0; i < courseList.length; i++) {
      if (
        courseList[i]["name"] !== "Master Class" &&
        courseList[i]["name"] != "DSML Beginner" &&
        courseList[i]["upcoming_lectures"].length > 0 &&
        courseList[i]["upcoming_lectures"][0]["timestamp"] < latest_timestamp
      ) {
        ind = i
        latest_timestamp = courseList[i]["upcoming_lectures"][0]["timestamp"]
      }
    }
    if (ind === -1) {
      latest_timestamp = 0
      for (let i = 0; i < courseList.length; i++) {
        if (
          courseList[i]["name"] !== "Master Class" &&
          courseList[i]["name"] != "DSML Beginner" &&
          courseList[i]["recorded_lectures"].length > 0 &&
          latest_timestamp < courseList[i]["recorded_lectures"][0]["timestamp"]
        ) {
          ind = i
          latest_timestamp = courseList[i]["recorded_lectures"][0]["timestamp"]
        }
      }
    }

    setCourseSelectedIndex(ind === -1 ? 0 : ind)
  }

  const fetchChatbotResponse = async (prompt: string) => {
    setLoading(true)
    const response = await chatbotUseCase.invoke(auth, prompt)

    setChatbotResponse(response)
    setLoading(false)
  }
  const closeChatbot = () => {
    setIsChatbotOpen(false)
    setChatbotResponse("")
  }
  const handleChatClick = (shortDetails: string) => {
    setIsChatbotOpen(true)
    setChatShort(shortDetails)
  }

  async function fetchCourse() {
    defaultindex()

    const response = await getCoursesUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch courses"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          shorts: resData[key]?.shorts,
          all_lectures: resData[key]?.all_lectures,
          recorded_lectures: resData[key]?.recorded_lectures,
          upcoming_lectures: resData[key]?.upcoming_lectures,
          module_progress: {
            completed: resData[key]?.completed_chapters,
            total: resData[key]?.total_chapters,
          },
          assignment_progress: {
            completed: resData[key]?.solved_module_assignments,
            total: resData[key]?.total_module_assignments,
          },
        },
      }
    })

    const newCourseList = courseList
    const tempCourseList = []
    let newCoursesWithIds = {}
    const courseKeys = Object.keys(modifiedResponse)

    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "master_class" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }

    defaultindex()
    if (isAccelerator) {
      setActiveButtonType(1)
    }
    setCoursesWithIds(newCoursesWithIds)
    setCourseList(newCourseList)
    setIsCourseFetched(true)
  }

  const fetchMasterClass = async () => {
    const response = await getMasterClassUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch master class"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any[] = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse
    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
        },
      }
    })

    const newCourseList: any[] = courseList
    const tempCourseList: any[] = []
    let newCoursesWithIds: any = coursesWithIds
    const courseKeys: any[] = Object.keys(modifiedResponse)

    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "master_class" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }
    if (
      student?.batch?.includes("DSML") &&
      student?.tracks?.includes("dsml_beginner") &&
      !newCoursesWithIds.hasOwnProperty("DSML Beginner")
    ) {
      newCoursesWithIds["DSML Beginner"] = "dsml_beginner"
    }
    setCoursesWithIds(newCoursesWithIds)
    setCourseList(newCourseList)
  }

  const handleReadShort = async (sessionID: string, chapterName: string, shortTitle: string) => {
    const response = await readShortUseCase.invoke(auth, sessionID, chapterName, shortTitle)
  }

  const handleSaveClick = async (sessionID: string, chapterName: string, shortTitle: string, save: boolean) => {
    setCurrentCourse((prevCourse: any) => {
      if (!prevCourse) return prevCourse

      const updatedLectures = prevCourse.all_lectures.map((lecture: any) => {
        if (lecture.chapter_name === chapterName) {
          const updatedShorts = lecture.shorts.map((short: any) => {
            if (short.title === shortTitle) {
              return {
                ...short,
                is_saved: save,
              }
            }
            return short
          })

          return {
            ...lecture,
            shorts: updatedShorts,
          }
        }
        return lecture
      })

      return {
        ...prevCourse,
        all_lectures: updatedLectures,
      }
    })
    const response = await saveShortUseCase.invoke(auth, sessionID, chapterName, shortTitle, save)

    if (response?.success == true) {
      changeToastDetails(STR_SUCCESS, "Short read status updated successfully")
      changeToastVisibility(true)
      return
    } else if (response == undefined) {
      changeToastDetails(STR_FAILURE, "Failed to mark short read status")
      changeToastVisibility(true)
      return
    }
  }

  const fetchAttendanceAndAssignments = async () => {
    setIsAssignmentsFetched(false)

    const response = await getAttendanceAndAssignmentsUseCase.invoke(auth)

    setIsAssignmentsFetched(true)

    if (!response?.success) {
      changeToastDetails(
        STR_FAILURE,
        genError(response, genError(response, "Failed to fetch attendance and assignments"))
      )
      changeToastVisibility(true)
      return
    }

    const moduleKeys = Object.keys(response?.data)
    const newCourseList: any[] = courseList

    moduleKeys?.forEach((key: any) => {
      if (key === "assignmentSolved") {
        return
      }
      const moduleIndex = newCourseList.findIndex((item: any) => item?.id === key)
      const recordedLectures = newCourseList[moduleIndex]?.recorded_lectures
      const upcomingLectures = newCourseList[moduleIndex]?.upcoming_lectures

      const moduleData = response?.data[key]
      moduleData?.forEach((data: any) => {
        let index = -1
        if (!isEmpty(recordedLectures)) {
          index = recordedLectures.findIndex((item: any) => item?.session_id === data?.session_id)
          if (index !== -1) recordedLectures[index] = { ...data, ...recordedLectures[index] }
        }
        if (!isEmpty(upcomingLectures)) {
          index = upcomingLectures.findIndex((item: any) => item?.session_id === data?.session_id)
          if (index !== -1) upcomingLectures[index] = { ...data, ...upcomingLectures[index] }
        }
      })

      newCourseList[moduleIndex] = {
        ...newCourseList[moduleIndex],
        recorded_lectures: recordedLectures,
        upcoming_lectures: upcomingLectures,
        assignment_progress: {
          completed: response?.data[key]?.reduce((total: number, item: any) => total + item?.num_assignment_solved, 0),
          total: response?.data[key]?.reduce((total: number, item: any) => total + item?.num_total_assignments, 0),
        },
      }
    })

    setCourseList(newCourseList)
  }

  function changeCourseSelected(course: any, index: number) {
    setCourseSelectedIndex(index)
    setCurrentCourse(course)
    if (isFullCourseContentActive) {
      setIsFullCourseContentActive(false)
      setFullCourseTableData((t) => ({ ...t, rows: [] }))
    }
    if (course?.name === "Master Class") changeActiveButtonType(0)

    setShowProjectModule(course?.id === "project_module")
  }

  function changeActiveButtonType(index: number) {
    if (activeButtonType !== index) setActiveButtonType(index)
  }
  function changeFilterButtonType(index: number) {
    if (filterButtonType !== index) setFilterButtonType(index)
  }

  async function handleTableClick(i: number, lecture: any) {
    setLoadingTable(i)
    await toggleRecordedComplete(lecture?.session_id, !lecture?.is_completed)
    setLoadingTable(-1)
  }

  function isLinkActive(timestamp: number) {
    const diff = minsDiff(Date.now(), timestamp)
    return diff <= 30 && diff >= -90
  }

  function handleModuleShorts() {
    if (currentCourse?.shorts && Array.isArray(currentCourse.shorts)) {
      setModuleShorts(currentCourse.shorts)
    } else {
      setModuleShorts([])
    }
  }

  function handleMasterClassTable() {
    const lectures = currentCourse?.recorded_lectures
    const header = [
      <CheckSuccessIcon className="h-4 w-4 text-new-solid-white" />,
      "Class Name",
      "Instructor",
      "Date",
      "Pre-Requisite",
      "Link to video",
    ]
    if (currentCourse?.name === STR_DSML_BEGINNER) {
      const rows = lectures?.map((lecture: any, i: number) => [
        <button className="flex h-6 w-6 items-center" onClick={() => handleTableClick(i, lecture)}>
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        lecture?.instructor_name,
        toDate(lecture?.timestamp * 1000),
        lecture?.pre_requisite,
        <TableButton outlined onClick={() => navigate(`${lecture?.session_id}`)}>
          View
        </TableButton>,
      ])
      setTableData({ header, rows })
    } else if (activeButtonType == 1) {
      const filtered_lectures = lectures.filter((lecture: any) => {
        return lecture.type === "Software Engineering" || lecture.type == "both"
      })
      const rows = filtered_lectures?.map((lecture: any, i: number) => [
        <button className="flex h-6 w-6 items-center" onClick={() => handleTableClick(i, lecture)}>
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        lecture?.instructor_name,
        toDate(lecture?.timestamp * 1000),
        lecture?.level,
        <TableButton outlined onClick={() => navigate(`${lecture?.session_id}`)}>
          View
        </TableButton>,
      ])
      setTableData({ header, rows })
    } else {
      const filtered_lectures = lectures.filter((lecture: any) => {
        return lecture.type === "Data Science" || lecture.type == "both"
      })
      const rows = filtered_lectures?.map((lecture: any, i: number) => [
        <button className="flex h-6 w-6 items-center" onClick={() => handleTableClick(i, lecture)}>
          {loadingTable === i ? (
            <Spinner xs />
          ) : lecture?.is_completed ? (
            <CheckSuccessIcon className="h-4 w-4 text-[#22C55E]" />
          ) : (
            <CheckBlankIcon className="h-4 w-4" />
          )}
        </button>,
        <span className="block w-full max-w-[350px] truncate" title={lecture?.chapter_name}>
          {lecture?.chapter_name}
        </span>,
        lecture?.instructor_name,
        toDate(lecture?.timestamp * 1000),
        lecture?.level,
        <TableButton outlined onClick={() => navigate(`${lecture?.session_id}`)}>
          View
        </TableButton>,
      ])
      setTableData({ header, rows })
    }
  }

  async function toggleRecordedComplete(session_id: string, status: boolean) {
    const response = await toggleRecordedCompleteStatusUseCase.invoke(auth, session_id, status)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, "Failed to update status")
      changeToastVisibility(true)
      return
    }

    const updatedCourseList = courseList.map((course: any) => ({
      ...course,
      recorded_lectures: course.recorded_lectures.map((lecture: any) =>
        lecture.session_id === session_id ? { ...lecture, is_completed: status } : lecture
      ),
    }))

    setCourseList(updatedCourseList)
    setCurrentCourse(updatedCourseList[courseSelectedIndex])
  }

  async function showFullCourseContent() {
    const module_id = coursesWithIds[currentCourse.name]
    if (!module_id) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Failed: module_id = undefined")
      return
    }

    const response = await getFullCourseContentUseCase.invoke(auth, module_id)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      return
    }

    const newFullCourse = { ...fullCourse, [module_id]: response?.data }
    setFullCourse(newFullCourse)

    const courses = newFullCourse[module_id]
    const rows = courses?.map((topic: any) => [topic?.name])
    setFullCourseTableData((t) => ({ ...t, rows }))
  }

  async function handleLeetCodeId(leetCodeId: string) {
    setIsLeetcodeChecked(false)

    const response = await setLeetCodeIdUseCase.invoke(auth, leetCodeId)

    setIsLeetcodeChecked(true)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response?.error)
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "LeetCode username saved successfully")

    setHasLeetCodeId(true)
  }

  async function checkLeetCodeProfile() {
    setIsLeetcodeFetched(false)

    const response = await fetchLeetCodeDetailsUseCase.invoke(auth)

    setIsLeetcodeFetched(true)

    if (!response?.success) {
      setHasLeetCodeId(false)
      return
    }

    const isLeetcodeVerified = response?.data?.leetcode_verified
    setHasLeetCodeId(isLeetcodeVerified)
  }

  async function submitFeedback(ratings: number[], suggestion?: string) {
    const isValid = ratings?.every((rating) => rating !== 0)

    if (!isValid) {
      changeToastDetails(STR_FAILURE, "Please rate all the questions")
      changeToastVisibility(true)
      return
    }

    const data: any = {
      session_id: attendanceModalData?.session_id,
      live_class_suggestion: suggestion,
    }
    for (let i = 0; i < ratings.length; ++i) {
      data[`${i + 1}`] = ratings[i]
    }

    const response: any = await submitFeedbackUseCase.invoke(auth, data)

    if (!response?.success) {
      changeToastDetails(STR_SUCCESS, genError(response, "Feedback submission failed!"))
      changeToastVisibility(true)
      return
    }

    await markAttendanceManually(attendanceModalData?.session_id, true)

    setFeedbackOpen(false)
  }

  const handleOpenAttendanceModal = (lecture: any) => {
    setIsAttendanceModalOpen(true)
    setAttendanceModalData(lecture)
  }

  const handleCloseAttendanceModal = () => {
    if (feedbackOpen) {
      setFeedbackOpen(false)
      return
    }
    setIsAttendanceModalOpen(false)
    setAttendanceModalData(null)
  }

  const markAttendanceManually = async (session_id: string, status: boolean) => {
    const response = await markAttendanceManuallyUseCase.invoke(auth, session_id, status)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Attendance updated successfully")
    changeToastVisibility(true)

    const recorded_lectures = currentCourse?.recorded_lectures?.map((c: any) =>
      c?.session_id === attendanceModalData?.session_id ? { ...c, is_attended: status } : c
    )
    setCurrentCourse((c: any) => ({ ...c, recorded_lectures }))

    setIsAttendanceModalOpen(false)
  }

  const handleAttendanceChange = async (status: boolean) => {
    setAttendanceSessionLoading(status ? 1 : 0)

    const response: any = await getSessionDetailsUseCase.invoke(auth, attendanceModalData?.session_id)

    setAttendanceSessionLoading(-1)

    const feedbackShowStatus = response?.module_name !== "master_class" && !response?.is_feedback_submitted

    if (feedbackShowStatus && status) {
      setFeedbackOpen(true)
    } else {
      await markAttendanceManually(attendanceModalData?.session_id, status)
    }
  }

  const fetchDsmlBeginnerModuleSessions = async () => {
    const response = await getDsmlBeginnerModuleSessionsUseCase.invoke(auth)

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Failed to fetch master class"))
      changeToastVisibility(true)
      return
    }

    let modifiedResponse: any[] = []
    const finalResponse = response?.data
    const keys = Object.keys(finalResponse)
    const resData = finalResponse

    keys?.forEach((key: any) => {
      modifiedResponse = {
        ...modifiedResponse,
        [key]: {
          id: key,
          name: resData[key]?.name,
          is_active: resData[key]?.is_active,
          recorded_lectures: resData[key]?.recorded_lectures,
        },
      }
    })

    const newCourseList: any[] = courseList
    const tempCourseList: any[] = []
    let newCoursesWithIds: any = coursesWithIds
    const courseKeys: any[] = Object.keys(modifiedResponse)

    for (let i = 0; i < courseKeys.length; i++) {
      const course = modifiedResponse[courseKeys[i]]
      if (
        modifiedResponse[courseKeys[i]] &&
        courseKeys[i] !== "dsml_beginner" &&
        modifiedResponse[courseKeys[i]]?.is_active
      )
        tempCourseList.unshift(course)
      else tempCourseList.push(course)
      newCoursesWithIds = {
        ...newCoursesWithIds,
        [course.name]: courseKeys[i],
      }
    }

    for (let i = 0; i < tempCourseList.length; i++) {
      const course = tempCourseList[i]
      const index = newCourseList.findIndex((item: any) => item?.name === course?.name)
      if (index === -1) newCourseList.push(course)
      else newCourseList[index] = course
    }
    if (student?.tracks?.includes("master_class") && !newCoursesWithIds.hasOwnProperty("Master Class")) {
      newCoursesWithIds["Master Class"] = "master_class"
    }

    setCoursesWithIds(newCoursesWithIds)
    setCourseList(newCourseList)
  }

  return {
    toast,
    student,
    courseList,
    currentCourse,
    activeButtonType,
    tableData,
    isFullCourseContentActive,
    coursesWithIds,
    fullCourseTableData,
    hasLeetCodeId,
    courseSelectedIndex,
    loadingTable,
    isCourseFetched,
    isAssignmentsFetched,
    isLeetcodeFetched,
    isLeetcodeChecked,
    auth,
    isAttendanceModalOpen,
    attendanceModalData,
    moduleShorts,
    filterButtonType,
    setCurrentCourse,
    setFilterButtonType,
    changeFilterButtonType,
    feedbackOpen,
    attendanceSessionLoading,
    showProjectModule,
    checkLeetCodeProfile,
    fetchMasterClass,
    fetchAttendanceAndAssignments,
    fetchDsmlBeginnerModuleSessions,
    fetchCourse,
    changeCourseSelected,
    changeActiveButtonType,
    showFullCourseContent,
    handleLeetCodeId,
    changeToastVisibility,
    toggleRecordedComplete,
    handleModuleShorts,
    handleMasterClassTable,
    handleCloseAttendanceModal,
    handleAttendanceChange,
    submitFeedback,
    setIsFullCourseContentActive,
    isChatbotOpen,
    chatShort,
    handleSaveClick,
    handleReadShort,
    closeChatbot,
    chatbotResponse,
    handleChatClick,
    fetchChatbotResponse,
  }
}
