import { ChatbotRepository } from "domain/repository/Student/ChatbotRepository"
import ChatbotDataSource from "data/dataSource/Student/ChatbotDataSource"
import { Auth } from "domain/model/Auth"

export class ChatbotRepositoryImpl implements ChatbotRepository {
  private dataSource: ChatbotDataSource

  constructor(dataSource: ChatbotDataSource) {
    this.dataSource = dataSource
  }

  async getChatbotResponse(auth: Auth, prompt: string): Promise<any> {
    return this.dataSource.getChatbotResponse(auth, prompt)
  }
}
