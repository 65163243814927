import { Auth } from "domain/model/Auth"
import MastersDataSource from "data/dataSource/Student/MastersDataSource"
import MastersRepository from "domain/repository/Student/MastersRepository"
import SessionStatusRepository from "domain/repository/Student/UpdateSessionStatusRepository"
import { UpdateSessionStatusDataSource } from "data/dataSource/Student/UpdateSessionStatusDataSource"
import GetLiveSessionDetails from "domain/model/GetLiveSessionDuration"

export class UpdateSessionStatusRepositoryImpl implements SessionStatusRepository {
  private dataSource: UpdateSessionStatusDataSource

  constructor(dataSource: UpdateSessionStatusDataSource) {
    this.dataSource = dataSource
  }

  async updateSessionStatus(auth: Auth, details: GetLiveSessionDetails): Promise<Auth> {
    return this.dataSource.updateSessionStatus(auth, details)
  }
}
