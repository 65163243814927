import Model from "core/components/Model"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import MentorsViewModel from "presentation/Admin/MentorSessions/MentorsViewModel"
import UpdateSession from "./UpdateSession"
import { format } from "date-fns"

export default function SessionsCard({
  data,
  setReloadData,
  reloadData,
  changeToastDetails,
  changeToastVisibility,
}: any) {
  const { isModalOpen, isModelOpen2, toggleModal, toggleModal2, setIsModalOpen, setIsModalOpen2 } = MentorsViewModel()

  const statusColor = data?.status.includes("Cancelled") ? "red" : data?.status.includes("Taken") ? "green" : "yellow"

  const sessionStatus = (data: any) => {
    if (data?.duration > 0) {
      return "Completed"
    }
    return data?.session_status ?? data?.status ?? "Scheduled Session"
  }

  const renderFeedbackStatus = () => {
    if (!isEmpty(data?.mentor_feedback)) {
      // Feedback Provided
      return (
        <div className="space-y-2">
          <p className="text-green-500 font-semibold">Feedback Provided</p>
        </div>
      )
    } else {
      return <p className="text-red-500 font-semibold">Feedback Not Provided</p>
    }
  }

  return (
    <div className="">
      <div className=" flex justify-between px-6 py-4 text-new-neutral-dark shadow">
        <div className="flex items-end gap-x-6">
          <div className="space-y-1">
            <h4 className="mb-1">{data.name}</h4>
            <div className="flex gap-x-4">
              {!isEmpty(data?.student_id) ? (
                <p className="">{data.student_id != "" ? data.student_id : "No Student Found"}</p>
              ) : (
                <p className="italic">No Student Found</p>
              )}
              <p className="">{data.batch}</p>
            </div>
            <p className="">{format(parseInt(data.start_timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}</p>
          </div>
          <div className="inline-flex items-baseline gap-x-1"></div>
        </div>
        <div className="flex gap-x-12">
          <div className="flex w-[180px] flex-col  gap-y-5">
            <p
              className={
                statusColor === "green"
                  ? "text-green-600"
                  : statusColor === "yellow"
                    ? "text-yellow-500"
                    : "text-red-500"
              }
            >
              {sessionStatus(data)}
            </p>
            {renderFeedbackStatus()}
          </div>

          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p className="">
              <strong>AD : </strong>
              {data.actual_duration
                ? `${Math.floor(data.actual_duration / 60)} hours ${data.actual_duration % 60} min`
                : "0 hours 0 min"}
            </p>
          </div>
          <div className="flex w-[150px] flex-col items-end gap-y-5">
            <p className="">
              <strong>CD : </strong>
              {data.considered_duration
                ? `${Math.floor(data.considered_duration / 60)} hours ${data.considered_duration % 60} min`
                : "0 hours 0 min"}
            </p>
            {data.new_type !== "extra_mentor_session" && (
              <Button outlined className="w-fit" onClick={toggleModal}>
                Update
              </Button>
            )}
          </div>
        </div>
      </div>
      <Model isOpen={isModalOpen} onClose={toggleModal} width={"max-w-3xl"}>
        <UpdateSession
          changeToastVisibility={changeToastVisibility}
          changeToastDetails={changeToastDetails}
          reloadData={reloadData}
          setReloadData={setReloadData}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          data={data}
          date={new Date(data.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        />
      </Model>
    </div>
  )
}
