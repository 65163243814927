import DashboardLayout from "core/layouts/DashboardLayout"
import React, { useEffect, useRef, useState } from "react"
import Button from "core/components/Button"
import DropDownMenu from "./components/DropDownMenu"
import ProblemCard from "./components/ProblemCard"
import BlogThumbnail from "assets/svgs/BlogThumbnail"
import VideoThumbnail from "assets/svgs/VideoThumbnail"
import DoubtSupportViewModel from "./DoubtSupportViewModel"
import Loader from "core/components/Loader"
import { isEmpty } from "core/utils/misc"
import OngoingDoubtRectangle from "assets/svgs/OngoingDoubtRectangle"
import { format } from "date-fns"
import { cn } from "core/lib/utils"
import { CrossIcon, InfoButton } from "core/constants/svgs"
import CheckBoxCircleFilled from "assets/svgs/CheckBoxCircleFilled"
import OperationalIssueCard from "./components/OperationalIssueCard"
import { useApp } from "core/context/app"
import { Tooltip } from "@mui/material"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import DashboardLayoutv2 from "core/layouts/DashboardLayoutv2"
import { Head } from "core/components/seo"
import NewFeedbackModal from "core/components/NewFeedbackModal"
import useLocalStorage from "core/hooks/useLocalStorage"

export default function DoubtSupportView() {
  const {
    classTopics,
    questions,
    solution,
    history,
    searchHistory,
    pendingDoubt,
    operationalChangesDone,
    fetchClassTopics,
    fetchQuestions,
    fetchDoubtQuerySolution,
    fetchStudentDoubtHistory,
    updateDoubtStatus,
    searchStudentDoubt,
    fetchStudentDoubtPending,
    setQuestions,
    isBuffer,
    setSolution,
    setSearchHistory,
    setPendingDoubt,
    setStudentBatchShift,
    setStudentBatchPause,
    setOperationalChangesDone,
    setMentor,
    fetchLastFeedback,
    shouldShowFeedbackAfter2Months,
    getJoiningDate,
    saveFeedback,
  } = DoubtSupportViewModel()

  const [index, setIndex] = useState(0)
  const [module, setModule] = useState("")
  const [topic, setTopic] = useState("")
  const [question, setQuestion] = useState("")
  const [resolve, setResolve] = useState(false)
  const [instantResolveCard, setInstantResolveCard] = useState(false)
  const [doubtResolvefromTop, setDoubtResolvefromTop] = useState(false)
  const [onSubmit, setOnSubmit] = useState(false)
  const [currentlySearching, setCurrentlySearching] = useState(false)
  const [popUp, setPopUp] = useState(false)
  const [popUpResolved, setPopUpResolved] = useState(false)
  const [doubtResolvedFromTop, setDoubtResolvedFromTop] = useState("")
  const [instantResolve, setInstantResolve] = useState(false)
  const [infoHover, setInfoHover] = useState(false)
  const [submitQueryTime, setSubmitQueryTime] = useState<any>()
  const [slidingPopUp, setSlidingPopUp] = useState<any>(false)
  const [slidingPopUpText, setSlidingPopUpText] = useState<any>("")
  const [minutes, setMinutes] = useState<any>(10)
  const [seconds, setSeconds] = useState<any>(0)
  const [showModal, setShowModal] = React.useState(false)

  const topicRef = useRef<HTMLLIElement | null>(null)

  const { student } = useApp()
  const isKCE = student?.batch?.includes("KCE") || student?.batch?.includes("KI-Accelerator")

  const doubtSupportAvailable = parseInt(student?.batch_start_timestamp) * 1000 <= Date.now()
  const message = "Doubt Support will be available after the batch starts."
  const str_human_mentor_support = isKCE ? "Mentor Support" : "Human Support"

  const updateTime = () => {
    setMinutes(Math.ceil(9 - (Date.now() - submitQueryTime) / 60000))
    setSeconds(Math.round(60 - (((Date.now() - submitQueryTime) / 1000) % 60)))
  }

  const toggleValue1 = () => {
    setIndex(0)
  }

  const toggleValue2 = () => {
    setIndex(1)
    setCurrentlySearching(false)
    setSearchHistory({})
    setInstantResolve(false)
  }

  useEffect(() => {
    fetchClassTopics()
    //fetchStudentDoubtPending()
    //fetchStudentDoubtHistory()
  }, [])

  useEffect(() => {
    setQuestion("")
    setQuestions([])
    module != "Operational Issue" && fetchQuestions(topic)
    setOnSubmit(false)
    setOperationalChangesDone(false)
  }, [topic])

  useEffect(() => {
    setQuestion("")
    if (!param.get("topic")) {
      setTopic("")
    }

    setOnSubmit(false)
  }, [module])

  useEffect(() => {
    setOnSubmit(false)
  }, [question])

  useEffect(() => {
    fetchStudentDoubtHistory()
    setPendingDoubt({})
    fetchStudentDoubtPending()
    setDoubtResolvefromTop(false)
  }, [resolve, instantResolveCard, searchHistory, popUp])

  useEffect(() => {
    setSubmitQueryTime(Date.now())
    const intervalId = setInterval(updateTime, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [solution])

  useEffect(() => {
    if (operationalChangesDone) {
      setSlidingPopUp(true)
      const timer = setTimeout(() => {
        setSlidingPopUp(false)
      }, 3000)
    }
  }, [operationalChangesDone])

  let [param, setParam] = useSearchParams()

  useEffect(() => {
    if (param.get("topic")) {
      setModule("Operational Issue")
      setTopic(param.get("topic") || "")
    }
  }, [param])

  useEffect(() => {
    const loadFeedback = async () => {
      const lastFeedbackDate = await fetchLastFeedback("Doubt Support")
      const joining_date = await getJoiningDate()
      setShowModal(shouldShowFeedbackAfter2Months(joining_date, lastFeedbackDate))
    }

    loadFeedback()
  }, [])

  return (
    <DashboardLayoutv2>
      <Head title={isKCE ? "Mentor Support" : "Doubt Support"} />

      {slidingPopUp && (
        <div className="slide-in sticky top-[22px] left-full z-50 flex h-[33px] w-[386px] gap-x-[26px] rounded-sm bg-[#00C22B] px-4 py-2">
          <div className="flex gap-x-4">
            <CheckBoxCircleFilled />
            <div className="mt-[-2px] text-sm font-semibold text-white">{slidingPopUpText}</div>
          </div>
        </div>
      )}

      {popUp && (
        <div className="fixed inset-0 z-20">
          <div className="flex min-h-screen items-center justify-center">
            <div onClick={() => setPopUp(false)} className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
            </div>
            <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
              <div className="m-auto w-[320px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
                <div className="relative flex">
                  <div className="text-base font-bold text-red-500">Problem NOT Resolved</div>
                  <button onClick={() => setPopUp(false)} className="absolute right-0">
                    <CrossIcon className="h-6 w-6 text-[#312E81]" />
                  </button>
                </div>

                <div className="mt-[6px] text-[12px] font-normal">
                  {isKCE
                    ? "Do you want to connect with a Mentor regarding this problem"
                    : "Do you want to connect to doubt support team regarding this problem"}
                </div>
                <div className="mt-3 text-[12px] font-normal">
                  <span className="text-red-600">NOTE* </span> We encourage all users to explore the provided resources
                  before reaching out to {isKCE ? "a Mentor." : "our support team."}
                </div>
                <div className="mt-3 flex gap-x-1">
                  <button
                    onClick={() => setPopUp(false)}
                    className="flex h-[36px] w-[134px] cursor-pointer items-center justify-center rounded border border-[#EF4444] text-[#EF4444] hover:bg-red-100"
                  >
                    CANCEL
                  </button>
                  <a
                    href={
                      "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: " +
                      (module !== "" ? "%0AModule: " + module : "") +
                      (topic !== "" ? "%0ATopics: " + topic : "") +
                      (doubtResolvedFromTop !== "" ? "%0AQuestion: " + doubtResolvedFromTop : "") +
                      ".%0AI have gone through the provided solution on the website."
                    }
                    target="_blank"
                    onClick={async () => {
                      setPopUp(false)
                      await updateDoubtStatus(0, doubtResolvedFromTop)
                      setOnSubmit(false)
                    }}
                    className="flex h-[36px] w-[134px] cursor-pointer items-center justify-center rounded border bg-new-gradient text-white hover:bg-[#3941ED] hover:bg-none"
                    rel="noreferrer"
                  >
                    YES
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {popUpResolved && (
        <div className="fixed inset-0 z-20">
          <div className="flex min-h-screen items-center justify-center">
            <div onClick={() => setPopUpResolved(false)} className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-new-solid-black opacity-75"></div>
            </div>
            <div className="no-scrollbar flex h-screen overflow-y-auto py-4">
              <div className="m-auto w-[320px] transform rounded border border-new-accent bg-new-solid-white p-6 font-medium">
                <div className="relative flex">
                  <div className="text-base font-bold text-indigo-700">Problem Resolved</div>
                  <button onClick={() => setPopUpResolved(false)} className="absolute right-0">
                    <CrossIcon className="h-6 w-6 text-[#312E81]" />
                  </button>
                </div>

                <div className="mt-[6px] text-sm font-normal">
                  You are about to confirm that your problem has been resolved.
                </div>

                <div className="mt-3 flex gap-x-1">
                  <button
                    onClick={() => {
                      setPopUpResolved(false)
                      setPopUp(true)
                    }}
                    className="flex h-[36px] w-[134px] cursor-pointer items-center justify-center rounded border border-[#EF4444] text-[#EF4444] hover:bg-red-100"
                  >
                    NO
                  </button>
                  <button
                    onClick={async () => {
                      await updateDoubtStatus(1, doubtResolvedFromTop)
                      setInstantResolveCard(true)
                      setInstantResolve(true)
                      setPopUpResolved(false)
                      setResolve(!resolve)
                      setOnSubmit(false)
                    }}
                    className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border bg-new-gradient text-white hover:bg-[#3941ED] hover:bg-none"
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="font-montserrat ml-6 mr-[14px] gap-y-[18px] overflow-hidden">
        <div className="mt-6">
          <h2 className="text-new-accent">{isKCE ? "Mentor Support" : "Doubt Support"}</h2>
          <div className="text-base font-medium">
            {isKCE
              ? "This platform facilitates query resolution through mentor assistance."
              : "This platform is designed to help you solve assignment problems and address any course-related queries you may have."}
          </div>
        </div>
        {index === 0 ? (
          isEmpty(pendingDoubt) ? (
            <div className="custom-shadow mt-4 flex h-[293px] items-center justify-center p-4">
              <Loader />
            </div>
          ) : (
            pendingDoubt?.data.length > 0 && (
              <div className="custom-shadow mt-4 p-4">
                <h3>Doubts that are not resolved yet :</h3>
                <div className="no-scrollbar relative mt-3 flex gap-x-4 overflow-x-scroll p-1">
                  {pendingDoubt.data.map((doubt: any, index: any) => (
                    <div id={index} key={index} className={cn("custom-shadow w-[158px]")}>
                      <div className="flex flex-col">
                        <div className="relative flex gap-x-2">
                          <h4 className="mt-4 ml-4 h-[67px] w-[96px] truncate whitespace-normal break-words text-new-accent">
                            {doubt["problem_name"]}
                          </h4>
                          <OngoingDoubtRectangle className="absolute top-0 right-4 ml-2"></OngoingDoubtRectangle>
                        </div>
                        <div className="mx-4 mb-4">
                          <div className="mt-2">
                            <div className="flex gap-x-[2px]">
                              <div className="text-[10px] font-semibold">Date:</div>
                              <div className="text-[10px] font-semibold">
                                {format(new Date(parseInt(doubt["timestamp"], 10) * 1000), "do MMM, yyyy")}
                              </div>
                            </div>

                            <h5 className="font-semibold">Status : Ongoing</h5>
                          </div>
                          <div className="mt-2">
                            <Button
                              onClick={() => {
                                setTopic("")
                                setModule("")
                                setDoubtResolvedFromTop(doubt["problem_id"])
                                setPopUpResolved(true)
                              }}
                              className="border-1 border border-indigo-700 bg-new-gradient text-sm font-medium text-white hover:bg-[#3941ED] hover:bg-none"
                            >
                              Resolved
                            </Button>
                            <a
                              href={"#" + doubt["problem_id"]}
                              onClick={() => {
                                setIndex(1)
                                setCurrentlySearching(false)
                              }}
                              className="mt-[6px] flex h-[33px] cursor-pointer items-center justify-center rounded border border-[#312E81] text-sm font-medium text-[#312E81] hover:bg-blue-100"
                            >
                              <div className="">Show Detail</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )
        ) : (
          <span />
        )}
        <div className="gap-y-[18px]">
          <div className="my-[18px] flex">
            <Button
              onClick={toggleValue1}
              className={
                index === 0
                  ? "rounded-r-none border-[0.5px] border-r-0 border-[#312E81] bg-new-gradient text-new-solid-white"
                  : "rounded-r-none border-[0.5px] border-r-0 border-[#312E81] bg-white text-[#312E81]"
              }
            >
              <h4>Raise New Query/Doubt</h4>
            </Button>
            <Button
              onClick={toggleValue2}
              className={
                index === 1
                  ? "rounded-l-none border-[0.5px] border-l-0 border-[#312E81] bg-new-gradient text-new-solid-white"
                  : "rounded-l-none border-[0.5px] border-l-0 border-[#312E81] bg-white text-[#312E81]"
              }
            >
              <h4>Problem History</h4>
            </Button>
          </div>

          <div className="pb-20">
            {index === 0 ? (
              // Raise New Query/Doubt
              <div className="flex flex-col">
                <div className="flex gap-x-2">
                  <div className="custom-shadow border-1 relative h-[482px] w-[367px] border">
                    <div className="m-6 flex flex-col gap-[24px]">
                      <h3 className="text-lg text-new-accent">Select Query Type :</h3>
                      <div className="flex flex-col">
                        <div className="flex flex-col gap-y-2">
                          <div className="flex gap-x-1 text-left text-lg font-semibold leading-[22px] tracking-normal">
                            <div className="">Query Type</div>
                            <div className="text-red-600">*</div>
                          </div>
                          <DropDownMenu
                            id={1}
                            options={(() => {
                              if (isKCE) {
                                return ["DSA", "Other"]
                              } else if (student["batch"].includes("EV") === true) {
                                return ["DSA", "System Design", "Operational Issue", "Other"]
                              } else if (
                                student["batch"].includes("Accelerator") &&
                                !student["batch"].includes("KI-Accelerator") === true
                              ) {
                                return ["DSA", "Projects", "Operational Issue", "Other"]
                              } else {
                                return ["DSA", "Data Engineering", "DSML", "Projects", "Operational Issue", "Other"]
                              }
                            })()}
                            module={module}
                            setModule={setModule}
                            zindex="z-50"
                            index={1}
                          />
                        </div>

                        {module === "Other" ? (
                          <div />
                        ) : module === "Projects" ? (
                          <div />
                        ) : module === "Data Engineering" ? (
                          <div />
                        ) : module === "System Design" ? (
                          <div />
                        ) : module === "DSML" ? (
                          <div className="flex flex-col gap-y-2">
                            <div className="mt-9 flex gap-x-1 text-left text-lg font-semibold leading-[22px] tracking-normal">
                              <div className="">Select Topics</div>
                              <div className="text-red-600">*</div>
                            </div>
                            <DropDownMenu
                              id={2}
                              options={[
                                "Excel",
                                "Tableau",
                                "Power BI",
                                "SQL",
                                "Python",
                                "Machine Learning",
                                "Deep Learning",
                                "Maths",
                                "Other",
                              ]}
                              module={module}
                              topic={topic}
                              setTopic={setTopic}
                              zindex="z-40"
                              index={1}
                            />
                          </div>
                        ) : module === "Operational Issue" ? (
                          <div className="flex flex-col gap-y-2">
                            <div className="mt-9 flex gap-x-1 text-left text-lg font-semibold leading-[22px] tracking-normal">
                              <div className="">Select Issue</div>
                              <div className="text-red-600">*</div>
                            </div>
                            <DropDownMenu
                              id={2}
                              options={
                                student?.batch?.includes("Accelerator")
                                  ? ["Other"]
                                  : ["Batch Change", "Pause Course", "Resume Course", "Mentor Change", "Other"]
                              }
                              module={module}
                              topic={topic}
                              ref={topicRef}
                              setTopic={setTopic}
                              zindex="z-40"
                              index={1}
                            />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-y-2">
                            <div className="mt-9 flex gap-x-1 text-left text-lg font-semibold leading-[22px] tracking-normal">
                              <div className="">Select Chapter</div>
                              <div className="text-red-600">*</div>
                            </div>
                            <DropDownMenu
                              id={2}
                              options={classTopics}
                              module={module}
                              topic={topic}
                              setTopic={setTopic}
                              zindex="z-40"
                              index={1}
                            />
                            {topic === "Other" ? (
                              <div />
                            ) : (
                              <div className="flex flex-col gap-y-2">
                                <div className="mt-9 flex gap-x-1 text-left text-lg font-semibold leading-[22px] tracking-normal">
                                  <div className="">Select Question</div>
                                  <div className="text-red-600">*</div>
                                </div>

                                <DropDownMenu
                                  id={3}
                                  options={questions}
                                  module={module}
                                  topic={topic}
                                  question={question}
                                  setQuestion={setQuestion}
                                  zindex="z-30"
                                  index={1}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {module !== "Operational Issue" &&
                    (["Other", "Projects", "System Design", "Data Engineering"].includes(module) ||
                      (module === "DSML" &&
                        [
                          "Excel",
                          "Tableau",
                          "Power BI",
                          "SQL",
                          "Python",
                          "Maths",
                          "Machine Learning",
                          "Deep Learning",
                        ].includes(topic)) ||
                      topic === "Other" ||
                      question === "Other") ? (
                      <Tooltip title={!doubtSupportAvailable && message} placement="bottom-end">
                        <button
                          disabled={!doubtSupportAvailable}
                          className="absolute bottom-6 left-6 h-[52px] w-[319px] rounded bg-new-gradient text-new-solid-white hover:bg-[#3941ED] hover:bg-none disabled:opacity-50 disabled:hover:bg-new-gradient"
                          onClick={() => {
                            const url =
                              "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a question about a topic that isn't currently covered on the website. Can you assist me with this?"
                            window.open(url, "_blank")
                          }}
                        >
                          {str_human_mentor_support}
                        </button>
                      </Tooltip>
                    ) : (
                      <Tooltip title={!doubtSupportAvailable && message} placement="bottom-end">
                        <button
                          onClick={async () => {
                            if (module !== "Operational Issue") {
                              fetchDoubtQuerySolution(question)
                            }
                            setResolve(!resolve)
                            setSolution({})
                            setInstantResolveCard(false)
                            setOnSubmit(true)
                            setPendingDoubt({})
                          }}
                          className="absolute bottom-6 left-6 h-[52px] w-[319px] rounded bg-new-gradient text-new-solid-white hover:bg-[#3941ED] hover:bg-none disabled:opacity-50 disabled:hover:bg-new-gradient"
                          disabled={
                            module === "" ||
                            topic === "" ||
                            (question === "" && module !== "Operational Issue") ||
                            !doubtSupportAvailable
                          }
                        >
                          Submit Query
                        </button>
                      </Tooltip>
                    )}
                  </div>
                  {!onSubmit ? (
                    // before submitting
                    <div className="custom-shadow border-1 flex h-[482px] w-full items-center justify-center border p-6 font-normal">
                      <div className="flex w-full flex-col">
                        <div className="text-center">Select question first to</div>
                        <div className="text-center">show solution</div>
                      </div>
                    </div>
                  ) : // after submitting
                  module === "Operational Issue" ? (
                    <OperationalIssueCard
                      buffer={isBuffer}
                      topic={topic}
                      setStudentBatchShift={setStudentBatchShift}
                      operationalChangesDone={operationalChangesDone}
                      setStudentBatchPause={setStudentBatchPause}
                      setSlidingPopUpText={setSlidingPopUpText}
                      setMentor={setMentor}
                    />
                  ) : isEmpty(solution) || Object.keys(solution).length === 0 ? (
                    <div className="custom-shadow flex h-[482px] w-full items-center justify-center">
                      <Loader />
                    </div>
                  ) : solution["resources"][1]["link"].length == 0 ||
                    solution["resources"][0]["link"].length == 0 ||
                    solution["resources"][1]["link"].substring(0, 5) != "https" ||
                    solution["resources"][0]["link"].substring(0, 5) != "https" ? (
                    <div className="custom-shadow border-1 relative flex h-[482px] w-full flex-col justify-center border p-6 font-normal">
                      <div className="flex flex-col gap-y-4">
                        <h2>Solution for this particular problem is not available on the website.</h2>
                        <h3>Please contact Human Doubt Support from here:</h3>
                        <button className="px-[16px 32px] h-[52px] w-[319px] space-x-10 rounded-sm bg-new-gradient text-new-solid-white">
                          <a
                            href={
                              "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: %0AModule: " +
                              module +
                              "%0ATopics: " +
                              topic +
                              "%0AQuestion: " +
                              question +
                              ".%0AI have gone through the provided solution on the website."
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {str_human_mentor_support}
                          </a>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="custom-shadow border-1 relative flex h-[482px] w-full flex-col border font-normal">
                      <div className="mx-6 mt-6 flex flex-col">
                        <h3 className="">New Doubt Raised :</h3>
                        <h2 className="mt-2 text-new-accent">{solution["problem_name"]}</h2>
                        <div className="flex gap-x-1">
                          <h4 className="mt-1 ">{solution["num_resolved"]}+</h4>
                          People find the solution for the problem relevant and helpful.
                        </div>
                        <div className="mt-6 flex gap-x-4">
                          <div className="flex flex-col gap-y-2">
                            <h4>Video Solution</h4>
                            <a
                              href={
                                solution["resources"][1]
                                  ? solution["resources"][1]["link"]
                                  : "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: %0AModule: " +
                                    module +
                                    "%0ATopics: " +
                                    topic +
                                    "%0AQuestion: " +
                                    question +
                                    ".%0AI have gone through the provided solution on the website."
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex cursor-pointer">
                                <VideoThumbnail h={145} w={286} />
                              </div>
                              <div className="mt-[13px] cursor-pointer text-center text-sm font-medium underline hover:text-sm hover:font-semibold hover:text-indigo-700">
                                Click here to view
                              </div>
                            </a>
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <h4 className="">Blog Article</h4>
                            <a
                              href={
                                solution["resources"][0]
                                  ? solution["resources"][0]["link"]
                                  : "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: %0AModule: " +
                                    module +
                                    "%0ATopics: " +
                                    topic +
                                    "%0AQuestion: " +
                                    question +
                                    ".%0AI have gone through the provided solution on the website."
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex cursor-pointer">
                                <BlogThumbnail w={286} h={145} />
                              </div>
                              <div className="mt-[13px] cursor-pointer text-center text-sm font-medium underline hover:text-sm hover:font-semibold hover:text-indigo-700">
                                Click here to view
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      {!instantResolveCard && (
                        <div className="border-1 absolute bottom-0 w-full border p-6">
                          <div className="relative flex">
                            <div className="flex flex-col">
                              <div className="text-sm font-medium">Was this solution helpful?</div>
                              <div className="relative flex gap-x-2 leading-[15px]">
                                <div className="flex items-center justify-center gap-x-1 text-[10px] font-semibold">
                                  Connect to{" "}
                                  {minutes >= 0 ? (
                                    <div className="cursor-not-allowed font-bold underline">
                                      {str_human_mentor_support}
                                    </div>
                                  ) : (
                                    <a
                                      className="cursor-pointer font-bold leading-[15px] underline"
                                      href={
                                        "https://api.whatsapp.com/send?phone=919870258716&text=Hi,%0AI have a Doubt related to: " +
                                        (module !== "" ? "%0AModule: " + module : "") +
                                        (topic !== "" ? "%0ATopics: " + topic : "") +
                                        (doubtResolvedFromTop !== "" ? "%0AQuestion: " + doubtResolvedFromTop : "") +
                                        ".%0AI have gone through the provided solution on the website."
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {str_human_mentor_support}
                                    </a>
                                  )}
                                </div>
                                <InfoButton width={17} height={17} setInfoHover={setInfoHover} />
                                {infoHover && (
                                  <div className="h[76px] border-1 absolute left-[170px] bottom-[20px] z-50 flex w-[184px] flex-col gap-y-[6px] border bg-white p-2 text-[10px] font-semibold">
                                    {minutes < 0 ? (
                                      <div>Click '{str_human_mentor_support}' for assistance.</div>
                                    ) : (
                                      <>
                                        <div>
                                          {str_human_mentor_support} button will be active in{" "}
                                          <span className="text-red-500">
                                            {minutes} min : {seconds} sec.
                                          </span>
                                        </div>
                                        <div>In the meantime, you can take a look at the provided solutions.</div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="absolute right-0 mt-[2px] flex gap-x-[10px]">
                              <button
                                className="flex h-[36px] w-[146px] cursor-pointer items-center justify-center rounded border border-[#EF4444] text-[#EF4444] hover:bg-red-100"
                                onClick={() => {
                                  setPopUp(true)
                                  setDoubtResolvedFromTop(solution["problem_id"])
                                }}
                              >
                                NO
                              </button>
                              <Button
                                onClick={() => {
                                  setPopUpResolved(true)
                                  setDoubtResolvedFromTop(solution["problem_id"])
                                }}
                                className="h-[36px] w-[146px] cursor-pointer bg-new-gradient text-white hover:bg-[#3941ED] hover:bg-none"
                              >
                                YES
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              // Problem History
              <div className="relative flex flex-col gap-[18px]">
                <div className="flex gap-6">
                  <div className="flex h-[63px] w-[319px] flex-col gap-2">
                    <div className="text-lg font-semibold">Select tags</div>
                    <DropDownMenu
                      id={2}
                      options={classTopics}
                      topic={topic}
                      setTopic={setTopic}
                      zindex="z-10"
                      index={2}
                    />
                  </div>
                  <div className="flex h-[63px] w-[319px] flex-col gap-2">
                    <div className="text-lg font-semibold">Question</div>
                    <DropDownMenu
                      id={3}
                      options={questions}
                      topic={topic}
                      question={question}
                      setQuestion={setQuestion}
                      zindex="z-10"
                      index={2}
                    />
                  </div>
                  <button
                    className={cn(
                      "mt-[37px] h-[33px] w-[97px] rounded-sm border-[1px] border-[#312E81] text-sm font-medium text-[#312E81] hover:bg-blue-100",
                      topic === "" || question === "" ? "pointer-events-none opacity-50" : ""
                    )}
                    onClick={() => {
                      searchStudentDoubt(question)
                      setSearchHistory({})
                      setCurrentlySearching(true)
                      setInstantResolve(false)
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="">
                  {currentlySearching ? (
                    isEmpty(searchHistory) ? (
                      <div className="custom-shadow flex h-[482px] w-full items-center justify-center">
                        <Loader />
                      </div>
                    ) : searchHistory[0]["found"] ? (
                      searchHistory.map((doubt: any) => (
                        <ProblemCard
                          history={doubt}
                          id={doubt["problem_id"]}
                          key={doubt["problem_name"]}
                          resolve={resolve}
                          setResolve={setResolve}
                          setDoubtResolvedFromTop={setDoubtResolvedFromTop}
                          setPopUp={setPopUp}
                          setPopUpResolved={setPopUpResolved}
                          setTopic={setTopic}
                          instantResolve={instantResolve}
                        ></ProblemCard>
                      ))
                    ) : (
                      <div className="custom-shadow flex h-[482px] w-full items-center justify-center">
                        No Result Found
                      </div>
                    )
                  ) : isEmpty(history) ? (
                    <div className="custom-shadow flex h-[482px] w-full items-center justify-center">
                      <Loader />
                    </div>
                  ) : history?.data?.length === 0 ? (
                    <div className="custom-shadow flex h-[482px] w-full flex-col items-center justify-center">
                      <div className="">You have not raised any</div>
                      <div className="">query yet !</div>
                    </div>
                  ) : (
                    history?.data?.map((doubt: any) => (
                      <ProblemCard
                        history={doubt}
                        id={doubt["problem_id"]}
                        key={doubt["problem_id"]}
                        resolve={resolve}
                        setResolve={setResolve}
                        setDoubtResolvedFromTop={setDoubtResolvedFromTop}
                        setPopUp={setPopUp}
                        setPopUpResolved={setPopUpResolved}
                        setTopic={setTopic}
                      ></ProblemCard>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && <NewFeedbackModal saveFeedback={saveFeedback} message="Doubt Support" />}
    </DashboardLayoutv2>
  )
}
