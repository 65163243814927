import DashboardLayout from "core/layouts/DashboardLayout"
import useHomeViewModel from "./HomeViewModel"
import Toast from "core/components/Toast"
import Avatar from "react-avatar"
import Input from "core/components/new/Input"
import Button from "core/components/new/Button"
import { isEmpty } from "core/utils/misc"
import { STR_TEXT_FIELD } from "core/constants/strings"
import { CrossIcon } from "core/constants/svgs"
import { useEffect } from "react"
import Notifications from "./Components/Notifications"

export default function HomeView() {
  const {
    toast,
    mentor,
    referralCode,
    totalAvailableSlots,
    upcomingSessionNotification,
    changeToastVisibility,
    handleCopyClick,
    fetchAvailability,
    notificatons,
    fetchMentorNotifications,
    refreshData,
    removeMentorNotificationsHandler,
    fetchOpenSlotNotification,
    formatTime,
    fetchMentorSessions,
    openSlotReminder,
    formatDate,
  } = useHomeViewModel()

  useEffect(() => {
    fetchAvailability()
    fetchMentorSessions()
    fetchOpenSlotNotification()
  }, [])

  useEffect(() => {
    fetchMentorNotifications()
  }, [refreshData])

  return (
    <DashboardLayout>
      <div className="space-y-6 p-6 text-new-neutral-dark">
        <div className="divide-y divide-new-neutral-light rounded-sm bg-new-solid-white shadow">
          <div className="flex gap-6 p-6">
            <Avatar src={mentor?.mentor?.photo} alt="profile pic" className="shadow" name={mentor?.mentor?.name} />
            <div className="flex flex-col gap-1">
              <h2 className="mb-1">{mentor?.mentor?.name}</h2>
              <p>
                Email: <strong>{mentor?.mentor?.email}</strong>
              </p>
              {!isEmpty(mentor?.mentor?.linkedin_profile) && (
                <p>
                  LinkedIn:{" "}
                  <a href={mentor?.mentor?.linkedin_profile}>
                    <strong>{mentor?.mentor?.linkedin_profile}</strong>
                  </a>
                </p>
              )}

              <p>
                Current Company: <strong>{mentor?.mentor?.current_company}</strong>
              </p>
              <p>
                Experience: <strong>{mentor?.experience}</strong>{" "}
                <strong>{mentor?.experience > 1 ? "years" : "year"}</strong>
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 p-6">
            <p>
              Total mentees: <strong>{mentor?.mentor?.total_mentees}</strong>
            </p>
            {mentor?.mentor?.is_mentor == true && (
              <p>
                Average Mentor Sessions Rating:{" "}
                {mentor?.rating != 0 && <strong>{Math.round(parseFloat(mentor?.rating) * 10) / 10}</strong>}
                {mentor?.rating == 0 && <strong>Not Rated</strong>}
              </p>
            )}
            {mentor?.mentor?.is_instructor == true && (
              <p>
                Average Class Rating:{" "}
                {mentor?.instructor_rating != 0 && (
                  <strong>{Math.round(parseFloat(mentor?.instructor_rating) * 10) / 10}</strong>
                )}
                {mentor?.instructor_rating == 0 && <strong>Not Rated</strong>}
              </p>
            )}
            {!isEmpty(referralCode) && (
              <div className="flex items-center gap-1">
                <p>Referral code:</p>
                <div className="flex gap-2">
                  <Input type={STR_TEXT_FIELD} placeholder="Your referral code" value={referralCode} readOnly />
                  <Button small className="h-fit w-fit rounded" onClick={handleCopyClick}>
                    Copy Code
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        {totalAvailableSlots === 0 && (
          <div className=" w-full px-[24px] py-[16px] h-[85px] bg-[#FFEDED] rounded-[8px]">
            <div className=" flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.6113 13.8333L8.26066 2.8333C8.14157 2.62705 7.9215 2.5 7.68333 2.5C7.44517 2.5 7.22509 2.62705 7.106 2.8333L0.755332 13.8333C0.636245 14.0396 0.636243 14.2937 0.755328 14.5C0.874412 14.7062 1.09449 14.8333 1.33267 14.8333H14.034C14.2722 14.8333 14.4923 14.7062 14.6113 14.5C14.7304 14.2937 14.7304 14.0396 14.6113 13.8333ZM12.88 13.5003H2.488L7.684 4.50031L12.88 13.5003ZM7.01742 11.5008H8.35076V12.8342H7.01742V11.5008ZM8.35076 6.83257H7.01742V10.1659H8.35076V6.83257Z"
                  fill="#DC2626"
                />
              </svg>
              <p className=" mx-[8px] text-[#DC2626] font-inter text-[16px] font-medium">Slot Alert</p>
            </div>
            <p className="text-[#646464] font-inter text-[14px] font-normal">
              It seems you haven’t opened any slots yet for the upcoming booking period. Open your slots now to connect
              with mentees!
            </p>
          </div>
        )}

        {openSlotReminder && (
          <Notifications
            formatTime={formatTime}
            formatDate={formatDate}
            removeNotification={removeMentorNotificationsHandler}
            notification={openSlotReminder}
          />
        )}
        {upcomingSessionNotification && (
          <Notifications
            formatTime={formatTime}
            formatDate={formatDate}
            removeNotification={removeMentorNotificationsHandler}
            notification={upcomingSessionNotification}
          />
        )}

        {notificatons.map((item: any, index: number) => {
          return (
            <div key={item.id}>
              <Notifications
                formatTime={formatTime}
                formatDate={formatDate}
                removeNotification={removeMentorNotificationsHandler}
                notification={item}
              />
            </div>
          )
        })}
      </div>

      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
