import Cross from "assets/svgs/Cross"
import FreezeActive from "assets/svgs/FreezeActive"
import FreezeDisable from "assets/svgs/FreezeDisable"
import Button from "core/components/new/Button"
import Input from "core/components/new/Input"
import Spinner from "core/components/Spinner"
import { CrossIcon } from "core/constants/svgs"
import { cn } from "core/lib/utils"
import RedeemCoins from "domain/useCase/Student/RedeemCoins/RedeemCoins"
import { ChangeEvent, useEffect, useState } from "react"

export default function ClassStreakModal({
  isOpen,
  onClose,
  lastWeekAttendance,
  reedemCoins,
  reedemCoinsHandle,
  reedemCoinLoading,
}: any) {
  if (!isOpen) return null

  return (
    <div className="relative z-40">
      <div className="fixed inset-0 bg-[rgba(12,12,12,0.85)] backdrop-blur-[2px] z-0"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto flex min-h-screen items-center justify-center">
        <div className="relative p-6">
          <div className="relative p-[24px] h-[479px] w-[795px] overflow-hidden rounded-[16px] bg-white">
            <div className="flex justify-between w-full">
              <div>
                <p className="text-[16px] font-[600] text-[#333]">Weekly Class Streak</p>
                <p className="text-[14px] font-[400]">Complete class streak and win exciting cash rewards!</p>
              </div>
              <button onClick={onClose} className="hover:bg-slate-200 rounded-[4px] size-[24px]">
                <CrossIcon />
              </button>
            </div>

            <div className="my-[26px] shadow-[-1px_1px_3px_0px_rgba(132,_136,_139,_0.15)] rounded-[8px] border-[0.5px] border-[#ADADAD] text-new-neutral">
              <h4 className="flex rounded-t-[8px] bg-[#F9F9F9] justify-center py-[13px] text-[14px] font-[Inter] font-[400]">
                Weekly Class Streak
              </h4>

              <div className="flex overflow-hidden">
                {lastWeekAttendance?.dailyAttendance?.map((attendance: any, i: number) => (
                  <div key={i} className="w-full h-full">
                    <div
                      className={cn(
                        "flex my-[8px] h-[81px] w-full min-w-[105px] flex-1 shrink-0 flex-col items-center justify-center border-new-neutral-light"
                        //   timestamp === new Date().setHours(0, 0, 0, 0)
                        //     ? "bg-[#000F43] rounded-[4px] text-new-solid-white"
                        //     : "text-[#646464]"
                      )}
                    >
                      <h3 className="text-[18px] font-[500]">
                        {attendance?.date} {attendance?.month.slice(0, 3)}
                      </h3>
                      <p className="text-[#D3D3D3] text-[12px] font-[500]">{attendance?.day}</p>
                    </div>

                    <div
                      className={cn(
                        "p-[16px] rounded-[4px] my-[16px] h-[96px]  w-[91px] flex-1 mx-[6px] shadow-[-1px_1px_3px_0px_rgba(132,_136,_139,_0.05)]",
                        attendance?.is_class ? (attendance?.attended ? "bg-[#EEF8FD]" : "bg-[#EBEBEB]") : "bg-[#F9F9F9]"
                      )}
                    >
                      {attendance?.is_class && (
                        <>
                          <p
                            className={cn(
                              " w-full text-center text-[14px] font-[600]",
                              attendance?.attended ? "text-[#162456]" : "text-[#D3D3D3]"
                            )}
                          >
                            {attendance?.no_of_class} Class
                          </p>
                          <div className="w-full flex justify-center py-[10px]">
                            {attendance?.attended ? <FreezeActive /> : <FreezeDisable className="size-[32px]" />}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between items-center h-[71px] bg-[#333] rounded-[8px] py-[16px] px-[24px]">
              <div>
                <div className="flex items-center gap-[8px]">
                  <FreezeActive className="size-[16px]" />
                  <div className="text-[#9EF0FE] text-[16px] font-[700]">Streak Reward</div>
                </div>

                <p className="text-[12px] font-[600] text-white">
                  {lastWeekAttendance?.attended_class} / {lastWeekAttendance?.total_class} Classes
                </p>
              </div>

              <button
                disabled={reedemCoinLoading || !reedemCoins}
                className={cn(
                  " rounded-[4px] w-[141px] h-[35px] duration-500 text-[14px] font-[500] flex justify-center items-center",
                  reedemCoins ? "bg-[#D3D3D3] hover:bg-[#c2c2c2]" : "bg-[#525252] text-[#979797]"
                )}
                onClick={reedemCoinsHandle}
              >
                {reedemCoinLoading ? <Spinner small={true} /> : "Redeem Reward"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
