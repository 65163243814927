import { API_V3_ADMIN, STR_FAILURE, STR_STUDENT_INFO_SAVED, STR_SUCCESS } from "core/constants/strings"
import { useAuth } from "core/context/auth"
import useToast from "core/hooks/useToast"
import { genError } from "core/utils/string"
import { MentorDashboardAPIDataSourceImpl } from "data/API/Admin/MentorDashboardAPIDataSourceImpl"
import { StudentDashboardAPIDataSourceImpl } from "data/API/Admin/StudentDashboardAPIDataSourceImpl"
import { MentorDashboardRepositoryImpl } from "data/repository/Admin/MentorDashboardRepositoryImpl"
import PaymentAdminRepositoryImpl from "data/repository/Admin/PaymentAdminRepositoryImpl"
import { StudentDashboardRepositoryImpl } from "data/repository/Admin/StudentDashboardRepositoryImpl"
import { TPaymentDocumentItem } from "domain/model/Payment"
import { GetMentorsDetailList } from "domain/useCase/Admin/MentorDashboard/GetMentorsDetailList"
import { GetPaymentDocuments } from "domain/useCase/Admin/Payment/GetPaymentDocuments"
import { GetStudentEMI } from "domain/useCase/Admin/Payment/GetStudentEMI"
import { UpdatePaymentDocument } from "domain/useCase/Admin/Payment/UpdatePaymentDocument"
import { GetPaymentMethods } from "domain/useCase/Admin/Payment/GetPaymentMethods"
import { UploadPaymentDocument } from "domain/useCase/Admin/Payment/UploadPaymentDocument"
import { DocumentVerified } from "domain/useCase/Admin/Payment/DocumentVerified"
import { GetOnboardingManager } from "domain/useCase/Admin/StudentDashboard/GetOnboardingManagerList"
import { GetAllStudentData } from "domain/useCase/Admin/StudentDashboard/GetUserData"
import RemoveMentor from "domain/useCase/Admin/StudentDashboard/RemoveMentor"
import UpdateStudentProfile from "domain/useCase/Admin/StudentDashboard/UpdateStudentProfile"
import { ChangeEvent, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import React from "react"
import { Country, State } from "country-state-city"
import { CreateSSMCalls } from "domain/useCase/Admin/CreateSSMCall/CreateSSMCall"
import { CreateSSMCallRepositoryImpl } from "data/repository/Admin/CreateSSMCallRepositoryImpl"
import { CreateSSMCallAPIDataSourceImpl } from "data/API/Admin/CreateSSMCallAPIDataSourceImpl"
import { SSMCallHistorys } from "domain/useCase/Admin/CreateSSMCall/SSMCallHistory"
import { SSMCallHistoryRepositoryImpl } from "data/repository/Admin/SSMCallHistoryRepositoryImpl"
import { SSMCallHistoryAPIDataSourceImpl } from "data/API/Admin/SSMCallHistoryAPIDataSourceImpl"
import { UpdateSSMCalls } from "domain/useCase/Admin/CreateSSMCall/UpdateSSMCall"
import { UpdateSSMCallRepositoryImpl } from "data/repository/Admin/UpdateSSMCallRepositoryImpl"
import { UpdateSSMCallAPIDataSourceImpl } from "data/API/Admin/UpdateSSMCallAPIDataSourceImpl"
import { UpdateSSMCallDetailRepositoryImpl } from "data/repository/Admin/UpdateSSMCallDetailRepositoryImpl"
import { UpdateSSMCallDetailAPIDataSourceImpl } from "data/API/Admin/UpdateSSMCallDetailAPIDataSourceImpl"
import { UpdateSSMCallDetails } from "domain/useCase/Admin/CreateSSMCall/UpdateSSMCallDetail"
import { DeleteSSMCalls } from "domain/useCase/Admin/CreateSSMCall/DeleteSSMCall"
import { DeleteSSMCallRepositoryImpl } from "data/repository/Admin/DeleteSSMCallRepositoryImpl"
import { DeleteSSMCallAPIDataSourceImpl } from "data/API/Admin/DeleteSSMCallAPIDataSourceImpl"
import { PaymentAdmin } from "domain/useCase/Admin/Payment/CreatePayment"
import { GetPaymentDetailAdmin } from "domain/useCase/Admin/Payment/GetPaymentAdmin"
import { UpdateTransactionDetailAdmin } from "domain/useCase/Admin/Payment/updateTransactionDetailAdmin"
import { UpdateCCAvTransactionDetailAdmin } from "domain/useCase/Admin/Payment/UpdateCCAvTransactionDetailAdmin"
import PaymentAdminAPIDataSourceImpl from "data/API/Admin/PaymentAdminAPIDataSourceImpl"
import Button from "core/components/Button"
import { capitalize } from "../../../../src/core/utils/string"
import { UpdatePaymentOptionsMenu } from "domain/useCase/Admin/Payment/UpdatePaymentOptionsMenu"
import RefundStudent from "domain/useCase/Admin/StudentDashboard/RefundStudent"
import { set } from "date-fns"
import CCAvPaymentAdminRepository from "data/repository/Admin/CCAvPaymentAdminRepositoryImpl"
import CCAvPaymentAdminAPIDataSourceImpl from "data/API/Admin/CCAvAdminAPIDataSourceImpl"
import { GetBatchStudentDetails } from "domain/useCase/Admin/Sheets/GetBatchStudentDetails"
import { GetBatchHistoryData } from "domain/useCase/Admin/StudentDashboard/GetBatchHistoryData"
import { updateMilestoneSessionMentor } from "domain/useCase/Admin/StudentDashboard/UpdateMilestoneSessionMentor"
import { AddPlacementCompany } from "domain/useCase/Admin/StudentDashboard/AddPlacementCompany"
import { GetPlacementCompany } from "domain/useCase/Admin/StudentDashboard/ProgressReport/GetPlacementCompany"
import { EditPlacementCompany } from "domain/useCase/Admin/StudentDashboard/EditPlacementCompany"
import { DeletePlacementCompany } from "domain/useCase/Admin/StudentDashboard/DeletePlacementCompany"
import { AddStudentComment } from "domain/useCase/Admin/StudentDashboard/AddStudentComment"
import { GetStudentComment } from "domain/useCase/Admin/StudentDashboard/GetStudentComment"
import { EditStudentComment } from "domain/useCase/Admin/StudentDashboard/EditStudentComment"
import { DeleteStudentComment } from "domain/useCase/Admin/StudentDashboard/DeleteStudentComment"
import { GetPlacementReady } from "domain/useCase/Admin/StudentDashboard/GetPlacementReady"
import { SetPlacementReady } from "domain/useCase/Admin/StudentDashboard/SetPlacementReady"
import { TTableData } from "core/constants/types"
import TableButton from "core/components/v2/TableButton"
import StarRating from "core/components/v2/RatingStars"
import { CreateOnboardingMeetAPIDataSourceImpl } from "data/API/Admin/CreateOnboardingMeetAPIDataSourceImpl"
import { CreateOnboardingMeetRepositoryImpl } from "data/repository/Admin/CreateOnboardingMeetRepositoryImpl"
import { CreateOnboardingMeet } from "domain/useCase/Admin/OnboardingMeet/CreateOnboardingMeet"
import { GetOnboardingMeet } from "domain/useCase/Admin/OnboardingMeet/GetOnboardingMeet"
import { PostOnboardingFeedback } from "domain/useCase/Admin/OnboardingMeet/PostOnboardingFeedback"
import { ManagerFeedback, OnboardingData } from "domain/model/OnboardingMeet"
import { OnboardingManagerNames } from "domain/useCase/Admin/OnboardingMeet/OnboardingManagerName"
import { OnboardingManagerNameRepositoryImpl } from "data/repository/Admin/OnboardingManagerNameRepositoryImpl"
import { OnboardingManagerNameAPIDataSourceImpl } from "data/API/Admin/OnboardingManagerNameAPIDataSourceImpl"
import useLocalStorage from "core/hooks/useLocalStorage"
import { UpdateLeetcode } from "domain/useCase/Student/Profile/UpdateLeetcode"
import { ProfileRepositoryImpl } from "data/repository/Student/ProfileRepositoryImpl"
import { ProfileAPIDataSourceImpl } from "data/API/Student/ProfileAPIDataSourceImpl"

export default function ProfilePageViewModel() {
  const [student, setStudent] = useLocalStorage<any>("student", {})
  const [paymentMethod, setPaymentMEthod] = useState<any>({})
  const [advanceStudent, setAdvanceStudent] = useState<any>({})
  const [refund, setRefund] = useState<any>({})
  const [placement, setPlacement] = useState<any>({ placement_history: [] })
  const [studentComments, setStudentComments] = useState<any>([])
  const [searchEmail, setSearchEmail] = useState<any>("")
  const [mentorPriority, setMentorPriority] = useState<any>([])
  const [onboardingManagersList, setOnboardingManagersList] = useState<any>([])
  const [onboardingManagerId, setOnboardingManagerId] = useState<any>("")
  const [milestoneSessionMentorId, setMilestoneSessionMentorId] = useState<any>("")
  const [mentorList, setMentorList] = useState<any>([])
  const [mentorId, setMentorId] = useState<any>("")
  const [comment, setComment] = useState<any>("")
  const [, setLeetcodeId] = useState<any>("")
  const [removeMentorLoading, setRemoveMentorLoading] = useState<boolean>(false)
  const [removeMilestoneMentorLoading, setRemoveMilestoneMentorLoading] = useState<boolean>(false)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [studentEMI, setStudentEMI] = useState<any>({})
  const [paymentDocuments, setPaymentDocuments] = useState<any>([])
  const [tableData, setTableData] = React.useState<TTableData>({} as TTableData)
  const [paymentDetails, setPaymentDetails] = useState({
    student_email: "",
    instalments: [{ amount: 0, due_time: 0 }],
    course_fees: 0,
    discount: 0,
    seat_booking_amount: 0,
  })
  const [studentGetPayDetail, setStudentGetPayDetail] = useState<any>({})

  const [studentPayTableData, setStudentPayTableData] = useState<any>({
    header: [],
    rows: [],
  })
  const [isExpanded, setIsExpanded] = useState<any>(false)
  const [isExpandedBreakdown, setIsExpandedBreakdown] = useState<any>(false)
  const [isCompletlyPaid, setIsCompletlyPaid] = useState<any>(true)

  const [openPaymentViewDetail, setOpenPaymentViewDetail] = useState<any>(false)
  const [instalmentPayInfo, setInstalmentPayInfo] = useState<any>({})
  const [updateTransactionPopup, setUpdateTransactionPopup] = useState<boolean>(false)
  const [updateTransactionDetailValues, setUpdateTransactionDetailValues] = useState<any>({
    payment_link_id: instalmentPayInfo?.paymentInfo?.payment_link_id,
    invoice_number: instalmentPayInfo?.paymentInfo?.invoice_number,
    status: instalmentPayInfo?.paymentInfo?.status,
    payment_method: instalmentPayInfo?.paymentInfo?.payment_method,
    paid_at: instalmentPayInfo?.paymentInfo?.paid_at,
  })
  const [payReciveDate, setPayReciveDate] = useState("")

  const { id }: any = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const [meeting, setMeeting] = useState("")
  const [updatedMeeting, setUpdatedMeeting] = useState("")
  const [meetingDate, setMeetingDate] = useState("")
  const [meetingUpdatedDate, setMeetingUpdatedDate] = useState("")
  const [meetingDuration, setMeetingDuration] = useState("")
  const [meetingUpdatedDuration, setMeetingUpdatedDuration] = useState("")
  const [meetingSsmRemark, setMeetingSsmRemark] = useState("")
  const [meetingAgenda, setMeetingAgenda] = useState("")
  const [meetingUpdatedSsmRemark, setMeetingUpdatedSsmRemark] = useState("")
  const [meetingUpdatedAgenda, setMeetingUpdatedAgenda] = useState("")
  const [status, setStatus] = useState("")
  const [updatedStatus, setUpdatedStatus] = useState("")
  const [rating, setRating] = useState(0)
  const [updatedRating, setUpdatedRating] = useState(0)
  const [currentDate, setCurrentDate] = useState("")
  const [feedback, setFeedback] = useState("")
  const [updatedFeedback, setUpdatedFeedback] = useState("")
  const [problemSolvingMilestoneDueDate, setProblemSolvingMilestoneDueDate] = useState("")
  const [updatedProblemSolvingMilestoneDueDate, setUpdatedProblemSolvingMilestoneDueDate] = useState("")
  const [developmentMilestoneDueDate, setDevelopmentMilestoneDueDate] = useState("")
  const [updatedDevelopmentMilestoneDueDate, setUpdatedDevelopmentMilestoneDueDate] = useState("")
  const [problemSolvingMilestone, setProblemSolvingMilestone] = useState("")
  const [updatedProblemSolvingMilestone, setUpdatedProblemSolvingMilestone] = useState("")
  const [developmentMilestone, setDevelopmentMilestone] = useState("")
  const [updatedDevelopmentMilestone, setUpdatedDevelopmentMilestone] = useState("")
  const [onboardingManagerName, setOnboardingManagerName] = useState("")
  const statusOption = ["Completed", "Student Absent"].map((item) => ({
    value: item,
    label: item,
  }))
  const [paymentMethodsList, setPaymentMethodsList] = useState([])
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const [menuVisible, setMenuVisible] = useState(true)
  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }
  const [loading, setLoading] = useState(false)
  const [isCreateGroupSessionOpen, setIsCreateGroupSessionOpen] = useState<boolean>(false)
  const [isDetailUpdateOpen, setIsDetailUpdateOpen] = useState<boolean>(false)
  const [isDeleteSSMPopUp, setIsDeleteSSMPopUp] = useState<boolean>(false)
  const [isSessionDetailOpen, setIsSessionDetailOpen] = useState<boolean>(false)
  const [history, setHistory] = useState<any>([])
  const [scheduledCnt, setScheduledCnt] = useState(0)
  const [largestDate, setLargestDate] = useState("00000000")
  const [secondLargestDate, setSecondLargestDate] = useState("00000000")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [paymentOption, setPaymentOptions] = useState<boolean>()
  const countryOptions = Country.getAllCountries().map((country: any) => ({
    value: country.name + " " + country.isoCode,
    label: country.name,
  }))
  const defaultCountryValue = {
    value: "Country Not Selected",
    label: "Country Not Selected",
  }
  const [stateOptions, setStateOptions] = useState<any[]>([])
  const [batchHistoryData, setBatchHistoryData] = useState<any>([])
  const [placementDetails, setPlacementDetails] = useState<any>([])
  const [placementReadyDetails, setPlacementReadyDetails] = useState<any>({})
  const [comments, setComments] = useState<any>([])
  const [formLoding, setFormLoding] = useState<boolean>(false)
  const [startTime, setStartTime] = useState<any>()
  const [refundUserData, setRefundUserData] = useState<any>({})
  const [refundFormOpen, setRefundFormOpen] = useState(false)
  const [showPopup, setShowPopup] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isFeedbackModalOpen, SetIsFeedbackModalOpen] = React.useState(false)
  const [isFeedbackModalView, SetIsFeedbackModalView] = React.useState(false)
  const [isFirstClick, setIsFirstClick] = useState(true)
  const [isFeedbackShow, SetIsFeedbackShow] = React.useState(1)
  const [onboardingDate, setOnboardingDate] = React.useState<any>()
  const [onboardingTime, setOnboardingTime] = React.useState<any>()
  const [endTime, setEndTime] = React.useState<any>()
  const [objective, setObjective] = React.useState("")
  const [workAction, setWorkAction] = React.useState("")
  const [onboardingData, setOnboardingData] = React.useState<any>("")
  const [remainingTime, setRemainingTime] = useState({ days: "", hours: "", minutes: "", seconds: "" })
  const [expired, setExpired] = useState(true)
  const [userFetched, setUserFetched] = useState(false)
  const [initialLeetcode, setInitialLeetcode] = useState("")

  const refundStudentUseCase = new RefundStudent(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const UpdateLeetcodeUseCase = new UpdateLeetcode(new ProfileRepositoryImpl(new ProfileAPIDataSourceImpl()))

  const handleRefundBtnClick = () => {
    setRefundFormOpen(true)
  }

  const handleDiscardBtnClick = () => {
    let obj: any = {
      student_name: refundUserData.student_name,
      phone_number: refundUserData.phone_number,
      email_id: refundUserData.email_id,
      state: refundUserData.state,
      status: "Refund",
      address: refundUserData.address,
    }
    setRefundUserData({ ...obj })
    setRefundFormOpen((prev) => !prev)
  }

  const handleRefundFormClose = () => {
    console.error(Object.keys(refundUserData).length)
    if (Object.keys(refundUserData).length === 5 || 6) {
      setRefundFormOpen(false)
    } else {
      if (
        refundUserData.amount === undefined ||
        refundUserData.refNo === undefined ||
        refundUserData.date_time === undefined ||
        refundUserData.payment_method === undefined
      ) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Please fill all the fields")
        return
      } else {
        setShowPopup(true)
      }
    }
  }

  const handleSaveChangesClick = () => {
    if (
      refundUserData.amount === undefined ||
      refundUserData.refNo === undefined ||
      refundUserData.date_time === undefined ||
      refundUserData.payment_method === undefined
    ) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Please fill all the fields")
      return
    }
    setShowPopup(true)
  }
  function formatTimestamp(timestamp: any) {
    const date = new Date(timestamp * 1000)

    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "long" })
    const year = date.getFullYear()

    // Determine the day suffix (st, nd, rd, th)
    const daySuffix = (day: any) => {
      if (day > 3 && day < 21) return "th" // Handle teens
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }

    return `${day}${daySuffix(day)} ${month}, ${year}`
  }

  function convertAndAddWeek(dateStr: string) {
    const year = dateStr.substring(0, 4)
    const month = dateStr.substring(4, 6)
    const day = dateStr.substring(6, 8)

    const date = new Date(`${year}-${month}-${day}`)

    function formatDate(date: any) {
      const newYear = date.getFullYear()
      const newMonth = date.getMonth() // getMonth() returns 0-based month index
      const newDay = date.getDate()

      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      const monthName = months[newMonth]

      const formattedDay = newDay < 10 ? "0" + newDay : newDay // Ensure two-digit day

      return `${formattedDay} ${monthName} ${newYear}`
    }

    const formattedOriginalDate = formatDate(date)

    return formattedOriginalDate
  }

  const handleConfirmClick = async () => {
    try {
      refundUserData["due_time"] = refundUserData.date_time.split("T")[0]
      refundUserData.date_time = Math.floor(new Date(refundUserData.date_time).getTime() / 1000)
      const response = await refundStudentUseCase.invoke(auth, refundUserData)
      if (!response?.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, "Error occurred while refunding record")
        return
      }
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, "Successfully refunded record is stored")
    } catch (error) {
      console.error(error)
    } finally {
      setShowPopup(false)
      setRefundFormOpen(false)
    }
  }
  const handlePopUpClose = () => {
    setShowPopup((prev) => !prev)
  }
  const handleCancelClick = () => {
    setShowPopup((prev) => !prev)
    handleDiscardBtnClick()
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target
    setRefundUserData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
    console.error(refundUserData)
  }

  const Keys = {
    student_name: "Student Name",
    email_id: "Email Id",
    phone_number: "Phone Number",
    payee_name: "Payee Name",
    verified_by_ops: "verified_by_ops",
    amount: "Amount",
    status: "Status",
    refNo: "Payment ID",
    date_time: "Date & Time",
    payment_method: "Payment Method",
    "payment_sub-method": "Payment Sub-Method",
    state: "Party State",
    GST: "GST Number",
    address: "Address",
  }
  const disabledKeys = ["email_id", "phone_number", "student_name", "status"]

  const getUserDataUseCase = new GetAllStudentData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const getBatchHistoryUseCase = new GetBatchHistoryData(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const getMentorsDetailListUseCase = new GetMentorsDetailList(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )
  const getOnboardingManagerListUseCase = new GetOnboardingManager(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const createOnboardingMeetUseCase = new CreateOnboardingMeet(
    new CreateOnboardingMeetRepositoryImpl(new CreateOnboardingMeetAPIDataSourceImpl())
  )
  const getOnboardingMeetUseCase = new GetOnboardingMeet(
    new CreateOnboardingMeetRepositoryImpl(new CreateOnboardingMeetAPIDataSourceImpl())
  )
  const postOnboardingFeedbackUseCase = new PostOnboardingFeedback(
    new CreateOnboardingMeetRepositoryImpl(new CreateOnboardingMeetAPIDataSourceImpl())
  )
  const getOnboardingManagerNameUseCase = new OnboardingManagerNames(
    new OnboardingManagerNameRepositoryImpl(new OnboardingManagerNameAPIDataSourceImpl())
  )
  const submitResponseUseCase = new UpdateStudentProfile(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const removeMentorUseCase = new RemoveMentor(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const removeMilestoneMentorUseCase = new updateMilestoneSessionMentor(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const createAdminPaymentUseCase = new PaymentAdmin(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const getPaymentDetailAdminUseCase = new GetPaymentDetailAdmin(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const updateTransactionDetailAdminUseCase = new UpdateTransactionDetailAdmin(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const updateCCAvTransactionDetailAdminUseCase = new UpdateCCAvTransactionDetailAdmin(
    new CCAvPaymentAdminRepository(new CCAvPaymentAdminAPIDataSourceImpl())
  )
  const getStudentEMIUseCase = new GetStudentEMI(new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl()))
  const getPaymentDocumentsUseCase = new GetPaymentDocuments(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const uploadPaymentDocumentCase = new UploadPaymentDocument(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const documentVerifiedUseCase = new DocumentVerified(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const updatePaymentDocumentUseCase = new UpdatePaymentDocument(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  const getPaymentMethodsUseCase = new GetPaymentMethods(
    new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  )
  // const changePaymentOptionsMenuUseCase = new UpdatePaymentOptionsMenu(
  //   new PaymentAdminRepositoryImpl(new PaymentAdminAPIDataSourceImpl())
  // );
  // const GetOnboardingMeetingDetailsUseCase = new GetOnboardingMeetingDetails(
  //   new HomeRepositoryImpl(new HomeAPIDataSourceImpl())
  // )
  const addPlacementCompanyUseCase = new AddPlacementCompany(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const getPlacementCompanyUseCase = new GetPlacementCompany(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const editPlacementCompanyUseCase = new EditPlacementCompany(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const deletePlacementCompanyUseCase = new DeletePlacementCompany(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const getPlacementReadyUseCase = new GetPlacementReady(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const setPlacementReadyUseCase = new SetPlacementReady(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const addStudentCommentUseCase = new AddStudentComment(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const getStudentCommentUseCase = new GetStudentComment(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const editStudentCommentUseCase = new EditStudentComment(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )
  const deleteStudentCommentUseCase = new DeleteStudentComment(
    new StudentDashboardRepositoryImpl(new StudentDashboardAPIDataSourceImpl())
  )

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCountry(e.target.value)
    setState("")

    const newSoptions = State.getStatesOfCountry(e.target.value.slice(-2)).map((state: any) => ({
      value: state.name,
      label: state.name,
    }))

    setStateOptions(newSoptions)
  }

  const getStudentEMI = async (student_email: string) => {
    setLoading(true)
    const response = await getStudentEMIUseCase.invoke(auth, student_email)
    if (!response?.success) {
      setLoading(false)
      console.error("ERror in getstudentemi")
      return
    }
    setStudentEMI(response)
    setLoading(false)
  }

  const addPlacementCompany = async (company_details: any) => {
    if (!company_details.company_name) {
      changeToastDetails(STR_FAILURE, "Company Name is required")
      changeToastVisibility(true)
      return
    }
    if (!company_details.company_date) {
      changeToastDetails(STR_FAILURE, "Company Date is required")
      changeToastVisibility(true)
      return
    }
    if (!company_details.designation) {
      changeToastDetails(STR_FAILURE, "Designation is required")
      changeToastVisibility(true)
      return
    }
    setFormLoding(true)
    try {
      const response = await addPlacementCompanyUseCase.invoke(auth, company_details)
      setPlacementDetails(response)
    } catch (error) {
      console.error(error)
    }
    setFormLoding(false)
  }

  const editPlacementCompany = async (company_details: any) => {
    if (!company_details.company_name) {
      changeToastDetails(STR_FAILURE, "Company Name is required")
      changeToastVisibility(true)
      return
    }
    if (!company_details.company_date) {
      changeToastDetails(STR_FAILURE, "Company Date is required")
      changeToastVisibility(true)
      return
    }
    if (!company_details.designation) {
      changeToastDetails(STR_FAILURE, "Designation is required")
      changeToastVisibility(true)
      return
    }
    setFormLoding(true)
    try {
      const response = await editPlacementCompanyUseCase.invoke(auth, company_details)
      setPlacementDetails(response)
    } catch (error) {
      console.error(error)
    }
    setFormLoding(false)
  }
  const deletePlacementCompany = async (company_details: any) => {
    setFormLoding(true)
    try {
      const response = await deletePlacementCompanyUseCase.invoke(auth, company_details)
      setPlacementDetails(response)
    } catch (error) {
      console.error(error)
    }
    setFormLoding(false)
  }
  const getPlacementCompany = async (email: string) => {
    try {
      const response = await getPlacementCompanyUseCase.invoke(auth, email)
      setPlacementDetails(response)
    } catch (error) {
      console.error(error)
    }
  }

  const getPlacementReady = async (email: string) => {
    try {
      const response = await getPlacementReadyUseCase.invoke(auth, email)
      setPlacementReadyDetails(response)
    } catch (error) {
      console.error(error)
    }
  }
  const setPlacementReady = async (placement_details: any) => {
    try {
      const response = await setPlacementReadyUseCase.invoke(auth, placement_details)
      setPlacementReadyDetails(response)
    } catch (error) {
      console.error(error)
    }
  }

  const addStudentComment = async (comment_details: any) => {
    if (!comment_details.comment_date) {
      changeToastDetails(STR_FAILURE, "Comment Date is required")
      changeToastVisibility(true)
      return
    }
    if (!comment_details.updated_by) {
      changeToastDetails(STR_FAILURE, "Updated By is required")
      changeToastVisibility(true)
      return
    }
    if (!comment_details.reason) {
      changeToastDetails(STR_FAILURE, "Reason is required")
      changeToastVisibility(true)
      return
    }
    setFormLoding(true)
    try {
      const response = await addStudentCommentUseCase.invoke(auth, comment_details)
      setStudentComments(response)
    } catch (error) {
      console.error(error)
    }
    setFormLoding(false)
  }

  const getStudentComment = async (email: string) => {
    try {
      const response = await getStudentCommentUseCase.invoke(auth, email)
      setStudentComments(response)
    } catch (error) {
      console.error(error)
    }
  }

  const editStudentComment = async (comment_details: any) => {
    if (!comment_details.comment_date) {
      changeToastDetails(STR_FAILURE, "Comment Date is required")
      changeToastVisibility(true)
      return
    }
    if (!comment_details.updated_by) {
      changeToastDetails(STR_FAILURE, "Updated By is required")
      changeToastVisibility(true)
      return
    }
    if (!comment_details.reason) {
      changeToastDetails(STR_FAILURE, "Reason is required")
      changeToastVisibility(true)
      return
    }
    setFormLoding(true)
    try {
      const response = await editStudentCommentUseCase.invoke(auth, comment_details)
      setStudentComments(response)
    } catch (error) {
      console.error(error)
    }
    setFormLoding(false)
  }

  const deleteStudentComment = async (comment_details: any) => {
    setFormLoding(true)
    try {
      const response = await deleteStudentCommentUseCase.invoke(auth, comment_details)

      setStudentComments(response)
    } catch (error) {
      console.error(error)
    }
    setFormLoding(false)
  }

  const getPaymentDocuments = async (student_email: string) => {
    try {
      const response = await getPaymentDocumentsUseCase.invoke(auth, student_email)
      if (!response?.success) {
        console.error("error")
        return
      }
      setPaymentDocuments(response?.data?.documents)
    } catch (error: any) {
      console.error(error)
    }
  }

  const updatePaymentDocument = async (student_email: string, document: TPaymentDocumentItem, id: number) => {
    try {
      const response = await updatePaymentDocumentUseCase.invoke(auth, student_email, document, id)
      if (!response?.success) {
        console.error("error")
        return
      }
    } catch (error) {
      console.error(error)
    }
  }

  const documentVerified = async (student_email: string, status: boolean) => {
    try {
      if (student_email) await documentVerifiedUseCase.invoke(auth, student_email, status)
    } catch (err) {
      console.error(err)
    }
  }

  const uploadPaymentDocument = async (student_email: string, file: File, filename: string, id: number) => {
    try {
      const response = await uploadPaymentDocumentCase.invoke(auth, student_email, file, filename, id)
      if (!response?.success) {
        console.error("error")
        return
      }
    } catch ({ response: { data: error } }: any) {
      console.error(error)
    }
  }

  function handlestateChange(e: any) {
    setState(e.target.value)
  }
  async function submitResponse(data: any) {
    return await submitResponseUseCase.invoke({ auth, ...data })
  }
  async function getBatchHistoryData(id: string) {
    const response = await getBatchHistoryUseCase.invoke(auth, id)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch batch history"))
      changeToastVisibility(true)
      return
    }
    setBatchHistoryData(response?.data)
  }
  async function getMentorsList(id: string) {
    const response = await getMentorsDetailListUseCase.invoke(auth)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch all mentors"))
      changeToastVisibility(true)
      return
    }
    const mentors = response?.data
    const mentorList = mentors?.map((m: any) => ({
      value: m?.email,
      label: m?.name,
    }))
    setMentorList(mentorList)
  }

  async function getOnboardingManagerList() {
    try {
      const response = await getOnboardingManagerListUseCase.invoke(auth)
      if (!response) {
        changeToastDetails(STR_FAILURE, genError(response, "Unable to fetch onboarding managers"))
        changeToastVisibility(true)
        return []
      }

      const onboardingManagers = response.onboarding_team_list
      type Manager = {
        name: string
        designation: string
        linkedin: string
        phone: string
        profile_pic: string
        isLead: boolean
        isDisabled: boolean
      }

      const onboardingManagersList: { value: string; label: string }[] = Object.entries(onboardingManagers).reduce(
        (result, [email, manager]) => {
          const m = manager as Manager
          if (!m.isDisabled) {
            result.push({ value: email, label: m.name })

            if (m.isLead && !result.some((entry) => entry.label === "Other")) {
              result.push({ value: email, label: "Other" })
            }
          }

          return result
        },
        [] as { value: string; label: string }[]
      )
      setOnboardingManagersList(onboardingManagersList)
      return onboardingManagersList
    } catch (error) {
      changeToastDetails(STR_FAILURE, "An error occurred while fetching onboarding managers")
      changeToastVisibility(true)
      return []
    }
  }

  const getUserData = async (email: string) => {
    const response = await getUserDataUseCase.invoke({
      admin_uid: auth.local_id,
      email: email,
    })

    if (!response) {
      changeToastDetails(STR_FAILURE, "Error while fetching user data")
      changeToastVisibility(true)
      return
    }
    if (typeof response === "string") {
      const fixedJsonString = response.replace(/NaN/g, "null")

      const parsedObject = JSON.parse(fixedJsonString)
      var user = parsedObject?.response
    } else {
      var user = response?.response
    }

    const student = user?.student
    const advanceStudent = user?.advance_student
    const refund_object = {
      student_name: student?.name,
      phone_number: student?.phone,
      email_id: student?.email,
      state: student?.state,
      address: student?.state + student?.country,
      milestone_session_mentor: student?.milestone_session_mentor,
    }

    setUserFetched(true)

    setSearchEmail(email)
    setStudent(student)
    setInitialLeetcode(student.leetcode)
    setRefundUserData(refund_object)
    setAdvanceStudent(advanceStudent)
    setRefund(advanceStudent?.refund)
    setPlacement(advanceStudent?.placement)
    setOnboardingManagerId(student?.onboardingManagerId)
    setComments(advanceStudent?.comments === undefined ? [] : advanceStudent?.comments)
    setMentorPriority(student?.mentor_priority)
    setMentorId(student?.mentor_id)
    setOnboardingManagerId(student?.onboardingManagerId)
    setLeetcodeId(student?.leetcode)
  }

  const ChangePaymentOptionsMenu = async (e: any) => {
    const student_obj = { ...student }
    student_obj[e.target.id] = ["true", "false"].includes(e.target.value) ? e.target.value === "true" : e.target.value
    setStudent(student_obj)
  }

  const getPaymentMethods = async () => {
    const response = await getPaymentMethodsUseCase.invoke(auth)
    setPaymentMethodsList(response.data.methods)
  }
  const redirectToSearchedUser = (e: any) => {
    e.preventDefault()
    navigate(`/admin/students/${searchEmail}`)
  }

  function handleSearchByEmail(event: any) {
    if (event.target.value) setSearchEmail(event.target.value)
    else setSearchEmail("")
  }

  function onChangeStudentObj(e: any) {
    if (e.target.id === "country") {
      handleCountryChange(e)
    }
    if (e.target.id === "state") {
      handlestateChange(e)
    }
    let student_obj = { ...student }
    student_obj[e.target.id] = e.target.value
    setStudent(student_obj)
  }
  function onChangeAdvStudentObj(e: any) {
    const student_obj = { ...advanceStudent }
    student_obj[e.target.id] = e.target.value
    setAdvanceStudent(student_obj)
  }

  function onSelectChangeStudentStatus(e: any) {
    const student_obj = { ...advanceStudent }
    student_obj.student_status = e.target.value
    setAdvanceStudent(student_obj)
  }

  function onSelectChangePaymentMethod(e: any) {
    const student_obj = { ...student }
    student_obj.payment_method = e.target.value
    setStudent(student_obj)
  }

  function onChangeRefund(e: any) {
    const refund_obj = { ...refund }
    refund_obj[e.target.id] = e.target.value
    setRefund(refund_obj)
  }

  function onChangePlacement(e: any) {
    const placement_obj = { ...placement }
    placement_obj[e.target.id] = e.target.value
    setPlacement(placement_obj)
  }

  function addPlacementHistory(e: any) {
    const placement_obj = { ...placement }
    const placement_history = placement_obj.placement_history
    placement_history.push("")
    placement_obj.placement_history = placement_history
    setPlacement(placement_obj)
  }

  function handlePlacementHistoryChange(e: any) {
    const index = parseInt(e.target.id)
    const newList = placement.placement_history.map((option: any, idx: any) => {
      if (idx === index) {
        return e.target.value
      }
      return option
    })

    setPlacement({ ...placement, placement_history: newList })
  }

  function onSelectChangeStudentObj(e: any) {
    const student_obj = { ...student }
    student_obj[e.target.id] = ["true", "false"].includes(e.target.value) ? e.target.value === "true" : e.target.value
    setStudent(student_obj)
  }
  function onSelectChangeOnboardingManager(e: any) {
    setOnboardingManagerId(e.target.value)
    const student_obj = { ...student }
    student_obj.onboardingManagerId = e.target.value
    setStudent(student_obj)
  }
  function onSelectChangeMilestoneSessionMentor(e: any) {
    setMilestoneSessionMentorId(e.target.value)
    const student_obj = { ...student }
    student_obj.milestone_session_mentor = e.target.value
    setStudent(student_obj)
  }
  function onSelectChangeAdvStudentObj(e: any) {
    const { id, value } = e.target
    setAdvanceStudent((prevState: any) => ({
      ...prevState,
      [id]: value,
    }))
  }

  function onChangeTypeOfRefund(e: any) {
    const refund_obj = { ...refund }
    refund_obj.type_of_refund = e.target.value
    setRefund(refund_obj)
  }

  function onChangeComment(e: any) {
    setComment(e.target.value)
  }

  function addComment(e: any) {
    const newComment = {
      date: Date.now(),
      comment: comment,
    }
    setComments([...comments, newComment])
    setComment("")
  }

  async function getOnBoardingData(email: any) {
    const response = await getOnboardingMeetUseCase.invoke(auth, email)
    setOnboardingData(response)
  }

  async function getOnboardingManagerName(email: any) {
    const response = await getOnboardingManagerNameUseCase.invoke(auth, email)
    setOnboardingManagerName(response)
  }

  function ConvertTimeStampToGivenFormat(seconds: number) {
    const date = new Date(seconds * 1000)
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "long" })
    const year = date.getFullYear()
    let hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, "0")
    const ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12
    const getOrdinalSuffix = (day: any) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }
    return `${day}${getOrdinalSuffix(day)} ${month}, ${year} ${hours}:${minutes} ${ampm}`
  }

  async function handleOnboardingMeetingTable(onboardingData: any) {
    const header = ["Subject", "Description", ""]
    let onboardingStatus = "Not Scheduled"
    let nameOfOnboardingManager = onboardingManagerName
    let onboardingTimeFormat = "-"
    if (onboardingData?.start_time && onboardingData?.manager_name) {
      onboardingStatus = "Scheduled"
      nameOfOnboardingManager = onboardingData?.manager_name
      onboardingTimeFormat = ConvertTimeStampToGivenFormat(onboardingData?.start_time)
    }
    if (onboardingData?.duration) {
      onboardingStatus = "Attended"
    }
    const onboarding = [
      { field: "Meeting status", desc: onboardingStatus },
      { field: "Onboarding Date", desc: onboardingTimeFormat },
      { field: "Rating By Student", desc: <StarRating starRating={onboardingData?.student_feedback?.star} /> },
      { field: "Manager Name", desc: nameOfOnboardingManager },
      { field: "Feedback", desc: "-" },
    ]

    const rows = onboarding?.map((lecture: any, index: number) => {
      return [
        <span className="block truncate" title={lecture?.field}>
          {lecture?.field}
        </span>,
        <span className="block truncate justify-center">{lecture?.desc}</span>,
        <div className="h-[32px] max-w-[220px] justify-end">
          {index === 0 ? (
            <TableButton
              outlined
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              Edit Details
            </TableButton>
          ) : (
            <></>
          )}
          {index === 4 ? (
            <TableButton
              outlined
              onClick={() => {
                SetIsFeedbackModalOpen(true)
              }}
            >
              Feedback
            </TableButton>
          ) : (
            <></>
          )}
        </div>,
      ]
    })
    setTableData({ header, rows })
  }

  function handleEditOnboardingDetails() {}

  function addThirtyMinutes(time: string) {
    if (time) {
      const [timePart, period] = time.split(" ")
      let [hours, minutes] = timePart.split(":").map(Number)
      minutes = minutes + 30
      hours = hours + Math.floor(minutes / 60)
      minutes = minutes % 60
      hours = hours % 12 || 12
      const padZero = (num: number) => (num < 10 ? `0${num}` : `${num}`)
      const formattedHours = padZero(hours)
      const formattedMinutes = padZero(minutes)
      setEndTime(`${formattedHours}:${formattedMinutes} ${period}`)
    }
  }

  function convertOnboardingTimeToTimeStamp(onboardingTime: any, onboardingDate: any) {
    if (onboardingTime && onboardingDate) {
      const [day, month, year] = onboardingDate.split("/").map(Number)
      const [timePart, period] = onboardingTime.split(" ")
      let [hours, minutes] = timePart.split(":").map(Number)
      if (period === "PM" && hours < 12) {
        hours += 12
      } else if (period === "AM" && hours === 12) {
        hours = 0
      }
      const dateTime = new Date(year, month - 1, day, hours, minutes, 0, 0)
      setStartTime(dateTime.getTime() / 1000)
    }
  }

  function handleObjective(e: any) {
    setObjective(e.target.value)
  }
  function handleWorkAction(e: any) {
    setWorkAction(e.target.value)
  }
  function joinMeet() {
    window.open(onboardingData?.manager_link)
  }
  async function handleFormSubmit(data: OnboardingData) {
    if (data.date != "" && data.start) {
      const [day, month, year] = data.date.split("/").map(Number)
      const currentDate = new Date()
      const currentDay = currentDate.getDate()
      const currentMonth = currentDate.getMonth() + 1
      const currentYear = currentDate.getFullYear()
      const storedTime = data.start * 1000
      const currentTime = currentDate.getTime()
      if (day === currentDay && month === currentMonth && year === currentYear && storedTime < currentTime) {
        changeToastDetails(STR_FAILURE, "The stored time is less than the current time.")
        changeToastVisibility(true)
        setIsModalOpen(true)
      } else {
        const response = await createOnboardingMeetUseCase.invoke(data, auth)
        if (response.success) {
          changeToastDetails(STR_SUCCESS, "Onboarding Meet Scheduled.")
          changeToastVisibility(true)
          setOnboardingData(response?.data)
        }
        setIsModalOpen(false)
      }
    } else {
      changeToastDetails(STR_FAILURE, "Please choose the correct date and time")
      changeToastVisibility(true)
      setIsModalOpen(true)
    }
  }
  async function handleFormSubmitFeedback(data: ManagerFeedback) {
    const response = await postOnboardingFeedbackUseCase.invoke(data, auth)
    if (!response.success) {
      changeToastDetails(STR_FAILURE, "Manager Feedback Not Submitted")
      changeToastVisibility(true)
      SetIsFeedbackModalOpen(false)
      // return response
    } else {
      changeToastDetails(STR_SUCCESS, "Manager Feedback Submitted Successfully")
      changeToastVisibility(true)
      SetIsFeedbackModalOpen(false)
      // setOnboardingData(response?.data)
    }
  }
  function cancelOnboardingForm() {
    setIsModalOpen(false)
  }
  function cancelFeedbackForm() {
    SetIsFeedbackModalOpen(false)
  }
  const handleChangePayment = (field: any, value: any) => {
    setPaymentDetails((prev) => ({
      ...prev,
      //  [field]: field==="course_fees"|| "discount"|| "seat_booking_fees" ? parseInt(value): value,
      [field]: value,
    }))
  }

  const handleInstalmentChange = (index: any, field: any, value: any) => {
    const updatedInstalments = [...paymentDetails.instalments]
    // console.error(index,updatedInstalments);
    updatedInstalments[index] = {
      ...updatedInstalments[index],
      // [field]: field==="amount"? parseInt(value,10):value,
      [field]: value,
    }

    handleChangePayment("instalments", updatedInstalments)
  }

  const handleAddInstalment = () => {
    setPaymentDetails((prev) => ({
      ...prev,
      instalments: [...prev.instalments, { amount: 0, due_time: 0 }],
    }))
  }

  const handleRemoveInstalment = () => {
    if (paymentDetails.instalments.length > 1) {
      const updatedInstalments = [...paymentDetails.instalments]
      updatedInstalments.pop()
      handleChangePayment("instalments", updatedInstalments)
    }
  }

  const handlePaymentSubmit = async () => {
    const response = await createAdminPaymentUseCase.invoke(auth, {
      paymentData: paymentDetails,
    })
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response))
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, response?.data)

    setPaymentDetails({
      student_email: "",
      instalments: [{ amount: 0, due_time: 0 }],
      course_fees: 0,
      discount: 0,
      seat_booking_amount: 0,
    })
  }

  async function handleSubmit(e: any) {
    e.preventDefault()

    // if (!student?.onboardingManagerId || student.onboardingManagerId === "") {
    //   changeToastVisibility(true)
    //   changeToastDetails(STR_FAILURE, "Please select an onboarding manager")
    //   return
    // }

    const advance_student = advanceStudent
    advance_student.refund = refund
    advance_student.placement = placement
    advance_student.comments = comments

    const data = {
      advance_student: advance_student,
      student: student,
    }

    setUpdating(true)

    if (student.leetcode !== initialLeetcode) {
      try {
        await UpdateLeetcodeUseCase.invoke(student.leetcode, student.email, auth, API_V3_ADMIN)
      } catch (err) {
        setUpdating(false)
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, genError(err))
        return
      }
    }

    const response = await submitResponse(data)

    setUpdating(false)

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, genError(response))
      return
    }

    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, STR_STUDENT_INFO_SAVED)
  }
  // time stamp to input format date Output: "2022-02-14"

  function formatPaidAt(timestamp: any): string {
    const date = new Date(parseInt(timestamp) * 1000)
    const day = String(date.getDate()).padStart(2, "0") // Ensure two-digit day
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return `${year}-${String(monthIndex + 1).padStart(2, "0")}-${day}`
  }

  const handlePaymentViewDetails = (payment: any, paymentIndex: any, index: any) => {
    console.error("view detail", payment)
    console.error("payment link", payment.payment_link_id)
    let payment_id = ""
    if (payment.payment_type === "refund") {
      console.error("refund id", payment.refund_id)
      payment_id = payment.refund_id
    } else if (payment.payment_type === "seat_booking") {
    } else {
      payment_id = payment.payment_link_id
    }

    setUpdateTransactionDetailValues({
      payment_link_id: payment.payment_link_id,
      payment_id: payment.payment_id,
      invoice_number: payment.invoice_number,
      status: payment.status,
      payment_method: payment.payment_method,
      paid_at: payment.paid_at,
    })
    setOpenPaymentViewDetail(!openPaymentViewDetail)
    setInstalmentPayInfo({
      paymentInfo: payment,
      index: index,
      paymentIdx: paymentIndex,
    })
    setPayReciveDate(formatPaidAt(payment.paid_at))
  }
  const getPaymentDetailAdmin = async (student_email: any) => {
    const response = await getPaymentDetailAdminUseCase.invoke(auth, {
      paymentData: { student_email },
    })
    setStudentGetPayDetail(response.data)

    const header = ["No.", "Fee Type", "Reference No.", "Payment Due", "Amount", "Status", "Payment Details"]
    if (response?.data?.instalments.length === 1) setIsCompletlyPaid(false)

    let numberOfInstallments = 0
    let numberOfRefunds = 0

    const rows = response?.data?.instalments.map((payment: any, index: any) => {
      const dueDateParts = payment.due_time.split(" ")
      const formattedDueDate = dueDateParts.slice(0, 4).join(" ") // Join the first 4 parts

      if (payment.status === "created") setIsCompletlyPaid(false)
      let feeType = ""
      let paymentIndex = 0

      if (payment.payment_type === "seat_booking") {
        feeType = "Seat Booking"
        paymentIndex = index + 1
      } else if (payment.payment_type === "refund") {
        feeType = `Refund ${++numberOfRefunds}`
        paymentIndex = numberOfRefunds // Index for refund
      } else {
        feeType = `Installment ${++numberOfInstallments}`
        paymentIndex = numberOfInstallments // Index for installment
      }

      return {
        "No.": index + 1,
        "Fee Type": feeType,
        "Reference No.": payment.invoice_number,
        "Payment Due": formattedDueDate,
        Amount: `${payment.amount} Rs`,
        Status: (
          <span
            className={`p-4 ${
              payment.status === "paid" ? "text-500 text-[#00C22B]" : ""
            } ${payment.status === "refund" ? "text-500 text-[#DC2626]" : ""}`}
          >
            {capitalize(payment.status)}
          </span>
        ),
        "Payment Details": (
          <span
            className={`pr-[24px] ${
              payment.status === "paid" || payment.status === "refund" ? "text-500-14px text-[#312E81]" : ""
            }`}
          >
            <Button
              className="h-[33px] w-[120px] rounded-[0.125rem] border-[0.5px] border-[#312E81] px-[16px] py-[8px] text-[14px]"
              small
              disabled={false}
              onClick={() => handlePaymentViewDetails(payment, paymentIndex, index + 1)}
            >
              {payment.status === "paid" || payment.status === "refund" ? "View Details" : "Due"}
            </Button>
          </span>
        ),
      }
    })
    setStudentPayTableData({ header, rows })
  }

  const calculateOfferDiscount = () => {
    const discountAmount =
      (studentGetPayDetail?.fees - studentGetPayDetail?.standard_discount - studentGetPayDetail?.seat_booking_amount) *
      (studentGetPayDetail?.offer_discount_in_percent / 100)

    return Math.max(0, Math.round(discountAmount)) // Ensure non-negative integer
  }
  const calculateOneshotDiscount = () => {
    const discountAmount =
      (studentGetPayDetail?.fees - studentGetPayDetail?.standard_discount - studentGetPayDetail?.seat_booking_amount) *
      (studentGetPayDetail?.one_shot_discount / 100)

    return Math.max(0, Math.round(discountAmount)) // Ensure non-negative integer
  }

  const getPayableAmount = () => {
    if (studentGetPayDetail?.instalments?.length > 2) {
      let amount = 0
      studentGetPayDetail?.instalments?.map((item: any, key: any) => {
        if (item.status === "created") {
          amount = amount + item.amount
        }
      })

      return amount
    } else {
      let amount = studentGetPayDetail?.fees
      amount = amount - studentGetPayDetail?.standard_discount
      amount = amount - studentGetPayDetail?.seat_booking_amount
      return amount
    }
  }

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(student?.password)
    setIsCopied(true)
  }
  const handleRemoveMilestoneMentor = async () => {
    setRemoveMilestoneMentorLoading(true)

    const response = await removeMilestoneMentorUseCase.invoke(auth, id)
    setRemoveMilestoneMentorLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Mentor removed successfully")
    changeToastVisibility(true)
    navigate(0)
  }
  const handleRemoveMentor = async () => {
    setRemoveMentorLoading(true)

    const response = await removeMentorUseCase.invoke(auth, id)
    setRemoveMentorLoading(false)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Mentor removed successfully")
    changeToastVisibility(true)
    navigate(0)
  }
  function handleCreateGroupSession() {
    setIsCreateGroupSessionOpen(!isCreateGroupSessionOpen)
    handleDiscard()
  }
  function handleIsDetailUpdateopen() {
    setIsDetailUpdateOpen(!isDetailUpdateOpen)
    handleDiscard()
  }
  function handleIsDeleteSSMPopUp() {
    setIsDeleteSSMPopUp(!isDeleteSSMPopUp)
    handleDiscard()
  }
  function handleIsSessionDetailopen() {
    setIsSessionDetailOpen(!isSessionDetailOpen)
    handleDiscard()
  }
  function handleMeeting(e: any) {
    setMeeting(e.target.value)
  }
  function handleUpdatedMeeting(e: any) {
    setUpdatedMeeting(e.target.value)
  }
  function handleMeetingDate(e: any) {
    setMeetingDate(e.target.value)
  }
  function handleMeetingUpdatedDate(e: any) {
    setMeetingUpdatedDate(e.target.value)
  }
  function handleMeetingDuration(e: any) {
    setMeetingDuration(e.target.value)
  }
  function handleMeetingUpdatedDuration(e: any) {
    setMeetingUpdatedDuration(e.target.value)
  }
  function handleMeetingSsmRemark(e: any) {
    setMeetingSsmRemark(e.target.value)
  }
  function handleMeetingAgenda(e: any) {
    setMeetingAgenda(e.target.value)
  }
  function handleMeetingUpdatedAgenda(e: any) {
    setMeetingUpdatedAgenda(e.target.value)
  }
  function handleMeetingUpdatedSsmRemark(e: any) {
    setMeetingUpdatedSsmRemark(e.target.value)
  }
  function handleUpdatedStatus(e: any) {
    setUpdatedStatus(e.target.value)
  }
  function handleUpdatedRating(e: number) {
    setUpdatedRating(e)
  }
  function handleUpdatedFeedback(e: any) {
    setUpdatedFeedback(e.target.value)
  }
  function handleUpdatedProblemSolvingMilestoneDate(e: any) {
    setUpdatedProblemSolvingMilestoneDueDate(e.target.value)
  }
  function handleUpdatedDevelopmentMilestoneDate(e: any) {
    setUpdatedDevelopmentMilestoneDueDate(e.target.value)
  }
  function handleUpdatedProblemSolvingMilestone(e: any) {
    setUpdatedProblemSolvingMilestone(e.target.value)
  }
  function handleUpdatedDevelopmentMilestone(e: any) {
    setUpdatedDevelopmentMilestone(e.target.value)
  }
  function removeHypens(dateString: string) {
    return dateString.replace(/-/g, "")
  }
  const getHistoryUseCase = new SSMCallHistorys(new SSMCallHistoryRepositoryImpl(new SSMCallHistoryAPIDataSourceImpl()))
  const createSSMCallUseCase = new CreateSSMCalls(new CreateSSMCallRepositoryImpl(new CreateSSMCallAPIDataSourceImpl()))
  const deleteSSMCallUseCase = new DeleteSSMCalls(new DeleteSSMCallRepositoryImpl(new DeleteSSMCallAPIDataSourceImpl()))
  const updateSSMCallUseCase = new UpdateSSMCalls(new UpdateSSMCallRepositoryImpl(new UpdateSSMCallAPIDataSourceImpl()))
  const updateSSMCallDetailUseCase = new UpdateSSMCallDetails(
    new UpdateSSMCallDetailRepositoryImpl(new UpdateSSMCallDetailAPIDataSourceImpl())
  )
  function getCurrentDate() {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 7)
    return currentDate.toISOString().slice(0, 10).replace(/-/g, "")
  }
  async function handleCreateMeeting(email: any) {
    if (meeting.length == 0 || meetingAgenda.length == 0 || meetingDate.length == 0 || meetingDuration.length == 0) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "All field must be filled to create meeting")
      return
    }
    if (scheduledCnt != 0) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "You already have an Scheduled Meeting , you can update that")
      return
    }
    if (removeHypens(meetingDate) <= largestDate) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "You cant choose that Date")
      return
    }
    setLoading(true)
    const response = await createSSMCallUseCase.invoke({
      id_token: auth.id_token.trim(),
      student_email: email,
      meeting_topic: meeting,
      meeting_date: meetingDate.replace(/-/g, ""),
      meeting_duration: meetingDuration,
      meeting_agenda: meetingAgenda,
      status: "Scheduled",
    })
    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "SSM Call created successfully!")
    handleDiscard()
    getHistory(email)
  }
  async function handleDeleteMeeting(meetingDate: string, email: any) {
    setLoading(true)
    const response = await deleteSSMCallUseCase.invoke({
      id_token: auth.id_token.trim(),
      student_email: email,
      meeting_date: meetingDate.replace(/-/g, ""),
    })
    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "SSM Call Deleted successfully!")
    getHistory(email)
    handleDiscard()
  }
  async function getHistory(email: any) {
    const response = await getHistoryUseCase.invoke({
      id_token: auth.id_token.trim(),
      student_email: email,
    })

    if (response.data) {
      const rawData = response.data
      let currentLargestDate: string | null = null
      let currentSecondLargestDate: string | null = null
      setCurrentDate(getCurrentDate())
      const scheduledCount = Object.values(rawData).filter(
        (entry: any) => entry.status === "Scheduled" && getCurrentDate() <= entry.meeting_date
      ).length

      const sortedDataArray = Object.entries(rawData)
        .map(([key, value]) => {
          if (!currentLargestDate || key > currentLargestDate) {
            currentSecondLargestDate = currentLargestDate
            currentLargestDate = key
          } else if (!currentSecondLargestDate || key > currentSecondLargestDate) {
            currentSecondLargestDate = key
          }
          return { key, value }
        })
        .sort((a, b) => +b.key - +a.key)
        .map(({ key, value }) => ({ [key]: value }))

      setScheduledCnt(scheduledCount)
      setHistory(sortedDataArray)
      setLargestDate(currentLargestDate || "00000000")
      setSecondLargestDate(currentSecondLargestDate || "00000000")
    } else {
      setScheduledCnt(0)
      setHistory([])
      setLargestDate("00000000")
      setSecondLargestDate("00000000")
    }
  }
  async function handleUpdateMeeting(email: any) {
    if (
      updatedMeeting.length == 0 ||
      meetingUpdatedAgenda.length == 0 ||
      meetingUpdatedDate.length == 0 ||
      meetingUpdatedDuration.length == 0
    ) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "All field must be filled to create meeting")
      return
    }
    if (
      updatedMeeting == meeting &&
      meetingUpdatedAgenda == meetingAgenda &&
      meetingDate == meetingUpdatedDate &&
      meetingDuration == meetingUpdatedDuration &&
      meetingUpdatedAgenda.length != 0
    ) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "There is no change in previous and current field")
      return
    }
    if (secondLargestDate != "00000000" && removeHypens(meetingUpdatedDate) <= secondLargestDate) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "You can't choose that Date")
      return
    }
    setLoading(true)
    const response = await updateSSMCallUseCase.invoke({
      id_token: auth.id_token.trim(),
      student_email: email,
      meeting_topic: updatedMeeting,
      meeting_date: meetingUpdatedDate.replace(/-/g, ""),
      meeting_duration: meetingUpdatedDuration,
      meeting_agenda: meetingUpdatedAgenda,
      status: "Scheduled",
      older_date: removeHypens(meetingDate),
    })
    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "SSM Call updated successfully!")
    getHistory(email)
    handleDiscard()
    handleCreateGroupSession()
  }
  async function handleMeetingDetails(email: any) {
    if (removeHypens(meetingUpdatedDate) <= secondLargestDate) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "You can't choose that Date")
      return
    }
    if (
      updatedProblemSolvingMilestoneDueDate.length != 0 &&
      removeHypens(updatedProblemSolvingMilestoneDueDate) <= removeHypens(meetingUpdatedDate)
    ) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Problem Solving Milestone Due Date must be greated than Meeting Date")
      setUpdatedProblemSolvingMilestoneDueDate("")
      return
    }
    if (
      updatedDevelopmentMilestoneDueDate.length != 0 &&
      removeHypens(updatedDevelopmentMilestoneDueDate) <= removeHypens(meetingUpdatedDate)
    ) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Development Milestone Due Date must be greated than Meeting Date")
      setUpdatedDevelopmentMilestoneDueDate("")
      return
    }

    if (
      (updatedDevelopmentMilestone.length != 0 && updatedDevelopmentMilestoneDueDate.length == 0) ||
      (updatedDevelopmentMilestone.length == 0 && updatedDevelopmentMilestoneDueDate.length != 0)
    ) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "If you set a Development Milestone then there must be a due date and vice versa")
      return
    }
    if (
      (updatedProblemSolvingMilestone.length != 0 && updatedProblemSolvingMilestoneDueDate.length == 0) ||
      (updatedProblemSolvingMilestone.length == 0 && updatedProblemSolvingMilestoneDueDate.length != 0)
    ) {
      changeToastVisibility(true)
      changeToastDetails(
        STR_FAILURE,
        "If you set a Problem Solving Milestone then there must be a due date and vice versa"
      )
      return
    }
    setLoading(true)
    const response = await updateSSMCallDetailUseCase.invoke({
      id_token: auth.id_token.trim(),
      student_email: email,
      meeting_topic: meeting,
      meeting_date: meetingUpdatedDate.replace(/-/g, ""),
      meeting_duration: meetingUpdatedDuration,
      meeting_ssmRemark: meetingUpdatedSsmRemark ? meetingUpdatedSsmRemark : "",
      meeting_agenda: meetingUpdatedAgenda,
      status: updatedStatus,
      older_date: meetingDate.replace(/-/g, ""),
      rate: updatedRating,
      feedback: updatedFeedback,
      problem_solving_milestone_due_date: updatedProblemSolvingMilestoneDueDate,
      problem_solving_milestone: updatedProblemSolvingMilestone,
      development_milestone_due_date: updatedDevelopmentMilestoneDueDate,
      development_milestone: updatedDevelopmentMilestone,
    })
    setLoading(false)
    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Unknown error occurred!")
      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "SSM Call details updated successfully!")
    getHistory(email)
    handleIsDetailUpdateopen()
  }
  const handleDiscard = () => {
    setMeeting("")
    setMeetingDate("")
    setMeetingDuration("")
    setMeetingSsmRemark("")
    setMeetingAgenda("")
    setStatus("")
    setRating(0)
    setFeedback("")
    setProblemSolvingMilestoneDueDate("")
    setProblemSolvingMilestone("")
    setDevelopmentMilestoneDueDate("")
    setDevelopmentMilestone("")
    setUpdatedMeeting("")
    setMeetingUpdatedDate("")
    setMeetingUpdatedDuration("")
    setMeetingUpdatedSsmRemark("")
    setMeetingUpdatedAgenda("")
    setUpdatedStatus("")
    setUpdatedRating(0)
    setUpdatedFeedback("")
    setUpdatedProblemSolvingMilestoneDueDate("")
    setUpdatedProblemSolvingMilestone("")
    setUpdatedDevelopmentMilestoneDueDate("")
    setUpdatedDevelopmentMilestone("")
  }
  const addHyphens = (dateString: string): string => {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const day = dateString.slice(6, 8)
    return `${year}-${month}-${day}`
  }
  function formatDate(dateString: string, status: string): string {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(4, 6)
    const day = dateString.slice(6, 8)

    const currentDate = new Date(`${year}-${month}-${day}`)
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    }

    if (status === "Scheduled") {
      const futureDate = new Date(currentDate)
      futureDate.setDate(currentDate.getDate() + 7)

      const formattedCurrentDate = currentDate.toLocaleDateString("en-US", options)
      const formattedFutureDate = futureDate.toLocaleDateString("en-US", options)

      return `${formattedCurrentDate} - ${formattedFutureDate}`
    } else if (status != "Scheduled") {
      const formattedDate = currentDate.toLocaleDateString("en-US", options)
      const day = currentDate.getDate()
      const daySuffix =
        day === 1 || day === 21 || day === 31
          ? "st"
          : day === 2 || day === 22
            ? "nd"
            : day === 3 || day === 23
              ? "rd"
              : "th"
      return `${day}${daySuffix} ${currentDate.toLocaleString("en-US", {
        month: "short",
        year: "numeric",
      })}`
    } else {
      return "Invalid status"
    }
  }
  function handleUpdateButton(meeting: string, meetingDate: string, meetingAgenda: string, meetingDuration: string) {
    setMeeting(meeting)
    setMeetingAgenda(meetingAgenda)
    setMeetingDuration(meetingDuration)
    setMeetingUpdatedDate(addHyphens(meetingDate))
    setMeetingDate(addHyphens(meetingDate))
    setUpdatedMeeting(meeting)
    setMeetingUpdatedAgenda(meetingAgenda)
    setMeetingUpdatedDuration(meetingDuration)
  }
  function handleUpdateDetailButton(
    meeting: string,
    meetingDate: string,
    meetingSsmRemark: string,
    meetingAgenda: string,
    meetingDuration: string,
    status: string,
    rating: number,
    feedback: string,
    psmdd: string,
    dmdd: string,
    psm: string,
    dm: string
  ) {
    setMeeting(meeting)
    setMeetingSsmRemark(meetingSsmRemark)
    setMeetingAgenda(meetingAgenda)
    setMeetingDuration(meetingDuration)
    setMeetingUpdatedDate(addHyphens(meetingDate))
    setMeetingDate(addHyphens(meetingDate))
    setUpdatedMeeting(meeting)
    setMeetingUpdatedSsmRemark(meetingSsmRemark)
    setMeetingUpdatedAgenda(meetingAgenda)
    setMeetingUpdatedDuration(meetingDuration)
    setStatus(status)
    setUpdatedStatus(status)
    setRating(rating)
    setUpdatedRating(rating)
    setFeedback(feedback)
    setUpdatedFeedback(feedback)
    setProblemSolvingMilestoneDueDate(psmdd)
    setUpdatedProblemSolvingMilestoneDueDate(psmdd)
    setDevelopmentMilestoneDueDate(dmdd)
    setUpdatedDevelopmentMilestoneDueDate(dmdd)
    setProblemSolvingMilestone(psm)
    setUpdatedProblemSolvingMilestone(psm)
    setDevelopmentMilestone(dm)
    setUpdatedDevelopmentMilestone(dm)
  }

  function handleUpdateTransactionDetail() {
    setUpdateTransactionPopup(false)
    handlePaymentViewDetails("", "", "")
  }
  const submitUpdateTransaction_of_CCAv_Detail = async (updateTransaction: any) => {
    const response = await updateCCAvTransactionDetailAdminUseCase.invoke(auth, {
      paymentData: updateTransaction,
    })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Transaction updated successfully")
    changeToastVisibility(true)
  }
  const submitUpdateTransactionDetail = async (updateTransaction: any) => {
    const response = await updateTransactionDetailAdminUseCase.invoke(auth, {
      paymentData: updateTransaction,
    })

    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response))
      changeToastVisibility(true)
      return
    }

    changeToastDetails(STR_SUCCESS, "Transaction updated successfully")
    changeToastVisibility(true)
  }

  return {
    toast,
    student,
    advanceStudent,
    refund,
    placement,
    comments,
    comment,
    searchEmail,
    mentorPriority,
    mentorList,
    mentorId,
    id,
    onboardingManagersList,
    onboardingManagerId,
    paymentDetails,

    isCopied,
    updating,
    removeMentorLoading,
    studentEMI,
    paymentDocuments,
    isExpanded,
    isExpandedBreakdown,
    isCompletlyPaid,
    loading,
    studentPayTableData,
    studentGetPayDetail,
    openPaymentViewDetail,
    paymentMethodsList,
    removeMilestoneMentorLoading,
    instalmentPayInfo,
    updateTransactionDetailValues,
    payReciveDate,
    updateTransactionPopup,
    meeting,
    meetingDate,
    meetingDuration,
    meetingSsmRemark,
    meetingAgenda,
    history,
    meetingUpdatedDate,
    meetingUpdatedSsmRemark,
    meetingUpdatedAgenda,
    meetingUpdatedDuration,
    updatedMeeting,
    updatedStatus,
    updatedRating,
    updatedFeedback,
    updatedProblemSolvingMilestoneDueDate,
    updatedDevelopmentMilestoneDueDate,
    updatedProblemSolvingMilestone,
    updatedDevelopmentMilestone,
    isCreateGroupSessionOpen,
    statusOption,
    isDetailUpdateOpen,
    isSessionDetailOpen,
    scheduledCnt,
    isDeleteSSMPopUp,
    menuVisible,
    currentDate,
    countryOptions,
    country,
    stateOptions,
    state,
    defaultCountryValue,
    batchHistoryData,
    refundFormOpen,
    disabledKeys,
    Keys,
    refundUserData,
    placementDetails,
    placementReadyDetails,
    studentComments,
    formLoding,
    showPopup,
    userFetched,
    setOnboardingManagerId,
    redirectToSearchedUser,
    setPaymentDocuments,
    setStudentEMI,
    getStudentEMI,
    getPaymentDocuments,
    uploadPaymentDocument,
    updatePaymentDocument,
    setLoading,
    handleUpdateTransactionDetail,
    setUpdateTransactionDetailValues,
    getPaymentMethods,
    changeToastDetails,
    changeToastVisibility,
    handleSearchByEmail,
    onChangeStudentObj,
    onChangeAdvStudentObj,
    onSelectChangeStudentStatus,
    onSelectChangePaymentMethod,
    onChangeRefund,
    onChangePlacement,
    addPlacementHistory,
    onSelectChangeOnboardingManager,
    onSelectChangeStudentObj,
    onSelectChangeAdvStudentObj,
    handlePlacementHistoryChange,
    onChangeTypeOfRefund,
    onChangeComment,
    addComment,
    getUserData,
    handleSubmit,
    getMentorsList,
    getOnboardingManagerList,
    handleCopyClick,
    handleRemoveMentor,
    setIsCopied,
    navigate,
    handlePaymentSubmit,
    handleChangePayment,
    handleInstalmentChange,
    handleAddInstalment,
    handleRemoveInstalment,
    getPaymentDetailAdmin,
    handlePaymentViewDetails,
    handleRemoveMilestoneMentor,
    setOpenPaymentViewDetail,
    submitUpdateTransactionDetail,
    setPayReciveDate,
    documentVerified,
    handleToggle,
    getPayableAmount,
    calculateOneshotDiscount,
    calculateOfferDiscount,
    setUpdateTransactionPopup,
    setUpdatedRating,
    handleMeeting,
    handleMeetingDate,
    handleMeetingDuration,
    handleMeetingSsmRemark,
    handleMeetingAgenda,
    handleCreateMeeting,
    handleDiscard,
    getHistory,
    formatDate,
    setMeetingUpdatedDate,
    handleMeetingUpdatedDate,
    handleUpdateButton,
    handleUpdatedMeeting,
    handleMeetingUpdatedSsmRemark,
    handleMeetingUpdatedAgenda,
    handleMeetingUpdatedDuration,
    handleUpdateMeeting,
    handleCreateGroupSession,
    setIsCreateGroupSessionOpen,
    handleUpdateDetailButton,
    handleUpdatedStatus,
    handleUpdatedRating,
    handleUpdatedFeedback,
    handleUpdatedProblemSolvingMilestoneDate,
    handleUpdatedDevelopmentMilestoneDate,
    handleUpdatedProblemSolvingMilestone,
    handleUpdatedDevelopmentMilestone,
    handleMeetingDetails,
    ChangePaymentOptionsMenu,
    setIsDetailUpdateOpen,
    handleIsDetailUpdateopen,
    handleIsSessionDetailopen,
    setIsSessionDetailOpen,
    removeHypens,
    handleDeleteMeeting,
    handleIsDeleteSSMPopUp,
    setIsDeleteSSMPopUp,
    setMenuVisible,
    toggleMenu,
    handleCountryChange,
    handlestateChange,
    setCountry,
    setStateOptions,
    handleRefundBtnClick,
    addPlacementCompany,
    editPlacementCompany,
    deletePlacementCompany,
    getPlacementCompany,
    addStudentComment,
    getPlacementReady,
    setPlacementReady,
    getStudentComment,
    editStudentComment,
    deleteStudentComment,
    handleDiscardBtnClick,
    onSelectChangeMilestoneSessionMentor,
    handleSaveChangesClick,
    handleInputChange,
    handleCancelClick,
    handleConfirmClick,
    handlePopUpClose,
    handleRefundFormClose,
    submitUpdateTransaction_of_CCAv_Detail,
    getBatchHistoryData,
    handleOnboardingMeetingTable,
    tableData,
    setTableData,
    handleEditOnboardingDetails,
    handleFormSubmit,
    handleFormSubmitFeedback,
    cancelOnboardingForm,
    cancelFeedbackForm,
    isModalOpen,
    setIsModalOpen,
    isFeedbackModalOpen,
    SetIsFeedbackModalOpen,
    onboardingDate,
    setOnboardingDate,
    onboardingTime,
    setOnboardingTime,
    objective,
    setObjective,
    workAction,
    setWorkAction,
    handleObjective,
    handleWorkAction,
    onboardingData,
    setOnboardingData,
    getOnBoardingData,
    endTime,
    setEndTime,
    addThirtyMinutes,
    startTime,
    setStartTime,
    convertOnboardingTimeToTimeStamp,
    expired,
    setExpired,
    remainingTime,
    setRemainingTime,
    isFeedbackShow,
    SetIsFeedbackShow,
    isFirstClick,
    setIsFirstClick,
    isFeedbackModalView,
    SetIsFeedbackModalView,
    formatTimestamp,
    joinMeet,
    getOnboardingManagerName,
    onboardingManagerName,
    setOnboardingManagerName,
  }
}
