// ToggleButton.tsx
import React, { useEffect, useState } from "react"
import { cva } from "class-variance-authority"
import { cn } from "core/lib/utils"

const toggleButtonStyles = cva(
  "relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none",
  {
    variants: {
      checked: {
        true: "bg-success",
        false: "bg-v2-white border border-v2-gray-500",
      },
      hover: {
        true: "border-v2-gray-400 bg-v2-gray-100",
        false: "",
      },
      checkedHover: {
        true: "bg-success",
        false: "",
      },
      disabled: {
        true: "border-transparent bg-v2-gray-100",
        false: "",
      },
    },
    defaultVariants: {
      checked: false,
    },
  }
)

const toggleCircleStyles = cva("inline-block w-4 h-4 transform bg-white rounded-full transition-transform", {
  variants: {
    checked: {
      true: "translate-x-6 bg-v2-white",
      false: "translate-x-1 bg-v2-gray-500",
    },
    hover: {
      true: "bg-v2-gray-200",
      false: "",
    },
    checkedHover: {
      true: "bg-v2-gray-200",
      false: "",
    },
    disabled: {
      true: "bg-v2-gray-200",
      false: "",
    },
  },
  defaultVariants: {
    checked: false,
  },
})

interface ToggleButtonProps {
  checked?: boolean
  disabled?: boolean
  className?: string
  onChange?: Function
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ checked = false, disabled = false, className, onChange }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleToggle = () => {
    const newChecked = !isChecked
    setIsChecked(newChecked)
    if (onChange) {
      onChange()
    }
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className={cn(
        toggleButtonStyles({
          checked: isChecked,
          hover,
          disabled,
          checkedHover: isChecked && hover,
          className,
        })
      )}
      onClick={handleToggle}
      onMouseMove={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      aria-pressed={isChecked}
    >
      <span className="sr-only">Toggle</span>
      <span
        className={cn(
          toggleCircleStyles({
            checked: isChecked,
            hover,
            disabled,
            checkedHover: isChecked && hover,
          })
        )}
      />
    </button>
  )
}

export { ToggleButton }
