import { cn } from "core/lib/utils"

const ModuleCard = ({ className, children, ...props }: any) => {
  return (
    <div className="">
      <div className={cn("bg-white rounded-[8px] h-[116px] border-[0.5px]", className)} {...props}>
        {children}
      </div>
    </div>
  )
}

export default ModuleCard
