import { ShortRepository } from "domain/repository/Student/ShortRepository"
import { Auth } from "domain/model/Auth"

export interface SaveShortUseCase {
  invoke(auth: Auth, sessionID: string, chapterName: string, moduleTitle: string, isSaved: boolean): Promise<any>
}

export class SaveShort implements SaveShortUseCase {
  private repository: ShortRepository

  constructor(repository: ShortRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, sessionID: string, chapterName: string, shortTitle: string, isSaved: boolean): Promise<any> {
    return this.repository.saveShort(auth, sessionID, chapterName, shortTitle, isSaved)
  }
}
