import { Auth } from "domain/model/Auth"
import SessionRescheduleRequestRepository from "domain/repository/Admin/SessionRescheduleRequestRepository"
import UpdateSessionRescheduleRequestDetails from "domain/model/UpdateSessionRescheduleRequest"
export interface UpdateSessionRescheduleRequestUseCase {
  invoke(auth: Auth, details: UpdateSessionRescheduleRequestDetails): Promise<any>
}

export class UpdateSessionRescheduleRequest implements UpdateSessionRescheduleRequestUseCase {
  private repository: SessionRescheduleRequestRepository

  constructor(repository: SessionRescheduleRequestRepository) {
    this.repository = repository
  }

  async invoke(auth: Auth, details: UpdateSessionRescheduleRequestDetails): Promise<any> {
    return this.repository.updateSessionRescheduleRequest(auth, details)
  }
}
