import CheckboxEmpty from "assets/svgs/CheckboxEmpty"
import React from "react"

const PhaseCard = ({
  phase_name,
  milestone_marks,
  passed,
  ongoing,
  isAllowed,
  selectedPhaseId,
  id,
  currentPhaseStartsOutsideBatchRange,
}: any) => {
  return (
    <div
      className={`w-full max-w-full flex-wrap h-full max-h-full rounded-[4px] py-[6px] px-4 gap-2 cursor-pointer my-[11px] ${isAllowed || (passed === true && !ongoing) ? "bg-new-solid-white hover:bg-[#EBEBEB]" : "bg-[#EBEBEB]"} 
      ${selectedPhaseId === id ? "border-[1px] border-[#00000040] shadow-[3px_3px_0px_0px_rgba(0,0,0,0.25)] rounded-[4px]" : ""}
      `}
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <p className="font-inter font-medium text-[14px] leading-[17px] w-full flex-wrap">{phase_name}</p>
          <div className="flex">
            <input
              type="checkbox"
              className="rounded-[2px] border-[#D3D3D3] checked:bg-[#00C22B] h-4 w-4 top-0"
              checked={!ongoing && passed === true && !currentPhaseStartsOutsideBatchRange}
              readOnly
            />
          </div>
        </div>
        <p
          className={`font-inter font-medium text-[12px] leading-[14.52px] max-w-[101px] w-full mt-2 pb-[19px] ${milestone_marks >= 75 && !ongoing ? "text-[#07B42D]" : milestone_marks === "N/A" ? "text-[#646464]" : passed === true && !ongoing && milestone_marks < 74 ? "text-[#FBBF24]" : "text-[#DC2626]"}`}
        >
          {milestone_marks === -1 ? "Marks: N/A" : "Marks: " + milestone_marks + "%"}
        </p>
      </div>
    </div>
  )
}

export default PhaseCard
