import React, { useState } from "react"
import { Info } from "lucide-react"
import influencerInvoice from "core/utils/influencerInvoice"
import PaymentPopUpBreakdowntotal from "presentation/Admin/InfluencerInvoice/components/PaymentDetails"

interface Invoice {
  date: string
  amount: number
  campaign: { [key: string]: { [key: string]: number } }
  downloadLink?: string
}

interface TableProps {
  invoiceData: Invoice[]
  influencerData: any
}

const TableHeader: string[] = ["Date", "Amount", "Campaign Enrolled", "Download"]

const generateInvoice = (invoice: any, data: any) => {
  const props: any = {
    email: invoice.email,
    name: data.beneficiary_name,
    address: data.address,
    month: invoice.date.slice(0, 2),
    year: invoice.date.slice(3),
    posts: invoice.posts,
    amount: invoice.amount,
    ifsc: data.IFSC,
    benificiary_name: data.beneficiary_name,
    account: data.account_number,
    phone: data.phone,
  }
  influencerInvoice(props)
}

const InvoiceTable: React.FC<TableProps> = ({ invoiceData, influencerData }) => {
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)

  return (
    <div className="overflow-x-auto">
      <table className="w-full border border-gray-300 text-left">
        <thead>
          <tr>
            {TableHeader.map((header) => (
              <th key={header} className="border-b bg-gray-100 px-4 py-2 text-sm font-medium text-gray-600">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {invoiceData && invoiceData.length > 0 ? (
            invoiceData.map((invoice, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border-b px-4 py-2 text-sm text-gray-800">{invoice.date}</td>
                <td className="border-b px-4 py-2 text-sm text-gray-800">
                  ₹{invoice.amount.toFixed(2)}
                  <button
                    className="ml-2 text-gray-500 hover:text-gray-700"
                    onClick={() => setSelectedInvoice(invoice)}
                  >
                    <Info size={16} color="gray" />
                  </button>
                </td>
                <td className="border-b px-4 py-2 text-sm text-gray-800">
                  {invoice.campaign && Object.keys(invoice.campaign).length > 0 ? (
                    <ul className="list-disc pl-4">
                      {Object.entries(invoice.campaign).map(([platform, details]) => {
                        const totalPosts = Object.values(details).reduce((sum, count) => sum + count, 0)

                        return <li key={platform}>{`${platform}: ${totalPosts}`}</li>
                      })}
                    </ul>
                  ) : (
                    <span>No campaigns enrolled</span>
                  )}
                </td>

                <td className="border-b px-4 py-2 text-sm text-gray-800">
                  <button
                    onClick={() => generateInvoice(invoice, influencerData)}
                    className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
                  >
                    Download
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="border-b px-4 py-4 text-center text-sm text-gray-800">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {selectedInvoice && (
        <PaymentPopUpBreakdowntotal selectedInvoice={selectedInvoice} handlPopUp2={() => setSelectedInvoice(null)} />
      )}
    </div>
  )
}

export default InvoiceTable
