import React from "react"

export default function DropdownArrow({ className }: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="chevron">
        <path
          id="shape"
          d="M7.99987 8.78145L11.2999 5.48145L12.2425 6.42411L7.99987 10.6668L3.7572 6.42411L4.69987 5.48145L7.99987 8.78145Z"
          fill="#646464"
        />
      </g>
    </svg>
  )
}
